/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import ErrorValidation from "../../components/ErrorValidation";
import validate from "../../helpers/validate";
import { CreateUserSchema } from "../../validations/signup.validations";
import InputMask from 'inputmask';
import { validateRut } from "../../helpers/Utils";
import { toast } from "react-toastify";

type IUser = {
    name: string;
    family_name: string;
    email: string;
    phone_number: string;
    rut: string;
    role: string;
    info: any
}

interface ICompanyUser {
    data?: null | IUser;
    handleClose: () => void;
    handleConfirm: (data: any) => void;
    action: string;
}

const UserForm = ({ data, handleClose, handleConfirm, action }: ICompanyUser) => {

    const [state, setState] = useState({
        inputs: {
            name: "",
            family_name: "",
            email: "",
            phone_number: "",
            rut: "",
            role: "client_company"
        },
        validations: {}
    })

    const phoneRef: any = useRef(null);

    const _handleOnChange = ({ target }: any) => {
        const { name, value } = target;
        setState({ ...state, inputs: { ...state.inputs, [name]: value } });
    }

    useEffect(() => {
        if (action === "update" && data) {
            setState({
                ...state,
                inputs: {
                    name: data?.name,
                    family_name: data?.family_name,
                    email: data?.email,
                    phone_number: data?.phone_number?.slice(3),
                    rut: data?.rut,
                    role: data?.info?.role
                }
            })
        }

        InputMask({
            mask: '999999999',
            casing: 'upper',
            placeholder: '_',
            showMaskOnFocus: false,
            showMaskOnHover: false,
            jitMasking: true,
        }).mask(phoneRef.current);
    }, [])

    const _handleConfirm = async () => {
        try {
            let validations = await validate(CreateUserSchema, state.inputs);

            if (!validateRut(state.inputs.rut)) {
                if (typeof validations === 'boolean') {
                    validations = { rut: ['El rut no es válido'] };
                } else {
                    validations.rut = ['El rut no es válido'];
                }
            }

            if (validations) {
                setState({ ...state, validations });
                return;
            }

            handleConfirm({
                ...data,
                ...state.inputs,
                phone_number: "+56" + state.inputs.phone_number,
                phone: "+56" + state.inputs.phone_number,
                info: {
                    isInactive: false,
                    roles: ["client_company"],
                    role: "client_company",
                    permissions: ["all"],
                    concessionaire_id: null,
                    eds_id: null,
                    isAuthZero: false
                }
            });
        } catch (e) {
            toast.error('Error al validar los datos');
        }
    }

    return (
        <div className="container-fluid">
            <div className="row color-black mb-4">
                <div className="col-12">
                    <h4>Usuario</h4>
                </div>
            </div>

            <div className="row">
                <div className="col-12 mb-2">
                    <span className="size-08 bold">Nombre</span>
                    <input type="text" className="input-default-editable rounded" value={`${state.inputs.name}`} name="name" placeholder='Ingresa un nombre' onChange={_handleOnChange} />
                    <ErrorValidation validations={state.validations} name="name" className="text-end size-09" />
                </div>
            </div>

            <div className="row">
                <div className="col-12 mb-2">
                    <span className="size-08 bold">Apellido</span>
                    <input type="text" className="input-default-editable rounded" value={`${state.inputs.family_name}`} name="family_name" placeholder='Ingresa un apellido' onChange={_handleOnChange} />
                    <ErrorValidation validations={state.validations} name="family_name" className="text-end size-09" />
                </div>
            </div>

            <div className="row">
                <div className="col-12 mb-2">
                    <span className="size-08 bold">Rut</span>
                    <input type="text" className="input-default-editable rounded" value={`${state.inputs.rut}`} name="rut" placeholder='Ingresa un rut sin puntos y con guión' onChange={_handleOnChange} />
                    <ErrorValidation validations={state.validations} name="rut" className="text-end size-09" />
                </div>
            </div>

            <div className="row">
                <div className="col-12 mb-2">
                    <span className="size-08 bold">Teléfono</span>
                    <input ref={phoneRef} type="text" className="input-default-editable rounded" value={`${state.inputs.phone_number}`} name="phone_number" placeholder="Ingresa tu teléfono" onChange={_handleOnChange} />
                    <ErrorValidation validations={state.validations} name="phone_number" className="text-end size-09" />
                </div>
            </div>

            <div className="row">
                <div className="col-12 mb-2">
                    <span className="size-08 bold">Email</span>
                    <input type="text" className="input-default-editable rounded" value={`${state.inputs.email}`} name="email" placeholder='Ingresa un email' onChange={_handleOnChange} disabled={action === "update"} />
                    <ErrorValidation validations={state.validations} name="email" className="text-end size-09" />
                </div>
            </div>

            <div className="row mt-5">
                <div className="col-12 text-end text-white">
                    <button className="btn-default size-09 px-3 py-2 rounded-10 me-3" onClick={handleClose}>Cancelar</button>
                    <button className="btn-default size-09 px-3 py-2 rounded-10" onClick={_handleConfirm}>Confirmar</button>
                </div>
            </div>
        </div>
    );
}

export default UserForm;