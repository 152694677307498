const Policies  = () => {
    return(
        <div className="container text-dark">
            <p><strong>POL&Iacute;TICA DE PRIVACIDAD COPEC S.A Y FILIALES&nbsp;</strong></p>
            <p>I. NUESTRO&nbsp;COMPROMISO &nbsp;</p>
            <p>Para COPEC S.A y sus filiales., en adelante referidas en conjunto e indistintamente como la &nbsp;&ldquo;Sociedad&rdquo;, la privacidad de las personas, la confidencialidad y el cuidado de los Datos &nbsp;Personales es primordial, por ello nos comprometemos a que la informaci&oacute;n personal de &nbsp;nuestros Clientes y/o Usuarios sea protegida y su privacidad respetada.&nbsp;</p>
            <p>La presente Pol&iacute;tica de Privacidad (en adelante la &ldquo;Pol&iacute;tica&rdquo;) detalla c&oacute;mo la Sociedad, &nbsp;accede, somete a tratamiento y protege Datos Personales ya sea que los faciliten nuestros &nbsp;Clientes y/o Usuarios o que cualquier integrante de la Sociedad los recoja a trav&eacute;s de &nbsp;diversas modalidades. Esto, con el objeto de que nuestros Clientes y/o Usuarios, puedan &nbsp;autorizar a que dichos Datos sean tratados, almacenados y protegidos por nosotros como&nbsp;</p>
            <p>Sociedad.&nbsp;</p>
            <p>Los Datos Personales, como indicamos, pueden ser facilitados por nuestros Clientes y/o &nbsp;Usuarios o pueden ser recolectados a trav&eacute;s de puntos de ventas, estaciones de servicios, &nbsp;tiendas de conveniencia, negocios complementarios y otros establecimientos, sean estos, operados directamente por la Sociedad o por concesionarios, consignatarios, &nbsp;franquiciatarios, distribuidores o terceros, sitios web, aplicaciones m&oacute;viles, formularios y &nbsp;otros canales.&nbsp;</p>
            <p>Por tanto, cuando cualquiera de nuestros Clientes y/o Usuarios contraten productos y/o &nbsp;servicios de la Sociedad y/o participen en actividades organizadas por &eacute;sta, el Tratamiento &nbsp;de sus Datos Personales se realizar&aacute; conforme a lo establecido en la presente Pol&iacute;tica.&nbsp;</p>
            <p>II. APLICACI&Oacute;N DE LA&nbsp;POL&Iacute;TICA DE&nbsp;PRIVACIDAD &nbsp;</p>
            <p>Para efectos de aplicaci&oacute;n de la presente Pol&iacute;tica de Privacidad, la Sociedad se compone de &nbsp;las siguientes compa&ntilde;&iacute;as: &nbsp;</p>
            <p>∙&nbsp;Administradora de Servicios Generales Ltda.&nbsp;∙&nbsp;EMOAC SpA&nbsp;∙&nbsp;Via Limpia SpA&nbsp;∙&nbsp;Ampere Copec SpA&nbsp;∙&nbsp;Administradora de Ventas al Detalle Ltda.&nbsp;∙&nbsp;Stem &ndash; Copec SpA&nbsp;∙&nbsp;Copec Voltex SpA&nbsp;∙&nbsp;Soluciones de Movilidad SpA&nbsp;∙&nbsp;Flux Solar energ&iacute;as renovables SpA&nbsp;</p>
            <p>∙&nbsp;Copec S.A&nbsp;</p>
            <p>Hacemos presente que esta Pol&iacute;tica no aplica a los Datos Personales que sean recolectados &nbsp;por compa&ntilde;&iacute;as o empresas distintas a las se&ntilde;aladas en el p&aacute;rrafo anterior o por terceros en &nbsp;otros sitios web que no pertenezcan a &eacute;stas, aun cuando &eacute;stos est&eacute;n enlazados o embebidos &nbsp;en los sitios web o aplicaciones m&oacute;viles de cualquier miembro de la Sociedad.&nbsp;</p>
            <p><br/></p>
            <p>Es por ello, y dado que la Sociedad no dispone de control alguno respecto de dichos sitios &nbsp;web o aplicaciones m&oacute;viles, la Sociedad no es responsable del contenido que ellos &nbsp;desarrollen. Por lo anterior, nuestros Clientes y/o Usuarios deber&aacute;n regirse por la Pol&iacute;tica de &nbsp;Privacidad de dichos sitios o aplicaciones m&oacute;viles y no por la presente.&nbsp;</p>
            <p>III. MARCO&nbsp;NORMATIVO&nbsp;</p>
            <p>El Tratamiento de Datos que realizan los miembros de la Sociedad es conforme a la Ley N&deg;19.628 &ldquo;Ley de Protecci&oacute;n a la Vida Privada&rdquo;, Ley N&deg;19.496 &ldquo;Ley sobre Protecci&oacute;n a los &nbsp;Derechos de los Consumidores&rdquo;, sus modificaciones y las dem&aacute;s normas relacionadas con &nbsp;el resguardo, protecci&oacute;n, reserva y confidencialidad de los Datos Personales.&nbsp;</p>
            <p>IV. RESPONSABLE DEL&nbsp;TRATAMIENTO DE&nbsp;DATOS&nbsp;</p>
            <p>Para efectos de tratar, verificar y resguardar los Datos de nuestros Clientes y/o Usuarios &nbsp;que contraten o a los cuales tenga acceso la Sociedad, &eacute;sta ha designado como Responsable del Tratamiento de Datos a: &nbsp;</p>
            <p>∙&nbsp;Copec S.A&nbsp;</p>
            <p>Rol &uacute;nico tributario n&uacute;mero 99.520.000-7&nbsp;</p>
            <p>Representante Legal: Arturo Natho Gamboa&nbsp;</p>
            <p>Domicilio: EL BOSQUE NORTE 0211 Y/O AV ISIDORA GOYENECHEA 2915 Tel&eacute;fono: (56 2)2 461 7000 &nbsp;</p>
            <p>Casilla de correo electr&oacute;nico de contacto: llamanos@copec.cl&nbsp;</p>
            <p>Lo anterior, sin perjuicio de la responsabilidad que a cada compa&ntilde;&iacute;a miembro de la Sociedad &nbsp;le quepa por cualquier incumplimiento de sus obligaciones como consecuencia de las normas &nbsp;que regulan la protecci&oacute;n de la vida privada. &nbsp;</p>
            <p>V. DEFINICIONES&nbsp;</p>
            <p>a)&nbsp;Cliente: es la persona natural a la cual la Sociedad le provee productos y/o servicios &nbsp;sean estos prove&iacute;dos directamente por ella o por terceros.&nbsp;</p>
            <p>b)&nbsp;Consentimiento del Titular: manifestaci&oacute;n de voluntad libre, expresa, especifica, e informada, mediante la cual el Titular autoriza, por escrito, el Tratamiento de Datos &nbsp;Personales que le conciernen, incluyendo la suscripci&oacute;n mediante firma electr&oacute;nica &nbsp;simple o avanzada, cuando corresponda.&nbsp;</p>
            <p>c)&nbsp;Datos: incluye Datos Personales y Datos Sensibles, seg&uacute;n corresponda.&nbsp;</p>
            <p>d)&nbsp;Datos Personales: los relativos a cualquier informaci&oacute;n concerniente a personas &nbsp;naturales, identificadas o identificables. Quedan comprendidos en esta categor&iacute;a, de &nbsp;forma meramente ejemplar, los antecedentes tales como, nombre, edad, estado civil,&nbsp;</p>
            <p><br/></p>
            <p>profesi&oacute;n, n&uacute;mero de c&eacute;dula de identidad, n&uacute;meros telef&oacute;nicos, casilla de correo &nbsp;electr&oacute;nico, n&uacute;mero de tarjetas bancarias.&nbsp;</p>
            <p>e)&nbsp;Datos Sensibles: un tipo de Datos Personales que se refieren a las caracter&iacute;sticas &nbsp;f&iacute;sicas o morales de las personas o a hechos o circunstancias de su vida privada o &nbsp;intimidad, tales como los h&aacute;bitos personales, el origen racial, las ideolog&iacute;as y opiniones &nbsp;pol&iacute;ticas, las creencias o convicciones religiosas, los estados de salud f&iacute;sicos o &nbsp;ps&iacute;quicos y la vida sexual.&nbsp;</p>
            <p>f)&nbsp;Encargado del Tratamiento de Datos (Encargado de Datos): corresponde a la persona &nbsp;natural o jur&iacute;dica que ejecuta todo o parte del Tratamiento de los Datos como un &nbsp;mandatario, por cuenta del Responsable de Datos.&nbsp;</p>
            <p>g)&nbsp;Responsable de Datos: es la persona natural o jur&iacute;dica, p&uacute;blica o privada, que decide &nbsp;a cerca de los fines y medios del Tratamiento de Datos, con independencia de si los &nbsp;datos son tratados directamente por &eacute;l o a trav&eacute;s de un Encargado de Datos. &nbsp;</p>
            <p>h)&nbsp;Terceros: toda persona o entidad distinta del Titular, el Responsable de Datos, y/o el &nbsp;Encargado de Datos.&nbsp;</p>
            <p>i)&nbsp;Titular de los Datos: es la persona natural, identificada o identificable, a quien &nbsp;conciernen o se refieren los Datos.&nbsp;</p>
            <p>j)&nbsp;Trabajador: es cualquier persona vinculada a la Sociedad por un contrato de trabajo, &nbsp;sea &eacute;ste indefinido, a plazo fijo o por faena, y, para estos efectos, incluye adem&aacute;s a &nbsp;los miembros de los directorios de cada compa&ntilde;&iacute;a miembro de la Sociedad.&nbsp;</p>
            <p>k)&nbsp;Transmisi&oacute;n de Datos Personales: es el Tratamiento de Datos que implica la &nbsp;comunicaci&oacute;n de los mismos dentro o fuera del territorio del pa&iacute;s cuando tenga por &nbsp;objeto la realizaci&oacute;n de un Tratamiento por el Encargado de Datos por cuenta del &nbsp;Responsable de Datos.&nbsp;</p>
            <p>l)&nbsp;Transferencia de Datos Personales: tiene lugar cuando el Responsable y/o el &nbsp;Encargado de Datos, ubicado en Chile, env&iacute;a la informaci&oacute;n o dichos Datos a un &nbsp;receptor, que a su vez es Responsable del Tratamiento de Datos y se encuentra dentro &nbsp;o fuera del pa&iacute;s.&nbsp;</p>
            <p>m)&nbsp;Tratamiento de Datos (en adelante indistintamente el &ldquo;Tratamiento&rdquo;): es cualquier &nbsp;operaci&oacute;n o conjunto de operaciones o procedimientos t&eacute;cnicos, de car&aacute;cter &nbsp;automatizado o no, que permite recolectar, almacenar, grabar, organizar, elaborar, &nbsp;seleccionar, extraer, confrontar, interconectar, disociar, comunicar, ceder, transferir, &nbsp;transmitir o cancelar Datos o utilizarlos en cualquier otra forma.&nbsp;</p>
            <p>n)&nbsp;Usuario: persona natural interesada en los productos y/o servicios ofrecidos por la &nbsp;Sociedad y que autoriza expresamente el Tratamiento de sus Datos por parte de &eacute;sta.</p>
            <p><br/></p>
            <p>VI. LICITUD DEL&nbsp;TRATAMIENTO DE&nbsp;DATOS &nbsp;</p>
            <p>El Tratamiento de Datos que realizamos como Sociedad se fundamenta:&nbsp;</p>
            <p>∙&nbsp;En el consentimiento entregado expresa, previa, inequ&iacute;voca, libre e informadamente, por parte de nuestros Clientes y/o Usuarios y Titular de los Datos. &nbsp;</p>
            <p>∙&nbsp;Los contratos en los cuales se incorporen Datos de nuestros Clientes, y/o Usuarios y Titular de los Datos. &nbsp;</p>
            <p>∙&nbsp;En la Ley vigente en materia de protecci&oacute;n de la vida privada, cuando reconoce la &nbsp;facultad de tratar Datos.&nbsp;</p>
            <p>VII. FINALIDAD DEL&nbsp;TRATAMIENTO DE&nbsp;DATOS &nbsp;</p>
            <p>Al consentir en esta Pol&iacute;tica, siempre que no existan restricciones legales para aquello, &nbsp;nuestros Clientes y/o Usuarios y Titulares de los Datos nos autorizan, a la Sociedad, a &nbsp;compartir sus Datos con el resto de las compa&ntilde;&iacute;as que la conforman y con las que en futuro &nbsp;la conformen, &uacute;nicamente para los fines que se se&ntilde;alan en este numeral. Asimismo, las &nbsp;incorporaciones que se realicen en el futuro, ser&aacute;n debidamente informadas al Titular de los &nbsp;Datos.&nbsp;</p>
            <p>Adicionalmente, dicho Titular de los Datos, al prestar su consentimiento, nos autoriza, a la &nbsp;Sociedad, a realizar el Tratamiento de sus Datos con los siguientes objetivos:&nbsp;</p>
            <p>1. Objetivo general:&nbsp;</p>
            <p>En la Sociedad trataremos los Datos a efectos de:&nbsp;</p>
            <p>∙&nbsp;Entregar una adecuada prestaci&oacute;n de productos y servicios, incluyendo la oferta, &nbsp;promoci&oacute;n y venta de &eacute;stos;&nbsp;</p>
            <p>∙&nbsp;Dar cumplimiento a las obligaciones legales vigentes; &nbsp;</p>
            <p>∙&nbsp;Realizar todas las gestiones necesarias para confirmar y actualizar la informaci&oacute;n &nbsp;del Titular de los Datos.&nbsp;</p>
            <p>∙&nbsp;Para efectos de resguardar la seguridad de su informaci&oacute;n y asegurar las &nbsp;gestiones de cobro. &nbsp;</p>
            <p>2. Objetivos espec&iacute;ficos:&nbsp;</p>
            <p>a.&nbsp;Fines operativos: trat&aacute;ndose de aplicaciones m&oacute;viles, en la Sociedad podremos efectuar el Tratamiento de todos aquellos Datos relacionados con las&nbsp;</p>
            <p><br/></p>
            <p>caracter&iacute;sticas t&eacute;cnicas del equipo m&oacute;vil en que el Cliente y/o Usuario y Titular de los Datos descargue las Aplicaciones vinculadas a la Sociedad. &nbsp;</p>
            <p>Lo anterior incluye: direcci&oacute;n Ip, ID del equipo, sistema operativo, idioma configurado, proveedor de red, entre otros aspectos, todo con ello con la &nbsp;finalidad de entregar una adecuada prestaci&oacute;n de los productos y servicios que &nbsp;ofrecen las Aplicaciones M&oacute;viles de la Sociedad.&nbsp;</p>
            <p>b.&nbsp;Fines comerciales: en la Sociedad trataremos los Datos con el objeto ofrecer a nuestros Clientes y/o Usuarios y Titulares de Datos, productos y servicios; &nbsp;beneficios asociados a programas de fidelizaci&oacute;n; informaci&oacute;n de eventos y &nbsp;sorteos, descuentos, promociones, encuestas de satisfacci&oacute;n y en general, &nbsp;ofreceremos diferentes opciones para mejorar su experiencia, lo cual podr&aacute; &nbsp;realizarse por medio de correo electr&oacute;nico, mensajes de texto (SMS), &nbsp;notificaciones en aplicaciones m&oacute;viles, WhatsApp, redes sociales, llamadas &nbsp;telef&oacute;nicas, entre otros tipos de comunicaciones. No obstante lo anterior, los &nbsp;Clientes y/o Usuarios y Titulares de Datos podr&aacute;n siempre solicitar la cancelaci&oacute;n &nbsp;de los env&iacute;os de oferta, en la forma se&ntilde;alada en la misma comunicaci&oacute;n.&nbsp;</p>
            <p>c.&nbsp;Fines operacionales: trataremos los Datos de nuestros Clientes y/o Usuarios y &nbsp;Titular de Datos en aquellos casos en los que sea necesario retirar del mercado &nbsp;y/o del domicilio productos defectuosos o en mal estado.&nbsp;</p>
            <p>d.&nbsp;Fines preventivos: trataremos los Datos de nuestros Clientes y/o Usuarios y &nbsp;Titular de Datos con el objeto de mantener la calidad de nuestros productos y &nbsp;servicios como Sociedad y de prevenir fraudes y/o delitos de cualquier &nbsp;naturaleza. En dicho sentido, podremos:&nbsp;</p>
            <p>∙&nbsp;Grabar conversaciones telef&oacute;nicas asociadas a Servicio de Atenci&oacute;n al &nbsp;Cliente y/o Usuario;&nbsp;</p>
            <p>∙&nbsp;Contar con un circuito cerrado de c&aacute;maras de video vigilancia en nuestras instalaciones y establecimientos, ya sean estos operados directamente por &nbsp;nosotros o por terceros;&nbsp;</p>
            <p>∙&nbsp;Revisar dichas grabaciones en casos en que como Sociedad lo &nbsp;consideremos necesario y en casos de incidentes u ocurrencia de delitos, y conservar dichas grabaciones, las que tambi&eacute;n podr&aacute;n ser utilizadas &nbsp;como prueba en procesos judiciales, administrativos y arbitrales.&nbsp;</p>
            <p>e.&nbsp;Fines hist&oacute;ricos: podremos utilizar los Datos con fines estad&iacute;sticos, hist&oacute;ricos, de &nbsp;estudios de mercado, entre otros. Dichos Datos, dentro de lo posible, se &nbsp;utilizar&aacute;n en forma anonimizada, esto es, no siendo posible identificar la &nbsp;identidad de la persona a quien estos pertenecen.&nbsp;</p>
            <p>Para las finalidades antes indicadas, como Sociedad, podremos recolectar Datos por &nbsp;diferentes medios, ya sea con ocasi&oacute;n del acceso y uso de los distintos sitios web de&nbsp;</p>
            <p><br/></p>
            <p>las empresas que componen a la Sociedad, las transacciones de compras o &nbsp;devoluciones realizadas en sus establecimientos, sean estos operados directamente la &nbsp;Sociedad o por terceros y en sus comercios electr&oacute;nicos, programas de fidelidad, &nbsp;encuestas, aplicaciones m&oacute;viles; remisi&oacute;n de formularios en l&iacute;nea, correos &nbsp;electr&oacute;nicos, llamadas telef&oacute;nicas, chat en l&iacute;nea, atenci&oacute;n dada por servicio al cliente, &nbsp;bases de datos de libre acceso al p&uacute;blico, entre otros canales autorizados por el Titular &nbsp;de los Datos de conformidad a la legislaci&oacute;n vigente.&nbsp;</p>
            <p>Dentro de lo posible y razonable, realizaremos controles respecto de la licitud y/o &nbsp;veracidad de la informaci&oacute;n que faciliten nuestros Clientes y/o Usuarios y Titulares de &nbsp;Datos. Sin embargo, no seremos responsables de cualquier infracci&oacute;n y/o perjuicio &nbsp;derivado de la ilicitud, ilegitimidad, alteraci&oacute;n, mal uso, fraude o sanci&oacute;n en relaci&oacute;n &nbsp;con la informaci&oacute;n que se nos haya entregado, sin perjuicio de las estipulaciones que &nbsp;contemple la ley. Es responsabilidad del Cliente y/o Usuario actualizar su informaci&oacute;n &nbsp;siempre que sea necesario.&nbsp;</p>
            <p>VIII. TRASPASO DE&nbsp;DATOS &nbsp;</p>
            <p>Las compa&ntilde;&iacute;as que formamos parte de la Sociedad podremos compartir los Datos de los &nbsp;Titulares:&nbsp;</p>
            <p>1. Con las siguientes compa&ntilde;ias: &nbsp;</p>
            <p>∙&nbsp;Administradora de Servicios Generales Ltda.&nbsp;∙&nbsp;EMOAC SpA&nbsp;∙&nbsp;Via Limpia SpA&nbsp;∙&nbsp;Ampere Copec SpA&nbsp;∙&nbsp;Administradora de Ventas al Detalle Ltda.&nbsp;∙&nbsp;Stem &ndash; Copec SpA&nbsp;∙&nbsp;Copec Voltex SpA&nbsp;∙&nbsp;Soluciones de Movilidad SpA&nbsp;∙&nbsp;Flux Solar energ&iacute;as renovables SpA&nbsp;∙&nbsp;Compa&ntilde;&iacute;a Emisora de Medios &nbsp;de Pago Digitales S.A&nbsp;</p>
            <p>∙&nbsp;Copec S.A&nbsp;</p>
            <p>2. Con los siguientes terceros:&nbsp;</p>
            <p>a) Con todos quienes se constituyan como nuestros concesionarios, consignatarios o franquiciados; &nbsp;</p>
            <p>b) Con proveedores que nos presten los servicios necesarios para proveer y &nbsp;promocionar los productos y servicios que ofrecen nuestros miembros;&nbsp;</p>
            <p>3. En las siguientes situaciones:&nbsp;</p>
            <p>a) Actualizar el estado de las relaciones contractuales con nuestros Clientes y/o &nbsp;Usuarios y Titular de Datos;&nbsp;</p>
            <p>b) Dar cumplimiento a las obligaciones pactadas con el Cliente y/o Usuario y Titular &nbsp;de los Datos;</p>
            <p><br/></p>
            <p>c) Prevenir el riesgo de la comisi&oacute;n de delitos, entre ellos fraude, lavado de activos &nbsp;y financiamiento del terrorismo;&nbsp;</p>
            <p>d) Responder a solicitudes de informaci&oacute;n de autoridades policiales o &nbsp;gubernamentales nacionales o internacionales;&nbsp;</p>
            <p>e) Dar cumplimiento a cualquier ley, reglamento, citaci&oacute;n u orden judicial; f) Proteger los derechos y propiedades de la Sociedad;&nbsp;</p>
            <p>g) Proteger la seguridad personal de los Trabajadores de la Sociedad y de sus &nbsp;representantes, de terceros y del p&uacute;blico en general;&nbsp;</p>
            <p>h) Ante cualquier circunstancia en que, por motivos estrat&eacute;gicos u otros motivos &nbsp;comerciales, la Sociedad o alguno de sus miembros decidiera vender, comprar, &nbsp;fusionarse o reorganizar los negocios de otra forma en Chile u otros pa&iacute;ses. En &nbsp;tal caso, dicha transacci&oacute;n podr&iacute;a incluir la divulgaci&oacute;n de informaci&oacute;n personal &nbsp;regulada en esta Pol&iacute;tica a compradores, debiendo obtener un nuevo &nbsp;consentimiento por parte del Titular de Datos.&nbsp;</p>
            <p>En todos estos casos, quien resulte como Encargado de Datos deber&aacute; dar &nbsp;cumplimiento a la presente Pol&iacute;tica de Privacidad.&nbsp;</p>
            <p>IX. TRANSMISI&Oacute;N Y TRANSFERENCIA INTERNACIONAL&nbsp;</p>
            <p>Mediante la aceptaci&oacute;n de esta Pol&iacute;tica de Privacidad, el Clientes y/o Usuarios y Titular de &nbsp;Datos nos autorizan expresamente, a la Sociedad, en miras de la entrega de un mejor &nbsp;servicio y de acuerdo con las mejores tecnolog&iacute;as existentes, a transferir sus Datos a &nbsp;servidores ubicados en la nube y/o en territorio extranjero.&nbsp;</p>
            <p>X. VIGENCIA DEL&nbsp;TRATAMIENTO DE&nbsp;DATOS&nbsp;</p>
            <p>Los Datos regulados en la presente Pol&iacute;tica, se conservar&aacute;n durante el tiempo que sea &nbsp;necesario en relaci&oacute;n con las finalidades antes indicadas o hasta que el Titular de los Datos &nbsp;pida su eliminaci&oacute;n, lo que primero ocurra. &nbsp;</p>
            <p>XI. DERECHOS&nbsp;ARCO + P&nbsp;</p>
            <p>Todo Titular de los Datos tiene derecho a solicitar informaci&oacute;n respecto de los datos que &nbsp;sean objeto de Tratamiento por parte de la Sociedad. En tal sentido, todo Titular de Datos podr&aacute; ejercer el derecho de acceso e informaci&oacute;n, rectificaci&oacute;n, cancelaci&oacute;n, oposici&oacute;n y &nbsp;portabilidad (ARCO+P) de sus Datos. &nbsp;</p>
            <p>Para ejercer estos derechos, el Titular de los Datos debe ingresar su solicitud a trav&eacute;s del &nbsp;formulario disponible en el sitio web&nbsp;https://web.copec.cl/centro-de-ayuda/contactanos&nbsp;o llamando al tel&eacute;fono 800 200 354.&nbsp;</p>
            <p>A trav&eacute;s de estos medios, el Titular de los Datos podr&aacute; ejercer los derechos ARCO+P que &nbsp;posee en relaci&oacute;n con nosotros, la Sociedad, tanto personalmente, como por medio de &nbsp;terceros, debidamente autorizados.&nbsp;</p>
            <p>En este entendido, la Sociedad se encuentra facultada para:&nbsp;</p>
            <p><br/></p>
            <p>∙&nbsp;Verificar la identidad del solicitante o bien, &nbsp;</p>
            <p>∙&nbsp;Verificar si el solicitante act&uacute;a debidamente representando a otra persona y cuenta con la autorizaci&oacute;n por escrito de la misma.&nbsp;</p>
            <p>La Sociedad s&oacute;lo podr&aacute; realizar las gestiones oportunas para el ejercicio de los derechos &nbsp;ARCO+P, en la medida que la petici&oacute;n:&nbsp;</p>
            <p>∙&nbsp;Se realice sobre Datos que le conciernan al solicitante;&nbsp;</p>
            <p>∙&nbsp;No afecte el cumplimiento de los servicios u obligaciones contractuales contra&iacute;das con &nbsp;alguna de las compa&ntilde;&iacute;as miembro de la Sociedad;&nbsp;</p>
            <p>∙&nbsp;No afecten derechos de otros Clientes y/o Usuarios de la Sociedad. &nbsp;</p>
            <p>La Sociedad entregar&aacute; respuesta de las solicitudes. En ocasiones, algunas de las respuestas &nbsp;podr&iacute;an tardar m&aacute;s de lo esperado, en cuyo caso la Sociedad informar&aacute; al solicitante la &nbsp;recepci&oacute;n del formulario y su estado de tramitaci&oacute;n. Del mismo modo, si no es posible cursar &nbsp;la solicitud, se le har&aacute; saber al solicitante mediante el medio de contacto que se hubiese &nbsp;registrado y se le informar&aacute; de los correspondientes motivos.&nbsp;</p>
            <p>Finalmente, la informaci&oacute;n solicitada ser&aacute; enviada de forma segura al correo electr&oacute;nico que &nbsp;el solicitante haya entregado en el formulario respectivo.&nbsp;</p>
            <p>Respecto de la cancelaci&oacute;n del Tratamiento de Datos, dicha solicitud no se tramitar&aacute; cuando &nbsp;recaiga sobre la Sociedad el deber legal o contractual de mantener los Datos en sus bases &nbsp;de datos en forma indefinida o por un tiempo determinado o cuando sea imposible la &nbsp;supresi&oacute;n de los datos.&nbsp;</p>
            <p>XII. SEGURIDAD&nbsp;</p>
            <p>Como se ha se&ntilde;alado anteriormente, en la Sociedad trataremos los Datos con la debida &nbsp;reserva y cuidado.&nbsp;</p>
            <p>En tal sentido, nos comprometemos a implementar las medidas t&eacute;cnicas y organizativas &nbsp;adecuadas, posibles y razonables para evitar la p&eacute;rdida, accesos no autorizados, filtraci&oacute;n, &nbsp;da&ntilde;o o destrucci&oacute;n sin autorizaci&oacute;n de los Datos de nuestros Clientes y/o Usuarios, de &nbsp;conformidad con las buenas pr&aacute;cticas el estado de la t&eacute;cnica y la disponibilidad de recursos.&nbsp;</p>
            <p>XIII. ACTUALIZACI&Oacute;N A LA&nbsp;POL&Iacute;TICA:&nbsp;</p>
            <p>La Sociedad podr&aacute; introducir modificaciones a la presente Pol&iacute;tica de Privacidad, las cuales &nbsp;deber&aacute;n ser debidamente aprobadas por el Titular de los Datos, las que surtir&aacute;n efecto a &nbsp;partir de entonces.&nbsp;</p>
            <p>En todo caso, frente a cambios a la presente Pol&iacute;tica, el consentimiento que el Titular de los &nbsp;Datos hubiere otorgado se mantendr&aacute; vigente y tendr&aacute; plena aplicaci&oacute;n respecto de la &nbsp;Pol&iacute;tica de Privacidad que en su caso haya aceptado.</p>
            <p><br/></p>
            <p>XIV. VIGENCIA&nbsp;</p>
            <p>La presente Pol&iacute;tica entra en vigencia a partir de la fecha de su publicaci&oacute;n, esto es el 12 &nbsp;de julio del a&ntilde;o 2022.</p>
        </div>
    )
}

export default Policies