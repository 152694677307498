import Images from '../../assets/image';
import Image from '../Image';

const CardService = (props: any) => {
  const { data, handleDelete, isMobile } = props;

  if (isMobile) {
    return (
      <div className="row p-2 py-3 m-0 line-border">
        <div className="col-3 d-flex align-items-center justify-content-center">
          <Image
            style={{ width: '50px', height: '50px' }}
            image={
              data.service?.info?.gallery?.url || data.info?.gallery?.url
                ? {
                  default: {
                    src: data.service?.info?.gallery?.url || data.info?.gallery?.url,
                  },
                }
                : Images.NotFound
            }
          />
        </div>
        <div className="col-7 d-flex flex-column justify-content-center size-09" style={{ color: '#000' }}>
          <div
            className="color-black bold-300"
            dangerouslySetInnerHTML={{
              __html: data.service?.name || data.name,
            }}
          />
          <div className="color-black bold-300">
            {data.service?.price ? (
              <>${parseInt(data.service?.price)?.toLocaleString('es-CL')} x {data.service?.quantity || 1}</>
            ) : null}
            {data?.price ? (
              <>${parseInt(data?.price)?.toLocaleString('es-CL')} x {data.quantity || 1}</>
            ) : null}
          </div>
          {data.service?.info?.full_points ? (
            <div className="color-black bold-300">
              <Image image={Images.CopecFullRed} className="me-2" style={{ width: '50px' }} />
              {parseInt(data.service?.info?.full_points)?.toLocaleString('es-CL')} puntos
            </div>
          ) : null}
        </div>
        <div className="col-2 d-flex align-items-center justify-content-end color-blue-base">
          <button className="btn" onClick={() => handleDelete({ ...data, confirmWindow: { open: true } })}>
            <Image image={Images.DeleteIcon} />
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="row line-border py-4">
      <div className="col-9 d-flex align-items-center">
        <Image
          style={{ width: '50px', height: '50px' }}
          image={
            data.service?.info?.gallery?.url || data.info?.gallery?.url
              ? {
                default: {
                  src: data.service?.info?.gallery?.url || data.info?.gallery?.url,
                },
              }
              : Images.NotFound
          }
        />
        <div className="ms-4">
          <div
            className="color-black bold-300"
            dangerouslySetInnerHTML={{
              __html: data.service?.name || data.name,
            }}
          />
          <div className="color-black bold-300 size-09">
            {data.service?.price ? (
              <>${parseInt(data.service?.price)?.toLocaleString('es-CL')} x {data.service?.quantity || 1}</>
            ) : null}
            {data?.price ? (
              <>${parseInt(data?.price)?.toLocaleString('es-CL')} x {data.quantity || 1}</>
            ) : null}

          </div>
          {data.service?.info?.full_points ? (
            <div className="color-black bold-300">
              <Image image={Images.CopecFullRed} className="me-2" style={{ width: '50px' }} />
              {parseInt(data.service?.info?.full_points)?.toLocaleString('es-CL')} puntos
            </div>
          ) : null}
        </div>
      </div>
      <div className="col-3 d-flex justify-content-end">
        <button className="btn color-blue-base bold" onClick={handleDelete}>
          Eliminar
        </button>
      </div>
    </div>
  );
};

export default CardService;
