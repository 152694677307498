import { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import Images from '../../assets/image';
import ButtonBack from '../../components/ButtonBack';
import ErrorValidation from '../../components/ErrorValidation';
import Image from '../../components/Image';
import Title from '../../components/Title';
import { regexCognito } from '../../helpers/Utils';
import validate from '../../helpers/validate';
import sessionService from '../../services/session.service';
import { EmailSchema } from '../../validations/email.validations';
import { RecoverySchema } from '../../validations/recovery.validations';
import Logo from '../../components/Logo';

const Recovery = () => {
  const history = useHistory();
  const [state, setState] = useState<any>({
    inputs: {
      email: '',
      code: '',
      password: '',
      password_c: '',
    },
    step: 1,
    validations: {},
  });

  const _handleOnChange = ({ target }: any) => {
    if (target.name === 'code') {
      target.value = target.value.replace(/\D/g, '');
    }
    const { name, value } = target;
    state.inputs[name] = value;
    setState({ ...state });
  };

  const _handleSubmitCode = async () => {
    const { step } = state;
    setState({ ...state, loading: true });

    if (step === 1) {
      const validations: any = await validate(EmailSchema, state.inputs);

      if (validations) {
        setState({ ...state, validations, loading: false });
        return;
      }

      try {
        await sessionService.forgotPasswordPublic({ email: state.inputs.email });
        setState({ ...state, step: 2, validations: {}, loading: false });
        toast.success('Se ha enviado un código de confirmación a tu email.');
      } catch (e: any) {
        setState({ ...state, validations: {}, loading: false });
        toast.error("No fue posible enviar el codigo de confirmación.");
      }
    }

    if (step === 2) {
      const validations: any = await validate(RecoverySchema, state.inputs);

      if (validations) {
        setState({ ...state, validations, loading: false });
        return;
      }

      if (!regexCognito(state.inputs.password)) {
        setState({ ...state, validations: { password: ['La contraseña debe tener al menos 10 caracteres, 1 letra mayúscula, 1 letra minúscula, 1 carácter especial'] }, loading: false });
        return;
      }

      if (state.inputs.password !== state.inputs.password_c) {
        setState({ ...state, validations: { password: ['Las contraseñas deben ser las mismas'], password_c: ['Las contraseñas deben ser las mismas'] }, loading: false });
        return;
      }

      try {
        await sessionService.confirmNewPasswordPublic({ email: state.inputs.email, password: state.inputs.password, code: state.inputs.code });
        setState({
          ...state,
          step: 1,
          validations: {},
          inputs: {
            email: '',
            code: '',
            password: '',
            password_c: '',
          },
          loading: false,
        });
        history.push('/sign-in?update-password=ok');
      } catch (e: any) {
        setState({ ...state, validations: {}, loading: false });
        console.log(e);
        toast.error('No se pudo cambiar la contraseña. Revisa el código e inténtalo de nuevo.');
      }
    }
  };

  return (
    <div className="container-fluid h-100 component-signin position-relative">
      <div className="row component-signin__header" style={{ height: '15%' }}>
        <Image image={Images.BGSignIn} className="position-absolute top-0 end-0 p-0 m-0 sign-in-image" />
        <div className="col-12 mt-5" style={{ zIndex: 1000 }}>
          <ButtonBack text="Volver" action={state.step === 1 ? () => history.goBack() : () => setState({ ...state, step: 1 })} />
        </div>
      </div>

      <div className="d-flex w-100 justify-content-center position-relative" style={{ height: '85%', zIndex: 100 }}>
        <div className="row component-signin__fields justify-content-between" style={{ height: '100%', maxWidth: '450px' }}>
          <div className="col-12">
            <div className="row">
              <div className="col-12 text-center">
                <Logo className="p-3 w-75" color style={{ maxWidth: '250px' }} />
              </div>
            </div>

            <div className="row mb-5 mt-1">
              <div className="col-12 text-center mt-5">
                <Title size="md" text="Recuperar contraseña" className="color-dark-blue" />
              </div>
            </div>

            <div className="row px-3 mb-5 mt-2">
              <div className="p-0 mb-1 color-black bold size-09">Email</div>
              <div className="col-12 d-flex bg-color-blue-light border p-3 rounded-10">
                <Image image={Images.UserInput} className="me-3" />
                <input
                  type="text"
                  className="input-transparent"
                  placeholder="usuario@copec.cl"
                  style={{ flex: '1' }}
                  name="email"
                  value={state.inputs.email}
                  onChange={_handleOnChange}
                  disabled={state.step === 2}
                />
              </div>
              <ErrorValidation validations={state.validations} name="email" className="text-end size-09 bold" />
            </div>

            {state.step !== 1 && (
              <>
                <div className="row px-3 mb-5 mt-2">
                  <div className="p-0 mb-1 color-black bold size-09">Código</div>
                  <div className="col-12 d-flex bg-color-blue-light border p-3 rounded-10">
                    <Image image={Images.UserInput} className="me-3" />
                    <input type="text" className="input-transparent" maxLength={6} placeholder="XXXXXX" style={{ flex: '1' }} name="code" value={state.inputs.code} onChange={_handleOnChange} />
                  </div>
                  <ErrorValidation validations={state.validations} name="code" className="text-end size-09 bold" />
                </div>
                <div className="row px-3 mb-5 mt-2">
                  <div className="p-0 mb-1 color-black bold size-09">Nueva contraseña</div>
                  <div className="col-12 d-flex bg-color-blue-light border p-3 rounded-10">
                    <Image image={Images.PasswordInput} className="me-3" />
                    <input type="password" className="input-transparent" placeholder="******" style={{ flex: '1' }} name="password" value={state.inputs.password} onChange={_handleOnChange} />
                  </div>
                  <ErrorValidation validations={state.validations} name="password" className="text-end size-09 bold" />
                </div>
                <div className="row px-3 mb-5 mt-2">
                  <div className="p-0 mb-1 color-black bold size-09">Repite nueva contraseña</div>
                  <div className="col-12 d-flex bg-color-blue-light border p-3 rounded-10">
                    <Image image={Images.PasswordInput} className="me-3" />
                    <input type="password" className="input-transparent" placeholder="******" style={{ flex: '1' }} name="password_c" value={state.inputs.password_c} onChange={_handleOnChange} />
                  </div>
                  <ErrorValidation validations={state.validations} name="password_c" className="text-end size-09 bold" />
                </div>
              </>
            )}

            <div className="row mb-4">
              <div className="col-12 color-white">
                <button className="btn-default w-100 py-3 size-11 rounded-10" onClick={_handleSubmitCode} disabled={state.loading}>
                  Enviar
                  {state.loading && <div className="spinner-border ms-3" role="status" style={{ width: '20px', height: '20px' }} />}
                </button>
              </div>
            </div>
          </div>

          <div className="col-12 bold-300 d-flex align-items-center justify-content-center">
            ¿No tienes cuenta?{' '}
            <Link to="/register" className="color-blue-base ms-1">
              {' '}
              Regístrate aquí.
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Recovery;
