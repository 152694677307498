import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Images from '../../assets/image';
import { _containsLatLan } from '../../helpers/check_polygon';
import { set_eds } from '../../redux/actions/available_eds';
import { setDirections } from '../../redux/actions/directions';
import { clear_services } from '../../redux/actions/services';
import { clear_cart } from '../../redux/actions/shopping_cart';
import { set_splash } from '../../redux/actions/splash_screen';
import Image from '../Image';
import Title from '../Title';

const HistoryAddressesMobile = (props: any) => {
  const { addresses, handleClose, closeAndOpen } = props;

  const history: any = useHistory();
  const dispatch: any = useDispatch();
  const _handleSplit = (address: any, target: any = 'first') => {
    const [first, second] = address.split('-');

    if (target === 'first') return first || '';

    return second || '';
  };

  const _handleSearch = async (addressDirection: any) => {
    dispatch(set_splash({ direction_desktop: false, direction: true }));
    handleClose();
    const available_eds = await _containsLatLan(addressDirection.latitude, addressDirection.longitude);

    if (!available_eds) {
      dispatch(set_splash({ direction: false, direction_desktop: false }));
      return;
    }

    dispatch(
      setDirections({
        _string: addressDirection.info.address,
        direction: addressDirection.info.address,
        address: addressDirection.info.address,
        longitude: addressDirection.longitude,
        latitude: addressDirection.latitude,
      })
    );
    
    dispatch(clear_cart());
    dispatch(clear_services());
    dispatch(set_splash({ direction: false, direction_desktop: false }));
    dispatch(set_eds(available_eds));
    history.push('/');
  };

  return (
    <div className="container-fluid mx-0 mb-5 pb-5 p-0">
      <div className="d-flex flex-column justify-content-between" style={{ minHeight: '100vh' }}>
        <div className="w-100 position-relative">
          <Title size="md" text="Direcciones recomendadas" className="color-dark-blue px-3 pt-3 text-center mt-4" />
          <div className="position-absolute color-white" style={{ top: '0px', right: '10px', width: '50px' }} onClick={handleClose}>
            <button className="btn">
              <Image image={Images.CloseBlue} />
            </button>
          </div>

          <div className="w-100 mt-4 d-flex flex-column position-absolute px-3" style={{ zIndex: 1 }}>
            {addresses.map((item: any, index: any) => (
              <div className="col-12 bg-color-blue-light border p-3 rounded-10 mb-2 d-flex shadow-sm" style={{ cursor: 'pointer' }} key={item.title + '-' + index}>
                <Image image={Images.DirectionInput} />
                <button className="w-100 btn d-flex flex-column" onClick={() => _handleSearch(item)}>
                  <div className="bold color-grey text-start">{_handleSplit(item.info?.address)}</div>
                  <div className="size-09 color-grey text-start">{_handleSplit(item.info?.address, 'second')}</div>
                </button>
              </div>
            ))}
          </div>
        </div>

        <div className="w-100">
          <div className="w-100 d-flex justify-content-between align-items-center input-default px-4" style={{ height: '75px' }} onClick={() => closeAndOpen('autocomplete')}>
            <div>
              <i className="bi bi-geo-alt px-2 size-11" />
              Agregar nueva direccion
            </div>
            <Image image={Images.ArrowDown} />
          </div>
          <div className="w-100 d-flex justify-content-between align-items-center input-default px-4" style={{ height: '75px' }} onClick={() => closeAndOpen('manual')}>
            <div>
              <i className="bi bi-geo-alt px-2 size-11" />
              Agregar dirección manualmente
            </div>
            <Image image={Images.ArrowDown} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HistoryAddressesMobile;
