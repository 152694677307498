/* eslint-disable react-hooks/exhaustive-deps */
import { Loader } from '@googlemaps/js-api-loader';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { _getGoogleAddressComponents } from '../../helpers/Utils';
import StepOne from './StepOne';
import StepTwo from './StepTwo';

const DirectionsCreate = (props: any) => {
  const [state, setState] = useState<any>({
    google: null,
    results: [],
    direction: {},
    showMaps: false,
  });

  useEffect(() => {
    if (!state.google) {
      const loader: any = new Loader({
        apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY ? process.env.REACT_APP_GOOGLE_MAPS_API_KEY : '',
        version: 'weekly',
        libraries: ['places', 'geometry'],
        ...{},
      });

      loader.load().then((google: any) => {
        const autocompleteService = new google.maps.places.AutocompleteService();
        setState({ ...state, google, autocompleteService, api_key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY });
      });
    }
  }, []);

  const _handleChange = (e: any) => {
    const { autocompleteService } = state;
    if (e.target.value === '') {
      setState({ ...state, results: [] });
    } else {
      autocompleteService.getPlacePredictions({ input: e.target.value, componentRestrictions: { country: 'cl' } }, _displaySuggestions);
    }
  };

  const _displaySuggestions = (payload: any) => {
    if (payload && payload.length > 0) {
      const results = payload.map((item: any) => {
        let show: any = false;
        let number: any = 0;
        item.terms.forEach((term: any) => {
          if (!isNaN(term.value)) {
            show = true;
            number = term.value;
          }
        });
        const data: any = {
          title: item.structured_formatting.main_text,
          subtitle: item.structured_formatting.secondary_text,
          description: item.description,
          place_id: item.place_id,
          show: show,
          number,
        };
        return data;
      });
      setState({ ...state, results: results.filter((item: any) => item.show) });
    }
  };

  const _handleGeolocation = (payload: any) => {
    const { google } = state;
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ placeId: payload.place_id }, async (response: any, status: any) => {
      if (status === 'OK') {
        if (response.length > 0) {
          const directions = response[0];
          const { address_components, geometry } = directions;
          const { location } = geometry;
          const latitude = location.lat();
          const longitude = location.lng();

          const { streetNumber, street, country, commune_adm_lvl_3, region } = _getGoogleAddressComponents(address_components, payload.number);

          if (!street || !streetNumber) {
            toast.error('No fue posible encontrar la dirección');
            return;
          }

          
          const address: any = `${street?.long_name} ${streetNumber?.long_name}, ${commune_adm_lvl_3?.long_name || ''} - ${region?.long_name || ''} ${country?.long_name || ''}`;
          
          const d = { latitude, longitude, address: address, number: streetNumber.long_name };
          console.log(d)
          setState({ ...state, direction: d, results: [], showMaps: true });
        }
      }
    });
  };

  return <>{state && state.showMaps ? <StepTwo geo={state.direction} /> : <StepOne _handleChange={_handleChange} _handleGeolocation={_handleGeolocation} results={state.results} />}</>;
};
export default DirectionsCreate;
