import { CrossSellingSetupSchema } from '../mockups/cross_selling';
import store from '../redux/store';
import instance from './api_overt';

class Discount {
  _setHeaders = () => {
    const state: any = store.getState();
    const { user } = state;
    if (user.isLogged) {
      instance.defaults.headers.common['user'] = user.profile?.PK;
      instance.defaults.headers.common['usersk'] = user.profile?.SK;
      instance.defaults.headers.common['Authorization'] = user?.session?.AuthenticationResult?.AccessToken;
    } else {
      delete instance.defaults.headers.common['user'];
      delete instance.defaults.headers.common['usersk'];
      delete instance.defaults.headers.common['Authorization'];
    }
  };

  getCrossSellingDiscountData = async () => {
    this._setHeaders();
    try {
      let response: any = await instance.get('overt/cross-selling/config/get');

      if (response?.data?.data) {
        response = response?.data?.data;
      } else {
        response = { ...CrossSellingSetupSchema };
      }
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };
}

const DiscountService = new Discount();
export default DiscountService;
