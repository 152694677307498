/* eslint-disable react-hooks/exhaustive-deps */
import { Loader } from '@googlemaps/js-api-loader';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import Images from '../../assets/image';
import ButtonBack from '../../components/ButtonBack';
import Image from '../../components/Image';
import Title from '../../components/Title';
import { _searchByAddress } from '../../helpers/search_services';
import { END_LOADING, START_LOADING } from '../../redux/actions/loader';
import { set_user } from '../../redux/actions/user';
import sessionService from '../../services/session.service';
import Logo from '../../components/Logo';
import SignPopUp from '../../components/SignPopUp';

const SignIn = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { user, directions } = useSelector((state: any) => state);
  const [state, setState] = useState<any>({
    inputs: {
      email: '',
      password: '',
    },
    loading: false,
    google: null,
    api_key: '',
  });

  const _handleOnChange = ({ target }: any) => {
    const { name, value } = target;
    setState({ ...state, inputs: { ...state.inputs, [name]: value } });
  };

  const _handleSigIn = async () => {
    setState({ ...state, loading: true });
    try {
      const response = await sessionService.signin({ ...state.inputs });
      const accessToken = response.data.AuthenticationResult?.AccessToken;
      const profile: any = await sessionService.getProfile(accessToken);
      const address = await sessionService.getAddressesWithId(response.data.PK, { user: response.data.PK, usersk: response.data.SK, Authorization: response.data?.AuthenticationResult?.AccessToken });

      const { info } = profile.data;

      const permissions = info.roles.find((r: any) => r === 'client_company' || r === 'client' || r === 'user_company');

      if (!permissions) {
        toast.error('Usuario o contraseña incorrecto');
        setState({ ...state, loading: false });
        return;
      }

      if (!info.roles.includes('user_company')) {
        if (directions._string === null) {
          if (address.data.length) {
            let addressDirection: any = address.data.find((add: any) => add.main);
            if (addressDirection) {
              await _searchByAddress({
                google: state.google,
                data: {
                  _string: addressDirection.info.address,
                  number: addressDirection.info.number,
                  observations: addressDirection.info?.observations,
                  apartment: addressDirection.info?.apartment,
                },
              });
            } else {
              await _searchByAddress({
                google: state.google,
                data: {
                  _string: address.data[0].info.address,
                  number: address.data[0].info.number,
                  observations: address.data[0].info?.observations,
                  apartment: address.data[0].info?.apartment,
                },
              });
            }
          }
        }
      }

			const user = {
        isLogged: true,
        session: response.data,
        profile: profile.data,
        created_at: moment().toString(),
      };
      await dispatch(set_user(user));
      setState({ ...state, loading: false });
      
    } catch (e) {
      toast.error('Usuario o contraseña inválidos');
      setState({ ...state, loading: false });
    }
  };

  const _handleSigInGoogle = () => {
    dispatch(START_LOADING());
  };

  useEffect(() => {
    if (!state.google) {
      const loader: any = new Loader({
        apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY ? process.env.REACT_APP_GOOGLE_MAPS_API_KEY : '',
        version: 'weekly',
        libraries: ['places', 'geometry'],
        ...{},
      });

      loader.load().then((google: any) => {
        setState({
          ...state,
          google,
          api_key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        });
      });
    }
  }, [state]);

  useEffect(() => {
    dispatch(END_LOADING());
    if (user && user.isLogged) {
      if(user?.profile?.info?.roles?.includes('user_company')) history.push('/empresa/usuarios');
      if(!user?.profile?.info?.roles?.includes('user_company')) history.push('/');
    }
  }, [user]);

  useEffect(() => {
    const params: any = new URLSearchParams(history.location.search);
    if (params.has('success')) {
      toast.success('Código confirmado con éxito');
      window.history.pushState({}, '', '/sign-in');
    }

    if (params.has('update-password')) {
      toast.success('Contraseña actualizada con éxito');
      window.history.pushState({}, '', '/sign-in');
    }
  }, []);

  return (
    <div className="container-fluid h-100 component-signin position-relative">
      <div className="row component-signin__header" style={{ height: '15%' }}>
        <Image image={Images.BGSignIn} className="position-absolute top-0 end-0 p-0 m-0 sign-in-image" />
        <div className="col-12 mt-5" style={{ zIndex: 1000 }}>
          <ButtonBack text="Volver" action={() => history.push('/')} />
        </div>
      </div>
      <SignPopUp />

      <div className="d-flex w-100 justify-content-center position-relative" style={{ height: '85%' }}>
        <div className="row w-100 component-signin__fields justify-content-between" style={{ height: '100%', maxWidth: '400px' }}>
          <div className="col-12 mt-3">
            <div className="row">
              <div className="col-12 text-center">
                <Logo color className=" p-3 w-75" style={{ maxWidth: '250px' }} />
              </div>
            </div>

            <div className="row">
              <div className="col-12 text-center mt-4">
                <Title size="md" text="Iniciar sesión" className="color-dark-blue" />
              </div>
            </div>

            <div className="row px-3 mb-3">
              <div className="p-0 mb-1 color-black bold size-09">Usuario</div>
              <div className="col-12 d-flex bg-color-blue-light border rounded-30 mb-3" style={{ padding: '10px 20px' }}>
                <Image image={Images.EmailInput} className="me-3" />
                <input type="text" className="input-transparent" placeholder="usuario@copec.cl" style={{ flex: '1' }} onChange={_handleOnChange} name="email" value={state.inputs.email} />
              </div>
            </div>

            <div className="row px-3 mb-4">
              <div className="p-0 mb-1 color-black bold size-09">Contraseña</div>
              <div className="col-12 d-flex bg-color-blue-light border rounded-30 mb-3" style={{ padding: '10px 20px' }}>
                <Image image={Images.PasswordInput} className="me-3" />
                <input
                  type="password"
                  className="input-transparent placeholder-lg"
                  placeholder="········"
                  style={{ flex: '1' }}
                  onChange={_handleOnChange}
                  name="password"
                  value={state.inputs.password}
                />
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-12 color-white">
                <button className="btn-default w-100 py-3 size-11 d-flex justify-content-center align-items-center" onClick={_handleSigIn} disabled={state.loading}>
                  {state.loading && <div className="spinner-border me-1" role="status" style={{ width: '20px', height: '20px' }} />}
                  Iniciar sesión
                </button>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <a href={process.env.REACT_APP_COGNITO_REDIRECT} target={'_self'} rel={'noreferrer'} onClick={_handleSigInGoogle}>
                  <button className="btn w-100 border rounded-10 size-11 py-2 bold d-flex align-items-center justify-content-center">
                    <Image image={Images.GoogleLogo} className="my-2 me-3" />
                    Inicio con Google
                  </button>
                </a>
              </div>
            </div>

            <div className="row mt-2">
              <div className="col-12 text-center mt-2 bold-300">
                <Link to="/recovery" className="color-blue-base">
                  Recuperar contraseña aquí.
                </Link>
              </div>
              <div className="col-12 bold-300 d-flex align-items-center justify-content-center mt-2">
                ¿No tienes cuenta?{' '}
                <Link to="/register" className="color-blue-base ms-1">
                  {' '}
                  <span className="color-blue-base underline">Regístrate aquí.</span>
                </Link>
              </div>
            </div>
          </div>
          <div style={{ height: '300px' }} />
        </div>
      </div>
    </div>
  );
};

export default SignIn;
