/* eslint-disable no-useless-escape */
import * as yup from 'yup'

export const DeliveryThirdSchema = yup.object().shape({
  name_third: yup
    .string()
    .matches(/^[\w\. ?¿¡!^\+-\:()\{\}\[\],;\*#@áàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ]*$/gim, 'Este campo contiene caracteres inválidos')
    .max(255, "Este campo acepta un máximo de 255 caracteres")
    .required('Este campo es requerido'),
  phone_number_third: yup
    .string()
    .min(9, 'El Nº de teléfono debe ser de 9 dígitos')
    .max(9, 'El Nº de teléfono debe ser de 9 dígitos')
    .required('Este campo es requerido')
});

export type DeliveryThirdType = yup.InferType<typeof DeliveryThirdSchema>
