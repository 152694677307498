/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from 'react-redux';
import Drawer from '../Drawer';
import '../../styles/components/component.shopping.cart.scss';
import Title from '../Title';
import Image from '../Image';
import Images from '../../assets/image';
import { Fragment, useEffect } from 'react';
import QuantitySelect from '../QuantitySelect';
import { clear_cart, close_cart, remove_service_from_cart, toggle_cart, update_service_to_cart } from '../../redux/actions/shopping_cart';
import { toaster } from 'rsuite';
import Alert from '../Alert';
import { toast } from 'react-toastify';
import { set_actionbar } from '../../redux/actions/action_bar';
import { clear_services, edit_service, remove_service } from '../../redux/actions/services';
import { addToCartDataLayerEvent, removeFromCartDataLayerEvent } from '../../helpers/data_layer';
import { _slug } from '../../helpers/Utils';
import { set_cache } from '../../redux/actions/cache';
import { LOAD_OFF } from '../../redux/actions/loader';

const ShoppingCart = () => {
  const { matchMedia, available_services, shopping_cart, action_bar, cache } = useSelector((state: any) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    _isNewStateApplied();
  }, [cache]);

  const _isNewStateApplied = () => {
    const currentUrl = window.location.pathname;
    if (cache?.isNewStateApplied) return;
    dispatch(set_cache({ ...cache, isNewStateApplied: true }));
    dispatch(clear_cart());
    dispatch(clear_services());
    if (currentUrl === '/confirmation-code' || currentUrl === '/empresa/confirmation-code') return;

    setTimeout(() => {
      dispatch(LOAD_OFF());
      window.location.href = '/';
    }, 1000);
  };

  const _handleMinusService = (payload: any) => {
    const service = shopping_cart.services.find((ser: any) => ser.id === payload.id);
    const { related_products } = service;
    const product = related_products.find((rp: any) => rp.subservice_product);
    if (product) {
      if (service.service.quantity > 1) {
        if (product.quantity === service.service.quantity) {
          service.service.quantity -= 1;
          product.quantity -= 1;
          removeFromCartDataLayerEvent({ ...service.service, quantity: 1 }, _slug(service.name), 'sub_service', 'cart_items_list');
          removeFromCartDataLayerEvent({ ...product, quantity: 1 }, _slug(service.name), 'related_product', 'cart_items_list');
        } else {
          service.service.quantity -= 1;
          removeFromCartDataLayerEvent({ ...service.service, quantity: 1 }, _slug(service.name), 'sub_service', 'cart_items_list');
        }
      }
    } else {
      if (service.service.quantity > 1) {
        service.service.quantity -= 1;
        removeFromCartDataLayerEvent({ ...service.service, quantity: 1 }, _slug(service.name), 'sub_service', 'cart_items_list');
      }
    }
    dispatch(update_service_to_cart({ ...service }));
    dispatch(edit_service({ ...service }));
  };

  const _handlePlusService = (payload: any) => {
    const service = shopping_cart.services.find((ser: any) => ser.id === payload.id);
    service.service.quantity += 1;
    addToCartDataLayerEvent(service.service, _slug(service.name), 'sub_service', 'cart_items_list');
    dispatch(update_service_to_cart({ ...service }));
    dispatch(edit_service({ ...service }));
  };

  const _handleMinusProduct = (payload: any) => {
    const service = shopping_cart.services.find((ser: any) => ser.id === payload.service.id);
    const { related_products } = service;
    const product = related_products.find((rp: any) => rp.SK === payload.product.SK);
    if (product.quantity > 1) {
      product.quantity -= 1;
      removeFromCartDataLayerEvent({ ...product, quantity: 1 }, _slug(service.name), 'related_product', 'cart_items_list');
    }
    dispatch(update_service_to_cart({ ...service }));
    dispatch(edit_service({ ...service }));
  };

  const _handlePlusProduct = (payload: any) => {
    const service = shopping_cart.services.find((ser: any) => ser.id === payload.service.id);
    const { related_products } = service;
    const product = related_products.find((rp: any) => rp.SK === payload.product.SK);
    if (product && product.subservice_product) {
      if (product.quantity < service.service.quantity) {
        product.quantity += 1;
        addToCartDataLayerEvent(product, _slug(service.name), 'related_product', 'cart_items_list');
      } else {
        toast.error('No si puede agregar mas bidones que kerosene...');
      }
    } else {
      product.quantity += 1;
      addToCartDataLayerEvent(product, _slug(service.name), 'related_product', 'cart_items_list');
    }
    dispatch(update_service_to_cart({ ...service }));
    dispatch(edit_service({ ...service }));
  };

  const _handleDeleteService = (payload: any) => {
    const service = shopping_cart.services.find((shoppingcart_service: any) => shoppingcart_service.id === payload.id);

    if (service && service.related_products) {
      service.related_products.forEach((item: any) => {
        removeFromCartDataLayerEvent(item, _slug(service.name), 'related_product', 'cart_items_list');
      });
    }

    if (service) {
      removeFromCartDataLayerEvent(service.service, _slug(service.name), 'related_product', 'cart_items_list');
      dispatch(remove_service_from_cart({ ...service }));
      dispatch(remove_service({ ...service }));
    }
  };

  const _handleDeleteProduct = ({ product, service }: any) => {
    const serviceData = shopping_cart.services.find((ser: any) => ser.id === service.id);
    const { related_products } = serviceData;
    const index = related_products.findIndex((rp: any) => rp.SK === product.SK);
    const relatedProduct = related_products.find((rp: any) => rp.PK === product.PK);
    if (relatedProduct) {
      removeFromCartDataLayerEvent(relatedProduct, _slug(service.name), 'related_product', 'cart_items_list');
      related_products.splice(index, 1);
      dispatch(update_service_to_cart({ ...serviceData }));
      dispatch(edit_service({ ...serviceData }));
    }
  };

  const _showConfirmDelete = (payload: any) => {
    const Message = (
      <Alert type="danger" duration={0} closable>
        <div className="row p-3">
          <div className="col-10 bold">
            <div>¿Te arrepentiste?</div>
            <div>Si, deseo eliminar este producto.</div>
          </div>
          <div className="col-2  d-flex justify-content-center align-items-center">
            <button onClick={() => _handleDelete(payload)} className="btn color-white size-15">
              <i className="bi bi-trash"></i>
            </button>
          </div>
        </div>
      </Alert>
    );
    toaster.push(Message, { placement: 'topEnd' });
  };

  const _handleDelete = (payload: any) => {
    const { product, service, event } = payload;
    switch (event) {
      case 'service':
        _handleDeleteService(service);
        toaster.clear();
        break;
      default:
        _handleDeleteProduct({ product, service });
        toaster.clear();
        break;
    }
  };

  const _handleModalServices = () => {
    dispatch(close_cart());
    available_services.sort((a: any, b: any) => (a.name > b.name ? 1 : -1));
    if (matchMedia.media === 'xs') {
      dispatch(set_actionbar({ ...action_bar, unselected_services: available_services, show: true }));
    } else {
      dispatch(set_actionbar({ ...action_bar, unselected_services: available_services, modal_open: true }));
    }
  };

  const _handleEditService = (service: any) => {
    dispatch(close_cart());
    setTimeout(() => {
      if(service.type === 'subscription') window.location.href = `/services/${service.slug}`;
      if(service.type !== 'subscription') window.location.href = `/services/${service.slug}?id=${service.id}`;
    }, 300);
  };

  return (
    <Drawer className="max-width" direction="right-left" show={shopping_cart.open} style={{ zIndex: 1001, borderLeft: '2px solid #203391' }}>
      <div className="component-shopping-cart">
        <div className="row justify-content-between my-4 px-3">
          <div className="col d-flex justify-content-start align-items-center">
            <Title size="md" text="Carro de Compras" className="color-dark-blue d-flex justify-content-center align-items-center mt-1" />
          </div>
          <div className="col-2 d-flex justify-content-end align-items-center">
            <button className="btn" onClick={() => dispatch(toggle_cart())}>
              <Image image={Images.CloseBlue} />
            </button>
          </div>
        </div>

        {shopping_cart?.services?.length === 0 && (
          <div className="w-100 d-flex flex-column align-items-center justify-content-center mt-5">
            <div className="col-12 mt-5 px-5">
              <Image className="w-100" image={Images.EmptyCart} />
            </div>
            <Title size="md" text="Carro Vacío!" className="color-blue-base d-flex justify-content-center align-items-center mt-1 size-11" />
          </div>
        )}

        {/* Loop products */}
        {shopping_cart?.services?.map((service: any, index: number) => (
          <Fragment key={`service-${service.id}`}>
            <div className="row line-border p-3 mt-3 align-items-center">
              {service.service && (
                <>
                  <div className="col-3 text-center">
                    <Image
                      className="w-75 rounded-10"
                      image={
                        service.service?.info?.gallery?.url
                          ? {
                              default: {
                                alt: 'thumb',
                                src: service.service?.info?.gallery?.url,
                              },
                            }
                          : Images.NotFound
                      }
                    />
                  </div>
                  <div className="col-6 size-08 color-black bold">
                    {service.car ? (
                      <>
                        <div>{service.service?.name}</div>
                        <div className="ms-1 size-09 bold-300 color-grey">Patente: {service.car.plate?.toUpperCase()}</div>
                        <div className="ms-1 size-09 bold-300 color-grey">Modelo: {service.car.model?.label?.toUpperCase()}</div>
                      </>
                    ) : (
                      <>{service.service.name}</>
                    )}
                  </div>
                  <div className="col-3 color-black size-09">
                    <div>${service.service.price_total?.toLocaleString('es-CL')}</div>
                  </div>
                  <div className="col-12 d-flex justify-content-between mt-3">
                    <div>
                      <button
                        className="btn color-blue-base size-08"
                        onClick={() =>
                          _showConfirmDelete({
                            product: {},
                            service,
                            event: 'service',
                          })
                        }
                      >
                        Eliminar
                      </button>

                      {service.type === 'subscription' || !service.service.quantity ? (
                        <button className="btn color-blue-base size-08" onClick={() => _handleEditService(service)}>
                          Editar
                        </button>
                      ) : null}
                    </div>

                    {service.type !== 'subscription' && service.service.quantity > 0 && <QuantitySelect quantity={service.service.quantity} onAdd={() => _handlePlusService(service)} onRemove={() => _handleMinusService(service)} />}
                  </div>
                </>
              )}
            </div>

            {/* Loop related products */}

            {service.related_products &&
              service.related_products.map((relatedProduct: any) => (
                <div className="row line-border p-3 mt-3 align-items-center" key={`${service.id}-${relatedProduct.SK}`}>
                  <div className="col-3 text-center">
                    <Image
                      className="w-75 rounded-10"
                      image={
                        relatedProduct.info?.gallery?.url
                          ? {
                              default: {
                                alt: 'thumb',
                                src: relatedProduct.info?.gallery?.url,
                              },
                            }
                          : Images.NotFound
                      }
                    />
                  </div>
                  <div
                    className="col-6 size-08 color-black bold"
                    dangerouslySetInnerHTML={{
                      __html: relatedProduct.title || relatedProduct._html || relatedProduct.nombre || relatedProduct.name,
                    }}
                  />
                  <div className="col-3 color-black size-09">
                    <div>${relatedProduct.price_total?.toLocaleString('es-CL')}</div>
                  </div>
                  <div className="col-12 d-flex justify-content-between mt-3">
                    <button
                      className="btn color-blue-base size-08"
                      onClick={() =>
                        _showConfirmDelete({
                          service: service,
                          product: relatedProduct,
                          event: 'product',
                        })
                      }
                    >
                      Eliminar
                    </button>

                    {relatedProduct.quantity && !relatedProduct.oneItem && !relatedProduct.info?.air_filter && (
                      <QuantitySelect
                        quantity={relatedProduct.quantity}
                        onAdd={() =>
                          _handlePlusProduct({
                            service: service,
                            product: relatedProduct,
                          })
                        }
                        onRemove={() =>
                          _handleMinusProduct({
                            service: service,
                            product: relatedProduct,
                          })
                        }
                      />
                    )}
                  </div>
                </div>
              ))}
          </Fragment>
        ))}

        {shopping_cart?.services.length > 0 && (
          <>
            <div className="row line-border px-4 py-4 color-black">
              <div className="col">Subtotal</div>
              <div className="col text-end bold">${shopping_cart.total_without_discount?.toLocaleString('es-CL')}</div>
            </div>

            <div className="row my-5 pb-5 px-5">
              <button className="btn-default p-3 size-11 rounded-10" onClick={_handleModalServices}>
                Ir a pagar
              </button>
            </div>
          </>
        )}
      </div>
    </Drawer>
  );
};

export default ShoppingCart;
