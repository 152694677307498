import { useSelector } from 'react-redux';

const SetTemplate = (Template: any, ComponentMobile: any, ComponentDesktop: any = null, url: any = null) => {
  const { matchMedia } = useSelector((state: any) => state);
  const { media } = matchMedia;

  if (media !== 'xs' && ComponentDesktop !== null) {
    return (
      <Template url={url}>
        <ComponentDesktop />
      </Template>
    );
  }

  return (
    <Template url={url}>
      <ComponentMobile />
    </Template>
  );
};

export default SetTemplate;
