import { handleActions } from 'redux-actions';
import { cartCalculations } from '../../helpers/cart_calculations';
import { apply_cupons, clear_cupons } from '../../helpers/handler_cupons';

import { TOOGLE_CART, CLOSE_CART, CLEAR_CART, SET_CUPON, CLEAR_CUPON, ADD_SERVICE_TO_CART, REMOVE_SERVICE_TO_CART, UPDATE_SERVICE_TO_CART, UPDATE_SHOPPINGCART } from '../actions/shopping_cart';

export const INITIAL_STATE = {
  open: false,
  total: null,
  cupon: null,
  cross_selling: null,
  payment_method: null,
  delivery: null,
  services: [],
  discountRules: null,
};

const sortStateServices: any = (state: any) => {
  state.services?.map((service: any) => {
    service.related_products?.sort((a: any, b: any) => {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    });

    service.related_products?.sort((a: any) => {
      if (a.oneItem) return -1;
      if (!a.oneItem) return 1;
      return 0;
    });

    return service;
  });

  return state;
};

export default handleActions(
  {
    [TOOGLE_CART]: (state: any) => {
      return { ...state, open: !state.open };
    },
    [CLOSE_CART]: (state: any) => {
      return { ...state, open: false };
    },
    [CLEAR_CART]: () => {
      return {
        open: false,
        total: null,
        cupon: null,
        cross_selling: null,
        payment_method: null,
        delivery: null,
        services: [],
        discountRules: null,
      };
    },
    /**
     * NEW SHOPPING CART MODULE
     */

    [ADD_SERVICE_TO_CART]: (state: any, { payload }: any) => {
      state.services.push(payload);
      state = apply_calculations(state);
      return { ...state };
    },
    [REMOVE_SERVICE_TO_CART]: (state: any, { payload }: any) => {
      const index = state.services.findIndex((service: any) => service.id === payload.id);
      if (index !== -1) {
        state.services.splice(index, 1);
        state = apply_calculations(state);
        return { ...state };
      }
    },
    [UPDATE_SERVICE_TO_CART]: (state: any, { payload }: any) => {
      const index = state.services.findIndex((service: any) => service.id === payload.id);
      if (index !== -1) {
        state.services[index] = payload;
        state = apply_calculations(state);
        return { ...state };
      }
    },
    [UPDATE_SHOPPINGCART]: (state: any, { payload }: any) => {
      state = apply_calculations({ ...state, ...payload });
      return { ...state };
    },
    [SET_CUPON]: (state: any, { payload }: any) => {
      if (payload) {
        const { data } = payload;
        if (data.length > 0) state.cupon = data[0];
        state = apply_calculations(state);
      } else {
        state.cupon = null;
        state = apply_calculations(state);
      }
      return { ...state };
    },
    [CLEAR_CUPON]: (state: any) => {
      state.cupon = null;
      let values = cartCalculations({ ...state });
      state = sortStateServices(state);
      state = clear_cupons(state);
      values = cartCalculations(state);
      return { ...state, ...values };
    },
  },
  INITIAL_STATE
);

const apply_calculations = (state: any) => {
  let values = cartCalculations(state);

  state.fullPointsCopec = 0;

  if (state.cupon) {
    const data: any = { ...values, cross_selling: state.cross_selling };
    const cupons: any = { ...state.cupon };
    const cuponResponse: any = apply_cupons(data, cupons);
    if (!cuponResponse) {
      state.cupon = null;
      state = clear_cupons(state);
      values = cartCalculations(state);
    } else {
      state = { ...state, ...cuponResponse };
      state = sortStateServices(state);
      state = _calcPointsFullCopec({ ...state });
      return { ...state };
    }
  }
  state = sortStateServices(state);
  state = _calcPointsFullCopec({ ...state, ...values });
  return { ...state };
};

const _calcPointsFullCopec = (state: any) => {
  state.fullPointsCopec = 0;
  state.services?.forEach((service: any) => {
    const isBaseDivisionSeted = service.info?.base_division && parseInt(service.info?.base_division) > 0;
    const isPointPerPartSeted = service.service?.info?.points_per_division && parseInt(service.service?.info?.points_per_division) > 0;

    service.pointsFullCopec = 0;
    if (isBaseDivisionSeted && isPointPerPartSeted) {

      const base_division = parseInt(service.info?.base_division);
      const points_per_division = parseInt(service.service?.info?.points_per_division);
      const quantity = service.service?.quantity || 1;
      const amount_discount = service.service?.amount_discount || 0;

      const amount = service.service?.price * quantity - amount_discount;
      const points = (amount / base_division) * points_per_division;

      state.fullPointsCopec += Math.floor(points);
      service.pointsFullCopec = Math.floor(points);
    }
  });

  return state;
};
