/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react';
import InputMask from 'inputmask';
import Images from '../../assets/image';
import Image from '../../components/Image';
import { validateRut } from '../../helpers/Utils';
import validate from '../../helpers/validate';
import { SignUpCognitoSchema } from '../../validations/signup.cognito.validations';
import ErrorValidation from '../../components/ErrorValidation';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { END_LOADING, START_LOADING } from '../../redux/actions/loader';
import moment from 'moment';
import { clear_user, set_user } from '../../redux/actions/user';
import { toast } from 'react-toastify';
import sessionService from '../../services/session.service';
import ButtonBack from '../../components/ButtonBack';
import { _searchByAddress } from '../../helpers/search_services';
import { Loader } from '@googlemaps/js-api-loader';
import Logo from '../../components/Logo';

const SignInCognito = () => {
  const [state, setState] = useState<any>({ inputs: { email: 'fnascimento@gux.tech', name: '', family_name: '', phone_number: '', rut: '', terms: false }, loading: false, access_token: false, isValid: true });
  const [validations, setValidations] = useState<any>({});
  const { user, directions } = useSelector((state: any) => state);
  const phoneRef: any = useRef(null);
  const history: any = useHistory();
  const searchParams: any = new URLSearchParams(history?.location?.search);
  const dispatch: any = useDispatch();

  useEffect(() => {
    if (!searchParams.has('code')) {
      dispatch(END_LOADING());
      setState({ ...state, isValid: false });
      return;
    } else {
      _handleSigIn();
      setState({ ...state, isValid: true });
    }
    InputMask({
      mask: '999999999',
      casing: 'upper',
      placeholder: '_',
      showMaskOnFocus: false,
      showMaskOnHover: false,
      jitMasking: true,
    }).mask(phoneRef.current);
  }, []);

  useEffect(() => {
    if (!state.google) {
      const loader: any = new Loader({
        apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY ? process.env.REACT_APP_GOOGLE_MAPS_API_KEY : '',
        version: 'weekly',
        libraries: ['places', 'geometry'],
        ...{},
      });

      loader.load().then((google: any) => {
        setState({
          ...state,
          google,
          api_key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        });
      });
    }
  }, [state]);

  const _handleOnChange = ({ target }: any) => {
    const { name, value, checked } = target;
    const selectedValue: any = name === 'terms' ? checked : value;
    state.inputs[name] = selectedValue;
    if (validations[name]) {
      delete validations[name];
      setValidations({ ...validations });
    }
    setState({ ...state });
  };

  const _handleSigIn = async () => {
    dispatch(START_LOADING());
    try {
      const response = await axios.get(`${process.env.REACT_APP_COGNITO_AUTH}${searchParams.get('code')}`);

      const profile: any = response.data;

      if (profile && profile.length && profile[0]?.error) {
        dispatch(clear_user());
        throw new Error('No se pudo iniciar sesión con google');
      }

      const { info } = profile[1];
      const permissions = info.roles.find((r: any) => r === 'client');
      if (!permissions) {
        dispatch(END_LOADING());
        setState({ ...state, isValid: false });
        throw new Error('No se pudo iniciar sesión con google');
      }

      const user = {
        isLogged: true,
        session: {
          ...profile[1],
          AuthenticationResult: {
            AccessToken: profile[0]?.access_token,
            RefreshToken: profile[0]?.refresh_token,
            TokenType: profile[0]?.token_type,
            IdToken: profile[0]?.id_token,
          },
        },
        profile: profile[1],
        created_at: moment().toString(),
      };

      setState({ ...state, user, inputs: { ...profile[1] } });

      if (user.profile?.name && user.profile?.family_name && user.profile?.rut && user.profile?.phone_number) {
        const loader: any = new Loader({
          apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY ? process.env.REACT_APP_GOOGLE_MAPS_API_KEY : '',
          version: 'weekly',
          libraries: ['places', 'geometry'],
          ...{},
        });

        const google: any = await loader.load();

        if (directions._string === null) {
          const address = await sessionService.getAddressesWithId(profile[1].PK, { user: user.profile.PK, usersk: user.profile.SK, Authorization: user.session?.AuthenticationResult?.AccessToken });
          if (address.data.length) {
            let addressDirection: any = address.data.find((add: any) => add.main);
            if (addressDirection) {
              await _searchByAddress({
                google: google,
                data: {
                  _string: addressDirection.info.address,
                  number: addressDirection.info.number,
                  observations: addressDirection.info?.observations,
                  apartment: addressDirection.info?.apartment,
                },
              });
            } else {
              await _searchByAddress({
                google: google,
                data: {
                  _string: address.data[0].info.address,
                  number: address.data[0].info.number,
                  observations: address.data[0].info?.observations,
                  apartment: address.data[0].info?.apartment,
                },
              });
            }
          }
        }

        dispatch(set_user(user));
        dispatch(END_LOADING());

        history.push('/');
      }

      dispatch(END_LOADING());
    } catch (e: any) {
      dispatch(END_LOADING());
      toast.error(e.message);
      setState({ ...state, isValid: false });
    }
  };

  const _handleConfirmData = async () => {
    let validations: any = await validate(SignUpCognitoSchema, state.inputs);

    if (!validateRut(state.inputs.rut)) {
      if (typeof validations === 'boolean') {
        validations = { rut: ['El rut no es válido'] };
      } else {
        validations.rut = ['El rut no es válido'];
      }
    }

    if (!state.inputs.terms) {
      if (typeof validations === 'boolean') {
        validations = { terms: ['Acepta los términos y condiciones antes de continuar'] };
      } else {
        validations.terms = ['Acepta los términos y condiciones antes de continuar'];
      }
    }

    setValidations(validations);

    if (validations) return;

    setState({ ...state, loading: true });

    try {
      await sessionService.updateProfile({ ...state.inputs }, { user: state.inputs.PK, usersk: state.inputs.SK, Authorization: state.user?.session?.AuthenticationResult.AccessToken });
      state.user.profile = { ...user.profile, ...state.inputs };
      dispatch(set_user(state.user));
      toast.success('Usuario registrado con éxito.');
    } catch (e: any) {
      toast.success('No se ha podido actualizar los datos del usuario. Por favor ingrese a su perfil y actualice tus datos.');
    }

    history.push('/');
  };

  const _handleSignOut = () => {
    dispatch(clear_user());
    history.push('/sign-in');
  };

  if (!state.isValid) {
    return (
      <div className="container-fluid h-100 component-signin position-relative">
        <div className="px-3" style={{ maxWidth: '400px', margin: '0 auto' }}>
          <div className="row component-signin__header" style={{ height: '15%' }}>
            <Image image={Images.BGSignIn} className="position-absolute top-0 end-0 p-0 m-0 sign-in-image" />
          </div>

          <div className="row mt-5">
            <div className="col-12 text-center">
              <Logo color className=" p-3 w-75" style={{ maxWidth: '250px' }} />
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-12 text-center color-dark-blue bold-300 size-12">¡Lo siento! Ha ocurrido algo.</div>
            <div className="col-12 text-center color-dark-blue bold-300 size-12">No se ha podido iniciar sesión con Google.</div>
          </div>

          <div className="row mb-4 mt-5">
            <div className="col-12 color-white">
              <button className="btn-default w-100 py-3 size-11 d-flex justify-content-center align-items-center" onClick={() => history.push('/sign-in')}>
                Volver al inicio
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container-fluid h-100 component-signin position-relative mb-5">
      <div className="row pt-5 px-0">
        <div className="col-12">
          <ButtonBack text="volver" action={_handleSignOut} />
        </div>
      </div>

      <div className="px-3" style={{ maxWidth: '400px', margin: '0 auto' }}>
        <div className="row component-signin__header" style={{ height: '15%' }}>
          <Image image={Images.BGSignIn} className="position-absolute top-0 end-0 p-0 m-0 sign-in-image" />
        </div>

        <div className="row mt-2">
          <div className="col-12 text-center">
            <Logo color className=" p-3 w-75" style={{ maxWidth: '250px' }} />
          </div>
        </div>

        <div className="row px-3 mb-3 mt-5">
          <div className="col-12 color-dark-blue bold-300 text-center mb-4 size-12">Completa tus datos.</div>
          <div className="p-0 mb-1 color-black bold size-09">Usuario</div>
          <div className="col-12 d-flex bg-color-blue-light border rounded-30 mb-3" style={{ padding: '10px 20px' }}>
            <Image image={Images.EmailInput} className="me-3" />
            <input type="text" className="input-transparent" placeholder="Ingresa tu email" style={{ flex: '1' }} onChange={_handleOnChange} name="email" value={state.inputs.email} disabled />
          </div>
          <ErrorValidation validations={validations} name="email" className="size-09 bold-300 text-end" />
        </div>

        <div className="row px-3 mb-3">
          <div className="p-0 mb-1 color-black bold size-09">Nombre</div>
          <div className="col-12 d-flex bg-color-blue-light border rounded-30 mb-3" style={{ padding: '10px 20px' }}>
            <Image image={Images.UserInput} className="me-3" />
            <input type="text" className="input-transparent" placeholder="Ingresa tu nombre" style={{ flex: '1' }} onChange={_handleOnChange} name="name" value={state.inputs.name} />
          </div>
          <ErrorValidation validations={validations} name="name" className="size-09 bold-300 text-end" />
        </div>

        <div className="row px-3 mb-3">
          <div className="p-0 mb-1 color-black bold size-09">Apellido</div>
          <div className="col-12 d-flex bg-color-blue-light border rounded-30 mb-3" style={{ padding: '10px 20px' }}>
            <Image image={Images.UserInput} className="me-3" />
            <input type="text" className="input-transparent" placeholder="Ingresa tu apellido" style={{ flex: '1' }} onChange={_handleOnChange} name="family_name" value={state.inputs.family_name} />
          </div>
          <ErrorValidation validations={validations} name="family_name" className="size-09 bold-300 text-end" />
        </div>

        <div className="row px-3 mb-3">
          <div className="p-0 mb-1 color-black bold size-09">Teléfono</div>
          <div className="col-12 d-flex bg-color-blue-light border rounded-30 mb-3" style={{ padding: '10px 20px' }}>
            <Image image={Images.PhoneInput} className="me-3" />
            <div className="bold me-1">+56</div>
            <input
              ref={phoneRef}
              type="text"
              className="input-transparent"
              placeholder="Ingresa tu teléfono"
              style={{ flex: '1' }}
              onChange={_handleOnChange}
              name="phone_number"
              value={state.inputs.phone_number}
            />
          </div>
          <ErrorValidation validations={validations} name="phone_number" className="size-09 bold-300 text-end" />
        </div>

        <div className="row px-3 mb-3">
          <div className="p-0 mb-1 color-black bold size-09">Rut</div>
          <div className="col-12 d-flex bg-color-blue-light border rounded-30 mb-3" style={{ padding: '10px 20px' }}>
            <input type="text" className="input-transparent" placeholder="Ingresa tu rut sin puntos y con guión" style={{ flex: '1' }} onChange={_handleOnChange} name="rut" value={state.inputs.rut} />
          </div>
          <ErrorValidation validations={validations} name="rut" className="size-09 bold-300 text-end" />
        </div>

        <div className="row mb-4">
          <div className="col-12">
            <input type="checkbox" name="terms" id="terms" className='me-2' checked={state.inputs.terms} onChange={_handleOnChange} />
            <label htmlFor="terms"> Acepto los <a href="/terminos-y-condiciones" target="_blank"><b>Términos y Condiciones</b></a></label>
          </div>
          <ErrorValidation validations={validations} name="terms" className="size-09 bold-300 text-end" />
        </div>

        <div className="row mb-4 mt-5">
          <div className="col-12 color-white">
            <button className="btn-default w-100 py-3 size-11 d-flex justify-content-center align-items-center" onClick={_handleConfirmData} disabled={state.loading}>
              {state.loading && <div className="spinner-border me-1" role="status" style={{ width: '20px', height: '20px' }} />}
              Confirmar datos
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignInCognito;
