import { useSelector } from 'react-redux';
import ErrorValidation from '../ErrorValidation';

const Building = (props: any) => {
  const { onChange, validations } = props;
  const { shopping_cart } = useSelector((state: any) => state);
  const showCheckbox: any = shopping_cart?.services?.find((service: any) => service.module !== 'kerosene' && service.module !== 'kerosene-schedule');
  return (
    <>
      {/* DESKTOP */}
      <div className="m-0 p-0  d-none d-sm-block">
        {showCheckbox ? (
          <div className="col-12 mb-3">
            <div className="col px-3 py-2">
              <input type="checkbox" name="keys" onChange={(e: any) => onChange(e, 'i_will_receive')} />
              <label className="ms-3 bold-300" htmlFor="keys">
                Dejaré las llaves en conserjería.
              </label>
            </div>
          </div>
        ) : null}

        {showCheckbox ? (
          <div className="col-12 mb-3">
            <div className="color-black bold mb-2">Indicanos tu estacionamiento</div>
            <div className="col bg-color-blue-light px-3 py-2 border-default rounded-20">
              <input className="w-100 p-2 input-transparent size-09" type="text" placeholder="Ej: Estacionamiento 314" name="parking" onChange={(e: any) => onChange(e, 'i_will_receive')} />
            </div>
            <ErrorValidation validations={validations} name="parking" className="size-09 text-end bold-300" />
          </div>
        ) : null}

        <div className="col-12 mb-3">
          <div className="color-black bold mb-2">Descripción adicional</div>
          <div className="col bg-color-blue-light px-3 py-2 border-default rounded-20">
            <input className="w-100 p-2 input-transparent size-09" type="text" placeholder="Ej: Casa B / departamento 1201" name="apartment" onChange={(e: any) => onChange(e, 'i_will_receive')} />
          </div>
          <ErrorValidation validations={validations} name="apartment" className="size-09 text-end bold-300" />
        </div>

        <div className="col-12 mb-3">
          <div className="color-black bold mb-2">Observaciones</div>
          <div className="col bg-color-blue-light px-3 py-2 border-default rounded-20">
            <textarea
              className="w-100 p-2 input-transparent size-09 remove-chrome-shadow"
              placeholder="Ej: Dejar en conserjeria"
              rows={4}
              name="observations"
              onChange={(e: any) => onChange(e, 'i_will_receive')}
            />
          </div>
          <ErrorValidation validations={validations} name="observations" className="size-09 text-end bold-300" />
        </div>
      </div>

      {/* MOBILE */}

      <div className="d-sm-none m-0 p-0">
        {showCheckbox ? (
          <div className="row flex-column px-4 mb-3 ">
            <div className="col px-3 py-1">
              <input type="checkbox" name="keys" onChange={(e: any) => onChange(e, 'i_will_receive')} />
              <label className="ms-3 color-grey" htmlFor="keys">
                Dejaré las llaves en conserjería.
              </label>
            </div>
          </div>
        ) : null}

        {showCheckbox ? (
          <div className="row flex-column px-4 mb-3 ">
            <div className="col ps-1 mb-1 color-black bold size-09">Indicanos tu estacionamiento</div>
            <div className="col bg-color-blue-light px-3 py-1 border-default rounded-10">
              <input className="w-100 p-2 input-transparent size-09" type="text" placeholder="Ej: Estacionamiento 314" name="parking" onChange={(e: any) => onChange(e, 'i_will_receive')} />
            </div>
            <ErrorValidation validations={validations} name="parking" className="size-09 text-end bold-300" />
          </div>
        ) : null}

        <div className="row flex-column px-4 mb-3 ">
          <div className="col ps-1 mb-1 color-black bold size-09">Descripción adicional</div>
          <div className="col bg-color-blue-light px-3 py-1 border-default rounded-10">
            <input className="w-100 p-2 input-transparent size-09" type="text" placeholder="Ej: Casa B / departamento 1201" name="apartment" onChange={(e: any) => onChange(e, 'i_will_receive')} />
          </div>
          <ErrorValidation validations={validations} name="apartment" className="size-09 text-end bold-300" />
        </div>

        <div className="row flex-column px-4 mb-3">
          <div className="col ps-1 mb-1 color-black bold size-09">Observaciones</div>
          <div className="col bg-color-blue-light px-3 py-1 border-default rounded-10">
            <textarea
              className="w-100 p-2 input-transparent size-09 remove-chrome-shadow"
              placeholder="Ej: Dejar en conserjeria"
              rows={4}
              name="observations"
              onChange={(e: any) => onChange(e, 'i_will_receive')}
            />
          </div>
          <ErrorValidation validations={validations} name="observations" className="size-09 text-end bold-300" />
        </div>
      </div>
    </>
  );
};

export default Building;
