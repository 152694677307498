/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import InputMask from 'inputmask';
import Title from '../../components/Title';
import { LOAD_OFF, LOAD_ON } from '../../redux/actions/loader';
import { set_user } from '../../redux/actions/user';
import sessionService from '../../services/session.service';
import validate from '../../helpers/validate';
import { UserUpdateSchema } from '../../validations/user.update.validations';
import { validateRut } from '../../helpers/Utils';
import ErrorValidation from '../../components/ErrorValidation';

const UpdateProfile = (props: any) => {
  const [state, setState] = useState<any>({
    inputs: {
      name: '',
      family_name: '',
      email: '',
      phone_number: '',
    },
    validations: {},
  });

  const dispatch = useDispatch();
  const rutRef: any = useRef(null);
  const phoneRef: any = useRef(null);
  const { user } = useSelector((state: any) => state);

  useEffect(() => {
    setState({ ...state, inputs: { ...user.profile } });
    InputMask({
      mask: '9{1,2}.9{3}.9{3}-(K|k|9)',
      casing: 'upper',
      numericInput: true,
      placeholder: '_',
      showMaskOnFocus: false,
      showMaskOnHover: false,
      jitMasking: true,
    }).mask(rutRef.current);

    InputMask({
      mask: '+56 999999999',
      casing: 'upper',
      placeholder: '_',
      showMaskOnFocus: false,
      showMaskOnHover: false,
      jitMasking: true,
    }).mask(phoneRef.current);
  }, [user]);

  const _handleOnChange = ({ target }: any) => {
    
    const { name, value } = target;
    setState({ ...state, inputs: { ...state.inputs, [name]: value } });
  };

  const _handleUpdateProfile = async () => {
    let validationsProfile: any = false;
    state.inputs.phone_number = state.inputs.phone_number.replace(' ', '');
    validationsProfile = await validate(UserUpdateSchema, state.inputs);

    if (!validateRut(state.inputs.rut)) {
      validationsProfile = validationsProfile === false ? {} : validationsProfile;
      validationsProfile.rut = ['El rut no es válido'];
    }

    if (validationsProfile) {
      setState({ ...state, validations: validationsProfile });
      return;
    }
    setState({ ...state, validations: {} });
    dispatch(LOAD_ON());

    try {
      await sessionService.updateProfile(state.inputs);
      setState({ ...state, disabled: true, validations: {} });
      user.profile = { ...user.profile, ...state.inputs };
      dispatch(set_user(user));
      toast.success('Perfil actualizado con éxito.');
    } catch (e: any) {
      console.log('LOGGER', e);
      toast.error(e.message);
    }
    dispatch(LOAD_OFF());
  };

  return (
    <div className="container-fluid mt-5">
      <div className="w-100 d-flex justify-content-center">
        <div className="row px-3 mb-3" style={{ maxWidth: '500px' }}>
          <Title text="Mis datos" size="md" className="text-center color-dark-blue" />

          <div className="col-12 mb-3">
            <div className="p-0 mb-1 color-black bold size-09">Nombre</div>
            <div className="col-12 d-flex bg-color-blue-light border py-2 px-3 rounded-10">
              <input onChange={_handleOnChange} value={state.inputs.name} type="text" className="input-transparent" name="name" placeholder="Ingresa tu nombre" style={{ flex: '1' }} />
            </div>
            <ErrorValidation validations={state.validations} name="name" className="text-end size-09" />
          </div>

          <div className="col-12 mb-3">
            <div className="p-0 mb-1 color-black bold size-09">Apellidos</div>
            <div className="col-12 d-flex bg-color-blue-light border py-2 px-3 rounded-10">
              <input onChange={_handleOnChange} value={state.inputs.family_name} type="text" className="input-transparent" name="family_name" placeholder="Ingresa tu apellido" style={{ flex: '1' }} />
            </div>
            <ErrorValidation validations={state.validations} name="family_name" className="text-end size-09" />
          </div>

          <div className="col-12 mb-3">
            <div className="p-0 mb-1 color-black bold size-09">Rut</div>
            <div className="col-12 d-flex bg-color-blue-light border py-2 px-3 rounded-10">
              <input
                ref={rutRef}
                onChange={_handleOnChange}
                onKeyUp={_handleOnChange}
                value={state.inputs.rut}
                type="text"
                className="input-transparent"
                name="rut"
                placeholder="12.345.678-9"
                style={{ flex: '1' }}
              />
            </div>
            <ErrorValidation validations={state.validations} name="rut" className="text-end size-09" />
          </div>

          <div className="col-12 mb-3">
            <div className="p-0 mb-1 color-black bold size-09">Celular</div>
            <div className="col-12 d-flex bg-color-blue-light border py-2 px-3 rounded-10">
              <input
                ref={phoneRef}
                onChange={_handleOnChange}
                onKeyUp={_handleOnChange}
                value={state.inputs.phone_number}
                type="text"
                className="input-transparent"
                name="phone_number"
                placeholder="+56 94698-1325"
                style={{ flex: '1' }}
              />
            </div>
            <ErrorValidation validations={state.validations} name="phone_number" className="text-end size-09" />
          </div>

          <div className="col-12 color-white">
            <button className="btn-default w-100 p-3 mt-4" onClick={_handleUpdateProfile}>
              Actualizar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default UpdateProfile;
