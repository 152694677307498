/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Images from '../../../assets/image';
import Image from '../../../components/Image';

/**
 *
 * @payment_types payments
 * Presencial = 1
 * Debito = 2
 * Credito = 3
 */

const PaymentMethods = (props: any) => {
  const { onChange, inputs, title } = props;
  const { shopping_cart } = useSelector((state: any) => state);
  const [state, setState] = useState({ payment_methods: [] });

  useEffect(() => {
    const { services } = shopping_cart;

    const payment_methods: any = services.map((item: any) => {
      const isSubscription = item.type === 'subscription';

      const payment = {
        PK: item.eds?.eds_id,
        credit: item.eds?.info?.payment_types?.find((element: any) => element === 3) === undefined ? false : true,
        debit: item.eds?.info?.payment_types?.find((element: any) => element === 2) === undefined ? false : true,
        presential: item.eds?.info?.payment_types?.find((element: any) => element === 1) === undefined ? false : true,
      };

      if (isSubscription) payment.presential = false;

      return payment;
    });

    setState({ ...state, payment_methods });
  }, [shopping_cart]);

  const _handleComponentClick = (e: any, value: any) => {
    onChange({ target: { name: 'payment_method', value } });
    e.currentTarget.querySelector('input').checked = true;
  };
  return (
    <>
      {title && (
        <div className="row px-2 mb-4">
          <div className="col-12 size-09 bold px-2 mt-4 pb-2 color-dark-blue line-border">Medios de pago</div>
        </div>
      )}

      <div className="row" onChange={onChange}>
        {state.payment_methods.filter((item: any) => item.debit === true).length === shopping_cart.services.length && (
          <div className="w-100 px-4 mb-3" onClick={(e: any) => _handleComponentClick(e, 2)}>
            <div className="d-flex bg-color-blue-light px-4 py-3 border-default rounded-10 bold">
              <div className={`col-8 ${parseInt(inputs.payment_method) === 2 ? 'color-blue-base' : ''}`}>
                {parseInt(inputs.payment_method) === 2 ? <Image image={Images.DebitOn} className="me-3" /> : <Image image={Images.DebitOff} className="me-3" />}
                Webpay Débito
              </div>
              <div className="col-4 text-end">
                <input type="radio" name="payment_method" className="radio-grey-color" value={2} />
              </div>
            </div>
          </div>
        )}

        {state.payment_methods.filter((item: any) => item.credit === true).length === shopping_cart.services.length && (
          <div className="w-100 px-4 mb-3" onClick={(e: any) => _handleComponentClick(e, 3)}>
            <div className="d-flex bg-color-blue-light px-4 py-3 border-default rounded-10 bold">
              <div className={`col-8 ${parseInt(inputs.payment_method) === 3 ? 'color-blue-base' : ''}`}>
                {parseInt(inputs.payment_method) === 3 ? <Image image={Images.CreditOn} className="me-3" /> : <Image image={Images.CreditOff} className="me-3" />}
                Webpay Crédito
              </div>
              <div className="col-4 text-end">
                <input type="radio" name="payment_method" className="radio-grey-color" value={3} />
              </div>
            </div>
          </div>
        )}

        {state.payment_methods.filter((item: any) => item.presential === true).length === shopping_cart.services.length && (
          <div className="w-100 px-4 mb-3" onClick={(e: any) => _handleComponentClick(e, 1)}>
            <div className="d-flex bg-color-blue-light px-4 py-3 border-default rounded-10 bold">
              <div className={`col-8 ${parseInt(inputs.payment_method) === 1 ? 'color-blue-base' : ''}`}>
                {parseInt(inputs.payment_method) === 1 ? <Image image={Images.PresentialOn} className="me-3" /> : <Image image={Images.PresentialOff} className="me-3" />}
                Pago presencial
              </div>
              <div className="col-4 text-end">
                <input type="radio" name="payment_method" className="radio-grey-color" value={1} />
              </div>
            </div>
          </div>
        )}

        {state.payment_methods.filter((item: any) => item.credit === true)?.length !== shopping_cart.services.length &&
        state.payment_methods.filter((item: any) => item.debit === true)?.length !== shopping_cart.services.length &&
        state.payment_methods.filter((item: any) => item.presential === true)?.length !== shopping_cart.services.length ? (
          <div className="w-100 px-4 mb-3" onClick={(e: any) => _handleComponentClick(e, 1)}>
            <div className="d-flex bg-color-blue-light px-4 py-3 border-default rounded-10 bold">
              <div className={`col-8 ${parseInt(inputs.payment_method) === 1 ? 'color-blue-base' : ''}`}>
                {parseInt(inputs.payment_method) === 1 ? <Image image={Images.PresentialOn} className="me-3" /> : <Image image={Images.PresentialOff} className="me-3" />}
                Pago presencial
              </div>
              <div className="col-4 text-end">
                <input type="radio" name="payment_method" className="radio-grey-color" value={1} />
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default PaymentMethods;
