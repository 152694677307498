/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import Images from '../../assets/image';
import DrawerDirections from '../../components/DrawerDirections';
import Image from '../../components/Image';
import StickAddress from '../../components/StrickAddress';
import '../../styles/template.services.scss';
import { useSelector } from 'react-redux';
import { Loader } from '@googlemaps/js-api-loader';
import moment from 'moment';
import sessionService from '../../services/session.service';

const INITIAL_STATE = {
  drawer: {
    script: {
      show: false,
      direction: 'right-left',
    },
    manual: {
      show: false,
      direction: 'right-left',
    },
    autocomplete: {
      show: false,
      direction: 'bottom-top',
    },
    history: {
      show: false,
      direction: 'bottom-top',
    },
  },
  modal_maps: {
    open: false,
    direction: 'bottom-top',
  },
  google: null,
  autocompleteService: null,
  addresses: [],
};

const ServiceTemplate = (props: any) => {
  const [state, setState] = useState<any>(INITIAL_STATE);
  const history = useHistory();
  const { matchMedia, user } = useSelector((state: any) => state);
  const { children, banner, infoUrl, hideInfo } = props;

  useEffect(() => {
    _handleInitComponent();
  }, []);

  const _handleInitComponent = async () => {
    /**
     *  Get address and filter history address
     *  @addresses
     */
    const addresses: any = await _getAddress();
    const historyAddress: any = addresses?.filter((address: any) => address?.info?.history);

    if (historyAddress.length > 0) {
      historyAddress.sort((a: any, b: any) => {
        if (moment(a.info?.history_date).isAfter(b.info?.history_date)) return -1;
        if (moment(a.info?.history_date).isBefore(b.info?.history_date)) return 1;
        return 0;
      });

      const elementsToShow: any = historyAddress.slice(0, 3);
      const elementsToDelete: any = historyAddress.slice(3, historyAddress.length);

      for (const el of elementsToDelete) {
        sessionService.deleteAddress(el.SK);
      }

      state.addresses = elementsToShow;
    } else {
      state.addresses = [];
    }
    if (!state.google) {
      const loader: any = new Loader({
        apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY ? process.env.REACT_APP_GOOGLE_MAPS_API_KEY : '',
        version: 'weekly',
        libraries: ['places', 'geometry'],
        ...{},
      });

      loader.load().then((google: any) => {
        const autocompleteService = new google.maps.places.AutocompleteService();
        setState({ ...state, google, api_key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY, autocompleteService });
      });
    } else {
      const autocompleteService = new state.google.maps.places.AutocompleteService();
      setState({ ...state, autocompleteService });
    }
  };

  const _getAddress = async () => {
    if (user.isLogged) {
      try {
        const address: any = await sessionService.getAddresses();
        return address.data;
      } catch (e: any) {
        return [];
      }
    }

    return [];
  };

  const _toogleDrawer = (target: any) => {
    if (matchMedia.media === 'xs') {
      if (target !== null) {
        state.drawer[target].show = !state.drawer[target].show;
        setState({ ...state });
      }
    } else {
      state.modal_maps.open = !state.modal_maps.open;
      setState({ ...state });
    }
  };

  const _closeAndOpen = (target: any) => {
    Object.keys(state.drawer).forEach((key) => {
      state.drawer[key].show = false;
    });
    setState({ ...state });

    /* TIMEOUT EFFECT */
    setTimeout(() => {
      state.drawer[target].show = true;
      setState({ ...state });
    }, 200);
  };

  return (
    <div className="template-service mt-4 mt-sm-0">
      <DrawerDirections state={state} _toogleDrawer={_toogleDrawer} _closeAndOpen={_closeAndOpen} google={state.google} autocompleteService={state.autocompleteService} addresses={state.addresses} />
      <StickAddress _toogleDrawer={_toogleDrawer} addresses={state.addresses} />

      <div className="template-service__header d-xs-block d-sm-none">
        <Image image={banner} />
        <div className="template-service__header__label">
          <span>{props?.data?.name}</span>
          {hideInfo ? null : (
            <button className="btn m-0 p-0" onClick={() => history.push(infoUrl)}>
              <Image image={Images.IconInfo} className="ms-3" style={{ width: '20px', cursor: 'pointer' }} />
            </button>
          )}
        </div>
      </div>

      {children}

      <div className="row mb-5" id="faq-desktop">
        <div className="col-12 size-13 text-center mt-5 color-dark-blue px-5">
          <button className="btn-grey-light rounded-30 bold-300 faq-font-size" onClick={() => history.push('/preguntas-frecuentes')}>
            <span className="color-dark-blue">¿Tienes dudas sobre estos servicios?</span>
            <span className=" ms-3 underline color-blue-base">Preguntas Frecuentes</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ServiceTemplate;
