/* eslint-disable react-hooks/exhaustive-deps */
import Images from '../../assets/image';
import Title from '../../components/Title';
import ServiceTemplate from '../../template/ServiceTemplate';
import ModalKerosene from './ModalKerosene';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { add_service_to_cart, remove_service_from_cart } from '../../redux/actions/shopping_cart';
import { LOAD_OFF, LOAD_ON } from '../../redux/actions/loader';
import Services from '../../services/services.service';
import { useParams, useHistory } from 'react-router';
import { set_available_services } from '../../redux/actions/available_services';
import { toast } from 'react-toastify';
import { addToCartDataLayerEvent, clearDataLayerEvent, createDataLayerObject, removeFromCartDataLayerEvent, sendDataLayerEvent } from '../../helpers/data_layer';
import DynamicModal from '../../components/DynamicModal';
import { v4 } from 'uuid';
import { checkIfCopecFullPointsIsAvailable } from '../../helpers/copec_full_points';
import CardSubscription from '../../components/CardSubscription';
import GoToCheckoutButton from '../../components/GoToCheckoutButton';
import CardSubscriptionMobile from '../../components/CardSubscriptionMobile';
import { create_service, remove_service } from '../../redux/actions/services';
import CopecPopUp from '../../components/CopecPopUp';
import Drawer from '../../components/Drawer';
import RenderInfo from './RenderInfo';

const ServiceSubscription = (props: any) => {
  const { data } = props;
  const [state, setState] = useState<any>({
    products: [],
    service: {},
    related_products: [],
    modal: {
      kerosene: false,
    },
    alert: false,
    product: {},
    productsList: [],
    eds: {},
    slidePosition: 0,
    showSlideNextPrevButtons: true,
    showSlideNextPrevButtonsMobile: true,
    drawers: {
      datepicker: {
        show: false,
      },
      resume: {
        show: false,
      },
    },
    dynamicPopUp: {
      open: false,
      first_load: true,
    },
  });

  const [signInModal, setSignInModal] = useState(true);
  const [renderDetailsModal, setRenderDetailsModal] = useState({
    open: false,
    sub_service: {},
  });

  const { matchMedia, shopping_cart, available_services, loader, services, user } = useSelector((state: any) => state);
  const params: any = useParams();
  const dispatch = useDispatch();
  const history: any = useHistory();

  useEffect(() => {
    const unlisten = history.listen(() => {
      dispatch(LOAD_ON());
      window.location.reload();
    });
    return () => {
      unlisten();
    };
  }, [history]);

  useEffect(() => {
    getSubServices();
  }, []);

  useEffect(() => {
    if (user?.isLogged) {
      setSignInModal(false);
    }
  }, [user]);

  const getSubServices = async () => {
    dispatch(LOAD_ON());

    try {
      /**
       * GET MAIN SERVICE
       */
      const parent_service: any = available_services.find((as: any) => as.info?.slug === params.slug);
      let serviceResponse: any = null;
      let relatedProducts: any = [];

      serviceResponse = await Services.details({ PK: parent_service.PK, SK: parent_service.SK });

      const service = { ...serviceResponse.data };

      const { sub_services } = service;

      const availableServices = sub_services.filter((ss: any) => ss.name !== 'Envio' && ss.name !== 'Envío' && !ss.info?.is_unvailable);

      availableServices.sort((a: any, b: any) => {
        if (a.info?.required < b.info?.required) return 1;
        if (a.info?.required > b.info?.required) return -1;
        return 0;
      });

      availableServices.map((item: any) => {
        if (item.info.without_discount_price) {
          item.info.without_discount_price = Number(item.info.without_discount_price);
          const porcentageDiscount = ((item.info.without_discount_price - item.price) / item.info.without_discount_price) * 100;
          item.info.porcentageDiscount = Math.round(porcentageDiscount);
          item.info.show_discount = item.info.porcentageDiscount > 0;
        }
        return item;
      });

      const available_service_list: any = available_services.filter((s: any) => s.PK !== parent_service.PK);

      const newService = {
        ...service,
        code: parent_service.code,
        url: parent_service.url,
        eds: parent_service.eds,
        id: parent_service.id,
      };

      newService.isFullPointsAvailable = checkIfCopecFullPointsIsAvailable(newService);

      if (parent_service.company) {
        newService.company = parent_service.company;
      }

      available_service_list.push({ ...newService });

      dispatch(set_available_services(available_service_list));

      availableServices.forEach((service: any, index: any) => {
        const item: any = {
          item_name: service.name,
          item_id: service.info?.sku || '',
          price: service.price,
          item_brand: service.name?.toLowerCase(),
          item_category: 'service',
          item_category2: service.info?.required ? 'sub_service' : 'related_product',
          item_category3: '',
          item_category4: '',
          item_variant: '',
          item_list_name: 'show_available_items',
          item_list_id: '',
          index: index,
          quantity: 1,
        };
        clearDataLayerEvent();
        const dataLayerEvent: any = createDataLayerObject('view_item', [item]);
        sendDataLayerEvent(dataLayerEvent);
      });

      service.related_products?.forEach((service: any, index: any) => {
        const item: any = {
          item_name: service.name,
          item_id: service.sku || '',
          price: service.price,
          item_brand: params.slug,
          item_category: 'service',
          item_category2: 'related_product',
          item_category3: '',
          item_category4: '',
          item_variant: '',
          item_list_name: 'show_available_related_products',
          item_list_id: index,
          index: index,
          quantity: 1,
        };
        clearDataLayerEvent();
        const dataLayerEvent: any = createDataLayerObject('view_item', [item]);
        sendDataLayerEvent(dataLayerEvent);
      });

      /** VERIFY IF SERVICE IS CREATED */
      const reduxServiceState: any = services.find((service: any) => service.PK === props.PK && service.SK === props.SK);

      /** VERIFY IF SERVICE IS IN SHOPPING CART */
      const isServiceInShoppingCart: any = shopping_cart.services.find((service: any) => service.PK === props.PK && service.SK === props.SK);

      let current_service: any = null;

      if (reduxServiceState) {
        current_service = { ...reduxServiceState, info: { ...service.info } };
      }

      if (isServiceInShoppingCart) {
        current_service = { ...isServiceInShoppingCart, info: { ...service.info } };
      }

      if (!isServiceInShoppingCart && !reduxServiceState) {
        current_service = {
          id: v4(),
          PK: props.PK,
          SK: props.SK,
          shipping_price: service?.shipping_price,
          service: {},
          related_products: [],
          module: service?.type,
          eds: data?.eds,
          delivery: service?.shipping_price,
          parent_service: parent_service.PK,
          name: props.data.name,
          info: { ...service.info },
          is_service_in_shopping_cart: false,
          slug: service?.info?.slug,
        };
      }

      setState((prevState: any) => {
        const dynamicPopUp: any = {
          open: false,
          first_load: false,
        };
        if (serviceResponse?.data?.info?.popup_info?.isEnabled && prevState.dynamicPopUp.first_load) {
          dynamicPopUp.open = true;
        }
        return {
          ...prevState,
          productsList: availableServices,
          relatedProductsList: relatedProducts,
          eds: data.eds,
          service: newService,
          dynamicPopUp,
          current_service,
        };
      });
    } catch (e) {
      toast.error('No fue posible cargar los prductos.');
      dispatch(LOAD_OFF());
    }
    dispatch(LOAD_OFF());
  };

  const _handleAddProduct = (payload: any) => {
    payload.quantity = 1;
    payload.shipping_price = state.current_service?.shipping_price;

    const isServiceInShoppingCart: any = shopping_cart.services.find((service: any) => service.PK === payload.PK);
    const isTheSameSubservice: any = shopping_cart.services.find((service: any) => service.PK === payload.PK && service.service?.SK === payload?.SK);

    if (!isServiceInShoppingCart) {
      addToCartDataLayerEvent(payload, params.slug, 'sub_service', 'show_available_items');

      dispatch(create_service({ ...state.current_service, type: 'subscription', service: payload, related_products: [] }));

      dispatch(
        add_service_to_cart({
          ...state.current_service,
          type: 'subscription',
          service: payload,
          related_products: [],
        })
      );
    }

    if (isServiceInShoppingCart && isTheSameSubservice) {
      removeFromCartDataLayerEvent(payload, params.slug, 'sub_service', 'show_available_items');

      dispatch(remove_service({ ...isTheSameSubservice, service: payload, related_products: [] }));

      dispatch(
        remove_service_from_cart({
          ...isTheSameSubservice,
          service: payload,
          related_products: [],
        })
      );
    }

    if (!isTheSameSubservice && isServiceInShoppingCart) {
      addToCartDataLayerEvent(payload, params.slug, 'sub_service', 'show_available_items');
      const id = v4();

      dispatch(create_service({ ...state.current_service, id, type: 'subscription', service: payload, related_products: [] }));

      dispatch(
        add_service_to_cart({
          ...state.current_service,
          id: id,
          type: 'subscription',
          service: payload,
          related_products: [],
        })
      );
    }

    setState((prevState: any) => {
      payload.quantity = 1;
      payload.shipping_price = state.current_service?.shipping_price;
      prevState.current_service = {
        ...prevState.current_service,
        service: payload,
        related_products: [],
      };

      return { ...prevState, modal: { kerosene: false } };
    });
  };

  return (
    <ServiceTemplate banner={Images.HeaderKerosene} title={'Kerosene'} infoUrl="/services/info/kerosene" data={props.data} hideInfo>
      <div className="page-service-kerosene" style={{ minHeight: '35vh' }}>
        <div className="page-service-kerosene__products my-4 d-none d-sm-block">
          <h1 className="bold-300 text-start px-5 color-dark-blue">{state.current_service?.name}</h1>
        </div>

        <Drawer show={renderDetailsModal.open} direction="bottom-top">
          <RenderInfo
            state={renderDetailsModal}
            open={renderDetailsModal.open}
            onClose={() => setRenderDetailsModal({ open: false, sub_service: {} })}
            handleBack={() => setRenderDetailsModal({ open: false, sub_service: {} })}
          />
        </Drawer>

        <ModalKerosene onContinue={() => _handleAddProduct(state.product)} open={state.modal.kerosene} />

        <DynamicModal open={state.dynamicPopUp?.open} data={state.service?.info?.popup_info} onContinue={() => setState({ ...state, dynamicPopUp: { ...state.dynamicPopUp, open: false } })} />

        <CopecPopUp open={signInModal} title="Inicio de sesion requerido" onCancel={() => history.push('/')} onConfirm={() => history.push('/sign-in')}>
          <div className="size-12 ">Los items adquiridos con esta compra quedan vinculados a la cuenta del usuario. Por lo tanto, debes iniciar sesión para continuar.</div>
          <div className="size-12  mt-3">¿Quieres iniciar sesión ahora?</div>
        </CopecPopUp>

        {!loader.loading && state.productsList.length === 0 && (
          <Title
            text="No pudimos encontrar ningún producto disponible para este servicio en su dirección."
            className={`text-center color-dark-blue mt-5 ${matchMedia.media === 'xs' ? 'size-07' : 'size-09'}`}
          />
        )}

        {/* Desktop Cards */}
        <div className="d-flex px-5 justify-content-center d-none d-sm-flex" style={{ flexWrap: 'wrap' }}>
          {state.productsList &&
            state.productsList.map((product: any) => (
              <div className="mx-3 mb-3" key={product.SK}>
                <CardSubscription
                  imageFull
                  price={product.price}
                  image={product.info?.gallery?.url ? { default: { alt: '', src: product.info?.gallery?.url } } : Images.NotFound}
                  text={product.name}
                  value="Ver detalle"
                  product={product}
                  data={product}
                  handleAction={() => _handleAddProduct(product)}
                  handleDetail={() => setRenderDetailsModal({ open: true, sub_service: product })}
                  recommended={product.info?.recommended || false}
                  fullpoints={state.service.isFullPointsAvailable}
                />
              </div>
            ))}
        </div>

        {/* Mobile Cards */}
        <div className="row px-3 mt-5 d-sm-none">
          {state.productsList &&
            state.productsList.map((product: any) => (
              <div className="col-12 px-3 mb-3" key={product.SK}>
                <CardSubscriptionMobile
                  image={product.info?.gallery?.url ? { default: { alt: '', src: product.info?.gallery?.url } } : Images.NotFound}
                  text={product.name}
                  price={product.price}
                  value="Ver detalle"
                  handleAction={() => _handleAddProduct(product)}
                  handleDetail={() => setRenderDetailsModal({ open: true, sub_service: product })}
                  data={product}
                  fullpoints={state.service.isFullPointsAvailable}
                />
              </div>
            ))}
        </div>

        <div className="row d-none d-sm-flex mt-5 mb-3">
          <div className="col-12 text-center color-white">
            <GoToCheckoutButton />
          </div>
        </div>
      </div>
    </ServiceTemplate>
  );
};

export default ServiceSubscription;
