import moment from 'moment';
import store from '../redux/store';
import geozoneService from '../services/geozone.service';
import { set_cache } from '../redux/actions/cache';
import { set_eds } from '../redux/actions/available_eds';
import { delete_service } from '../redux/actions/services';
import { remove_service_from_cart } from '../redux/actions/shopping_cart';

export const validateAvailablesGeozone = async () => {
  try {
    // Get data from redux store and geozone service
    const GEO = geozoneService;
    const reduxStore: any = store.getState();
    const dispatch: any = store.dispatch;
    const { directions, available_eds, shopping_cart, cache } = reduxStore;

    const { latitude, longitude } = directions;

    // Get new availale geozone data
    const availableEdsResponse: any = await GEO.search(latitude, longitude);
    dispatch(set_cache({ ...cache, directions: { created_at: moment().toString(), last_access: moment().toString(), start_reset: null } }));
    dispatch(set_eds(availableEdsResponse.data.data));
    // compare new geozone data with old data
    const edsUnvailables: any = _compareGeozoneData(available_eds, availableEdsResponse.data.data);

    const { services } = shopping_cart;

    let actions: any = [];
    let newShoppingCartServies: any = [...services];
    let servicesRemoved: any = [];

    // if there are unvailable eds, update eds service or remove them from shopping cart
    if (edsUnvailables.length > 0) {
      edsUnvailables.forEach((item: any) => {
        const isServiceUnvailable: any = services.find((service: any) => service.eds?.service_id === item.service_id && service.eds?.eds_id === item.eds_id);

        // Set new available eds to service
        if (isServiceUnvailable && item.availableEdsToThisService.length > 0) {
          const firstEdsAvailable: any = item.availableEdsToThisService[0];
          isServiceUnvailable.eds = firstEdsAvailable;
          actions.push('switch_eds');
        }

        // Remove service from shopping cart
        if (isServiceUnvailable && item.availableEdsToThisService.length === 0) {
          dispatch(delete_service({ ...isServiceUnvailable }));
          dispatch(remove_service_from_cart({ ...isServiceUnvailable }));
          servicesRemoved.push(isServiceUnvailable);
          actions.push('remove_service');
        }
      });
    }

    return {
      actions,
      newShoppingCartServies,
      servicesRemoved,
    };
  } catch (e: any) {
    return false;
  }
};

const _compareGeozoneData = (edsCacheData: any, edsRequestData: any) => {
  const edsNotAvailableAnyMore = [];

  for (const edsData of edsCacheData) {
    const { eds_code, eds_id, service_id } = edsData;

    /** validate if eds is available */
    const isEdsAvailable: any = edsRequestData.find((item: any) => item.eds_code === eds_code && item.eds_id === eds_id && item.service_id === service_id);

    /** validate if service is active */
    let isServiceActive = false;
    if ('is_active' in isEdsAvailable) {
      isServiceActive = isEdsAvailable.is_active;
    } else {
      isServiceActive = isEdsAvailable?.info?.services_id?.find((item: any) => item === service_id);
    }

    /** if eds is not available or service is not active, add to array */
    if (!isEdsAvailable || !isServiceActive) {
      /** find for available eds to this service */
      const availableEdsToThisService: any = edsRequestData.filter((item: any) => item.service_id === service_id && item.eds_code !== eds_code);
      edsData.availableEdsToThisService = availableEdsToThisService;
      edsNotAvailableAnyMore.push(edsData);
    }
  }

  return edsNotAvailableAnyMore;
};
