import axios from "axios";

const api = axios.create({
  baseURL: `https://86zpltvria.execute-api.us-east-1.amazonaws.com/Prod/api/station`,
  headers: {
    "Content-type": "application/json",
    "ApiKey": "d47d780f-5efc-4fd2-949c-57703d39013f"
  }
});

class KeroseneDAO {
  getService = (id: any) => {
    return api.post(`salesforce?codEs=${id}&company=1&region=-1&comuna=-1`)
  }
}

export default new KeroseneDAO();