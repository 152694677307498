import axios from "axios";
import instance from './api_overt';

const api = axios.create({
  baseURL: "https://98uejbwyvk.execute-api.sa-east-1.amazonaws.com/dev",
  headers: {
    "Content-type": "application/json"
  }
});
class GeozoneDao {

  getAll = () => {
    return api.get('/geozona/all')
  }

  getAllEds = () => {
    return api.get("/eds/all");
  }

  search = async (latitude: any, longitude: any) => {
    try {
      return await instance.post('/overt/check-coverage', { latitude, longitude });
    } catch (e) {
      return Promise.reject(e);
    }
  }
}

export default new GeozoneDao();