/* eslint-disable react-hooks/exhaustive-deps */
import Image from '../Image';
import '../../styles/components/component.card-lub.scss';
import Images from '../../assets/image';
import './style.scss';
import QuantitySelectorCard from '../QuantitySelectorCard';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { update_cart } from '../../redux/actions/shopping_cart';

const CardLavamax = (props: any) => {
  const { image, text, handleAction, handleDetail, price, data, product, onPlusProduct, onMinusProduct, imageFull, fullpoints } = props;
  const { shopping_cart } = useSelector((state: any) => state);
  const [productData, setProductData] = useState<any>({});
  const dispatch: any = useDispatch();

  useEffect(() => {
    const service: any = shopping_cart.services.find((s: any) => s.PK === data.PK);
    if (service) {
      const rp: any = service.related_products || [];
      const products: any = [...[service.service], ...rp];
      const productData: any = products.find((p: any) => p.PK === product.PK);
      setProductData({ ...productData });
    }
  }, [shopping_cart]);

  const _handleOnClick = () => {
    if (handleAction) {
      handleAction();
    } else {
      console.log('[INFO]', 'Everything ok here ;)...');
    }
  };

  const _handleAddQuantity = () => {
    const service: any = shopping_cart.services?.find((el: any) => el.id === data.PK);
    const serviceData: any = service?.related_products.find((el: any) => el.PK === product.PK);
    serviceData.quantity += 1;
    setProductData({ ...data });
    dispatch(update_cart(shopping_cart));
  };

  const _handleSubtractQuantity = () => {
    const service: any = shopping_cart.services?.find((el: any) => el.id === data.PK);
    const serviceData: any = service?.related_products.find((el: any) => el.PK === product.PK);
    serviceData.quantity -= 1;
    setProductData({ ...data });

    if (serviceData.quantity === 0) {
      service.related_products = service.related_products.filter((el: any) => el.PK !== serviceData.PK);
    }

    dispatch(update_cart(shopping_cart));
  };

  return (
    <div className="card-lavamax">
      <div className={`card-lavamax__image ${imageFull ? 'image-full' : ''}`}>
        <Image image={image || Images.NotFound} />
      </div>
      <div className="card-lavamax__content row">
        <div className="size-12 color-white bold-300">{text}</div>

        {fullpoints && data?.info?.points_per_division && parseInt(data?.info?.points_per_division) ? (
          <div className="d-flex color-white align-items-center size-09 my-2">
            <Image image={Images.CopecFull} className="me-2" style={{ width: '50px' }} />
            Acumula puntos Full Copec.
          </div>
        ) : null}
        
        <div className="size-12 color-white bold-300">
          <button className="btn-default size-09 color-green-base p-0 m-0 underline" onClick={handleDetail}>
            Ver detalle
          </button>
        </div>

        {product?.info?.show_discount ? (
          <div className="d-flex flex-column">

            <div className="d-flex align-items-center">
              <span className="color-white me-1">$</span>
              <span className="color-white  size-12 bold text-decoration-line-through">{product?.info?.without_discount_price?.toLocaleString('es-CL')}</span>
              <span className="color-green-base bold ms-3">{product?.info?.porcentageDiscount}% Dcto</span>
            </div>

            <div className="d-flex size-15 align-items-center">
              <span className="color-green-base size-08 me-1">$</span>
              <span className="color-white size-15 bold">{price?.toLocaleString('es-CL')}</span>
            </div>
          </div>
        ) : (
          <div className="d-flex size-15 align-items-center">
            <span className="color-green-base size-08 me-1">$</span>
            <span className="color-white size-15 bold">{price?.toLocaleString('es-CL')}</span>
          </div>
        )}

        {!productData?.quantity || productData?.quantity === 0 ? (
          <div className="w-100 mt-2">
            <button className="btn-green-light py-2 w-100 bold" onClick={_handleOnClick}>
              Agregar
            </button>
          </div>
        ) : (
          <>
            {productData.oneItem ? (
              <div className="w-100 mt-2">
                <button className="btn-green-light py-2 w-100 bold" onClick={_handleSubtractQuantity}>
                  Eliminar
                </button>
              </div>
            ) : (
              <div className="w-75 mt-2">
                <QuantitySelectorCard
                  quantity={productData?.quantity}
                  onAdd={onPlusProduct ? () => onPlusProduct(product.PK) : _handleAddQuantity}
                  onRemove={onMinusProduct ? () => onMinusProduct(product.PK) : _handleSubtractQuantity}
                />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default CardLavamax;
