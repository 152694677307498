import { useEffect, useState } from "react";
import instance from '../../services/api_admin';

const OrderStatus = () => {

    const [status, setStatus] = useState('');
    const [canceled, setCanceled] = useState(false);
    const [orderId, setOrderId] = useState('');
    const [token, setToken] = useState('');

    useEffect( () => {
        let url_string = window.location;
        let url = new URL(url_string);
        let status = url.searchParams.get('status');
        let orderId = url.searchParams.get('order_id');
        let token = url.searchParams.get('token');
        setStatus(status ?? '');
        setOrderId(orderId);
        setToken(token)

        if (status === 'OK') {
            sendConfirmation(orderId, token);
        }

    }, []);

    const sendConfirmation = async (orderId, token) => {
        try {
            const params = [
                'order_id='+orderId,
                'token='+token
            ];
            const confirmUrl = '/admin/orders/confirm_order?'+params.join('&');
            await instance.get(confirmUrl);
        } catch (e) {
            setStatus('CANT_CONFIRM');
        }
    }

    const cancel = async () => {
        try {
            const params = [
                'order_id='+orderId,
                'token='+token
            ];
            const cancelUrl = '/admin/orders/cancel_order?'+params.join('&');
            const { data } = await instance.post(cancelUrl);
            if (data.data.is_canceled) {
                setCanceled(true);
            } else {
                setCanceled(false);
                setStatus('CANT_CANCEL');
            }
        } catch (e) {
            setStatus('CANT_CANCEL');
        }
    }   

    const noCancel = () => {
        setStatus('NO_CANCELED');
    }

    return(
        <div className="container text-dark p-5 d-flex justify-content-center align-items-center" style={{minHeight: '400px'}}>
            {
                status === 'OK' && <>
                    <div className="alert alert-success text-center">
                        <h4><strong>¡Gracias!</strong></h4>
                        <p>Hemos confirmado exitosamente tu pedido.</p>
                    </div>
                </>
            }

            {
                status === 'NO_CANCELED' && <>
                    <div className="alert alert-success text-center">
                        Tu pedido sigue programado.
                    </div>
                </>
            }

            {
                status === 'CANT_CONFIRM' && <>
                    <div className="alert alert-warning text-center">
                        No ha sido posible confirmar tu pedido, favor contactarse al número: <a href={`tel:${process.env.REACT_APP_PHONE_SUPPORT}`}>{process.env.REACT_APP_PHONE_SUPPORT}</a>
                    </div>
                </>
            }

            {
                status === 'CANT_CANCEL' && <>
                    <div className="alert alert-warning">
                        No ha sido posible anular tu pedido. Si deseas anular favor contactarse al número: <a href={`tel:${process.env.REACT_APP_PHONE_SUPPORT}`}>{process.env.REACT_APP_PHONE_SUPPORT}</a>
                    </div>
                </>
            }

            {
                status === 'CANCELED' && <>
                    <div className="alert alert-success text-center" style={{ width: '50%'}}>
                        {
                            canceled ? <>
                                Hemos cancelado con éxito tu pedido.
                            </> : <>
                                <h4><strong>¿Estás seguro que deseas anular tu pedido?</strong></h4>
                                <div className="d-flex">
                                    <button className="btn-green-light w-50 size-5 m-2" onClick={cancel}>SI</button>
                                    <button className="btn-default w-50 size-5 m-2" onClick={noCancel}>NO</button>
                                </div>
                            </>
                        }
                    </div>
                </>
            }
        </div>
    )
}

export default OrderStatus;