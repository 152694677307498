import { handleActions } from 'redux-actions';
import { DELETE_AUTO, SET_AUTO } from '../actions/autos';

export const INITIAL_STATE = {
  autos: [],
  selected_auto: []
};

export default handleActions({
  [SET_AUTO]: (state: any, action: any) => {
    state.selected_auto.push(action.payload);
    return { ...state }
  },
  [DELETE_AUTO]: (state: any, action: any) => {
    const index = state.selected_auto.findIndex((item: any) => item.service === action.payload.service)
    state.selected_auto.splice(index, 1)
    return { ...state }
  }
}, INITIAL_STATE);