
import React from 'react'
import AliceCarousel from "react-alice-carousel"

const Carousel = React.forwardRef((props: any, ref: any) => {
  const { items, cRef, ...rest } = props
  return (
    <AliceCarousel ref={ref} items={items} {...rest} />

  )
})

export default Carousel