import Drawer from "../../components/Drawer"

import SelectCar from "./SelectCar"

const DrawerSelectCar = (props: any) => {

  const { show, _handleState, id, PK, SK } = props

  return (
    <Drawer show={show} direction="right-left" className="drawer-with-header" >
      <SelectCar _handleState={_handleState} id={id} PK={PK} SK={SK} show={show} />
    </Drawer >
  )
}

export default DrawerSelectCar