/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react"
import termsService from "../../services/terms.service";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { LOAD_OFF, LOAD_ON } from "../../redux/actions/loader";

const Terms = () => {
    const [state, setState] = useState({
        pdf_path: '',
        render: false
    })
    const dispatch: any = useDispatch()

    useEffect(() => {
        _getTerms();
    }, [])

    const _getTerms = async () => {
        try {
            dispatch(LOAD_ON());
            const response: any = await termsService.read();
            setState({ ...state, pdf_path: response.data.url, render: true })
            dispatch(LOAD_OFF());
        } catch (e: any) {
            toast.error("No fue posible cargar los términos y condiciones")
            dispatch(LOAD_OFF());
        }
    }

    return (
        <div className="container text-dark">
            {state.render ? (
                <iframe src={state.pdf_path} title="Terminos y condiciones" style={{ width: '100%', height: '1000px' }} className="mb-5 mt-3 rounded border"></iframe>
            ) : null}
        </div>
    )
}

export default Terms