/* eslint-disable no-useless-escape */
import * as yup from 'yup';

export const DeliverySchema = yup.object().shape({
  name: yup
    .string()
    .matches(/^[\w\. ?¿¡!^\+-\:()\{\}\[\],;\*#@áàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ]*$/gim, 'Este campo contiene caracteres inválidos')
    .max(255, 'Este campo acepta un máximo de 255 caracteres')
    .required('Este campo es requerido'),
  rut: yup
    .string()
    .matches(/^[\w\. ?¿¡!^\+-\:()\{\}\[\],;\*#@áàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ]*$/gim, 'Este campo contiene caracteres inválidos')
    .required('Este campo es requerido'),
  email: yup
    .string()
    .matches(/^[\w+.]+@\w{2,}\.\w{2,}(?:\.\w{2,})?$/, 'Formato de email no es válido')
    .required('Este campo es requerido'),
  phone_number: yup.string().min(9, 'El Nº de teléfono debe ser de 9 dígitos').max(9, 'El Nº de teléfono debe ser de 9 dígitos').required('Este campo es requerido'),
  apartment: yup
    .string()
    .matches(/^[\w\. ?¿¡!^\+-\:()\{\}\[\],;\*#@áàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ]*$/gim, 'Este campo contiene caracteres inválidos')
    .max(40, 'Este campo acepta un máximo de 40 caracteres'),
  showCarOptions: yup.boolean(),
  parking: yup
    .string()
    .matches(/^[\w\. ?¿¡!^\+-\:()\{\}\[\],;\*#@áàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ]*$/gim, 'Este campo contiene caracteres inválidos')
    .max(40, 'Este campo acepta un máximo de 40 caracteres')
    .when('house_type', {
      is: 2,
      then: yup
        .string()
        .matches(/^[\w\. ?¿¡!^\+-\:()\{\}\[\],;\*#@áàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ]*$/gim, 'Este campo contiene caracteres inválidos')
        .max(40, 'Este campo acepta un máximo de 40 caracteres')
        .when('show_car_options', {
          is: true,
          then: yup
            .string()
            .matches(/^[\w\. ?¿¡!^\+-\:()\{\}\[\],;\*#@áàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ]*$/gim, 'Este campo contiene caracteres inválidos')
            .max(40, 'Este campo acepta un máximo de 40 caracteres')
            .required('Este campo es requerido'),
        }),
    }),
  observations: yup
    .string()
    .matches(/^[\w\. ?¿¡!^\+-\:()\{\}\[\],;\*\n#@áàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ]*$/gi, 'Este campo contiene caracteres inválidos')
    .max(255, 'Este campo acepta un máximo de 255 caracteres'),
});

export type DeliveryType = yup.InferType<typeof DeliverySchema>;
