const Pages:any = [
    {
        title: 'Home',
        rule: ''
    },
    {
        title: 'Servicios',
        rule: 'services'
    },
    {
        title: 'Checkout',
        rule: 'check-out'
    },
    {
        title: 'Detalles del Pedido',
        rule: 'order'
    },
    {
        title: 'Perfil',
        rule: 'profile'
    },
];

const getPageInfo = (path:any) => {
    let page:any = null;

    Pages.forEach((item:any) => {
        if(path.includes(item.rule)) {
            page = item;
        }
    })

    return page;
}

export default getPageInfo;