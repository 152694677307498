import store from '../redux/store';
import instanceAccount from './api_account';
import instanceOvert from './api_overt';

class UsersService {
  _setHeaders = () => {
    const state: any = store.getState();
    const { user } = state;
    if (user.isLogged) {
      instanceAccount.defaults.headers.common['Authorization'] = user?.session?.AuthenticationResult?.AccessToken;
      instanceOvert.defaults.headers.common['Authorization'] = user?.session?.AuthenticationResult?.AccessToken;
    } else {
      delete instanceAccount.defaults.headers.common['Authorization'];
      delete instanceOvert.defaults.headers.common['Authorization'];
    }
  };

  async getClientCompanyUsers() {
    try {
      this._setHeaders();
      const response = await instanceOvert.get('/overt/company/users?role=CLIENT_COMPANY');
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async createClientCompanyUser(user: any) {
    try {
      this._setHeaders();
      const response = await instanceOvert.post('/overt/company/user/create', user);
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async updateClientCompanyUser(user: any) {
    try {
      this._setHeaders();
      const response = await instanceOvert.put('/overt/company/user/update', user);
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async massiveLoadClientCompanyUsers(data: any) {
    try {
      this._setHeaders();
      const response = await instanceOvert.post('/overt/company/load/users', data);
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  }
}

export default UsersService;
