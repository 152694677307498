import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Images from '../../assets/image';
import { _containsLatLan } from '../../helpers/check_polygon';
import { set_eds } from '../../redux/actions/available_eds';
import { setDirections } from '../../redux/actions/directions';
import { clear_services } from '../../redux/actions/services';
import { clear_cart } from '../../redux/actions/shopping_cart';
import { set_splash } from '../../redux/actions/splash_screen';
import Image from '../Image';

const HistoryAddresses = (props: any) => {
  const { addresses, handleClose } = props;

  const history: any = useHistory();
  const dispatch: any = useDispatch();

  const _handleSplit = (address: any, target: any = 'first') => {
    const [first, second] = address.split('-');

    if (target === 'first') return first || '';

    return second || '';
  };

  const _handleSearch = async (addressDirection: any) => {
    dispatch(set_splash({ direction_desktop: true, direction: false }));
    handleClose();
    const available_eds = await _containsLatLan(addressDirection.latitude, addressDirection.longitude);

    if (!available_eds) {
      dispatch(set_splash({ direction: false, direction_desktop: false }));
      return;
    }
    dispatch(
      setDirections({
        _string: addressDirection.info.address,
        direction: addressDirection.info.address,
        address: addressDirection.info.address,
        longitude: addressDirection.longitude,
        latitude: addressDirection.latitude,
      })
    );
    
    dispatch(clear_cart());
    dispatch(clear_services());
    dispatch(set_splash({ direction: false, direction_desktop: false }));
    dispatch(set_eds(available_eds));
    history.push('/');
  };

  return (
    <>
      {addresses.map((item: any, index: any) => (
        <div className="col-12 bg-color-blue-light border p-3 rounded-10 mb-1 d-flex" style={{ cursor: 'pointer' }} key={item.title + '-' + index}>
          <Image image={Images.DirectionInput} />
          <button className="w-100 btn d-flex flex-column" onClick={() => _handleSearch(item)}>
            <div className="bold color-grey text-start">{_handleSplit(item.info?.address)}</div>
            <div className="size-09 color-grey text-start">{_handleSplit(item.info?.address, 'second')}</div>
          </button>
        </div>
      ))}
    </>
  );
};

export default HistoryAddresses;
