/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { toast } from 'react-toastify';
import Images from '../../assets/image';
import ErrorValidation from '../../components/ErrorValidation';
import Image from '../../components/Image';
import Title from '../../components/Title';
import { availableTypes, carTypes } from '../../helpers/car_types';
import { sendDataLayerEvent } from '../../helpers/data_layer';
import validate from '../../helpers/validate';
import { LOAD_OFF, LOAD_ON } from '../../redux/actions/loader';
import { create_service, update_service } from '../../redux/actions/services';
import CarService from '../../services/cars.service';
import sessionService from '../../services/session.service';
import { CarSchema, CarWithTypeSchema, PlateSchema } from '../../validations/car.validations';
import SelectCarData from './SelectCarData';
import SelectManualCarData from './SelectManualCarData';
import CarApiService from '../../services/new.car.api.service';
import { close_cart, update_service_to_cart } from '../../redux/actions/shopping_cart';
const INITIAL_STATE = {
  inputs: {
    plate: '',
    model: '',
    brand: '',
    year: '',
    type: '',
    IdItemDefault: null
  },
  models: [],
  brands: [],
  types: [],
  validations: {},
  formState: {
    plate: false,
    brand: false,
    model: false,
    searchPlate: true,
    manualData: false,
    createCar: false,
  },
  inputState: {
    plate: false,
    model: false,
    brand: false,
    type: false,
    year: false,
  },
  locale: {
    noResultsText: 'No se encontraron resultados',
    searchPlaceholder: 'Buscar',
  },
  cars: [],
};

const SelectCar = (props: any) => {
  const { _handleState, rounded, id } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const params: any = useParams()
  const urlParams: any = new URLSearchParams(window.location.search);
  const [state, setState] = useState<any>({ ...INITIAL_STATE });
  const { user, loader, shopping_cart, available_services, services } = useSelector((state: any) => state);
  const Car = new CarService();

  useEffect(() => {
    getMyVehicles();
  }, [props.show]);

  const _clearInputs = () => {
    getMyVehicles();
  };

  const _handleChangeInput = async (e: any) => {
    const { target } = e;
    if (target.name === 'year') {
      target.value = target.value.replace(/\D/g, '');
    }
    const { name, value } = target;
    state.inputs[name] = value ? value : '';
    delete state.validations[name];

    setState({ ...state });
  };

  const _handleAddCar = async () => {
    const validations = !state.inputs?.success ? await validate(CarWithTypeSchema, state.inputs) : await validate(CarSchema, state.inputs);
    if (validations) {
      setState({ ...state, validations });
    } else {
      let payload: any = {};
      if (state.formState.manualData) {
        payload = {
          plate: state.inputs.plate,
          model: {
            value: 1,
            label: state.inputs.model,
          },
          brand: {
            value: 1,
            label: state.inputs.brand,
          },
          year: state.inputs.year,
          size: state.inputs.type,
          IdItemDefault: state.inputs.IdItemDefault,
          service: 'lub',
        };
      } else {
        payload = {
          plate: state.inputs.plate,
          model: state.models.find((item: any) => parseInt(item.value) === parseInt(state.inputs.model)),
          brand: state.brands.find((item: any) => parseInt(item.value) === parseInt(state.inputs.brand)),
          year: state.inputs.year,
          IdItemDefault: state.inputs.IdItemDefault,
          size: state.IdMedidaVehiculo ? state.IdMedidaVehiculo : 2,
          type: state.types?.find((item: any) => parseInt(item.value) === parseInt(state.inputs.type))?.label,
          service: 'lub',
        };
      }

      if (!state?.inputs?.success && !state?.formState?.manualData) {
        dispatch(LOAD_ON());
        try {
          const responseDimensions: any = await CarApiService._getDimensions({
            brand: payload.brand.value,
            model: payload.model.value,
          });

          dispatch(LOAD_OFF());
          if (responseDimensions?.code !== 200) {
            throw new Error(responseDimensions.message);
          }

          const convertTable: any = [
            "pequeño",
            "mediano",
            "grande",
            "extra grande"
          ]
          const dimension: any = responseDimensions?.data?.dimension
          const size: any = convertTable.findIndex((el: any) => el === dimension?.toLowerCase());

          payload.size = size !== -1 ? size + 1 : 2;
        } catch (e: any) {
          dispatch(LOAD_OFF());
          payload.size = 2;
        }
      }

      /** CLOSE MODAL */
      _handleState(false, 'selectCar');
      const parent_service: any = available_services.find((as: any) => as.PK === props.PK && as.SK === props.SK);

      /**
       * IF HAS ID IN URL, CHECK IF ID IS VALID
       * IF ID IS VALID, UPDATE SERVICE
       * IF NOT, CREATE A NEW SERVICE
       */
      if (urlParams.has("id")) {
        const existsServiceIntoState = services.find((service: any) => service.id === urlParams.get('id'));
        const existsServiceIntoShoppingCart = shopping_cart.services.find((service: any) => service.id === urlParams.get('id'));

        if (existsServiceIntoState) {
          existsServiceIntoState.car = payload;
          dispatch(update_service({ ...existsServiceIntoState }));
        }

        if (existsServiceIntoShoppingCart) {
          existsServiceIntoShoppingCart.car = payload;
          dispatch(update_service_to_cart({ ...existsServiceIntoState }));
        }

        if (!existsServiceIntoState && !existsServiceIntoShoppingCart) {
          dispatch(create_service({
            id: id,
            PK: props.PK,
            SK: props.SK,
            module: 'lub',
            car: payload,
            slug: params.slug,
            is_service_in_cart: false,
            info: { ...parent_service.info },
            isFullPointsAvailable: parent_service.isFullPointsAvailable,
          }));
        }
      }

      if (!urlParams.has("id")) {
        dispatch(create_service({
          id: id,
          PK: props.PK,
          SK: props.SK,
          module: 'lub',
          car: payload,
          slug: params.slug,
          is_service_in_cart: false,
          info: { ...parent_service.info },
          isFullPointsAvailable: parent_service.isFullPointsAvailable,
        }));
      }

      dispatch(close_cart());

      let urlPage: any = '/services/cambio-de-aceite/paso2/agregar-vehiculo';
      let eventName: any = 'aceite_vehiculo';

      if (urlParams.has("id")) {
        urlPage = '/services/cambio-de-aceite/paso2.1/agregar-vehiculo-nuevo';
        eventName = 'aceite_vehiculo_nuevo';
      }

      const dataLayerEvent: any = {
        event: eventName,
        page: urlPage,
        pagename: document.title,
      };

      sendDataLayerEvent(dataLayerEvent);
    }
  };

  const _handleSetCar = () => {
    const selectedCar: any = state.cars.find((item: any) => item.selected);
    const payload = {
      plate: selectedCar.plate,
      year: selectedCar.year,
      model: {
        value: selectedCar.info.model.id,
        label: selectedCar.info.model.label,
      },
      brand: {
        value: selectedCar.info.brand.id,
        label: selectedCar.info.brand.label,
      },
      size: selectedCar.info.size,
      service: 'lub',
      IdItemDefault: selectedCar.info?.IdItemDefault,
    };

    /** CLOSE MODAL */
    _handleState(false, 'selectCar');

    const parent_service: any = available_services.find((as: any) => as.PK === props.PK && as.SK === props.SK);

    /**
     * IF HAS ID IN URL, CHECK IF ID IS VALID
     * IF ID IS VALID, UPDATE SERVICE
     * IF NOT, CREATE A NEW SERVICE
     */
    if (urlParams.has("id")) {
      const existsServiceIntoState = services.find((service: any) => service.id === urlParams.get('id'));
      const existsServiceIntoShoppingCart = shopping_cart.services.find((service: any) => service.id === urlParams.get('id'));

      if (existsServiceIntoState) {
        existsServiceIntoState.car = payload;
        dispatch(update_service({ ...existsServiceIntoState }));
      }

      if (existsServiceIntoShoppingCart) {
        existsServiceIntoShoppingCart.car = payload;
        dispatch(update_service_to_cart({ ...existsServiceIntoState }));
      }

      if (!existsServiceIntoState && !existsServiceIntoShoppingCart) {
        dispatch(create_service({
          id: id,
          PK: props.PK,
          SK: props.SK,
          module: 'lub',
          car: payload,
          slug: params.slug,
          is_service_in_cart: false,
          info: { ...parent_service.info },
          isFullPointsAvailable: parent_service.isFullPointsAvailable,
        }));
      }
    }

    if (!urlParams.has("id")) {
      dispatch(create_service({
        id: id,
        PK: props.PK,
        SK: props.SK,
        module: 'lub',
        car: payload,
        slug: params.slug,
        is_service_in_cart: false,
        info: { ...parent_service.info },
        isFullPointsAvailable: parent_service.isFullPointsAvailable,
      }));
    }

    dispatch(close_cart());

    let urlPage: any = '/services/cambio-de-aceite/paso2/agregar-vehiculo';
    let eventName: any = 'aceite_vehiculo';

    if (urlParams.has("id")) {
      urlPage = '/services/cambio-de-aceite/paso2.1/agregar-vehiculo-nuevo';
      eventName = 'aceite_vehiculo_nuevo';
    }

    const dataLayerEvent: any = {
      event: eventName,
      page: urlPage,
      pagename: document.title,
    };

    sendDataLayerEvent(dataLayerEvent);
  };

  const _getBrands = async () => {
    try {
      const response = await Car.listBrands();
      const { data } = response;
      const brands = data.map((brand: any) => {
        return {
          value: brand.IdMarca,
          label: brand.NombreMarca,
        };
      });
      return brands;
    } catch (err) {
      toast.error('No fue posible cargas las marcas');
    }
  };

  const _getTypes = async () => {
    try {
      const types: any = [];

      availableTypes.forEach((item: any) => {
        const type: any = carTypes.find((el: any) => el.IdTipoVehiculo === item);
        if (type) {
          types.push({
            value: type.IdTipoVehiculo,
            label: type.NombreTipoVehiculo,
          });
        }
      });

      return types;
    } catch (err) {
      toast.error('No fue posible cargar los tipos de vehículos.');
    }
  };

  const _handlePlate = async () => {
    const validations = await validate(PlateSchema, state.inputs);
    if (validations) {
      setState({ ...state, validations });
      return;
    }
    dispatch(LOAD_ON());
    try {
      const service = available_services.find((as: any) => as.info?.slug === params.slug);
      const response = await CarApiService._searchPlate({ plate: state.inputs.plate, eds_code: service?.eds?.eds_code || 0 });
      const { data } = response;
      if (!data) {
        const brands = await _getBrands();
        const types = await _getTypes();
        setState({
          ...state,
          brands,
          types,
          formState: { ...state.formState, plate: true, searchPlate: false },
          inputState: { ...state.inputState, plate: true },
          inputs: { ...state.inputs, model: '', brand: '', year: '', success: false, type: '' },
        });
      } else {
        const brands = [
          {
            value: data.IdMarca || 1,
            label: data.Marca || 'Marca no informada',
          },
        ];
        const models = [
          {
            value: data.IdModelo || 1,
            label: data.Modelo || 'Modelo no informado',
          },
        ];

        setState({
          ...state,
          brands,
          models,
          inputs: { ...state.inputs, model: models[0].value, brand: brands[0].value, year: data.Anho, success: true, type: data.TipoVehiculo, IdItemDefault: data.IdItemDefault },
          formState: { ...state.formState, plate: true, searchPlate: false },
          inputState: {
            plate: true,
            model: true,
            brand: true,
            year: true,
            type: true,
          },
          IdMedidaVehiculo: data.IdMedidaVehiculo,
        });
      }

      const dataLayerEvent: any = {
        event: 'aceite_patente',
        page: '/services/cambio-de-aceite/paso1/agregar-patente',
        pagename: document.title,
      };

      sendDataLayerEvent(dataLayerEvent);
    } catch (err) {
      toast.error('No fue posible validar la patente');
    }
    dispatch(LOAD_OFF());
  };

  const _handleBrand = async (e: any) => {
    dispatch(LOAD_ON());
    try {
      if (e === null) {
        setState({ ...state, models: [] });
        return;
      }
      const response = await Car.getModels(e);
      const { data } = response;
      const models = data.map((brand: any) => {
        return {
          value: brand.IdMarca,
          label: brand.NombreModelo,
        };
      });
      delete state.validations.brand;
      setState({ ...state, models, inputs: { ...state.inputs, brand: e } });
    } catch (err) {
      toast.error('No fue posible cargar los modelos');
    }
    dispatch(LOAD_OFF());
  };

  const _handleManualSection = (e: any) => {
    e.preventDefault();
    setState({ ...state, formState: { ...state.formState, manualData: true }, inputs: { ...state.inputs, brand: '', model: '', year: '' } });
  };

  const getMyVehicles = async () => {
    if (user.isLogged && props.show) {
      dispatch(LOAD_ON());
      try {
        const response = await sessionService.getVehicles();
        const vehicles: any = response.data.map((item: any) => {
          if (item.main) {
            item.selected = true;
          } else {
            item.selected = false;
          }
          return item;
        });

        if (vehicles?.length === 0) {
          setState({ ...INITIAL_STATE, cars: vehicles, formState: { ...INITIAL_STATE.formState, createCar: true } });
        } else {
          if (!vehicles.find((item: any) => item.selected)) {
            vehicles[0].selected = true;
          }

          setState({ ...INITIAL_STATE, cars: vehicles });
        }
      } catch (e) {
        toast.error('No fue posible buscar los vehículos');
      }
      dispatch(LOAD_OFF());
    } else {
      setState({ ...INITIAL_STATE, inputs: { ...INITIAL_STATE.inputs, plate: "AAAA11" }, formState: { ...INITIAL_STATE.formState, createCar: true } });
    }
  };

  const handleSelectedVehicle = (payload: any) => {
    state.cars?.map((item: any) => {
      if (item.SK === payload.SK) {
        item.selected = true;
      } else {
        item.selected = false;
      }
      return item;
    });
    setState({ ...state });
  };

  const _handleDontKnowPlate = async (e: any) => {
    e.preventDefault();
    try {
      dispatch(LOAD_ON());
      const brands = await _getBrands();
      const types = await _getTypes();
      dispatch(LOAD_OFF());
      setState({
        ...state,
        brands,
        types,
        formState: { ...state.formState, plate: true, searchPlate: false },
        inputState: { ...state.inputState, plate: true },
        inputs: { ...state.inputs, plate: 'AAAA11', model: '', brand: '', year: '', success: false, type: '' },
      });
    } catch (e: any) {
      dispatch(LOAD_OFF());
      toast.error('No fue posible cargar las marcas');
    }
  };

  return (
    <div
      className={`drawer-services-form-fields d-flex flex-column justify-content-between bg-color-white ${rounded ? 'rounded-20' : ''}`}
      style={{ overflowY: 'auto', overflowX: 'hidden', minWidth: '30%', maxWidth: '500px', maxHeight: '95vh' }}
    >
      <div className="w-100 position-relative">
        <div className="position-absolute" style={{ right: '5px', top: '5px' }}>
          <button className="btn" onClick={() => history.push('/')}>
            <Image image={Images.CloseBlue} />
          </button>
        </div>
      </div>

      {/* List of vehicles for logged in user */}

      {user && user.isLogged && !state.formState.createCar && (
        <div className="drawer-services-form-field">
          <Title size="md" text="Selecciona un vehículo" className="d-flex w-100 justify-content-center my-4 color-dark-blue bold" />

          {state.cars &&
            state.cars.map((car: any) => (
              <button className={`btn w-100 text-start`} onClick={() => handleSelectedVehicle(car)} key={car.SK}>
                <div className={`row rounded-10 border mx-2 bg-color-blue-light ${car.selected ? 'border border-2 border-primary' : ''}  py-3`}>
                  <div className="col-2 d-flex align-item-center justify-content-center">
                    <Image image={Images.MyCars} style={{ width: '25px' }} />
                  </div>
                  <div className="col-10">
                    <div className="bold-300 color-black size-09 pb-2">{`${car.info?.brand?.label} ${car.info?.model?.label}`}</div>
                    <div className="size-07">
                      Patente <span style={{ textTransform: 'uppercase' }}>{car.plate}</span>
                    </div>
                  </div>
                </div>
              </button>
            ))}

          <div className="w-100 row text-center mx-0 px-2 my-4">
            <div className="col-12 mb-2 d-flex justify-content-around">
              <button className="btn-default py-3 size-11 rounded-30" onClick={() => setState({ ...state, formState: { ...state.formState, createCar: true } })}>
                Agregar un vehículo
              </button>
              <button className="btn-default py-3 size-11 rounded-30" onClick={_handleSetCar}>
                Continuar
              </button>
            </div>
          </div>
        </div>
      )}

      {loader.loading && (
        <div className="mt-5">
          <Title size="md" text="Cargando información" className="d-flex w-100 justify-content-center my-4 color-dark-blue bold" />
        </div>
      )}

      {/* Add plate and search */}

      {!loader.loading && state.formState.createCar && (
        <div className="drawer-services-form-fields">
          <Title size="md" text="Agregar un vehículo" className="d-flex w-100 justify-content-center my-4 color-dark-blue bold" />

          {/* SEARCH PLATE */}
          <div className="row px-3 mb-3">
            <div  className="col-12 color-black position-relative">
              <div className="bold ps-2 mb-2">Ingresa la patente</div>
              <input
                className="input-default rounded-30 py-3 px-4"
                onChange={_handleChangeInput}
                value={state.inputs.plate}
                name="plate"
                style={{ textTransform: 'uppercase' }}
                disabled={state.inputState.plate}
                maxLength={6}
              />
              {!state.formState.searchPlate && <Image image={Images.CloseBlue} className="position-absolute" style={{ right: '30px', top: '50px' }} onClick={_clearInputs} />}
              <ErrorValidation className="ps-4 size-08" validations={state.validations} name="plate" />
              {state.formState.searchPlate ? (
                <div className="d-flex justify-content-center align-items-center color-dark-blue mt-2">
                  <a className="btn size-09 color-dark-blue bold" href="#" onClick={_handleDontKnowPlate}>
                    Ingresar patente manualmente
                  </a>
                </div>
              ) : null}
            </div>
          </div> 

          {state.formState.searchPlate && (
            <>
              <div className="d-flex justify-content-center align-items-center mt-2 m-4">
                <button className="btn-default py-3 w-50 size-11 rounded-30" onClick={_handlePlate}>
                  Confirmar
                </button>
              </div>
              <div className="w-100 px-4 pb-5 size-08">
                <b>¿Por qué debo ingresar la patente? </b>
                <br />
                <p className='ps-2'>
                  Con la patente podemos buscar en sistema el tamaño asociado al tipo de auto en sistema y te entregaremos los valores de servicios mas adecuados para ti y tu auto.
                </p>
              </div>
            </>
          )}

          {/* Select data with car from a list */}

          {state.formState.plate && !state.formState.manualData && (
            <SelectCarData state={state} _handleBrand={_handleBrand} _handleChangeInput={_handleChangeInput} _handleAddCar={_handleAddCar} _handleManualSection={_handleManualSection} />
          )}

          {/* Add manual car data */}

          {state.formState.plate && state.formState.manualData && (
            <SelectManualCarData state={state} _handleBrand={_handleBrand} _handleChangeInput={_handleChangeInput} _handleAddCar={_handleAddCar} _handleManualSection={_handleManualSection} />
          )}
        </div>
      )}

      <div className="adjustment-iphone" style={{ height: '15vh' }} />
    </div>
  );
};

export default SelectCar;
