import { createAction } from 'redux-actions'
import ServiceDataAccessObject from '../../services/ServiceDataAccessObject';
import { set_splash } from './splash_screen';

export const GET_SERVICES: any = createAction('GET_SERVICES');

export const getServices = () => async (dispatch: any) => {
  try {
    const services = await ServiceDataAccessObject.getAllServices();
    dispatch(GET_SERVICES(services.data))
    dispatch(set_splash({ direction_desktop: false, direction_mobile: false, direction: false }))
  } catch (e: any) {
    return Promise.reject(e)
  }
}
