import { handleActions } from 'redux-actions';
import { CrossSellingSetupSchema } from '../../mockups/cross_selling';
import { DELETE_CROSS_SELLING, SET_CROSS_SELLING } from '../actions/cross_selling';

export const INITIAL_STATE = { ...CrossSellingSetupSchema };

export default handleActions(
  {
    [SET_CROSS_SELLING]: (state: any, action: any) => {
      return { ...action.payload };
    },
    [DELETE_CROSS_SELLING]: () => {
      return { ...CrossSellingSetupSchema };
    },
  },
  INITIAL_STATE
);
