/* eslint-disable react-hooks/exhaustive-deps */
import { Loader } from '@googlemaps/js-api-loader';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Card from '../../components/Card';
import CardHomeDesktop from '../../components/CardHomeDesktop';
import DrawerDirections from '../../components/DrawerDirections';
import StickAddress from '../../components/StrickAddress';
import Title from '../../components/Title';
import { clearDataLayerEvent, createDataLayerObject, sendDataLayerEvent } from '../../helpers/data_layer';
import { set_available_services } from '../../redux/actions/available_services';
import { LOAD_OFF, LOAD_ON } from '../../redux/actions/loader';
import { set_recommended } from '../../redux/actions/recommended_subservices';
import Services from '../../services/services.service';
import sessionService from '../../services/session.service';
import { checkIfCopecFullPointsIsAvailable } from '../../helpers/copec_full_points';
import geozoneService from '../../services/geozone.service';
import GenericPopUp from '../../components/GenericPopUp';
import CompanyClientPopUp from '../../components/CompanyClientPopUp';

const INITIAL_STATE = {
  drawer: {
    script: {
      show: false,
      direction: 'right-left',
    },
    manual: {
      show: false,
      direction: 'right-left',
    },
    autocomplete: {
      show: false,
      direction: 'bottom-top',
    },
    history: {
      show: false,
      direction: 'bottom-top',
    },
  },
  modal_maps: { open: false },
  services: [],
  requestError: false,
  google: null,
  autocompleteService: null,
  addresses: [],
};

const AddressSet = () => {
  const history = useHistory();
  const [state, setState] = useState<any>({ ...INITIAL_STATE });
  const [genericPopUp, setGenericPopUp] = useState<any>({
    open: false,
    title: '',
    message: '',
  })
  const [companyClientPopUp, setCompanyClientPopUp] = useState<any>({
    open: false,
    title: '',
    message: '',
    accept: () => { },
    cancel: () => { },
  })
  const { matchMedia, available_eds, available_services, loader, directions } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const { user } = useSelector((state: any) => state);

  useEffect(() => {
    checkCompanyUser();
  }, [available_eds]);

  const isClientCompany = (user: any) => {
    return user?.profile?.info?.roles?.find((role: string) => role === 'client_company')
  }

  const checkCompanyUser = () => {
    if (isClientCompany(user)) {
      setCompanyClientPopUp({
        open: true,
        title: 'Cliente empresa detectado',
        message: '¿Continuar como cliente empresa?',
        accept: () => _handleInitComponent({ is_company: true }),
        cancel: () => _handleInitComponent({ is_company: false })
      })

      return
    }

    _handleInitComponent({ is_company: false })
  }

  const _handleInitComponent = async ({ is_company = false }: { is_company: boolean }) => {
    setCompanyClientPopUp({
      open: false,
      title: '',
      message: '',
      accept: () => { },
      cancel: () => { },
    })
    dispatch(LOAD_ON());
    dispatch(set_available_services([]));
    const availableEdsResponse: any = await geozoneService.search(directions?.latitude, directions?.longitude);

    try {
      const serviceResponse = await Services.list(is_company);
      const serviceList: any = serviceResponse.data.filter((item: any) => item.type !== 'recommended_subservice');
      const recommended_subservices: any = serviceResponse.data.find((item: any) => item.type === 'recommended_subservice');

      if (recommended_subservices) {
        const recommended_subservices_details = await Services.details(recommended_subservices);
        dispatch(set_recommended(recommended_subservices_details.data));
      } else {
        dispatch(set_recommended(null));
      }

      /** service list that will be shown on the screen */
      let services = [...serviceList];
      const availableServices: any = [];

      /**
       * check if service is active and has enabled operator
       */
      if (!is_company) {
        for (const eds of availableEdsResponse.data.data) {
          const service = services.find((s: any) => s.PK === eds.service_id);
          eds.code = eds.eds_code;
          if (service && eds.operator_enable) {
            /** check if service is active */
            let isThisServiceActive = false;
            if ("is_active" in eds) {
              isThisServiceActive = eds.is_active;
            } else {
              isThisServiceActive = eds?.info?.services_id.find((PK: any) => PK === service.PK);
            }

            service.code = eds.eds_code;
            service.eds = eds;
            service.active = isThisServiceActive ? true : false;

            if (service.active) availableServices.push({ ...service });
          }
        }
      }

      if(is_company) {
        services.forEach((service:any) => {
          service.eds = {
            eds_code: service?.company?.eds?.code,
            code: service?.company?.eds?.code,
            eds_id: service?.company?.eds?.PK,
            eds_service_id: null,
            info: service?.company?.eds?.info,
            is_active: true,
            operator_enable: true,
            service_id: service.PK,
          }
          service.code = service?.company?.eds?.code;
          service.active = true;
          availableServices.push({ ...service });
        })
      }

      /**
       *  Check if a service has full points promo configured
       */

      for (const service of services) {
        let isFullPointsAvailable: boolean = checkIfCopecFullPointsIsAvailable(service);
        service.isFullPointsAvailable = isFullPointsAvailable;
      }

      /**
       * Prioritizes scheduled kerosene when both types are available
       */

      const keroseneSchedule: any = availableServices.find((service: any) => service.type === 'kerosene-schedule');
      const kerosene: any = availableServices.find((service: any) => service.type === 'kerosene');

      if (keroseneSchedule && kerosene) {
        services = services.filter((service: any) => service.type !== 'kerosene');
      }

      /**
       * Hide services
       */

      const dontShow: any = [];

      services.forEach((service: any) => {
        if (!service.active && service.info?.dont_show_grey_service) {
          dontShow.push(service.PK);
        }
      });

      dontShow.forEach((PK: any) => {
        const serviceIndex: any = services.findIndex((el: any) => el.PK === PK);
        services.splice(serviceIndex, 1);
      });

      /**
       * Sort services
       */

      let activeServices: any = services.filter((service: any) => service.active);

      activeServices = activeServices.map((service: any) => {
        return {
          ...service,
          url: service.info.slug,
          id: service.PK,
        };
      });

      activeServices.sort((a: any, b: any) => {
        if (a.name > b.name) return 1;
        if (a.name < b.name) return -1;
        return 0;
      });

      services.sort((a: any, b: any) => {
        if (a.name > b.name) return 1;
        if (a.name < b.name) return -1;
        return 0;
      });

      /**
       * DataLayer Events
       */

      const dataLayerItems: any = [];

      services.forEach((service: any, index: any) => {
        if (activeServices.find((el: any) => el.PK === service.PK)) {
          const item: any = {
            item_name: service.name,
            item_id: service.PK,
            price: '',
            item_brand: service.name?.toLowerCase(),
            item_category: 'service',
            item_category2: '',
            item_category3: '',
            item_category4: '',
            item_variant: '',
            item_list_name: 'show_available_services',
            item_list_id: '',
            index: index,
            quantity: 1,
          };
          dataLayerItems.push(item);
        }
      });

      clearDataLayerEvent();
      const dataLayerEvent: any = createDataLayerObject('view_item_list', dataLayerItems);
      sendDataLayerEvent(dataLayerEvent);

      dispatch(set_available_services(activeServices));

      /**
       *  Get address and filter history address
       *  @addresses
       */
      const addresses: any = await _getAddress();
      const historyAddress: any = addresses?.filter((address: any) => address?.info?.history);

      if (historyAddress.length > 0) {
        historyAddress.sort((a: any, b: any) => {
          if (moment(a.info?.history_date).isAfter(b.info?.history_date)) return -1;
          if (moment(a.info?.history_date).isBefore(b.info?.history_date)) return 1;
          return 0;
        });

        const elementsToShow: any = historyAddress.slice(0, 3);
        const elementsToDelete: any = historyAddress.slice(3, historyAddress.length);

        for (const el of elementsToDelete) {
          sessionService.deleteAddress(el.SK);
        }

        state.addresses = elementsToShow;
      } else {
        state.addresses = [];
      }

      if (!state.google) {
        const loader: any = new Loader({
          apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY ? process.env.REACT_APP_GOOGLE_MAPS_API_KEY : '',
          version: 'weekly',
          libraries: ['places', 'geometry'],
          ...{},
        });

        loader.load().then((google: any) => {
          const autocompleteService = new google.maps.places.AutocompleteService();
          setState({ ...state, services: services, google, api_key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY, autocompleteService });
        });
      } else {
        const autocompleteService = new state.google.maps.places.AutocompleteService();
        setState({ ...state, services: services, inputs: { ...state.inputs, coordinates: '' }, autocompleteService });
      }
    } catch (err) {
      setState({ ...state, requestError: true });
    }

    dispatch(LOAD_OFF());
  };

  const _getAddress = async () => {
    if (user.isLogged) {
      try {
        dispatch(LOAD_ON());
        const address: any = await sessionService.getAddresses();
        dispatch(LOAD_OFF());
        return address.data;
      } catch (e: any) {
        dispatch(LOAD_OFF());
        return [];
      }
    }

    return [];
  };

  const _toogleDrawer = (target: any = null) => {
    if (matchMedia.media === 'xs') {
      if (target !== null) {
        state.drawer[target].show = !state.drawer[target].show;
        setState({ ...state });
      }
    } else {
      state.modal_maps.open = !state.modal_maps.open;
      setState({ ...state });
    }
  };

  const _closeAndOpen = (target: any) => {
    Object.keys(state.drawer).forEach((key) => {
      state.drawer[key].show = false;
    });
    setState({ ...state });

    /* TIMEOUT EFFECT */
    setTimeout(() => {
      state.drawer[target].show = true;
      setState({ ...state });
    }, 200);
  };

  const _handleDisabledMessage = (data: any) => {
    setGenericPopUp({
      open: true,
      title: data.name,
      message: data.disabledMessage,
    })
  }

  return (
    <>
      <DrawerDirections state={state} _toogleDrawer={_toogleDrawer} _closeAndOpen={_closeAndOpen} google={state.google} autocompleteService={state.autocompleteService} addresses={state.addresses} />
      <StickAddress _toogleDrawer={_toogleDrawer} addresses={state.addresses} />
      <GenericPopUp {...genericPopUp} toggleModal={() => setGenericPopUp({ ...genericPopUp, open: false })} />
      <CompanyClientPopUp {...companyClientPopUp} />

      <div className="row mt-5 p-3 justify-content-center">
        {loader.loading === true && (
          <div className="col-12 text-center">
            <Title text="Buscando servicios cercanos a tu dirección..." className="color-dark-blue" />
          </div>
        )}

        {available_services && available_services.length > 0 && (
          <>
            {matchMedia && matchMedia.media && matchMedia.media === 'xs' ? (
              <Title size="xl" text="Servicios disponibles en tu dirección" className="size-07 mb-4 text-center color-dark-blue px-0" />
            ) : (
              <Title size="xxl" text="Servicios disponibles en tu dirección" className="d-flex w-100 justify-content-start mb-5 color-dark-blue px-5" />
            )}
          </>
        )}

        {loader.loading === false && available_services && available_services.length === 0 && (
          <div className="col-12 text-start mt-2 mb-5">
            <Title text="No hay servicios disponibles en la dirección ingresada." className="color-dark-blue" />
          </div>
        )}

        {/* Desktop service list */}

        <div className="w-100 d-flex justify-content-center mb-5 d-none d-sm-flex" style={{ flexWrap: 'wrap', minHeight: '50vh' }}>
          {loader.loading === false &&
            state.services &&
            state.services.map((service: any) => (
              <div className="mx-3 mb-4 color-green-base" key={service.PK}>
                <CardHomeDesktop
                  service={service}
                  image={service.info?.gallery?.url ? { default: { src: service.info?.gallery?.url } } : null}
                  title={service.name}
                  action={() => history.push(`/services/${service.info.slug}`)}
                  description={service.descripcion}
                  disabled={!service.active}
                  btnText="Pide aquí"
                  fullpoints={service.isFullPointsAvailable}
                  handleDisabledMessage={(data: any) => _handleDisabledMessage(data)}
                />
              </div>
            ))}
        </div>

        {/* Mobile service list */}

        <div className="d-sm-none">
          {loader.loading === false &&
            state.services &&
            state.services.map((service: any) => (
              <div className="mx-1 mb-4 color-green-base" key={service.PK}>
                <Card
                  image={service.info?.gallery?.url}
                  title={service.name}
                  subtitle="Pide Aquí"
                  action={() => history.push(`/services/${service.info.slug}`)}
                  direction
                  key={service.PK}
                  disabled={!service.active}
                  fullpoints={service.isFullPointsAvailable}
                  service={service}
                />
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export default AddressSet;
