/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react';
import ButtonBack from '../ButtonBack';
import Carousel from '../Carousel';
import Select from '../Select';
import Title from '../Title';
import moment from 'moment';
import 'moment/locale/es';
import '../../styles/components/component.datetimepicker.scss';
import getDates, { _createDayComponent } from './getDates';
import Image from '../Image';
import Images from '../../assets/image';
import { v4 } from 'uuid';
import Schedule from '../../services/schedule.service';
import { useDispatch, useSelector } from 'react-redux';
import { LOAD_OFF, LOAD_ON } from '../../redux/actions/loader';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';

moment.locale('es');

const DateTimePicker = (props: any) => {
  const { onContinue, onClose, show, module, service, mainService, showBlocks } = props;
  const { shopping_cart } = useSelector((state: any) => state);
  const urlParams: any = new URLSearchParams(window.location.search);
  const params: any = useParams();

  const [state, setState] = useState<any>({
    months: [],
    hoursComponents: [],
    daysComponents: [],
    inputs: {
      month: -1,
    },
    emptyResults: false,
    updates: 0,
  });

  const [day, setDay] = useState<any>(null);
  const [hour, setHour] = useState<any>(null);
  const [activeIndexDays, setActiveIndexDays] = useState<any>(0);
  const [activeIndexHours, setActiveIndexHours] = useState<any>(0);
  const [activeIndexDaysMobile, setActiveIndexDaysMobile] = useState<any>(0);
  const [activeIndexHoursMobile, setActiveIndexHoursMobile] = useState<any>(0);
  const { loader } = useSelector((state: any) => state);

  const carouselRefDaysMobile = useRef<any>(null);
  const carouselRefTimeMobile = useRef<any>(null);
  const carouselRefDays = useRef<any>(null);
  const carouselRefTime = useRef<any>(null);
  const dispatch = useDispatch();

  const CAROUSEL_CONFIGS_DAYS = {
    disableButtonsControls: true,
    disableDotsControls: true,
    infinite: false,
    autoPlay: false,
    animationDuration: 500,
    paddingRight: 20,
    responsive: {
      0: {
        items: 4,
      },
      1024: {
        items: 5,
      },
      1338: {
        items: 7,
      },
    },
  };

  const CAROUSEL_CONFIGS_HOURS = {
    disableButtonsControls: true,
    disableDotsControls: true,
    infinite: false,
    autoPlay: false,
    animationDuration: 500,
    paddingRight: 0,
    responsive: {
      0: {
        items: 3,
      },
      1024: {
        items: 5,
      },
      1338: {
        items: 5,
      },
    },
  };

  useEffect(() => {
    if (show) {
      _getAvailableDays();
    } else {
      setState({
        months: [],
        hoursComponents: [],
        daysComponents: [],
        inputs: {
          month: -1,
        },
        emptyResults: false,
        updates: 0,
      });
    }
  }, [show]);

  useEffect(() => {
    setTimeout(() => {
      const root: any = document.querySelector('html');
      if (!shopping_cart.open) {
        if (show) {
          root.style.overflowY = 'hidden';
        } else {
          root?.removeAttribute('style');
        }
      }
    }, 1);
  }, [shopping_cart.open, show]);

  useEffect(() => {
    if (show) {
      const daysComponents = state.days.map((item: any) => {
        const component: any = _createDayComponent(item, _handleDay);
        return component;
      });

      /**
       * ADD NEXT MONTH TO END OF DAYS LIST
       */

      const month: any = state.months.findIndex((item: any) => parseInt(item.value) === parseInt(state.inputs.month));
      const nextMonth: any = state.months[month + 1];

      if (nextMonth) {
        const monthComponent: any = <div className='component-day-item text-center bold color-blue-base' style={{ maxWidth: '120px' }} onClick={() => _getAvailableDays(nextMonth?.value?.toString())}>{nextMonth?._string}</div>;
        daysComponents.push(monthComponent);
      }

      setState({ ...state, daysComponents });
    }
  }, [state.updates]);

  useEffect(() => {
    if (day) {
      setActiveIndexDays(carouselRefDays.current?.state?.activeIndex);
    }

    if (hour) {
      setActiveIndexHours(carouselRefTime.current?.state?.activeIndex);
      setActiveIndexHoursMobile(carouselRefTimeMobile.current?.state?.activeIndex);
    }
  }, [day, hour]);

  const _getAvailableDays = async (month: any = null) => {
    const data: any = getDates(_handleHour, null, month);
    if (month) {
      setActiveIndexDays(0)
      setActiveIndexDaysMobile(0)
    }

    dispatch(LOAD_ON());
    try {
      const days: any = data.days.reduce((acc: any, current: any) => {
        acc.push(current._moment.format('YYYY-MM-DD'));
        return acc;
      }, []);


      const scheduleParams = {
        eds_id: props.mainService?.eds?.eds_id,
        service_id: props.mainService?.PK,
        date: days,
      };

      if (urlParams.has('id')) {
        const IsServiceInCart = shopping_cart.services.find((item: any) => item.id === urlParams.get('id'))
        if (IsServiceInCart) {
          scheduleParams.eds_id = IsServiceInCart.eds?.eds_id
          scheduleParams.service_id = IsServiceInCart.PK
        }
      }

      const daysResponse: any = await Schedule.getAvailableTime(scheduleParams);

      let unvailableBlocks: any = [];


      if (!urlParams.has('id')) {
        unvailableBlocks = shopping_cart.services
          .filter((service: any) => service.slug === params.slug)
          .map((service: any) => {
            const block_date_time = moment(service.schedule?.hour?._moment)
            return {
              date: block_date_time.format('YYYY-MM-DD'),
              time: block_date_time.format('HH:mm'),
            }
          })
      }

      if (urlParams.has('id')) {
        unvailableBlocks = shopping_cart.services
          .filter((service: any) => service.slug === params.slug && service.id !== urlParams.get('id'))
          .map((service: any) => {
            const block_date_time = moment(service.schedule?.hour?._moment)
            return {
              date: block_date_time.format('YYYY-MM-DD'),
              time: block_date_time.format('HH:mm'),
            }
          })
      }

      data.days.map((item: any) => {
        const date_check: any = item._moment.format('YYYY-MM-DD');
        const db_item: any = daysResponse.data.find((day: any) => day.date_check === date_check);
        item.disabled = false;

        if (db_item.schedule?.status && db_item.schedule?.status === 'error') {
          item.disabled = true;
        }

        if (db_item.schedule?.blocks && db_item.schedule?.blocks.length === 0) {
          item.disabled = true;
        }

        item.blocks = db_item.schedule?.blocks;
        item.duration = db_item.schedule?.duration;
        item.coverage = db_item.schedule?.schedule_coverage?.coverage

        if (item.blocks) {
          item.blocks?.map((block: any) => {
            if (block?.nota?.includes('almuerzo')) block.lunch_time = true;
            return block;
          });

          item.blocks = item.blocks.filter((block: any) => !block.lunch_time);

          item.blocks.map((block: any) => {
            block.parent_ref = { id: item.id };
            if (block.enable === false) return block;

            const block_time: any = moment(`${block.date} ${block.time}`);
            const _ruleMoment: any = moment(`${block.date} ${block.time}`).subtract(mainService?.info?.min_reservation_time, 'hours');
            const now: any = moment();

            if (unvailableBlocks.find((item: any) => item.date === block.date && item.time === block.time)) {
              block.enable = false;
            }

            if (now.isAfter(block_time)) {
              block.enable = false;
            }

            if (now.isAfter(_ruleMoment)) {
              block.enable = false;
            }

            if (item.coverage && item.coverage?.length > 0) {
              block._moment = moment(block.date + ' ' + block.time);
              let endBlock: any = moment(block._moment.toString()).add(item.duration, 'minutes');
              const coverage: any = item.coverage[0];
              const [endHour, endMinute] = coverage?.end?.split(':');
              const endWorkDay: any = moment(block._moment.toString()).set({ hour: endHour, minute: endMinute });
              if (endBlock.isAfter(endWorkDay)) {
                block.enable = false;
              }
            }

            return block;
          });

          const blockDisables: any = db_item.schedule?.blocks.filter((block: any) => block.enable === false);

          if (blockDisables.length === db_item.schedule?.blocks.length) {
            item.disabled = true;
          }
        }

        return item;
      });

      // fn to auto select a day and hour

      let firstDayAvailable: any = null;
      let firstHourAvailable: any = null;

      data.days.forEach((item: any) => {
        if (!item.disabled && !firstDayAvailable) {
          if (item.blocks && item.blocks.length > 0) {
            const availableHour: any = item.blocks.find((block: any) => block.enable === true);
            if (availableHour) {
              firstDayAvailable = item;
              firstHourAvailable = availableHour;
            }
          }
        }
      })

      data.schedules = daysResponse.data;

      if (firstDayAvailable) {
        _autoSelectDayAndHour(data, firstDayAvailable, firstHourAvailable)
      } else {
        const daysComponents = data.days.map((item: any) => {
          const component: any = _createDayComponent(item, _handleDay);
          return component;
        });

        data.daysComponents = daysComponents;
        setState({ ...state, ...data, updates: state.updates + 1 });
        setHour(null);
      }
      dispatch(LOAD_OFF());
    } catch (e: any) {
      toast.error('No pudimos encontrar ningún día disponible para este mes');
      dispatch(LOAD_OFF());
    }
  };

  const _handleDay = async (payload: any) => {
    dispatch(LOAD_ON());
    setDay(payload);
    setState((prevState: any) => {
      return { ...prevState, hoursComponents: [], emptyResults: false };
    });
    try {
      const data = {
        eds_id: props.mainService?.eds?.eds_id,
        service_id: props.mainService?.PK,
        date: payload._moment.format('YYYY-MM-DD'),
      };
      let blocks: any = [];

      if (state.schedules) {
        const selected_day = state.schedules.find((item: any) => item.date_check === data.date);
        if (selected_day) {
          blocks = selected_day.schedule?.blocks ? selected_day.schedule?.blocks : [];
        } else {
          const response: any = await Schedule.getAvailableTime(data);
          blocks = response.data.schedule.blocks;
        }
      }

      blocks.map((block: any) => {
        if (block?.nota?.includes('almuerzo')) block.lunch_time = true;
        return block;
      });

      blocks = blocks.filter((block: any) => !block.lunch_time);

      setActiveIndexHours(0)
      setActiveIndexHoursMobile(0)

      // Fake load time
      setTimeout(() => {
        dispatch(LOAD_OFF());
        setState((prevState: any) => {
          prevState.days.map((item: any) => {
            item.selected = false;
            if (payload.day === item.day && payload._string === item._string) {
              item.selected = true;
            }
            return item
          })

          if (blocks && blocks.length > 0) {
            blocks.map((block: any) => {
              block.selected = false;
              return block
            })
          }
          const data: any = getDates(_handleHour, payload, prevState.inputs.month, blocks ? blocks : [], showBlocks);
          const { inputs, hoursComponents } = data;
          const emptyResults: any = data.hoursComponents.length > 0 ? false : true;
          return { ...prevState, inputs, hoursComponents, emptyResults, updates: prevState.updates + 1 };
        });
      }, 300);

      setHour(null);
      _clearAllTimeElements();
    } catch (e: any) {
      setState((prevState: any) => {
        return { ...prevState, emptyResults: true };
      });
    }
  };

  const _autoSelectDayAndHour = (data: any, day: any, hour: any) => {
    let dayIndex: number = 0;
    let hourIndex: number = 0;

    const daysComponents = data.days.map((item: any, index: number) => {
      if (day.day === item.day && day._string === item._string) {
        item.selected = true;
        setDay(item);
        dayIndex = index;
      }
      const component: any = _createDayComponent(item, _handleDay);
      return component;
    });

    data.daysComponents = daysComponents;

    day.blocks.map((item: any, index: number) => {
      if (item.date === hour.date && item.time === hour.time && item.enable) {
        item.selected = true;
        hourIndex = index;
      }
      return item;
    });

    const HourData: any = getDates(_handleHour, day, data.inputs.month, day.blocks || [], showBlocks);
    const { inputs, hoursComponents, hours } = HourData;
    const selectedHour: any = hours.find((item: any) => item.selected === true);
    setHour(selectedHour);
    const emptyResults: any = HourData.hoursComponents.length > 0 ? false : true;

    setState({ ...state, ...data, inputs, hoursComponents, emptyResults, updates: state.updates + 1 });
    setActiveIndexDays(dayIndex);
    setActiveIndexDaysMobile(dayIndex);
    setActiveIndexHours(hourIndex);
    setActiveIndexHoursMobile(hourIndex);

  }

  const _handleHour = (payload: any) => {
    setState((prevState: any) => {
      const day: any = prevState.days.find((item: any) => payload.parent_ref.id === item.id);
      day?.blocks.map((block: any) => {
        block.selected = false;
        if (block.time === payload._string) {
          block.selected = true;
        }
        return block;
      });

      const HourData: any = getDates(_handleHour, day, prevState.inputs.month, day.blocks || [], showBlocks);
      const { inputs, hoursComponents } = HourData;
      const emptyResults: any = HourData.hoursComponents.length > 0 ? false : true;

      return { ...prevState, inputs, hoursComponents, emptyResults, updates: state.updates + 1 };
    })
    setHour(payload);
  };

  const _handleChangeMonth = (e: any) => {
    setState({ ...state, daysComponents: [], hoursComponents: [] });
    _getAvailableDays(e.target.value);
  };

  const _clearAllTimeElements = () => {
    const elTime = document.querySelector('.time-item-selected');
    elTime?.classList.remove('time-item-selected');
  };

  const _saveDates = () => {
    const date = moment().month(state.inputs.month);
    if (date.month() < moment().month()) {
      date.add(1, 'year');
    }
    const schedule = {
      id: v4(),
      day,
      hour,
      month: {
        _string: date.format('MMMM'),
        _moment: moment(date),
      },
      service: {
        module,
        ...service,
      },
    };
    onContinue(schedule);
  };

  const _handleNextDay = () => {
    const lastItem: any = carouselRefDays.current.state.itemsCount - carouselRefDays.current.state.itemsInSlide;
    if (carouselRefDays.current.state.activeIndex === lastItem) {
      const month: any = state.months.findIndex((item: any) => parseInt(item.value) === parseInt(state.inputs.month));
      const nextMonth: any = state.months[month + 1];

      if (nextMonth) {
        _getAvailableDays(nextMonth?.value?.toString())
      }
    } else {
      carouselRefDays.current?.slideNext();
    }
  };

  const _handlePrevDay = () => {
    carouselRefDays.current?.slidePrev();
  };

  const _nextSlideDayMobile = () => {
    const lastItem: any = carouselRefDaysMobile.current.state.itemsCount - carouselRefDaysMobile.current.state.itemsInSlide;
    if (carouselRefDaysMobile.current.state.activeIndex === lastItem) {
      const month: any = state.months.findIndex((item: any) => parseInt(item.value) === parseInt(state.inputs.month));
      const nextMonth: any = state.months[month + 1];

      if (nextMonth) {
        setActiveIndexDaysMobile(0)
        _getAvailableDays(nextMonth?.value?.toString())
      }
    } else {
      carouselRefDaysMobile.current?.slideNext();
    }
  };

  const _prevSlideDayMobile = () => {
    if (activeIndexDaysMobile > 0) {
      carouselRefDaysMobile.current.slidePrev();
    }
  };

  const _handleNextHour = () => {
    const lastItem: any = carouselRefTime.current.state.itemsCount - carouselRefTime.current.state.itemsInSlide;
    if (carouselRefTime.current.state.activeIndex !== lastItem) {
      carouselRefTime.current?.slideNext();
    }
  }

  const _handleNextHourMobile = () => {
    const lastItem: any = carouselRefTimeMobile.current.state.itemsCount - carouselRefTimeMobile.current.state.itemsInSlide;
    if (carouselRefTimeMobile.current.state.activeIndex !== lastItem) {
      carouselRefTimeMobile.current?.slideNext();
    }
  }

  const _onSlideDayChanged = (e: any) => {
    setActiveIndexDaysMobile(e.item);
  };

  return (
    <>
      {/* MOBILE VERSION */}

      <div className="w-100 d-flex justify-content-between flex-column h-100 d-sm-none ">
        <div className="component-datetimepicker">
          <div className="row mt-3">
            <ButtonBack text="Volver" action={onClose} />
          </div>
          <Title size="md" text="Selecciona una fecha y horario" className="d-flex w-100 justify-content-center mb-4 mt-2 color-dark-blue bold" />
          <Title size="sm" text="¿Cuándo quieres agendar tu servicio?" className="d-flex w-100 mx-2 my-4 bold size-09 px-2" />

          <div className="row mx-2">
            <div className="col-8">
              <Select
                title="Selecciona un mes"
                data={state.months}
                onChange={_handleChangeMonth}
                className="select-datepicker bold size-11 mx-0 px-0"
                value={state?.inputs?.month}
                template="datepicker"
                name="month"
              />
            </div>

            <div className="col-4 d-flex justify-content-end align-items-center pr-3">
              <button onClick={_prevSlideDayMobile} className="btn color-blue-base bold">
                <Image image={Images.ArrowDown} style={{ transform: 'rotate(90deg)' }} />
              </button>
              <button onClick={_nextSlideDayMobile} className="btn color-blue-base bold">
                <Image image={Images.ArrowDown} style={{ transform: 'rotate(270deg)' }} />
              </button>
            </div>
          </div>

          <div className="row mx-2 mb-4">
            <div className="col-12">
              <Carousel ref={carouselRefDaysMobile} {...CAROUSEL_CONFIGS_DAYS} items={state.daysComponents} activeIndex={activeIndexDaysMobile} onSlideChanged={_onSlideDayChanged} />
            </div>
          </div>

          <div className="line-border pt-3" />

          <div className={`row ${loader.loading ? 'd-flex' : 'd-none'}`}>
            <Title text="Buscando horarios disponibles..." className="mt-5 text-center size-07 px-3 color-blue-base" />
          </div>

          <div className={`row ${!loader.loading && state.emptyResults === true ? 'd-flex' : 'd-none'}`}>
            <Title text="No encontramos horarios disponibles para este día" className="mt-5 px-5 text-center size-06 color-blue-base" />
          </div>

          <div className={`row mx-2 mb-2 ${state.hoursComponents && state.hoursComponents.length > 0 ? 'd-flex' : 'd-none'}`}>
            <Title size="sm" text="¿En qué horario quieres agendar tu servicio?" className="d-flex w-100 mb-4 mt-5 bold size-09 px-2" />
            <Title size="sm" text="Horarios disponibles" className="col-8 d-flex bold size-11 color-blue-base justify-content-start align-items-center" />

            <div className="col-4 d-flex justify-content-end align-items-center pr-3">
              <button onClick={() => carouselRefTimeMobile.current?.slidePrev()} className="btn color-blue-base bold">
                <Image image={Images.ArrowDown} style={{ transform: 'rotate(90deg)' }} />
              </button>
              <button onClick={_handleNextHourMobile} className="btn color-blue-base bold">
                <Image image={Images.ArrowDown} style={{ transform: 'rotate(270deg)' }} />
              </button>
            </div>
          </div>

          <div className={`row mx-2 ${state.hoursComponents && state.hoursComponents.length > 0 ? 'd-flex' : 'd-none'}`}>
            <div className="col-12">
              <Carousel ref={carouselRefTimeMobile} {...CAROUSEL_CONFIGS_HOURS} items={state.hoursComponents} activeIndex={activeIndexHoursMobile} />
            </div>
          </div>
        </div>

        <div className="row mx-3 my-5 pb-3">
          <div className="col-12">
            <button className="btn-default w-100 p-3 size-12 rounded-40" onClick={_saveDates} disabled={!hour}>
              Continuar
            </button>
          </div>
        </div>
      </div>

      {/* DESKTOP VERSION */}

      <div className="container-fluid bg-color-blue-light d-none d-sm-block" style={{ minHeight: '100vh' }}>
        <div className="row position-relative align-items-center pt-5">
          <div className="position-absolute">
            <ButtonBack text="Volver" action={onClose} style={{ left: '50px', fontSize: '1.2em' }} />
          </div>
          <div className="col-12 d-flex align-items-center">
            <Title size="xl" text="Selecciona una fecha y horario" className="d-flex w-100 justify-content-center mt-2 color-dark-blue bold" />
          </div>
        </div>

        <div className="row justify-content-center mt-5">
          <div className="col-8 bg-color-white rounded-25 py-5 px-5 border-default">
            {/* Select Month */}
            <Title size="md" text="¿Cuándo quieres agendar tu servicio?" className="d-flex w-100 mx-2 mt-4 mb-2 bold size-09 px-2" />
            <div className="row mx-2">
              <div className="col-4">
                <Select
                  title="Selecciona un mes"
                  data={state.months}
                  onChange={_handleChangeMonth}
                  className="select-datepicker bold size-11 mx-0 px-0"
                  value={state?.inputs?.month}
                  template="datepicker"
                  name="month"
                />
              </div>

              <div className="col-8 d-flex justify-content-end align-items-center pr-3">
                <button onClick={_handlePrevDay} className="btn color-blue-base bold">
                  <Image image={Images.ArrowDown} style={{ transform: 'rotate(90deg)' }} />
                </button>
                <button onClick={_handleNextDay} className="btn color-blue-base bold">
                  <Image image={Images.ArrowDown} style={{ transform: 'rotate(270deg)' }} />
                </button>
              </div>
            </div>

            {/* Select Date */}
            <div className="row mx-2 mb-4">
              <div className="col-12">
                <Carousel ref={carouselRefDays} {...CAROUSEL_CONFIGS_DAYS} items={state.daysComponents} activeIndex={activeIndexDays} />
              </div>
            </div>

            <div className="line-border pt-3" />

            <div className={`row ${loader.loading ? 'd-flex' : 'd-none'}`}>
              <Title text="Buscando horarios disponibles..." className="mt-5 text-center size-09 color-blue-base" />
            </div>

            <div className={`row ${!loader.loading && state.emptyResults === true ? 'd-flex' : 'd-none'}`}>
              <Title text="No se encontraron horas disponibles para este día" className="mt-5 text-center size-09 color-blue-base" />
            </div>

            <div className={`row mx-2 mb-2 ${state.hoursComponents && state.hoursComponents.length > 0 ? 'd-flex' : 'd-none'}`}>
              <Title size="md" text="¿En qué horario quieres agendar tu servicio?" className="d-flex w-100 mb-4 mt-5 bold size-09 px-2" />
              <Title size="sm" text="Horarios disponibles" className="col-8 d-flex bold size-11 color-blue-base justify-content-start align-items-center" />

              <div className="col-4 d-flex justify-content-end align-items-center pr-3">
                <button onClick={() => carouselRefTime.current?.slidePrev()} className="btn color-blue-base bold">
                  <Image image={Images.ArrowDown} style={{ transform: 'rotate(90deg)' }} />
                </button>
                <button onClick={_handleNextHour} className="btn color-blue-base bold">
                  <Image image={Images.ArrowDown} style={{ transform: 'rotate(270deg)' }} />
                </button>
              </div>
            </div>

            <div className={`row mx-2 mb-4 ${state.hoursComponents && state.hoursComponents.length > 0 ? 'd-flex' : 'd-none'}`}>
              <div className="col-12">
                <Carousel ref={carouselRefTime} {...CAROUSEL_CONFIGS_HOURS} items={state.hoursComponents} activeIndex={activeIndexHours} />
              </div>
            </div>
          </div>
        </div>

        <div className="row justify-content-center mt-4 pb-5">
          <div className="col-7 text-end">
            <button className="btn-default px-5 py-2 size-12 rounded-25 " onClick={_saveDates} disabled={!hour}>
              Continuar
            </button>
          </div>
        </div>
      </div>

      <div className="adjustment-iphone" style={{ height: '30vh' }} />
    </>
  );
};

export default DateTimePicker;
