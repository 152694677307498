import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import IImage from '../../interfaces/IImage'

const Image: React.FC<IImage> = (props) => {

  const [src, setSrc] = useState('')
  const [alt, setAlt] = useState('')
  const { className, image, ...rest } = props
  const { matchMedia } = useSelector((state: any) => state);
  const { media } = matchMedia;

  useEffect(() => {
    if (typeof image === 'object') {
      if (image[media]) {
        setSrc(image[media].src);
        setAlt(image[media].alt);
      } else {
        setSrc(image.default.src);
        setAlt(image.default.alt);
      }
    } else {
      setSrc("");
      setAlt("");
    }
  }, [media, image]);

  return (
    <img className={className || ''} src={src} alt={alt} {...rest} />
  )
}

export default Image