const WhoWillRecieve = (props: any) => {
  const { onChange, inputs } = props;

  return (
    <>
      <form>
        <div className="d-sm-none container-who-will">
          <div className="row px-2 mb-4">
            <div className="col-12 size-09 bold px-2 mt-4 pb-2 color-dark-blue line-border">¿Quién recibirá el pedido?</div>
          </div>

          <div className="row flex-column m-3" onChange={(e: any) => onChange({ target: { name: 'radio_delivery', value: e.target.value } })}>
            <div className={`col mb-2 border-default bg-color-blue-light p-3 rounded-10 ${parseInt(inputs.radio_delivery) === 1 ? 'bold' : ''}`}>
              <input type="radio" className="me-3" name="radio_delivery_mobile" value={1} defaultChecked />
              Lo recibiré personalmente
            </div>

            <div className={`col mb-2 border-default bg-color-blue-light p-3 rounded-10 ${parseInt(inputs.radio_delivery) === 2 ? 'bold' : ''}`}>
              <input type="radio" className="me-3" name="radio_delivery_mobile" value={2} />
              Lo recibirá un tercero
            </div>
          </div>
        </div>
      </form>

      {/* Version Desktop */}

      <form>
        <div className="d-none d-sm-block px-5 container-who-will-desktop">
          <div className="row mb-4 line-border">
            <div className="col-12 color-black  size-12 bold mt-4 pb-2">¿Quién recibirá el pedido?</div>
          </div>

          <div className="row mb-4 line-border pb-3" onChange={onChange}>
            <div className={`col ${parseInt(inputs.radio_delivery) === 1 ? 'bold' : 'bold-300'}`}>
              <input type="radio" className="me-3" name="radio_delivery" value={1} defaultChecked />
              Lo recibiré personalmente
            </div>

            <div className={`col ${parseInt(inputs.radio_delivery) === 2 ? 'bold' : 'bold-300'}`}>
              <input type="radio" className="me-3" name="radio_delivery" value={2} />
              Lo recibirá un tercero
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default WhoWillRecieve;
