import { v4 } from "uuid"
import ISelect from "../../interfaces/ISelect"
import '../../styles/components/component.select.scss';

const Select: React.FC<ISelect> = (props) => {

  const { data, title, onChange, className, label, value, template, name } = props
  const ref = v4();

  if (template) {
    switch (template) {
      case 'datepicker':
        return (
          <div className="container-select default">
            <select className={className} onChange={onChange} value={value} name={name}>
              <option value="-1" disabled>{title}</option>
              {data && data.map((item: any) => (
                <option value={item.value} key={v4()}>{item.name}</option>
              ))}

            </select>
          </div>
        )
      default: return <></>
    }
  } else {

    return (
      <div className="container-select default">
        <label htmlFor={ref}>{label}</label>
        <select className={className} onChange={onChange} value={value} name={name} id={ref}>
          <option value="-1" disabled>{title}</option>
          {data && data.map((item: any) => (
            <option value={item.value} key={v4()}>{item.name}</option>
          ))}

        </select>
      </div>
    )
  }
}

export default Select