/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import Images from '../../assets/image';
import Image from '../../components/Image';
import ServicePriceTableLub from '../../components/ServicePriceTableLub';
import Title from '../../components/Title';
import { send_message } from '../../redux/actions/broadcast';
import { LOAD_OFF, LOAD_ON } from '../../redux/actions/loader';
import productService from '../../services/product.service';
import Services from '../../services/services.service';
import '../../styles/components/component.lubinfo.scss';

const Lubinfo = () => {
  const history = useHistory();
  const dispatch: any = useDispatch();
  const [data, setData] = useState<any>(null);

  useEffect(() => {
    _getData();
  }, []);

  const _getData = async () => {
    try {
      dispatch(LOAD_ON());
      const servicesResponse = await Services.list();
      const lavamax: any = servicesResponse.data.find((service: any) => service.type === 'lub');

      const filteredServices: any = lavamax.sub_services.filter((ss: any) => ss.name?.toLowerCase() !== 'envío' && ss.name?.toLowerCase() !== 'envio');

      filteredServices.sort((a: any, b: any) => {
        if (a.price > b.price) return -1;
        if (a.price < b.price) return 1;
        return 0;
      });

      const services: any = [];

      for (const service of filteredServices) {
        try {
          if (service.info?.extra_lubricant) {
            const responseProduct: any = await productService.read(service.info?.extra_lubricant?.PK, service.info?.extra_lubricant?.SK);
            service.extra = responseProduct?.data?.price;
            services.push({ ...service });
          } else {
            service.extra = parseInt(service.price) / 4;
            services.push({ ...service });
          }
        } catch (e: any) {
          service.extra = parseInt(service.price) / 4;
          services.push({ ...service });
        }
      }
      dispatch(LOAD_OFF());
      if (services) {
        setData({ sub_services: services });
        return;
      }

      throw new Error('No fue posible cargar los precios.');
    } catch (e: any) {
      dispatch(LOAD_OFF());
      toast.error('No fue posible cargar los precios de los servicios.');
    }
  };

  const _openDirectionsModal = () => {
    try {
      dispatch(send_message({ open_directions: true }));
      history.push('/');
    } catch (e: any) {
      toast.error("No fue posible completar esta acción. Intente volver a la página principal y haga clic en el botón 'Ingresa tu dirección'");
    }
  };

  return (
    <>
      {/* Version Mobile */}
      <div className="container-lubinfo d-lg-none">
        <Title size="md" text="Nuestro servicio incluye:" className="color-dark-blue text-center mt-3" />
        <div className="container-lubinfo__info mt-4">
          <div className="row align-items-start px-4 pb-3 size-09">
            <div className="col-1">
              <Image image={Images.BulletInfo} />
            </div>
            <div className="col-11">Lubricante Mobil (4 litros).</div>
          </div>

          <div className="row align-items-start px-4 pb-3 size-09">
            <div className="col-1">
              <Image image={Images.BulletInfo} />
            </div>
            <div className="col-11">Filtro de aceite.</div>
          </div>

          <div className="row align-items-start px-4 pb-3 size-09">
            <div className="col-1">
              <Image image={Images.BulletInfo} />
            </div>
            <div className="col-11">Revisión de niveles.</div>
          </div>

          <div className="row align-items-start px-4 pb-3 size-09">
            <div className="col-1">
              <Image image={Images.BulletInfo} />
            </div>
            <div className="col-11">Agua lavaparabrisas (1 litro) .</div>
          </div>

          <div className="row align-items-start px-4 pb-3 size-08">
            <div className="col-12">* Puedes comprar un filtro de aire por separado.</div>
          </div>
        </div>

        <Title size="md" text="Principales beneficios:" className="color-dark-blue text-center mt-3" />
        <div className="container-lubinfo__info mt-4 mb-4">
          <div className="row align-items-start px-4 pb-3 size-09">
            <div className="col-1">
              <Image image={Images.BulletInfo} />
            </div>
            <div className="col-11">Acumula puntos Full Copec.</div>
          </div>

          <div className="row align-items-start px-4 pb-3 size-09">
            <div className="col-1">
              <Image image={Images.BulletInfo} />
            </div>
            <div className="col-11">Agenda online y llegamos a tu casa.</div>
          </div>

          <div className="row align-items-start px-4 pb-3 size-09">
            <div className="col-1">
              <Image image={Images.BulletInfo} />
            </div>
            <div className="col-11">Te recomendamos el mejor aceite según la marca y modelo de tu vehículo.</div>
          </div>

          <div className="row align-items-start px-4 pb-3 size-09">
            <div className="col-1">
              <Image image={Images.BulletInfo} />
            </div>
            <div className="col-11">Variedad y confiabilidad de productos.</div>
          </div>
        </div>

        <div className="container-lubinfo__info mt-4 mb-4">
          <div className="row align-items-start px-4 pb-3 size-09">
            <div className="col-12 d-flex justify-content-center align-items-start">{data && 'sub_services' in data ? <ServicePriceTableLub data={data} /> : null}</div>
          </div>
        </div>

        <div className="container-lubinfo__info mt-4 mb-4">
          <div className="row align-items-start px-0 pb-3 size-09">
            <div className="col-12 d-flex justify-content-center align-items-center px-4">
              <iframe
                height={300}
                src={process.env.REACT_APP_LUB_VIDEO}
                title="Cambio de aceite"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
                className="rounded-10 shadow mt-4 w-100"
              ></iframe>
            </div>
          </div>
        </div>

        <div className="row mb-4">
          <div className="col-12 text-center px-5">
            <button className="btn-default py-2 px-5 size-12 rounded-40" onClick={_openDirectionsModal}>
              Agenda Aquí
            </button>
          </div>
        </div>
      </div>

      {/* Version Desktop  */}
      <div className="container-fluid d-none d-lg-block px-5 mx-5">
        <h1 className="text-start p-0 mt-3 bold-300 color-dark-blue">Cambio de aceite a domicilio</h1>
        <div className="row px-5 mx-5">
          <div className="col-6 mb-5">
            <div className="row size-11">
              <div className="col-12">
                <Title size="md" text="Nuestro servicio incluye:" className="color-dark-blue mt-5 size-12" />
              </div>

              <div className="col-12 size-09">
                <div className="row color-black my-3">
                  <div className="col-12">
                    <Image image={Images.BulletInfo} className="me-3" />
                    Lubricante Mobil (4 litros).
                  </div>
                </div>

                <div className="row color-black my-3">
                  <div className="col-12">
                    <Image image={Images.BulletInfo} className="me-3" />
                    Filtro de aceite.
                  </div>
                </div>

                <div className="row color-black my-3">
                  <div className="col-12">
                    <Image image={Images.BulletInfo} className="me-3" />
                    Revisión de niveles.
                  </div>
                </div>

                <div className="row color-black my-3">
                  <div className="col-12">
                    <Image image={Images.BulletInfo} className="me-3" />
                    Agua lavaparabrisas (1 litro)
                  </div>
                </div>

                <div className="row align-items-start px-1 pb-3">
                  <div className="col-12">* Puedes comprar un filtro de aire por separado.</div>
                </div>
              </div>

              <div className="col-12">
                <Title size="md" text="Principales beneficios:" className="color-dark-blue size-12 my-3" />
              </div>

              <div className="col-12 size-09">
                <div className="row color-black my-3">
                  <div className="col-12">
                    <Image image={Images.BulletInfo} className="me-3" />
                    Acumula puntos Full Copec.
                  </div>
                </div>

                <div className="row color-black my-3">
                  <div className="col-12">
                    <Image image={Images.BulletInfo} className="me-3" />
                    Agenda online y llegamos a tu casa.
                  </div>
                </div>

                <div className="row color-black my-3">
                  <div className="col-12">
                    <Image image={Images.BulletInfo} className="me-3" />
                    Te recomendamos el mejor aceite según la marca y modelo de tu vehículo.
                  </div>
                </div>

                <div className="row color-black my-3">
                  <div className="col-12">
                    <Image image={Images.BulletInfo} className="me-3" />
                    Variedad y confiabilidad de productos.
                  </div>
                </div>

                <div className="row color-black my-3">
                  <div className="col-12">
                    <Image image={Images.BulletInfo} className="me-3" />
                    Atención de profesionales altamente capacitados.
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-6 d-flex flex-column align-items-center">
            <div className="w-100">{data && 'sub_services' in data ? <ServicePriceTableLub data={data} /> : null}</div>
            <iframe
              width="500"
              height="300"
              src={process.env.REACT_APP_LUB_VIDEO}
              title="Lavamax"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
              className="rounded-10 shadow mt-4"
            ></iframe>
          </div>
        </div>

        <div className="row mb-4">
          <div className="col-12 text-center px-5">
            <button className="btn-default py-2 px-5 size-12 rounded-40" onClick={_openDirectionsModal}>
              Agenda Aquí
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Lubinfo;
