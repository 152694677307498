import store from '../redux/store';
import instance from './api_overt';
class RateService {
  _setHeaders = () => {
    const state: any = store.getState();
    const { user } = state;
    if (user.isLogged) {
      instance.defaults.headers.common['user'] = user.profile?.PK;
      instance.defaults.headers.common['usersk'] = user.profile?.SK;
      instance.defaults.headers.common['Authorization'] = user?.session?.AuthenticationResult?.AccessToken;
    } else {
      delete instance.defaults.headers.common['user'];
      delete instance.defaults.headers.common['usersk'];
      delete instance.defaults.headers.common['Authorization'];
    }
  };

  create = async (params: any) => {
    const { user } = store.getState();
    this._setHeaders();
    try {
      let response = undefined;
      const user_id = user.profile && user.profile.PK ? user.profile.PK : '';
      if (user.isLogged) {
        response = await instance.post('/overt/rating/create', params, { headers: { userid: user_id } });
      } else {
        response = await instance.post('/overt/rating/create', params);
      }
      if (response.data.status === 'error') {
        throw new Error(response.data.message);
      }
      return response;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  check = async (id: any) => {
    try {
      const response = await instance.post(`/overt/rating/check`);

      return response;
    } catch (e) {
      return Promise.reject(e);
    }
  };
}

export default new RateService();
