import instance from './api_overt'

class CuponDataAccsess {
    checkCupon = async (payload: any) => {
        try {
            const response = await instance.put('/overt/check/cupon', payload);
            if (response.data?.status === 'error') {
                throw new Error("Este cupón no es válido...");
            }
            return response
        } catch (e: any) {
            return Promise.reject(e)
        }
    }
}

const Cupon = new CuponDataAccsess()
export default Cupon;