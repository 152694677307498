import Title from "../../components/Title";

const MassiveLoadResponse = ({ errors, csvData, handleClose }: any) => {
    return (
        <div className="row">

            <div className="col-12 border-bottom mb-3">
                <Title size="md" text="Carga masiva" className="text-start color-dark-blue" />
            </div>

            <div className="col-12 mb-3 text-08">
                {`${csvData.length - errors.length}`} usuarios fueron registrados con exito.
            </div>

            <div className="col-12 mb-3 text-08">
                {errors.length === 1 && (
                    <>{errors.length} usuario no pudo ser registrado.</>
                )}
                
                {errors.length > 1 && (
                    <>{errors.length} usuarios no pudieron ser registrados.</>
                )}
                
            </div>

            {errors.length > 0 && (
                <div className="col-12 mt-2">
                    <div className="row">
                        <div className="col-12">
                            <span className="bold text-black">Errores</span>
                        </div>

                        {errors.map((el: any, index: number) => (
                            <div className="col-12" key={index}>
                                <div className="row">
                                    <div className="col-12 border-bottom py-2">
                                        <span className="bold size-08">{index + 1} - {el.text}</span>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}

            <div className="col-12 mt-5 text-end text-white">
                <button className="btn-default py-2 px-4" onClick={handleClose}>Cerrar</button>
            </div>

        </div>
    )
}

export default MassiveLoadResponse;