/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { toast } from 'react-toastify'
import Text from '../../components/Text'
import Title from '../../components/Title'
import { LOAD_OFF, LOAD_ON } from '../../redux/actions/loader'
import Options from '../../services/options.service'
import Order from '../../services/order.service'
import Services from '../../services/services.service'
import DesktopTemplate from '../Profile/DesktopTemplate'
import OrderCard from './OrderCard'

const OrderHistory = (props: any) => {

    const [state, setState] = useState({
        orders: [],
        status: [],
        services: []
    })
    const history = useHistory();
    const dispatch = useDispatch();
    const { loader } = useSelector((state: any) => state)

    const _getHistory = async () => {
        dispatch(LOAD_ON());
        try {
            const response = await Order.list();
            const status = await Options.getOrderState();
            const serviceNames = await Services.selectList();
            response.data.sort((a: any, b: any) => {
                if (moment(a.created_at).isAfter(moment(b.created_at))) return 1;
                if (moment(b.created_at).isAfter(moment(a.created_at))) return -1;
                return 0
            })

            response.data.map((item: any) => {
                const [, order_status] = item.GS1PK?.split('-');
                let total_with_complement = item.amount + item.amount_shipping;
                if (item.complement) {
                    total_with_complement += item.complement.products.reduce((acc: any, current: any) => {
                        acc += current.amount * current.quantity_to_buy
                        return acc;
                    }, 0)
                }
                item.total_with_complement = total_with_complement;
                item.status = order_status?.toLowerCase();
                item.parent_service_id = item.GS4PK;
                return item;
            })

            const orders: any = response.data.filter((item: any) => item.status !== "new" && item.status !== "aborted")

            setState({ ...state, orders: orders, status: status.data, services: serviceNames.data })
        } catch (e: any) {
            toast.error("No fue posible cargar su historial")
        }
        dispatch(LOAD_OFF());
    }

    useEffect(() => {
        _getHistory();
    }, [])

    return (
        <>
            {/* Mobile Version */}
            <div className="container-fluid d-md-none mt-5">
                <Title text="Mi historial" size="md" className="bold color-dark-blue text-center" />

                {!loader.loading && state.orders.length === 0 && (
                    <div className="bold size-14 color-dark-blue mt-5 w-100 text-center">No tienes ningún pedido en tu historial...</div>
                )}

                {!loader.loading && state.orders && state.orders.map((item: any) => (
                    <button className="btn w-100" onClick={() => history.push(`/profile/my-history/${item.PK}`)} key={`d-${item.PK}`}>
                        <OrderCard data={item} status={state.status} key={item.PK} services={state.services} />
                    </button>
                ))}

                {loader.loading && Array.from({ length: 3 }).map((item: any, index: any) => (
                    <button className="btn w-100 bg-input my-2 border" onClick={() => history.push(`/profile/my-history/${item.PK}`)} key={`d-${index}`}>
                        <div className="row">
                            <div className="col-12 text-start" style={{ marginBottom: '2px' }}>
                                <Text loading={loader.loading} value='' rows={4} />
                            </div>
                        </div>
                    </button>
                ))}
            </div>

            {/* Desktop Version */}
            <div className="container-fluid d-none d-md-block">
                <DesktopTemplate>
                    <div className="row border-bottom mx-4 px-0 py-4 mb-2">
                        <div className="col-6 p-0">
                            <span className="bold size-12 color-black">Mi historial</span>
                        </div>
                    </div>

                    {!loader.loading && state.orders.length === 0 && (
                        <div className="bold size-14 color-dark-blue mt-5 w-100 text-center">No tienes ningún pedido en tu historial...</div>
                    )}
                    {!loader.loading && state.orders && state.orders.map((item: any) => (
                        <button className="btn w-100 bg-input my-2 border color-grey" onClick={() => history.push(`/profile/my-history/${item.PK}`)} key={`m-${item.PK}`}>
                            <OrderCard data={item} status={state.status} key={item.PK} services={state.services} />
                        </button>
                    ))}

                    {loader.loading && Array.from({ length: 3 }).map((item: any, index: any) => (
                        <button className="btn w-100 bg-input my-2 border" onClick={() => history.push(`/profile/my-history/${item.PK}`)} key={`m-${index}`}>
                            <div className="row">
                                <div className="col-12 text-start" style={{ marginBottom: '2px' }}>
                                    <Text loading={loader.loading} value='' rows={4} />
                                </div>
                            </div>
                        </button>
                    ))}
                </DesktopTemplate>
            </div>
        </>
    )

}
export default OrderHistory