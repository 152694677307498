/* eslint-disable react-hooks/exhaustive-deps */
import Image from '../Image';
import '../../styles/components/component.product-card.scss';
import { useEffect, useState } from 'react';
import QuantitySelect from '../QuantitySelect';
import Images from '../../assets/image';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

const ProductCard = (props: any) => {
  const { title, value, image, onClick, data, product, onPlusClick, onMinusClick } = props;

  const { shopping_cart, services } = useSelector((state: any) => state);
  const [productData, setProductData] = useState<any>({});

  useEffect(() => {
    const service: any = shopping_cart.services.find((s: any) => s.PK === data.PK && s.SK === data.SK);
    const target_service: any = services.find((s: any) => s.PK === data.PK && s.SK === data.SK);

    if (service || target_service) {
      let products: any = [];
      let related_products: any = [];
      if (service) {
        related_products = service.related_products || [];
        products = [...[service.service], ...related_products];
      }

      if (target_service) {
        related_products = target_service.related_products || [];
        products = [...[target_service.service], ...related_products];
      }
      const productData: any = products.find((p: any) => p.SK === product.SK);
      setProductData(productData);
    } else {
      setProductData({});
    }
  }, [shopping_cart]);

  useEffect(() => {
    try {
      const isServiceInShoppingCart: any = shopping_cart.services.find((s: any) => s.PK === data.PK);
      if (!isServiceInShoppingCart) {
        const service: any = services.find((service: any) => service.PK === data.PK);

        if (service) {
          service.related_products = service.related_products || [];
          const products: any = [...[service.service], ...service.related_products];
          const productData: any = products.find((p: any) => p.SK === product.SK);
          setProductData(productData);
        } else {
          setProductData({});
        }
      }
    } catch (e: any) {
      toast.error('No fue posible actualizar este producto.');
    }
  }, [services]);

  return (
    <div className=" d-flex justify-content-center m-0 p-0 w-100">
      <div className="component-product-card" style={{ maxWidth: '350px' }}>
        <div className="component-product-card__header" style={{ zIndex: 5 }}>
          <Image image={image ? { default: { src: image } } : Images.NotFound} />
        </div>
        <div className="component-product-card__content" style={{ zIndex: 100 }}>
          <div className="component-product-card__content__title py-2 size-09 bold" dangerouslySetInnerHTML={{ __html: title }} />
          <div className="component-product-card__content__value bold size-13 py-2">${value?.toLocaleString('pt-BR')}</div>
          {!productData?.quantity || productData?.quantity === 0 ? (
            <div className="row">
              <div className="col-12">
                <button className="btn-green mb-3" onClick={onClick ? onClick : () => console.log('[LOG]', 'Everything ok here ;)')}>
                  Agregar
                </button>
              </div>
            </div>
          ) : (
            <div className="row px-4 justify-content-center">
              <QuantitySelect quantity={productData?.quantity} onAdd={() => onPlusClick({ PK: product.PK, SK: product.SK })} onRemove={() => onMinusClick({ PK: product.PK, SK: product.SK })} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
