/* eslint-disable react-hooks/exhaustive-deps */
import { Loader } from '@googlemaps/js-api-loader';
import { useEffect, useState } from 'react';
import Images from '../../assets/image';
import Image from '../../components/Image';
import Modal from '../../components/Modal';
import Title from '../../components/Title';
import GoogleAutoComplete from './GoogleAutomplete';
import ManualDirections from './ManualDirections';
import Session from '../../services/session.service';
import { useDispatch, useSelector } from 'react-redux';
import { LOAD_OFF, LOAD_ON } from '../../redux/actions/loader';
import HistoryAddresses from './HistoryAddresses';
import moment from 'moment';

const MapsDirections = (props: any) => {
  const { open, handleClose, callback, data, isHistoryDisabled } = props;
  const { user } = useSelector((state: any) => state);
  const dispatch: any = useDispatch();

  const stateObj = {
    inputs: {
      address: '',
      number: '',
      observations: '',
    },
    results: [],
    autocompleteService: undefined,
    services: {
      autocomplete: false,
      manual: false,
      script: false,
      history: false,
    },
    validations: {},
    addresses: [],
  };
  const [state, setState] = useState<any>(stateObj);

  useEffect(() => {
    if (open) {
      _handleInitComponent();
    }

    if (!open) {
      setState({ ...state, services: { ...state.services, manual: false }, inputs: { ...state.inputs, address: '' }, results: [], addresses: [] });
    }
  }, [open]);

  const _handleInitComponent = async () => {
    /**
     *  Get address and filter history address
     *  @addresses
     */
    if (!isHistoryDisabled) {
      const addresses: any = await _getAddress();
      const historyAddress: any = addresses?.filter((address: any) => address?.info?.history);

      if (historyAddress.length > 0) {
        historyAddress.sort((a: any, b: any) => {
          if (moment(a.info?.history_date).isAfter(b.info?.history_date)) return -1;
          if (moment(a.info?.history_date).isBefore(b.info?.history_date)) return 1;
          return 0;
        });

        const elementsToShow: any = historyAddress.slice(0, 3);
        const elementsToDelete: any = historyAddress.slice(3, historyAddress.length);

        for (const el of elementsToDelete) {
          Session.deleteAddress(el.SK);
        }

        state.services = { ...state.services, history: true, autocomplete: false };
        state.addresses = elementsToShow;
      } else {
        state.services = { ...state.services, autocomplete: true, history: false };
        state.addresses = [];
      }
    } else {
      state.services = { ...state.services, autocomplete: true, history: false };
    }

    const searchInput: any = document.querySelector('#autocompletesearchdesktop');
    searchInput?.focus();
    if (!state.google) {
      const loader: any = new Loader({
        apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY ? process.env.REACT_APP_GOOGLE_MAPS_API_KEY : '',
        version: 'weekly',
        libraries: ['places', 'geometry'],
        ...{},
      });

      loader.load().then((google: any) => {
        const autocompleteService = new google.maps.places.AutocompleteService();
        setState({
          ...state,
          google,
          inputs: { ...state.inputs, address: data?.info?.address || '' },
          api_key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
          autocompleteService,
        });
      });
    } else {
      const autocompleteService = new state.google.maps.places.AutocompleteService();
      setState({
        ...state,
        inputs: { ...state.inputs, coordinates: '', address: data?.info?.address || '' },
        autocompleteService,
      });
    }
  };

  const _getAddress = async () => {
    if (user.isLogged) {
      try {
        dispatch(LOAD_ON());
        const address: any = await Session.getAddresses();
        dispatch(LOAD_OFF());
        return address.data;
      } catch (e: any) {
        dispatch(LOAD_OFF());
        return [];
      }
    }

    return [];
  };

  const handleCloseButton = () => {
    setState(stateObj);
    handleClose();
  };

  return (
    <Modal open={open}>
      <div className="row bg-color-white rounded-15 justify-content-between flex-column" style={{ width: '500px', overflow: 'auto', minHeight: '600px' }}>
        <section>
          <div className="col-12 position-relative">
            <Title size="lg" text={state.services?.history ? 'Direcciones recomendadas' : 'Ingresa una dirección'} className=" w-100 text-center py-3 color-dark-blue mt-4" />
            <button className="position-absolute mt-2 me-4 btn" style={{ right: '-20px', top: '-20px' }} onClick={handleCloseButton}>
              <Image image={Images.CloseBlue} />
            </button>
          </div>

          <div className="col-12 d-flex justify-content-center mb-4 px-3 py-2">
            <div className="row" style={{ width: '100%' }}>
              {open && state.services?.history ? <HistoryAddresses addresses={state.addresses} google={state.google} handleClose={handleClose} /> : null}

              {open && state.services?.autocomplete ? (
                <GoogleAutoComplete google={state.google} autocompleteService={state.autocompleteService} handleClose={handleClose} callback={callback} data={data} addresses={state.addresses} />
              ) : null}

              {open && state.services?.manual ? <ManualDirections google={state.google} handleClose={handleClose} callback={callback} data={data} addresses={state.addresses} /> : null}
            </div>
          </div>
        </section>

        {!state.services?.manual ? (
          <section className="p-0 m-0">
            {state.services?.history ? (
              <div
                className="col-12 d-flex justify-content-between align-items-center input-default p-4"
                style={{ maxHeight: '90px', cursor: 'pointer' }}
                onClick={() => setState({ ...state, services: { ...state.services, autocomplete: true, manual: false, history: false } })}
              >
                <div>
                  <i className="bi bi-geo-alt px-2 size-11" />
                  Agregar nueva dirección
                </div>
                <Image image={Images.ArrowDown} />
              </div>
            ) : null}

            {state.services?.autocomplete && state.addresses.length > 0 ? (
              <div
                className="col-12 d-flex justify-content-between align-items-center input-default p-4"
                style={{ maxHeight: '90px', cursor: 'pointer' }}
                onClick={() => setState({ ...state, services: { ...state.services, autocomplete: false, manual: false, history: true } })}
              >
                <div>
                  <i className="bi bi-geo-alt px-2 size-11" />
                  Direcciones recomendadas
                </div>
                <Image image={Images.ArrowDown} />
              </div>
            ) : null}

            <div
              className="col-12 d-flex justify-content-between align-items-center input-default p-4"
              style={{ maxHeight: '90px', cursor: 'pointer' }}
              onClick={() => setState({ ...state, services: { autocomplete: false, manual: true } })}
            >
              <div>
                <i className="bi bi-geo-alt px-2 size-11" />
                Agregar dirección manualmente
              </div>
              <Image image={Images.ArrowDown} />
            </div>
          </section>
        ) : null}
      </div>
    </Modal>
  );
};

export default MapsDirections;
