/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import Images from '../../assets/image';
import ButtonBack from '../../components/ButtonBack';
import Image from '../../components/Image';
import Title from '../../components/Title';
import sessionService from '../../services/session.service';
import Logo from '../../components/Logo';

const ConfirmationCodeCompany = () => {
  const [state, setState] = useState({
    inputs: {
      email: '',
      confirm_code: '',
    },
    loading: false,
  });
  const history = useHistory();

  const _handleConfirmationCode = async () => {
    setState({ ...state, loading: true });
    try {
      await sessionService.confirmCode({ ...state.inputs });
      history.push('/sign-in?success=ok');
      setState({ ...state, loading: false });
    } catch (e) {
      toast.error('El código no es válido');
      setState({ ...state, loading: false });
    }
  };

  const _handleOnChange = ({ target }: any) => {
    if (target.name === 'confirm_code') {
      target.value = target.value.replace(/\D/g, '');
    }
    const { name, value } = target;
    setState({ ...state, inputs: { ...state.inputs, [name]: value } });
  };

  return (
    <div className="container-fluid h-100 component-signin position-relative">
      <div className="row component-signin__header" style={{ height: '15%' }}>
        <Image image={Images.BGSignIn} className="position-absolute top-0 end-0 p-0 m-0 sign-in-image" />
        <div className="col-12" style={{ zIndex: 1000 }}>
          <ButtonBack text="Volver" action={() => history.goBack()} />
        </div>
      </div>

      <div className="d-flex w-100 justify-content-center position-relative" style={{ height: '85%', zIndex: 100 }}>
        <div className="row component-signin__fields justify-content-between" style={{ height: '100%', maxWidth: '450px' }}>
          <div className="col-12">
            <div className="row">
              <div className="col-12 text-center">
                <Logo color className="p-3" style={{ maxWidth: '250px' }} />
              </div>
            </div>

            <div className="row mb-4 mt-1 text-center">
              <div className="col-12 text-center mt-5">
                <Title size="md" text="Código de confirmación" className="color-dark-blue" />
              </div>
            </div>

            <div className="row mb-5">
              <div className="col-12 bold-300">Hemos enviado un código de confirmación a tu email. Por favor ingresa este código para continuar.</div>
            </div>

            <div className="row px-3 mb-5 mt-2">
              <div className="p-0 mb-1 color-black bold size-09">Email</div>
              <div className="col-12 d-flex bg-color-blue-light border p-3 rounded-10">
                <Image image={Images.EmailInput} className="me-3" />
                <input type="text" className="input-transparent" placeholder="usuario@copec.cl" style={{ flex: '1' }} name="email" value={state.inputs.email} onChange={_handleOnChange} />
              </div>
            </div>

            <div className="row px-3 mb-5 mt-2">
              <div className="p-0 mb-1 color-black bold size-09">Código</div>
              <div className="col-12 d-flex bg-color-blue-light border p-3 rounded-10">
                <Image image={Images.PasswordInput} className="me-3" />
                <input
                  type="text"
                  className="input-transparent"
                  placeholder="******"
                  maxLength={6}
                  style={{ flex: '1' }}
                  name="confirm_code"
                  value={state.inputs.confirm_code}
                  onChange={_handleOnChange}
                />
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-12 color-white">
                <button className="btn-default w-100 py-3 size-11 rounded-10" onClick={_handleConfirmationCode} disabled={state.loading}>
                  {state.loading && <div className="spinner-border me-1" role="status" style={{ width: '20px', height: '20px' }} />}
                  Confirmar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationCodeCompany;
