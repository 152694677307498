import { createAction } from 'redux-actions';

export const SET_SERVICE: any = createAction('SET_SERVICE');
export const ADD_RELATED: any = createAction('ADD_RELATED');
export const UPDATE_SERVICE: any = createAction('UPDATE_SERVICE');
export const DELETE_SERVICE: any = createAction('DELETE_SERVICE');
export const CLEAR_SERVICES: any = createAction('CLEAR_SERVICES');
export const SAVE_STATE: any = createAction('SAVE_STATE');

export const set_service = (payload: any) => {
  return (dispatch: any) => {
    dispatch(SET_SERVICE(payload));
    dispatch(SAVE_STATE());
  };
};

export const add_related = (payload: any) => {
  return (dispatch: any) => {
    dispatch(ADD_RELATED(payload));
    dispatch(SAVE_STATE());
  };
};

export const update_service = (payload: any) => {
  return (dispatch: any) => {
    dispatch(UPDATE_SERVICE(payload));
    dispatch(SAVE_STATE());
  };
};

export const delete_service = (payload: any) => {
  return (dispatch: any) => {
    dispatch(DELETE_SERVICE(payload));
    dispatch(SAVE_STATE());
  };
};

/**
 *  NEW SHOPPING CART MODULE - SERVICES
 *  USED TO STORE INFO ABOUT SERVICES AND PRODUCTS
 *  BEFORE ADDING TO CART
 */

export const CREATE_SERVICE: any = createAction('CREATE_SERVICE');
export const REMOVE_SERVICE: any = createAction('REMOVE_SERVICE');
export const EDIT_SERVICE: any = createAction('EDIT_SERVICE');
export const UPDATE_RELATED_PRODUCT: any = createAction('UPDATE_RELATED_PRODUCT');

export const create_service = (payload: any) => {
  return (dispatch: any) => {
    dispatch(CREATE_SERVICE(payload));
    dispatch(SAVE_STATE());
  };
};

export const remove_service = (payload: any) => {
  return (dispatch: any) => {
    dispatch(REMOVE_SERVICE(payload));
    dispatch(SAVE_STATE());
  };
};

export const edit_service = (payload: any) => {
  return (dispatch: any) => {
    dispatch(EDIT_SERVICE(payload));
    dispatch(SAVE_STATE());
  };
};

export const update_related_product = (payload: any) => {
  return (dispatch: any) => {
    dispatch(UPDATE_RELATED_PRODUCT(payload));
    dispatch(SAVE_STATE());
  };
};

export const clear_services = () => {
  return (dispatch: any) => {
    dispatch(CLEAR_SERVICES());
    dispatch(SAVE_STATE());
  };
};
