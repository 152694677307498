import Image from '../Image';
import { ReactComponent as Recommended } from '../../assets/image/svg/recommended_full.svg';
import '../../styles/components/component.card-lub.scss';
import Images from '../../assets/image';
import './style.scss';
import { toast } from 'react-toastify';

const CardLub = (props: any) => {
  const { image, text, handleAction, handleDetail, price, recommended, value, fullpoints, data } = props;

  const _handleOnClick = () => {
    if (handleAction) {
      handleAction();
    } else {
      toast.error('No fue posible agregar este producto')
    }
  };
  return (
    <div className={`card-home-lub ${recommended && 'recommended-border'}`}>
      {recommended ? (
        <div className='position-absolute d-flex align-items-center justify-content-center' style={{ top: '5px', right: '10px' }}>
          <span className='color-dark-blue fw-bold me-2'>Recomendado</span>
          <Recommended style={{ top: '25px', right: '25px', width: '25px', height: '25px', color: '#203391' }} />
        </div>
      ) : null}
      <div className="card-home-lub__image ">
        <Image image={image || Images.NotFound} />
      </div>
      <div className="card-home-lub__content">
        <div className="size-14 color-white bold">{text}</div>
        <div className="color-white">Detalle del servicio</div>
        {fullpoints && data?.info?.points_per_division && parseInt(data?.info?.points_per_division) ? (
          <div className="d-flex color-white align-items-center size-09 my-2">
            <Image image={Images.CopecFull} className="me-2" style={{ width: '50px' }} />
            Acumula puntos Full Copec.
          </div>
        ) : null}
        <div className="d-flex justify-content-between w-100">

          {data?.info?.show_discount ? (
            <div className="d-flex flex-column">

              <div className="d-flex align-items-center">
                <span className="color-white me-1">$</span>
                <span className="color-white  size-12 bold text-decoration-line-through">{data?.info?.without_discount_price?.toLocaleString('es-CL')}</span>
                <span className="color-green-base bold ms-3">{data?.info?.porcentageDiscount}% Dcto</span>
              </div>
              
              <div className="d-flex size-15 align-items-center">
                <span className="color-green-base size-08 me-1">$</span>
                <span className="color-white size-15 bold">{price?.toLocaleString('es-CL')}</span>
              </div>
            </div>
          ) : (
            <div className="d-flex size-15 align-items-center">
              <span className="color-green-base size-08 me-1">$</span>
              <span className="color-white size-15 bold">{price?.toLocaleString('es-CL')}</span>
            </div>
          )}

          <div className="d-flex align-items-center color-white" onClick={handleDetail}>
            <button className="btn-default size-09 m-0 p-0 color-green-base underline">{value}</button>
          </div>

        </div>
        <div className="w-100 mt-2">
          <button className="btn-green-light py-2 w-100 bold" onClick={_handleOnClick}>
            Agregar
          </button>
        </div>
      </div >
    </div >
  );
};

export default CardLub;
