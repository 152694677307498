/* eslint-disable no-useless-escape */
export function toBase64(file: any) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export function cloneObject(object: any) {
  return JSON.parse(JSON.stringify(object));
}

export function getRandomInt(min: any, max: any) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min)) + min;
}

export function splitDirection(direction: any) {
  try {
    const splited = direction ? direction.split(',') : null;
    if (splited) {
      if (splited.length > 1) {
        const data: any = {
          title: splited[0],
        };
        splited.splice(0, 1);
        data.subtitle = splited.join(' ').trim();
        return data;
      } else {
        const data: any = {
          title: splited[0],
          subtitle: splited[0],
        };
        return data;
      }
    }
  } catch (e) {
    console.log('[LOG ERROR]', e);
    return {
      title: '',
      subtitle: '',
    };
  }
}

export function regexCognito(pass: any) {
  const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-\"!@#%&\/,><\':;|_~`])\S{10,99}$/;
  return regex.test(pass);
}

export function formatRut(rut: any) {
  if (rut) {
    return rut.replace(/[.-]/g, '').replace(/^(\d{1,2})(\d{3})(\d{3})(\w{1})$/, '$1.$2.$3-$4');
  }
  return '';
}

export const _formatRut = (value: any, mask: any) => {
  // let rut = value.replace(/[^\dkK]/g, "");

  const rules: any = [
    {
      size: 1,
      rule: /(\d{1})/,
      format: '$1.',
    },
    {
      size: 4,
      rule: /(\d{1})(\d{3})/,
      format: '$1.$2.',
    },
    {
      size: 7,
      rule: /(\d{1})(\d{3})(\d{3})/,
      format: '$1.$2.$3-',
    },
    {
      size: 8,
      rule: /(\d{1})(\d{3})(\d{3})([\dkK]{1})/,
      format: '$1.$2.$3-$4',
    },
  ];

  let rut = value.replace(/[^\dkK]/g, '');
  const rule = rules.find((r: any) => r.size === rut.length);
  if (rule) {
    rut = rut.replace(rule.rule, rule.format);
    return rut;
  }
  return false;
};

export function unformatRut(rut: any) {
  return rut ? rut.replace(/\./g, '') : '';
}

export function validateRut(rutValue: any) {
  const rut = unformatRut(rutValue);
  console.log(rut);
  const rexp = new RegExp(/^([0-9])+-([kK0-9])+$/);
  if (rut.match(rexp)) {
    const RUT = rut.split('-');
    const elRut = RUT[0].split('');
    let factor: any = 2;
    let suma = 0;
    let dv;
    for (let i = elRut.length - 1; i >= 0; i--) {
      factor = factor > 7 ? 2 : factor;
      suma += parseInt(elRut[i]) * factor++;
    }
    dv = 11 - (suma % 11);
    if (dv === 11) {
      dv = 0;
    } else if (dv === 10) {
      dv = 'k';
    }

    if (dv.toString() === RUT[1].toLowerCase()) {
      return true;
    }
    return false;
  }
  return false;
}

export const _scrollTo = (e: any, directions: any, id: any = null) => {
  e.preventDefault();
  if (id) {
    const el = document.querySelector(id);
    const root = document.querySelector('html');
    if (el && root) {
      const distanceToTop = el?.getBoundingClientRect().top;
      const discount = directions.direction ? 170 : 70;
      document.querySelector('html')?.scrollTo({
        top: distanceToTop ? root.scrollTop + distanceToTop - discount : 0,
        behavior: 'smooth',
      });
    }
  } else {
    document.querySelector('html')?.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }
};

export const _getGoogleAddressComponents = (address_components: any, number: any) => {
  let streetNumber: any = address_components.find((address_comp: any) => address_comp.types.find((type: any) => type === 'street_number'));
  let street: any = address_components.find((address_comp: any) => address_comp.types.find((type: any) => type === 'route'));
  let locality: any = address_components.find((address_comp: any) => address_comp.types.find((type: any) => type === 'locality'));
  let country: any = address_components.find((address_comp: any) => address_comp.types.find((type: any) => type === 'country'));
  let commune_adm_lvl_3: any = address_components.find((address_comp: any) => address_comp.types.find((type: any) => type === 'administrative_area_level_3' || type === 'administrative_area_level_2'));
  let region: any = address_components.find((address_comp: any) => address_comp.types.find((type: any) => type === 'administrative_area_level_1'));

  if (!streetNumber) {
    if (number !== 0) {
      streetNumber = { long_name: String(number), short_name: String(number) };
    } else {
      streetNumber = { long_name: '', short_name: '' };
    }
  }

  if (!street) {
    street = locality;
  }

  return { streetNumber, street, country, commune_adm_lvl_3, region };
};

export const _slug = (name: any) => {
  const slug = name
    ?.toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, '')
    .replace(/[\s_-]+/g, '-')
    .replace(/^-+|-+$/g, '');

  return slug;
};


export const createCsvSimple = (data: any, name: any = null) => {
  try {
    let csvContent = "data:text/csv;charset=utf-8,";
    const universalBOM: any = "\uFEFF";
    csvContent += universalBOM + data;
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", name || "my_data.csv");
    document.body.appendChild(link);
    link.click();
  } catch (e: any) {
    return false;
  }
};