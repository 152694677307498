import Images from '../../assets/image';
import Image from '../Image';
import QuantitySelect from '../QuantitySelect';

const CheckoutProductItemMobile = (props: any) => {
  const { product, service, _handlePlusProduct, _handleMinusProduct, _handleDeleteProduct } = props;
  return (
    <div className="row line-border p-3 mt-3 align-items-center">
      <div className="col-3">
        <Image className="w-100" image={product.info?.gallery?.url ? { default: { src: product.info?.gallery?.url } } : Images.NotFound} />
      </div>
      <div className="col-9 color-black bold m-0 p-0">
        <div className="row m-0 p-0">
          <div
            className="col-9 size-08 color-black bold"
            dangerouslySetInnerHTML={{
              __html: product.title || product._html || product.nombre || product.name,
            }}
          />
          <div className="col-3 color-black size-09 d-flex align-items-center">
            <div>${product.price_total?.toLocaleString('es-CL')}</div>
          </div>
        </div>
      </div>

      <div className="col-12 d-flex justify-content-between mt-3">
        <button className="btn color-blue-base size-08" onClick={() => _handleDeleteProduct({ service: service, product: product })}>
          <u>Eliminar</u>
        </button>

        {product.quantity && !product.oneItem && !product.info?.air_filter && (
          <QuantitySelect
            quantity={product.quantity}
            onAdd={() => _handlePlusProduct({ service: service, product: product })}
            onRemove={() => _handleMinusProduct({ service: service, product: product })}
          />
        )}
      </div>
    </div>
  );
};

export default CheckoutProductItemMobile;
