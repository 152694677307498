/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import Images from '../../assets/image';
import Image from '../../components/Image';
import Title from '../../components/Title';
import { send_message } from '../../redux/actions/broadcast';
import '../../styles/components/component.washinfo.scss';
import Services from '../../services/services.service';
import { useEffect, useState } from 'react';
import { LOAD_OFF, LOAD_ON } from '../../redux/actions/loader';
import ServicePriceTableLavamax from '../../components/ServicePriceTableLavamax';

const WashInfo = () => {
  const history = useHistory();
  const dispatch: any = useDispatch();
  const [data, setData] = useState(null);

  useEffect(() => {
    _getData();
  }, []);

  const _getData = async () => {
    try {
      dispatch(LOAD_ON());
      const services = await Services.list();
      const lavamax: any = services.data.find((service: any) => service.type === 'lavamax');
      dispatch(LOAD_OFF());
      if (lavamax) {
        setData(lavamax);
        return;
      }

      throw new Error('No fue posible cargar los precios.');
    } catch (e: any) {
      dispatch(LOAD_OFF());
      toast.error('No fue posible cargar los precios de los servicios.');
    }
  };

  const _openDirectionsModal = () => {
    try {
      dispatch(send_message({ open_directions: true }));
      history.push('/');
    } catch (e: any) {
      toast.error("No fue posible completar esta acción. Intente volver a la página principal y haga clic en el botón 'Ingresa tu dirección'");
    }
  };

  return (
    <>
      {/* Version Mobile */}
      <div className="container-washinfo d-lg-none">
        <Title size="md" text="Tipos de lavados disponibles:" className="color-dark-blue text-center mt-3" />

        <div className="container-lubinfo__info mt-4">
          <div className="row align-items-start px-4 pb-3 size-09">
            <div className="col-1">
              <Image image={Images.BulletInfo} />
            </div>
            <div className="col-11">Lavado exterior.</div>
          </div>
          <div className="row align-items-start px-4 pb-3 size-09">
            <div className="col-1">
              <Image image={Images.BulletInfo} />
            </div>
            <div className="col-11">Lavado Full (exterior + interior).</div>
          </div>
          <div className="row align-items-start px-4 pb-3 size-09">
            <div className="col-1">
              <Image image={Images.BulletInfo} />
            </div>
            <div className="col-11">Lavado de tapiz.</div>
          </div>
          <div className="row align-items-start px-4 pb-3 size-09">
            <div className="col-1">
              <Image image={Images.BulletInfo} />
            </div>
            <div className="col-11">Lavado Full + Sanitizado.</div>
          </div>
          <div className="row align-items-start px-4 pb-3 size-09">
            <div className="col-1">
              <Image image={Images.BulletInfo} />
            </div>
            <div className="col-11">Lavado Full + Limpieza motor.</div>
          </div>
        </div>

        <Title size="md" text="Nos caracterizamos por entregar un servicio 100% ecológico:" className="color-dark-blue text-center my-4 px-4" />

        <div className="container-lubinfo__info mt-4 mb-4">
          <div className="row align-items-start px-4 pb-3 size-09">
            <div className="col-1">
              <Image image={Images.BulletInfo} />
            </div>
            <div className="col-11">No usamos agua.</div>
          </div>
          <div className="row align-items-start px-4 pb-3 size-09">
            <div className="col-1">
              <Image image={Images.BulletInfo} />
            </div>
            <div className="col-11">No dejamos residuos.</div>
          </div>
          <div className="row align-items-start px-4 pb-3 size-09">
            <div className="col-1">
              <Image image={Images.BulletInfo} />
            </div>
            <div className="col-11">Todos nuestro productos son biodegradables.</div>
          </div>
        </div>

        <Title size="md" text="Precios" className="color-dark-blue text-center my-4 px-4" />

        <div className="container-lubinfo__info mt-4 mb-4">
          <div className="row align-items-start px-0 pb-3 size-09">
            <div className="col-12 d-flex justify-content-center align-items-center">{data && 'sub_services' in data ? <ServicePriceTableLavamax data={data} /> : null}</div>
          </div>
        </div>

        <div className="container-lubinfo__info mt-4 mb-4">
          <div className="row align-items-start px-0 pb-3 size-09">
            <div className="col-12 d-flex justify-content-center align-items-center px-4">
              <iframe
                height={300}
                src={process.env.REACT_APP_LAVAMAX_VIDEO}
                title="Lavamax"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
                className="rounded-10 shadow mt-4 w-100"
              ></iframe>
            </div>
          </div>
        </div>

        <div className="row mb-4">
          <div className="col-12 text-center px-5">
            <button className="btn-default py-2 px-5 size-12 rounded-40" onClick={_openDirectionsModal}>
              Agenda Aquí
            </button>
          </div>
        </div>
      </div>

      {/* Version Desktop  */}
      <div className="container-fluid d-none d-lg-block px-5 mx-5">
        <h1 className="text-start p-0 mt-3 bold-300 color-dark-blue">Servicio de Lavado de auto</h1>

        <div className="row px-5 mx-5">
          <div className="col-6 mb-5 align-items-center d-flex flex-column justify-content-center">
            <div className="row size-11">
              <div className="col-12">
                <Title size="md" text="Tipos de lavados disponibles:" className="color-dark-blue mt-3 size-12" />
              </div>

              <div className="col-12 size-09">
                <div className="row color-black my-3">
                  <div className="col-12">
                    <Image image={Images.BulletInfo} className="me-3" />
                    Lavado exterior.
                  </div>
                </div>

                <div className="row color-black my-3">
                  <div className="col-12">
                    <Image image={Images.BulletInfo} className="me-3" />
                    Lavado Full (exterior + interior).
                  </div>
                </div>

                <div className="row color-black my-3">
                  <div className="col-12">
                    <Image image={Images.BulletInfo} className="me-3" />
                    Lavado de tapiz.
                  </div>
                </div>

                <div className="row color-black my-3">
                  <div className="col-12">
                    <Image image={Images.BulletInfo} className="me-3" />
                    Lavado Full + Sanitizado.
                  </div>
                </div>

                <div className="row color-black my-3">
                  <div className="col-12">
                    <Image image={Images.BulletInfo} className="me-3" />
                    Lavado Full + Limpieza motor.
                  </div>
                </div>
              </div>

              <div className="col-12 size-09">
                <Title size="md" text="Nos caracterizamos por entregar un servicio 100% ecológico:" className="color-dark-blue size-12 my-3" />
                <div className="row color-black my-3">
                  <div className="col-12">
                    <Image image={Images.BulletInfo} className="me-3" />
                    No usamos agua.
                  </div>
                </div>
                <div className="row color-black my-3">
                  <div className="col-12">
                    <Image image={Images.BulletInfo} className="me-3" />
                    No dejamos residuos.
                  </div>
                </div>
                <div className="row color-black my-3">
                  <div className="col-12">
                    <Image image={Images.BulletInfo} className="me-3" />
                    Todos nuestro productos son biodegradables.
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-6 d-flex flex-column align-items-center">
            <div className="w-100">{data && 'sub_services' in data ? <ServicePriceTableLavamax data={data} /> : null}</div>
            <iframe
              width="500"
              height="300"
              src={process.env.REACT_APP_LAVAMAX_VIDEO}
              title="Lavamax"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
              className="rounded-10 shadow mt-4"
            ></iframe>
          </div>
        </div>
        <div className="row mb-4 mt-4">
          <div className="col-12 text-center px-5">
            <button className="btn-default py-2 px-5 size-12 rounded-40" onClick={_openDirectionsModal}>
              Agenda Aquí
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default WashInfo;
