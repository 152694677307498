/* eslint-disable react-hooks/exhaustive-deps */
import Images from '../../assets/image';
import ProductsCarousel from '../../components/ProductsCarousel';
import Title from '../../components/Title';
import ServiceTemplate from '../../template/ServiceTemplate';
import ModalKerosene from './ModalKerosene';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Confirm from '../../components/Confirm';
import Image from '../../components/Image';
import { add_service_to_cart, add_to_cart, close_cart, remove_service_from_cart, update_service_to_cart } from '../../redux/actions/shopping_cart';
import { LOAD_OFF, LOAD_ON } from '../../redux/actions/loader';
import KeroseneDAO from '../../services/kerosene.service';
import Services from '../../services/services.service';
import { useParams, useHistory } from 'react-router';
import { set_available_services } from '../../redux/actions/available_services';
import { toast } from 'react-toastify';
import CardKerosene from '../../components/CardKerosene';
import CardProduct from '../../components/CardProduct';
import ProductCard from '../../components/ProductsCarousel/ProductCard';
import { addToCartDataLayerEvent, clearDataLayerEvent, createDataLayerObject, removeFromCartDataLayerEvent, sendDataLayerEvent } from '../../helpers/data_layer';
import Drawer from '../../components/Drawer';
import DateTimePicker from '../../components/DateTimePicker';
import { create_service, edit_service } from '../../redux/actions/services';
import { set_actionbar } from '../../redux/actions/action_bar';
import DynamicModal from '../../components/DynamicModal';
import { v4 } from 'uuid';
import { checkIfCopecFullPointsIsAvailable } from '../../helpers/copec_full_points';

const ServiceKeroseneSchedule = (props: any) => {
  const CAROUSEL_CONFIGS: any = {
    disableButtonsControls: true,
    infinite: false,
    autoPlay: false,
    paddingRight: 0,
    paddingLeft: 0,
    responsive: {
      0: {
        items: 1,
      },
      755: {
        items: 2,
      },
      1023: {
        items: 3,
      },
      1365: {
        items: 4,
      },
    },
  };

  const CAROUSEL_CONFIGS_MOBILE: any = {
    disableButtonsControls: true,
    infinite: false,
    autoPlay: false,
    paddingRight: 5,
    paddingLeft: 5,
    responsive: {
      0: {
        items: 1,
      },
      300: {
        items: 2,
      },
      755: {
        items: 2,
      },
      1023: {
        items: 3,
      },
      1365: {
        items: 4,
      },
    },
  };

  const { data } = props;
  const carouselRef = useRef<any>(null);
  const carouselRefMobile = useRef<any>(null);
  const [state, setState] = useState<any>({
    products: [],
    service: {},
    related_products: [],
    modal: {
      kerosene: false,
    },
    alert: false,
    product: {},
    productsList: [],
    eds: {},
    slidePosition: 0,
    showSlideNextPrevButtons: true,
    showSlideNextPrevButtonsMobile: true,
    drawers: {
      datepicker: {
        show: false,
      },
      resume: {
        show: false,
      },
    },
    dynamicPopUp: {
      open: false,
      first_load: true
    }
  });

  const [counter, setCounter] = useState<any>(null);

  const [activeIndex, setActiveIndex] = useState(0);

  const { matchMedia, shopping_cart, available_services, loader, services, action_bar } = useSelector((state: any) => state);
  const params: any = useParams();
  const dispatch = useDispatch();
  const history: any = useHistory();

  useEffect(() => {
    const unlisten = history.listen(() => {
      dispatch(LOAD_ON());
      window.location.reload();
    });
    return () => {
      unlisten();
    };
  }, [history]);

  useEffect(() => {
    getSubServices();
  }, []);

  useEffect(() => {
    let showSlideNextPrevButtons: any = true;
    let showSlideNextPrevButtonsMobile: any = true;
    if (carouselRef.current && carouselRef.current.state && carouselRef.current.state.itemsCount <= carouselRef.current.state.itemsInSlide) {
      showSlideNextPrevButtons = false;
    }
    if (carouselRefMobile.current && carouselRefMobile.current.state && carouselRefMobile.current.state.itemsCount <= carouselRefMobile.current.state.itemsInSlide) {
      showSlideNextPrevButtonsMobile = false;
    }

    setState((prevState: any) => {
      return { ...prevState, showSlideNextPrevButtons, showSlideNextPrevButtonsMobile };
    });
  }, [carouselRef.current, state.related_products, state.related_products_mobile]);

  useEffect(() => {
    if (state.target_service) {
      const isServiceInShoppingCart: any = shopping_cart.services.find((service: any) => service.PK === props.PK && service.SK === props.SK);
      const existsService: any = services.find((service: any) => service.PK === props.PK && service.SK === props.SK);
      if (isServiceInShoppingCart) dispatch(update_service_to_cart({ ...state.target_service }));
      if (existsService) dispatch(edit_service({ ...state.target_service }));
      if (!existsService) dispatch(create_service({ ...state.target_service }));
    }
  }, [state]);

  const getSubServices = async () => {
    dispatch(LOAD_ON());
    const { code, eds } = props.data;
    let codeEds: any = eds?.info?.code_ref || code
    let priceServiceResponse: any = null

    /**
     * GET KEROSENE API PRICE
     */

    try {
      priceServiceResponse = await KeroseneDAO.getService(codeEds);
    } catch (e: any) {
      priceServiceResponse = { data: null }
    }
    try {
      /**
       * GET MAIN SERVICE
       */
      const parent_service: any = available_services.find((as: any) => as.info?.slug === params.slug);
      let serviceResponse: any = null;
      let relatedProducts: any = [];

      /**
       * CHECK IF EXISTS RELATED PRODUCTS FLAG
       */
      if (parent_service.eds.eds_service_id) {
        serviceResponse = await Services.detailsWithRelatedProducts({ PK: parent_service.eds.eds_service_id, service_PK: parent_service.eds.service_id });
      } else {
        serviceResponse = await Services.details({ PK: parent_service.PK, SK: parent_service.SK });
      }

      const service = { ...serviceResponse.data };

      let related_products: any = [];
      let related_products_mobile: any = [];

      if (!service.related_products) {
        service.related_products = [];
      } else {
        /**
         * CREATE RELATED PRODUCTS COMPONENTS TO CARROUSEL
         */
        relatedProducts = service?.related_products?.filter((item: any) => item.active);

        related_products = relatedProducts.map((rp: any) => {
          rp.quantity = 0;
          return (
            <CardKerosene
              price={rp.price}
              image={rp.info?.gallery?.url ? { default: { alt: '', src: rp.info?.gallery?.url } } : Images.NotFound}
              text={rp.name}
              value="Ver detalle"
              product={rp}
              data={props.data}
              handleAction={_addRelatedProduct}
              onPlusProduct={_plusProduct}
              onMinusProduct={_minusProduct}
              recommended={rp.info?.recommended || false}
            />
          );
        });

        related_products_mobile = relatedProducts.map((rp: any) => {
          rp.quantity = 0;
          return (
            <ProductCard
              title={rp.name}
              value={rp.price}
              image={rp.info?.gallery?.url}
              id={rp.PK}
              onClick={() => _addRelatedProduct({ product: rp, module: 'kerosene' })}
              onPlusClick={_plusProduct}
              onMinusClick={_minusProduct}
              product={rp}
              data={props.data}
              recommended={rp.info?.recommended || false}
            />
          );
        });
      }

      const { sub_services } = service;

      const availableServices = sub_services.filter((ss: any) => ss.name !== 'Envio' && ss.name !== 'Envío' && !ss.info?.is_unvailable);

      availableServices.sort((a: any, b: any) => {
        if (a.info?.required < b.info?.required) return 1;
        if (a.info?.required > b.info?.required) return -1;
        return 0;
      });

      const kerosenePrice = priceServiceResponse.data;
      if (kerosenePrice?.length > 0) {
        const mainService = availableServices.find((as: any) => as.info?.required === true);
        if (mainService) {
          const { Prices } = kerosenePrice[0];
          if (Prices) {
            const price = Prices.find((p: any) => p.CodPro === '4');
            if (price) {
              const liter: any = mainService?.info?.liter || 20;
              mainService.price = price ? Number(price.Precio) * liter : mainService.price;
            }
          }
        }
      }

      availableServices.map((item: any) => {
        if (item.info.without_discount_price) {
          item.info.without_discount_price = Number(item.info.without_discount_price)
          const porcentageDiscount = (item.info.without_discount_price - item.price) / item.info.without_discount_price * 100;
          item.info.porcentageDiscount = Math.round(porcentageDiscount);
          item.info.show_discount = item.info.porcentageDiscount > 0;
        }
        return item;
      });

      const available_service_list: any = available_services.filter((s: any) => s.PK !== parent_service.PK);

      const newService = {
        ...service,
        code: parent_service.code,
        url: parent_service.url,
        eds: parent_service.eds,
        id: parent_service.id,
      }

      newService.isFullPointsAvailable = checkIfCopecFullPointsIsAvailable(newService)

      if(parent_service.company) {
        newService.company = parent_service.company
      }

      available_service_list.push({ ...newService });

      dispatch(set_available_services(available_service_list));

      availableServices.forEach((service: any, index: any) => {
        const item: any = {
          item_name: service.name,
          item_id: service.info?.sku || '',
          price: service.price,
          item_brand: service.name?.toLowerCase(),
          item_category: 'service',
          item_category2: service.info?.required ? 'sub_service' : 'related_product',
          item_category3: '',
          item_category4: '',
          item_variant: '',
          item_list_name: 'show_available_items',
          item_list_id: '',
          index: index,
          quantity: 1,
        };
        clearDataLayerEvent();
        const dataLayerEvent: any = createDataLayerObject('view_item', [item]);
        sendDataLayerEvent(dataLayerEvent);
      });

      service.related_products?.forEach((service: any, index: any) => {
        const item: any = {
          item_name: service.name,
          item_id: service.sku || '',
          price: service.price,
          item_brand: params.slug,
          item_category: 'service',
          item_category2: 'related_product',
          item_category3: '',
          item_category4: '',
          item_variant: '',
          item_list_name: 'show_available_related_products',
          item_list_id: index,
          index: index,
          quantity: 1,
        };
        clearDataLayerEvent();
        const dataLayerEvent: any = createDataLayerObject('view_item', [item]);
        sendDataLayerEvent(dataLayerEvent);
      });

      /** VERIFY IF SERVICE IS CREATED */
      const reduxServiceState: any = services.find((service: any) => service.PK === props.PK && service.SK === props.SK);

      /** VERIFY IF SERVICE IS IN SHOPPING CART */
      const isServiceInShoppingCart: any = shopping_cart.services.find((service: any) => service.PK === props.PK && service.SK === props.SK);

      let target_service: any = null;

      if (reduxServiceState) {
        target_service = { ...reduxServiceState, info: { ...service.info } };
      }

      if (isServiceInShoppingCart) {
        target_service = { ...isServiceInShoppingCart, info: { ...service.info } };
      }

      if (!isServiceInShoppingCart && !reduxServiceState) {
        target_service = {
          id: v4(),
          PK: props.PK,
          SK: props.SK,
          shipping_price: service?.shipping_price,
          service: {},
          related_products: [],
          module: service?.type,
          eds: data?.eds,
          delivery: service?.shipping_price,
          parent_service: parent_service.PK,
          name: props.data.name,
          info: { ...service.info },
          is_service_in_shopping_cart: false,
        };
      }

      setState((prevState: any) => {
        const dynamicPopUp: any = {
          open: false,
          first_load: false
        }
        if (serviceResponse?.data?.info?.popup_info?.isEnabled && prevState.dynamicPopUp.first_load) {
          dynamicPopUp.open = true;
        }
        return {
          ...prevState,
          productsList: availableServices,
          relatedProductsList: relatedProducts,
          eds: data.eds,
          related_products,
          related_products_mobile,
          service: newService,
          dynamicPopUp,
          target_service,
        };
      });
    } catch (e) {
      toast.error('No fue posible cargar los prductos.');
      dispatch(LOAD_OFF());
    }
    dispatch(LOAD_OFF());
  };

  const _handleAddProduct = (payload: any) => {
    const isServiceInShoppingCart: any = shopping_cart.services.find((service: any) => service.PK === props.PK && service.SK === props.SK);

    if (payload.info?.required) {
      setState((prevState: any) => {
        payload.quantity = 1;
        payload.shipping_price = state.target_service?.shipping_price;
        prevState.target_service = {
          ...prevState.target_service,
          service: payload,
          related_products: [],
        };

        return { ...prevState, modal: { kerosene: false } };
      });
    } else {
      if (isServiceInShoppingCart) {
        addToCartDataLayerEvent(payload, params.slug, 'related_product', 'show_available_items');
      }
      setState((prevState: any) => {
        const target_service = prevState.target_service;

        if (!target_service) {
          _openAlert();
          return;
        }

        const { service, related_products } = target_service;

        if (service?.quantity > 0) {
          const target_product: any = related_products.find((related_product: any) => related_product.PK === payload.PK && related_product.SK === payload.SK);
          if (target_product) {
            target_product.quantity = 1;
            target_product.subservice_product = true;
            return { ...prevState };
          }

          related_products.push({ ...payload, subservice_product: true, quantity: 1 });
          return { ...prevState };
        } else {
          _openAlert();
          return { ...prevState };
        }
      });
    }
  };

  const _addRelatedProduct = (payload: any) => {
    addToCartDataLayerEvent(payload.product, params.slug, 'related_product', 'show_available_items');

    const { product } = payload;
    setState((prevState: any) => {
      const target_service = prevState.target_service;

      const { service, related_products } = target_service;

      if (service?.quantity > 0) {
        const target_product: any = related_products.find((item: any) => item.PK === product.PK && item.SK === product.SK);
        if (target_product) {
          target_product.quantity += 1;
        } else {
          related_products.push({ ...product, quantity: 1 });
        }
        return { ...prevState };
      } else {
        _openAlert();
      }

      return { ...prevState };
    });
  };

  const _plusProduct = (payload: any) => {
    // addToCartDataLayerEvent(product, params.slug, 'related_product', 'show_available_items');

    setState((prevState: any) => {
      const target_service: any = prevState.target_service;

      if (!target_service) {
        toast.error('No fue posible encontrar este producto.');
        return { ...prevState };
      }

      const main_product: any = target_service.service;

      if (payload.PK === main_product.PK && payload.SK === main_product.SK) {
        main_product.quantity += 1;
        return { ...prevState };
      }

      const { related_products } = target_service;
      const target_product: any = related_products.find((related_product: any) => related_product.PK === payload.PK && related_product.SK === payload.SK);

      if (!target_product) {
        toast.error('No fue posible encontrar este producto.');
        return { ...prevState };
      }

      if (target_product.GS1PK === 'SUB_SERVICES') {
        if (target_product.quantity + 1 > main_product.quantity) {
          toast.error('No se puede agregar más bidones que unidades de Kerosene...');
          return { ...prevState };
        }
        target_product.quantity += 1;
        return { ...prevState };
      }

      target_product.quantity += 1;
      return { ...prevState };
    });
  };

  const _minusProduct = (payload: any) => {
    const isServiceInShoppingCart: any = shopping_cart.services.find((service: any) => service.PK === props.PK && service.SK === props.SK);

    setState((prevState: any) => {
      const target_service: any = prevState.target_service;

      if (!target_service) {
        toast.error('No fue posible encontrar este producto.');
        return { ...prevState };
      }

      const main_product: any = target_service.service;
      const { related_products } = target_service;

      if (payload.PK === main_product.PK && payload.SK === main_product.SK) {
        main_product.quantity -= 1;

        if (main_product.quantity === 0) {
          related_products.map((item: any) => {
            item.quantity = 0;
            return item;
          });

          target_service.related_products = [];

          if (isServiceInShoppingCart) {
            dispatch(remove_service_from_cart({ ...target_service }));
          }
          return { ...prevState };
        }

        const sub_service: any = related_products.find((related_product: any) => related_product.GS1PK === 'SUB_SERVICES');

        if (sub_service && sub_service.quantity > main_product.quantity) {
          sub_service.quantity = main_product.quantity;
        }

        return { ...prevState };
      }

      const target_product: any = related_products.find((related_product: any) => related_product.PK === payload.PK && related_product.SK === payload.SK);

      if (!target_product) {
        toast.error('No fue posible encontrar este producto.');
        return { ...prevState };
      }

      target_product.quantity -= 1;
      if (target_product.quantity === 0) {
        if (isServiceInShoppingCart) {
          const index: any = related_products.findIndex((product: any) => product.PK === target_product.PK && product.SK === target_product.SK);
          related_products.splice(index, 1);
          dispatch(add_to_cart({ ...target_service }));
        }
      }
      return { ...prevState };
    });
  };

  const _handleModal = (payload: any) => {
    setState({
      ...state,
      modal: { ...state.modal, kerosene: true },
      product: payload,
    });
  };

  const _openAlert = () => {
    setState((prevState: any) => {
      return { ...prevState, alert: true };
    });
    clearTimeout(counter);
    const counterTime: any = setTimeout(() => {
      setState((prevState: any) => {
        return { ...prevState, alert: false };
      });
    }, 3000);
    setCounter(counterTime);
  };

  const _toogleAlert = () => {
    clearTimeout(counter);
    setState((prevState: any) => {
      return { ...prevState, alert: !prevState.alert };
    });
  };

  const _nextSlide = (slide: any = null) => {
    if (slide === 'mobile') {
      if (carouselRefMobile.current.state.itemsCount - carouselRefMobile.current.state.itemsInSlide > activeIndex) {
        carouselRefMobile.current.slideNext();
      }
    } else {
      if (carouselRef.current.state.itemsCount - carouselRef.current.state.itemsInSlide > activeIndex) {
        carouselRef.current.slideNext();
      }
    }
  };

  const _prevSlide = (slide: any = null) => {
    if (slide === 'mobile') {
      if (activeIndex > 0) {
        carouselRefMobile.current.slidePrev();
      }
    } else {
      if (activeIndex > 0) {
        carouselRef.current.slidePrev();
      }
    }
  };

  const _onSlideChanged = (e: any) => {
    setActiveIndex(e.item);
  };

  const _saveScheduleRedux = (payload: any) => {

    _handleNext('datepicker', 'resume');

    const service: any = { ...state.target_service };
    const serviceInShoppingCart = shopping_cart.services.find((service: any) => service.PK === props.PK && service.SK === props.SK);
    service.schedule = { id: payload.id, day: payload.day, hour: payload.hour, month: payload.month };

    if (serviceInShoppingCart) {
      service.related_products = serviceInShoppingCart.related_products;
      removeFromCartDataLayerEvent(serviceInShoppingCart.service, params.slug, 'sub_service', 'show_available_items');
      addToCartDataLayerEvent(service.service, params.slug, 'sub_service', 'show_available_items');
    } else {
      addToCartDataLayerEvent(service.service, params.slug, 'sub_service', 'show_available_items');
    }

    const parent_service = available_services.find((as: any) => as.info?.slug === params.slug);
    service.info = parent_service.info;
    service.isFullPointsAvailable = parent_service.isFullPointsAvailable;
    service.is_service_in_shopping_cart = true;

    if (!serviceInShoppingCart) dispatch(add_service_to_cart({ ...service }));
    if (serviceInShoppingCart) dispatch(update_service_to_cart({ ...service }));
    dispatch(edit_service({ ...service }));

    setState((prevState: any) => {
      return { ...prevState, target_service: service }
    })

    let urlPage: any = '/services/kerosene-con-agendamiento/paso4/seleccion-fecha-horario';
    let eventName: any = 'kerosene_con_agendamiento_fecha';

    const dataLayerEvent: any = {
      event: eventName,
      page: urlPage,
      pagename: document.title,
    };

    sendDataLayerEvent(dataLayerEvent);
    dispatch(close_cart());
    available_services.sort((a: any, b: any) => a.name > b.name ? 1 : -1)
    if (matchMedia.media === 'xs') {
      dispatch(set_actionbar({ ...action_bar, unselected_services: available_services, show: true }))
    } else {
      dispatch(set_actionbar({ ...action_bar, unselected_services: available_services, modal_open: true }))
    }
  };

  const _handleBack = (target: any) => {
    _handleState(false, target);
  };

  const _handleNext = (current: any, next: any) => {
    const data = { ...state };
    data.drawers[current].show = false;
    data.drawers[next].show = true;
    setState({ ...data });
  };

  const _handleState = (value: any, target: any) => {
    state.drawers[target].show = value;
    setState({ ...state });
  };

  return (
    <ServiceTemplate banner={Images.HeaderKerosene} title={'Kerosene'} infoUrl="/services/info/kerosene" data={props.data}>
      <Confirm type="danger" open={state.alert} onClose={_toogleAlert}>
        <div className="px-3 py-4 d-flex justify-content-between" onClick={_toogleAlert}>
          <Image image={Images.DontIcon} />
          <span className="bold">Debes seleccionar antes Kerosene</span>
          <Image image={Images.CloseWhite} />
        </div>
      </Confirm>

      <Drawer show={state.drawers?.datepicker.show} direction="right-left" className="drawer-with-header">
        <DateTimePicker
          show={state.drawers?.datepicker.show}
          onContinue={_saveScheduleRedux}
          onClose={() => _handleBack('datepicker')}
          module={state.service?.info?.slug}
          service={state.service}
          mainService={state.target_service}
          showBlocks
        />
      </Drawer>

      {/* <DateTimePicker
          show={state.drawers.datepicker.show}
          onContinue={_saveScheduleRedux}
          onClose={() => _handleBack('datepicker')}
          module={state.service?.info?.slug}
          service={state.service}
          mainService={state.main_service}
        /> */}

      <div className="page-service-kerosene" style={{ minHeight: '35vh' }}>
        <div className="page-service-kerosene__products my-4 d-none d-sm-block">
          <h1 className="bold-300 text-start px-5 color-dark-blue">{state.target_service?.name}</h1>
        </div>

        <ModalKerosene onContinue={() => _handleAddProduct(state.product)} open={state.modal.kerosene} />

        <DynamicModal open={state.dynamicPopUp?.open} data={state.service?.info?.popup_info} onContinue={() => setState({ ...state, dynamicPopUp: { ...state.dynamicPopUp, open: false } })} />

        {!loader.loading && state.productsList.length === 0 && (
          <Title
            text="No pudimos encontrar ningún producto disponible para este servicio en su dirección."
            className={`text-center color-dark-blue mt-5 ${matchMedia.media === 'xs' ? 'size-07' : 'size-09'}`}
          />
        )}

        {/* Desktop Cards */}
        <div className="d-flex px-5 justify-content-center d-none d-sm-flex" style={{ flexWrap: 'wrap' }}>
          {state.productsList &&
            state.productsList.map((product: any) => (
              <div className="mx-3 mb-3" key={product.SK}>
                <CardKerosene
                  price={product.price}
                  image={product.info?.gallery?.url ? { default: { alt: '', src: product.info?.gallery?.url } } : Images.NotFound}
                  text={product.name}
                  value="Ver detalle"
                  product={product}
                  data={props.data}
                  handleAction={product.info?.required ? () => _handleModal(product) : () => _handleAddProduct(product)}
                  onPlusProduct={_plusProduct}
                  onMinusProduct={_minusProduct}
                  recommended={product.info?.recommended || false}
                  fullpoints={state.service.isFullPointsAvailable}
                />
              </div>
            ))}
        </div>

        {/* Mobile Cards */}
        <div className="row px-3 mt-5 d-sm-none">
          {state.productsList &&
            state.productsList.map((product: any) => (
              <div className="col-12 px-3 mb-3" key={product.SK}>
                <CardProduct
                  {...product}
                  imagen={product.info?.gallery?.url}
                  action={product.info?.required ? () => _handleModal(product) : () => _handleAddProduct(product)}
                  products={state.products}
                  onPlusProduct={_plusProduct}
                  onMinusProduct={_minusProduct}
                  product={product}
                  fullpoints={state.service.isFullPointsAvailable}
                  data={props.data}
                />
              </div>
            ))}
        </div>

        <div className="row mt-5 mb-3">
          <div className="col-12 text-center color-white">
            {/* <GoToCheckoutButton onClick={() => _handleState(true, 'datepicker')} /> */}
            <button className={`${'btn-default py-3 px-5 size-12'}`} style={{ width: '300px' }} onClick={() => _handleState(true, 'datepicker')} disabled={!state?.target_service?.service?.quantity}>
              Continuar
            </button>
          </div>
        </div>

        {/* Related Products Desktop */}

        {state.related_products && state.related_products.length > 0 && state.productsList.length > 0 && (
          <div className="row mt-4 bg-color-blue-light py-4 d-none d-sm-flex">
            {state.related_products?.length > 0 && (
              <div className="col-12 px-5">
                <div className="d-sm-none color-dark-blue mb-5 size-15 bold text-center">También te puede interesar</div>

                <div className="d-none d-sm-block color-dark-blue mb-5 bold-300 text-center px-5" style={{ fontSize: '2rem' }}>
                  ¡Productos más recomendados que te pueden interesar!
                </div>
              </div>
            )}
            <div className="col-12 d-flex justify-content-center align-items-start mb-3">
              {state.showSlideNextPrevButtons && (
                <>
                  <button onClick={_prevSlide} className="btn color-blue-base bold">
                    <Image image={Images.ArrowDown} style={{ transform: 'rotate(90deg)' }} />
                  </button>
                  <button onClick={_nextSlide} className="btn color-blue-base bold">
                    <Image image={Images.ArrowDown} style={{ transform: 'rotate(270deg)' }} />
                  </button>
                </>
              )}
            </div>
            <ProductsCarousel items={state.related_products} {...CAROUSEL_CONFIGS} ref={carouselRef} activeIndex={activeIndex} onSlideChanged={_onSlideChanged} />
          </div>
        )}

        {/* Related Products Mobile */}

        {state.related_products_mobile && state.related_products_mobile.length > 0 && state.productsList.length > 0 && (
          <div className="row mt-4 bg-color-blue-light py-4 d-sm-none">
            {state.related_products_mobile?.length > 0 && (
              <div className="col-12 px-5">
                <div className="d-sm-none color-blue-base mb-4 size-13 bold text-center">También te puede interesar</div>

                <div className="d-none d-sm-block color-dark-blue mb-5 bold-300 text-start px-5" style={{ fontSize: '2rem' }}>
                  ¡Productos más recomendados que te pueden interesar!
                </div>
              </div>
            )}
            <div className="col-12 d-flex justify-content-center align-items-start">
              {state.showSlideNextPrevButtons && (
                <>
                  <button onClick={() => _prevSlide('mobile')} className="btn color-blue-base bold">
                    <Image image={Images.ArrowDown} style={{ transform: 'rotate(90deg)' }} />
                  </button>
                  <button onClick={() => _nextSlide('mobile')} className="btn color-blue-base bold">
                    <Image image={Images.ArrowDown} style={{ transform: 'rotate(270deg)' }} />
                  </button>
                </>
              )}
            </div>
            <ProductsCarousel items={state.related_products_mobile} {...CAROUSEL_CONFIGS_MOBILE} ref={carouselRefMobile} activeIndex={activeIndex} onSlideChanged={_onSlideChanged} />
          </div>
        )}
      </div>
    </ServiceTemplate>
  );
};

export default ServiceKeroseneSchedule;
