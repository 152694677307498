const faq_kerosene: any = [
  {
    question: '¿En qué consiste el servicio?',
    response:
      'El cambio de aceite a domicilio Copec, consiste en remover el aceite usado del vehículo del cliente, para luego incorporar aceite nuevo en función de las necesidades del vehículo. Esto incluye el cambio del filtro de aceite correspondiente y el filtro de aire en caso de ser necesario. Se ofrecen a su vez servicios extra, como revisión de niveles de líquidos básicos, venta de los productos, revisión de la presión de aire de neumáticos, entre otros. Utilizamos alzadores de vehículos de alta gama y un equipo mecánico de primer nivel.',
    image: null,
  },
  {
    question: '¿Cómo funciona la recomendación?',
    response:
      'Al solicitar los datos del vehículo, como marca, modelo y año, nuestras bases de datos recomiendan el tipo de aceite y filtros adecuados para cada vehículo. En caso de que no podamos sugerir alguno de estos elementos, nuestros operarios aplicarán su expertiz para brindar un buen servicio de recomendación en cada caso.',
  },
  {
    question: '¿Cómo agendo un cambio de aceite?',
    response: [
      {
        text: '1 - Iniciar sesión.',
      },
      {
        text: '2 - Ingresar tu dirección.',
      },
      {
        text: '3 - Seleccionar el servicio cambio de aceite.',
      },
      {
        text: '4 - Ingresa la patente de tu vehículo y haz click en "Continuar". Se mostrará información resumen de tu vehículo y te recomendaremos el aceite más adecuado. Podrás elegir entre este aceite u otro, según tus preferencias.',
      },
      {
        text: '5 - Selecciona día y hora de tu reserva.',
      },
      {
        text: '6 - Confirmar agendamiento en el botón "Agendar".',
      },
      {
        text: '7 - Ir a pagar. Puedes revisar tu reserva en el módulo "Mi Historial".',
      },
    ],
  },
  {
    question: '¿En qué comunas está habilitado el servicio?',
    response: 'Por el momento, el servicio se encuentra disponible en las siguientes comunas: Las Condes, Vitacura, Lo Barnechea, Providencia, La Reina, Peñalolén y Ñuñoa.',
  },
  {
    question: '¿Cuáles son las formas de pago?',
    response: 'Por el momento, las formas de pagos disponibles son las siguientes: efectivo, tarjeta de crédito y tarjeta de débito.',
  },
  {
    question: '¿Cuánto se demora cada cambio?',
    response: 'El tiempo estimado es de 45 a 60 minutos por cada cambio de aceite. Este tiempo puede variar dependiendo del tipo de vehículo y su estado.',
  },
  {
    question: '¿Puedo modificar mi reserva?',
    response: [
      {
        text: 'Si necesitas cambiar la fecha de tu reserva, debes hacer lo siguiente:',
      },
      {
        text: '1 - Iniciar sesión.',
      },
      {
        text: '2 - Entrar al módulo "Mi Historial".',
      },
      {
        text: '3 - Seleccionar la orden del servicio.',
      },
      {
        text: '4 - Hacer click en reagendar e informar día y hora.',
      },
    ],
  },
  {
    question: '¿Qué ocurre con el aceite que se retira?',
    response:
      'Es almacenado en compartimentos especialmente diseñados para esto, que se trasladan a una estación de servicio. Posteriormente, y una vez ahí, es procesado y retirado por nuestra filial Vía Limpia.',
  },
  {
    question: '¿Acumulo puntos Full al contratar el servicio?',
    response: 'Lamentablemente no es posible acumular puntos Full con el servicio de Cambio de Aceite a domicilio por el momento.',
  },
  {
    question: '¿Qué aceites utilizamos?',
    response: 'Los aceites que utilizamos para los cambios son: Mobil 1, Mobil Super 3000 y Mobil Super 2000.',
  },
  {
    question: '¿Cuánto cuesta un cambio de aceite?',
    response: [
      {
        text: 'Los valores varían dependiendo del pack a contratar.',
      },
      {
        text: '- Servicio Cambio de Aceite 1 (aceite Mobil 1).',
      },
      {
        text: '- Servicio Cambio de Aceite 2 (aceite Mobil Super 3000).',
      },
      {
        text: '- Servicio Cambio de Aceite 3 (aceite Mobil Super 2000).',
      },
      {
        text: 'Los precios pueden variar según el tipo de vehículo y las promociones o descuentos vigentes.',
      },
    ],
  },
  {
    question: '¿Qué incluye un cambio de aceite?',
    response:
      'Cada servicio de cambio de aceite incluye: un aceite Mobil de 4 litros, un filtro de aceite (hasta $4.500) y un agua lava parabrisas Copec de 1 litro. El filtro de aire puedes agregarlo en caso que lo necesites.',
  },
  {
    question: 'Condiciones de pago Webpay (Débito y Crédito)',
    response: [
      {
        text: 'Para pagos con WebPay, el dinero saldrá de tu cuenta bancaria en el momento que confirmes el pedido. Si tu pedido se entrega conforme a lo previsto, el dinero permanecerá en las cuentas de Copec. En caso de que desees añadir productos en el momento de la venta, estos podrán ser agregados a tu pedido original sin problemas, pero el pago de esos productos se debe realizar de forma presencial.',
      },
      {
        text: 'Por otra parte, si necesitas anular tu pedido, podrás realizarlo siempre y cuando éste no haya cambiado de estado a "En Ruta". Para solicitar la devolución de tu dinero, puedes contactarte con nuestros ejecutivos llamando al 800 200 354, quienes te ayudarán a gestionar la devolución mediante transferencia bancaria, la cual se realizará dentro de 7 hábiles.',
      },
    ],
    image: null,
  },
  {
    question: '¿Cuándo se cargarán mis puntos Full?',
    response: 'Los puntos Full serán cargados en el momento en que el servicio sea entregado por el atendedor. Los puntos Full solo podrán ser cargados si eres parte del programa Full.',
    image: null,
  },
];

export default faq_kerosene;
