/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Image from '../../components/Image';
import Modal from '../../components/Modal';
import Title from '../../components/Title';

const DynamicModal = (props: any) => {
    const { open, onContinue, data } = props;
    const { matchMedia } = useSelector((state: any) => state);

    const [style, setStyle] = useState<any>({
        background: '#fff',
        width: '100%',
        height: '85vh',
        maxHeight: '85vh',
        overflow: 'hidden',
        maxWidth: '500px',
    });

    useEffect(() => {
        if (matchMedia.media === 'xs') {
            setStyle({ ...style, height: '85vh' });
        } else {
            const s = {
                background: '#fff',
                width: '100%',
                maxHeight: '85vh',
                overflow: 'hidden',
                maxWidth: '500px',
            };
            setStyle({ ...s });
        }
    }, [matchMedia.media]);
    return (
        <Modal className="px-3 " open={open}>
            <div className="row px-3 rounded-20 position-relative pt-4" style={style}>
                <div className="col-12 position-relative" style={{ maxHeight: '55vh', overflow: 'auto' }}>
                    {data?.image_url ? (
                        <div className="col-12 text-center">
                            <Image image={{
                                default: {
                                    src: data?.image_url,
                                    alt: 'Logo',
                                },
                            }}
                                style={{ maxWidth: '150px' }}
                                className='rounded'
                            />
                        </div>
                    ) : null}

                    <Title size="md" text={data?.title} className="d-flex w-100 justify-content-center mt-3 mb-3 color-dark-blue" />

                    {data?.description?.map((item: any) => {
                        if (item) {
                            return (
                                <div className="col-12 d-flex mb-3 size-09 bold">
                                    <div className="circle-blue me-3 mt-1" />
                                    {item}
                                </div>
                            )
                        }

                        return null
                    })}

                    {data?.observations ? (
                        <div className="col-12 mb-3 size-09 bold-300">
                            <i>{data?.observations}</i>
                        </div>
                    ) : null}
                </div>

                <div className={`col-12 text-center mt-3 ${matchMedia.media === 'xs' ? 'mb-2' : 'mb-5'}`}>
                    <button className="btn-default w-100 py-3 rounded-10 size-11" onClick={onContinue}>
                        Continuar
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default DynamicModal;
