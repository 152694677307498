import Images from "../../assets/image";
import Image from "../Image";

const Logo = (props: any) => {
    const { color, ...rest } = props
    return (
        <Image image={color ? Images.LogoInLine : Images.LogoInLineWhite} {...rest} />
    )
}

export default Logo;
