import PlaceholderParagraph from 'rsuite/esm/Placeholder/PlaceholderParagraph'

const Text = (props: any) => {

    const { value, loading, rows } = props

    if (loading) {
        return (
            <PlaceholderParagraph active rows={rows || 1} />
        )
    }

    return (
        <>{value}</>
    )

}
export default Text