export const menu_home: any = [
  {
    label: "Servicios",
    id: null,
    target_class: 'service-menu'
  },
  {
    label: "Preguntas frecuentes",
    id: "#faq-desktop",
    target_class: 'faq-desktop-menu'
  }
]

export const menu_kerosene: any = [
  {
    label: "Preguntas frecuentes",
    id: "#faq-desktop"
  }
]

export const menu_lub: any = [
  {
    label: "Preguntas frecuentes",
    id: "#faq-desktop"
  }
]

export const menu_lavamax: any = [
  {
    label: "Preguntas frecuentes",
    id: "#faq-desktop"
  }
]