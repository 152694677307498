const Modal = (props: any) => {

  const { open, children, className, ...rest } = props
  return (
    <div
      className={`modal justify-content-center align-items-center ${className}`}
      style={{ display: `${open ? 'flex' : 'none'}`, backdropFilter: 'blur(10px)' }}
      {...rest}
    >
      {children}
    </div>
  )
}

export default Modal