import { handleActions } from 'redux-actions';
import { SET_GTM_CONSENT } from '../actions/gtm_consent';

export const INITIAL_STATE = {
  ad_storage: 'granted',
  analytics_storage: 'granted',
  personalization_storage: 'granted',
  functionality_storage: 'granted',
  security_storage: 'granted',
  show: true,
};

export default handleActions(
  {
    [SET_GTM_CONSENT]: (state: any, action: any) => {
      return { ...state, ...action.payload };
    },
  },
  INITIAL_STATE
);
