/* eslint-disable react-hooks/exhaustive-deps */
import TableLub from './TableLub';

const ServicePriceTableLub = (props: any) => {
  const headers = [
    {
      label: 'Servicio 4 Litros',
      key: 'name',
    },
    {
      label: 'Precio',
      key: 'price',
    },
    {
      label: 'Litro Extra',
      key: 'extra',
    },
  ];

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12 p-0">
          <TableLub data={props.data?.sub_services} headers={headers} />
        </div>
      </div>
    </div>
  );
};

export default ServicePriceTableLub;
