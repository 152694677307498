// PNG Images

import LogoColumn from './svg/logo_column.svg';
import Kerosene from './png/kerosene-20-lts.png';
import Bidon from './png/bidon20.png';
import Bidon2 from './png/bidon_2.png';
import Bidon3 from './png/bidones.png';
import Mask from './png/mascarilla.png';
import Alcohol from './png/alcohol.png';
import CopecCar from './png/copec_car.png';
import ServiceKerosene from './png/service_kerosene.png';
import ServiceLavamax from './png/service_lavamax.png';
import ServiceLub from './png/service_lub.png';
import HeaderInfoKerosene from './png/header_info_kerosene.png';
import HeaderInfoLavamax from './png/header_info_lavamax.png';
import HeaderInfoLub from './png/header_info_lub.png';
import HeaderKerosene from './png/header_kerosene.png';
import HeaderLavamax from './png/header_lavamax.png';
import HeaderLub from './png/header_lub.png';
import WashFull from './png/wash_full.png';
import WashFullMotor from './png/wash_full_plus_motor.png';
import RegisterImage from './png/register_image.png';
import CopecFull from './png/copec_full.png';
import CopecFullRed from './png/copec_full_red.png';
import CopecFullIcon from './png/copec_full_icon.png';

// Svg Images
import Cart from './svg/carrito.svg';
import User from './svg/user.svg';
import ArrowDown from './svg/arrow_down.svg';
import LogoInLine from './svg/logo.svg';
import LogoInLineWhite from './svg/logo_white.svg';
import IconHome1 from './svg/icon_home_1.svg';
import IconHome2 from './svg/icon_home_2.svg';
import IconHome3 from './svg/icon_home_3.svg';
import IconHome4 from './svg/icon_home_4.svg';
import IconInfo from './svg/info.svg';
import SuccessIcon from './svg/icon_success.svg';
import DeleteIcon from './svg/delete_on.svg';
import DontIcon from './svg/select_kerosene.svg';
import BackIcon from './svg/back_icon.svg';
import CuponIcon from './svg/cupon_input.svg';
import CuponMenu from './svg/coupon_menu.svg';
import CuponMenuWhite from './svg/coupon_menu_white.svg';
import CloseWhite from './svg/close_white.svg';
import CloseBlue from './svg/close_blue.svg';
import PlusBlue from './svg/plus_blue.svg';
import EmptyCart from './svg/empty_cart.svg';
import DebitOn from './svg/debito_on.svg';
import DebitOff from './svg/debito.svg';
import CreditOn from './svg/credito_on.svg';
import PasswordOn from './svg/password_on.svg';
import CreditOff from './svg/credito_of.svg';
import PresentialOn from './svg/presencial_on.svg';
import PresentialOff from './svg/presencial_of.svg';
import DirectionIcon from './svg/direction.svg';
import DirectionColoredIcon from './svg/direction_on.svg';
import SplashService from './svg/search_service.svg';
import BulletInfo from './svg/bullet_info.svg';
import Car1 from './svg/car_1.svg';
import Car2 from './svg/car_2.svg';
import Car3 from './svg/car_3.svg';
import Car4 from './svg/car_4.svg';
import BGSignIn from './svg/bg_signin.svg';
import GoogleLogo from './svg/google.svg';
import UserInput from './svg/user_line.svg';
import UserInputWhite from './svg/user_line_white.svg';
import PasswordInput from './svg/password.svg';
import PasswordInputWhite from './svg/password_white.svg';
import EmailInput from './svg/email.svg';
import PhoneInput from './svg/phone.svg';
import DirectionInput from './svg/direction_grey.svg';
import DirectionInputWhite from './svg/direction_white.svg';
import RedDot from './svg/red_dot.svg';
import UserOn from './svg/user_on.svg';
import BGStars from './svg/bg_stars.svg';
import History from './svg/history.svg';
import HistoryWhite from './svg/history_white.svg';
import MyCars from './svg/my_cars.svg';
import MyCarsWhite from './svg/my_cars_white.svg';
import Water from './svg/water.svg';
import WaterWhite from './svg/water_white.svg';
import CloseSession from './svg/end_session.svg';
import HomeColor from './svg/home_color.svg';
import SupportColor from './svg/support_color.svg';
import HistoryColor from './svg/history_color.svg';
import EditIcon from './svg/edit.svg';
import Discount from './svg/discount.svg';

// JPG Images
import CardLavamax from './jpg/card-lavamax.jpg';
import CardLub from './jpg/card-lub.jpg';
import Lub1 from './jpg/oil-01.jpg';
import Lub2 from './jpg/oil-02.jpg';
import NotFound from './jpg/not-found.jpg';

const Images: any = {
  LogoInLine: {
    default: {
      src: LogoInLine,
      alt: 'Logo',
    },
    sm: {
      src: LogoInLine,
      alt: 'Logo',
    },
  },
  LogoInLineWhite: {
    default: {
      src: LogoInLineWhite,
      alt: 'Logo',
    },
    sm: {
      src: LogoInLineWhite,
      alt: 'Logo',
    },
  },
  LogoColumn: {
    default: {
      src: LogoColumn,
      alt: 'Logo',
    },
    sm: {
      src: LogoColumn,
      alt: 'Logo',
    },
  },
  Cart: {
    default: {
      src: Cart,
      alt: 'Cart',
    },
    sm: {
      src: Cart,
      alt: 'Cart',
    },
  },
  User: {
    default: {
      src: User,
      alt: 'User',
    },
    sm: {
      src: User,
      alt: 'User',
    },
  },
  CardLavamax: {
    default: {
      src: CardLavamax,
      alt: 'CardLavamax',
    },
    sm: {
      src: CardLavamax,
      alt: 'CardLavamax',
    },
  },
  CardLub: {
    default: {
      src: CardLub,
      alt: 'CardLub',
    },
    sm: {
      src: CardLub,
      alt: 'CardLub',
    },
  },
  ArrowDown: {
    default: {
      src: ArrowDown,
      alt: 'icon-arrow',
    },
  },
  Kerosene: {
    default: {
      src: Kerosene,
      alt: 'kerosene',
    },
  },
  Bidon: {
    default: {
      src: Bidon,
      alt: 'bidon',
    },
  },
  Bidon2: {
    default: {
      src: Bidon2,
      alt: 'bidon',
    },
  },
  Bidon3: {
    default: {
      src: Bidon3,
      alt: 'bidon',
    },
  },
  Alcohol: {
    default: {
      src: Alcohol,
      alt: 'bidon',
    },
  },
  Mask: {
    default: {
      src: Mask,
      alt: 'bidon',
    },
  },
  Lub1: {
    default: {
      src: Lub1,
      alt: 'lub-01',
    },
  },
  Lub2: {
    default: {
      src: Lub2,
      alt: 'lub-02',
    },
  },
  CopecCar: {
    default: {
      src: CopecCar,
      alt: 'copec-car',
    },
  },
  ServiceKerosene: {
    default: {
      src: ServiceKerosene,
      alt: 'copec-services',
    },
  },
  ServiceLavamax: {
    default: {
      src: ServiceLavamax,
      alt: 'copec-services',
    },
  },
  ServiceLub: {
    default: {
      src: ServiceLub,
      alt: 'copec-services',
    },
  },
  IconHome1: {
    default: {
      src: IconHome1,
      alt: 'copec-services',
    },
  },
  IconHome2: {
    default: {
      src: IconHome2,
      alt: 'copec-services',
    },
  },
  IconHome3: {
    default: {
      src: IconHome3,
      alt: 'copec-services',
    },
  },
  IconHome4: {
    default: {
      src: IconHome4,
      alt: 'copec-services',
    },
  },
  HeaderInfoKerosene: {
    default: {
      src: HeaderInfoKerosene,
      alt: 'copec-services',
    },
  },
  HeaderInfoLavamax: {
    default: {
      src: HeaderInfoLavamax,
      alt: 'copec-services',
    },
  },
  HeaderInfoLub: {
    default: {
      src: HeaderInfoLub,
      alt: 'copec-services',
    },
  },
  HeaderKerosene: {
    default: {
      src: HeaderKerosene,
      alt: 'copec-services',
    },
  },
  HeaderLavamax: {
    default: {
      src: HeaderLavamax,
      alt: 'copec-services',
    },
  },
  HeaderLub: {
    default: {
      src: HeaderLub,
      alt: 'copec-services',
    },
  },
  IconInfo: {
    default: {
      src: IconInfo,
      alt: 'info',
    },
  },
  WashFull: {
    default: {
      src: WashFull,
      alt: 'wash-full',
    },
  },
  WashFullMotor: {
    default: {
      src: WashFullMotor,
      alt: 'wash-full-plus-motor',
    },
  },
  DeleteIcon: {
    default: {
      src: DeleteIcon,
      alt: 'delete-icon',
    },
  },
  CloseWhite: {
    default: {
      src: CloseWhite,
      alt: 'close-icon',
    },
  },
  CloseBlue: {
    default: {
      src: CloseBlue,
      alt: 'close-icon',
    },
  },
  EmptyCart: {
    default: {
      src: EmptyCart,
      alt: 'empty-cart-icon',
    },
  },
  DontIcon: {
    default: {
      src: DontIcon,
      alt: 'dont-icon',
    },
  },
  BackIcon: {
    default: {
      src: BackIcon,
      alt: 'back-icon',
    },
  },
  CuponIcon: {
    default: {
      src: CuponIcon,
      alt: 'cupon-icon',
    },
  },
  CuponMenu: {
    default: {
      src: CuponMenu,
      alt: 'cupon-icon',
    },
  },
  CuponMenuWhite: {
    default: {
      src: CuponMenuWhite,
      alt: 'cupon-icon',
    },
  },
  SuccessIcon: {
    default: {
      src: SuccessIcon,
      alt: 'success-icon',
    },
  },
  DebitOn: {
    default: {
      src: DebitOn,
      alt: 'payment-icon',
    },
  },
  DebitOff: {
    default: {
      src: DebitOff,
      alt: 'payment-icon',
    },
  },
  CreditOn: {
    default: {
      src: CreditOn,
      alt: 'payment-icon',
    },
  },
  CreditOff: {
    default: {
      src: CreditOff,
      alt: 'payment-icon',
    },
  },
  PresentialOn: {
    default: {
      src: PresentialOn,
      alt: 'payment-icon',
    },
  },
  PresentialOff: {
    default: {
      src: PresentialOff,
      alt: 'payment-icon',
    },
  },
  DirectionIcon: {
    default: {
      src: DirectionIcon,
      alt: 'direction-icon',
    },
  },
  DirectionColoredIcon: {
    default: {
      src: DirectionColoredIcon,
      alt: 'direction-icon',
    },
  },
  SplashService: {
    default: {
      src: SplashService,
      alt: 'splash-service',
    },
  },
  BulletInfo: {
    default: {
      src: BulletInfo,
      alt: 'bullet-info',
    },
  },
  Car1: {
    default: {
      src: Car1,
      alt: 'car-icon',
    },
  },
  Car2: {
    default: {
      src: Car2,
      alt: 'car-icon',
    },
  },
  Car3: {
    default: {
      src: Car3,
      alt: 'car-icon',
    },
  },
  Car4: {
    default: {
      src: Car4,
      alt: 'car-icon',
    },
  },
  BGSignIn: {
    default: {
      src: BGSignIn,
      alt: 'bg-sign-page',
    },
  },
  GoogleLogo: {
    default: {
      src: GoogleLogo,
      alt: 'google-icon',
    },
  },
  UserInput: {
    default: {
      src: UserInput,
      alt: 'user-icon',
    },
  },
  UserInputWhite: {
    default: {
      src: UserInputWhite,
      alt: 'user-icon',
    },
  },
  PasswordInput: {
    default: {
      src: PasswordInput,
      alt: 'password-icon',
    },
  },
  PasswordInputWhite: {
    default: {
      src: PasswordInputWhite,
      alt: 'password-icon',
    },
  },
  EmailInput: {
    default: {
      src: EmailInput,
      alt: 'email-icon',
    },
  },
  DirectionInput: {
    default: {
      src: DirectionInput,
      alt: 'direction-icon',
    },
  },
  DirectionInputWhite: {
    default: {
      src: DirectionInputWhite,
      alt: 'direction-icon',
    },
  },
  PhoneInput: {
    default: {
      src: PhoneInput,
      alt: 'phone-icon',
    },
  },
  RedDot: {
    default: {
      src: RedDot,
      alt: 'reddot-icon',
    },
  },
  UserOn: {
    default: {
      src: UserOn,
      alt: 'user-icon',
    },
  },
  BGStars: {
    default: {
      src: BGStars,
      alt: 'stars-bg',
    },
  },
  PlusBlue: {
    default: {
      src: PlusBlue,
      alt: 'plus-icon',
    },
  },
  NotFound: {
    default: {
      src: NotFound,
      alt: 'not-found',
    },
  },
  History: {
    default: {
      src: History,
      alt: 'history',
    },
  },
  HistoryWhite: {
    default: {
      src: HistoryWhite,
      alt: 'history',
    },
  },
  MyCars: {
    default: {
      src: MyCars,
      alt: 'cars',
    },
  },
  MyCarsWhite: {
    default: {
      src: MyCarsWhite,
      alt: 'cars',
    },
  },
  Water: {
    default: {
      src: Water,
      alt: 'water',
    },
  },
  WaterWhite: {
    default: {
      src: WaterWhite,
      alt: 'water',
    },
  },
  CloseSession: {
    default: {
      src: CloseSession,
      alt: 'logout',
    },
  },
  HomeColor: {
    default: {
      src: HomeColor,
      alt: 'icons',
    },
  },
  HistoryColor: {
    default: {
      src: HistoryColor,
      alt: 'icons',
    },
  },
  SupportColor: {
    default: {
      src: SupportColor,
      alt: 'icons',
    },
  },
  EditIcon: {
    default: {
      src: EditIcon,
      alt: 'icons',
    },
  },
  PasswordOn: {
    default: {
      src: PasswordOn,
      alt: 'icons',
    },
  },
  Discount: {
    default: {
      src: Discount,
      alt: 'icons',
    },
  },
  RegisterImage: {
    default: {
      src: RegisterImage,
      alt: 'icons',
    },
  },
  CopecFull: {
    default: {
      src: CopecFull,
      alt: 'icons',
    },
  },
  CopecFullRed: {
    default: {
      src: CopecFullRed,
      alt: 'icons',
    },
  },
  CopecFullIcon: {
    default: {
      src: CopecFullIcon,
      alt: 'icons',
    },
  },
};

export default Images;
