import ServiceKerosene from "../pages/ServiceKerosene"
import ServiceKeroseneSchedule from "../pages/ServiceKeroseneSchedule"
import ServiceLavamax from "../pages/ServiceLavamax"
import ServiceLub from "../pages/ServiceLub"
import ServiceSubscription from "../pages/ServiceSubscription"

export const ServiceSizes = () => {
    return [
        {
            value: 'small_vehicle',
            label: "Vehículo Pequeño",
            size: 1
        },
        {
            value: 'medium_vehicle',
            label: "Vehículo Mediano",
            size: 2
        },
        {
            value: 'large_vehicle',
            label: "Vehículo Grande",
            size: 3
        },
        {
            value: 'extra_large_vehicle',
            label: "Vehículo Extra Grande",
            size: 4
        },
        {
            value: 'requerid_product',
            label: "Kerosene",
            size: 0
        },

    ]
}

export const ServicePages = () => {
    return [
        {
            value: 'kerosene',
            type: 'kerosene',
            label: "Kerosene",
            Component: ServiceKerosene,
        },
        {
            value: 'lavamax',
            type: 'lavamax',
            label: "Lavamax",
            Component: ServiceLavamax
        },
        {
            value: 'lub',
            type: 'lub',
            label: "Cambio de Aceite",
            Component: ServiceLub
        },
        {
            value: 'kerosene-schedule',
            type: 'kerosene-schedule',
            label: "Kerosene con agendamiento",
            Component: ServiceKeroseneSchedule
        },
        {
            value: 'subscription',
            type: 'subscription',
            label: "Suscripción",
            Component: ServiceSubscription,
        },
    ]
}