import Slider, { CustomArrowProps } from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import historial from '../../assets/alima/historial.jpg'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import leftArrow from '../../assets/alima/leftarrow.svg'
import rightArrow from '../../assets/alima/rightarrow.svg'
import CardCliendApp from './components/cardClient'
import { useHistory } from "react-router-dom"


const NextArrow = ({ currentSlide, slideCount, ...props }: CustomArrowProps) => (
    <div {...props}>
        <img alt='Right' src={rightArrow} height={25} />
    </div>
);

const PrevArrow = ({ currentSlide, slideCount, ...props }: CustomArrowProps) => (
    <div {...props}>
        <img alt='Left' src={leftArrow} height={25} />
    </div>
);

function OurClients() {

    const history = useHistory();

    var settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 0,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: false,
                    nextArrow: <img alt='Right' src={rightArrow} height={25} />,
                    prevArrow: <img alt='Left' src={leftArrow} height={25} />,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2,
                    dots: false,
                    nextArrow: <img alt='Right' src={rightArrow} height={25} />,
                    prevArrow: <img alt='Left' src={leftArrow} height={25} />,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: false,
                    nextArrow: <img alt='Right' src={rightArrow} height={25} />,
                    prevArrow: <img alt='Left' src={leftArrow} height={25} />,
                }
            }
        ]
    };


    return (

        <Row className='styleContent'>
            <Col className='container-white'>
                <Row style={{ marginTop: 50, marginBottom: 150 }}>
                    <Col xs={12} sm={12} md={6}>
                        <img src={historial} width="500" className='img-fluid' style={{ borderRadius: "30px"}} alt="Historial" />
                    </Col>
                    <Col xs={12} sm={12} md={6} className="textClient" >
                        <div className='titleClient'>Tu historial de pedidos siempre a mano</div>
                        <p className="paragrapHistory" style={{color: "var(--bs-body-color)"}}>
                            Porque queremos que tengas el control y seguridad de toda la relación con nosotros, aquí encontrarás el historial de todos tus pedidos y el nombre del repartidor que colaboró en cada uno de ellos. ¡Qué esperas para registrarte!
                        </p>
                        <Button className='btnRegister' onClick={() => history.push("/register")}>Registrarme</Button>
                    </Col>
                </Row>

                <div className='title'>Nuestros clientes opinan</div>
                <div className="contentSlider">
                    <Slider {...settings}>
                        <div>
                            <CardCliendApp
                                name="Camila"
                                text="Entrega rápida y fácil forma de pago."
                                start={7} />
                        </div>
                        <div>
                            <CardCliendApp
                                name="Felipe"
                                text="Me llego el pedido muy rápido y venia bien sellado el bidón."
                                start={7} />
                        </div>
                        <div>
                            <CardCliendApp
                                name="Maria"
                                text="Bidones impecables, y buena atención de repartidores."
                                start={7} />
                        </div>
                        <div>
                            <CardCliendApp
                                name="Paula"
                                text="Me solucionaron la vida! Antes tenia que tomar la micro ida y vuelta para llegar a la bomba, y debido al trabajo, tenía que ir de noche. Así que cuando encontré su pagina fue mi salvación, ya que mi bidón de 20 litros pesa bastante."
                                start={7} />
                        </div>
                        <div>
                            <CardCliendApp
                                name="José"
                                text="Muy buen servicio, llega en la fecha comprometida."
                                start={7} />
                        </div>
                        <div>
                            <CardCliendApp
                                name="Luis"
                                text="Vinieron rápido y además contestan mis mensajes incluso el sábado en la noche."
                                start={7} />
                        </div>
                    </Slider>
                </div>

            </Col>
        </Row>
    );
}

export default OurClients;
