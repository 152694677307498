/* eslint-disable react-hooks/exhaustive-deps */
import Images from '../../assets/image';
import ProductsCarousel from '../../components/ProductsCarousel';
import Title from '../../components/Title';
import ServiceTemplate from '../../template/ServiceTemplate';
import ModalKerosene from './ModalKerosene';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Confirm from '../../components/Confirm';
import Image from '../../components/Image';
import { add_service_to_cart, remove_service_from_cart, update_service_to_cart } from '../../redux/actions/shopping_cart';
import { LOAD_OFF, LOAD_ON } from '../../redux/actions/loader';
import KeroseneDAO from '../../services/kerosene.service';
import Services from '../../services/services.service';
import { useParams, useHistory } from 'react-router';
import { set_available_services } from '../../redux/actions/available_services';
import { toast } from 'react-toastify';
import CardKerosene from '../../components/CardKerosene';
import CardProduct from '../../components/CardProduct';
import ProductCard from '../../components/ProductsCarousel/ProductCard';
import { addToCartDataLayerEvent, clearDataLayerEvent, createDataLayerObject, removeFromCartDataLayerEvent, sendDataLayerEvent } from '../../helpers/data_layer';
import GoToCheckoutButton from '../../components/GoToCheckoutButton';
import DynamicModal from '../../components/DynamicModal';
import { v4 } from 'uuid';
import { checkIfCopecFullPointsIsAvailable } from '../../helpers/copec_full_points';

const ServiceKerosene = (props: any) => {
  const CAROUSEL_CONFIGS: any = {
    disableButtonsControls: true,
    infinite: false,
    autoPlay: false,
    paddingRight: 0,
    paddingLeft: 0,
    responsive: {
      0: {
        items: 1,
      },
      755: {
        items: 2,
      },
      1023: {
        items: 3,
      },
      1365: {
        items: 4,
      },
    },
  };

  const CAROUSEL_CONFIGS_MOBILE: any = {
    disableButtonsControls: true,
    infinite: false,
    autoPlay: false,
    paddingRight: 5,
    paddingLeft: 5,
    responsive: {
      0: {
        items: 1,
      },
      300: {
        items: 2,
      },
      755: {
        items: 2,
      },
      1023: {
        items: 3,
      },
      1365: {
        items: 4,
      },
    },
  };

  const { data } = props;
  const carouselRef = useRef<any>(null);
  const carouselRefMobile = useRef<any>(null);
  const [state, setState] = useState<any>({
    products: [],
    service: {},
    related_products: [],
    modal: {
      kerosene: false,
    },
    alert: false,
    product: {},
    productsList: [],
    eds: {},
    slidePosition: 0,
    showSlideNextPrevButtons: true,
    showSlideNextPrevButtonsMobile: true,
    dynamicPopUp: {
      open: false,
      first_load: true,
    },
  });

  const [counter, setCounter] = useState<any>(null);

  const [activeIndex, setActiveIndex] = useState(0);

  const { matchMedia, shopping_cart, available_services, loader } = useSelector((state: any) => state);
  const params: any = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    getSubServices();
  }, []);

  useEffect(() => {
    const unlisten = history.listen(() => {
      dispatch(LOAD_ON());
      window.location.reload();
    });
    return () => {
      unlisten();
    };
  }, [history]);

  useEffect(() => {
    let showSlideNextPrevButtons: any = true;
    let showSlideNextPrevButtonsMobile: any = true;
    if (carouselRef.current && carouselRef.current.state && carouselRef.current.state.itemsCount <= carouselRef.current.state.itemsInSlide) {
      showSlideNextPrevButtons = false;
    }
    if (carouselRefMobile.current && carouselRefMobile.current.state && carouselRefMobile.current.state.itemsCount <= carouselRefMobile.current.state.itemsInSlide) {
      showSlideNextPrevButtonsMobile = false;
    }

    setState((prevState: any) => {
      return { ...prevState, showSlideNextPrevButtons, showSlideNextPrevButtonsMobile };
    });
  }, [carouselRef.current, state.related_products, state.related_products_mobile]);

  const getSubServices = async () => {
    dispatch(LOAD_ON());
    try {
      const page_service: any = available_services.find((as: any) => as.info?.slug === params.slug);
      let serviceResponse: any = null;
      if (page_service.eds.eds_service_id) {
        serviceResponse = await Services.detailsWithRelatedProducts({ PK: page_service.eds.eds_service_id, service_PK: page_service.eds.service_id });
      } else {
        serviceResponse = await Services.details({ PK: page_service.PK, SK: page_service.SK });
      }

      const { code, eds } = props.data;

      let codeEds: any = eds?.info?.code_ref || code;

      const priceServiceResponse = await KeroseneDAO.getService(codeEds);

      const service = { ...serviceResponse.data };

      let related_products: any = [];
      let related_products_mobile: any = [];

      if (!service.related_products) {
        service.related_products = [];
      } else {
        let relatedProducts: any = service?.related_products?.filter((item: any) => item.active);

        related_products = relatedProducts.map((rp: any) => {
          return (
            <CardKerosene
              price={rp.price}
              image={rp.info?.gallery?.url ? { default: { alt: '', src: rp.info?.gallery?.url } } : Images.NotFound}
              text={rp.name}
              value="Ver detalle"
              product={rp}
              data={props.data}
              handleAction={() => _addRelatedProduct({ product: rp, module: 'kerosene' })}
              onPlusProduct={_plusProduct}
              onMinusProduct={_minusProduct}
              recommended={rp.info?.recommended || false}
            />
          );
        });

        related_products_mobile = relatedProducts.map((rp: any) => {
          return (
            <ProductCard
              title={rp.name}
              value={rp.price}
              image={rp.info?.gallery?.url}
              id={rp.PK}
              onClick={() => _addRelatedProduct({ product: rp, module: 'kerosene' })}
              onPlusClick={_plusProduct}
              onMinusClick={_minusProduct}
              product={rp}
              data={props.data}
              recommended={rp.info?.recommended || false}
            />
          );
        });
      }
      const { sub_services } = service;

      const availableServices = sub_services.filter((ss: any) => ss.name !== 'Envio' && ss.name !== 'Envío' && !ss.info?.is_unvailable);

      availableServices.sort((a: any, b: any) => {
        if (a.info?.required < b.info?.required) return 1;
        if (a.info?.required > b.info?.required) return -1;
        return 0;
      });

      const kerosenePrice = priceServiceResponse.data;
      if (kerosenePrice.length > 0) {
        const mainService = availableServices.find((as: any) => as.info?.required === true);
        if (mainService) {
          const { Prices } = kerosenePrice[0];
          if (Prices) {
            const price = Prices.find((p: any) => p.CodPro === '4');
            if (price) {
              const liter: any = mainService?.info?.liter || 20;
              mainService.price = price ? Number(price.Precio) * liter : mainService.price;
            }
          }
        }
      }

      availableServices.map((item: any) => {
        if (item.info.without_discount_price) {
          item.info.without_discount_price = Number(item.info.without_discount_price);
          const porcentageDiscount = ((item.info.without_discount_price - item.price) / item.info.without_discount_price) * 100;
          item.info.porcentageDiscount = Math.round(porcentageDiscount);
          item.info.show_discount = item.info.porcentageDiscount > 0;
        }
        return item;
      });

      const available_service_list: any = available_services.filter((s: any) => s.PK !== page_service.PK);

      const newService = {
        ...service,
        code: page_service.code,
        url: page_service.url,
        eds: page_service.eds,
        id: page_service.id,
      };

      if (page_service.company) {
        newService.company = page_service.company;
      }

      newService.isFullPointsAvailable = checkIfCopecFullPointsIsAvailable(newService);

      available_service_list.push({ ...newService });

      dispatch(set_available_services(available_service_list));

      availableServices.forEach((service: any, index: any) => {
        const item: any = {
          item_name: service.name,
          item_id: service.info?.sku || '',
          price: service.price,
          item_brand: service.name?.toLowerCase(),
          item_category: 'service',
          item_category2: service.info?.required ? 'sub_service' : 'related_product',
          item_category3: '',
          item_category4: '',
          item_variant: '',
          item_list_name: 'show_available_items',
          item_list_id: '',
          index: index,
          quantity: 1,
        };
        clearDataLayerEvent();
        const dataLayerEvent: any = createDataLayerObject('view_item', [item]);
        sendDataLayerEvent(dataLayerEvent);
      });

      service.related_products?.forEach((service: any, index: any) => {
        const item: any = {
          item_name: service.name,
          item_id: service.sku || '',
          price: service.price,
          item_brand: params.slug,
          item_category: 'service',
          item_category2: 'related_product',
          item_category3: '',
          item_category4: '',
          item_variant: '',
          item_list_name: 'show_available_related_products',
          item_list_id: index,
          index: index,
          quantity: 1,
        };
        clearDataLayerEvent();
        const dataLayerEvent: any = createDataLayerObject('view_item', [item]);
        sendDataLayerEvent(dataLayerEvent);
      });

      setState((prevState: any) => {
        const dynamicPopUp: any = {
          open: false,
          first_load: false,
        };
        if (serviceResponse?.data?.info?.popup_info?.isEnabled && prevState.dynamicPopUp.first_load) {
          dynamicPopUp.open = true;
        }
        return { ...prevState, productsList: availableServices, eds: data.eds, related_products, related_products_mobile, service: newService, dynamicPopUp };
      });
    } catch (e) {
      console.log(e);
      toast.error('No fue posible cargar los prductos.');
      dispatch(LOAD_OFF());
    }
    dispatch(LOAD_OFF());
  };

  /** ADDED FIRST PRODUCT OR RELATED PRODUCT */
  const _handleAddProduct = (payload: any) => {
    const isServiceInShoppingCart: any = shopping_cart.services?.find((s: any) => s.PK === props.PK && s.SK === props.SK && s.slug === params.slug);
    const mainService: any = available_services.find((availableService: any) => availableService.PK === props.PK && availableService.SK === props.SK);
    payload.quantity = 1;

    /**
     * IF SERVICE IS REQUIRED ADD TO CART LIKE A MAIN PRODUCT
     * ELSE ADD TO CART LIKE A RELATED PRODUCT
     */

    if (payload.info?.required && !isServiceInShoppingCart) {
      /** ADD SHIPPING PRICE TO PAYLOAD */
      payload.shipping_price = mainService.shipping_price;

      /** CREATE SERVICE OBJECT */
      const newService = {
        id: v4(),
        PK: props.PK,
        SK: props.SK,
        shipping_price: mainService.shipping_price,
        service: payload,
        related_products: [],
        module: 'kerosene',
        eds: state.eds,
        delivery: mainService.shipping_price,
        parent_service: mainService.PK,
        name: props.data.name,
        type: mainService.type,
        slug: params.slug,
        is_service_in_cart: true,
        isFullPointsAvailable: mainService.isFullPointsAvailable,
        info: { ...mainService.info },
      };

      setState((prevState: any) => {
        return {
          ...prevState,
          service: newService,
          products: [payload],
          modal: { kerosene: false },
        };
      });

      /** CREATE DATALAYER ACTION */
      addToCartDataLayerEvent(payload, params.slug, 'sub_service', 'show_available_items');

      /** ADD SERVICE TO CART */
      dispatch(add_service_to_cart(newService));
    }

    if (isServiceInShoppingCart && !payload.info?.required) {
      payload.id = v4();
      payload.service_id = props.PK;
      payload.module = 'kerosene';
      payload.subservice_product = true;
      isServiceInShoppingCart.related_products?.push(payload);
      addToCartDataLayerEvent(payload, params.slug, 'related_product', 'show_available_items');
      dispatch(update_service_to_cart(isServiceInShoppingCart));
    }

    if (!isServiceInShoppingCart && !payload.info?.required) {
      _openAlert();
    }
  };

  /** ADD NEW RELATED PRODUCT */
  const _addRelatedProduct = (payload: any) => {
    /** SEARCH PARENT SERVICE */
    const isServiceInShoppingCart = shopping_cart.services.find((service: any) => service.PK === props.PK && service.slug === params.slug);

    if (isServiceInShoppingCart) {
      payload.product.service_id = props.PK;
      payload.product.module = 'kerosene';
      payload.product.quantity = 1;
      isServiceInShoppingCart.related_products.push(payload.product);
      addToCartDataLayerEvent(payload.product, params.slug, 'related_product', 'show_available_items');
      dispatch(update_service_to_cart(isServiceInShoppingCart));
    } else {
      _openAlert();
    }
  };

  const _plusProduct = (payload: any) => {
    /** SEARCH PARENT SERVICE */
    const isServiceInShoppingCart = shopping_cart.services.find((service: any) => service.PK === props.PK && service.slug === params.slug);

    /** CHECK IF IS A MAIN SERVICE */
    if (isServiceInShoppingCart.service.SK === payload.SK) {
      isServiceInShoppingCart.service.quantity += 1;
      addToCartDataLayerEvent(isServiceInShoppingCart.service, params.slug, 'sub_service', 'show_available_items');
    }

    if (isServiceInShoppingCart.service.SK !== payload.SK) {
      const { related_products } = isServiceInShoppingCart;
      const product = related_products.find((relatedProduct: any) => relatedProduct.SK === payload.SK || relatedProduct.SK === payload.SK);

      if (product && product.subservice_product) {
        if (product.quantity < isServiceInShoppingCart.service.quantity) {
          product.quantity += 1;
          addToCartDataLayerEvent(product, params.slug, 'related_product', 'show_available_items');
        } else {
          toast.error('No se puede agregar más bidones que unidades de Kerosene...');
        }
      } else {
        product.quantity += 1;
        addToCartDataLayerEvent(product, params.slug, 'related_product', 'show_available_items');
      }
    }
    dispatch(update_service_to_cart(isServiceInShoppingCart));
  };

  const _minusProduct = (payload: any) => {
    /** SEARCH PARENT SERVICE */
    const isServiceInShoppingCart = shopping_cart.services.find((service: any) => service.PK === props.PK && service.slug === params.slug);

    /** CHECK IF IS A MAIN SERVICE */
    if (isServiceInShoppingCart.service.SK === payload.SK) {
      const { related_products } = isServiceInShoppingCart;

      /** GET SUBSERVICE PRODUCT */
      const product = related_products.find((rp: any) => rp.subservice_product);

      /**
       * APPLY RULES TO SUBSERVICE PRODUCT
       * SUBSERVICE PRODUCT QUANTITY CAN'T BE GREATER THAN SUBSERVICE MAIN PRODUCT QUANTITY
       */

      if (product) {
        if (product.quantity === isServiceInShoppingCart.service.quantity) {
          isServiceInShoppingCart.service.quantity -= 1;
          product.quantity -= 1;
          removeFromCartDataLayerEvent({ ...isServiceInShoppingCart.service, quantity: 1 }, params.slug, 'sub_service', 'show_available_items');
          removeFromCartDataLayerEvent({ ...product, quantity: 1 }, params.slug, 'related_product', 'show_available_items');
        } else {
          isServiceInShoppingCart.service.quantity -= 1;
          removeFromCartDataLayerEvent({ ...isServiceInShoppingCart.service, quantity: 1 }, params.slug, 'sub_service', 'show_available_items');
        }
      } else {
        isServiceInShoppingCart.service.quantity -= 1;
        removeFromCartDataLayerEvent({ ...isServiceInShoppingCart.service, quantity: 1 }, params.slug, 'sub_service', 'show_available_items');
      }

      /**
       * IF MAIN SERVICE QUANTITY IS 0 REMOVE SERVICE FROM CART AND
       * SEND DATALAYER EVENT TO REMOVE SUBSERVICES PRODUCTS
       */

      if (isServiceInShoppingCart.service.quantity === 0) {
        const relatedToRemove: any = related_products.filter((item: any) => item.quantity > 0);
        relatedToRemove.forEach((item: any) => {
          removeFromCartDataLayerEvent(item, params.slug, 'related_product', 'show_available_items');
        });
        dispatch(remove_service_from_cart(isServiceInShoppingCart));
        return;
      }
    }

    /** CHECK IF IS NOT A MAIN SERVICE */
    if (isServiceInShoppingCart.service.SK !== payload.SK) {
      const { related_products } = isServiceInShoppingCart;
      const product = related_products.find((relateProduct: any) => relateProduct.SK === payload.SK);
      product.quantity -= 1;
      removeFromCartDataLayerEvent({ ...product, quantity: 1 }, params.slug, 'related_product', 'show_available_items');
      if (product.quantity === 0) {
        const index = related_products.findIndex((rp: any) => rp.SK === payload.SK || rp.id === payload.SK);
        related_products.splice(index, 1);
      }
    }

    dispatch(update_service_to_cart(isServiceInShoppingCart));
  };

  const _handleModal = (payload: any) => {
    setState({
      ...state,
      modal: { ...state.modal, kerosene: true },
      product: payload,
    });
  };

  const _openAlert = () => {
    setState((prevState: any) => {
      return { ...prevState, alert: true };
    });
    clearTimeout(counter);
    const counterTime: any = setTimeout(() => {
      setState((prevState: any) => {
        return { ...prevState, alert: false };
      });
    }, 3000);
    setCounter(counterTime);
  };

  const _toogleAlert = () => {
    clearTimeout(counter);
    setState((prevState: any) => {
      return { ...prevState, alert: !prevState.alert };
    });
  };

  const _nextSlide = (slide: any = null) => {
    if (slide === 'mobile') {
      if (carouselRefMobile.current.state.itemsCount - carouselRefMobile.current.state.itemsInSlide > activeIndex) {
        carouselRefMobile.current.slideNext();
      }
    } else {
      if (carouselRef.current.state.itemsCount - carouselRef.current.state.itemsInSlide > activeIndex) {
        carouselRef.current.slideNext();
      }
    }
  };

  const _prevSlide = (slide: any = null) => {
    if (slide === 'mobile') {
      if (activeIndex > 0) {
        carouselRefMobile.current.slidePrev();
      }
    } else {
      if (activeIndex > 0) {
        carouselRef.current.slidePrev();
      }
    }
  };

  const _onSlideChanged = (e: any) => {
    setActiveIndex(e.item);
  };

  return (
    <ServiceTemplate banner={Images.HeaderKerosene} title={'Kerosene'} infoUrl="/services/info/kerosene" data={props.data}>
      <Confirm type="danger" open={state.alert} onClose={_toogleAlert}>
        <div className="px-3 py-4 d-flex justify-content-between" onClick={_toogleAlert}>
          <Image image={Images.DontIcon} />
          <span className="bold">Debes seleccionar antes Kerosene</span>
          <Image image={Images.CloseWhite} />
        </div>
      </Confirm>

      <div className="page-service-kerosene" style={{ minHeight: '35vh' }}>
        <div className="page-service-kerosene__products my-4 d-none d-sm-block">
          <h1 className="bold-300 text-start px-5 color-dark-blue">Kerosene</h1>
        </div>

        <ModalKerosene onContinue={() => _handleAddProduct(state.product)} open={state.modal.kerosene} />

        <DynamicModal open={state.dynamicPopUp?.open} data={state.service?.info?.popup_info} onContinue={() => setState({ ...state, dynamicPopUp: { ...state.dynamicPopUp, open: false } })} />

        {!loader.loading && state.productsList.length === 0 && (
          <Title
            text="No pudimos encontrar ningún producto disponible para este servicio en su dirección."
            className={`text-center color-dark-blue mt-5 ${matchMedia.media === 'xs' ? 'size-07' : 'size-09'}`}
          />
        )}

        <div className="d-flex px-5 justify-content-center d-none d-sm-flex" style={{ flexWrap: 'wrap' }}>
          {state.productsList &&
            state.productsList.map((product: any) => (
              <div className="mx-3 mb-3" key={product.SK}>
                <CardKerosene
                  price={product.price}
                  image={product.info?.gallery?.url ? { default: { alt: '', src: product.info?.gallery?.url } } : Images.NotFound}
                  text={product.name}
                  value="Ver detalle"
                  product={product}
                  data={props.data}
                  handleAction={product.info?.required ? () => _handleModal(product) : () => _handleAddProduct(product)}
                  onPlusProduct={_plusProduct}
                  onMinusProduct={_minusProduct}
                  recommended={product.info?.recommended || false}
                  fullpoints={state.service.isFullPointsAvailable}
                />
              </div>
            ))}
        </div>

        <div className="row d-none d-sm-flex mt-5 mb-3">
          <div className="col-12 text-center color-white">
            <GoToCheckoutButton />
          </div>
        </div>

        <div className="row px-3 mt-5 d-sm-none">
          {state.productsList &&
            state.productsList.map((product: any) => (
              <div className="col-12 px-3 mb-3" key={product.SK}>
                <CardProduct
                  {...product}
                  imagen={product.info?.gallery?.url}
                  action={product.info?.required ? () => _handleModal(product) : () => _handleAddProduct(product)}
                  products={state.products}
                  onPlusProduct={_plusProduct}
                  onMinusProduct={_minusProduct}
                  product={product}
                  fullpoints={state.service.isFullPointsAvailable}
                  data={props.data}
                />
              </div>
            ))}
        </div>

        {/* Related Products Desktop */}

        {state.related_products && state.related_products.length > 0 && state.productsList.length > 0 && (
          <div className="row mt-4 bg-color-blue-light py-4 d-none d-sm-flex">
            {state.related_products?.length > 0 && (
              <div className="col-12 px-5">
                <div className="d-sm-none color-dark-blue mb-5 size-15 bold text-center">También te puede interesar</div>

                <div className="d-none d-sm-block color-dark-blue mb-5 bold-300 text-center px-5" style={{ fontSize: '2rem' }}>
                  ¡Productos más recomendados que te pueden interesar!
                </div>
              </div>
            )}
            <div className="col-12 d-flex justify-content-center align-items-start mb-3">
              {state.showSlideNextPrevButtons && (
                <>
                  <button onClick={_prevSlide} className="btn color-blue-base bold">
                    <Image image={Images.ArrowDown} style={{ transform: 'rotate(90deg)' }} />
                  </button>
                  <button onClick={_nextSlide} className="btn color-blue-base bold">
                    <Image image={Images.ArrowDown} style={{ transform: 'rotate(270deg)' }} />
                  </button>
                </>
              )}
            </div>
            <ProductsCarousel items={state.related_products} {...CAROUSEL_CONFIGS} ref={carouselRef} activeIndex={activeIndex} onSlideChanged={_onSlideChanged} />
          </div>
        )}

        {/* Related Products Mobile */}

        {state.related_products_mobile && state.related_products_mobile.length > 0 && state.productsList.length > 0 && (
          <div className="row mt-4 bg-color-blue-light py-4 d-sm-none">
            {state.related_products_mobile?.length > 0 && (
              <div className="col-12 px-5">
                <div className="d-sm-none color-blue-base mb-4 size-13 bold text-center">También te puede interesar</div>

                <div className="d-none d-sm-block color-dark-blue mb-5 bold-300 text-start px-5" style={{ fontSize: '2rem' }}>
                  ¡Productos más recomendados que te pueden interesar!
                </div>
              </div>
            )}
            <div className="col-12 d-flex justify-content-center align-items-start">
              {state.showSlideNextPrevButtons && (
                <>
                  <button onClick={() => _prevSlide('mobile')} className="btn color-blue-base bold">
                    <Image image={Images.ArrowDown} style={{ transform: 'rotate(90deg)' }} />
                  </button>
                  <button onClick={() => _nextSlide('mobile')} className="btn color-blue-base bold">
                    <Image image={Images.ArrowDown} style={{ transform: 'rotate(270deg)' }} />
                  </button>
                </>
              )}
            </div>
            <ProductsCarousel items={state.related_products_mobile} {...CAROUSEL_CONFIGS_MOBILE} ref={carouselRefMobile} activeIndex={activeIndex} onSlideChanged={_onSlideChanged} />
          </div>
        )}
      </div>
    </ServiceTemplate>
  );
};

export default ServiceKerosene;
