/* eslint-disable react-hooks/exhaustive-deps */
import Image from '../Image';
import '../../styles/components/component.card-lub.scss';
import Images from '../../assets/image';
import './style.scss';
import QuantitySelectorCard from '../QuantitySelectorCard';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { update_service_to_cart } from '../../redux/actions/shopping_cart';
import { edit_service } from '../../redux/actions/services';

const CardRelatedProducts = (props: any) => {
  const { image, text, handleAction, price, data, product, onPlusProduct, onMinusProduct, imageFull } = props;
  const { shopping_cart } = useSelector((state: any) => state);
  const [productData, setProductData] = useState<any>({});
  const dispatch: any = useDispatch();
  const urlParams = new URLSearchParams(window.location.search);

  useEffect(() => {
    const service = shopping_cart.services.find((service: any) => service.id === urlParams.get("id"));

    if (service) {
      const rp: any = service.related_products || [];
      const products: any = [...[service.service], ...rp];
      const productData: any = products.find((p: any) => p.SK === product.SK);
      setProductData({ ...productData });
    }
  }, [shopping_cart]);

  const _handleOnClick = () => {
    if (handleAction) {
      handleAction();
    }
  };

  const _handleAddQuantity = () => {
    const service = shopping_cart.services.find((service: any) => service.id === urlParams.get("id"));
    const relatedProductData: any = service?.related_products.find((el: any) => el.SK === product.SK);
    relatedProductData.quantity += 1;
    setProductData({ ...data });
    dispatch(update_service_to_cart({...service}));
    dispatch(edit_service({...service}));
  };

  const _handleSubtractQuantity = () => {
    const service = shopping_cart.services.find((service: any) => service.id === urlParams.get("id"));
    const relatedProductData: any = service?.related_products.find((el: any) => el.SK === product.SK);
    relatedProductData.quantity -= 1;
    setProductData({ ...data });

    if (relatedProductData.quantity === 0) {
      service.related_products = service.related_products.filter((el: any) => el.SK !== relatedProductData.SK);
    }

    dispatch(update_service_to_cart({...service}));
    dispatch(edit_service({...service}));
  };

  return (
    <div className="card-related-products">
      <div className={`card-related-products__image ${imageFull ? 'image-full' : ''}`}>
        <Image image={image || Images.NotFound} />
      </div>
      <div className="card-related-products__content row">
        <div className="size-12 color-white bold-300">{text}</div>

        <div className="size-15 d-flex align-items-center w-100">
          <span className="color-green-base size-08 me-1">$</span>
          <span className="color-white size-15 bold">{parseInt(price)?.toLocaleString('es-CL')}</span>
        </div>
        {!productData?.quantity || productData?.quantity === 0 ? (
          <div className="w-100 mt-2">
            <button className="btn-green-light py-2 w-100 bold" onClick={_handleOnClick}>
              Agregar
            </button>
          </div>
        ) : (
          <>
            {productData.oneItem ? (
              <div className="w-100 mt-2">
                <button className="btn-green-light py-2 w-100 bold" onClick={_handleSubtractQuantity}>
                  Eliminar
                </button>
              </div>
            ) : (
              <div className="w-75 mt-2">
                <QuantitySelectorCard
                  quantity={productData?.quantity}
                  onAdd={onPlusProduct ? () => onPlusProduct({ PK: product.PK, SK: product.SK }) : _handleAddQuantity}
                  onRemove={onMinusProduct ? () => onMinusProduct({ PK: product.PK, SK: product.SK }) : _handleSubtractQuantity}
                />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default CardRelatedProducts;
