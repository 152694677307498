/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';

const DayItem = (props: any) => {
  const { text, number, disabled, className, selected, handleOnSelect, data, ...rest } = props;
  const [discount, setDiscount] = useState({ max: 0, min: 0, text: "", subtext: "" });

  useEffect(() => {
    const discounts: any = data.blocks?.map((item: any) => parseInt(item.discount));
    if (discounts) {
      discounts.sort((a: any, b: any) => {
        if (a > b) return -1;
        if (a < b) return 1;
        return 0;
      });

      if (discounts.length > 0) {
        const discountValues: any = discounts.reduce((acc: any, item: any) => {
          const searchItem: any = acc.find((i: any) => i === item)
          if (searchItem === undefined) {
            acc.push(item)
          }
          return acc
        }, []);

        const validDiscounts = discountValues.filter((item: any) => item > 0);

        if (validDiscounts.length > 0) {
          setDiscount({ ...discount, text: `${validDiscounts[0]}% Dcto`, subtext: "HASTA", max: validDiscounts[0] })
        }
      }
    }
  }, []);

  const _handleClick = (ev: any) => {
    let hasDisabledClass = false;
    [].forEach.call(ev.currentTarget.classList, (el) => {
      if (el === 'day-item-disabled') {
        hasDisabledClass = true;
      }
    });

    if (!hasDisabledClass) {
      handleOnSelect(data);
    }
  };

  return (
    <div className={`component-day-item ${className} ${disabled ? 'day-item-disabled' : ''} ${selected ? 'day-item-selected' : ''}`} onClick={_handleClick} {...rest}>
      {discount?.max > 0 ? (
        <>
          <div className="component-day-item__text w-100 text-center">
            {text} {number}
          </div>

          <div className="component-day-item__discount text-center mt-1 d-none d-sm-block w-100 text-center">
            <div className='my-1 fs-8'>
              {discount.subtext}
            </div>
            <div className={`${disabled ? '' : 'badge bg-color-blue-base color-white'} py-1 fs-7 ${selected ? 'fw-bolder' : ''}`} style={{ fontSize: '14px' }}>
              {discount.text}
            </div>
          </div>

          <div className="component-day-item__discount text-center mt-1 d-block d-sm-none w-100">
            <div className='my-1 fs-8'>
              {discount.subtext}
            </div>
            <div className={`${disabled ? '' : 'badge bg-color-blue-base color-white'} py-1 fs-7 ${selected ? 'fw-bolder' : ''}`} style={{ fontSize: '11px' }}>
              {discount.text}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="component-day-item__text">{text}</div>
          <div className="component-day-item__text">{number}</div>
        </>
      )}
    </div>
  );
};

export default DayItem;
