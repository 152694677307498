import { createAction } from 'redux-actions';

export const SET_DIRECTIONS: any = createAction('SET_DIRECTIONS');
export const CLEAR_DIRECTIONS: any = createAction('CLEAR_DIRECTIONS');
export const SAVE_STATE: any = createAction('SAVE_STATE');

export const setDirections = (payload: any) => {
  return async (dispatch: any) => {
    dispatch(SET_DIRECTIONS(payload))
    dispatch(SAVE_STATE())
  }
}

export const clearDirections = () => {
  return (dispatch: any) => {
    dispatch(CLEAR_DIRECTIONS())
    dispatch(SAVE_STATE())
  }
}