import store from '../redux/store';
import instance from './api_overt';
class SubscriptionService {
  _setHeaders = () => {
    const state: any = store.getState();
    const { user } = state;
    if (user.isLogged) {
      instance.defaults.headers.common['user'] = user.profile?.PK;
      instance.defaults.headers.common['usersk'] = user.profile?.SK;
      instance.defaults.headers.common['Authorization'] = user?.session?.AuthenticationResult?.AccessToken;
    } else {
      delete instance.defaults.headers.common['user'];
      delete instance.defaults.headers.common['usersk'];
      delete instance.defaults.headers.common['Authorization'];
    }
  };

  list = async () => {
    this._setHeaders();
    try {
      const response = await instance.get('/overt/coupons/list');
      return response;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };
}

const Subscription = new SubscriptionService();
export default Subscription;
