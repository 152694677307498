import moment from "moment";
import "./menuHeader.scss";
import { AiOutlineClose } from "react-icons/ai";
import { PiGasCanBold, PiSealQuestionBold } from "react-icons/pi";
import { RiCarWashingFill } from "react-icons/ri";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import { FaOilCan } from "react-icons/fa";
import { Link } from "react-router-dom";
import Logo from "../../components/Logo";

export default function MenuHeader({ open, toogleMenu }: { open: boolean, toogleMenu: () => void }) {
    return (
        <>
            <div className={`menu-header${open ? ' show' : ''}`}>
                <div className="d-flex justify-content-between">
                    <Logo color style={{ width: "130px", maxWidth: "100%" }} />
                    <div className="color-grey">
                        <button className="btn p-0 color-grey" onClick={toogleMenu}><AiOutlineClose /></button>
                    </div>
                </div>

                <hr className="w-100" />

                <div className="row justify-content-around my-4">
                    <div className="col text-center color-white">
                        <Link to="/register" className="btn-default px-4 py-2">Registrar</Link>
                    </div>
                    <div className="col text-center color-white">
                        <Link to="/sign-in" className="btn-default px-4 py-2">Iniciar sesión</Link>
                    </div>

                </div>

                <hr className="w-100" />

                <ul className="color-grey size-09 bold-300">
                    <li className="mb-4">
                        <Link to="/services/info/kerosene" className="d-flex align-items-center justify-content-between">
                            <div>
                                <PiGasCanBold className="me-2" style={{ fontSize: "22px" }} />
                                <span className="ms-2">Kerosene</span>
                            </div>
                            <MdOutlineArrowForwardIos />
                        </Link>
                    </li>
                    <li className="mb-4">
                        <Link to="/services/info/lavado" className="d-flex align-items-center justify-content-between">
                            <div>
                                <RiCarWashingFill className="me-2" style={{ fontSize: "22px" }} />
                                <span className="ms-2">Lavado de auto</span>
                            </div>
                            <MdOutlineArrowForwardIos />
                        </Link>
                    </li>
                    <li className="mb-4">
                        <Link to="/services/info/cambio-aceite" className="d-flex align-items-center justify-content-between">
                            <div>
                                <FaOilCan className="me-2" style={{ fontSize: "22px" }} />
                                <span className="ms-2">Cambio de aceite</span>
                            </div>
                            <MdOutlineArrowForwardIos />
                        </Link>
                    </li>
                    <li className="mb-4">
                        <Link to="/preguntas-frecuentes" className="d-flex align-items-center justify-content-between">
                            <div>
                                <PiSealQuestionBold className="me-2" style={{ fontSize: "22px" }} />
                                <span className="ms-2">
                                    Preguntas Frecuentes
                                </span>
                            </div>
                            <MdOutlineArrowForwardIos />
                        </Link>
                    </li>
                </ul>
                <div className="size-08 position-absolute bottom-0 py-3 text-center start-0 w-100 color-grey">
                    Todos los derechos reservados © {moment().year()}
                </div>
            </div>
        </>
    )
}
