import ICard from '../../interfaces/ICard';
import Image from '../Image';

import '../../styles/components/component.card.scss';
import Images from '../../assets/image';

const Card: React.FC<ICard> = (props) => {
  const { image, title, subtitle, action, direction, disabled, underlined, fullpoints } = props;

  const _handleOnClick = () => {
    if (action) {
      action();
    } else {
      console.info('[INFO]', 'Everything ok here ;)...');
    }
  };
  return (
    <div className="container-card " style={{ maxWidth: '450px' }}>
      {disabled && <div className="container-card__disabled" />}
      <Image image={image ? { default: { src: image } } : Images.NotFound} className={direction ? 'container-card__image direction' : 'container-card__image'} />

      <div className="container-card__text color-blue-base">
        <div className="container-card__text__title mb-2 color-white">{title}</div>
        <button
          className={direction ? 'btn bold container-card__text__subtitle direction' : 'btn container-card__text__subtitle'}
          onClick={disabled ? () => console.log('LOGGER]', 'Action disabled') : _handleOnClick}
        >
          {underlined ? (
            <>
              <u>{subtitle}</u>
            </>
          ) : (
            <>{subtitle}</>
          )}
        </button>
        {fullpoints ? (
          <div className="color-white size-08 mt-2 d-flex align-items-center" style={{marginLeft: '-6px'}}>
            <Image image={Images.CopecFull || Images.NotFound} className="me-1" style={{ width: '45px' }} />
            Acumula puntos Full Copec
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Card;
