import AddressSeted from '../Address/AddressSeted';

const StickAddress = (props: any) => {
  const { _toogleDrawer, bgWhite, addresses } = props;
  return (
    <div className="row" style={{ position: 'sticky', top: '68px', zIndex: 2 }}>
      <AddressSeted bgWhite={bgWhite} onClick={_toogleDrawer} addresses={addresses} />
    </div>
  );
};

export default StickAddress;
