/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import Modal from '../../components/Modal';
import Title from '../../components/Title';
import DesktopTemplate from '../Profile/DesktopTemplate';
import CarCard from './CarCard';
import ModalSelectCar from './ModalSelectCar';
import { useDispatch, useSelector } from 'react-redux';
import { LOAD_OFF, LOAD_ON } from '../../redux/actions/loader';
import sessionService from '../../services/session.service';
import ModalSelectCarUpdate from './ModalSelectCarUpdate';
import { set_user } from '../../redux/actions/user';
import ConfirmationModal from '../../components/ConfirmationModal';

const Cars = (props: any) => {
  const dispatch = useDispatch();

  const [state, setState] = useState<any>({
    modal: {
      car: {
        show: false,
      },
      car_update: {
        show: false,
      },
    },
    vehicle: {},
  });
  const [cars, setCars] = useState<any>();
  const [selectedCar, setSelectedCar] = useState<any>(null);

  const [confirmationModal, setConfirmationModal] = useState({
    open: false,
    type: 'warning',
    size: 'md',
    title: 'Eliminar',
  });

  const history = useHistory();
  const { user } = useSelector((state: any) => state);

  useEffect(() => {
    const params: any = new URLSearchParams(history.location.search);
    _getCarsList();
    if (params.has('success')) {
      toast.success('Vehículo agregado con éxito');
      window.history.pushState({}, '', '/profile/my-cars');
    }

    if (params.has('success_update')) {
      toast.success('Vehículo actualizado con éxito');
      window.history.pushState({}, '', '/profile/my-cars');
    }

    if (params.has('error')) {
      toast.error('No fue posible agregar el vehículo...');
      window.history.pushState({}, '', '/profile/my-cars');
    }
  }, []);

  const _handleChangeFavorite = async (id: any) => {
    dispatch(LOAD_ON());
    const target = cars.find((car: any) => car.SK === id);
    const favorite = cars.find((car: any) => car.main === true);

    try {
      if (favorite) {
        const favoriteParams = {
          plate: favorite.plate,
          year: favorite.year,
          type: favorite.type,
          main: false,
          info: favorite.info,
        };
        await sessionService.updateVehicles(favorite.SK, favoriteParams);
      }

      if (target) {
        const targetParams = {
          plate: target.plate,
          year: target.year,
          type: target.type,
          main: true,
          info: target.info,
        };
        await sessionService.updateVehicles(target.SK, targetParams);
      }

      await _getCarsList();
    } catch (e) {
      console.log('LOGGER', e);
      toast.error('No fue posible actualizar un vehículo');
      dispatch(LOAD_OFF());
    }
  };

  const _handleZindexMenu = (target: any) => {
    const el: any = document.querySelector('#header-desktop');
    if (el) {
      if (!state.modal[target].show) {
        el.style.zIndex = 0;
      } else {
        el.style.zIndex = 1000;
      }
    }
  };

  const _toggleModal = () => {
    _handleZindexMenu('car');
    setState({
      ...state,
      modal: { ...state.modal, car: { show: !state.modal.car.show } },
    });
  };

  const _toggleModalUpdate = () => {
    _handleZindexMenu('car_update');
    setState({
      ...state,
      modal: {
        ...state.modal,
        car_update: { show: !state.modal.car_update.show },
      },
    });
  };

  const _handleAddCar = async (data: any) => {
    dispatch(LOAD_ON());
    const { plate, year, brand, model } = data;
    const params = {
      plate,
      year: Number(year),
      type: 'null',
      main: false,
      info: {
        brand: {
          id: brand.value,
          label: brand.label,
        },
        model: {
          id: model.value,
          label: model.label,
        },
        size: data.size,
        IdItemDefault: data.IdItemDefault,
      },
    };

    try {
      const response = await sessionService.createVehicle(params);
      setCars([...cars, response.data]);
      toast.success('Vehículo agregado con éxito');
    } catch (e) {
      console.log('LOGGER', e);
      toast.error('No fue posible agregar un vehículo');
    }
    dispatch(LOAD_OFF());
    _toggleModal();
  };

  const _handleUpdateCar = async (data: any) => {
    dispatch(LOAD_ON());
    const { plate, year, brand, model } = data;
    const params = {
      ...state.vehicle,
      plate,
      year: Number(year),
      info: {
        ...state.vehicle?.info,
        brand: {
          id: brand.value,
          label: brand.label,
        },
        model: {
          id: model.value,
          label: model.label,
        },
        size: data.size,
        IdItemDefault: data.IdItemDefault,
      },
    };

    try {
      await sessionService.updateVehicles(state.vehicle.SK, params);
      const carIndex: any = cars.findIndex((c: any) => c.SK === state.vehicle.SK);
      cars.splice(carIndex, 1);
      const newCars: any = [...cars, params];
      setCars(newCars);
      dispatch(set_user({ ...user, vehicles: newCars }));
      toast.success('Vehículo actualizado con éxito');
    } catch (e) {
      console.log('LOGGER', e);
      toast.error('No fue posible actualizar este vehículo');
    }
    dispatch(LOAD_OFF());
    _toggleModalUpdate();
  };

  const _getCarsList = async () => {
    dispatch(LOAD_ON());
    try {
      const response = await sessionService.getVehicles();
      dispatch(set_user({ ...user, vehicles: response.data }));
      setCars(response.data);
    } catch (e) {
      console.log('LOGGER', e);
      toast.error('No fue posible recuperar los vehículos');
    }
    dispatch(LOAD_OFF());
  };

  const _handleConfirmationModalDeleteCar = (id: any) => {
    const car: any = cars.find((item: any) => item.SK === id);
    setSelectedCar({ ...car, id: id });
    setConfirmationModal({ ...confirmationModal, open: true });
  };

  const handleDelete = async (selectedCar: any) => {
    dispatch(LOAD_ON());
    try {
      setConfirmationModal({ ...confirmationModal, open: false });
      await sessionService.deleteVehicle({ PK: selectedCar.PK, SK: selectedCar.SK });
      const data = cars.filter((i: any) => i.SK !== selectedCar.SK);
      setCars(data);
      toast.success('Vehículo eliminado con éxito');
    } catch (e) {
      toast.error('No fue posible eliminar el vehículo');
    }
    dispatch(LOAD_OFF());
  };

  const _handleUpdateData = (pk: any) => {
    const car = cars.find((c: any) => c.SK === pk);
    _handleZindexMenu('car_update');
    setState({
      ...state,
      vehicle: car,
      modal: { ...state.modal, car_update: { show: true } },
    });
  };

  return (
    <>
      <div className="container-fluid d-md-none mt-5">
        <ConfirmationModal {...confirmationModal} handleClose={() => setConfirmationModal({ ...confirmationModal, open: false })} handleConfirm={() => handleDelete(selectedCar)}>
          ¿Seguro que quiere eliminar el vehículo {selectedCar?.info?.brand?.label} - {selectedCar?.info?.model?.label}, patente <span className="to-upper">{selectedCar?.plate}</span> ?
        </ConfirmationModal>
        <div className="w-100 d-flex justify-content-center">
          <div className="row px-2" style={{ maxWidth: '500px' }}>
            <Title text="Mis vehículos" className="text-center color-dark-blue mb-3" size="md" />

            {cars && cars.length === 0 && <Title text="No tienes vehículos guardados. ¡Agrega uno ahora!" className="size-07 text-center mt-5 color-dark-blue" />}

            {cars &&
              cars.map((item: any, index: any) => (
                <CarCard
                  id={item.SK}
                  className="mb-3"
                  selected={item.is_selected}
                  data={item}
                  key={`cars-${index}`}
                  name="favorite_mobile"
                  onChange={_handleChangeFavorite}
                  onUpdate={_handleUpdateData}
                  onDelete={_handleConfirmationModalDeleteCar}
                />
              ))}

            <div className="col-12 p-0 mt-4">
              <button className="btn-default w-100 p-3" onClick={() => history.push('/profile/my-cars/create')}>
                Agregar un vehículo
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid d-none d-md-block">
        <DesktopTemplate>
          <div className="row border-bottom mx-4 px-0 py-4 mb-2">
            <div className="col-6 p-0">
              <span className="bold size-12 color-black">Mis vehículos</span>
            </div>
            <div className="col-6 p-0 text-end color-white">
              <button className="btn btn-default rounded-25 px-4 py-3" onClick={_toggleModal}>
                Agregar un vehículo
              </button>
            </div>
          </div>

          <Modal open={state.modal.car.show}>
            <ModalSelectCar handleConfirm={_handleAddCar} handleClose={_toggleModal} rounded open={state.modal.car.show} />
          </Modal>

          <Modal open={state.modal.car_update.show}>
            <ModalSelectCarUpdate handleConfirm={_handleUpdateCar} handleClose={_toggleModalUpdate} data={state.vehicle} open={state.modal.car_update.show} rounded />
          </Modal>

          {cars && cars.length === 0 && <Title text="No tienes vehículos guardados. ¡Agrega uno ahora!" className="size-07 text-center mt-5 color-dark-blue" />}

          {cars &&
            cars.map((item: any, index: any) => (
              <CarCard
                desktop
                data={item}
                className="mb-3"
                selected={item.is_selected}
                id={item.SK}
                key={`cars-${index}`}
                name="favorite_desktop"
                onChange={_handleChangeFavorite}
                onUpdate={_handleUpdateData}
                onDelete={_handleConfirmationModalDeleteCar}
              />
            ))}
        </DesktopTemplate>
      </div>
    </>
  );
};
export default Cars;
