import { useEffect } from 'react';
import { Button, Modal } from 'rsuite';

const ConfirmationModal = (props: any) => {
  const { handleClose, open, handleConfirm, children, title, type, size, hideFooter, overflow } = props;

  useEffect(() => {
    if (open) {
      document.querySelector('span[title=Close]')?.setAttribute('title', 'Cerrar');
    }
  }, [open]);

  const Warning = () => <i className="bi bi-exclamation-circle-fill" style={{ color: 'orange', fontSize: '30px' }}></i>;
  const Danger = () => <i className="bi bi-x-circle-fill size-15" style={{ color: 'red', fontSize: '30px' }}></i>;
  const Success = () => <i className="bi bi-check-circle-fill" style={{ color: 'green', fontSize: '30px' }}></i>;
  const Schedule = () => <i className="bi bi-calendar-check" style={{ color: '#3598FF', fontSize: '25px' }}></i>;
  const Image = () => <i className="bi bi-card-image" style={{ color: '#a3a3a3', fontSize: '25px' }}></i>;

  const Types: any = {
    undefined: <Success />,
    success: <Success />,
    danger: <Danger />,
    warning: <Warning />,
    schedule: <Schedule />,
    image: <Image />,
  };

  return (
    <Modal open={open} onClose={handleClose} size={size || 'auto'} full={false} className="px-3">
      <Modal.Header>
        <Modal.Title>
          <div className="d-flex align-items-center px-3">
            {Types[type]}
            <span className={`${type === 'none' ? '' : 'ms-4'} bold color-grey`}>{title}</span>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ overflow: overflow ? overflow : 'auto' }}>{children}</Modal.Body>
      {!hideFooter && (
        <Modal.Footer>
          <Button onClick={handleClose} appearance="subtle">
            Cancelar
          </Button>
          <Button onClick={handleConfirm} appearance="primary">
            Confirmar
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};
export default ConfirmationModal;
