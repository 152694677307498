import './style.scss';

import IconLub from '../../assets/image/svg/icon-aceite.svg';
import IconKer from '../../assets/image/svg/icon-kerosene.svg';
import IconLav from '../../assets/image/svg/icon-lavado.svg';
import CloseIcon from '@rsuite/icons/Close';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

const MultipleServicesModal = (props: any) => {
  const { toggleModal, onClose, open, title, subtle, disableCancelBtn, cancelBtnText, onCancel, goToPayment, onOk } = props;

  const { shopping_cart } = useSelector((state: any) => state);
  const params: any = useParams();
  const history = useHistory();

  const _handleOnClose = () => {
    toggleModal();
    onClose();
  }

  const _handleUpdateService = (id: any) => {
    toggleModal();
    history.push(`/services/${params.slug}?id=${id}`);
  }

  return (
    <div className="multipleservices-popup" style={{ display: open ? 'block' : 'none' }}>
      <div className="multipleservices-popup__popup">
        <div className="multipleservices-popup__popup__wrapper">
          <div className="multipleservices-popup__popup__wrapper__header">
            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={_handleOnClose}>
              <CloseIcon />
            </button>
            <h2>Copec a domicilio</h2>
            <hr />
          </div>

          <div className="multipleservices-popup__popup__wrapper__body">

            <div className='d-flex justify-content-around mb-4'>
              <img src={IconLub} alt="Icono Aceite" />
              <img src={IconKer} alt="Icono Kerosene" />
              <img src={IconLav} alt="Icono Lavado" />
            </div>
            <hr />
            <h4>
              {title}
            </h4>

            <div className='size-11 bold py-3'>
              {subtle}
            </div>

            <div>
              {shopping_cart?.services?.filter((service: any) => service.slug === params.slug).map((service: any, index: number) => (
                <div className='row align-items-center'>
                  <div className="col-9 mb-2">
                    <div className="bold size-12">{service?.service?.name}</div>
                    <div className="bold-300 color-grey ms-2">{service?.car?.model?.label}</div>
                    <div className="bold-300 color-grey ms-2">{service?.car?.plate?.toUpperCase()}</div>
                  </div>
                  <div className="col-3 mb-2">
                    <button type='button' className='btn-clear' onClick={() => _handleUpdateService(service.id)}>
                      Editar
                    </button>
                  </div>
                  <hr />
                </div>
              ))}
            </div>

            <div className='w-100 text-start mb-3'>
              <button className='btn-clear bold-300 size-12 text-start px-0' onClick={onOk}>+ Agregar otro vehículo</button>
            </div>

            <div className='d-flex justify-content-around w-100'>

              {!disableCancelBtn ? (
                <button className="bold" type="button" onClick={onCancel} style={{ width: "auto" }}>
                  {cancelBtnText || 'Cancelar'}
                </button>
              ) : null}


              <button className="bold" type="button" onClick={goToPayment} style={{ width: disableCancelBtn ? "100%" : "auto" }}>
                Ir a pagar
              </button>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default MultipleServicesModal;
