import { createAction } from 'redux-actions'; 
 

export const SET_ACTIONBAR: any = createAction('SET_ACTIONBAR'); 
export const SAVE_CHANGES: any = createAction('SAVE_CHANGES'); 


export const set_actionbar = (payload: any) => (dispatch: any) => {
    dispatch(SET_ACTIONBAR(payload));
    dispatch(SAVE_CHANGES());
    return payload;
}