/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import SubscriptionCard from '../../components/SubscriptionCard';
import Title from '../../components/Title';
import Subscription from '../../services/subscriptions.service';
import DesktopTemplate from '../Profile/DesktopTemplate';
import { useDispatch } from 'react-redux';
import { LOAD_OFF, LOAD_ON } from '../../redux/actions/loader';
import { toast } from 'react-toastify';
import moment from 'moment';

const Subscriptions = () => {
  const [subscriptions, setSubscriptions] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    _getSubscriptions();
  }, []);

  const _getSubscriptions = async () => {
    try {
      dispatch(LOAD_ON());
      const response = await Subscription.list();
      setSubscriptions(response.data);
      dispatch(LOAD_OFF());
    } catch (e) {
      toast.error('Ocurrió un error al obtener las suscripciones');
      dispatch(LOAD_OFF());
    }
  };

  return (
    <>
      {/* Mobile version */}
      <div className="container-fluid d-md-none mt-5">
        <Title text="Mis suscripciones" className="text-center color-dark-blue mb-5" size="md" />
        {subscriptions.map((subscription: any, index: number) => (
          <SubscriptionCard className="mb-2" name={subscription.name} end_at={`Cupones validos hasta ${moment(subscription.valid_until)?.format("DD/MM/YYYY hh:mm")}`} coupons={subscription.coupons} />
        ))}
      </div>

      {/* Desktop version */}
      <div className="container-fluid d-none d-md-block">
        <DesktopTemplate>
          <div className="container">
            <div className="row border-bottom mx-4 px-0 py-4 mb-2">
              <div className="col-12 p-0 d-flex align-items-center">
                <span className="bold size-12 color-black">Mis suscripciones</span>
              </div>
            </div>

            {subscriptions.map((subscription: any, index: number) => (
              <SubscriptionCard className="mb-2" name={subscription.name} end_at={`Cupones validos hasta ${moment(subscription.valid_until)?.format("DD/MM/YYYY hh:mm")}`} coupons={subscription.coupons} />
            ))}
          </div>
        </DesktopTemplate>
      </div>
    </>
  );
};

export default Subscriptions;
