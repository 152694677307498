export const carTypes: any = [
  {
    IdTipoVehiculo: 1,
    NombreTipoVehiculo: 'AMBULANCIA',
  },
  {
    IdTipoVehiculo: 2,
    NombreTipoVehiculo: 'AUTOMOVIL',
  },
  {
    IdTipoVehiculo: 3,
    NombreTipoVehiculo: 'BICICLETA MOTOR',
  },
  {
    IdTipoVehiculo: 4,
    NombreTipoVehiculo: 'BICIMOTO',
  },
  {
    IdTipoVehiculo: 5,
    NombreTipoVehiculo: 'BUGGI',
  },
  {
    IdTipoVehiculo: 6,
    NombreTipoVehiculo: 'BUS',
  },
  {
    IdTipoVehiculo: 7,
    NombreTipoVehiculo: 'CAMION',
  },
  {
    IdTipoVehiculo: 8,
    NombreTipoVehiculo: 'CAMIONETA',
  },
  {
    IdTipoVehiculo: 9,
    NombreTipoVehiculo: 'CARROBOMBA',
  },
  {
    IdTipoVehiculo: 10,
    NombreTipoVehiculo: 'CASA RODANTE MOTORIZADA',
  },
  {
    IdTipoVehiculo: 11,
    NombreTipoVehiculo: 'CHASIS CABINADO',
  },
  {
    IdTipoVehiculo: 12,
    NombreTipoVehiculo: 'CHASSIS',
  },
  {
    IdTipoVehiculo: 13,
    NombreTipoVehiculo: 'COCHE MORTUORIO',
  },
  {
    IdTipoVehiculo: 14,
    NombreTipoVehiculo: 'CUATRIMOTO',
  },
  {
    IdTipoVehiculo: 15,
    NombreTipoVehiculo: 'FURGON',
  },
  {
    IdTipoVehiculo: 16,
    NombreTipoVehiculo: 'JEEP',
  },
  {
    IdTipoVehiculo: 17,
    NombreTipoVehiculo: 'LIMUSINA',
  },
  {
    IdTipoVehiculo: 18,
    NombreTipoVehiculo: 'MAQUINA AGRICOLA',
  },
  {
    IdTipoVehiculo: 19,
    NombreTipoVehiculo: 'MAQUINA INDUSTRIAL',
  },
  {
    IdTipoVehiculo: 20,
    NombreTipoVehiculo: 'MICROBUS',
  },
  {
    IdTipoVehiculo: 21,
    NombreTipoVehiculo: 'MINIBUS',
  },
  {
    IdTipoVehiculo: 22,
    NombreTipoVehiculo: 'MOTO',
  },
  {
    IdTipoVehiculo: 23,
    NombreTipoVehiculo: 'MOTONETA',
  },
  {
    IdTipoVehiculo: 24,
    NombreTipoVehiculo: 'OMNIBUS',
  },
  {
    IdTipoVehiculo: 25,
    NombreTipoVehiculo: 'REMOLQUE',
  },
  {
    IdTipoVehiculo: 26,
    NombreTipoVehiculo: 'SEMIREMOLQUE',
  },
  {
    IdTipoVehiculo: 27,
    NombreTipoVehiculo: 'STATION WAGON',
  },
  {
    IdTipoVehiculo: 28,
    NombreTipoVehiculo: 'TAXIBUS',
  },
  {
    IdTipoVehiculo: 29,
    NombreTipoVehiculo: 'TRACTOCAMION',
  },
  {
    IdTipoVehiculo: 30,
    NombreTipoVehiculo: 'TRACTOR',
  },
  {
    IdTipoVehiculo: 31,
    NombreTipoVehiculo: 'TRACTOR ANFIBIO',
  },
  {
    IdTipoVehiculo: 32,
    NombreTipoVehiculo: 'TRICICLO CON MOTOR',
  },
  {
    IdTipoVehiculo: 33,
    NombreTipoVehiculo: 'TRIMOTO',
  },
  {
    IdTipoVehiculo: 34,
    NombreTipoVehiculo: 'TROLEBUS',
  },
];

export const availableTypes: any = [2, 8, 15, 16, 27];
