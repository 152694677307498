const week_days: any = [
  {
    name: 'Domingo',
    value: 0,
  },
  {
    name: 'Lunes',
    value: 1,
  },
  {
    name: 'Martes',
    value: 2,
  },
  {
    name: 'Miércoles',
    value: 3,
  },
  {
    name: 'Jueves',
    value: 4,
  },
  {
    name: 'Viernes',
    value: 5,
  },
  {
    name: 'Sabado',
    value: 6,
  },
];

export default week_days;
