/* eslint-disable no-useless-escape */
import * as yup from 'yup';

export const RecoverySchema = yup.object().shape({
  email: yup
    .string()
    .matches(/^[\w+.]+@\w{2,}\.\w{2,}(?:\.\w{2,})?$/, 'Formato de email no es válido')
    .required('Este campo es requerido'),
  code: yup
    .string()
    .matches(/^[\w\. ?¿¡!^\+-\:()\{\}\[\],;\*#@áàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ]*$/gim, 'Este campo contiene caracteres inválidos')
    .required('Este campo es requerido'),
});

export type RecoveryType = yup.InferType<typeof RecoverySchema>;
