export const checkIfCopecFullPointsIsAvailable = (service: any) => {
  let isFullPointsAvailable: boolean = false;

  const hasParentServiceFullPointsAvailable: any = service.info?.base_division && service.info?.base_division > 0;
  const hasOneOrMoreSubServicesFullPointsAvailable: any = service.sub_services?.filter((item: any) => item.info?.points_per_division && parseInt(item.info?.points_per_division) > 0)?.length > 0;

  if (hasParentServiceFullPointsAvailable && hasOneOrMoreSubServicesFullPointsAvailable) isFullPointsAvailable = true;

  return isFullPointsAvailable;
};
