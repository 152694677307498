import { createAction } from 'redux-actions';

export const SET_CACHE: any = createAction('SET_CACHE');
export const CLEAR_CACHE: any = createAction('CLEAR_CACHE');
export const SAVE_STATE: any = createAction('SAVE_STATE');

export const set_cache = (payload: any) => {
  return (dispatch: any) => {
    dispatch(SET_CACHE(payload));
    dispatch(SAVE_STATE());
  };
};

export const clear_cache = () => {
  return (dispatch: any) => {
    dispatch(CLEAR_CACHE());
    dispatch(SAVE_STATE());
  };
};
