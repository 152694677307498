/* eslint-disable react-hooks/exhaustive-deps */
import { useSelector } from "react-redux"

import './Loader.scss'

const Loader = () => {
  const { loader } = useSelector((state: any) => state)
  const { loading } = loader

  return (
    <div className={`container-loader ${loading ? 'd-flex' : 'd-none'}`}>
      <div className="spinner-border text-light" style={{ width: '80px', height: '80px' }} role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  )
}

export default Loader