/* eslint-disable react-hooks/exhaustive-deps */
import Title from '../../components/Title';
import DesktopTemplate from '../Profile/DesktopTemplate';
import { useEffect, useState } from 'react';
import TableUsers from './TableUsers';
import { Modal } from 'rsuite';
import UserForm from './UserForm';
import MassiveLoadUser from './MassiveLoadUser';
import UsersService from '../../services/users.service';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { END_LOADING, START_LOADING } from '../../redux/actions/loader';
import MassiveLoadResponse from './MassiveLoadResponse';

const CompanyUsers = (props: any) => {
  const [state, setState] = useState<any>({
    data: [],
    filtered: [],
    inputs: {
      search: ""
    },
    modals: {
      user: {
        action: "create",
        open: false
      },
      massiveload: {
        action: "create",
        open: false
      }
    }

  })

  const [isCompanySet, setIsCompanySet] = useState<boolean>(false);

  const [massiveLoadResponse, setMassiveLoadResponse] = useState<any>({
    errors: [],
    csvData: [],
    modal: {
      open: false
    }
  })

  const dispatch = useDispatch();

  useEffect(() => {
    _getData();
  }, []);

  const _getData = async () => {
    try {
      dispatch(START_LOADING())
      const response = await new UsersService().getClientCompanyUsers();
      setState({
        ...state,
        data: response.data,
        filtered: response.data,
        modals: {
          ...state.modals,
          user: { ...state.modals.user, open: false },
          massiveload: { ...state.modals.massiveload, open: false }
        }
      });
      setIsCompanySet(true);
      dispatch(END_LOADING())
    } catch (e: any) {
      setIsCompanySet(false);
      if (e.response?.data?.message?.includes('object is not subscriptable')) {
        toast.error('No hay ninguna empresa asociada a este usuario');
      } else {
        toast.error('Error al obtener los usuarios');
      }
      dispatch(END_LOADING())
    }
  }

  const _handleOnChange = ({ target }: any) => {
    const { name, value } = target;
    setState({ ...state, inputs: { ...state.inputs, [name]: value } });
  }

  const _toggleUserForm = () => {
    console.log('toggle user form')
    setState({ ...state, modals: { ...state.modals, user: { ...state.modals.user, open: !state.modals.user.open } } })
  }
  const _toggleMassiveLoadForm = () => {
    setState({ ...state, modals: { ...state.modals, massiveload: { ...state.modals.massiveload, open: !state.modals.massiveload.open } } })
  }

  const _openCreateForm = () => {
    setState({ ...state, modals: { ...state.modals, user: { ...state.modals.user, action: "create", open: true } } })
  }
  const _openMassiveLoadForm = () => {
    setState({ ...state, modals: { ...state.modals, massiveload: { ...state.modals.massiveload, action: "create", open: true } } })
  }

  const _toggleMassiveLoadResponseModal = () => {
    setMassiveLoadResponse({ ...massiveLoadResponse, modal: { ...massiveLoadResponse.modal, open: !massiveLoadResponse.modal.open } })
  }

  const _openEditForm = (data: any) => {
    setState({ ...state, modals: { ...state.modals, user: { ...state.modals.user, action: "update", open: true, data } } })
  }

  const _handleSubmitUserForm = async (data: any) => {
    try {
      dispatch(START_LOADING())
      if (state.modals.user.action === "create") {
        await new UsersService().createClientCompanyUser(data);
      }

      if (state.modals.user.action === "update") {
        await new UsersService().updateClientCompanyUser(data);
      }

      const message = state.modals.user.action === "create" ? 'Usuario creado correctamente' : 'Usuario actualizado correctamente';

      toast.success(message);

      _getData();
    } catch (e: any) {
      toast.error(e.response?.data?.message || 'Error al crear el usuario. Verifique se hay una empresa asociada al tu usuario junto a un administrador copec');
      dispatch(END_LOADING())
    }
  }

  const _handleToggleStatus = async (data: any) => {
    try {
      dispatch(START_LOADING())
      data.info.isInactive = !data.info?.isInactive;
      await new UsersService().updateClientCompanyUser(data);
      toast.success('Usuario actualizado correctamente');
      _getData();
    } catch (e) {
      toast.error('Error al actualizar el usuario');
      dispatch(END_LOADING())
    }
  }


  const _handleMassiveLoad = async (data: any) => {
    try {
      dispatch(START_LOADING())
      await new UsersService().massiveLoadClientCompanyUsers(data);
      toast.success('Usuarios creados correctamente');
      _getData();
    } catch (e: any) {
      if (e.response?.status === 422) {
        const errors = e.response?.data?.errors?.map((el: any) => {
          return Object.keys(el).map((key: any) => {
            return {
              text: el[key]
            }
          })[0]
        });

        setMassiveLoadResponse({ ...massiveLoadResponse, errors: errors, csvData: data, modal: { open: true } });
        _toggleMassiveLoadForm();
      } else {
        toast.error('Error al crear los usuarios');
      }
      dispatch(END_LOADING())
    }
  }

  const _handleFilter = () => {
    const { search } = state.inputs;
    const filtered = state.data.filter((el: any) => {
      return el.email?.toLowerCase().includes(search.toLowerCase());
    });
    setState({ ...state, filtered });
  }

  return (
    <>
      <Modal open={state.modals.user.open} onClose={_toggleUserForm} >
        <UserForm
          handleClose={_toggleUserForm}
          handleConfirm={_handleSubmitUserForm}
          action={state.modals.user.action}
          data={state.modals.user.data}
        />
      </Modal>

      <Modal open={state.modals.massiveload.open}>
        <MassiveLoadUser handleConfirm={(data) => _handleMassiveLoad(data)} handleClose={_toggleMassiveLoadForm} />
      </Modal>

      <Modal open={massiveLoadResponse.modal.open} onClose={_toggleMassiveLoadResponseModal} >
        <MassiveLoadResponse errors={massiveLoadResponse.errors} csvData={massiveLoadResponse.csvData} handleClose={_toggleMassiveLoadResponseModal} />
      </Modal>

      {/* Mobile Version  */}
      <div className="container-fluid d-md-none mt-5">
        <div className="row justify-content-center">
          <Title size="md" text="Usuarios" className="text-center color-dark-blue" />
        </div>

        <div className="row mt-5 align-items-center">
          <div className="col-12 mt-2">
            <input type="text" className="input-default-editable rounded" value={`${state.inputs.search}`} name="search" placeholder='buscar por correo electronico' onChange={_handleOnChange} />
          </div>

          <div className="col-12 mt-2 text-center text-white">
            <button className='btn-default size-07 px-3 py-2 rounded-10 me-2' onClick={_handleFilter} disabled={!isCompanySet}>Buscar</button>
            <button className='btn-default size-07 px-3 py-2 rounded-10 me-2' onClick={_openCreateForm} disabled={!isCompanySet} >+ Nuevo Usuario</button>
            <button className='btn-default size-07 px-3 py-2 rounded-10' onClick={_openMassiveLoadForm} disabled={!isCompanySet}>+ Carga Masiva</button>
          </div>
        </div>

        <TableUsers
          data={state.filtered}
          toggleStatus={(data: any) => _handleToggleStatus(data)}
          handleUpdate={(data: any) => _openEditForm(data)}
        />

      </div>

      {/* Desktop Version */}

      <div className="container-fluid d-none d-md-block">
        <DesktopTemplate>
          <div className="row border-bottom mx-4 px-0 py-4">
            <div className="col-6 p-0">
              <span className="bold size-12 color-black">Usuarios</span>
            </div>
          </div>

          <div className="row mt-5 align-items-center">
            <div className="col-12 col-xl-5 mt-2">
              <input type="text" className="input-default-editable rounded" value={`${state.inputs.search}`} name="search" placeholder='buscar por correo electronico' onChange={_handleOnChange} />
            </div>

            <div className="col-12 col-xl-7 mt-2 text-end text-white">
              <button className='btn-default size-09 px-3 py-2 rounded-10 me-2' onClick={_handleFilter} disabled={!isCompanySet}>Buscar</button>
              <button className='btn-default size-09 px-3 py-2 rounded-10 me-2' onClick={_openCreateForm} disabled={!isCompanySet}>+ Nuevo Usuario</button>
              <button className='btn-default size-09 px-3 py-2 rounded-10' onClick={_openMassiveLoadForm} disabled={!isCompanySet}>+ Carga Masiva</button>
            </div>
          </div>

          <TableUsers
            data={state.filtered}
            toggleStatus={(data: any) => _handleToggleStatus(data)}
            handleUpdate={(data: any) => _openEditForm(data)}
          />

        </DesktopTemplate>
      </div>
    </>
  );
};
export default CompanyUsers;
