/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import { useEffect, useState } from "react"
import { useSelector } from "react-redux";

const HourItem = (props: any) => {

    const { checked, onChange, rest, hour, day, disabled } = props
    const { matchMedia } = useSelector((state: any) => state)
    const [state, setState] = useState({
        day_format: ''
    });

    useEffect(() => {
        if (matchMedia.media === 'xs') {
            setState({ ...state, day_format: moment(day).format('DD/MM/YYYY') })
        } else {
            setState({ ...state, day_format: moment(day).format('dddd, DD [de] MMMM') })
        }
    }, [day])


    return (
        <div className="container-fluid">
            <div className={`row border rounded my-2 px-1 py-3 size-09 ${disabled ? 'bg-color-grey' : ''}`} style={{ opacity: disabled ? '.4' : '1', cursor: disabled ? 'not-allowed' : 'default' }}>
                <div className={`${matchMedia.media === 'xs' ? 'd-block' : 'd-none'} col-1`}>
                    <input type="radio" checked={checked} onChange={onChange} {...rest} disabled={disabled} />
                </div>
                <div className={`${matchMedia.media === 'xs' ? 'd-none' : 'd-block'} col-1`}>
                    <i className="bi bi-truck color-blue-base"></i>
                </div>
                <div className="col-10 d-flex justify-content-between">
                    <div className="bold capitalize" style={{ width: matchMedia.media === 'xs' ? '40%' : '70%' }}>
                        {`${state.day_format}`}
                    </div>
                    <div style={{ width: '15%' }}>
                        {hour}
                    </div>
                </div>
                <div className={`${matchMedia.media === 'xs' ? 'd-none' : 'd-block'} col-1`}>
                    <input type="radio" checked={checked} onChange={onChange} {...rest} disabled={disabled} />
                </div>
            </div>
        </div>
    )

}
export default HourItem