import DayItem from './DayItem';
import TimeItem from './TimeItem';
import moment from 'moment';
import { v4 } from 'uuid';

const getDates = (_handleHour: any, _setDay: any = null, _setMonth: any = null, _days: any = null, showBlocks: any = false) => {
  let days: any = null;
  if (_setMonth !== null) {
    const currentMonth = moment();
    if (currentMonth.month() === parseInt(_setMonth)) {
      days = moment();
    } else {
      if (parseInt(_setMonth) < moment().month()) {
        days = moment(moment().add(1, 'year')).month(_setMonth).startOf('month');
      } else {
        days = moment().month(_setMonth).startOf('month');
      }
    }
  } else {
    days = moment();
  }
  console.log("_handleHour",_handleHour)
  const availableDays = [];
  const daysComponents: any = [];
  let carouselIndex = 0;
  const startIndex = _setMonth === null || parseInt(_setMonth) === moment().month() ? days.date() - 1 : 0;
  const daysInMonth = days.daysInMonth();

  for (let i = startIndex; i < daysInMonth; i++) {
    const day = {
      day: days.date(),
      month: days.month(),
      _string: days.format('llll').split('.')[0],
      _moment: moment(days),
      selected: false,
      disabled: false,
      id: carouselIndex,
    };

    carouselIndex++;
    availableDays.push(day);
    days.add(1, 'days');
  }

  const months = moment();
  const availableMonths = [];

  for (let i = 0; i < 12; i++) {
    availableMonths.push({
      _string: months.format('MMMM YYYY'),
      _moment: moment(months),
      value: months.month(),
      name: months.format('MMMM YYYY')[0].toUpperCase() + months.format('MMMM YYYY').substring(1),
    });
    months.add(1, 'month');
  }

  const hours = moment();
  const availablesHours: any = [];
  const hoursComponents: any = [];

  if (_setDay) {
    for (let i = 0; i < _days.length; i++) {
      const _moment = moment(_days[i].date + ' ' + _days[i].time);
      const hour: any = {
        _string: _moment.format('HH:mm'),
        _moment: _moment,
        selected: _days[i].selected,
        disabled: !_days[i].enable,
        discount: _days[i].discount,
        parent_ref: _days[i].parent_ref,
        id: v4(),
        duration: _setDay?.duration,
        coverage: _setDay?.coverage,
      };
      const component = _createHourComponent(hour, _handleHour, showBlocks);
      hoursComponents.push(component);
      availablesHours.push(hour);

      hours.add(1, 'hour');
    }
  }

  return {
    hours: availablesHours,
    days: availableDays,
    months: availableMonths,
    inputs: { month: _setMonth ? _setMonth : months.month() },
    hoursComponents,
    daysComponents,
  };
};

export const _createDayComponent = (day: any, _handleClick: any) => {
  return <DayItem text={day._string} number={day.day} disabled={day.disabled} selected={day.selected} data={day} handleOnSelect={_handleClick} style={{ maxWidth: '120px' }} />;
};

const _createHourComponent = (hour: any, _handleClick: any, showBlocks: any = false) => {
  return (
    <TimeItem
      text={hour._string}
      discount={hour.discount}
      disabled={hour.disabled}
      selected={hour.selected}
      data={hour}
      handleOnSelect={_handleClick}
      style={{ maxWidth: '120px' }}
      showBlocks={showBlocks}
    />
  );
};

export default getDates;
