/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import Title from '../../components/Title'
import { LOAD_OFF, LOAD_ON } from '../../redux/actions/loader'
import Order from '../../services/order.service'
import Services from '../../services/services.service'
import DesktopTemplate from '../Profile/DesktopTemplate'
import { ReactComponent as WaterIcon } from '../../assets/image/svg/water_color.svg'
import { ReactComponent as WCIcon } from '../../assets/image/svg/wc.svg'
import { ReactComponent as ShowerIcon } from '../../assets/image/svg/shower.svg'
import { ReactComponent as GlassIcon } from '../../assets/image/svg/glass.svg'

const WaterSaving = (props: any) => {

    const [state, setState] = useState<any>({
        orders: [],
        waterSaved: 0,
        drinkQuantity: 0,
        showerQuantity: 0,
        toiletPond: 0,
        saving_water_by_size: {
            1: 52,
            2: 56,
            3: 60,
            4: 81,
        }
    })
    const dispatch = useDispatch();

    const _getHistory = async () => {
        dispatch(LOAD_ON());
        try {
            const response = await Order.list();
            const serviceNames = await Services.list();

            const lavamaxServices = serviceNames.data.filter((item: any) => item.type === "lavamax");

            response.data.map((item: any) => {
                const [, order_status] = item.GS1PK?.split('-');
                item.status = order_status?.toLowerCase();
                return item
            })

            const lavamaxOrders = response.data.filter((item: any) => item.status === "delivered")
                .filter(
                    (item: any) => lavamaxServices.some(
                        (service: any) => service.PK === item.GS4PK || service.PK === item.parent_service_id)
                );

            const waterSaved = lavamaxOrders.reduce((acc: number, current: any) => {
                const waterQuantity = state.saving_water_by_size[current.info?.vehicle?.size];
                if (waterQuantity) {
                    acc += waterQuantity;
                }
                return acc
            }, 0)

            let drinkQuantity = parseFloat((waterSaved / 2).toFixed(2));
            let showerQuantity = parseFloat((waterSaved / 100).toFixed(2));
            let toiletPond = parseFloat((waterSaved / 15).toFixed(2));

            if (waterSaved === 0) {
                drinkQuantity = 0;
                showerQuantity = 0;
                toiletPond = 0;
            }

            setState({ ...state, orders: lavamaxOrders, waterSaved, drinkQuantity, showerQuantity, toiletPond })
        } catch (e: any) {
            toast.error("No fue posible cargar su historial")
        }
        dispatch(LOAD_OFF());
    }

    useEffect(() => {
        _getHistory();
    }, [])

    return (
        <>
            {/* Mobile Version */}
            <div className="container-fluid d-md-none mt-5">
                <Title text="Mi ahorro de agua" size="md" className="bold color-dark-blue text-center" />

                <div className="row px-5 justify-content-between size-09">
                    <div className="col-12 rounded p-3 mt-3" style={{ background: "#002df910", border: "3px solid", borderColor: "#002df930" }}>
                        <div className="row h-100">
                            <div className="col-9">
                                <div className="w-100 bold color-grey">
                                    Litros acumulados
                                </div>
                                <div className="w-100 color-blue-base bold" style={{ fontSize: "36px" }}>
                                    {state.waterSaved}
                                </div>
                                <div className="w-100 size-08 bold">
                                    En {state.orders?.length} servicio(s)
                                </div>
                            </ div>
                            <div className="col-3 d-flex justify-content-center align-items-center color-blue-base">
                                <div className='d-flex align-items-center justify-content-center' style={{ width: "50px", height: "50px", background: "#002df910", borderRadius: "50%" }}>
                                    <WaterIcon style={{ width: "30px", height: "30px" }} />
                                </div>
                            </ div>
                        </div>
                    </div>

                    <div className="col-12 text-center color-dark-blue bold mt-5 size-12">
                        Estos litros equivale a cualquiera de las siguientes:
                    </div>

                    <div className="col-12 d-flex align-items-center mt-5 color-dark-blue">
                        <GlassIcon className='color-blue-base' style={{ width: "30px", height: "30px" }} />
                        <span className="ms-2">Lo que bebe una persona en <b>{state.drinkQuantity}</b> días.</span>
                    </div>

                    <div className="col-12 d-flex align-items-center mt-5 color-dark-blue">
                        <ShowerIcon className='color-blue-base' style={{ width: "30px", height: "30px" }} />
                        <span className="ms-2"><b>{state.showerQuantity}</b> duchas de 5 minutos.</span>
                    </div>

                    <div className="col-12 d-flex align-items-center mt-5 color-dark-blue">
                        <WCIcon className='color-blue-base' style={{ width: "30px", height: "30px" }} />
                        <span className="ms-2"><b>{state.toiletPond}</b> estanques de WC.</span>
                    </div>

                    <div className='mt-5 color-grey'>
                        * Cuando optas por un lavado en seco, estás ahorrando una cantidad de agua similar a la que se usaría en un lavado tradicional con agua para un vehículo del tamaño del tuyo.
                    </div>
                </div>

            </div>

            {/* Desktop Version */}
            <div className="container-fluid d-none d-md-block">
                <DesktopTemplate>
                    <div className="row border-bottom mx-4 px-0 py-4 mb-2">
                        <div className="col-6 p-0">
                            <span className="bold size-12 color-black">Mi ahorro de agua</span>
                        </div>
                    </div>

                    <div className="row px-5 justify-content-center">
                        <div className="col-4 rounded p-3 mt-4" style={{ background: "#002df910", border: "3px solid", borderColor: "#002df930" }}>
                            <div className="row h-100">
                                <div className="col-9">
                                    <div className="w-100 bold color-grey">
                                        Litros acumulados
                                    </div>
                                    <div className="w-100 color-blue-base bold" style={{ fontSize: "36px" }}>
                                        {state.waterSaved}
                                    </div>
                                    <div className="w-100 size-08">
                                        En {state.orders?.length} servicio(s)
                                    </div>
                                </ div>
                                <div className="col-3 d-flex justify-content-center align-items-center color-blue-base">
                                    <div className='d-flex align-items-center justify-content-center' style={{ width: "50px", height: "50px", background: "#002df910", borderRadius: "50%" }}>
                                        <WaterIcon style={{ width: "30px", height: "30px" }} />
                                    </div>
                                </ div>
                            </div>
                        </div>

                        <div className="col-12 text-start color-dark-blue bold mt-5 size-12">
                            Estos litros equivale a cualquiera de las siguientes:
                        </div>

                        <div className="col-12 d-flex align-items-center mt-5 color-dark-blue">
                            <GlassIcon className='color-blue-base' style={{ width: "30px", height: "30px" }} />
                            <span className="ms-2">Lo que bebe una persona en <b>{state.drinkQuantity}</b> días.</span>
                        </div>

                        <div className="col-12 d-flex align-items-center mt-5 color-dark-blue">
                            <ShowerIcon className='color-blue-base' style={{ width: "30px", height: "30px" }} />
                            <span className="ms-2"><b>{state.showerQuantity}</b> duchas de 5 minutos.</span>
                        </div>

                        <div className="col-12 d-flex align-items-center mt-5 color-dark-blue">
                            <WCIcon className='color-blue-base' style={{ width: "30px", height: "30px" }} />
                            <span className="ms-2"><b>{state.toiletPond}</b> estanques de WC.</span>
                        </div>
                    </div>

                    <div className='px-5 mt-5 color-grey'>
                        * Cuando optas por un lavado en seco, estás ahorrando una cantidad de agua similar a la que se usaría en un lavado tradicional con agua para un vehículo del tamaño del tuyo.
                    </div>


                </DesktopTemplate>
            </div>
        </>
    )

}
export default WaterSaving