/* eslint-disable react-hooks/exhaustive-deps */
import Header from './Header';
import IDefaultTemplate from '../../interfaces/DefaultTemplate';
import Footer from './Footer';
import Adjustment from '../../components/Adjustment';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import AdjustmentDesktop from '../../components/AdjustmentDesktop';

import '../../styles/template.default_template.scss';

const DefaultTemplate: React.FC<IDefaultTemplate> = (props) => {
  const { children, noHeader } = props;
  const { directions } = useSelector((state: any) => state);
  const history = useHistory();

  useEffect(() => {
    if (
      !directions._string &&
      !history.location.pathname.includes('/faq/') &&
      !history.location.search.includes('utm_source') &&
      !history.location.search.includes('gclid') &&
      !history.location.search.includes('fclid')
    ) {
      history.push('/');
    }
  }, [directions]);

  return (
    <div className="container-template-default">
      {noHeader || <Header />}
      <Adjustment />
      <AdjustmentDesktop />
      {children}
      <Footer />
    </div>
  );
};

export default DefaultTemplate;
