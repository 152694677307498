import { Table, Tooltip, Whisper } from 'rsuite';
const { Column, HeaderCell, Cell } = Table;

const TableLub = (props: any) => {
  const { data, headers } = props;

  return (
    <Table
      autoHeight
      data={data}
    >
      {headers &&
        headers.map((h: any) => (
          <>
            {h.key === 'name' ? (
              <Column flexGrow={1}>
                <HeaderCell align="center">
                  <Whisper trigger="click" placement="auto" controlId={`control-id-auto`} speaker={<Tooltip>{h.label}</Tooltip>}>
                    <span className="color-dark-blue bold-300 size-12">{h.label}</span>
                  </Whisper>
                </HeaderCell>
                <Cell align="right">
                  {(rowData) => (
                    <>
                      {rowData[h.key] ? (
                        <div className="size-12">
                          <span className="color-dark-blue bold-300">{rowData[h.key]?.toLocaleString('pt-BR')}</span>
                        </div>
                      ) : null}
                    </>
                  )}
                </Cell>
              </Column>
            ) : (
              <Column flexGrow={1}>
                <HeaderCell align="center">
                  <Whisper trigger="click" placement="auto" controlId={`control-id-auto`} speaker={<Tooltip>{h.label}</Tooltip>}>
                    <span className="color-dark-blue bold-300 size-12">{h.label}</span>
                  </Whisper>
                </HeaderCell>
                <Cell align="center">
                  {(rowData) => (
                    <>
                      {rowData[h.key] ? (
                        <div className="size-12">
                          <span className="color-green-base bold me-1">$</span>
                          <span className="color-dark-blue bold-300">{rowData[h.key]?.toLocaleString('pt-BR')}</span>
                        </div>
                      ) : null}
                    </>
                  )}
                </Cell>
              </Column>
            )}
          </>
        ))}
    </Table>
  );
};

export default TableLub;
