/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import Images from '../../assets/image';
import Image from '../Image';
import QuantitySelect from '../QuantitySelect';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { close_cart } from '../../redux/actions/shopping_cart';

const CheckoutServiceItem = (props: any) => {
  const { service, _handlePlusService, _handleMinusService, _handleDeleteService } = props;
  const [state, setState] = useState({ delivery_date: '' });
  const dispatch: any = useDispatch();

  useEffect(() => {
    if (service.type === 'kerosene' || service.type === 'mini-kerosene') {
      const today: any = moment();
      const todayLimit: any = moment();
      todayLimit.set({ hour: 21, minute: 0, second: 0, millisecond: 0 });
      if (today.isAfter(todayLimit)) {
        today.add(2, 'days');
      } else {
        today.add(1, 'days');
      }

      setState({ ...state, delivery_date: `Fecha de entrega: ${today.format('DD/MM/YYYY')}` });
    }

    if (service.type !== 'kerosene' && service.type !== 'mini-kerosene') {
      if (service.schedule?.hour) {
        setState({ ...state, delivery_date: `Fecha agendamiento: ${moment(service.schedule?.hour?._moment).format('DD/MM/YYYY HH:mm')}` });
      }
    }
  }, []);

  const _handleEditService = (service: any) => {
    dispatch(close_cart());
    if (service.type === 'subscription') window.location.href = `/services/${service.slug}`;
    if (service.type !== 'subscription') window.location.href = `/services/${service.slug}?id=${service.id}`;
  };

  return (
    <div className="row bg-color-white border-default rounded-15 align-items-center py-4">
      <div className="col-2 text-center">
        <Image
          className="w-75"
          image={
            service.service?.info?.gallery?.url
              ? {
                  default: {
                    src: service.service?.info?.gallery?.url,
                  },
                }
              : Images.NotFound
          }
        />
      </div>
      <div className="col-5 size-11 color-black bold-300">
        <div>{service.service.name}</div>

        {service.car && service.car?.brand ? <div className="size-08 color-grey">{`Patente: `}</div> : null}

        <div className="size-09 color-blue-base">{state.delivery_date}</div>
      </div>

      <div className="col-3 color-black d-flex flex-column justify-content-center align-items-center">
        <div className="mb-3 size-14 bold-300">${service.service?.price_total?.toLocaleString('es-CL')}</div>

        {service.type !== 'subscription' && service.service.quantity > 0 && (
          <QuantitySelect quantity={service.service.quantity} onAdd={() => _handlePlusService(service)} onRemove={() => _handleMinusService(service)} />
        )}
      </div>
      <div className="col-2 d-flex justify-content-between mt-3">
        <div>
          <button className="btn color-blue-base size-11 bold" onClick={() => _handleDeleteService(service)}>
            <u className="color-blue-base">Eliminar</u>
          </button>
          {service.type === 'subscription' || !service.service.quantity ? (
            <button className="btn color-blue-base size-11 bold" onClick={() => _handleEditService(service)}>
              <u className="color-blue-base">Editar</u>
            </button>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default CheckoutServiceItem;
