/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react';

const Map = (props: any) => {
  const { google, target, onClickMap, setPin, enableClick, height } = props;

  const mapRef: any = useRef(null);
  const [maps, setMaps] = useState<any>(null);
  const [markers, setMarkers] = useState<any>([]);
  const [position, setPosition] = useState({
    lat: -33.44875413813615,
    lng: -70.66793565449402,
  });

  useEffect(() => {
    if (google && !maps) {
      const maps = new google.maps.Map(mapRef.current, {
        center: position,
        zoom: 12,
        disableDefaultUI: true,
        zoomControl: true,
      });
      if (target.latitude && target.longitude && maps) {
        _setMapData(maps, setPin, enableClick);
      }
      setMaps(maps);
    }
  }, [google]);

  const _addMarker = (position: any, maps: any) => {
    markers.forEach((item: any) => {
      item.setMap(null);
    });
    const marker: any = new google.maps.Marker({
      position: position,
      map: maps,
    });
    maps.setCenter(position);
    maps.setZoom(19);
    setPosition({ lat: position.lat(), lng: position.lng() });
    markers.push(marker);
    setMarkers(markers);
    onClickMap({ latitude: position.lat(), longitude: position.lng() });
  };

  const _setMapData = (maps: any, pin: any = true, onClickEvent: any = false) => {
    const LatLng: any = new google.maps.LatLng(target.latitude, target.longitude);
    markers.forEach((item: any) => {
      item.setMap(null);
    });

    if (pin) {
      const marker: any = new google.maps.Marker({
        position: LatLng,
        map: maps,
      });
      maps.setCenter(LatLng);
      maps.setZoom(19);
      setPosition({ lat: LatLng.lat(), lng: LatLng.lng() });
      markers.push(marker);
      setMarkers(markers);
    }

    if (onClickEvent) {
      if (!google.maps.event.hasListeners(maps, 'click')) {
        google.maps.event.addListener(maps, 'click', (event: any) => {
          _addMarker(event.latLng, maps);
        });
      }
    }
  };

  useEffect(() => {
    if (target.latitude && target.longitude && maps) {
      _setMapData(maps, true, true);
    }
  }, [target]);
  return <div ref={mapRef} className="shadow-sm border" style={{ width: '100%', height: height || '300px', borderRadius: '10px' }} />;
};

export default Map;
