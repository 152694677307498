/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router'
import ButtonBack from '../../components/ButtonBack';
import ConfirmationModal from '../../components/ConfirmationModal';
import Image from '../../components/Image';
import Title from '../../components/Title';
import faq_kerosene from '../../mockups/faq_kerosene';
import faq_lavamax from '../../mockups/faq_lavamax';
import faq_lub from '../../mockups/faq_lub';

const Faq = (props: any) => {

    const params: any = useParams();
    const history = useHistory();
    const { matchMedia } = useSelector((state: any) => state)
    const [state, setState] = useState({
        data: [],
        name: '',
        image: null,
        modal_image: false
    })

    useEffect(() => {
        switch (params.service) {
            case 'kerosene':
                setState({ ...state, name: 'kerosene', data: faq_kerosene });
                break;
            case 'lavado-en-seco':
                setState({ ...state, name: 'lavado en seco', data: faq_lavamax });
                break;
            case 'cambio-de-aceite':
                setState({ ...state, name: 'cambio de aceite', data: faq_lub });
                break;
            default:
                console.log();
        }
    }, [params.service])

    const _handleImage = (image: any) => {
        setState({ ...state, modal_image: true, image: image })
    }

    const _closeModal = () => {
        setState({ ...state, modal_image: false })
    }

    return (
        <div className="container-fluid">

            <ConfirmationModal overflow="none" hideFooter open={state.modal_image} handleConfirm={_closeModal} handleClose={_closeModal} type="image" size="md">
                <div className="row">
                    <div className="col-12 text-center">
                        <Image className="w-75" image={state.image ? state.image : { default: { src: '', alt: '' } }} />
                    </div>
                </div>
            </ConfirmationModal>
            <div className={`row bg-color-blue-light ${matchMedia.media === 'xs' ? 'pt-5 pb-3' : 'py-3'}`}>
                <ButtonBack text="Volver" action={() => history.goBack()} />
            </div>
            <div className="row">
                <Title text={`Preguntas frecuentes sobre ${state.name}`} className={`text-center color-dark-blue ${matchMedia.media === 'xs' ? 'size-06 my-4' : 'my-5'}`} />
            </div>

            <div className={`row bg-color-white mb-4 ${matchMedia.media === 'xs' ? 'px-3' : 'px-5'}`}>
                {state.data && state.data.map((item: any) => (
                    <div className="input-default rounded-10 size-11 my-3 py-4 px-4 ">
                        <div className="col-12 bold color-blue-base">
                            {item.question}
                        </div>
                        <div className="col-12 ps-2 pt-3">
                            {Array.isArray(item.response) ?
                                (
                                    <>
                                        {item.response.map((response: any) => (
                                            <>
                                                <div className="col-12" dangerouslySetInnerHTML={{ __html: response.text }} />
                                                {response.image && (
                                                    <button className="btn" onClick={() => _handleImage(response.image)}>
                                                        <Image style={{ width: '130px' }} className="my-3" image={response.image} />
                                                    </button>
                                                )}
                                            </>
                                        ))}
                                    </>
                                ) :
                                (
                                    <div className="col-12" dangerouslySetInnerHTML={{ __html: item.response }} />
                                )
                            }
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )

}
export default Faq