/* eslint-disable react-hooks/exhaustive-deps */
import Image from '../Image';
import '../../styles/components/component.product-card.scss';
import { useEffect, useState } from 'react';
import QuantitySelect from '../QuantitySelect';
import Images from '../../assets/image';
import { useDispatch, useSelector } from 'react-redux';
import { update_service_to_cart } from '../../redux/actions/shopping_cart';

const ProductCardSchedule = (props: any) => {
  const { title, value, image, onClick, data, product, onPlusClick, onMinusClick } = props;

  const { shopping_cart } = useSelector((state: any) => state);
  const [productData, setProductData] = useState<any>({});
  const urlParams = new URLSearchParams(window.location.search);

  const dispatch: any = useDispatch();

  useEffect(() => {
    const service = shopping_cart.services.find((service: any) => service.id === urlParams.get("id"));

    if (service) {
      const products: any = service.related_products || [];
      const productData: any = products.find((p: any) => p.SK === product.SK);
      setProductData(productData);
    } else {
      setProductData({});
    }
  }, [shopping_cart]);

  const _handleAddQuantity = () => {
    const service = shopping_cart.services.find((service: any) => service.id === urlParams.get("id"));
    const relatedProductData: any = service?.related_products.find((el: any) => el.SK === product.SK);
    relatedProductData.quantity += 1;
    setProductData({ ...data });
    dispatch(update_service_to_cart(service));
  };

  const _handleSubtractQuantity = () => {
    const service = shopping_cart.services.find((service: any) => service.id === urlParams.get("id"));
    const relatedProductData: any = service?.related_products.find((el: any) => el.SK === product.SK);
    relatedProductData.quantity -= 1;
    setProductData({ ...data });

    if (relatedProductData.quantity === 0) {
      service.related_products = service.related_products.filter((el: any) => el.SK !== relatedProductData.SK);
    }

    dispatch(update_service_to_cart(service));
  };

  return (
    <div className=" d-flex justify-content-center m-0 p-0 w-100">
      <div className="component-product-card" style={{ maxWidth: '200px' }}>
        <div className="component-product-card__header" style={{ zIndex: 5 }}>
          <Image image={image ? { default: { src: image } } : Images.NotFound} />
        </div>
        <div className="component-product-card__content" style={{ zIndex: 100 }}>
          <div className="component-product-card__content__title py-2 size-09 bold" dangerouslySetInnerHTML={{ __html: title }} />
          <div className="component-product-card__content__value bold size-13 py-2">${parseInt(value)?.toLocaleString('pt-BR')}</div>
          {!productData?.quantity || productData?.quantity === 0 ? (
            <div className="row">
              <div className="col-12">
                <button className="btn-green mb-3" onClick={onClick ? onClick : () => console.log('[LOG]', 'Everything ok here ;)')}>
                  Agregar
                </button>
              </div>
            </div>
          ) : (
            <>
              {productData.oneItem ? (
                <div className="row">
                  <div className="col-12">
                    <button className="btn-green mb-3" onClick={_handleSubtractQuantity}>
                      Eliminar
                    </button>
                  </div>
                </div>
              ) : (
                <div className="row px-4 justify-content-center">
                  <QuantitySelect
                    quantity={productData?.quantity}
                    onAdd={onPlusClick ? () => onPlusClick({ PK: product.PK, SK: product.SK }) : _handleAddQuantity}
                    onRemove={onMinusClick ? () => onMinusClick({ PK: product.PK, SK: product.SK }) : _handleSubtractQuantity}
                  />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductCardSchedule;
