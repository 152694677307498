/* eslint-disable react-hooks/exhaustive-deps */
import { Loader } from '@googlemaps/js-api-loader';
import { useEffect, useState } from 'react';
import Script from '../Address/Script';
import Drawer from '../Drawer';
import MapsDirections from '../MapsDirections';
import GoogleAutoCompleteMobile from '../MapsDirections/GoogleAutompleteMobile';
import HistoryAddressesMobile from '../MapsDirections/HistoryAddressesMobile';
import ManualDirectionsMobile from '../MapsDirections/ManualDirectionsMobile';

const DrawerDirections = (props: any) => {
  const { state, _toogleDrawer, _closeAndOpen, google, autocompleteService, callback, data, addresses } = props;

  const [googleData, setGoogleData] = useState<any>({
    google: null,
    autocompleteService: null,
    api_key: '',
  });

  useEffect(() => {
    if (!googleData.google) {
      const loader: any = new Loader({
        apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY ? process.env.REACT_APP_GOOGLE_MAPS_API_KEY : '',
        version: 'weekly',
        libraries: ['places', 'geometry'],
        ...{},
      });

      loader.load().then((google: any) => {
        const autocompleteService = new google.maps.places.AutocompleteService();
        setGoogleData({ ...googleData, google, api_key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '', autocompleteService });
      });
    } else {
      const autocompleteService = new googleData.google.maps.places.AutocompleteService();
      setGoogleData({ ...googleData, autocompleteService });
    }
  }, []);

  return (
    <>
      <Drawer {...state.drawer.script}>
        <Script handleClose={() => _toogleDrawer('script')} show={state.drawer.script.show} />
      </Drawer>

      <Drawer {...state.drawer.manual}>
        {state.drawer?.manual?.show ? (
          <ManualDirectionsMobile
            handleClose={() => _toogleDrawer('manual')}
            show={state.drawer.autocomplete.show}
            google={google || googleData?.google}
            autocompleteService={autocompleteService || googleData?.autocompleteService}
            callback={callback}
            data={data}
          />
        ) : null}
      </Drawer>

      <Drawer {...state.drawer.autocomplete}>
        {state.drawer?.autocomplete?.show ? (
          <GoogleAutoCompleteMobile
            handleClose={() => _toogleDrawer('autocomplete')}
            closeAndOpen={_closeAndOpen}
            show={state.drawer.autocomplete.show}
            google={google || googleData?.google}
            autocompleteService={autocompleteService || googleData?.autocompleteService}
            callback={callback}
            data={data}
            addresses={addresses || []}
          />
        ) : null}
      </Drawer>

      <Drawer {...state.drawer.history}>
        {state.drawer?.history?.show ? (
          <HistoryAddressesMobile
            handleClose={() => _toogleDrawer('history')}
            closeAndOpen={_closeAndOpen}
            show={state.drawer?.history?.show}
            google={google || googleData?.google}
            autocompleteService={autocompleteService || googleData?.autocompleteService}
            callback={callback}
            data={data}
            addresses={addresses || []}
          />
        ) : null}
      </Drawer>

      {state && state.modal_maps && <MapsDirections open={state.modal_maps.open} handleClose={() => _toogleDrawer()} />}
    </>
  );
};

export default DrawerDirections;
