/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import Images from '../../assets/image';
import Image from '../Image';
import CuponService from '../../services/cupon.service';

import '../../styles/components/component.cupon.scss';
import { useDispatch, useSelector } from 'react-redux';
import { clear_cupon, set_cupon } from '../../redux/actions/shopping_cart';
import { LOAD_OFF, LOAD_ON } from '../../redux/actions/loader';
import { toast } from 'react-toastify';

const Cupon = (props: any) => {
  const { rounded } = props;
  const [state, setState] = useState({ code: '' });
  const { shopping_cart } = useSelector((state: any) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    setState({ ...state, code: shopping_cart.cupon?.GS2PK || '' });
  }, [shopping_cart]);

  const _handleChange = ({ target }: any) => {
    const { name, value } = target;
    setState({ ...state, [name]: value.toUpperCase() });
  };

  const _handleCupon = async () => {
    state.code = state.code.trim();
    if (!state.code || state.code === '') {
      toast.error('Este cupón no es válido.');
      return;
    }

    dispatch(LOAD_ON());
    try {
      const response: any = await CuponService.checkCupon(state);
      response.data.data = response.data?.data.map((item: any) => {
        item.is_first_check = true;
        return item;
      });
      dispatch(set_cupon(response.data));
    } catch (e: any) {
      console.log(e);
      toast.error('Este cupón no es válido.');
      dispatch(LOAD_OFF());
      dispatch(clear_cupon());
    }
    dispatch(LOAD_OFF());
  };
  return (
    <div className={`component-cupon bg-color-blue-light d-flex justify-content-between ${rounded ? 'rounded-25' : ''}`}>
      <Image image={Images.CuponIcon} />
      <input type="text" placeholder="Ingresa el código" className="size-09" name="code" value={state.code} onChange={_handleChange} />
      <button className="w-25" onClick={_handleCupon} disabled={shopping_cart?.services?.length === 0}>
        Usar
      </button>
    </div>
  );
};

export default Cupon;
