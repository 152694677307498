/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment';
import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { toast } from 'react-toastify';
import Images from '../../assets/image';
import Image from '../../components/Image';
import Title from '../../components/Title';
import { sendDataLayerEvent } from '../../helpers/data_layer';
import { CREDIT_CARD, DEBIT_CARD, PRESENTIAL_PAYMENT } from '../../helpers/payment_methods';
import { LOAD_ON, LOAD_OFF } from '../../redux/actions/loader';
import { clear_services } from '../../redux/actions/services';
import { clear_cart } from '../../redux/actions/shopping_cart';
import paymentService from '../../services/payment.service';
import Header from '../../template/DefaultTemplate/Header';
import RateOrder from './RateOrder';

const OrderSuccess = () => {
  const { user, available_services, loader, matchMedia } = useSelector((state: any) => state);
  const history = useHistory();
  const dispatch = useDispatch();
  const params: any = useParams();
  const [state, setState] = useState<any>({
    payment_type: '',
    services: [],
    payment_status: '',
    full_points: 0,
  });
  useEffect(() => {
    setTimeout(() => {
      document.querySelector('html')?.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }, 100);
  }, []);

  const _handleFinish = () => {
    history.push('/');
  };

  useEffect(() => {
    _checkPayment();
  }, []);

  const _handlePaymentMethod = (type: any) => {
    let payment_method = '';
    switch (type) {
      case 1:
        payment_method = PRESENTIAL_PAYMENT;
        break;
      case 2:
        payment_method = DEBIT_CARD;
        break;
      default:
        payment_method = CREDIT_CARD;
        break;
    }
    return payment_method;
  };

  const _checkPayment = async () => {
    try {
      dispatch(LOAD_ON());
      const order = await paymentService.getPayment(params.id);
      const data = { ...order.data };

      data.services = order.data.services.map((service: any) => {
        service.service_data = available_services.find((as: any) => as.PK === service.id);
        service.amount = service.sub_items.reduce((acc: any, current: any) => (acc += current.quantity_to_buy * parseFloat(current.amount)), 0);
        service.amount_discount = service.sub_items.reduce((acc: any, current: any) => (acc += current.quantity_to_buy * parseFloat(current.amount_discount)), 0);
        return service;
      });

      state.payment_method = _handlePaymentMethod(parseInt(data.payment_type));
      state.sub_total = data.payment?.amount_total + data.payment?.amount_shipping;
      state.amount_total = data.payment?.amount_total;
      state.total = data.payment?.amount_pay;
      state.amount_discount = data.payment?.amount_off;
      state.payment_status = order.data?.payment?.status;
      state.full_points = 0;


      const items: any = [];
      order.data?.services?.forEach((service: any) => {

        if (service.full_points) state.full_points = + service.full_points;

        service.sub_items?.forEach((item: any) => {
          if (item.type_item !== 2) {
            items.push({
              item_name: item.name,
              item_id: item.sku || '',
              price: item.amount,
              item_brand: service?.service_data?.name?.toLowerCase(),
              item_category: item.type_item === 0 ? 'service' : 'related_product',
              item_variant: '',
              quantity: item.quantity_to_buy,
            });
          }
        });
      });

      setState({ ...state, ...data });

      let cupon_name: any = '';

      if (order.data?.cupon) {
        cupon_name = order.data?.cupon?.campaign?.setup[0]?.name;
      }

      let status: any = '';

      if (params.status) {
        status = params.status?.split('=')[1];
      } else {
        status = history?.location?.search?.split('=')[1];
      }

      const payload: any = {
        event: 'purchase',
        path: document.location.href,
        status: status,
        ecommerce: {
          transaction_id: params.id,
          affiliation: '',
          value: order.data?.payment?.amount_pay,
          tax: '',
          shipping: order.data?.payment?.amount_shipping,
          currency: 'CL',
          coupon: cupon_name,
          items
        },
      };

      sendDataLayerEvent(payload);

      if (state.payment_status.toLowerCase() === 'paid' || state.payment_status.toLowerCase() === 'in process') {
        dispatch(clear_services());
        dispatch(clear_cart());
      }

      dispatch(LOAD_OFF());
    } catch (e: any) {
      dispatch(LOAD_OFF());
      toast.error("No fue posible cargar los datos del pedido.")
    }
  };

  return (
    <>
      <Header />
      <div className="container-fluid" style={{ maxWidth: '700px' }}>
        <input type="hidden" name="payment_id" id="payment_id" value={params.id} />

        {loader.loading && (
          <div className="d-flex justify-content-center align-items-center" style={{ width: '100%', height: '100vh' }}>
            <Title text="Cargando información del pedido" className="color-dark-blue text-center" />
          </div>
        )}

        {!loader.loading && state.payment_status.toLowerCase() !== 'paid' && state.payment_status.toLowerCase() !== 'in process' && (
          <div className="d-flex justify-content-center align-items-center" style={{ width: '100%', height: '100vh' }}>
            <div className="row px-4">
              <div className="col-12">
                <Title text="No fue posible completar su pedido." className="color-dark-blue text-center" />
              </div>

              {state.payment_status.toLowerCase() === 'finished' && (
                <div className="col-12 text-center size-11 bold-300 my-4">Has cancelado la transacción en el formulario de pago. Intenta nuevamente.</div>
              )}

              {state.payment_status.toLowerCase() === 'error' && (
                <div className="col-12 text-center size-11 bold-300 my-4">Al parecer ocurrió un error en el formulario de pago. Intenta nuevamente.</div>
              )}

              {state.payment_status.toLowerCase() === 'expired' && (
                <div className="col-12 text-center size-11 bold-300 my-4">Superaste el tiempo máximo que puedes estar en el formulario de pago (10 minutos). Intenta nuevamente.</div>
              )}

              <div className="col-12 color-white">
                <button className="btn-default p-3 size-11 mt-5 color-white w-100" onClick={_handleFinish}>
                  Volver al Inicio
                </button>
              </div>
            </div>
          </div>
        )}

        {!loader.loading && (state.payment_status.toLowerCase() === 'paid' || state.payment_status.toLowerCase() === 'in process') && (
          <>
            <div
              className="row mx-4 px-5"
              style={{
                marginTop: matchMedia.media === 'xs' ? '100px' : '50px',
              }}
            >
              <Image className="w-100" image={Images.SuccessIcon} />
              <div className="col-12 text-center size-15 bold mt-3 color-blue-base">¡Pedido exitoso!</div>
            </div>

            <div className="row py-2">
              <div className="col bg-color-grey color-black px-3 py-2 mt-4 size-11">Detalle de la compra</div>
            </div>

            {state.services &&
              state.services.map((service: any, index: number) => (
                <Fragment key={`service-${index}`}>
                  <div className="row">
                    <div className="col-12 p-3 mt-2 bold color-dark-blue line-border">Servicio {service?.service_data?.name}</div>
                  </div>

                  {/* Loop related products */}

                  {service.sub_items &&
                    service.sub_items.map((rp: any) => (
                      <div className="row line-border p-3 mt-3 align-items-center">
                        <div className="col-12 col-sm-8 size-08 color-black bold" dangerouslySetInnerHTML={{ __html: rp.name?.toLowerCase() === 'envío' ? 'Despacho' : rp.name }} />
                        <div
                          className="col-4 col-sm-2 size-08 color-black bold"
                          dangerouslySetInnerHTML={{
                            __html: `x${rp.quantity_to_buy}`,
                          }}
                        />
                        <div
                          className="col-2 size-08 color-black bold text-end"
                          dangerouslySetInnerHTML={{
                            __html: '$' + (rp.quantity_to_buy * rp.amount).toLocaleString('pt-BR'),
                          }}
                        />
                      </div>
                    ))}
                </Fragment>
              ))}

            <div className="row py-2">
              <div className="col bg-color-grey color-black px-3 py-2 mt-4 size-11">Información del pedido</div>
            </div>

            <div className="row size-09 my-3">
              <div className="col-4">Nombre</div>
              <div className="col-8 color-black" style={{ textTransform: 'capitalize' }}>
                : {state?.user?.name.toLowerCase()}
              </div>
            </div>

            <div className="row size-09 my-3">
              <div className="col-4">Email</div>
              <div className="col-8 color-black">: {state?.user?.email.toLowerCase()}</div>
            </div>

            <div className={`row size-09 my-3 ${!user.rut ? 'd-none' : ''}`}>
              <div className="col-4">Rut</div>
              <div className="col-8 color-black">: {state?.user?.rut}</div>
            </div>

            <div className="row size-09 my-3">
              <div className="col-4">Medio de Pago</div>
              <div className="col-8 color-black">: {state.payment_method}</div>
            </div>

            {state.full_points > 0 ? (
              <div className="row size-09 my-3">
                <div className="col-4">Puntos full</div>
                <div className="col-8 color-black">: {state.full_points}</div>
              </div>
            ) : null}

            {state.payment && state.payment.tbk_detail && (
              <>
                <div className="row my-3 size-09">
                  <div className="col-4">Tarjeta</div>
                  <div className="col-8 color-black">: **** **** **** {state.payment?.tbk_detail?.card_detail?.card_number}</div>
                </div>
                <div className="row my-3 size-09">
                  <div className="col-4">Código de Autorización</div>
                  <div className="col-8 d-flex align-items-center color-black">: {state.payment?.tbk_detail?.details[0]?.authorization_code}</div>
                </div>
                <div className="row my-3 size-09">
                  <div className="col-4">Cuotas</div>
                  <div className="col-8 color-black">: {state.payment?.tbk_detail?.details[0]?.installments_number || 1}</div>
                </div>
              </>
            )}

            {state.services &&
              state.services.map((service: any) => (
                <>
                  {service.schedule && (
                    <div className="row size-09 my-3">
                      <div className="col-4" style={{ textTransform: 'capitalize' }}>
                        Agendamiento {service.service_data?.name}
                      </div>
                      <div className="col-8 bold color-black ">
  :{`${moment(service.schedule?.schedule_at).format('DD/MM/YYYY HH:mm')} - ${moment(service.schedule?.schedule_at).add(1, 'hours').add(15, 'minutes').format('HH:mm')}`}
</div>

                    </div>
                  )}
                  {!service.schedule && (
                    <div className="row size-09 my-3">
                      <div className="col-4" style={{ textTransform: 'capitalize' }}>
                        Entrega {service.service_data?.name}
                      </div>
                      <div className="col-8 bold color-black ">: {`${moment().add('days', 1).format('DD/MM/YYYY')}`}</div>
                    </div>
                  )}
                </>
              ))}

            {state.amount_discount && state.amount_discount > 0 ? (
              <div className="row size-09 my-3">
                <div className="col-4 my-2">Subtotal</div>
                <div className="col-8 my-2 bold color-blue-base">: ${state.sub_total?.toLocaleString('pt-BR')}</div>
                <div className="col-4 my-2">Descuento</div>
                <div className="col-8 my-2 bold color-blue-base">: ${state.amount_discount?.toLocaleString('pt-BR')}</div>
                <div className="col-4 my-2">Total</div>
                <div className="col-8 my-2 bold color-blue-base">: ${(state.total)?.toLocaleString('pt-BR')}</div>
              </div>
            ) : (
              <div className="row size-09 my-3">
                <div className="col-4">Total</div>
                <div className="col-8 bold color-blue-base">: ${state.total?.toLocaleString('pt-BR')}</div>
              </div>
            )}

            {state.full_points > 0 ? (
              <div className="row size-09 mt-5 mb-2">
                <div className="col-12">* Los puntos Full serán cargados cuando los servicios sean entregados.</div>
              </div>
            ) : null}



            <RateOrder payment_id={params.id} />

            <div className="row px-4 py-5 bg-color-blue-light mb-5 color-white">
              <button className="btn-default p-3 size-11" onClick={_handleFinish}>
                Volver al Inicio
              </button>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default OrderSuccess;
