/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Rate } from 'rsuite';
import ErrorValidation from '../../components/ErrorValidation';
import validate from '../../helpers/validate';
import { set_evaluations } from '../../redux/actions/evaluations';
import RateService from '../../services/rate.service';
import { RateOrderSchema } from '../../validations/rate.order.validations';

const RateOrder = (props: any) => {
  const dispatch: any = useDispatch();
  const { evaluations } = useSelector((state: any) => state);
  const [inputs, setInputs] = useState<any>({
    order_id: props.order_id,
    payment_id: props.payment_id,
    rate: 0,
    temporary_rate: 0,
    descripcion: '',
    is_evaluated: false,
    is_loading: false,
  });

  const [validations, setValidations] = useState<any>({});

  useEffect(() => {
    const payment: any = evaluations.payments?.find((item: any) => item === props.payment_id);
    if (payment) {
      setInputs({ ...inputs, is_evaluated: true });
    }
  }, [evaluations]);

  const handleInput = (e: any) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };

  const _handleFinish = async () => {
    let validations: any = await validate(RateOrderSchema, inputs);

    console.log(inputs.rate)

    if (inputs.rate < 1) {
      if (validations) validations = { ...validations, rate: ['Este campo es requerido'] };
      if (!validations) validations = { rate: ['Este campo es requerido'] };
    }

    setValidations({ ...validations });
    if (validations) return;

    setInputs({ ...inputs, is_loading: true });

    try {
      let res: any = await RateService.create(inputs);
      dispatch(set_evaluations([...evaluations.payments, props.payment_id]));
      if (res.data.status === 'success') {
        toast.success('Evaluación exitosa.');
      } else {
        toast.error('Evalución ya realizada.');
      }
      setInputs({ ...inputs, is_loading: false });
    } catch (e: any) {
      setInputs({ ...inputs, is_loading: false });
      toast.error('No fue posible realizar su evaluación.');
    }
  };

  if (inputs.is_evaluated) {
    return (
      <div className="row py-5 bg-color-blue-light mb-5 color-white text-center bold-300">
        <div className="col-12">
          <p className="color-dark-blue size-12">¡Gracias por calificar este pedido!</p>
        </div>
      </div>
    );
  }

  return (
    <div className="row px-4 py-5 bg-color-blue-light mb-5 color-white">
      <p className="color-black">A nivel general, ¿Cómo evalúas tu experiencia de compra en nuestra web Copec a domicilio?</p>

      <Rate
        max={7}
        value={inputs.rate}
        onChangeActive={(v: any) => {
          handleInput({ target: { name: 'temporary_rate', value: v } });
        }}
        onClick={() => handleInput({ target: { name: 'rate', value: inputs.temporary_rate } })}
        className="mx-auto mb-4"
        disabled={inputs.is_loading}
      />
      <ErrorValidation name="rate" className="text-end bold-300" validations={validations} />

      <p className="color-black">Déjanos tus sugerencias</p>
      <div className="w-100">
        <textarea name="descripcion" value={inputs.descripcion} cols={30} rows={10} className="w-100 color-black" onChange={handleInput} disabled={inputs.is_loading}></textarea>
        <ErrorValidation name="descripcion" className="text-end bold-300" validations={validations} />
      </div>

      <button className="btn-default p-3 size-11 mt-3 d-flex justify-content-center align-items-center" onClick={_handleFinish} disabled={inputs.is_loading}>
        {inputs.is_loading && <div className="spinner-border size-09 me-4" role="status" />}
        Enviar
      </button>
    </div>
  );
};

export default RateOrder;
