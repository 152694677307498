import { handleActions } from 'redux-actions'
import { SET_MEDIA } from '../actions/matchMedia'
export const INITIAL_STATE = {
  media: 'sm'
}
export default handleActions({
  [SET_MEDIA]: (state: any, action: any) => {
    if (state.media !== action.payload) {
      return { ...state, media: action.payload }
    }

    return state
  }
}, INITIAL_STATE)