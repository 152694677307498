/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import Images from '../../assets/image'
import ButtonBack from '../../components/ButtonBack'
import Image from '../../components/Image'
import ProfileImage from '../../components/ProfileImage'
import { clear_user } from '../../redux/actions/user'
import Header from '../../template/DefaultTemplate/Header'
import DesktopTemplate from './DesktopTemplate'

import { menu } from './menu'

const Profile = (props: any) => {

  const history = useHistory();
  const dispatch = useDispatch();
  const { user, matchMedia } = useSelector((state: any) => state);

  useEffect(() => {
    if (!user || !user.isLogged) {
      history.push('/sign-in')
    }

    if (matchMedia && matchMedia.media !== 'xs') {
      history.push('/profile/personal-data')
    }
  }, [user])

  const _logOff = () => {
    dispatch(clear_user());
  }

  const _goto = (to: any) => {
    history.push(to)
  }
  return (
    <div className="container-fluid m-0 p-0">
      <Header />

      {/* Mobile Version  */}

      {matchMedia && matchMedia.media && matchMedia.media === 'xs' && (
        <div className="d-flex" style={{ flexDirection: 'column', alignItems: 'center' }}>
          <div className="row w-100 px-0 m-0" style={{ paddingTop: '90px', maxWidth: '600px' }}>
            <div className="col-12 px-0 mb-3">
              <ButtonBack text="Volver" action={() => history.goBack()} />
            </div>
          </div>

          <div className="d-flex align-items-center mb-4 w-100 px-2" style={{ maxWidth: '600px' }}>
            <ProfileImage _string={`${user.profile.name} ${user.profile.family_name}`} name={user.profile.name} surname={user.profile.family_name} />
            <div className="color-black ms-3 capitalize size-11 bold">{`${user.profile.name} ${user.profile.family_name}`}</div>
          </div>

          {menu && menu.map((item: any) => (
            <div className="row px-2 py-1 w-100" style={{ maxWidth: '600px' }}>
              <div className="col-12 input-default rounded-10 p-3" style={{ border: 'none' }}>
                <button className="btn w-100 color-grey m-0 p-0 d-flex justify-content-between align-items-center" onClick={() => _goto(item.url)}>
                  <div className="d-flex align-items-center color-black">
                    <Image image={Images[item.icon]} className="me-4" style={{width: "20px"}} />
                    {item.label}
                  </div>
                  <Image image={Images.ArrowDown} style={{ transform: 'rotate(270deg)' }} />
                </button>
              </div>
            </div>
          ))}

          <div className="row px-2 py-1 w-100" style={{ maxWidth: '600px' }}>
            <div className="col-12 input-default rounded-10 p-3 d-flex justify-content-between" style={{ border: 'none' }}>
              <button className="btn w-100 color-grey m-0 p-0" onClick={_logOff}>
                <div className="d-flex align-items-center bold">
                  <Image image={Images.CloseSession} className="me-4" />
                Cerrar sesión
              </div>
              </button>
            </div>
          </div>

          <div className="adjustment-iphone" style={{ height: '30vh' }} />
        </div>
      )}

      {/* Desktop Version  */}

      {matchMedia && matchMedia.media && matchMedia.media !== 'xs' && (
        <div className="container-fluid pt-5 mt-5">
          <DesktopTemplate>

          </DesktopTemplate>
        </div>
      )}

    </div >
  )

}
export default Profile