import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Accordion from 'react-bootstrap/Accordion'
import Button from 'react-bootstrap/Button'
import atencion from '../../assets/alima/atencion.svg'
import productos from '../../assets/alima/productos.svg'
import servicios from '../../assets/alima/servicios.svg'
import protocolos from '../../assets/alima/protocolos.svg'
import point from '../../assets/alima/point.svg'
import step1 from '../../assets/alima/step1.png'
import step2 from '../../assets/alima/step2.png'
import step3 from '../../assets/alima/step3.png'

import CardBenefitApp from './components/cardBenefit'

function HowDoesWork({ _handleToogleDirections, _handleFirstDrawer }: any) {
    return (
        <Row className='styleContentWhite' >
            <Col className='container-color'>
                <div className='title'>Cómo Funciona</div>

                <Accordion defaultActiveKey="0" >
                    <Accordion.Item eventKey="0" className='acordeon'>
                        <Accordion.Header>
                            <div style={{ flexDirection: 'row', display: 'flex' }}>
                                <div>
                                    <img src={step1} alt="step1" className='acordeonImg' height={40} style={{ float: 'left' }} />
                                </div>
                                <div className='acordeonTitle'>Ingresa tu dirección para ver los servicios en tu zona</div>
                            </div>
                        </Accordion.Header>
                        <Accordion.Body>
                            <p className="color-black" style={{ fontFamily: 'var(--font-normsLight)' }}>
                                Tu dirección exacta es importante para chequear si el servicio que requieres está disponible en tu hogar, nos permite saber dónde brindarte el mejor servicio y ayudarte a tener la mejor experiencia con nosotros.
                            </p>

                            <Button className='btnApp d-none d-sm-inline-block' onClick={_handleToogleDirections}>
                                <img src={point} alt="Point" height={25} style={{ float: 'left' }} />
                                Ingresa tu dirección
                            </Button>

                            <Button className='btnApp d-inline-block d-sm-none' onClick={_handleFirstDrawer}>
                                <img src={point} alt="Point" height={25} style={{ float: 'left' }} />
                                Ingresa tu dirección
                            </Button>

                        </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="1" className='acordeon'>
                        <Accordion.Header>
                            <div style={{ flexDirection: 'row', display: 'flex' }}>
                                <div>
                                    <img src={step2} alt="step2" className='acordeonImg' height={40} style={{ float: 'left' }} />
                                </div>
                                <div className='acordeonTitle'>Elige tu servicio y agenda tu hora</div>
                            </div>
                        </Accordion.Header>
                        <Accordion.Body>
                            <p className="color-black" style={{ fontFamily: 'var(--font-normsLight)' }}>Elige el servicio que necesites y luego escoge el horario que más te acomode. Puedes solicitar más de un servicio en una misma compra</p>
                        </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="2" className='acordeon'>
                        <Accordion.Header>
                            <div style={{ flexDirection: 'row', display: 'flex' }}>
                                <div>
                                    <img src={step3} alt="step3" className='acordeonImg' height={40} style={{ float: 'left' }} />
                                </div>
                                <div className='acordeonTitle'>Espera nuestra llegada el día acordado</div>
                            </div>
                        </Accordion.Header>
                        <Accordion.Body>
                            <p className="color-black" style={{ fontFamily: 'var(--font-normsLight)' }}>Uno de nuestros socios atendedores irá a realizar el servicio en la día y hora agendada. Recuerda que debes estar presente o dar aviso para que alguien haga la recepción de nuestro socio atendedor.</p>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>


                <div className='title'>Beneficios</div>
                <Row style={{ marginTop: 50, textAlign: 'center' }}>

                    <Col xs={6} sm={6} md={6} lg={3}>
                        <CardBenefitApp
                            imagen={atencion}
                            title="Atención personalizada"
                            content="El mejor equipo a tu servicio."
                        />
                    </Col>

                    <Col xs={6} sm={6} md={6} lg={3}>
                        <CardBenefitApp
                            imagen={productos}
                            title="Productos de la mejor calidad"
                            content="Productos biodegradables para entregar el mejor servicio y comprometido con el medio ambiente."
                        />
                    </Col>

                    <Col xs={6} sm={6} md={6} lg={3}>
                        <CardBenefitApp
                            imagen={servicios}
                            title="Servicios todos los días"
                            content="Nuestro compromiso es estar para ti, cuándo quieras y dónde quieras."
                        />
                    </Col>

                    <Col xs={6} sm={6} md={6} lg={3}>
                        <CardBenefitApp
                            imagen={protocolos}
                            title="Protocolos de seguridad y sanidad"
                            content="Productos certificados y desinfectados. Entrega de información activa. Identificación a la vista. de nuestro personal."
                        />
                    </Col>
                </Row>

                <div className="text-center" style={{ marginBottom: 50, paddingTop: 40 }}>
                    <Button className='btnApp d-none d-sm-inline-block' onClick={_handleToogleDirections}>Agendar un servicio</Button>
                    <Button className='btnApp d-inline-block d-sm-none' onClick={_handleFirstDrawer}>Agendar un servicio</Button>
                </div>
            </Col>
        </Row>
    );
}

export default HowDoesWork;
