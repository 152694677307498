import Images from '../../assets/image'
import Image from '../../components/Image'
import Title from '../../components/Title'

const StepOne = (props: any) => {
  const { _handleChange, _handleGeolocation, results } = props

  return (
    <div className="container-fluid mt-5">
      <div className="w-100 d-flex justify-content-center">
        <div className="row px-2" style={{ maxWidth: '500px' }}>
          <Title text="Mis direcciones" className="text-center color-dark-blue mb-3" size="md" />
          <div className="col-12 d-flex bg-color-blue-light border p-3 rounded-10 mb-2 w-100">
            <input
              type="text"
              className="input-transparent w-100"
              name="name"
              placeholder="Informa la dirección"
              style={{ flex: '1' }}
              onChange={_handleChange}
            />
          </div>

          {results && results.map((item: any) => (
            <div className="col-12 bg-color-blue-light border p-3 rounded-10 mb-1 d-flex">
              <Image image={Images.DirectionInput} />
              <button className="w-100 btn d-flex flex-column" onClick={() => _handleGeolocation(item)}>
                <div className="bold color-grey text-start">{item.title}</div>
                <div className="size-09 color-grey text-start">{item.subtitle}</div>
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  )

}
export default StepOne