/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Images from '../../assets/image';
import Image from '../Image';
import Title from '../Title';
import { PAGES_DONT_RENDER } from '../../helpers/pages_payment_dont';
import { set_actionbar } from '../../redux/actions/action_bar';
import ServiceCard from './ServiceCard';
import DiscountService from '../../services/discounts.service';
import { set_cross_selling } from '../../redux/actions/cross_selling';
import { LOAD_OFF, LOAD_ON } from '../../redux/actions/loader';
import { _isCrossSellingAvailable } from '../../helpers/search_services';

import '../../styles/components/component.topayment.scss';
import ActionBottomModalDesktop from './ModalDesktop';
import { close_cart } from '../../redux/actions/shopping_cart';

const ActionBottom = (props: any) => {
  const { shopping_cart, matchMedia, user, available_services, action_bar } = useSelector((state: any) => state);
  const [show, setShow] = useState(true);
  const [crossSellingData, setCrossSellingData] = useState<any>({
    show: false,
    service: null,
  });

  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (PAGES_DONT_RENDER.find((pdr: any) => pdr === history.location.pathname) || history.location.pathname.includes('order')) {
      setShow(false);
    } else {
      setShow(true);
    }
    history.listen((location: any) => {
      if (PAGES_DONT_RENDER.find((pdr: any) => pdr === location.pathname) || location.pathname.includes('order')) {
        setShow(false);
      } else {
        setShow(true);
      }
    });
  }, []);

  useEffect(() => {
    _handleTotal();
  }, [shopping_cart]);

  useEffect(() => {
    if (action_bar.modal_open || action_bar.show) {
      _handleCrossSelling();
    }
  }, [action_bar.modal_open, action_bar.show]);

  const _handleCrossSelling = async () => {
    dispatch(LOAD_ON());
    const crossSellingDiscount: any = await DiscountService.getCrossSellingDiscountData();
    const isCrossSellingAvailable: any = _isCrossSellingAvailable({ ...shopping_cart });
    const service: any = action_bar.unselected_services?.find((item: any) => item.type !== 'kerosene' && item.type !== 'kerosene-schedule');

    if (crossSellingDiscount?.is_available && isCrossSellingAvailable && service) {
      setCrossSellingData({ ...crossSellingData, show: true, service: service });
    } else {
      setCrossSellingData({ ...crossSellingData, show: false, service: null });
    }
    dispatch(set_cross_selling(crossSellingDiscount));
    dispatch(LOAD_OFF());
  };

  const _handleServices = () => {
    dispatch(close_cart());
    available_services.sort((a: any, b: any) => a.name > b.name ? 1 : -1)
    if (matchMedia.media === 'xs') {
      dispatch(set_actionbar({ ...action_bar, unselected_services: available_services, show: true }))
    } else {
      dispatch(set_actionbar({ ...action_bar, unselected_services: available_services, modal_open: true }))
    }
  };

  const _handleGotoCheckout = () => {
    dispatch(set_actionbar({ ...action_bar, show: false, modal_open: false }));
    history.push('/check-out/step-1');
  };


  const _closeWindowAndRedirectToHome = () => {
    dispatch(set_actionbar({ ...action_bar, show: false }))
    history.push('/')
  }

  const _handleGotoService = (payload: any) => {
    dispatch(set_actionbar({ ...action_bar, show: false, modal_open: false }));
    history.push(payload);
  };

  const _handleTotal = () => {
    const total = shopping_cart.services.reduce((acc: any, current: any) => {
      acc += parseFloat(current.service.price) * (current.service.quantity || 1);
      if (current.related_products) {
        current.related_products.forEach((rp: any) => {
          const totalRP = Number(rp.price) * (rp.quantity || 1);
          acc += totalRP;
        });
      }
      return acc;
    }, 0);

    dispatch(set_actionbar({ ...action_bar, total }));
  };

  return (
    <>
      {/* Versao Mobile */}
      <div className={`component-available-services ${action_bar.show ? 'show' : ''}`}>
        <div className="component-available-services__services px-4 pt-5">
          <Title size="md" text="También puedes agregar" className="color-dark-blue text-center bold mb-4" />

          <button className="btn component-available-services__services__close" onClick={_closeWindowAndRedirectToHome}>
            {' '}
            <Image image={Images.CloseBlue} />
          </button>

          {shopping_cart?.cross_selling?.is_available && shopping_cart?.services?.length === 1 ? (
            <div className="row btn-dark-blue m-0 mb-3 p-3 rounded-10 bold-300">
              <div className="col-12">
                Pide otro servicio ahora y obtén un {shopping_cart.cross_selling?.percentage}% de descuento en tu carrito.
              </div>
            </div>
          ) : null}

          {action_bar.unselected_services.map((service: any) => (
            <ServiceCard service={service} _handleGotoService={_handleGotoService} key={service.PK} />
          ))}

          <div className="row mt-4 px-2 mb-4">
            <button className="btn-dark-blue bold p-3 size-11 rounded-10" onClick={_handleGotoCheckout}>
              No, gracias
            </button>
          </div>
          <div className="adjustment-iphone" style={{ height: '15vh' }} />
        </div>
      </div>

      {/* Versao Desktop */}

      <ActionBottomModalDesktop _handleGotoCheckout={_handleGotoCheckout} _handleGotoService={_handleGotoService} />

      {/* Dual version Mobile - Desktop */}

      <div
        className={`component-topayment px-3 size-12 ${shopping_cart.services.length > 0 && show === true ? 'd-flex d-sm-none' : 'd-none'}`}
        style={{
          bottom: `${user.isLogged && (matchMedia.media === 'md' || matchMedia.media === 'sm' || matchMedia.media === 'xs') ? '70px' : '0px'}`,
        }}
        onClick={_handleServices}
      >
        <div className="row">
          <div className="">
            <span className="bold me-2">Ir a pagar</span>
            <span>${action_bar.total?.toLocaleString('pt-BR')}</span>
          </div>
        </div>
      </div>

      {user && user.isLogged && (matchMedia.media === 'md' || matchMedia.media === 'sm' || matchMedia.media === 'xs') && history.location.pathname !== '/sign-in/cognito' && (
        <div
          className="d-flex justify-content-between align-items-center px-4 position-fixed w-100 m-0"
          id="footer-tools"
          style={{
            bottom: '0px',
            left: '0px',
            background: '#FFF',
            zIndex: 1000,
            height: '70px',
          }}
        >
          <div className="d-flex align-items-center color-dark-blue size-08" style={{ flexDirection: 'column' }}>
            <button className="btn p-0 m-0" onClick={() => history.push('/')}>
              <Image image={Images.HomeColor} style={{ width: '25px' }} />
            </button>
            Inicio
          </div>

          <div className="d-flex align-items-center color-dark-blue size-08" style={{ flexDirection: 'column' }}>
            <button className="btn p-0 m-0" onClick={() => history.push('/profile/my-history')}>
              <Image image={Images.HistoryColor} style={{ width: '25px' }} />
            </button>
            Historial
          </div>

          <div className="d-flex align-items-center color-dark-blue size-08" style={{ flexDirection: 'column' }}>
            <a className="btn p-0 m-0" target="_blank" rel="noreferrer" href="https://api.whatsapp.com/send?phone=+56934604429&text=Hola!,%20estoy%20en%20copec%20a%20domicilio%20y%20necesito%20ayuda">
              <Image image={Images.SupportColor} style={{ width: '25px' }} />
            </a>
            Soporte
          </div>
        </div>
      )}
    </>
  );
};

export default ActionBottom;
