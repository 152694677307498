/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { v4 } from 'uuid';
import Images from '../../assets/image';
import Image from '../../components/Image';
import Title from '../../components/Title';
import ServiceInfoTemplate from '../../template/ServiceInfoTemplate';

const RenderInfo = ({ state, handleBack, onContinue , open}: any) => {
  const [descripcion, setDescripcion] = useState<any>([]);

  useEffect(() => {
    if(open === false) return;
    const descripcion = Array.isArray(state?.sub_service?.descripcion) ? state?.sub_service?.descripcion : state?.sub_service?.descripcion?.split('<br>');
    setDescripcion(descripcion);
  }, [open]);

  return (
    <>
      <div className="d-sm-none">
        <ServiceInfoTemplate noFooter headerImage={Images.CardLavamax} headerService={state?.sub_service?.name} actionBack={handleBack}>
          <Title size="md" text="Detalle del servicio" className="d-flex w-100 justify-content-center my-4 color-dark-blue bold" />

          <div className="render-info-service-detalle">
            <div className="row align-items-center px-4 pb-3 size-11" key={v4()}>
              <div className="col-12">
                {Array.isArray(descripcion) ? (
                  <>
                    {descripcion.map((item: any) => (
                      <div className="mb-2" key={v4()}>
                        <Image image={Images.BulletInfo} className="me-3" />
                        <span className="size-09">{item}</span>
                      </div>
                    ))}
                  </>
                ) : (
                  <div className="mb-2">
                    <Image image={Images.BulletInfo} className="me-3" />
                    <span className="size-09">{descripcion}</span>
                  </div>
                )}
              </div>
            </div>
          </div>

        </ServiceInfoTemplate>
      </div>

      {/* Version Desktop  */}
      <div className="d-none d-sm-block">
        <ServiceInfoTemplate headerImage={Images.CardLavamax} headerService={state?.sub_service?.name} actionBack={handleBack}>
          <div className="row px-5 mx-5 justify-content-center" style={{minHeight: "500px"}}>
            <div className="col-6 my-5 px-5">
              <div className="row size-13">
                <div className="col-12">
                  <Title size="md" text="Detalle del servicio" className="color-dark-blue mt-5 size-12" />
                </div>

                <div className="col-12">
                  <div className="row color-black my-3">
                    <div className="col-12">
                      {Array.isArray(descripcion) ? (
                        <>
                          {descripcion.map((item: any) => (
                            <div className="mb-2" key={v4()}>
                              <Image image={Images.BulletInfo} className="me-3" />
                              <span className="size-09">{item}</span>
                            </div>
                          ))}
                        </>
                      ) : (
                        <div className="mb-2">
                          <Image image={Images.BulletInfo} className="me-3" />
                          <span className="size-09">{descripcion}</span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ServiceInfoTemplate>
      </div>
    </>
  );
};

export default RenderInfo;
