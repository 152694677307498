import store from '../redux/store';
import instanceAccount from './api_account';
import instanceOvert from './api_overt';

class Terms {
  _setHeaders = () => {
    const state: any = store.getState();
    const { user } = state;
    if (user.isLogged) {
      instanceAccount.defaults.headers.common['user'] = user.profile?.PK;
      instanceAccount.defaults.headers.common['usersk'] = user.profile?.SK;
      instanceAccount.defaults.headers.common['Authorization'] = user?.session?.AuthenticationResult?.AccessToken;
      instanceOvert.defaults.headers.common['user'] = user.profile?.PK;
      instanceOvert.defaults.headers.common['usersk'] = user.profile?.SK;
      instanceOvert.defaults.headers.common['Authorization'] = user?.session?.AuthenticationResult?.AccessToken;
    } else {
      delete instanceAccount.defaults.headers.common['user'];
      delete instanceAccount.defaults.headers.common['usersk'];
      delete instanceAccount.defaults.headers.common['Authorization'];
      delete instanceOvert.defaults.headers.common['user'];
      delete instanceOvert.defaults.headers.common['usersk'];
      delete instanceOvert.defaults.headers.common['Authorization'];
    }
  };

  read = async () => {
    this._setHeaders();
    try {
      const response = await instanceOvert.get('/overt/terms');
      return response;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  readUser = async (email:any) => {
    this._setHeaders();
    try {
      const response = await instanceOvert.get(`/overt/terms?email=${email}`);
      return response;
    } catch (e) {
      return Promise.reject(e);
    }
  };
}

export default new Terms();
