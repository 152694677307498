/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment';
import { useEffect, useState } from 'react';

const TimeItem = (props: any) => {
  const { text, discount, disabled, className, selected, handleOnSelect, data, showBlocks, ...rest } = props;

  const [blockFormat, setBlockFormat] = useState<any>({});
  useEffect(() => {
    if (showBlocks) {
      let endBlock: any = moment(data._moment.toString()).add(data.duration, 'minutes');
      setBlockFormat({ start: data._moment?.format('HH:mm'), end: endBlock.format('HH:mm') });
    }
  }, []);

  const _handleClick = (ev: any) => {
    let hasClass = false;
    [].forEach.call(ev.currentTarget.classList, (el) => {
      if (el === 'time-item-disabled') {
        hasClass = true;
      }
    });

    if (!hasClass) {
      handleOnSelect(data);
    }
  };
  return (
    <div className={`component-time-item ${className} ${disabled ? 'time-item-disabled' : ''} ${selected ? 'time-item-selected' : ''}`} onClick={_handleClick} {...rest}>
      {showBlocks ? (
        <div className="component-time-item__text">
          <span>{blockFormat?.start} - {blockFormat?.end}</span>
        </div>
      ) : (
        <div className="component-time-item__text">{text}</div>
      )}
      {discount > 0 && <div className="component-time-item__discount bold mt-2">{discount}% Dcto</div>}
    </div>
  );
};

export default TimeItem;
