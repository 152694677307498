import { handleActions } from 'redux-actions';
import { SET_EVALUATIONS } from '../actions/evaluations';

export const INITIAL_STATE = {
  payments: [],
};

export default handleActions(
  {
    [SET_EVALUATIONS]: (state: any, action: any) => {
      return { ...state, payments: action.payload };
    },
  },
  INITIAL_STATE
);
