import { useHistory } from "react-router"
import ButtonBack from "../../components/ButtonBack"
import Image from "../../components/Image"
import IHeaderServiceTemplate from "../../interfaces/IHeaderServiceTemplate"

const Header: React.FC<IHeaderServiceTemplate> = (props) => {

  const { data, actionBack } = props
  const history = useHistory()


  return (
    <div className="container-service-template__header">
      <div className="d-block d-sm-none">
        <Image image={data.image} className="container-service-template__header__bg__image" onClick={() => history.push('/')} />
        <div className="container-service-template__header__back ">
          <ButtonBack action={actionBack ? actionBack : () => history.goBack()} text="Volver" />
        </div>
      </div>

      <div className="container-service-template__header__title bg-color-dark-blue">
        {data.service}
      </div>

      <div className="py-4 px-5 d-none d-sm-block">
        <ButtonBack action={actionBack ? actionBack : () => history.goBack()} text="Volver" />
      </div>

    </div>
  )
}

export default Header