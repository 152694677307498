/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Accordion from 'react-bootstrap/Accordion'
import q1 from '../../assets/alima/q1.svg'
import q2 from '../../assets/alima/q2.svg'
import kerosene from '../../assets/alima/kerosene.svg'
import mobil_click from '../../assets/alima/mobil_click.svg'
import carWhite from '../../assets/alima/car-white.svg'
import hover from '../../assets/alima/hover.svg'
import down from '../../assets/alima/down.png'
import arrow from '../../assets/alima/arrow.png'
import faq_kerosene from '../../mockups/faq_kerosene';
import faq_lavamax from '../../mockups/faq_lavamax';
import faq_lub from '../../mockups/faq_lub';
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { LOAD_OFF } from '../../redux/actions/loader'

function FrequentQuestions() {
    const [currentValueRadio, setcurrentValueRadio] = useState(1)
    const history: any = useHistory();
    const dispatch: any = useDispatch();
    const { loader } = useSelector((state: any) => state);
    const services = [
        {
            id: 1,
            label: 'Sobre Reparto de Kerosene',
            selected: true,
            questions: faq_kerosene,
            slug: 'kerosene',
        },
        {
            id: 2,
            label: 'Sobre Lavado de auto',
            selected: false,
            questions: faq_lavamax,
            slug: 'lavado-en-seco',
        },
        {
            id: 3,
            label: 'Sobre Cambio de aceite',
            selected: false,
            questions: faq_lub,
            slug: 'cambio-de-aceite',
        },
    ];

    useEffect(() => {
        const params: any = new URLSearchParams(history?.location?.search);
        const serviceSlug: String = params.get('service');
        const faq: any = services.find((item: any) => item.slug === serviceSlug);
        if (faq) {
            faq.selected = true;
            setcurrentValueRadio(faq.id);
        }
        if (loader.loading) dispatch(LOAD_OFF())
    }, []);
    return (
        <Row className='container-color'>
            <div className='container-fluid mb-5'>
                <Row>
                    <Col className='containerTitle'>
                        <div className='title'>Preguntas Frecuentes</div>
                    </Col>
                </Row>

                <Row style={{ backgroundColor: '#F3F5FA', marginTop: 50 }}>
                    <Col>
                        <Row justify="space-between" >

                            <Col xs={12} sm={12} md={12} lg={4} onClick={() => { setcurrentValueRadio(1) }} style={{ cursor: 'pointer', marginBottom: 5 }}>
                                <div className='cardBtn' style={{ backgroundColor: currentValueRadio === 1 ? '#213291' : '#ffffff' }}>
                                    <Row justify="center">
                                        <Col>
                                            {currentValueRadio === 1 ? <img alt="Sobre Reparto de Kerosene" src={hover} height={40} /> : <img alt="Sobre Reparto de Kerosene" src={kerosene} height={40} />}
                                        </Col>
                                        <Col xs={6} style={{ color: currentValueRadio === 1 ? '#ffffff' : '#213291', fontSize: 17, fontFamily: 'normsMedium' }}>Sobre Reparto de Kerosene</Col>
                                        <Col >
                                            {currentValueRadio === 1 ? <img alt="Sobre Reparto de Kerosene" src={down} height={35} /> : <img alt="Sobre Lavado de auto" src={arrow} height={35} />}
                                        </Col>
                                    </Row>
                                </div>
                            </Col>

                            <Col xs={12} sm={12} md={12} lg={4} onClick={() => { setcurrentValueRadio(2) }} style={{ cursor: 'pointer', marginBottom: 5 }}>
                                <div className='cardBtn' style={{ backgroundColor: currentValueRadio === 2 ? '#213291' : '#ffffff' }}>
                                    <Row justify="center">
                                        <Col>
                                            {currentValueRadio === 2 ? <img alt="Sobre Lavado de auto" src={carWhite} height={40} /> : <img alt="Sobre Lavado de auto" src={q2} height={40} />}
                                        </Col>
                                        <Col xs={6} style={{ color: currentValueRadio === 2 ? '#ffffff' : '#213291', fontSize: 17, fontFamily: 'normsMedium' }}>Sobre Lavado de auto</Col>
                                        <Col>
                                            {currentValueRadio === 2 ? <img alt="Sobre Lavado de auto" src={down} height={35} /> : <img alt="Sobre Lavado de auto" src={arrow} height={35} />}
                                        </Col>
                                    </Row>
                                </div>
                            </Col>

                            <Col xs={12} sm={12} md={12} lg={4} onClick={() => { setcurrentValueRadio(3) }} style={{ cursor: 'pointer', marginBottom: 5 }}>
                                <div className='cardBtn' style={{ backgroundColor: currentValueRadio === 3 ? '#213291' : '#ffffff' }}>
                                    <Row justify="center">
                                        <Col>
                                            {currentValueRadio === 3 ? <img alt="Sobre Cambio de aceite" src={mobil_click} height={40} /> : <img alt="Sobre Reparto de Kerosene" src={q1} height={40} />}
                                        </Col>
                                        <Col xs={6} style={{ color: currentValueRadio === 3 ? '#ffffff' : '#213291', fontSize: 17, fontFamily: 'normsMedium' }}>  Sobre Cambio de aceite</Col>
                                        <Col>
                                            {currentValueRadio === 3 ? <img alt="Sobre Cambio de aceite" src={down} height={35} /> : <img alt="Sobre Lavado de auto" src={arrow} height={35} />}
                                        </Col>
                                    </Row>
                                </div>
                            </Col>

                        </Row>

                        <div style={{ marginTop: 50 }}>
                            <Accordion>
                                {services
                                    .find((item: any) => item.id === currentValueRadio)
                                    ?.questions?.map((question: any, index: string) => (
                                        <Accordion.Item eventKey={index}>
                                            <Accordion.Header>
                                                <div className='titlePanel'>{question.question}</div>
                                            </Accordion.Header>
                                            <Accordion.Body className='pt-0 pb-4 text-black'>
                                                {typeof question.response === 'string' ? (
                                                    <div className="color-black" style={{ fontFamily: 'var(--font-normsLight)' }}>{question.response}</div>
                                                ) : (
                                                    <>
                                                        {question.response.map((r: any) => (
                                                            <div className="color-black" style={{ fontFamily: 'var(--font-normsLight)' }} key={r.text}>
                                                                {r.text}
                                                            </div>
                                                        ))}
                                                    </>
                                                )}
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    ))}
                            </Accordion>
                        </div>
                    </Col>
                </Row>
            </div>
        </Row>
    );
}

export default FrequentQuestions;
