/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Images from '../../assets/image';
import Image from '../../components/Image';
import { CLOSE_CART, toggle_cart } from '../../redux/actions/shopping_cart';
import { menu_home, menu_kerosene, menu_lavamax, menu_lub } from '../../helpers/menus_desktop';
import { useEffect, useState } from 'react';
import Modal from '../../components/Modal';
import Title from '../../components/Title';
import ActionBottomButton from '../../components/ActionBottom/ActionBottomButton';
import Logo from '../../components/Logo';
import { RxHamburgerMenu } from "react-icons/rx"
import MenuHeader from './MenuHeader';

const Header = () => {
  const history = useHistory();
  const { shopping_cart, user } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const [state, setState] = useState({ menu: [], target_admin: "/profile", modal: { open: false }, products_total: 0, shopping_cart_show: true });
  const [menu, setMenu] = useState(false);

  

  useEffect(() => {
    let menu = [];
    state.shopping_cart_show = true;

    switch (history.location.pathname) {
      case '/':
        menu = menu_home;
        break;
      case '/services/kerosene':
        menu = menu_kerosene;
        break;
      case '/services/lavado':
        menu = menu_lavamax;
        break;
      case '/services/cambio-aceite':
        menu = menu_lub;
        break;
      case '/check-out/step-2':
        menu = [];
        state.shopping_cart_show = false;
        break;
      default:
        menu = [];
    }

    let products_total = 0;
    shopping_cart.services.forEach((service: any) => {
      products_total += service.service.quantity || 1;
      if (service.related_products) {
        service.related_products.forEach((rp: any) => {
          products_total += rp.quantity || 1;
        });
      }
    });

    state.products_total = products_total;

    setState({ ...state, menu });
  }, [shopping_cart]);

  useEffect(() => {
    if (user && user.isLogged) {
      if (user.profile?.info?.roles.includes('client') || user.profile?.info?.roles.includes('client_company')) {
        setState({ ...state, target_admin: '/profile' });
      } 

      if (user.profile?.info?.roles.includes('user_company')) {
        setState({ ...state, target_admin: '/empresa/usuarios' });
      }
    }
  }, [user]);

  useEffect(() => {
    return () => {
      setMenu(false)
    }
  }, [])

  const _toogleShoppingCart = () => {
    if (shopping_cart.services.length) {
      dispatch(toggle_cart());
    } else {
      setState({ ...state, modal: { open: !state.modal.open } });
    }
  };

  const _gotoPage = () => {
    dispatch(CLOSE_CART());
    history.push('/');
  };

  return (
    <>
      <Modal open={state.modal.open}>
        <div className="row bg-color-white py-5 rounded-15 modal-400 position-relative">
          <button className="btn rounded-20 px-5 bold py-2 position-absolute text-end top-0" onClick={_toogleShoppingCart}>
            <Image image={Images.CloseBlue} className="mt-2" />
          </button>
          <div className="col-12 text-center px-5">
            <Image image={Images.EmptyCart} className="w-75" />
          </div>
          <div className="col-12 text-center">
            <Title size="xl" text="Carro vacío!" className="color-blue-base" />
          </div>
          <div className="col-12 text-center bold mt-3">Ingresa tu dirección y conoce los servicios a domicilio disponibles en tu zona</div>

          <div className="col-12 text-center mt-4 color-white">
            <button className="btn btn-red rounded-20 px-5 bold py-2" onClick={_toogleShoppingCart}>
              Cerrar
            </button>
          </div>
        </div>
      </Modal>

      <MenuHeader toogleMenu={() => setMenu(!menu)} open={menu} />

      {/* MOBILE HEADER */}

      <header className="container-template-default__header container-fluid d-sm-none d-flex">
        {/* Menu Hamburguer */}
        <div className="" style={{ width: "30%" }}>
          <button className="btn color-blue-base" onClick={() => setMenu(!menu)}>
            <RxHamburgerMenu className='color-dark-blue' style={{ width: "24px", height: "24px" }} />
          </button>
        </div>

        {/* Logo */}
        <div style={{ width: "40%" }}>
          <Logo color style={{ width: "130px", maxWidth: "100%" }} onClick={_gotoPage} />
        </div>

        {/* Icons */}
        <div className="d-flex justify-content-end" style={{ width: "30%" }}>
          {user && user.isLogged ? (
            <button className="btn px-1" onClick={() => history.push(state.target_admin)}>
              <Image image={Images.UserOn} />
            </button>
          ) : (
            <button className="btn px-1" onClick={() => history.push('/sign-in')}>
              <Image className='mb-1' image={Images.UserOn} />
            </button>
          )}

          {state.shopping_cart_show && (
            <div className="container-template-default__header__item position-relative">
              <button className="btn position-relative" type="button" onClick={_toogleShoppingCart}>
                <Image image={Images.Cart} />
                {shopping_cart.services.length > 0 && (
                  <span
                    className="color-white size-08 bold position-absolute d-flex justify-content-center align-items-center"
                    style={{
                      zIndex: 2,
                      backgroundColor: 'red',
                      aspectRatio: state.products_total > 99 ? '2/1' : '1/1',
                      right: state.products_total > 99 ? '-4px' : '6px',
                      top: '-4px',
                      borderRadius: state.products_total > 99 ? '20%' : '50%',
                      width: state.products_total > 99 ? '28px' : '20px',
                      paddingLeft: state.products_total > 99 ? '4px' : '0',
                      paddingRight: state.products_total > 99 ? '4px' : '0',
                    }}
                  >
                    {state.products_total}
                  </span>
                )}
              </button>
            </div>
          )}
        </div>

      </header>

      {/* DESKTOP HEADER */}

      <div className="container-fluid d-sm-block d-none position-fixed bg-color-white" style={{ top: '0px', zIndex: 1000 }} id="header-desktop">
        <div className="row bottom-shadow container-template-default__header-desktop align-items-center px-5">
          <div className="col-3 p-0 d-flex align-items-start">
            <button className="btn p-0 m-0" type="button" onClick={() => history.push('/')}>
              <Logo color style={{ width: '150px' }} />
            </button>
          </div>

          <div className="col-9 d-flex color-dark-blue bold-300 align-items-center justify-content-end" id="navbarNav">
            <a href="/preguntas-frecuentes" className="d-none d-lg-block">
              <span className={`mx-3`}>Preguntas frecuentes</span>
            </a>

            {shopping_cart.services.length > 0 && <ActionBottomButton />}
            {user && user.isLogged ? (
              <button className="btn" onClick={() => history.push(state.target_admin)}>
                <Image image={Images.UserOn} />
              </button>
            ) : (
              <button className="btn-green-light px-3 py-2 me-2 size-09" onClick={() => history.push('/sign-in')}>
                Iniciar sesión
              </button>
            )}

            {state.shopping_cart_show && (
              <button className="btn position-relative" type="button" onClick={_toogleShoppingCart}>
                <Image image={Images.Cart} />
                {shopping_cart.services.length > 0 && (
                  <span
                    className="color-white size-08 bold position-absolute d-flex justify-content-center align-items-center"
                    style={{
                      zIndex: 2,
                      backgroundColor: 'red',
                      aspectRatio: state.products_total > 99 ? '2/1' : '1/1',
                      right: state.products_total > 99 ? '-4px' : '6px',
                      top: '-4px',
                      borderRadius: state.products_total > 99 ? '20%' : '50%',
                      width: state.products_total > 99 ? '28px' : '20px',
                      paddingLeft: state.products_total > 99 ? '4px' : '0',
                      paddingRight: state.products_total > 99 ? '4px' : '0',
                    }}
                  >
                    {state.products_total}
                  </span>
                )}
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
