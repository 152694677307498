import instance from './api_overt';

class CarService {
  getDataByPlate = async (payload: any) => {
    try {
      const response: any = await instance.post('/overt/api/car/patente2', payload);
      response.data = response.data.data;
      return response;
    } catch (e: any) {
      console.log('LOGGER', e.message);
      return Promise.reject(e);
    }
  };

  getCarData = async (payload: any) => {
    try {
      const response: any = await instance.post('/overt/api/car/vehicle', payload);
      return response.data;
    } catch (e: any) {
      console.log('LOGGER', e.message);
      return Promise.reject(e);
    }
  };

  listBrands = async () => {
    try {
      const response: any = await instance.get('/overt/api/car/marcas');
      response.data = response.data.data;
      return response;
    } catch (e: any) {
      console.log('LOGGER', e.message);
      return Promise.reject(e);
    }
  };

  getBranchById = async (id: any) => {
    try {
      const response: any = await instance.get(`/overt/api/car/marcas/${id}`);
      response.data = response.data.data;
      return response;
    } catch (e: any) {
      console.log('LOGGER', e.message);
      return Promise.reject(e);
    }
  };
  getModels = async (id: any) => {
    try {
      const response: any = await instance.get(`/overt/api/car/vehicle/modelo/${id}`);
      response.data = response.data.data;
      return response;
    } catch (e: any) {
      console.log('LOGGER', e.message);
      return Promise.reject(e);
    }
  };

  getModelById = async (id: any) => {
    try {
      const response: any = await instance.get(`/overt/api/car/modelo/${id}`);
      response.data = response.data.data;
      return response;
    } catch (e: any) {
      console.log('LOGGER', e.message);
      return Promise.reject(e);
    }
  };

  listTypesCar = async () => {
    try {
      const response: any = await instance.get(`/overt/api/car/tipo/vehicle`);
      response.data = response.data.data;
      return response;
    } catch (e: any) {
      console.log('LOGGER', e.message);
      return Promise.reject(e);
    }
  };

  getTypeById = async (id: any) => {
    try {
      const response: any = await instance.get(`/overt/api/car/tipo/vehicle/${id}`);
      response.data = response.data.data;
      return response;
    } catch (e: any) {
      console.log('LOGGER', e.message);
      return Promise.reject(e);
    }
  };
}

export default CarService;
