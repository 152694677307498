import { handleActions } from 'redux-actions';

import { SET_ACTIONBAR } from '../actions/action_bar';

export const INITIAL_STATE: any = {
    unselected_services: [],
    show: false,
    modal_open: false,
    total: ''
}

export default handleActions({
    [SET_ACTIONBAR]: (state:any, action: any) => {
    return { ...state, ...action.payload };
}
}, INITIAL_STATE);
