import Images from "../../assets/image"
import Image from "../Image"
import '../../styles/components/component.splash.scss'
import Title from "../Title"
import { useSelector } from "react-redux"

const SplashDirectionDesktop = () => {
  const { splash_screen } = useSelector((state: any) => state)
  return (
    <div className={`component-splash d-flex justify-content-around flex-column ${splash_screen.direction_desktop ? 'show' : ''}`}>
      <Image image={Images.BGStars} className="mt-5" />
      <div className="row position-absolute justify-content-center w-100 h-50 align-items-center mt-lg-5 pt-lg-5 mt-sm-0 pt-sm-0">
        <div className="col-12 mt-lg-5 pt-lg-5 mt-sm-0 pt-sm-0">
          <Title size="xxl" text="¡Estamos buscando los servicios disponibles en tu zona!" className="text-center color-dark-blue size-15" />
        </div>
      </div>
    </div>
  )
}

export default SplashDirectionDesktop