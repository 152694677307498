import './style.scss';

import CloseIcon from '@rsuite/icons/Close';
import { useEffect } from 'react';

const CopecPopUp = ({ open, title, onCancel, onConfirm, children }: any) => {
  useEffect(() => {
    const html = document.querySelector('html');
    if (html) {
      if (open) {
        setTimeout(() => {
          html.style.overflowY = 'hidden';
        }, 400);
      } else {
        setTimeout(() => {
          html.removeAttribute('style');
        }, 400);
      }
    }
  }, [open]);

  if (!open) return null;

  return (
    <div className="sign-popup">
      <div className="sign-popup__popup">
        <div className="sign-popup__popup__wrapper">
          <div className="sign-popup__popup__wrapper__header">
            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={onCancel}>
              <CloseIcon />
            </button>
            <h2>{title}</h2>
            <hr />
          </div>
          <div className="sign-popup__popup__wrapper__body">
            {children}
            <div className="d-flex justify-content-between mt-5">
              <button className="btn-promo me-3" type="button" onClick={onCancel}>
                Cancelar
              </button>
              <button className="btn-promo" type="button" onClick={onConfirm}>
                Confirmar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CopecPopUp;
