import store from '../redux/store';
import instance from './api_account';

class OptionsService {
  _setHeaders = () => {
    const state: any = store.getState();
    const { user } = state;
    if (user.isLogged) {
      instance.defaults.headers.common['user'] = user.profile?.PK;
      instance.defaults.headers.common['usersk'] = user.profile?.SK;
      instance.defaults.headers.common['Authorization'] = user?.session?.AuthenticationResult?.AccessToken;
    } else {
      delete instance.defaults.headers.common['user'];
      delete instance.defaults.headers.common['usersk'];
      delete instance.defaults.headers.common['Authorization'];
    }
  };

  getOrderState = async () => {
    this._setHeaders();
    try {
      const response = await instance.get('/account/client/order/states');
      return response.data;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };
}

const Options = new OptionsService();
export default Options;
