/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { ReactComponent as Cookie } from '../../assets/image/svg/cookie.svg';
import { ReactComponent as Configs } from '../../assets/image/svg/configs.svg';
import { ReactComponent as Close } from '../../assets/image/svg/close_cookies.svg';

import './style.scss';
import { useHistory } from 'react-router-dom';
import { sendDataLayerArguments, sendDataLayerEvent } from '../../helpers/data_layer';
import { useDispatch, useSelector } from 'react-redux';
import { set_gtm_consent } from '../../redux/actions/gtm_consent';

const CookieConsent = () => {
  const [setup, setSetup] = useState({
    show: false,
    ad_storage: true,
    analytics_storage: true,
    personalization_storage: true,
    functionality_storage: true,
    security_storage: true,
  });

  const { gtm_consent } = useSelector((state: any) => state);

  const [modal, setModal] = useState({ show: false });

  const history: any = useHistory();
  const dispatch: any = useDispatch();
  const hidePages: any = ['/politicas'];

  useEffect(() => {
    const { location } = history;
    const isPageHide: any = hidePages.find((item: any) => item === location.pathname);
    if (isPageHide) {
      setModal({ ...setup, show: false });
    } else {
      if (gtm_consent.show) {
        setModal({ ...modal, show: true });
      } else {
        setModal({ ...modal, show: false });
      }
    }
  }, [gtm_consent]);

  const _handleOnChange = (event: any) => {
    const { target } = event;
    const { name, checked } = target;
    setSetup({ ...setup, [name]: checked });
  };

  const _handleAcceptCookies = () => {
    const consent: any = {
      ad_storage: setup.ad_storage ? 'granted' : 'denied',
      analytics_storage: setup.analytics_storage ? 'granted' : 'denied',
      personalization_storage: setup.personalization_storage ? 'granted' : 'denied',
      functionality_storage: setup.personalization_storage ? 'granted' : 'denied',
      security_storage: setup.security_storage ? 'granted' : 'denied',
    };

    sendDataLayerArguments('consent', 'update', consent);

    const dataLayerEvent: any = {
      event: 'privacy_consent_update',
      consent_categories: consent,
      status: 'allow',
    };

    sendDataLayerEvent(dataLayerEvent);
    dispatch(set_gtm_consent({ ...consent, show: false }));
  };

  const _handleDeniedCookies = () => {
    const consent: any = {
      ad_storage: 'denied',
      analytics_storage: 'denied',
      personalization_storage: 'denied',
      functionality_storage: 'denied',
      security_storage: 'denied',
    };

    sendDataLayerArguments('consent', 'update', consent);

    const dataLayerEvent: any = {
      event: 'privacy_consent_update',
      consent_categories: consent,
      status: 'denied',
    };

    sendDataLayerEvent(dataLayerEvent);
    dispatch(set_gtm_consent({ ...consent, show: false }));
  };

  return (
    <div className={`cookie-consent ${modal.show ? '' : 'hide'}`}>
      <div className="cookie-consent__header d-flex mt-2">
        <Cookie className="me-2" /> <h4>Centro de preferencia de la privacidad</h4>
        <button className="btn m-0 p-0" style={{ position: 'absolute', top: '10px', right: '10px' }} onClick={() => setModal({ ...modal, show: false })}>
          <Close style={{ width: '25px' }} />
        </button>
      </div>

      <div className="cookie-consent__msg-wrap">
        <p>
          Utilizamos cookies en nuestro sitios. Algunas son estrictamente necesarias para su funcionamiento, otras son opcionales y su objetivo es: mejorar tu experiencia de navegación, medir cómo
          utilizas el sitio, etc. Al “Aceptar”, autorizas la instalación de cookies opcionales.
          <br />
          <a href="/politicas" target="_blank">
            Politica de privacidad
          </a>
        </p>
      </div>

      <div className="cookie-consent__configs">
        <div className="cookie-consent__configs__btn">
          <button className="btn m-0 p-0" onClick={() => setSetup({ ...setup, show: !setup.show })}>
            <Configs className="me-2" /> <span>Configurar</span>
          </button>
        </div>
      </div>

      <div className={`cookie-consent__options my-2 ${setup.show ? '' : 'hide'}`}>
        <div className="row">
          <div className="col-6 mb-2 d-flex align-items-center">
            <input className="me-2" type="checkbox" name="security_storage" id="security_storage" checked={setup?.security_storage} disabled />
            <label htmlFor="security_storage">Necesarias</label>
          </div>
          <div className="col-6 mb-2 d-flex align-items-center">
            <input className="me-2" type="checkbox" name="personalization_storage" id="personalization_storage" checked={setup?.personalization_storage} onChange={_handleOnChange} />
            <label htmlFor="personalization_storage">Opcionales</label>
          </div>
          <div className="col-6 mb-2 d-flex align-items-center">
            <input className="me-2" type="checkbox" name="analytics_storage" id="analytics_storage" checked={setup?.analytics_storage} onChange={_handleOnChange} />
            <label htmlFor="analytics_storage">Analytcs</label>
          </div>
          <div className="col-6 mb-2 d-flex align-items-center">
            <input className="me-2" type="checkbox" name="ad_storage" id="ad_storage" checked={setup?.ad_storage} onChange={_handleOnChange} />
            <label htmlFor="ad_storage">Marketing</label>
          </div>
        </div>
      </div>

      <div className="cookie-consent__actions d-flex mt-3">
        <button className="btn-ok" onClick={_handleAcceptCookies}>
          Aceptar
        </button>
        <button className="btn-cancel" onClick={_handleDeniedCookies}>
          Rechazar
        </button>
      </div>
    </div>
  );
};

export default CookieConsent;
