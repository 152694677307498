import ITitle from '../../interfaces/ITitle'
const Title: React.FC<ITitle> = (props) => {

  const { size, text, ...rest } = props

  const Element = () => {
    switch (size) {
      case 'sm':
        return <h5 dangerouslySetInnerHTML={{ __html: text }} />
      case 'md':
        return <h4 dangerouslySetInnerHTML={{ __html: text }} />
      case 'lg':
        return <h3 dangerouslySetInnerHTML={{ __html: text }} />
      case 'xl':
        return <h2 dangerouslySetInnerHTML={{ __html: text }} />
      case 'xxl':
        return <h1 dangerouslySetInnerHTML={{ __html: text }} />
      default:
        return <h1 dangerouslySetInnerHTML={{ __html: text }} />
    }
  }

  return (
    <div {...rest}>
      <Element />
    </div>
  )
}

export default Title