import { useEffect, useState } from 'react';
import Footer from '../DefaultTemplate/Footer';
import { useHistory } from 'react-router';
import Header from './Header';
import Images from '../../assets/image';
import '../../styles/template.service_template.scss';

const ServiceInfoTemplate = (props: any) => {
  const { children, noFooter, headerImage, headerService, actionBack } = props;
  const history = useHistory();
  const [state, setState] = useState<any>({
    header: {
      service: '',
      image: null,
    },
  });

  const { location } = history;

  useEffect(() => {
    const { pathname } = location;
    const path = pathname.split('/');
    switch (path[path.length - 1].toLowerCase()) {
      case 'kerosene':
        setState({
          header: {
            service: 'Kerosene',
            image: Images.HeaderInfoKerosene,
          },
        });
        break;
      case 'cambio-aceite':
        setState({
          header: {
            service: 'Cambio de aceite',
            image: Images.HeaderInfoLub,
          },
        });
        break;
      case 'lavado':
        setState({
          header: {
            service: 'Lavado de auto',
            image: Images.HeaderInfoLavamax,
          },
        });
        break;
      default:
    }
  }, [location]);

  const { header } = state;

  return (
    <div className="container-service-template">
      {headerImage ? <Header data={{ service: headerService, image: headerImage }} actionBack={actionBack} /> : header && header.image && <Header data={header} />}

      {children}
      {!noFooter && <Footer />}
    </div>
  );
};

export default ServiceInfoTemplate;
