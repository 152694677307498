/* eslint-disable react-hooks/exhaustive-deps */
import Images from '../../assets/image';
import Image from '../Image';
import { ReactComponent as ArrowIcon } from '../../assets/image/svg/arrow_front.svg';
import './style.scss';
import { useEffect, useState } from 'react';

const CardHomeDesktop = (props: any) => {
  const { image, title, description, action, disabled, btnText, fullpoints, service, handleDisabledMessage } = props;

  const [state, setState] = useState({
    isLub: false,
    hasDisabledMessage: false,
    disabledMessage: "",
    isActive: false,
    name: ""
  })

  useEffect(() => {
    state.isActive = !disabled;
    state.isLub = service.type === 'lub';
    state.name = service.name;
    if (service.eds?.info?.service_messages && handleDisabledMessage) {
      const disabledMessage = service.eds.info.service_messages.find((message: any) => message.service_id === service.PK);
      if (disabledMessage && !disabledMessage.dont_show_message) {
        state.hasDisabledMessage = true;
        state.disabledMessage = disabledMessage.message;
      }
    }
    setState({ ...state })
  }, [service])


  if (state.hasDisabledMessage && !state.isActive) {
    return (
      <div className="card-home-desktop">
        <div className="card-home-desktop__image position-relative" style={{ zIndex: 0 }}>
          <div className="card-home-desktop__disabled" />
          <Image image={image || Images.NotFound} />
        </div>
        <div className="card-home-desktop__content bold-300">
          <div className="d-block-inline color-white">
            <span className='color-white'>El servicio <span className='bold' style={{ textDecoration: 'underline' }}>{service?.name}</span> no está disponible por el momento. Para saber más, haga clic</span>
            <button className='color-green-base ps-1 pe-0 py-0 m-0' style={{ background: "transparent" }} onClick={() => handleDisabledMessage(state)} > aquí</button>.
          </div>
        </div>
      </div>
    )
  }

  if (state.isLub && !state.isActive) {
    return (
      <div className="card-home-desktop">
        <div className="card-home-desktop__image position-relative" style={{ zIndex: 0 }}>
          <div className="card-home-desktop__disabled" />
          <Image image={image || Images.NotFound} />
        </div>
        <div className="card-home-desktop__content bold-300">
          <div className="d-block-inline">
            <span className='color-white'>Lo lamentamos, todavía no hay cobertura en la dirección ingresada para el servicio <span className='bold' style={{ textDecoration: 'underline' }}>{service?.name}</span>. Recuerda que si no hay cobertura, puedes agendar presencial</span>
            <a href={process.env.REACT_APP_PRESENTIAL_LUB} target="_blank" rel="noreferrer" className='color-green-base' > aquí</a>.
          </div>
        </div>
      </div>
    )
  }

  if (disabled) {
    return (
      <div className="card-home-desktop">
        <div className="card-home-desktop__disabled" />
        <div className="card-home-desktop__image">
          <Image image={image || Images.NotFound} />
        </div>
        <div className="card-home-desktop__content">
          {fullpoints ? (
            <div className="color-white size-09 p-0 text-center  mb-1">
              <Image image={Images.CopecFull || Images.NotFound} className="me-2" style={{ width: '75px' }} />
              Acumula puntos Full Copec
            </div>
          ) : null}
          <div className="bold size-14 color-white">{title}</div>
          <div className="color-white size-11" dangerouslySetInnerHTML={{ __html: description }} />
          <button className="p-0 m-0 btn-clear color-green-base size-11 bold mt-2" onClick={action}>
            {btnText || 'Saber más'} <ArrowIcon className="ms-1" style={{ width: '12px' }} />
          </button>
        </div>
      </div>
    )
  }

  return (
    <div className="card-home-desktop">
      <div className="card-home-desktop__image">
        <Image image={image || Images.NotFound} />
      </div>
      <div className="card-home-desktop__content">
        {fullpoints ? (
          <div className="color-white size-09 p-0 text-center  mb-1">
            <Image image={Images.CopecFull || Images.NotFound} className="me-2" style={{ width: '45px' }} />
            Acumula puntos Full Copec
          </div>
        ) : null}
        <div className="bold size-14 color-white">{title}</div>
        <div className="color-white size-11" dangerouslySetInnerHTML={{ __html: description }} />
        <button className="p-0 m-0 btn-clear color-green-base size-11 bold mt-2" onClick={action}>
          {btnText || 'Saber más'} <ArrowIcon className="ms-1" style={{ width: '12px' }} />
        </button>
      </div>
    </div>
  )


};

export default CardHomeDesktop;
