import { handleActions } from 'redux-actions';

import { SET_MESSAGE } from '../actions/broadcast';

export const INITIAL_STATE: any = {}

export default handleActions({
    [SET_MESSAGE]: (state:any, action: any) => {
    return { ...state, ...action.payload };
}
}, INITIAL_STATE);
