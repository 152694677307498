import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import Images from '../../assets/image';
import ButtonBack from '../../components/ButtonBack';
import ErrorValidation from '../../components/ErrorValidation';
import Image from '../../components/Image';
import Title from '../../components/Title';
import { regexCognito, validateRut } from '../../helpers/Utils';
import validate from '../../helpers/validate';
import { set_user } from '../../redux/actions/user';
import sessionService from '../../services/session.service';
import { SignUpSchema } from '../../validations/signup.validations';
import InputMask from 'inputmask';
import Logo from '../../components/Logo';

const Register = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const rutRef: any = useRef(null);
  const phoneRef: any = useRef(null);
  const [state, setState] = useState({
    inputs: {
      email: '',
      password: '',
      password_c: '',
      phone_number: '',
      rut: '',
      name: '',
      family_name: '',
      terms: false
    },
    loading: false,
    validations: {},
  });

  useEffect(() => {
    InputMask({
      mask: '9{1,2}.9{3}.9{3}-(K|k|9)',
      casing: 'upper',
      numericInput: true,
      placeholder: '_',
      showMaskOnFocus: false,
      showMaskOnHover: false,
      jitMasking: true,
    }).mask(rutRef.current);

    InputMask({
      mask: '999999999',
      casing: 'upper',
      placeholder: '_',
      showMaskOnFocus: false,
      showMaskOnHover: false,
      jitMasking: true,
    }).mask(phoneRef.current);
  }, []);

  const _handleOnChange = ({ target }: any) => {
    const { name, value, checked } = target;
    const selectedValue: any = name === 'terms' ? checked : value;
    setState({ ...state, inputs: { ...state.inputs, [name]: selectedValue }, validations: {} });
  };

  const _handleRegister = async () => {
    let result = await validate(SignUpSchema, state.inputs);
    if (!regexCognito(state.inputs.password)) {
      if (typeof result === 'boolean') {
        result = { password: ['La contraseña debe tener al menos 10 caracteres, 1 letra mayúscula, 1 letra minúscula, 1 carácter especial'] };
      } else {
        result.password = ['La contraseña debe tener al menos 10 caracteres, 1 letra mayúscula, 1 letra minúscula, 1 carácter especial'];
      }
    }

    if (state.inputs.password !== state.inputs.password_c) {
      if (typeof result === 'boolean') {
        result = { password: ['Las contraseñas deben ser las mismas'], password_c: ['Las contraseñas deben ser las mismas'] };
      } else {
        result.password = ['Las contraseñas deben ser las mismas'];
        result.password_c = ['Las contraseñas deben ser las mismas'];
      }
    }

    if (!validateRut(state.inputs.rut)) {
      if (typeof result === 'boolean') {
        result = { rut: ['El rut no es válido'] };
      } else {
        result.rut = ['El rut no es válido'];
      }
    }

    if (!state.inputs.terms) {
      if (typeof result === 'boolean') {
        result = { terms: ['Acepta los términos y condiciones antes de continuar'] };
      } else {
        result.terms = ['Acepta los términos y condiciones antes de continuar'];
      }
    }

    if (result) {
      setState({ ...state, validations: result, loading: false });
      return;
    }

    setState({ ...state, validations: {}, loading: true });

    try {
      const params: any = JSON.parse(JSON.stringify(state.inputs));

      if (params.password_c) {
        delete params.password_c;
      }

      params.phone_number = '+56' + params.phone_number;
      const response = await sessionService.register(params);
      dispatch(set_user({ profile: response.data }));
      setState({ ...state, loading: false, validations: {} });
      history.push('/confirmation-code');
    } catch (e: any) {
      setState({ ...state, loading: false, validations: {} });
      if (e?.response?.data?.error && e.response.data.error.indexOf('An account with the given email already exists')) {
        toast.error('Error. Email ya existe.');
      } else {
        toast.error(e.response?.data?.error);
      }
    }
  };

  return (
    <div className="container-fluid h-100 component-signin position-relative">
      <div className="row component-signin__header" style={{ height: '15%' }}>
        <Image image={Images.BGSignIn} className="position-absolute top-0 end-0 p-0 m-0 sign-in-image" />
        <div className="col-12 mt-4" style={{ zIndex: 1000 }}>
          <ButtonBack text="Volver" action={() => history.goBack()} />
        </div>
      </div>
      <div className="d-flex w-100 justify-content-center position-relative" style={{ height: '85%' }}>
        <div className="row w-100 component-signin__fields justify-content-between" style={{ height: '100%', maxWidth: '650px' }}>
          <div className="col-12">
            <div className="row">
              <div className="col-12 text-center">
                <Logo color className=" p-3 w-50" style={{ maxWidth: '200px' }} />
              </div>
            </div>

            <div className="row">
              <div className="col-12 text-center mt-4">
                <Title size="md" text="Regístrate" className="color-dark-blue size-13" />
              </div>
            </div>

            <div className="row px-3 mb-3">
              <div className="p-0 mb-1 color-black bold size-09">Usuario</div>
              <div className="col-12 d-flex bg-color-blue-light border rounded-30" style={{ padding: '10px 20px' }}>
                <Image image={Images.EmailInput} className="me-3" />
                <input type="text" className="input-transparent" placeholder="Ingresa tu email" style={{ flex: '1' }} name="email" value={state.inputs.email} onChange={_handleOnChange} />
              </div>
              <ErrorValidation validations={state.validations} name="email" className="size-09 bold-300" />
            </div>

            <div className="row px-3 mb-3">
              <div className="p-0 mb-1 color-black bold size-09">Nombre</div>
              <div className="col-12 d-flex bg-color-blue-light border rounded-30" style={{ padding: '10px 20px' }}>
                <Image image={Images.UserInput} className="me-3" />
                <input type="text" className="input-transparent" placeholder="Ingresa tu nombre" style={{ flex: '1' }} name="name" value={state.inputs.name} onChange={_handleOnChange} />
              </div>
              <ErrorValidation validations={state.validations} name="name" className="size-09 bold-300" />
            </div>

            <div className="row px-3 mb-3">
              <div className="p-0 mb-1 color-black bold size-09">Apellido</div>
              <div className="col-12 d-flex bg-color-blue-light border rounded-30" style={{ padding: '10px 20px' }}>
                <Image image={Images.UserInput} className="me-3" />
                <input
                  type="text"
                  className="input-transparent"
                  placeholder="Ingresa tu apellido"
                  style={{ flex: '1' }}
                  name="family_name"
                  value={state.inputs.family_name}
                  onChange={_handleOnChange}
                />
              </div>
              <ErrorValidation validations={state.validations} name="family_name" className="size-09 bold-300" />
            </div>

            <div className="row px-3 mb-4">
              <div className="col-12 col-sm-6 ps-0 pe-0 ps-sm-0 pe-sm-2 mb-3">
                <div className="p-0 mb-1 color-black bold size-09">Contraseña</div>
                <div className="col-12 d-flex bg-color-blue-light border rounded-30" style={{ padding: '10px 20px' }}>
                  <Image image={Images.PasswordInput} className="me-3" />
                  <input
                    type="password"
                    className="input-transparent placeholder-lg"
                    placeholder="········"
                    style={{ flex: '1' }}
                    name="password"
                    value={state.inputs.password}
                    onChange={_handleOnChange}
                  />
                </div>
                <ErrorValidation validations={state.validations} name="password" className="size-09 bold-300" />
              </div>
              <div className="col-12 col-sm-6 ps-0 pe-0 pe-sm-0 ps-sm-2 mb-3">
                <div className="p-0 mb-1 color-black bold size-09">Repite contraseña</div>
                <div className="col-12 d-flex bg-color-blue-light border rounded-30" style={{ padding: '10px 20px' }}>
                  <Image image={Images.PasswordInput} className="me-3" />
                  <input
                    type="password"
                    className="input-transparent placeholder-lg"
                    placeholder="········"
                    style={{ flex: '1' }}
                    name="password_c"
                    value={state.inputs.password_c}
                    onChange={_handleOnChange}
                  />
                </div>
                <ErrorValidation validations={state.validations} name="password_c" className="size-09 bold-300" />
              </div>
            </div>

            <div className="row px-3 mb-4">
              <div className="col-12 col-sm-6 ps-0 pe-0 ps-sm-0 mb-3 pe-sm-2">
                <div className="p-0 mb-1 color-black bold size-09">Teléfono</div>
                <div className="col-12 d-flex bg-color-blue-light border rounded-30" style={{ padding: '10px 20px' }}>
                  <Image image={Images.PhoneInput} className="me-3" />
                  <div className="bold me-1">+56</div>
                  <input
                    type="text"
                    className="input-transparent"
                    placeholder="987654321"
                    style={{ flex: '1' }}
                    name="phone_number"
                    value={state.inputs.phone_number}
                    ref={phoneRef}
                    onChange={_handleOnChange}
                  />
                </div>
                <ErrorValidation validations={state.validations} name="phone_number" className="size-09 bold-300" />
              </div>
              <div className="col-12 col-sm-6 ps-0 pe-0 pe-sm-0 mb-3 ps-sm-2">
                <div className="p-0 mb-1 color-black bold size-09">Rut</div>
                <div className="col-12 d-flex bg-color-blue-light border rounded-30" style={{ padding: '10px 20px' }}>
                  <input ref={rutRef} type="text" className="input-transparent" placeholder="Ingresa tu rut sin puntos y con guión" style={{ flex: '1' }} name="rut" value={state.inputs.rut} onChange={_handleOnChange} />
                </div>
                <ErrorValidation validations={state.validations} name="rut" className="size-09 bold-300" />
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-12">
                <input type="checkbox" name="terms" id="terms" className='me-2' checked={state.inputs.terms} onChange={_handleOnChange} />
                <label htmlFor="terms"> Acepto los <a href="/terminos-y-condiciones" target="_blank"><b>Términos y Condiciones</b></a></label>
              </div>
              <ErrorValidation validations={state.validations} name="terms" className="size-09 bold-300" />
            </div>

            <div className="row mb-5">
              <div className="col-12 color-white">
                <button className="btn-default w-100 py-3 size-11" onClick={_handleRegister} disabled={state.loading}>
                  {state.loading && <div className="spinner-border me-1" role="status" style={{ width: '20px', height: '20px' }} />}
                  Registrarse
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
