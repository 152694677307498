import React from 'react'

const containerBody: React.CSSProperties = {
    backgroundColor: '#213291',
    color: '#ffffff',
    borderRadius: 10,
    height: 150,
    textAlign: 'left',
    marginBottom: 10,
    display: 'flex',
    flexDirection: 'row'
}

const title: React.CSSProperties = {
    fontSize: 18,
    fontFamily: 'var(--font-normsBold)',
    float: 'left'
}

const btn: React.CSSProperties = {
    color: '#00FF7D',
    fontFamily: 'var(--font-normsBold)',
    cursor: 'pointer'
}

function CardServiceMobileApp(props: any) {

    return (
        <div>
            <div style={containerBody}>
                <div style={{ height: 150}}>
                    <img alt={props.title} src={props.img} style={{ height: '100%', borderTopLeftRadius: 10, borderBottomLeftRadius: 10 }} />
                </div>
                <div style={{ height: 150, marginLeft:30, marginTop:40 }}>
                    <div style={title}>{props.title}</div>
                    <div style={btn} onClick={props.click}>{props.textLink}</div>
                </div>
            </div>
        </div>
    );
}



export default CardServiceMobileApp;