/* eslint-disable react-hooks/exhaustive-deps */
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import point from '../../assets/alima/point.svg'
import camion from '../../assets/alima/top-car.png'
import MapsDirections from '../MapsDirections'
import WelcomePopUp from '../WelcomePopUp'
import DrawerDirections from '../DrawerDirections'

function Top({ state, _handleDirections, _handleToogleDirections, _toogleDrawer, _closeAndOpen, _handleFirstDrawer }: any) {
    return (
        <Row className='topStyle'>
            {/* Welcome POP UP */}
            <WelcomePopUp handleModalDirections={_handleDirections} />

            {/* Directions Modal Desktop */}
            <MapsDirections open={state.modal.directions.show} handleClose={_handleToogleDirections} />

            {/* Directions Modal Mobile */}
            <DrawerDirections state={state} _toogleDrawer={_toogleDrawer} _closeAndOpen={_closeAndOpen} google={state.google} autocompleteService={state.autocompleteService} addresses={state.addresses} />

            <Col xs={12} sm={6} md={6} >
                <div className='titleStyle'>Kerosene, Lavado de auto y Cambio de aceite a domicilio</div>
                <div className='subTitleStyle'>Ahora tus servicios Copec en la puerta de tu casa</div>
                <div className='btnRight'>

                    <Button className='btnApp d-none d-sm-inline-block' style={{ width: '100%' }} onClick={_handleToogleDirections}>
                        <img src={point} alt="Point" height={25} style={{ float: 'left' }} />
                        Ingresa tu dirección
                    </Button>

                    <Button className='btnApp d-inline-block d-sm-none' style={{ width: '100%' }} onClick={_handleFirstDrawer}>
                        <img src={point} alt="Point" height={25} style={{ float: 'left' }} />
                        Ingresa tu dirección
                    </Button>
                </div>
                <div className='text'>*Servicios disponibles según área de cobertura. Ingresa tu dirección para saber qué servicios tenemos disponibles en tu zona.</div>
            </Col>
            <Col xs={12} sm={6} md={6} style={{ marginTop: 20 }}>
                <img src={camion} width="700" alt="Camion" className='img-fluid-car' />
            </Col>
        </Row>
    );
}

export default Top;
