/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import ConfirmationModal from '../../components/ConfirmationModal';
import Reschedule from '../../components/Reschedule';
import Text from '../../components/Text';
import Title from '../../components/Title';
import { compare } from '../../helpers/orders';
import { formatRut } from '../../helpers/Utils';
import { LOAD_OFF, LOAD_ON } from '../../redux/actions/loader';
import Options from '../../services/options.service';
import Order from '../../services/order.service';
import paymentService from '../../services/payment.service';
import DesktopTemplate from '../Profile/DesktopTemplate';
import ItemsComplement from './ItemsComplement';
import ItemsOrder from './ItemsOrder';
import Logo from '../../components/Logo';

const OrderDetails = (props: any) => {
  const [state, setState] = useState<any>({
    data: {},
    cancel: { open: false },
    schedule: { open: false, step: 1, day: moment().toDate() },
    status: [],
    cancelButton: false,
    total: 0,
    original_amount: 0,
    diff: 0,
    orders: [],
    is_custom_order: false,
    items_order: [],
    payment: {},
    complementOrders: {},
  });
  const params: any = useParams();
  const dispatch = useDispatch();
  const { matchMedia, loader } = useSelector((state: any) => state);

  useEffect(() => {
    _getOrderDetail();
  }, []);

  const _getOrderDetail = async () => {
    dispatch(LOAD_ON());
    try {
      const response = await Order.detail(params.id);
      const [payment_id] = response.data.PK?.split('-');
      const [, order_status] = response.data.GS1PK?.split('-');
      const payment: any = await paymentService.getPayment(payment_id);
      const status = await Options.getOrderState();
      const data = response.data;
      data.parent_service_id = response.data.GS4PK;
      data.eds_id = response.data.GS3PK;
      data.status = order_status?.toLowerCase();
      const array = data.info?.direction?._string?.split(',');
      if (array.length === 1) {
        data.info.direction.address_one = array[0];
        data.info.direction.address_two = '';
      }

      if (array.length > 1) {
        data.info.direction.address_one = array[0];
        array.splice(0, 1);
        data.info.direction.address_two = array.join(' ');
      }

      data.delivery_date = data.info?.reservation ? moment(data.info.reservation?.schedule_at).format('DD/MM/YYYY HH:mm') : moment(data.created_at).add(1, 'day').format('DD/MM/YYYY');
      data._moment = data.info?.reservation ? moment(data.info.reservation?.schedule_at) : moment(data.created_at).add(1, 'day');

      const now = moment();
      let cancelButton = false;
      if (!now.isBefore(data._moment.add(1, 'hour'))) {
        cancelButton = true;
      }

      const { items, complement } = data;
      let items_order: any = [];
      let total: any = 0;
      let is_custom_order: any = false;
      let original_amount: any = 0;
      let diff: any = 0;
      let amount_discount: any = 0;
      let responseCompare: any = {};

      if (complement?.length > 0) {
        const complementedOrder: any = complement[0];
        const itemsNewOrder: any = complementedOrder.info?.items;

        responseCompare = compare(items, itemsNewOrder);

        is_custom_order = true;
        diff = complementedOrder.amount_diff;
      } else {
        items.forEach((item: any) => {
          const name: any = item.info?.name?.toLowerCase() === 'envío' ? 'Despacho' : item.info?.name;
          items_order.push({
            name: name,
            price: item.info?.unit_price,
            quantity_to_buy: item.info?.quantity_to_buy,
            canceled: false,
            item_id: item.PK,
            new_item_id: item.PK,
            amount_discount: item.info?.amount_discount || 0,
          });
        });
      }

      if (is_custom_order) {
        total = responseCompare.total;
        amount_discount = responseCompare.amount_discount;
      } else {
        total = items_order.reduce((acc: any, current: any) => {
          if (!current.canceled) {
            acc += current.price * current.quantity_to_buy;
          }
          return acc;
        }, 0);

        amount_discount = items_order.reduce((acc: any, current: any) => {
          if (!current.canceled) {
            acc += current.amount_discount * current.quantity_to_buy;
          }
          return acc;
        }, 0);
      }

      items_order.sort((a: any, b: any) => {
        if (a.name?.toLowerCase() === 'despacho') return 1;
        if (a.name?.toLowerCase() !== 'despacho') return -1;
        return 0;
      });

      items_order.sort((a: any, b: any) => {
        if (a.canceled > b.canceled) return 1;
        if (a.canceled < b.canceled) return -1;
        return 0;
      });



      setState({
        ...state,
        data: data,
        status: status.data,
        cancelButton,
        schedule: { open: false, step: 1, day: moment().toDate() },
        total,
        items_order: items_order,
        is_custom_order,
        payment: payment.data,
        diff,
        original_amount,
        amount_discount,
        complementOrders: responseCompare,
      });
    } catch (e: any) {
      console.log('LOGGER', e);
      toast.error('No fue posible cargar su historial');
    }
    dispatch(LOAD_OFF());
  };

  const _toggleCancelModal = () => setState({ ...state, cancel: { open: !state.cancel.open } });

  const _handleCancelOrder = async () => {
    dispatch(LOAD_ON());
    try {
      await Order.changeStatus({
        order_id: state.data.PK,
        PK: state.data.PK,
        SK: state.data.SK,
        status: 'canceled',
      });
      toast.success(`Orden #${params.id} anulada con éxito`);
      state.data.status = 'canceled';
      setState({ ...state, cancel: { open: false } });
    } catch (e: any) {
      console.log('LOGGER', e);
      toast.error('No fue posible anular esta orden');
    }
    dispatch(LOAD_OFF());
  };

  const _toggleScheduleModal = () =>
    setState({
      ...state,
      schedule: { ...state.schedule, open: !state.schedule.open, step: 1 },
    });

  return (
    <>
      <ConfirmationModal open={state.cancel.open} type="danger" title="Atención" handleClose={_toggleCancelModal} handleConfirm={_handleCancelOrder} size={matchMedia.media === 'xs' ? 'auto' : 'md'}>
        <span>¿Seguro que desea anular esta orden?</span>
      </ConfirmationModal>

      <ConfirmationModal open={state.schedule.open} type="schedule" title="Reagendar" handleClose={_toggleScheduleModal} hideFooter size={matchMedia.media === 'xs' ? 'auto' : 'md'}>
        <Reschedule order={state.data} handleSuccess={_getOrderDetail} />
      </ConfirmationModal>

      {/* Mobile Version */}
      <div className="container-fluid d-sm-none mt-5 px-4">
        <Title text="Detalles" size="md" className="color-dark-blue text-center bold mb-3" />
        <div className="row">
          <Logo color style={{ width: '150px' }} />
        </div>

        <div className="row mt-2">
          <div className="col-12 bold-300 color-black mt-2">Mi dirección</div>
          <div className="col-12 bold-300 color-grey ps-4">
            <Text loading={loader.loading} value={`${state.data.info?.direction?.address_one}`} />
          </div>
          <div className="col-12 size-09 mb-2 ps-4">
            <Text loading={loader.loading} value={state.data.info?.direction?.address_two} />
          </div>

          {state.data?.info?.direction?.apartment && (
            <>
              <div className="col-12 bold-300 color-black">Casa/Departamento</div>
              <div className="col-12 bold-300 color-grey mb-2 size-09 ps-4">
                <Text loading={loader.loading} value={`${state.data?.info?.direction?.apartment}`} />
              </div>
            </>
          )}

          {state.data?.info?.direction?.observations && (
            <>
              <div className="col-12 bold-300 color-black ">Observaciones</div>
              <div className="col-12 bold-300 color-grey size-09 ps-4 mb-2">
                <Text loading={loader.loading} value={`${state.data?.info?.direction?.observations}`} />
              </div>
            </>
          )}

          <div className="col-12 bold-300 color-black">Fecha creación</div>
          <div className="col-12 size-09 color-grey ps-4 mb-2">
            <Text loading={loader.loading} value={moment(state.data?.created_at).format('DD/MM/YYYY HH:mm')} />
          </div>
        </div>

        <div className="row mt-3">
          <div className="bg-input px-3 py-2 col-12 bold color-black">Información de compra</div>
        </div>

        <div className="row mt-3 bold-300 size-09">
          <div className="col-4">Orden No</div>
          <div className="col-8">
            {' '}
            <Text loading={loader.loading} value={`: ${state.data?.PK}`} />
          </div>
        </div>
        <div className="row mt-1 bold-300 size-09 align-items-center">
          <div className="col-4">Estado</div>
          <div className="col-8">
            <Text loading={loader.loading} value={`: ${state.status.find((s: any) => s.value?.toLowerCase() === state.data.status)?.label}`} />
          </div>
        </div>
        <div className="row mt-1 bold-300 size-09">
          <div className="col-4">Nombre</div>
          <div className="col-8">
            <Text loading={loader.loading} value={`: ${state.data?.user?.name}`} />
          </div>
        </div>
        <div className="row mt-1 bold-300 size-09">
          <div className="col-4">Rut</div>
          <div className="col-8">
            <Text loading={loader.loading} value={`: ${state.data?.user?.rut ? formatRut(state.data?.user?.rut) : ''}`} />
          </div>
        </div>
        <div className="row mt-1 bold-300 size-09">
          <div className="col-4">Email</div>
          <div className="col-8">
            <Text loading={loader.loading} value={`: ${state.data?.user?.email}`} />
          </div>
        </div>
        {state.payment && state.payment.payment?.tbk_detail && (
          <>
            <div className="row mt-1 bold-300 size-09">
              <div className="col-4">Tarjeta</div>
              <div className="col-8">
                <Text loading={loader.loading} value={`: **** **** **** ${state.payment?.payment?.tbk_detail?.card_detail?.card_number}`} />
              </div>
            </div>
            <div className="row mt-1 bold-300 size-09">
              <div className="col-4">Código de Autorización</div>
              <div className="col-8 d-flex align-items-center">
                <Text loading={loader.loading} value={`: ${state.payment?.payment?.tbk_detail?.details[0]?.authorization_code}`} />
              </div>
            </div>
            <div className="row mt-1 bold-300 size-09">
              <div className="col-4">Cuotas</div>
              <div className="col-8">
                <Text loading={loader.loading} value={`: ${state.payment?.payment?.tbk_detail?.details[0]?.installments_number || 1}`} />
              </div>
            </div>
          </>
        )}

        <div className="row mt-1 bold-300 size-09 align-items-center">
          <div className="col-4">Fecha de entrega</div>
          <div className="col-8">
            <Text loading={loader.loading} value={`: ${state.data?.delivery_date}`} />
          </div>
        </div>

        {state.data?.order_accumulated_points ? (
          <div className="row mt-1 bold-300 size-09 align-items-center">
            <div className="col-4">Puntos Full</div>
            <div className="col-8">
              <Text loading={loader.loading} value={`: ${state.data?.order_accumulated_points || 0}`} />
            </div>
          </div>
        ) : null}


        <div className="row mt-1 bold-300 size-09">
          <div className="col-4">Total</div>
          <div className="col-8 bold color-blue-base size-12">
            <Text loading={loader.loading} value={`: $ ${Number(state.total)?.toLocaleString('pt-BR')}`} />
          </div>
        </div>

        <div className="row mt-3">
          <div className="bg-input px-3 py-2 col-12 bold color-black">Detalle de la compra</div>
        </div>

        <div className="row mt-3">{state.is_custom_order ? <ItemsComplement data={state.complementOrders} /> : <ItemsOrder data={state.items_order} />}</div>

        {loader.loading && (
          <>
            {Array.from({ length: 4 }).map(() => (
              <div className="row border-bottom my-2 rounded p-2 mx-2 align-items-center">
                <div className="col-12 bold-300">
                  <Text loading={loader.loading} value="" />
                </div>
                <div className="col-2 size-09">
                  <Text loading={loader.loading} value="" />
                </div>
                <div className="col-5 bold-300 size-09">
                  <Text loading={loader.loading} value="" />
                </div>
                <div className="col-5 bold color-dark-blue size-11">
                  <Text loading={loader.loading} value="" />
                </div>
              </div>
            ))}
          </>
        )}

        <div className="row mt-5">
          <div className="col-6 color-white">
            <button className="btn btn-danger w-100 p-2 bold" onClick={_toggleCancelModal} disabled={state.cancelButton || state.data.status !== 'programmed'}>
              Anular
            </button>
          </div>
          <div className="col-6 color-white">
            <button className="btn btn-primary w-100 p-2 bold" onClick={_toggleScheduleModal} disabled={state.data?.status !== 'programmed' && state.data?.status !== 'rescheduled'}>
              Reagendar
            </button>
          </div>
        </div>
      </div>

      {/* Desktop Version */}
      <div className="container-fluid d-none d-md-block">
        <DesktopTemplate>
          <div className="row border-bottom mx-4 px-0 py-4 mb-2">
            <div className="col-6 p-0">
              <span className="bold size-12 color-black">Detalle de compra</span>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-3 d-flex align-items-center justify-content-center">
              <Logo color style={{ width: '150px' }} />
            </div>
            <div className="col-9">
              <div className="row mt-2 size-09">
                <div className="col-12 bold-300 color-black">Mi dirección</div>
                <div className="col-12 bold-300 color-grey ps-4">
                  <Text loading={loader.loading} value={`${state.data.info?.direction?.address_one}`} />
                </div>
                <div className="col-12 size-09 ps-4">
                  <Text loading={loader.loading} value={state.data.info?.direction?.address_two} />
                </div>
                {state.data?.info?.direction?.apartment && (
                  <>
                    <div className="col-12 bold-300 color-black">Casa/Departamento</div>
                    <div className="col-12 color-grey mb-2 size-09 ps-4">
                      <Text loading={loader.loading} value={`${state.data?.info?.direction?.apartment}`} />
                    </div>
                  </>
                )}

                {state.data?.info?.direction?.observations && (
                  <>
                    <div className="col-12 bold-300 color-black ">Observaciones</div>
                    <div className="col-12 color-grey size-09 ps-4 mb-2">
                      <Text loading={loader.loading} value={`${state.data?.info?.direction?.observations}`} />
                    </div>
                  </>
                )}
                <div className="col-12 bold-300 color-black">Fecha creación</div>
                <div className="col-12 size-09 ps-4">
                  <Text loading={loader.loading} value={moment(state.data?.created_at).format('DD/MM/YYYY HH:mm')} />
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-5">
            <div className="col-6 px-0">
              <div className="row mx-2">
                <div className="px-3 py-2 col-12 bold color-dark-blue border-bottom size-11">Detalle de la compra</div>
              </div>

              <div className="row p-4">{state.is_custom_order ? <ItemsComplement data={state.complementOrders} /> : <ItemsOrder data={state.items_order} />}</div>

              {loader.loading && (
                <>
                  {Array.from({ length: 4 }).map(() => (
                    <div className="row border-bottom my-2 rounded p-2 mx-2 align-items-center">
                      <div className="col-12 bold-300">
                        <Text loading={loader.loading} value="" />
                      </div>
                      <div className="col-2 size-09">
                        <Text loading={loader.loading} value="" />
                      </div>
                      <div className="col-5 bold-300 size-09">
                        <Text loading={loader.loading} value="" />
                      </div>
                      <div className="col-5 bold color-dark-blue size-11">
                        <Text loading={loader.loading} value="" />
                      </div>
                    </div>
                  ))}
                </>
              )}
            </div>

            <div className="col-6 px-5">
              <div className="row">
                <div className="px-3 py-2 col-12 bold color-dark-blue border-bottom size-11">Información de compra</div>
              </div>

              <div className="row mt-3 bold-300 size-09">
                <div className="col-5">Order No</div>
                <div className="col-7">
                  {' '}
                  <Text loading={loader.loading} value={`: ${state.data?.PK}`} />
                </div>
              </div>
              <div className="row mt-1 bold-300 size-09 align-items-center">
                <div className="col-5">Estado</div>
                <div className="col-7">
                  <Text loading={loader.loading} value={`: ${state.status.find((s: any) => s.value.toLowerCase() === state.data.status)?.label}`} />
                </div>
              </div>
              <div className="row mt-1 bold-300 size-09">
                <div className="col-5">Nombre</div>
                <div className="col-7">
                  <Text loading={loader.loading} value={`: ${state.data?.user?.name}`} />
                </div>
              </div>
              <div className="row mt-1 bold-300 size-09">
                <div className="col-5">Rut</div>
                <div className="col-7">
                  <Text loading={loader.loading} value={`: ${state.data?.user?.rut ? formatRut(state.data?.user?.rut) : ''}`} />
                </div>
              </div>
              <div className="row mt-1 bold-300 size-09">
                <div className="col-5">Email</div>
                <div className="col-7">
                  <Text loading={loader.loading} value={`: ${state.data?.user?.email}`} />
                </div>
              </div>

              {state.payment && state.payment.payment?.tbk_detail && (
                <>
                  <div className="row mt-1 bold-300 size-09">
                    <div className="col-5">Tarjeta</div>
                    <div className="col-7">
                      <Text loading={loader.loading} value={`: **** **** **** ${state.payment?.payment?.tbk_detail?.card_detail?.card_number}`} />
                    </div>
                  </div>
                  <div className="row mt-1 bold-300 size-09">
                    <div className="col-5">Código de Autorización</div>
                    <div className="col-7 d-flex align-items-center">
                      <Text loading={loader.loading} value={`: ${state.payment?.payment?.tbk_detail?.details[0]?.authorization_code}`} />
                    </div>
                  </div>
                  <div className="row mt-1 bold-300 size-09">
                    <div className="col-5">Cuotas</div>
                    <div className="col-7">
                      <Text loading={loader.loading} value={`: ${state.payment?.payment?.tbk_detail?.details[0]?.installments_number || 1}`} />
                    </div>
                  </div>
                </>
              )}
              <div className="row mt-1 bold-300 size-09 align-items-center">
                <div className="col-5">Fecha de entrega</div>
                <div className="col-7">
                  <Text loading={loader.loading} value={`: ${state.data?.delivery_date}`} />
                </div>
              </div>

              {state.data?.order_accumulated_points ? (
                <div className="row mt-1 bold-300 size-09 align-items-center">
                  <div className="col-5">Puntos Full</div>
                  <div className="col-7">
                    <Text loading={loader.loading} value={`: ${state.data?.order_accumulated_points || 0}`} />
                  </div>
                </div>
              ) : null}

              <div className="row mt-1 bold-300 size-09">
                <div className="col-5">Total</div>
                <div className="col-7 bold color-blue-base size-12">
                  <Text loading={loader.loading} value={`: $ ${Number(state.total)?.toLocaleString('pt-BR')}`} />
                </div>
              </div>
              <div className="row mt-5 justify-content-end">
                <div className="col-6 color-white">
                  <button className="btn btn-danger w-100 p-2 bold" onClick={_toggleCancelModal} disabled={state.cancelButton || (state.data.status !== 'programmed' && state.data.status !== 'new')}>
                    Anular
                  </button>
                </div>
                {state.data?.reservation && (
                  <div className="col-6 color-white">
                    <button
                      className="btn btn-primary w-100 p-2 bold"
                      onClick={_toggleScheduleModal}
                      disabled={state.data?.status !== 'new' && state.data?.status !== 'programmed' && state.data?.status !== 'rescheduled'}
                    >
                      {' '}
                      Reagendar
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </DesktopTemplate>
      </div>
    </>
  );
};
export default OrderDetails;
