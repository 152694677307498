/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Images from '../../assets/image';
import { update_service_to_cart } from '../../redux/actions/shopping_cart';
import ButtonBack from '../ButtonBack';
import CardRelatedProducts from '../CardRelatedProducts';
import Image from '../Image';
import ProductsCarousel from '../ProductsCarousel';
import ProductCardSchedule from '../ProductsCarousel/ProductCardSchedule';
import { addToCartDataLayerEvent } from '../../helpers/data_layer';
import { edit_service } from '../../redux/actions/services';

const RecommendedService = (props: any) => {
  const { handleClose, service, show, setShowRecommendedButton } = props;
  const params: any = useParams();
  const { available_services, recommended_subservices, shopping_cart } = useSelector((state: any) => state);

  const [data, setData] = useState<any>({ related_products: [], recommended_subservices: [] });

  const [recommendedSubserviceList, setRecommendedSubserviceList] = useState<any>([]);
  const [recommendedSubserviceListMobile, setRecommendedSubserviceListMobile] = useState<any>([]);

  const [nextPrevButtons, setNextPrevButtons] = useState(false);
  const [rssNextPrevButtons, setRssNextPrevButtons] = useState(false);
  const [nextMobilePrevButtons, setNextMobilePrevButtons] = useState(false);
  const [rssMobileNextPrevButtons, setRssMobileNextPrevButtons] = useState(false);

  const [activeIndexSubService, setActiveIndexSubService] = useState<any>(0);
  const [activeIndexProducts, setActiveIndexProducts] = useState<any>(0);
  const [activeIndexSubServiceMobile, setActiveIndexSubServiceMobile] = useState<any>(0);
  const [activeIndexProductsMobile, setActiveIndexProductsMobile] = useState<any>(0);

  const CAROUSEL_CONFIGS: any = {
    disableButtonsControls: true,
    infinite: false,
    autoPlay: false,
    paddingRight: 0,
    paddingLeft: 0,
    responsive: {
      0: {
        items: 1,
      },
      755: {
        items: 2,
      },
      1023: {
        items: 3,
      },
      1365: {
        items: 4,
      },
    },
  };

  const CAROUSEL_CONFIGS_MOBILE: any = {
    disableButtonsControls: true,
    infinite: false,
    autoPlay: false,
    paddingRight: 5,
    paddingLeft: 5,
    responsive: {
      0: {
        items: 1,
      },
      300: {
        items: 2,
      },
      755: {
        items: 3,
      },
      1023: {
        items: 3,
      },
      1365: {
        items: 4,
      },
    },
  };

  const rpRef: any = useRef();
  const rssRef: any = useRef();
  const rpMobileRef: any = useRef();
  const rssMobileRef: any = useRef();
  const dispatch: any = useDispatch();

  useEffect(() => {
    const page_service: any = available_services.find((as: any) => as.info?.slug === params.slug);
    const recommended_subservices_list: any = [];
    const recommended_subservices_list_mobile: any = [];
    service?.info?.recommended_subservices?.forEach((item: any) => {
      const { services } = recommended_subservices;
      const rss: any = services?.sub_services?.find((ss: any) => ss.SK === item && !ss.info?.is_unvailable);

      if (rss) {
        recommended_subservices_list.push(
          <CardRelatedProducts
            imageFull
            price={rss.price}
            image={rss.info?.gallery?.url ? { default: { alt: '', src: rss.info?.gallery?.url } } : Images.NotFound}
            text={rss.name}
            value="Ver detalle"
            product={rss}
            data={page_service}
            handleAction={() => _addRelatedProduct({ ...rss, oneItem: true })}
            recommended={rss.info?.recommended || false}
          />
        );

        recommended_subservices_list_mobile.push(
          <ProductCardSchedule
            title={rss.name}
            value={rss.price}
            image={rss.info?.gallery?.url}
            id={rss.PK}
            onClick={() => _addRelatedProduct({ ...rss, oneItem: true })}
            onPlusClick={null}
            onMinusClick={null}
            product={rss}
            data={page_service}
            recommended={rss.info?.recommended || false}
          />
        );
      }
    });

    setRecommendedSubserviceList(recommended_subservices_list);
    setRecommendedSubserviceListMobile(recommended_subservices_list_mobile);

    if (recommended_subservices_list.length > 0) {
      setShowRecommendedButton(true);
    } else {
      setShowRecommendedButton(false);
    }
    let relatedProducts: any = page_service?.related_products?.filter((item: any) => item.active) || [];

    const products = relatedProducts.map((item: any) => {
      return (
        <CardRelatedProducts
          price={item.price}
          image={item.info?.gallery?.url ? { default: { alt: '', src: item.info?.gallery?.url } } : Images.NotFound}
          text={item.name}
          value="Ver detalle"
          product={item}
          data={page_service}
          handleAction={() => _addRelatedProduct(item)}
          recommended={item.info?.recommended || false}
        />
      );
    });

    const products_mobile = relatedProducts.map((item: any) => {
      return (
        <ProductCardSchedule
          title={item.name}
          value={item.price}
          image={item.info?.gallery?.url}
          id={item.PK}
          onClick={() => _addRelatedProduct(item)}
          onPlusClick={null}
          onMinusClick={null}
          product={item}
          data={page_service}
          recommended={item.info?.recommended || false}
        />
      );
    });

    data.related_products = products;
    data.related_products_mobile = products_mobile;

    setData({ ...data });

    if (rssRef.current && rssRef.current.state && rssRef.current.state.itemsCount <= rssRef.current.state.itemsInSlide) {
      setRssNextPrevButtons(false);
    } else {
      setRssNextPrevButtons(true);
    }

    if (rpRef.current && rpRef.current.state && rpRef.current.state.itemsCount <= rpRef.current.state.itemsInSlide) {
      setNextPrevButtons(false);
    } else {
      setNextPrevButtons(true);
    }

    if (rssMobileRef.current && rssMobileRef.current.state && rssMobileRef.current.state.itemsCount <= rssMobileRef.current.state.itemsInSlide) {
      setRssMobileNextPrevButtons(false);
    } else {
      setRssMobileNextPrevButtons(true);
    }

    if (rpMobileRef.current && rpMobileRef.current.state && rpMobileRef.current.state.itemsCount <= rpMobileRef.current.state.itemsInSlide) {
      setNextMobilePrevButtons(false);
    } else {
      setNextMobilePrevButtons(true);
    }
  }, [service]);

  useEffect(() => {
    setTimeout(() => {
      const root: any = document.querySelector('html');
      if (!shopping_cart.open) {
        if (show) {
          root.style.overflowY = 'hidden';
        } else {
          root?.removeAttribute('style');
        }
      }
    }, 1);
  }, [shopping_cart.open, show]);

  const _addRelatedProduct = (item: any) => {
    const urlParams = new URLSearchParams(window.location.search);
    const service = shopping_cart.services.find((service: any) => service.id === urlParams.get("id"));
    if (!service) return

    item.quantity = 1;
    if (service.related_products) service.related_products.push(item);
    if (!service.related_products) service.related_products = [item];

    addToCartDataLayerEvent(item, params.slug, 'related_product', 'show_available_items');

    dispatch(update_service_to_cart({ ...service }));
    dispatch(edit_service({ ...service }));
  };

  const _nextSlide = (target: any = 'rpRef') => {
    switch (target) {
      case 'rssRef':
        if (rssRef?.current?.state?.itemsCount - rssRef.current.state.itemsInSlide > rssRef?.current?.state?.activeIndex) {
          rssRef.current.slideNext();
        }
        break;
      case 'rpMobileRef':
        if (rpMobileRef?.current?.state?.itemsCount - rpMobileRef.current.state.itemsInSlide > rpMobileRef?.current?.state?.activeIndex) {
          rpMobileRef.current.slideNext();
        }
        break;
      case 'rssMobileRef':
        if (rssMobileRef?.current?.state?.itemsCount - rssMobileRef.current.state.itemsInSlide > rssMobileRef?.current?.state?.activeIndex) {
          rssMobileRef.current.slideNext();
        }
        break;
      default:
        if (rpRef?.current?.state?.itemsCount - rpRef.current.state.itemsInSlide > rpRef?.current?.state?.activeIndex) {
          rpRef.current.slideNext();
        }
        break;
    }
  };

  const _prevSlide = (target: any = 'rpRef') => {
    switch (target) {
      case 'rssRef':
        rssRef.current.slidePrev();
        break;
      case 'rpMobileRef':
        rpMobileRef.current.slidePrev();
        break;
      case 'rssMobileRef':
        rssMobileRef.current.slidePrev();
        break;
      default:
        rpRef.current.slidePrev();
        break;
    }
  };

  return (
    <div className="container-fluid p-0 m-0">
      <div className="row">
        <div className="col-12 text-end px-2 py-3">
          <ButtonBack action={handleClose} text="Volver" />
        </div>
      </div>

      {/* Desktop Version */}

      <div className="row bg-color-blue-light p-0 m-0 d-none d-sm-block">
        <div className="col-12 py-3 px-3 color-black bold-300 d-flex align-items-center justify-content-between">
          Otros servicios
          {rssNextPrevButtons && (
            <div className="">
              <button onClick={() => _prevSlide('rssRef')} className="btn color-blue-base bold">
                <Image image={Images.ArrowDown} style={{ transform: 'rotate(90deg)' }} />
              </button>
              <button onClick={() => _nextSlide('rssRef')} className="btn color-blue-base bold">
                <Image image={Images.ArrowDown} style={{ transform: 'rotate(270deg)' }} />
              </button>
            </div>
          )}
        </div>
      </div>

      <div className="row mt-3 pe-4"></div>
      <div className="row d-none d-sm-block">
        <ProductsCarousel activeIndex={activeIndexSubService} items={recommendedSubserviceList} {...CAROUSEL_CONFIGS} ref={rssRef} onSlideChanged={(e: any) => setActiveIndexSubService(e.item)} />
      </div>

      <div className="row bg-color-blue-light p-0 m-0 d-none d-sm-block">
        <div className="col-12 py-3 px-3 color-black bold-300 d-flex align-items-center justify-content-between">
          <span>Productos</span>
          {nextPrevButtons && (
            <div className="">
              <button onClick={() => _prevSlide()} className="btn color-blue-base bold">
                <Image image={Images.ArrowDown} style={{ transform: 'rotate(90deg)' }} />
              </button>
              <button onClick={() => _nextSlide()} className="btn color-blue-base bold">
                <Image image={Images.ArrowDown} style={{ transform: 'rotate(270deg)' }} />
              </button>
            </div>
          )}
        </div>
      </div>

      <div className="row mb-5 py-5 d-none d-sm-block">
        <ProductsCarousel {...CAROUSEL_CONFIGS} items={data.related_products} activeIndex={activeIndexProducts} ref={rpRef} onSlideChanged={(e: any) => setActiveIndexProducts(e.item)} />
      </div>

      {/* Mobile Version  */}

      <div className="row bg-color-blue-light p-0 m-0 d-sm-none">
        <div className="col-12 py-3 px-3 color-black bold-300 d-flex align-items-center justify-content-between">
          Otros servicios
          {rssMobileNextPrevButtons && (
            <div className="">
              <button onClick={() => _prevSlide('rssMobileRef')} className="btn color-blue-base bold">
                <Image image={Images.ArrowDown} style={{ transform: 'rotate(90deg)' }} />
              </button>
              <button onClick={() => _nextSlide('rssMobileRef')} className="btn color-blue-base bold">
                <Image image={Images.ArrowDown} style={{ transform: 'rotate(270deg)' }} />
              </button>
            </div>
          )}
        </div>
      </div>

      <div className="row mt-3 pe-4"></div>
      <div className="row d-sm-none">
        <ProductsCarousel
          activeIndex={activeIndexSubServiceMobile}
          items={recommendedSubserviceListMobile}
          {...CAROUSEL_CONFIGS_MOBILE}
          ref={rssMobileRef}
          onSlideChanged={(e: any) => setActiveIndexSubServiceMobile(e.item)}
        />
      </div>

      <div className="row bg-color-blue-light p-0 m-0 d-sm-none">
        <div className="col-12 py-3 px-3 color-black bold-300 d-flex align-items-center justify-content-between">
          <span>Productos</span>
          {nextMobilePrevButtons && (
            <div className="">
              <button onClick={() => _prevSlide('rpMobileRef')} className="btn color-blue-base bold">
                <Image image={Images.ArrowDown} style={{ transform: 'rotate(90deg)' }} />
              </button>
              <button onClick={() => _nextSlide('rpMobileRef')} className="btn color-blue-base bold">
                <Image image={Images.ArrowDown} style={{ transform: 'rotate(270deg)' }} />
              </button>
            </div>
          )}
        </div>
      </div>

      <div className="row mb-5 py-5 d-sm-none">
        <ProductsCarousel
          {...CAROUSEL_CONFIGS_MOBILE}
          items={data.related_products_mobile}
          activeIndex={activeIndexProductsMobile}
          ref={rpMobileRef}
          onSlideChanged={(e: any) => setActiveIndexProductsMobile(e.item)}
        />
      </div>
    </div>
  );
};

export default RecommendedService;
