import { Table, Tooltip, Whisper } from 'rsuite';
import Car1 from '../../assets/image/svg/car_1.svg';
import Car2 from '../../assets/image/svg/car_2.svg';
import Car3 from '../../assets/image/svg/car_3.svg';
import Car4 from '../../assets/image/svg/car_4.svg';

const { Column, HeaderCell, Cell } = Table;

const TableLavamax = (props: any) => {
  const { data, headers } = props;

  return (
    <Table
      autoHeight
      data={data}
    >
      <Column width={50} align="center" fixed>
        <HeaderCell>{``}</HeaderCell>
        <Cell>
          {(rowData) => {
            switch (rowData.size) {
              case 1:
                return <img src={Car1} alt="icon-car-sm" />;
              case 2:
                return <img src={Car2} alt="icon-car-md" />;
              case 3:
                return <img src={Car3} alt="icon-car-xl" />;
              case 4:
                return <img src={Car4} alt="icon-car-xxl" />;
              default:
                break;
            }
          }}
        </Cell>
      </Column>

      {headers &&
        headers.map((h: any) => (
          <>
            {h !== 'size' ? (
              <Column flexGrow={1}>
                <HeaderCell align="center">
                  <Whisper trigger="click" placement="auto" controlId={`control-id-auto`} speaker={<Tooltip>{h}</Tooltip>}>
                    <span className="color-dark-blue bold-300 size-12">{h}</span>
                  </Whisper>
                </HeaderCell>
                <Cell align="center">
                  {(rowData) => (
                    <>
                      {rowData[h] ? (
                        <div className="size-12">
                          <span className="color-green-base bold me-1">$</span>
                          <span className="color-dark-blue bold-300">{rowData[h]?.toLocaleString('pt-BR')}</span>
                        </div>
                      ) : null}
                    </>
                  )}
                </Cell>
              </Column>
            ) : null}
          </>
        ))}
    </Table>
  );
};

export default TableLavamax;
