/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import InputMask from 'inputmask';
import Images from '../../assets/image';
import Image from '../../components/Image';
import Title from '../../components/Title';
import { LOAD_OFF, LOAD_ON } from '../../redux/actions/loader';
import { set_user } from '../../redux/actions/user';
import sessionService from '../../services/session.service';
import DesktopTemplate from '../Profile/DesktopTemplate';
import validate from '../../helpers/validate';
import { UserUpdateSchema } from '../../validations/user.update.validations';
import { validateRut } from '../../helpers/Utils';
import ErrorValidation from '../../components/ErrorValidation';

const PersonalData = (props: any) => {
  const history = useHistory();
  const { user } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const rutRef: any = useRef(null);
  const phoneRef: any = useRef(null);
  const [state, setState] = useState<any>({
    inputs: {
      name: '',
      family_name: '',
      rut: '',
      phone_number: '',
    },
    inputs_initial: {
      name: '',
      family_name: '',
      rut: '',
      phone_number: '',
    },
    disabled: true,
  });

  useEffect(() => {
    setState({
      ...state,
      inputs: {
        name: user?.profile?.name,
        family_name: user?.profile?.family_name,
        rut: user?.profile?.rut,
        phone_number: user?.profile?.phone_number,
      },
      inputs_initial: {
        name: user?.profile?.name,
        family_name: user?.profile?.family_name,
        rut: user?.profile?.rut,
        phone_number: user?.profile?.phone_number,
      },
      validations: {},
    });

    InputMask({
      mask: '9{1,2}.9{3}.9{3}-(K|k|9)',
      casing: 'upper',
      numericInput: true,
      placeholder: '_',
      showMaskOnFocus: false,
      showMaskOnHover: false,
      jitMasking: true,
    }).mask(rutRef.current);

    InputMask({
      mask: '+56 999999999',
      casing: 'upper',
      placeholder: '_',
      showMaskOnFocus: false,
      showMaskOnHover: false,
      jitMasking: true,
    }).mask(phoneRef.current);

    dispatch(LOAD_OFF());
  }, [user]);

  const _handleUpdateProfile = async () => {
    let validationsProfile: any = false;
    state.inputs.phone_number = state.inputs.phone_number.replace(' ', '');
    validationsProfile = await validate(UserUpdateSchema, state.inputs);

    if (!validateRut(state.inputs.rut)) {
      validationsProfile = validationsProfile === false ? {} : validationsProfile;
      validationsProfile.rut = ['El rut no es válido'];
    }

    if (validationsProfile) {
      setState({ ...state, validations: validationsProfile });
      return;
    }
    setState({ ...state, validations: {} });
    dispatch(LOAD_ON());
    try {
      await sessionService.updateProfile({ ...state.inputs, PK: user.profile?.PK, SK: user.profile?.SK, document: user.profile?.rut, phone: user.profile?.phone_number });
      setState({ ...state, inputs_initial: { ...state.inputs }, disabled: true });
      user.profile = { ...user.profile, ...state.inputs };
      dispatch(set_user(user));
      toast.success('Perfil actualizado con éxito.');
    } catch (e: any) {
      console.log('LOGGER', e);
      toast.error(e.message);
    }
    dispatch(LOAD_OFF());
  };

  const _handleOnChange = ({ target }: any) => {
    const { name, value } = target;
    state.inputs[name] = value;
    setState({ ...state });
  };

  const _toggleDisableEdit = () => {
    setState({ ...state, inputs: { ...state.inputs_initial }, disabled: !state.disabled });
  };

  return (
    <>
      {/* Mobile Version  */}
      <div className="container-fluid d-md-none mt-5">
        <div className="row justify-content-center">
          <Title size="md" text="Mis Datos" className="text-center color-dark-blue" />
        </div>

        <div className="w-100 d-flex justify-content-center">
          <div className="row color-black bold-300 my-2 px-4 size-08" style={{ maxWidth: '500px' }}>
            <div className='col-12 p-1 mb-1 bold size-11'>Nombre</div>
            <div className="col-12 mb-2 bg-color-blue-light border p-3 rounded color-grey">{`${user?.profile?.name} ${user?.profile?.family_name}`}</div>
            <div className='col-12 p-1 mb-1 bold size-11'>Rut</div>
            <div className="col-12 mb-2 bg-color-blue-light border p-3 rounded color-grey">{user?.profile?.rut}</div>
            <div className='col-12 p-1 mb-1 bold size-11'>Email</div>
            <div className="col-12 mb-2 bg-color-blue-light border p-3 rounded color-grey">{user?.profile?.email}</div>
            <div className='col-12 p-1 mb-1 bold size-11'>Teléfono</div>
            <div className="col-12 mb-2 bg-color-blue-light border p-3 rounded color-grey">{user?.profile?.phone_number}</div>
            <div className='col-12 p-1 mb-1 bold size-11'>Email</div>
            <div className="col-12 mb-2 bg-color-blue-light border p-3 rounded color-grey">{user?.profile?.email}</div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <button className="btn bold color-grey d-flex align-items-center justify-content-center w-100" onClick={() => history.push('/profile/personal-data/update')}>
              Editar
              <Image image={Images.EditIcon} className="ms-3" />
            </button>
          </div>
        </div>
      </div>

      {/* Desktop Version */}

      <div className="container-fluid d-none d-md-block">
        <DesktopTemplate>
          <div className="row border-bottom mx-4 px-0 py-4">
            <div className="col-6 p-0">
              <span className="bold size-12 color-black">Mis datos</span>
            </div>
            <div className="col-6 p-0">
              <button className="btn bold color-grey d-flex align-items-center justify-content-end w-100 " onClick={_toggleDisableEdit}>
                Editar
                <Image image={Images.EditIcon} className="ms-3" />
              </button>
            </div>
          </div>

          <div className="row mx-4 p-0 color-black mt-5">
            <div className="col-3 mb-3 bold pt-2">Nombre</div>
            <div className="col-9 mb-3 bold-300">
              <input type="text" className="input-default-editable" disabled={state.disabled} value={`${state.inputs.name}`} name="name" onChange={_handleOnChange} />
              <ErrorValidation validations={state.validations} name="name" className="text-end size-09" />
            </div>

            <div className="col-3 mb-3 bold pt-2">Apellido</div>
            <div className="col-9 mb-3 bold-300">
              <input type="text" className="input-default-editable" disabled={state.disabled} value={`${state.inputs.family_name}`} name="family_name" onChange={_handleOnChange} />
              <ErrorValidation validations={state.validations} name="family_name" className="text-end size-09" />
            </div>

            <div className="col-3 mb-3 bold pt-2">Rut</div>
            <div className="col-9 mb-3 bold-300">
              <input
                ref={rutRef}
                type="text"
                className="input-default-editable"
                disabled={state.disabled}
                value={`${state.inputs.rut}`}
                name="rut"
                onKeyUp={_handleOnChange}
                onChange={_handleOnChange}
              />
              <ErrorValidation validations={state.validations} name="rut" className="text-end size-09" />
            </div>

            <div className="col-3 mb-3 bold pt-2">Teléfono</div>
            <div className="col-9 mb-3 bold-300">
              <input
                ref={phoneRef}
                type="text"
                className="input-default-editable"
                disabled={state.disabled}
                value={`${state.inputs.phone_number}`}
                name="phone_number"
                onKeyUp={_handleOnChange}
                onChange={_handleOnChange}
              />
              <ErrorValidation validations={state.validations} name="phone_number" className="text-end size-09" />
            </div>
            <div className="col-3 mb-3 bold pt-2">Email</div>
            <div className="col-9 mb-3 bold-300">
              <input
                type="text"
                className="input-default-editable"
                value={`${user?.profile?.email}`}
                name="email"
                disabled
              />
            </div>
          </div>

          <div className="row px-4 mt-5">
            <div className="col-12 text-end color-white">
              <button className="btn-default size-11 px-5 py-2 rounded-30" disabled={state.disabled} onClick={_handleUpdateProfile}>
                Actualizar
              </button>
            </div>
          </div>
        </DesktopTemplate>
      </div>
    </>
  );
};
export default PersonalData;
