import { handleActions } from 'redux-actions';
import { DELETE_SCHEDULE, SET_SCHEDULE } from '../actions/schedule';

export const INITIAL_STATE = {
  schedules: []
};

export default handleActions({
  [SET_SCHEDULE]: (state: any, action: any) => {
    if (state.schedules.length > 0) {
      if (state.schedules.filter((item: any) => item.service.id === action.payload.service.id).length === 0) {
        state.schedules.push(action.payload)
      } else {
        const index = state.schedules.findIndex((item: any) => item.service.id === action.payload.service.id)
        action.payload.service.related_products = state.schedules[index].service.related_products
        state.schedules.splice(index, 1)
        state.schedules.push(action.payload)
      }
    } else {
      state.schedules.push(action.payload)
    }
    return state
  },
  [DELETE_SCHEDULE]: (state: any, action: any) => {
    const index = state.schedules.findIndex((item: any) => item.service.id === action.payload.service.id)
    state.schedules.splice(index, 1)
    return state
  }
}, INITIAL_STATE);