/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment'
import Images from '../../assets/image'
import Image from '../../components/Image'

const OrderCard = (props: any) => {
  const { data, status, className, services } = props

  return (
    <div className={`w-100 p-2 ${className || ''}`} style={{ borderBottom: '4px solid #F8FAFF' }}>
      <div className="row">
        <div className="col-10">
          <div className="row">
            <div className="col-2 d-flex align-items-center justify-content-center" style={{ marginBottom: '2px' }}>
              <Image image={Images.History} />
            </div>
            <div className="col-10 text-start" style={{ marginBottom: '2px' }}>
              <div className="size-09 color-black bold">{`# ${data.PK}`} - {status && status.find((s: any) => s.value?.toLowerCase() === data.status)?.label}</div>
              <div className="size-09 ellipsis w-100 bold">{`${services?.find((item: any) => item.value === data.parent_service_id)?.label}`}</div>
              <div className="size-08 ellipsis w-100">{`${data.info?.direction._string}`}</div>
              <div className="size-08">{`${moment(data.created_at).format('DD/MM/YYYY HH:mm')}`} </div>
            </div>
          </div>
        </div>
        <div className="col-2">
          <div className="w-100 h-100 d-flex align-items-center justify-content-end">
            <Image image={Images.ArrowDown} style={{ width: '12px', transform: 'rotate(270deg)' }} />
          </div>
        </div>
      </div>
    </div>
  )
}
export default OrderCard