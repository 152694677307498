/* eslint-disable react-hooks/exhaustive-deps */
import { Loader } from '@googlemaps/js-api-loader';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { _getGoogleAddressComponents } from '../../helpers/Utils';
import { LOAD_OFF, LOAD_ON } from '../../redux/actions/loader';
import sessionService from '../../services/session.service';
import StepOneUpdate from './StepOneUpdate';
import StepTwoUpdate from './StepTwoUpdate';

const DirectionsUpdate = (props: any) => {
  const [state, setState] = useState<any>({
    google: null,
    results: [],
    direction: {},
    directionData: {},
    showMaps: false,
    search: '',
  });

  const params: any = useParams();
  const dispatch: any = useDispatch();
  const { user } = useSelector((state: any) => state);

  useEffect(() => {
    _getDirections();
    if (!state.google) {
      const loader: any = new Loader({
        apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY ? process.env.REACT_APP_GOOGLE_MAPS_API_KEY : '',
        version: 'weekly',
        libraries: ['places', 'geometry'],
        ...{},
      });

      loader.load().then((google: any) => {
        const autocompleteService = new google.maps.places.AutocompleteService();
        setState((prevState: any) => {
          return {
            ...prevState,
            google,
            autocompleteService,
            api_key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
          };
        });
      });
    }
  }, []);

  const _getDirections = async () => {
    const { directions } = user;
    let direction: any = null;
    if (directions) {
      direction = directions.find((d: any) => d.SK === params.id);
    }

    if (direction) {
      setState({
        ...state,
        directionData: direction,
        search: direction.info?.address,
      });
    } else {
      try {
        dispatch(LOAD_ON());
        const directionsResponse: any = await sessionService.getAddresses();
        direction = directionsResponse.data.find((d: any) => d.SK === params.id);
        if (!direction) {
          throw new Error('No se pudo encontrar esta dirección');
        }
        setState({
          ...state,
          directionData: direction,
          search: direction.info?.address,
        });
        dispatch(LOAD_OFF());
      } catch (e: any) {
        toast.error('No se pudo encontrar esta dirección.');
        dispatch(LOAD_OFF());
      }
    }
  };

  const _handleChange = (e: any) => {
    const { autocompleteService } = state;
    if (e.target.value === '') {
      setState({ ...state, search: '', results: [] });
    } else {
      autocompleteService.getPlacePredictions({ input: e.target.value, componentRestrictions: { country: 'cl' } }, _displaySuggestions);
      setState({ ...state, search: e.target.value, results: [] });
    }
  };

  const _displaySuggestions = (payload: any) => {
    if (payload && payload.length > 0) {
      const results = payload.map((item: any) => {
        let show: any = false;
        let number: any = 0;
        item.terms.forEach((term: any) => {
          if (!isNaN(term.value)) {
            show = true;
            number = term.value;
          }
        });
        const data: any = {
          title: item.structured_formatting.main_text,
          subtitle: item.structured_formatting.secondary_text,
          description: item.description,
          place_id: item.place_id,
          show: show,
          number,
        };
        return data;
      });
      setState((prevState: any) => {
        return { ...prevState, results: results.filter((item: any) => item.show) };
      });
    }
  };

  const _handleGeolocation = (payload: any) => {
    const { google } = state;
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ placeId: payload.place_id }, async (response: any, status: any) => {
      if (status === 'OK') {
        if (response.length > 0) {
          const directions = response[0];
          const { address_components, geometry } = directions;
          const { location } = geometry;
          const latitude = location.lat();
          const longitude = location.lng();
          const { streetNumber, street, country, commune_adm_lvl_3, region } = _getGoogleAddressComponents(address_components, payload.number);

          if (!street) {
            toast.error('No fue posible encontrar la dirección');
            return;
          }

          const address: any = `${street?.long_name} ${streetNumber?.long_name}, ${commune_adm_lvl_3?.long_name || ''} - ${region?.long_name || ''} ${country?.long_name || ''}`;

          const d = { latitude, longitude, address: address, number: payload.number };
          setState({ ...state, direction: d, results: [], showMaps: true });
        }
      }
    });
  };

  return (
    <>
      {state && state.showMaps ? (
        <StepTwoUpdate geo={state.direction} address={state.directionData} />
      ) : (
        <StepOneUpdate _handleChange={_handleChange} _handleGeolocation={_handleGeolocation} results={state.results} search={state.search} />
      )}
    </>
  );
};
export default DirectionsUpdate;
