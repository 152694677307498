/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from 'react-redux';
import '../../styles/components/component.shopping.cart.scss';
import { useEffect, useState } from 'react';
import { remove_service_from_cart, update_service_to_cart } from '../../redux/actions/shopping_cart';
import { useHistory } from 'react-router';
import CheckoutMobile from './CheckoutMobile';
import CheckoutDesktop from './CheckoutDesktop';
import { toast } from 'react-toastify';
import Confirm from '../../components/Confirm';
import { edit_service, remove_service } from '../../redux/actions/services';
import { addToCartDataLayerEvent, clearDataLayerEvent, createDataLayerObject, createDataLayerObjectItem, removeFromCartDataLayerEvent, sendDataLayerEvent } from '../../helpers/data_layer';
import { _slug } from '../../helpers/Utils';
import { LOAD_OFF, LOAD_ON } from '../../redux/actions/loader';
import DiscountService from '../../services/discounts.service';
import { set_cross_selling } from '../../redux/actions/cross_selling';
import moment from 'moment';
import sessionService from '../../services/session.service';
const Checkout = () => {
  const { shopping_cart, matchMedia, user } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const history = useHistory();
  const [state, setState] = useState<any>({
    services: [],
    total: 0,
    total_without_discount: 0,
    discount: 0,
    delivery: 10.99,
    confirmWindow: { open: false },
    drawer: {
      script: {
        show: false,
        direction: 'right-left',
      },
      manual: {
        show: false,
        direction: 'right-left',
      },
      autocomplete: {
        show: false,
        direction: 'right-left',
      },
      history: {
        show: false,
        direction: 'bottom-top',
      },
    },
    product_to_delete: {},
    addresses: [],
  });

  useEffect(() => {
    const { services, total, delivery, total_without_discount } = shopping_cart;
    const items: any = [];
    services.forEach((service: any) => {
      const item: any = createDataLayerObjectItem(service.service, _slug(service.name), 'sub_service', 'checkout_items_list');
      items.push(item);
      service.related_products?.forEach((rp: any) => {
        const rpItem: any = createDataLayerObjectItem(rp, _slug(service.name), 'related_product', 'checkout_items_list');
        items.push(rpItem);
      });
    });
    const dataLayerEvent: any = createDataLayerObject('begin_checkout', items);
    clearDataLayerEvent();
    sendDataLayerEvent(dataLayerEvent);
    setState({ ...state, services, total, delivery, total_without_discount });
  }, [shopping_cart]);

  useEffect(() => {
    _handleInitComponent();
  }, []);

  const _handleInitComponent = async () => {
    dispatch(LOAD_ON());
    const crossSellingDiscount: any = await DiscountService.getCrossSellingDiscountData();
    dispatch(set_cross_selling(crossSellingDiscount));

    /**
     *  Get address and filter history address
     *  @addresses
     */
    const addresses: any = await _getAddress();
    const historyAddress: any = addresses?.filter((address: any) => address?.info?.history);

    if (historyAddress.length > 0) {
      historyAddress.sort((a: any, b: any) => {
        if (moment(a.info?.history_date).isAfter(b.info?.history_date)) return -1;
        if (moment(a.info?.history_date).isBefore(b.info?.history_date)) return 1;
        return 0;
      });

      const elementsToShow: any = historyAddress.slice(0, 3);
      const elementsToDelete: any = historyAddress.slice(3, historyAddress.length);

      for (const el of elementsToDelete) {
        sessionService.deleteAddress(el.SK);
      }

      state.addresses = elementsToShow;
    } else {
      state.addresses = [];
    }

    setState({ ...state });

    dispatch(LOAD_OFF());
  };

  const _getAddress = async () => {
    if (user.isLogged) {
      try {
        const address: any = await sessionService.getAddresses();
        return address.data;
      } catch (e: any) {
        return [];
      }
    }

    return [];
  };

  const _handleMinusService = (payload: any) => {
    const service = shopping_cart.services.find((ser: any) => ser.id === payload.id);
    const { related_products } = service;
    const product = related_products.find((rp: any) => rp.subservice_product);
    if (product) {
      if (service.service.quantity > 1) {
        if (product.quantity === service.service.quantity) {
          service.service.quantity -= 1;
          product.quantity -= 1;
          removeFromCartDataLayerEvent({ ...service.service, quantity: 1 }, _slug(service.name), 'sub_service', 'checkout_items_list');
          removeFromCartDataLayerEvent({ ...product, quantity: 1 }, _slug(service.name), 'related_product', 'checkout_items_list');
        } else {
          service.service.quantity -= 1;
          removeFromCartDataLayerEvent({ ...service.service, quantity: 1 }, _slug(service.name), 'sub_service', 'checkout_items_list');
        }
      }
    } else {
      if (service.service.quantity > 1) {
        service.service.quantity -= 1;
        removeFromCartDataLayerEvent({ ...service.service, quantity: 1 }, _slug(service.name), 'sub_service', 'checkout_items_list');
      }
    }
    dispatch(update_service_to_cart({ ...service }));
    dispatch(edit_service({ ...service }));
  };

  const _handlePlusService = (payload: any) => {
    const service = shopping_cart.services.find((ser: any) => ser.id === payload.id);
    service.service.quantity += 1;
    addToCartDataLayerEvent(service.service, _slug(service.name), 'sub_service', 'checkout_items_list');
    dispatch(update_service_to_cart({ ...service }));
    dispatch(edit_service({ ...service }));
  };

  const _handleMinusProduct = (payload: any) => {
    const service = shopping_cart.services.find((ser: any) => ser.id === payload.service.id);
    const { related_products } = service;
    const product = related_products.find((rp: any) => rp.PK === payload.product.PK);
    if (product.quantity > 1) {
      product.quantity -= 1;
      removeFromCartDataLayerEvent({ ...product, quantity: 1 }, _slug(service.name), 'related_product', 'checkout_items_list');
    }
    dispatch(update_service_to_cart({ ...service }));
    dispatch(edit_service({ ...service }));
  };

  const _handlePlusProduct = (payload: any) => {
    const service = shopping_cart.services.find((ser: any) => ser.id === payload.service.id);
    const { related_products } = service;
    const product = related_products.find((rp: any) => rp.PK === payload.product.PK);
    if (product && product.subservice_product) {
      if (product.quantity < service.service.quantity) {
        product.quantity += 1;
        addToCartDataLayerEvent(product, _slug(service.name), 'related_product', 'checkout_items_list');
      } else {
        toast.error('No se puede agregar más bidones que unidades de Kerosene...');
      }
    } else {
      product.quantity += 1;
      addToCartDataLayerEvent(product, _slug(service.name), 'related_product', 'checkout_items_list');
    }
    dispatch(update_service_to_cart({ ...service }));
    dispatch(edit_service({ ...service }));
  };

  const _handleDeleteService = (payload: any) => {
    setState({ ...state, confirmWindow: { open: false } });
    const service = shopping_cart.services.find((sc_service: any) => sc_service.id === payload.id);
    if (service && service.related_products) {
      service.related_products.forEach((item: any) => {
        removeFromCartDataLayerEvent(item, _slug(service.name), 'related_product', 'checkout_items_list');
      });
      delete service.related_products;
      if (service.service && service.service.quantity) {
        service.service.quantity = 0;
      }
    }
    removeFromCartDataLayerEvent(service.service, _slug(service.name), 'related_product', 'checkout_items_list');
    dispatch(remove_service_from_cart({ ...payload }));
    dispatch(remove_service({ ...payload }));
  };

  const _handleDeleteProduct = (payload: any) => {
    const service = shopping_cart.services.find((ser: any) => ser.id === payload.service.id);
    const { related_products } = service;
    const index = related_products.findIndex((rp: any) => rp.PK === payload.product.PK);
    const product = related_products.find((rp: any) => rp.PK === payload.product.PK);
    if (product) {
      removeFromCartDataLayerEvent(product, _slug(service.name), 'related_product', 'checkout_items_list');
    }
    related_products.splice(index, 1);
    setState({ ...state, confirmWindow: { open: false } });
    dispatch(update_service_to_cart({ ...service }));
    dispatch(edit_service({ ...service }));
  };

  const _toogleDrawer = (payload: any) => {
    Object.keys(state.drawer).forEach((key: any) => {
      if (key === payload) {
        state.drawer[key].show = !state.drawer[key].show;
      } else {
        state.drawer[key].show = false;
      }
    });
    setState({ ...state });
  };

  const _handleDelete = () => {
    if (state.product_to_delete.id) {
      _handleDeleteService(state.product_to_delete);
    } else {
      _handleDeleteProduct(state.product_to_delete);
    }
  };

  const _handleNext = () => {
    history.push('/check-out/step-2');
  };

  return (
    <div className="position-relative" style={{ top: matchMedia.media === 'xs' ? '30px' : '0px' }}>
      <Confirm type="danger" open={state.confirmWindow.open} onClose={() => setState({ ...state, confirmWindow: { open: false } })} sticky>
        <div className="row px-3 py-3">
          <div className="col-10 bold">
            <div>¿Te arrepentiste?</div>
            <div>Si, deseo eliminar este producto.</div>
          </div>
          <div className="col-2  d-flex justify-content-center align-items-center">
            <button onClick={_handleDelete} className="btn color-white size-15">
              <i className="bi bi-trash"></i>
            </button>
          </div>
        </div>
      </Confirm>

      <CheckoutMobile
        state={state}
        _toogleDrawer={_toogleDrawer}
        _handleDeleteService={(payload: any) => setState({ ...state, confirmWindow: { open: true }, product_to_delete: payload })}
        _handlePlusProduct={_handlePlusProduct}
        _handleMinusProduct={_handleMinusProduct}
        _handlePlusService={_handlePlusService}
        _handleMinusService={_handleMinusService}
        _handleDeleteProduct={(payload: any) => setState({ ...state, confirmWindow: { open: true }, product_to_delete: payload })}
        _handleNext={_handleNext}
      />
      <CheckoutDesktop
        state={state}
        _toogleDrawer={_toogleDrawer}
        _handleDeleteService={(payload: any) => setState({ ...state, confirmWindow: { open: true }, product_to_delete: payload })}
        _handlePlusProduct={_handlePlusProduct}
        _handleMinusProduct={_handleMinusProduct}
        _handlePlusService={_handlePlusService}
        _handleMinusService={_handleMinusService}
        _handleDeleteProduct={(payload: any) => setState({ ...state, confirmWindow: { open: true }, product_to_delete: payload })}
        _handleNext={_handleNext}
      />
    </div>
  );
};

export default Checkout;
