import { toast } from 'react-toastify';
import { set_eds } from '../redux/actions/available_eds';
import { setDirections } from '../redux/actions/directions';
import { clear_services } from '../redux/actions/services';
import { clear_cart } from '../redux/actions/shopping_cart';
import { set_splash } from '../redux/actions/splash_screen';
import store from '../redux/store';
import { _containsLatLan } from './check_polygon';
import { _getGoogleAddressComponents } from './Utils';

export const _searchByAddress = async (state: any, clear: any = false) => {
  const { google, data } = state;
  const dispatch: any = store.dispatch;
  const { matchMedia } = store.getState();
  try {
    const geocoder = new google.maps.Geocoder();
    if (matchMedia.media === 'xs') {
      dispatch(set_splash({ direction: true }));
    } else {
      dispatch(set_splash({ direction_desktop: true }));
    }
    return await geocoder.geocode({ address: `${data._string}` }, async (response: any, status: any) => {
      if (status === 'OK') {
        const directions = response[0];
        const { address_components, geometry } = directions;
        const { location } = geometry;
        const latitude = data.latitude || location.lat();
        const longitude = data.longitude || location.lng();

        const { streetNumber, street, country, commune_adm_lvl_3, region } = _getGoogleAddressComponents(address_components, data.number);

        if (!street) {
          toast.error('No fue posible encontrar la dirección');
          dispatch(set_splash({ direction: false, direction_desktop: false }));
          return;
        }

        const address: any = `${street?.long_name} ${streetNumber?.long_name}, ${commune_adm_lvl_3?.long_name || ''} - ${region?.long_name || ''} ${country?.long_name || ''}`;
        try {
          const availble_eds = await _containsLatLan(latitude, longitude);

          if (!availble_eds) {
            dispatch(set_splash({ direction: false, direction_desktop: false }));
            return;
          }

          dispatch(set_eds(availble_eds));
          dispatch(
            setDirections({
              _string: address,
              direction: address,
              longitude: longitude,
              latitude: latitude,
              observations: data.observations || '',
              apartment: data.apartment || '',
            })
          );

          dispatch(set_splash({ direction_desktop: false, direction: false }));
          if (clear) {
            dispatch(clear_cart());
            dispatch(clear_services());
          }
          return Promise.resolve(true);
        } catch (e) {
          dispatch(set_splash({ direction_desktop: false, direction: false }));
          toast.error('No se pudo encontrar ningún servicio en su dirección principal...');
          return Promise.reject(false);
        }
      } else {
        dispatch(set_splash({ direction_desktop: false, direction: false }));
        toast.error('No se pudo encontrar ningún servicio en su dirección principal...');
        return Promise.reject(false);
      }
    });
  } catch (e) {
    console.log('[LOG]', e);
    dispatch(set_splash({ direction_desktop: false, direction: false }));
    toast.error('No se pudo encontrar ningún servicio en su dirección principal...');
    return Promise.reject(false);
  }
};

export const _isCrossSellingAvailable = (shopping_cart: any) => {
  const services: any = shopping_cart?.services?.length;
  return services > 0 ? true : false;
};

export const _isCrossSellingApplicable = (shopping_cart: any) => {
  const services: any = shopping_cart?.services?.length;
  return services > 1 ? true : false;
};
