import { createAction } from 'redux-actions';

export const SET_ADMIN_USER: any = createAction('SET_ADMIN_USER');
export const CLEAR_ADMIN_USER: any = createAction('CLEAR_ADMIN_USER');
export const SAVE_STATE: any = createAction('SAVE_STATE');

export const set_admin_user = (payload: any) => {
  return async (dispatch: any) => {
    await dispatch(SET_ADMIN_USER(payload));
    await dispatch(SAVE_STATE());
    return Promise.resolve()
  }
}

export const clear_admin_user = () => {
  return (dispatch: any) => {
    dispatch(CLEAR_ADMIN_USER());
    dispatch(SAVE_STATE());
  }
}
