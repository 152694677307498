/* eslint-disable react-hooks/exhaustive-deps */
import Image from '../Image';
import '../../styles/components/component.card-lub.scss';
import Images from '../../assets/image';
import './style.scss';
import QuantitySelectorCard from '../QuantitySelectorCard';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

const CardKerosene = (props: any) => {
  const { image, text, handleAction, price, data, product, onPlusProduct, onMinusProduct, fullpoints } = props;
  const { shopping_cart, services } = useSelector((state: any) => state);
  const [productData, setProductData] = useState<any>({});

  useEffect(() => {
    const service: any = shopping_cart.services.find((s: any) => s.PK === data.PK && s.SK === data.SK);
    const target_service: any = services.find((s: any) => s.PK === data.PK && s.SK === data.SK);

    if (service || target_service) {
      let products: any = [];
      let related_products: any = [];
      if (service) {
        related_products = service.related_products || [];
        products = [...[service.service], ...related_products];
      }

      if (target_service) {
        related_products = target_service.related_products || [];
        products = [...[target_service.service], ...related_products];
      }
      const productData: any = products.find((p: any) => p.SK === product.SK);
      setProductData(productData);
    } else {
      setProductData({});
    }
  }, [shopping_cart]);

  const _handleOnClick = () => {
    try {
      handleAction({ product, module: 'kerosene' });
    } catch (e: any) {
      toast.error('No fue posible agregar este producto');
    }
  };

  useEffect(() => {
    const isServiceInShoppingCart: any = shopping_cart.services.find((s: any) => s.SK === data.PK);
    if (!isServiceInShoppingCart) {
      const service: any = services.find((service: any) => service.PK === data.PK);

      if (service) {
        service.related_products = service.related_products || [];
        const products: any = [...[service.service], ...service.related_products];
        const productData: any = products.find((p: any) => p.SK === product.SK);
        setProductData(productData);
      } else {
        setProductData({});
      }
    }
  }, [services]);

  return (
    <div className="card-home-kerosene">
      <div className="card-home-kerosene__image">
        <Image image={image || Images.NotFound} />
      </div>
      <div className="card-home-kerosene__content row">
        <div className="size-12 color-white bold-300">{text}</div>

        {fullpoints && product?.info?.points_per_division && parseInt(product?.info?.points_per_division) ? (
          <div className="d-flex color-white align-items-center size-09 my-2">
            <Image image={Images.CopecFull} className="me-2" style={{ width: '100px' }} />
            Acumula puntos Full Copec.
          </div>
        ) : null}

        {product?.info?.show_discount ? (
          <div className="d-flex flex-column">

            <div className="d-flex align-items-center">
              <span className="color-white me-1">$</span>
              <span className="color-white  size-12 bold text-decoration-line-through">{product?.info?.without_discount_price?.toLocaleString('es-CL')}</span>
              <span className="color-green-base bold ms-3">{product?.info?.porcentageDiscount}% Dcto</span>
            </div>

            <div className="d-flex size-15 align-items-center">
              <span className="color-green-base size-08 me-1">$</span>
              <span className="color-white size-15 bold">{price?.toLocaleString('es-CL')}</span>
            </div>
          </div>
        ) : (
          <div className="d-flex size-15 align-items-center">
            <span className="color-green-base size-08 me-1">$</span>
            <span className="color-white size-15 bold">{price?.toLocaleString('es-CL')}</span>
          </div>
        )}

        {!productData?.quantity || productData?.quantity === 0 ? (
          <div className="w-100 mt-2">
            <button className="btn-green-light py-2 w-100 bold" onClick={_handleOnClick}>
              Agregar
            </button>
          </div>
        ) : (
          <div className="w-75 mt-2">
            <QuantitySelectorCard
              quantity={productData?.quantity}
              onAdd={() => onPlusProduct({ PK: product.PK, SK: product.SK })}
              onRemove={() => onMinusProduct({ PK: product.PK, SK: product.SK })}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default CardKerosene;
