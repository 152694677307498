/* eslint-disable react-hooks/exhaustive-deps */
import { Loader } from '@googlemaps/js-api-loader';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import ErrorValidation from '../../components/ErrorValidation';
import Title from '../../components/Title';
import validate from '../../helpers/validate';
import { LOAD_ON } from '../../redux/actions/loader';
import sessionService from '../../services/session.service';
import { DirectionsManualSchema } from '../../validations/directions.manual.validations';

const StepTwo = (props: any) => {
  const { geo } = props;
  const [state, setState] = useState<any>({
    direction: {},
    validations: {},
  });
  const mapRef = useRef<any>(null);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!state.google) {
      const loader: any = new Loader({
        apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY ? process.env.REACT_APP_GOOGLE_MAPS_API_KEY : '',
        version: 'weekly',
        libraries: ['places', 'geometry'],
        ...{},
      });

      loader.load().then((google: any) => {
        _handleGeolocation(geo, google);
      });
    }
  }, []);

  const _handleGeolocation = (geo: any, google: any) => {
    const { latitude, longitude } = geo;
    try {
      const location = new google.maps.LatLng(latitude, longitude);

      const map = new google.maps.Map(mapRef.current, {
        center: location,
        zoom: 15,
        disableDefaultUI: true,
        zoomControl: true,
        fullscreenControl: true,
      });

      new google.maps.Marker({
        position: location,
        map: map,
      });

      setState({ ...state, google, api_key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY, direction: geo });
    } catch (e) {
      console.log('[LOG]', e);
    }
  };

  const _handleCreate = async () => {
    const validations: any = await validate(DirectionsManualSchema, state.direction);
    setState({ ...state, validations });

    if (validations) return;

    const { latitude, longitude, address, number, observations, apartment } = state.direction;
    dispatch(LOAD_ON());
    try {
      const response = await sessionService.createAddress({
        longitude: longitude,
        latitude: latitude,
        name: 'Principal',
        main: false,
        info: {
          address: address,
          number,
          observations,
          apartment,
        },
      });
      if (response) {
        history.push('/profile/my-directions?success=ok');
      }
    } catch (error) {
      history.push('/profile/my-directions?error=ok');
    }
  };

  return (
    <div className="container-fluid mt-4">
      <Title text="Agregar dirección" size="md" className="text-center color-dark-blue" />
      <div className="row justify-content-center p-3 rounded-10" style={{ height: '250px' }}>
        <div id="map" className="rounded-20 border" ref={mapRef} />
      </div>

      <div className="row px-3" style={{ maxWidth: '500px' }}>
        <div className="p-0 mb-1 color-black bold size-09">Dirección</div>
        <div className="col-12 d-flex bg-color-blue-light border px-3 py-2 rounded-10">
          <input
            type="text"
            className="input-transparent"
            name="direction"
            placeholder="Ingresa dirección"
            style={{ flex: '1' }}
            value={state.direction.address}
            onChange={(e: any) => setState({ ...state, direction: { ...state.direction, address: e.target.value } })}
            disabled
          />
        </div>
      </div>

      <div className="row px-3 mt-2" style={{ maxWidth: '500px' }}>
        <div className="p-0 mb-1 color-black bold size-09">Casa/Departamento</div>
        <div className="col-12 d-flex bg-color-blue-light border px-3 py-2 rounded-10">
          <input
            type="text"
            className="input-transparent"
            name="apartment"
            placeholder="Casa/Depto/Block"
            style={{ flex: '1' }}
            value={state.direction.apartment}
            onChange={(e: any) => setState({ ...state, direction: { ...state.direction, apartment: e.target.value } })}
          />
        </div>
        <ErrorValidation validations={state.validations} name="apartment" className="text-end size-09 bold-300" />
      </div>

      <div className="row px-3 mt-2" style={{ maxWidth: '500px' }}>
        <div className="p-0 mb-1 color-black bold size-09">Observaciones</div>
        <div className="col-12 d-flex bg-color-blue-light border px-3 py-2 rounded-10">
          <textarea
            className="input-transparent"
            name="observations"
            placeholder="Ej: Dejar en conserjeria"
            style={{ flex: '1' }}
            value={state.direction.observations}
            onChange={(e: any) => setState({ ...state, direction: { ...state.direction, observations: e.target.value } })}
            rows={4}
          />
        </div>
        <ErrorValidation validations={state.validations} name="observations" className="text-end size-09 bold-300" />
      </div>

      <div className="row px-3 mt-3 color-white">
        <button className="btn-default p-3" onClick={_handleCreate}>
          Guardar
        </button>
      </div>
    </div>
  );
};
export default StepTwo;
