import { useEffect, useRef } from 'react';
import ErrorValidation from '../../components/ErrorValidation';
import InputMask from 'inputmask';

const ThirdWillReceive = (props: any) => {
  const { onChange, validations } = props;
  const phoneRef: any = useRef(null);
  const phoneRefDesktop: any = useRef(null);

  useEffect(() => {
    InputMask({
      mask: '999999999',
      casing: 'upper',
      numericInput: true,
      placeholder: '_',
      showMaskOnFocus: false,
      showMaskOnHover: false,
      jitMasking: true,
    }).mask(phoneRef.current);
    InputMask({
      mask: '999999999',
      casing: 'upper',
      numericInput: true,
      placeholder: '_',
      showMaskOnFocus: false,
      showMaskOnHover: false,
      jitMasking: true,
    }).mask(phoneRefDesktop.current);
  }, []);
  return (
    <>
      <div className="d-sm-none color-black">
        <div className="row px-2 mb-4">
          <div className="col-12 size-09 bold px-2 mt-4 pb-2 color-dark-blue line-border">Datos de quien recibe</div>
        </div>

        <div className="row flex-column px-4 mb-3">
          <div className="col ps-1 mb-1 color-black bold size-09">Nombre</div>
          <div className="col bg-color-blue-light px-3 py-1 border-default rounded-10">
            <input className="w-100 p-2 input-transparent size-09" type="text" placeholder="Ingrese nombre" name="name_third" onChange={(e: any) => onChange(e, 'third_will_receive')} />
          </div>
          <ErrorValidation validations={validations} name="name_third" className="size-09 text-end bold-300" />
        </div>

        <div className="row flex-column px-4 mb-3">
          <div className="col ps-1 mb-1 color-black bold size-09">Teléfono</div>
          <div className="col bg-color-blue-light px-3 py-1 border-default rounded-10 d-flex align-items-center">
            <span className="py-2 size-09 bold">+56</span>
            <input
              ref={phoneRef}
              className="w-100 p-2 input-transparent size-09"
              maxLength={9}
              type="text"
              placeholder="996789261"
              name="phone_number_third"
              onChange={(e: any) => onChange(e, 'third_will_receive')}
            />
          </div>
          <ErrorValidation validations={validations} name="phone_number_third" className="size-09 text-end bold-300" />
        </div>
      </div>

      {/* Desktop Version */}

      <div className="d-none d-sm-block px-5 mb-3">
        <div className="row mb-4 line-border">
          <div className="col-12 color-black  size-12 bold mt-3 pb-2">Datos de quien recibe</div>
        </div>

        <div className="row">
          <div className="col-6 mb-3">
            <div className="color-black bold mb-2">Nombre</div>
            <div className="col bg-color-blue-light px-3 py-2 border-default rounded-20">
              <input className="w-100 p-2 input-transparent size-09" type="text" placeholder="Ingresa su nombre" name="name_third" onChange={(e: any) => onChange(e, 'third_will_receive')} />
            </div>
            <ErrorValidation validations={validations} name="name_third" className="size-09 text-end bold-300" />
          </div>
          <div className="col-6 mb-3">
            <div className="color-black bold mb-2">Teléfono</div>
            <div className="col bg-color-blue-light px-3 py-2 border-default rounded-20 d-flex align-items-center">
              <span className="py-2 size-09 bold">+56</span>
              <input
                ref={phoneRefDesktop}
                className="w-100 p-2 input-transparent size-09"
                type="text"
                placeholder="996789261"
                name="phone_number_third"
                onChange={(e: any) => onChange(e, 'third_will_receive')}
              />
            </div>
            <ErrorValidation validations={validations} name="phone_number_third" className="size-09 text-end bold-300" />
          </div>
        </div>
      </div>
    </>
  );
};

export default ThirdWillReceive;
