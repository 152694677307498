
function CardBenefitApp(props: any) {
    return (
        <div className='card'>
            <img alt={props.title} src={props.imagen} height={100} style={{ paddingTop: 10 }} />
            <div className='titleCard'> {props.title} </div>
            <div className='contentCard'> {props.content} </div>
        </div>
    );
}

export default CardBenefitApp;