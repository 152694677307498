import './style.scss'
const QuantitySelectorCard = (props: any) => {
  const { quantity, onAdd, onRemove } = props
  return (
    <div className="component-quantity-card bold">
      <button className="component-quantity-card__actions" onClick={onRemove}>{'-'}</button>
      <div className="component-quantity-card__input">{quantity}</div>
      <button className="component-quantity-card__actions" onClick={onAdd}>{'+'}</button>
    </div>
  )
}

export default QuantitySelectorCard;