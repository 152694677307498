import { toast } from 'react-toastify';
import { Tag } from 'rsuite';
interface SubscriptionCardProps {
  className?: string;
  name: string;
  end_at: string;
  coupons: string[];
}

const _copyToClipboard = (text: string) => {
  navigator.clipboard.writeText(text);
  toast.success('Cupón copiado con exito');
};

const SubscriptionCard = ({ className, name, end_at, coupons }: SubscriptionCardProps) => {
  return (
    <div className={`w-100 p-2 ${className || ''}`} style={{ borderBottom: '4px solid #F8FAFF' }}>
      <div className="w-100 size-11 bold color-black">{name}</div>
      <div className="w-100 size-08 ">{end_at}</div>
      <div className="w-100 mt-3 size-09 color-dark-blue bold">Cupones</div>
      <div className="w-100 p-3">
        {coupons.map((coupon, index) => (
          <button className="btn p-0 mx-1" key={index} onClick={() => _copyToClipboard(coupon)}>
            <Tag className="mb-2 border">
              {coupon}
            </Tag>
          </button>
        ))}
      </div>
    </div>
  );
};

export default SubscriptionCard;
