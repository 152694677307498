/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Images from '../../assets/image';
import Image from '../../components/Image';
import Title from '../../components/Title';
import { clear_user } from '../../redux/actions/user';
import { menu } from './menu';
import { remove_service_from_cart } from '../../redux/actions/shopping_cart';
import { remove_service } from '../../redux/actions/services';

const DesktopTemplate = (props: any) => {
  const [state, setState] = useState<any>({
    menu: [],
  });

  const history = useHistory();
  const dispatch = useDispatch();
  const { user, shopping_cart } = useSelector((state: any) => state);
  const { children } = props;

  const _signout = () => {
    const company_services = shopping_cart?.services?.filter((service: any) => service?.info?.is_company || service.type === "subscription");
    company_services.forEach((service: any) => {
      dispatch(remove_service_from_cart({ ...service }));
      dispatch(remove_service({ ...service }));
    });

    dispatch(clear_user());
  };

  useEffect(() => {
    const roles = user?.profile?.info?.roles;

    const menu_filtered_by_role = menu.filter((item: any) => item.roles.some((role: any) => roles?.includes(role)));

    const data = menu_filtered_by_role.map((item: any) => {
      if (item.home === history.location.pathname || item.url === history.location.pathname) {
        item.is_active = true;
        return item;
      }

      item.is_active = false;
      return item;
    });
    setState({ ...state, menu: data });
  }, []);

  return (
    <div className="container-fluid px-5">
      <Title text="Mi perfil" size="lg" className="bold color-dark-blue" />
      <div className="row" style={{ minHeight: '70vh' }}>
        <div className="col-3 border rounded-15 p-3 mx-2" style={{ height: 'fit-content' }}>
          {state.menu &&
            state.menu.map((item: any) => (
              <div className="row py-1 mx-2" key={item.label}>
                <div className={`${item.is_active ? 'selected' : ''} col-12 bg-input rounded-15 p-3`} style={{ border: 'none' }}>
                  <button className="btn w-100 m-0 p-0 d-flex justify-content-between align-items-center bold" onClick={() => history.push(item.url)}>
                    <div className={`d-flex align-items-center ${item.is_active ? 'color-white' : 'color-grey'}`}>
                      <Image image={item.is_active ? Images[item.selected] : Images[item.icon]} className="me-4" style={{ width: '20px' }} />
                      {item.label}
                    </div>
                    <Image image={Images.ArrowDown} style={{ transform: 'rotate(270deg)' }} />
                  </button>
                </div>
              </div>
            ))}
          <div className="row py-1 mx-2">
            <div className="col-12 bg-input rounded-15 p-3" style={{ border: 'none' }}>
              <button className="btn w-100 m-0 p-0 d-flex justify-content-between align-items-center bold" onClick={_signout}>
                <div className="d-flex align-items-center color-grey">
                  <Image image={Images.CloseSession} className="me-4" />
                  Cerrar Sesión
                </div>
                <Image image={Images.ArrowDown} style={{ transform: 'rotate(270deg)' }} />
              </button>
            </div>
          </div>
        </div>

        <div className="col-8 border rounded-15 mx-2 p-3" style={{ overflow: 'auto', maxHeight: '100%' }}>
          {children}
        </div>
      </div>
    </div>
  );
};
export default DesktopTemplate;
