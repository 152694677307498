import { createAction } from 'redux-actions';

export const TOOGLE_CART: any = createAction('TOOGLE_CART');
export const CLOSE_CART: any = createAction('CLOSE_CART');
export const ADD_TO_CART: any = createAction('ADD_TO_CART');
export const ADD_TO_CART_WITH_RENDER: any = createAction('ADD_TO_CART_WITH_RENDER');
export const REMOVE_TO_CART: any = createAction('REMOVE_TO_CART');
export const UPDATE_CART: any = createAction('UPDATE_CART');
export const UPDATE_VALUES_CART: any = createAction('UPDATE_VALUES_CART');
export const UPDATE_CUPON_CART: any = createAction('UPDATE_CUPON_CART');
export const CLEAR_CART: any = createAction('CLEAR_CART');
export const ADD_RELATED_PRODUCT: any = createAction('ADD_RELATED_PRODUCT');
export const SET_CUPON: any = createAction('SET_CUPON');
export const CLEAR_CUPON: any = createAction('CLEAR_CUPON');
export const SAVE_STATE: any = createAction('SAVE_STATE');

export const add_related_product = (payload: any) => (dispatch: any) => {
  dispatch(ADD_RELATED_PRODUCT({ ...payload }));
  dispatch(SAVE_STATE());
};

export const set_cupon = (payload: any) => (dispatch: any) => {
  dispatch(SET_CUPON({ ...payload }));
  dispatch(SAVE_STATE());
};

export const clear_cupon = () => (dispatch: any) => {
  dispatch(CLEAR_CUPON());
  dispatch(SAVE_STATE());
};

export const update_cart = (payload: any) => (dispatch: any) => {
  dispatch(UPDATE_CART({ ...payload }));
  dispatch(SAVE_STATE());
};

export const toggle_cart = () => {
  return (dispatch: any) => {
    dispatch(TOOGLE_CART());
    dispatch(SAVE_STATE());
  };
};

export const close_cart = () => {
  return (dispatch: any) => {
    dispatch(CLOSE_CART());
    dispatch(SAVE_STATE());
  };
};

export const add_to_cart = (payload: any) => {
  return (dispatch: any) => {
    dispatch(ADD_TO_CART(payload));
    dispatch(SAVE_STATE());
  };
};

export const add_to_cart_with_render = (payload: any) => {
  return (dispatch: any) => {
    dispatch(ADD_TO_CART_WITH_RENDER(payload));
    dispatch(SAVE_STATE());
  };
};

export const remove_to_cart = (payload: any) => {
  return (dispatch: any) => {
    dispatch(REMOVE_TO_CART(payload));
    dispatch(SAVE_STATE());
  };
};

export const update_values_cart = (payload: any) => {
  return (dispatch: any) => {
    dispatch(UPDATE_VALUES_CART(payload));
    dispatch(SAVE_STATE());
  };
};

export const update_cupon_cart = (payload: any) => {
  return (dispatch: any) => {
    dispatch(UPDATE_CUPON_CART(payload));
    dispatch(SAVE_STATE());
  };
};

/**
 *  NEW SHOPPING CART MODULE
 */

export const ADD_SERVICE_TO_CART: any = createAction('ADD_SERVICE_TO_CART');
export const REMOVE_SERVICE_TO_CART: any = createAction('REMOVE_SERVICE_TO_CART');
export const UPDATE_SERVICE_TO_CART: any = createAction('UPDATE_SERVICE_TO_CART');
export const UPDATE_SHOPPINGCART: any = createAction('UPDATE_SHOPPINGCART');

export const add_service_to_cart = (payload: any) => {
  return (dispatch: any) => {
    dispatch(ADD_SERVICE_TO_CART(payload));
    dispatch(SAVE_STATE());
  };
};

export const remove_service_from_cart = (payload: any) => {
  return (dispatch: any) => {
    dispatch(REMOVE_SERVICE_TO_CART(payload));
    dispatch(SAVE_STATE());
  };
};

export const update_service_to_cart = (payload: any) => {
  return (dispatch: any) => {
    dispatch(UPDATE_SERVICE_TO_CART(payload));
    dispatch(SAVE_STATE());
  };
};

export const update_shoppingcart = (payload: any) => {
  return (dispatch: any) => {
    dispatch(UPDATE_SHOPPINGCART(payload));
    dispatch(SAVE_STATE());
  };
};

export const clear_cart = () => {
  return (dispatch: any) => {
    dispatch(CLEAR_CART());
    dispatch(SAVE_STATE());
  };
};
