/* eslint-disable no-useless-escape */
import * as yup from 'yup';

export const UserUpdateSchema = yup.object().shape({
  name: yup
    .string()
    .matches(/^[\w\. ?¿¡!^\+-\:()\{\}\[\],;\*#@áàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ]*$/gim, 'Este campo contiene caracteres inválidos')
    .max(255, "Este campo acepta un máximo de 255 caracteres")
    .required('Este campo es requerido'),
  family_name: yup
    .string()
    .matches(/^[\w\. ?¿¡!^\+-\:()\{\}\[\],;\*#@áàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ]*$/gim, 'Este campo contiene caracteres inválidos')
    .max(255, "Este campo acepta un máximo de 255 caracteres")
    .required('Este campo es requerido'),
  phone_number: yup.string().min(12, 'Número de teléfono inválido').max(12, 'Número de teléfono inválido').required('Este campo es requerido'),
  rut: yup.string().required('Este campo es requerido'),
});

export type UserUpdateType = yup.InferType<typeof UserUpdateSchema>;
