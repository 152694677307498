import { handleActions } from 'redux-actions';
import { cloneObject } from '../../helpers/Utils';
import { ADD_RELATED, CLEAR_SERVICES, CREATE_SERVICE, DELETE_SERVICE, EDIT_SERVICE, REMOVE_SERVICE, SET_SERVICE, UPDATE_RELATED_PRODUCT, UPDATE_SERVICE } from '../actions/services';

/**
 * Example Service
 * {
    id: null,
    car: null,
    date: null,
    service: null,
    module: null
    related_products: [],
   }
 *
**/

export const INITIAL_STATE = [];

export default handleActions(
  {
    [SET_SERVICE]: (state: any, action: any) => {
      const index = state.findIndex((s: any) => s.id === action.payload.id);
      if (index === -1) {
        state.push(action.payload);
        return [...state];
      }

      state[index] = { ...action.payload };

      return [...state];
    },
    [ADD_RELATED]: (state: any, action: any) => {
      const index = state.findIndex((service: any) => service.id === action.payload.id);
      const product_index = state[index].related_products.findIndex((rp: any) => rp.id === action.payload.id);
      if (product_index === -1) {
        state[index].related_products.push(action.payload.product);
      } else {
        state[index].related_products.splice(product_index, 1);
        state[index].related_products.push(action.payload.product);
      }
      return [...cloneObject(state)];
    },
    [UPDATE_SERVICE]: (state: any, action: any) => {
      const index = state.findIndex((service: any) => service.id === action.payload.id);
      state[index] = { ...action.payload };
      return [...cloneObject(state)];
    },
    [DELETE_SERVICE]: (state: any, action: any) => {
      const index = state.findIndex((service: any) => service.id === action.payload.id);
      state.splice(index, 1);
      return [...cloneObject(state)];
    },
    /**
     * NEW MODULE SERVICES AND PRODUCTS
     */

    [CREATE_SERVICE]: (state: any, { payload }: any) => {
      state.push(payload);
      return [...state];
    },
    [EDIT_SERVICE]: (state: any, { payload }: any) => {
      let target_index = state.findIndex((service: any) => service.id === payload.id);
      if (target_index !== -1) {
        state[target_index] = payload;
        return [...state];
      }
    },
    [REMOVE_SERVICE]: (state: any, { payload }: any) => {
      let target_index = state.findIndex((service: any) => service.id === payload.id);
      if (target_index !== -1) {
        state.splice(target_index, 1);
        return [...state];
      }
    },
    [UPDATE_RELATED_PRODUCT]: (state: any, { payload }: any) => {
      let target_index = state.findIndex((service: any) => service.id === payload.id);
      if (target_index !== -1) {
        state[target_index].related_products = payload.related_products;
        return [...state];
      }
    },
    [CLEAR_SERVICES]: () => {
      return [];
    },
  },
  INITIAL_STATE
);
