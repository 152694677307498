/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import ButtonBack from "../../components/ButtonBack";
import Header from "../DefaultTemplate/Header";

const ProfileTemplate = (props: any) => {
  const { children, url } = props;
  const history = useHistory();
  const { user, matchMedia } = useSelector((state: any) => state);

  useEffect(() => {
    if (!user.isLogged) {
      history.push("/sign-in");
      return;
    }
    const userAccessListRoles = user.profile?.info?.roles;
    const hasACLPermission = userAccessListRoles.find((role: any) => role === "client" || role === "client_company");

    if(!hasACLPermission) {
      history.push("/");
      return;
    }
    
  }, [user]);

  return (
    <div className="container-fluid m-sm-0 p-sm-0">
      {matchMedia && matchMedia.media && matchMedia.media === "xs" ? (
        <div
          className="bg-color-blue-base position-fixed w-100 color-white pt-3"
          style={{ left: 0, top: 0, zIndex: 0, height: "20vh" }}
        >
          <ButtonBack
            text="Volver"
            action={url ? () => history.push(url) : () => history.goBack()}
          />
        </div>
      ) : (
        <Header />
      )}

      <div
        className="row justify-content-start position-relative bg-color-white rounded-30"
        style={{ zIndex: 1, top: "10vh", height: "80vh", overflow: "auto" }}
      >
        {children}
        <div style={{ height: "150px" }} />
      </div>
    </div>
  );
};
export default ProfileTemplate;
