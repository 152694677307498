import instanceOvert from './api_overt';
import instanceAdmin from './api_admin';
import store from '../redux/store';

class ServicesDataObject {
  _setHeaders = () => {
    const state: any = store.getState();
    const { user } = state;
    if (user.isLogged) {
      instanceAdmin.defaults.headers.common['user'] = user.profile?.PK;
      instanceAdmin.defaults.headers.common['usersk'] = user.profile?.SK;
      instanceAdmin.defaults.headers.common['Authorization'] = user?.session?.AuthenticationResult?.AccessToken;
    } else {
      delete instanceAdmin.defaults.headers.common['user'];
      delete instanceAdmin.defaults.headers.common['usersk'];
      delete instanceAdmin.defaults.headers.common['Authorization'];
    }
  };
  list = async (is_company: boolean = false) => {
    this._setHeaders();
    try {
      let response: any = {};

      if (!is_company) {
        response = await instanceOvert.get(`/overt/service/list/all`);
      }

      if (is_company) {
        response = await instanceOvert.get('/overt/service/list/companies/all', {
          headers: {
            Authorization: store.getState().user?.session?.AuthenticationResult?.AccessToken,
          },
        });
      }

      return response.data;
    } catch (e: any) {
      console.log('LOGGER', e.message);
      return Promise.reject(e);
    }
  };

  selectList = async () => {
    this._setHeaders();
    try {
      const response = await instanceOvert.get('/overt/service/select');
      return response.data;
    } catch (e: any) {
      console.log('LOGGER', e.message);
      return Promise.reject(e);
    }
  };

  details = async (payload: any) => {
    this._setHeaders();
    try {
      const response = await instanceOvert.get(`/overt/service/read/${payload.PK}?PK=${payload.PK}&SK=${payload.SK}`);
      return response.data;
    } catch (e: any) {
      console.log('LOGGER', e.message);
      return Promise.reject(e);
    }
  };

  detailsWithRelatedProducts = async (payload: any) => {
    try {
      const response = await instanceOvert.get(`/overt/service/detail/${payload.PK}?PK=${payload.PK}&SK=${payload.service_PK}`);
      return response.data;
    } catch (e: any) {
      console.log('LOGGER', e.message);
    }
  };
}

const Services = new ServicesDataObject();
export default Services;
