import { handleActions } from 'redux-actions';
import { CLEAR_CACHE, SET_CACHE } from '../actions/cache';

export const INITIAL_STATE = {};

export default handleActions(
  {
    [SET_CACHE]: (_: any, action: any) => {
      return { ...action.payload };
    },
    [CLEAR_CACHE]: () => {
      return { ...{} };
    },
  },
  INITIAL_STATE
);
