const ButtonBack = (props: any) => {
  const { text, action, ...rest } = props

  return (
    <button className="btn-back-default" onClick={action} {...rest}>
      <div className="btn-back-default__icon bold">
        <div />
      </div>
      {text}
    </button>
  )
}

export default ButtonBack