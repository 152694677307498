export const TableHeader = [
    {
        label: 'Nombre',
        key: 'name',
        align: 'center',
        alignFlex: 'center',
        flexGrow: 1,
    },
    {
        label: 'Email',
        key: 'email',
        align: 'center',
        alignFlex: 'left',
        flexGrow: 1,
    },
    {
        label: 'Role',
        key: 'role',
        align: 'center',
        alignFlex: 'center',
        flexGrow: 1,
    },
    {
        label: 'Status',
        key: 'status',
        align: 'center',
        alignFlex: 'center',
        flexGrow: 1,
    }
]