import { createAction } from 'redux-actions'; 
 

export const SET_MESSAGE: any = createAction('SET_MESSAGE'); 
export const SAVE_CHANGES: any = createAction('SAVE_CHANGES'); 


export const send_message = (payload: any) => (dispatch: any) => {
    dispatch(SET_MESSAGE(payload));
    dispatch(SAVE_CHANGES());
    return payload;
}