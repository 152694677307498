/* eslint-disable jsx-a11y/anchor-is-valid */
import { SelectPicker } from "rsuite";
import ErrorValidation from "../../components/ErrorValidation";

const SelectManualCarData = (props: any) => {
  const { state, _handleChangeInput, _handleAddCar } = props

  const types = [
    {
      value: 1,
      label: 'Sedan',
      size: 0,
    },
    {
      value: 2,
      label: 'SUV',
      size: 1,
    },
    {
      value: 3,
      label: 'Van',
      size: 2,
    },
    {
      value: 4,
      label: 'Bus',
      size: 3,
    },

  ]
  return (
    <>
      <div className="row px-3 mb-3">
        <div className="col-12 color-black">
          <div className="mb-2">
            <b className="size-09">Ingresa la marca</b>
          </div>
          <input
            className="input-default rounded-30 py-3 px-4"
            onChange={_handleChangeInput}
            value={state.inputs.brand}
            name="brand"
            style={{ textTransform: 'uppercase' }}
            disabled={state.inputState.brand}
          />
          <ErrorValidation className="ps-4 size-08" validations={state.validations} name="brand" />
        </div>
      </div>

      <div className="row px-3 mb-3">
        <div className="col-12 color-black">
          <div className="mb-2">
            <b className="size-09">Ingresa el modelo</b>
          </div>
          <input
            className="input-default rounded-30 py-3 px-4"
            onChange={_handleChangeInput}
            value={state.inputs.model}
            name="model"
            style={{ textTransform: 'uppercase' }}
            disabled={state.inputState.model}
          />
          <ErrorValidation className="ps-4 size-08" validations={state.validations} name="model" />
        </div>
      </div>

      <div className="row px-3 mb-3">
        <div className="col-12 color-black">
          <div className="bold ps-2 mb-2">Ingresa el año</div>
          <input
            className="input-default rounded-30 py-3 px-4"
            onChange={_handleChangeInput}
            value={state.inputs.year}
            name="year"
            style={{ textTransform: 'uppercase' }}
            disabled={state.inputState.year}
          />
          <ErrorValidation className="ps-4 size-08" validations={state.validations} name="year" />
        </div>
      </div>

      <div className="row px-3 mb-3">
        <div className="col-12 color-black">
          <div className="mb-2">
            <b className="size-09">Selecciona el tipo</b>
          </div>
          <SelectPicker
            data={types}
            disabled={state.inputState.model}
            classPrefix="copec-select"
            cleanable={false}
            className="rs-copec-select-cleanable"
            placeholder="Selecciona el tipo"
            placement="auto"
            locale={state.locale}
            value={state.inputs.type}
            onChange={(e: any) => _handleChangeInput({ target: { name: 'type', value: e } })}
          />
          <ErrorValidation className="ps-4 size-08" validations={state.validations} name="type" />
        </div>
      </div>

      <div className="d-flex justify-content-center align-items-center m-4">
        <button className="btn-default w-100 p-3 size-11 rounded-30" onClick={_handleAddCar}>Agregar vehículo</button>
      </div>

    </>
  )
}

export default SelectManualCarData;