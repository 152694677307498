import Drawer from '../Drawer';

import RecommendedService from './RecommendedService';

const DrawerRecommendedService = (props: any) => {
  const { show, handleClose, id, service, setShowRecommendedButton } = props;
  
  return (
    <Drawer show={show} direction="bottom-top" className="drawer-with-header">
      <RecommendedService handleClose={handleClose} id={id} show={show} service={service} setShowRecommendedButton={setShowRecommendedButton} />
    </Drawer>
  );
};

export default DrawerRecommendedService;
