import { handleActions } from 'redux-actions';
import { CLEAR_ADMIN_USER, SET_ADMIN_USER } from '../actions/admin_user';

export const INITIAL_STATE = {
  isLogged: false,
  token: {},
  profile: {},
  created_at: undefined
};

export default handleActions({
  [SET_ADMIN_USER]: (state: any, action: any) => {
    return { ...state, ...action.payload }
  },
  [CLEAR_ADMIN_USER]: () => {
    return INITIAL_STATE
  }
}, INITIAL_STATE);