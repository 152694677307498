import { handleActions } from 'redux-actions';
import { SET_DIRECTIONS, CLEAR_DIRECTIONS } from '../actions/directions';

export const INITIAL_STATE = {
  _string: null,
  latitude: null,
  longitude: null,
  direction: null,
  observations: ''
};

export default handleActions({
  [SET_DIRECTIONS]: (state: any, action: any) => {
    return { ...state, ...action.payload };
  },
  [CLEAR_DIRECTIONS]: () => {
    return { _string: null, latitude: null, longitude: null, direction: null, observations: '' };
  }
}, INITIAL_STATE);