import { createAction } from 'redux-actions';

export const SET_SCHEDULE: any = createAction('SET_SCHEDULE');
export const DELETE_SCHEDULE: any = createAction('DELETE_SCHEDULE');
export const SAVE_STATE: any = createAction('SAVE_STATE');

export const set_schedule = (payload: any) => {
  return (dispatch: any) => {
    dispatch(SET_SCHEDULE(payload));
    dispatch(SAVE_STATE());
  }
}

export const delete_schedule = (payload: any) => {
  return (dispatch: any) => {
    dispatch(DELETE_SCHEDULE(payload));
    dispatch(SAVE_STATE());
  }
}
