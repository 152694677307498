/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import Images from '../../assets/image';
import Image from '../../components/Image';
import Title from '../../components/Title';
import { send_message } from '../../redux/actions/broadcast';
import '../../styles/components/component.keroseneinfo.scss';
import { useEffect } from 'react';
import { LOAD_OFF } from '../../redux/actions/loader';
const KeresoneInfo = () => {
  const dispatch: any = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(LOAD_OFF())
  },[]);

  const Price = (data: any) => {
    return (
      <span className="container-pricing">
        <span>$</span>
        <span>{data.value}</span>
      </span>
    );
  };

  const _openDirectionsModal = () => {
    try {
      dispatch(send_message({ open_directions: true }));
      history.push('/');
    } catch (e: any) {
      toast.error("No fue posible completar esta acción. Intente volver a la página principal y haga clic en el botón 'Ingresa tu dirección'");
    }
  };

  return (
    <>
      {/* Version Mobile  */}
      <div className="container-keroseneinfo d-lg-none">
        <Title size="md" text="¿Qué necesitas?" className="color-dark-blue text-center mt-3" />

        <div className="container-lubinfo__info mt-4">
          <div className="row align-items-start px-4 pb-3 size-09">
            <div className="col-1">
              <Image image={Images.BulletInfo} />
            </div>
            <div className="col-11">Un bidón Copec de 20 litros con certificado SEC.</div>
          </div>

          <div className="row align-items-start px-4 pb-3 size-09">
            <div className="col-1 ">
              <Image image={Images.BulletInfo} />
            </div>
            <div className="col-11">Debe incluir su tapa para asegurar su hermeticidad.</div>
          </div>

          <div className="row align-items-start px-4 pb-3 size-09">
            <div className="col-1 ">
              <Image image={Images.BulletInfo} />
            </div>
            <div className="col-11">Debe estar completamente limpio, sin líquidos ni material particulado.</div>
          </div>

          <div className="row align-items-start px-4 pb-3 size-09">
            <div className="col-1 ">
              <Image image={Images.BulletInfo} />
            </div>
            <div className="col-11">Exige tu sello de seguridad al momento de la entrega del bidón.</div>
          </div>
        </div>

        {/* <Title size="md" text="¿Aún no tienes bidón?" className="color-dark-blue text-center mt-5 mb-2" /> */}

        {/* <div className="row px-1 pb-4 container-keroseneinfo__info">
          <div className="col-12 size-09 mb-4 text-center">
            Aprovecha el descuento y cómpralo a solo <Price value="11.990" />
          </div>
        </div> */}

        <div className="row">
          <div className="col-12 px-4">
            <iframe
              height={300}
              src={process.env.REACT_APP_KEROSENE_VIDEO}
              title="Cambio de aceite"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
              className="rounded-10 shadow mt-4 w-100"
            ></iframe>
          </div>
        </div>

        <div className="row my-4">
          <div className="col-12 text-center px-5">
            <button className="btn-default py-2 px-5 size-12 rounded-40" onClick={_openDirectionsModal}>
              Pide Aquí
            </button>
          </div>
        </div>
      </div>

      {/* Desktop Version  */}

      <div className="container-fluid d-none d-lg-block mx-5 pt-3" style={{ minHeight: '60vh' }}>
        <h1 className="text-start p-0 mt-3 bold-300 color-dark-blue">Reparto de Kerosene</h1>
        <div className="row px-5 mx-5">
          <div className="col-6 mb-5">
            <div className="row size-11">
              <div className="col-12">
                <Title size="md" text="¿Qué necesitas?" className="color-dark-blue mt-3 size-12" />
              </div>

              <div className="col-12 size-09">
                <div className="row color-black my-3">
                  <div className="col-11">
                    <Image image={Images.BulletInfo} className="me-3" />
                    Un bidón Copec de 20 litros con certificado SEC.
                  </div>
                </div>

                <div className="row color-black my-3">
                  <div className="col-11">
                    <Image image={Images.BulletInfo} className="me-3" />
                    Debe incluir su tapa para asegurar su hermeticidad.
                  </div>
                </div>

                <div className="row color-black my-3">
                  <div className="col-11">
                    <Image image={Images.BulletInfo} className="me-3" />
                    Debe estar completamente limpio, sin líquidos ni material particulado.
                  </div>
                </div>

                <div className="row color-black my-3">
                  <div className="col-11">
                    <Image image={Images.BulletInfo} className="me-3" />
                    Exige tu sello de seguridad al momento de la entrega del bidón.
                  </div>
                </div>
              </div>

              <div className="col-12 size-09">
                <Title size="md" text="¿Aún no tienes bidón?" className="color-dark-blue size-12 my-3" />
                {/* <div className="color-black my-3">Aprovecha el descuento y cómpralo con un 20% de descuento.</div> */}

                <div className="color-black my-3">* Recuerda que el bidón que entregues será reutilizado en el futuro, por lo que te pedimos el mayor cuidado posible.</div>
              </div>
            </div>
          </div>

          <div className="col-6 d-flex justify-content-center align-items-start">
            <iframe
              width="500"
              height="300"
              src={process.env.REACT_APP_KEROSENE_VIDEO}
              title="Lavamax"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
              className="rounded-10 shadow mt-4"
            ></iframe>
          </div>
        </div>

        <div className="row mb-4">
          <div className="col-12 text-center px-5">
            <button className="btn-default py-2 px-5 size-12 rounded-40" onClick={_openDirectionsModal}>
              Pide Aquí
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default KeresoneInfo;
