/* eslint-disable react-hooks/exhaustive-deps */
import { Loader } from '@googlemaps/js-api-loader';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import Images from '../../assets/image';
import { _containsLatLan } from '../../helpers/check_polygon';
import { set_eds } from '../../redux/actions/available_eds';
import { setDirections } from '../../redux/actions/directions';
import { clear_services } from '../../redux/actions/services';
import { clear_cart } from '../../redux/actions/shopping_cart';
import { set_splash } from '../../redux/actions/splash_screen';
import Image from '../Image';
import Title from '../Title';

interface IScript {
  handleClose: any;
  show: boolean;
}

const INITIAL_STATE = {
  inputs: {
    location: '',
  },
};
const Script: React.FC<IScript> = (props) => {
  const { handleClose, show } = props;
  const [state, setState] = useState(JSON.parse(JSON.stringify(INITIAL_STATE)));
  const dispatch = useDispatch();
  const history = useHistory();
  const mapRef = useRef<any>(null);
  const { matchMedia } = useSelector((state: any) => state);

  useEffect(() => {
    if (!state.google && show) {
      const loader: any = new Loader({
        apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY ? process.env.REACT_APP_GOOGLE_MAPS_API_KEY : '',
        version: 'weekly',
        libraries: ['places', 'geometry'],
        ...{},
      });

      loader.load().then((google: any) => {
        setState({ ...state, google, api_key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY });
        _getGeolocation(google);
      });
    }
  }, [show]);

  const _getGeolocation = (google: any) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((event) => _handleGeolocation(event, google));
    } else {
      toast.error('Tu navegador no soporta geolocalización');
    }
  };

  const _handleGeolocation = (geo: any, google: any) => {
    const { coords } = geo;
    const { latitude, longitude } = coords;
    try {
      const location = new google.maps.LatLng(latitude, longitude);
      const geocoder = new google.maps.Geocoder();

      const map = new google.maps.Map(mapRef.current, {
        center: location,
        zoom: 15,
        disableDefaultUI: true,
        zoomControl: true,
        fullscreenControl: true,
      });

      new google.maps.Marker({
        position: location,
        map: map,
      });

      geocoder.geocode({ location: location }, async (response: any, status: any) => {
        const location = response.length > 0 ? response[0].formatted_address : '';
        if (status === 'OK') {
          setState({ ...state, inputs: { location }, latitude, longitude });
        } else {
          toast.error('No se pudo encontrar ningún servicio en esta dirección');
          if (matchMedia.media === 'xs') {
            dispatch(set_splash({ direction: false }));
          } else {
            dispatch(set_splash({ direction_desktop: false }));
          }
        }
      });
    } catch (e) {
      if (matchMedia.media === 'xs') {
        dispatch(set_splash({ direction: false }));
      } else {
        dispatch(set_splash({ direction_desktop: false }));
      }
    }
  };

  const _handleSearchServices = async () => {
    handleClose();
    if (matchMedia.media === 'xs') {
      dispatch(set_splash({ direction: true }));
    } else {
      dispatch(set_splash({ direction_desktop: true }));
    }
    try {
      const availble_eds = await _containsLatLan(state.latitude, state.longitude);

      if (!availble_eds) {
        dispatch(set_splash({ direction: false, direction_desktop: false }));
        return;
      }

      dispatch(set_eds(availble_eds));
      setTimeout(() => {
        dispatch(
          setDirections({
            _string: state.inputs.location,
            direction: state.inputs.location,
            longitude: state.longitude,
            latitude: state.latitude,
          })
        );
        dispatch(clear_cart());
        dispatch(clear_services());
        dispatch(set_splash({ direction: false, direction_desktop: false }));
        history.push('/');
      }, 1000);
    } catch (e: any) {
      toast.error('No se pudo encontrar ningún servicio en esta dirección');
      if (matchMedia.media === 'xs') {
        dispatch(set_splash({ direction: false }));
      } else {
        dispatch(set_splash({ direction_desktop: false }));
      }
    }
  };

  const _handleClearDirections = () => {
    handleClose();
  };

  return (
    <div className="container-address__script">
      <div className="container-address__script__header mb-4">
        <Title size="md" text="Ubicación actual" className="color-dark-blue px-3 pt-3 text-center" />
        <div className="container-address__script__header__close" onClick={_handleClearDirections}>
          <button className="btn">
            <Image image={Images.CloseBlue} />
          </button>
        </div>
      </div>

      <div className="row position-relative ">
        <div className="col-12">
          <input type="text" className="input-default black icon-left py-3 size-09" defaultValue={state.inputs?.location} disabled />
          <i className="bi bi-geo-alt input-left-center" />
        </div>
      </div>

      <div className="row mt-4 justify-content-center">
        <div id="map" ref={mapRef} />
      </div>

      <div className="container-address__script__controls">
        <div className="col-8">
          <button type="button" className="btn-default w-100 size-11 py-3" onClick={_handleSearchServices}>
            {' '}
            Buscar servicios
          </button>
        </div>
      </div>
    </div>
  );
};

export default Script;
