export default interface ICrossSellingSetup {
  PK: any;
  SK: any;
  is_available: boolean;
  percentage: Number;
  description: String;
  info: any;
}

export const CrossSellingSetupSchema: ICrossSellingSetup = {
  PK: 'setup',
  SK: 'setup',
  is_available: false,
  percentage: 10,
  description: 'Descuento por venta cruzada',
  info: {},
};
