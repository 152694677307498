import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Images from '../../assets/image';
import ButtonBack from '../../components/ButtonBack';
import Cupon from '../../components/Cupon';
import Image from '../../components/Image';
import Title from '../../components/Title';
import CheckoutServiceItem from '../../components/CheckoutServiceItem';
import CheckoutProductItem from '../../components/CheckoutProductItem';

const CheckoutDesktop = (props: any) => {
  const { _handleDeleteService, _handlePlusProduct, _handleMinusProduct, _handlePlusService, _handleMinusService, _handleDeleteProduct, _handleNext } = props;
  const history = useHistory();
  const { shopping_cart } = useSelector((state: any) => state);
  return (
    <div className="container-fluid d-none d-sm-block  bg-color-blue-light">
      <div className="row justify-content-between px-3 pb-2 pt-4">
        <div className="col-5">
          <ButtonBack text="Volver" action={() => history.goBack()} />
        </div>
        <div className="col">
          <Title size="xxl" text="Carro de compra" className="color-dark-blue" />
        </div>
      </div>

      <div className="row px-5 mb-5">
        {/* Loop products */}
        <div className="col-9 px-5">
          {shopping_cart.services && shopping_cart.services.length === 0 && (
            <div className="row mb-5 mt-5 text-center">
              <div>
                <Image image={Images.EmptyCart} className="w-50" />
              </div>
              <Title text="Carro vacío!" className="text-center color-blue-base " />
            </div>
          )}
          {shopping_cart.services &&
            shopping_cart.services.map((service: any, index: number) => (
              <Fragment key={`service-${index}`}>
                <div className="row">
                  <div className="col-12 p-3 mt-2 bold color-dark-blue">Servicio {service?.name}</div>
                </div>
                {service.service ? (
                  <CheckoutServiceItem service={service} _handlePlusService={_handlePlusService} _handleMinusService={_handleMinusService} _handleDeleteService={_handleDeleteService} />
                ) : null}

                {/* Loop related products */}

                {service.related_products &&
                  service.related_products.map((rp: any) => (
                    <CheckoutProductItem service={service} product={rp} _handlePlusProduct={_handlePlusProduct} _handleMinusProduct={_handleMinusProduct} _handleDeleteProduct={_handleDeleteProduct} />
                  ))}
              </Fragment>
            ))}
        </div>

        <div className="col-3">
          <div className="row">
            <div className="col-12 p-3 mt-2 bold color-dark-blue">Cupón de descuento</div>
          </div>

          <div className="row py-4 bg-white rounded-15 border-default">
            <div className="col-12 p-4">
              <Cupon rounded />
            </div>
          </div>

          <div className="row">
            <div className="col-12 p-3 mt-2 bold color-dark-blue">Resumen de compra</div>
          </div>

          <div className="row bg-color-white border-default rounded-15 px-3 pb-5 pt-3" style={{ minHeight: '20vh' }}>
            <div className="col-12">
              {shopping_cart.services.length > 0 && (
                <>
                  <div className="row px-2 py-3 color-black">
                    <div className="col bold-300">Subtotal</div>
                    <div className="col text-end bold">${shopping_cart.total_without_discount?.toLocaleString('es-CL')}</div>
                  </div>

                  <div className="row px-2 py-3 color-black">
                    <div className="col bold-300">Descuento</div>
                    <div className="col text-end bold">-${shopping_cart.discount?.toLocaleString('es-CL')}</div>
                    {shopping_cart.discountRules && shopping_cart.discountRules?.discount > 0 ? (
                      <>
                        {shopping_cart.discountRules.type === 'by_day' && shopping_cart.cupon?.campaign?.setup[0]?.apply === 'sobre_envio' ? null : (
                          <div className="col-12 size-08 ms-1 bold-300 color-grey mt-2">
                            <Image image={Images.Discount} className="me-2" style={{ width: '20px' }} />
                            {shopping_cart.discountRules?.description}
                          </div>
                        )}
                      </>
                    ) : null}
                    {shopping_cart.cupon && (
                      <div className="col-12 size-08 ms-1 bold-300 color-grey mt-2">
                        <Image image={Images.Discount} className="me-2" style={{ width: '20px' }} />
                        {shopping_cart.setup?.name}
                      </div>
                    )}
                    {shopping_cart.deliveryRules && shopping_cart.deliveryRules?.discount > 0 ? (
                      <>
                        <div className="col-12 size-08 ms-1 bold-300 color-grey mt-2">
                          <Image image={Images.Discount} className="me-2" style={{ width: '20px' }} />
                          {shopping_cart.deliveryRules?.description}
                        </div>
                      </>
                    ) : null}
                  </div>

                  <div className="row px-2 py-3 color-black">
                    <div className="col bold-300">Despacho</div>
                    <div className="col text-end bold">${shopping_cart.delivery?.toLocaleString('es-CL')}</div>
                  </div>

                  {shopping_cart.fullPointsCopec && shopping_cart.fullPointsCopec > 0 ? (
                    <div className="row px-2 py-3 color-black">
                      <div className="col-8 bold-300">Puntos Full Acumulados</div>
                      <div className="col-4 text-end bold size-11">{shopping_cart.fullPointsCopec}</div>
                    </div>
                  ) : null}

                  <div className="row px-2 py-3 mt-4 color-blue-base">
                    <div className="col-8 bold">TOTAL A PAGAR</div>
                    <div className="col-4 text-end bold size-11">${(shopping_cart.total + shopping_cart.delivery)?.toLocaleString('es-CL')}</div>
                  </div>



                  <div className="row mt-4 px-3 color-white">
                    <button className="btn-default p-3 size-11 rounded-10" onClick={_handleNext}>
                      Continuar
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckoutDesktop;
