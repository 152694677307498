import * as yup from 'yup';

export const EmailSchema = yup.object().shape({
  email: yup
    .string()
    .matches(/^[\w+.]+@\w{2,}\.\w{2,}(?:\.\w{2,})?$/, 'Formato de email no es válido')
    .required('Este campo es requerido'),
});

export type EmailType = yup.InferType<typeof EmailSchema>;
