/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useEffect, useState } from 'react';
import { v4 } from 'uuid';
import Images from '../../assets/image';
import Image from '../../components/Image';
import Title from '../../components/Title';
import ServiceInfoTemplate from '../../template/ServiceInfoTemplate';
import { ReactComponent as Check } from '../../assets/image/svg/check.svg';

const RenderInfo = (props: any) => {
  const { state, handleBack, onContinue } = props;
  const [descripcion, setDescripcion] = useState<any>([]);

  useEffect(() => {
    const descripcion = Array.isArray(state.service?.descripcion) ? state.service?.descripcion : state.service?.descripcion.split('<br>');
    setDescripcion(descripcion);
  }, [props.state]);
  return (
    <>
      <div className="d-sm-none">
        <ServiceInfoTemplate noFooter headerImage={Images.CardLub} headerService={state.service?.name} actionBack={() => handleBack('info')}>
          {/* Mobile Version  */}
          <div className="render-info-service-detalle line-border">
            <div className="row m-3 mt-5">
              <div className="col-3 d-flex justify-content-center align-items-center flex-column m-0 p-0">
                {state.service && (
                  <>
                    <Image className="w-100" image={state.service?.info?.gallery?.url ? { default: { alt: '', src: state.service?.info?.gallery?.url } } : Images.NotFound} />
                    <div className="size-14 bold color-dark-blue">
                      <span className="color-green-base">$</span>
                      {state?.service?.price?.toLocaleString('pt-BR')}
                    </div>
                  </>
                )}
              </div>
              <div className="col-9 size-11">
                <div className="col-12  bold-300 color-dark-blue">Detalle del servicio</div>
                {Array.isArray(descripcion) ? (
                  <>
                    {descripcion.map((item: any) => (
                      <div className="mb-2 " key={v4()}>
                        <Check className="color-green-base me-1" style={{ width: '13px' }} />
                        <span className="size-09">{item}</span>
                      </div>
                    ))}
                  </>
                ) : (
                  <div className="mb-2 ">
                    <Check className="color-green-base me-1" style={{ width: '13px' }} />
                    <span className="size-09">{descripcion}</span>
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* <Title size="md" text="Cobertura" className="d-flex w-100 justify-content-center mt-4 mb-2 color-dark-blue bold" />

          <div className="row line-border pb-4">
            <div className="col-12 mx-3 size-09 color-black">* Servicio disponible solo en las comunas de</div>
            <div className="col-12 mx-3 size-09 color-blue-base bold">{state.service?.covering}</div>
          </div>

          <Title size="md" text="Horario de atención" className="d-flex w-100 justify-content-center mt-4 mb-2 color-dark-blue bold" />

          <div className="row line-border pb-4 mb-5">
            <div className="col-12 mx-3 size-09 color-black">{state.service?.opening_hours?.days}</div>
            <div className="col-12 mx-3 size-09 color-blue-base bold">{state.service?.opening_hours?.hours}</div>
          </div> */}

          <div className="row px-3 mb-5 mt-5">
            <div className="col-12">
              <button className="btn-default w-100 p-3 size-12 rounded-40" onClick={() => onContinue('info', 'datepicker')}>
                Pide AQUÍ
              </button>
            </div>
          </div>
        </ServiceInfoTemplate>
      </div>

      {/* Desktop Version  */}
      <div className="d-none d-sm-block">
        <ServiceInfoTemplate headerImage={Images.CardLub} headerService={state.service?.name} actionBack={() => handleBack('info')}>
          <div className="row justify-content-center my-5" style={{minHeight: '45vh'}}>
            <div className="col-4 px-4">
              {state.service && (
                <div className="row">
                  <div className="col-12 text-center">
                    <Image image={state.service?.info?.gallery?.url ? { default: { alt: '', src: state.service?.info?.gallery?.url } } : Images.NotFound} style={{ maxWidth: '300px' }} />
                    <div className="bold color-dark-blue text-center" style={{ fontSize: '3em' }}>
                      <span className="color-green-base ">$</span>
                      {state.service?.price?.toLocaleString('pt-BR')}
                    </div>
                  </div>
                </div>
              )}

              <div className="row">
                <div className="col-12 text-center color-white">
                  <button className="btn-default w-100 p-2 size-12 rounded-40" style={{maxWidth: '250px'}} onClick={() => onContinue('info', 'datepicker')}>
                    Pide AQUÍ
                  </button>
                </div>
              </div>
            </div>

            <div className="col-6 px-4">
              <div className="row">
                <Title size="md" text="Detalle del servicio" className="d-flex w-100 justify-content-start my-4 color-dark-blue bold" />
              </div>
              <div className="row h-100 align-items-start justify-content-between">
                <div className="col-12">
                  {Array.isArray(descripcion) ? (
                    <>
                      {descripcion.map((item: any) => (
                        <div className="mb-2" key={v4()}>
                          <Image image={Images.BulletInfo} className="me-3" />
                          <span className="size-09">{item}</span>
                        </div>
                      ))}
                    </>
                  ) : (
                    <div className="mb-2">
                      <Image image={Images.BulletInfo} className="me-3" />
                      <span className="size-09">{descripcion}</span>
                    </div>
                  )}
                </div>

                {/* <div className="col-12">
                  <Title size="md" text="Cobertura" className="color-dark-blue bold" />

                  <div className="row">
                    <div className="col-12 color-black">
                      * Servicio disponible solo en las comunas de <span className="color-blue-base bold">{state.service?.covering}</span>
                    </div>
                  </div>
                </div>

                <div className="col-12">
                  <Title size="md" text="Horario de atención" className="color-dark-blue bold" />

                  <div className="row">
                    <div className="col-12 color-black">
                      {state.service?.opening_hours?.days} <span className="color-blue-base bold">{state.service?.opening_hours?.hours}</span>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </ServiceInfoTemplate>
      </div>
    </>
  );
};

export default RenderInfo;
