/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { v4 } from 'uuid';
import Images from '../../assets/image';
import Image from '../../components/Image';
import Title from '../../components/Title';
import ServiceInfoTemplate from '../../template/ServiceInfoTemplate';

const RenderInfo = (props: any) => {
  const { state, handleBack, onContinue } = props;
  const params: any = useParams();
  const { available_services } = useSelector((state: any) => state);
  const CARS = [Images.Car1, Images.Car2, Images.Car3, Images.Car4];
  const [descripcion, setDescripcion] = useState<any>([]);

  const SIZE = ['Pequeño', 'Mediano', 'Grande', 'Extra Grande'];

  const [pricing, setPricing] = useState<any>([]);

  useEffect(() => {
    const service = available_services.find((item: any) => item?.info?.slug === params.slug);
    const services = service?.sub_services?.filter((item: any) => item.name?.trim().toLowerCase() === state?.service?.name?.trim().toLowerCase());
    const descripcion = Array.isArray(state.service?.descripcion) ? state.service?.descripcion : state.service?.descripcion.split('<br>');
    setDescripcion(descripcion);

    if (services) {
      services.sort((a: any, b: any) => {
        if (a.price > b.price) return 1;
        if (a.price < b.price) return -1;
        return 0;
      });
      setPricing(services);
    }
  }, [props]);

  return (
    <>
      <div className="d-sm-none">
        <ServiceInfoTemplate noFooter headerImage={Images.CardLavamax} headerService={state.service?.name} actionBack={() => handleBack('info')}>
          <Title size="md" text="Detalle del servicio" className="d-flex w-100 justify-content-center my-4 color-dark-blue bold" />

          <div className="render-info-service-detalle">
            <div className="row align-items-center px-4 pb-3 size-11" key={v4()}>
              <div className="col-12">
                {Array.isArray(descripcion) ? (
                  <>
                    {descripcion.map((item: any) => (
                      <div className="mb-2" key={v4()}>
                        <Image image={Images.BulletInfo} className="me-3" />
                        <span className="size-09">{item}</span>
                      </div>
                    ))}
                  </>
                ) : (
                  <div className="mb-2">
                    <Image image={Images.BulletInfo} className="me-3" />
                    <span className="size-09">{descripcion}</span>
                  </div>
                )}
              </div>
            </div>
          </div>

          <Title size="md" text="Valores Vehículo" className="d-flex w-100 justify-content-center my-4 color-dark-blue bold" />

          {pricing.map((price: any, index: number) => (
            <div className="row mx-0 w-100 justify-content-center px-4 mb-3" key={v4()}>
              <div className="col-2">
                <Image image={CARS[index]} />
              </div>
              <div className="col-10">
                <div className="size-09 mb-1">{`${price.name} - ${SIZE[index]}`}</div>
                <div>
                  <span className="color-green-base bold size-11">$</span>
                  <span className="color-dark-blue bold size-11">{price.price?.toLocaleString('pt-BR')}</span>
                </div>
              </div>
            </div>
          ))}

          <div className="row mx-3 mt-5" style={{ marginBottom: '100px' }}>
            <div className="col-12">
              <button className="btn-default w-100 p-3 size-12 rounded-40" onClick={() => onContinue('info', 'datepicker')}>
                Pide AQUÍ
              </button>
            </div>
          </div>
        </ServiceInfoTemplate>
      </div>

      {/* Version Desktop  */}
      <div className="d-none d-sm-block">
        <ServiceInfoTemplate headerImage={Images.CardLavamax} headerService={state.service?.name} actionBack={() => handleBack('info')}>
          <div className="row px-5 mx-5">
            <div className="col-6 my-5 px-5">
              <div className="row size-13">
                <div className="col-12">
                  <Title size="md" text="Detalle del servicio" className="color-dark-blue mt-5 size-12" />
                </div>

                <div className="col-12">
                  <div className="row color-black my-3">
                    <div className="col-12">
                      {Array.isArray(descripcion) ? (
                        <>
                          {descripcion.map((item: any) => (
                            <div className="mb-2" key={v4()}>
                              <Image image={Images.BulletInfo} className="me-3" />
                              <span className="size-09">{item}</span>
                            </div>
                          ))}
                        </>
                      ) : (
                        <div className="mb-2">
                          <Image image={Images.BulletInfo} className="me-3" />
                          <span className="size-09">{descripcion}</span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-6 my-5">
              <div className="row size-11">
                <div className="col-12">
                  <Title size="md" text="Valores Vehículo" className="d-flex w-100 color-dark-blue bold mt-5 mb-3 size-12" />
                </div>
                {pricing.map((price: any, index: number) => (
                  <div className="row justify-content-center align-items-center px-4 mb-3" key={v4()}>
                    <div className="col-2">
                      <Image image={CARS[index]} className="w-75" />
                    </div>
                    <div className="col-10">
                      <div className="bold-300 mb-1">{`${price.name} - ${SIZE[index]}`}</div>
                      <div>
                        <span className="color-green-base bold size-11">$</span>
                        <span className="color-dark-blue bold size-11">{price.price?.toLocaleString('pt-BR')}</span>
                      </div>
                    </div>
                  </div>
                ))}
                <div className="row my-3">
                  <div className="col-12 text-center color-white">
                    <button className="btn-default w-50 p-2 size-12 rounded-40" onClick={() => onContinue('info', 'datepicker')}>
                      Pide AQUÍ
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ServiceInfoTemplate>
      </div>
    </>
  );
};

export default RenderInfo;
