/* eslint-disable no-useless-escape */
import * as yup from 'yup';

export const CarSchema = yup.object().shape({
  plate: yup
    .string()
    .matches(/^[a-z]{2}[a-z0-9]{2}[0-9]{2}$/gim, 'Patente inválida.')
    .min(6, 'Debes ingresar una patente válida'),
  brand: yup
    .string()
    .min(1, 'Este campo es requerido')
    .matches(/^[\w\. ?¿¡!^\+-\:()\{\}\[\],;\*#@áàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ]*$/gim, 'Este campo contiene caracteres inválidos')
    .nullable()
    .required('Este campo es requerido'),
  model: yup
    .string()
    .min(1, 'Este campo es requerido')
    .matches(/^[\w\. ?¿¡!^\+-\:()\{\}\[\],;\*#@áàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ]*$/gim, 'Este campo contiene caracteres inválidos')
    .nullable()
    .required('Este campo es requerido'),
  year: yup.string().min(4, 'Este campo es requerido').required('Este campo es requerido'),
});

export const CarWithTypeSchema = yup.object().shape({
  plate: yup
    .string()
    .matches(/^[a-z]{2}[a-z0-9]{2}[0-9]{2}$/gim, 'Patente inválida.')
    .min(6, 'Debes ingresar una patente válida'),
  brand: yup
    .string()
    .matches(/^[\w\. ?¿¡!^\+-\:()\{\}\[\],;\*#@áàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ]*$/gim, 'Este campo contiene caracteres inválidos')
    .min(1, 'Este campo es requerido')
    .nullable()
    .required('Este campo es requerido'),
  model: yup
    .string()
    .matches(/^[\w\. ?¿¡!^\+-\:()\{\}\[\],;\*#@áàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ]*$/gim, 'Este campo contiene caracteres inválidos')
    .min(1, 'Este campo es requerido')
    .nullable()
    .required('Este campo es requerido'),
  type: yup
    .string()
    .matches(/^[\w\. ?¿¡!^\+-\:()\{\}\[\],;\*#@áàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ]*$/gim, 'Este campo contiene caracteres inválidos')
    .min(1, 'Este campo es requerido')
    .nullable()
    .required('Este campo es requerido'),
  year: yup.string().min(4, 'Este campo es requerido').required('Este campo es requerido'),
});

export const PlateSchema = yup.object().shape({
  plate: yup
    .string()
    .matches(/^[a-z]{2}[a-z0-9]{2}[0-9]{2}$/gim, 'Patente inválida.')
    .min(6, 'Debes ingresar una patente válida'),
});

export type CarType = yup.InferType<typeof CarSchema>;
