/* eslint-disable no-useless-escape */
import * as yup from 'yup';

export const RateOrderSchema = yup.object().shape({
  descripcion: yup
    .string()
    .matches(/^[\w\. ?¿¡!^\+-\:()\{\}\[\],;\*#@áàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ]*$/gi, 'Este campo contiene caracteres inválidos')
    .max(1000, "Este campo acepta un máximo de 1000 caracteres")
    .required('Este campo es requerido'),
});

export type RateOrderType = yup.InferType<typeof RateOrderSchema>;
