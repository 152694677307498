import store from '../redux/store';
import instance from './api_overt';
class ProductService {
  _setHeaders = () => {
    const state: any = store.getState();
    const { user } = state;
    if (user.isLogged) {
      instance.defaults.headers.common['user'] = user.profile?.PK;
      instance.defaults.headers.common['usersk'] = user.profile?.SK;
      instance.defaults.headers.common['Authorization'] = user?.session?.AuthenticationResult?.AccessToken;
    } else {
      delete instance.defaults.headers.common['user'];
      delete instance.defaults.headers.common['usersk'];
      delete instance.defaults.headers.common['Authorization'];
    }
  };

  read = async (PK: any, SK: any) => {
    this._setHeaders();
    try {
      const response: any = await instance.get(`/overt/product/read/${PK}?PK=${PK}&SK=${SK}`);

      if (response.data.status === 'error') {
        throw new Error(response.data.message);
      }
      return response?.data;
    } catch (e) {
      return Promise.reject(e);
    }
  };
}

export default new ProductService();
