import { createAction } from 'redux-actions';
import store from '../store';
import { UPDATE_SHOPPINGCART } from './shopping_cart';

export const GET_CROSS_SELLING: any = createAction('GET_CROSS_SELLING');
export const SET_CROSS_SELLING: any = createAction('SET_CROSS_SELLING');
export const DELETE_CROSS_SELLING: any = createAction('DELETE_CROSS_SELLING');
export const SAVE_STATE: any = createAction('SAVE_STATE');

export const set_cross_selling = (payload: any) => {
  const { shopping_cart } = store.getState();
  return (dispatch: any) => {
    dispatch(SET_CROSS_SELLING(payload));
    dispatch(UPDATE_SHOPPINGCART({ ...shopping_cart, cross_selling: payload }));
    dispatch(SAVE_STATE());
  };
};

export const delete_cross_selling = () => {
  const { shopping_cart } = store.getState();
  shopping_cart.cross_selling = null;
  return (dispatch: any) => {
    dispatch(SET_CROSS_SELLING());
    dispatch(UPDATE_SHOPPINGCART({ ...shopping_cart }));
    dispatch(SAVE_STATE());
  };
};
