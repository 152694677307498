import moment from 'moment';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Button, Calendar } from 'rsuite';
import { calendarSpanishlocale } from '../../helpers/locale';
import HourItem from '../../pages/OrderHistory/HourItem';
import { LOAD_OFF, LOAD_ON } from '../../redux/actions/loader';
import Schedule from '../../services/schedule.service';

const Reschedule = (props: any) => {
  const [state, setState] = useState<any>({
    schedule: { open: false, step: 1, day: moment().toDate() },
    availableHours: [],
  });

  const dispatch = useDispatch();

  const _handleOnChangeDate = (data: any) => {
    setState({ ...state, schedule: { ...state.schedule, day: moment(data).toDate() } });
  };

  const _handleOnChangeHour = (id: any) => {
    try {
      const newChecked = state.availableHours.find((h: any) => h.PK === id);
      const checked = state.availableHours.find((h: any) => h.checked === true);
      newChecked.checked = true;
      if (checked) {
        checked.checked = false;
      }
      setState({ ...state });
    } catch (e: any) {
      console.log('LOGGER', e);
    }
  };

  const _handleSchedule = async () => {
    if (state.schedule.step === 1) {
      const params: any = {
        eds_id: props.order.eds_id,
        service_id: props.order.parent_service_id,
        date: moment(state.schedule.day).format('YYYY-MM-DD'),
      };

      try {
        dispatch(LOAD_ON());
        const response = await Schedule.getAvailableTime(params);
        const { blocks } = response.data.schedule;

        blocks.map((item: any, index: any) => {
          item.PK = index;
          const _ruleMoment = moment(item.date + ' ' + item.time);
          const today = moment();
          let status = false;

          _ruleMoment.subtract(1, 'hours');

          if (_ruleMoment.isAfter(today)) {
            status = true;
          }

          item.disabled = item.enable && status ? false : true;

          return item;
        });

        setState({ ...state, schedule: { ...state.schedule, step: 2 }, availableHours: blocks });
      } catch (e: any) {
        console.log('LOGGER', e.message);
        toast.error('No fue posible cargar las horas');
        dispatch(LOAD_OFF());
      }
      dispatch(LOAD_OFF());
    }

    if (state.schedule.step === 2) {
      const hour = state.availableHours.find((h: any) => h.checked === true);
      if (hour) {
        const schedule_at = moment(`${hour.date} ${hour.time}`).toString();
        dispatch(LOAD_ON());
        try {
          const params: any = {
            schedule_at,
            order_id: props.order.PK,
            PK: props.order?.reservation?.PK,
            SK: props.order?.reservation?.SK,
            info: { note: 'Reagendamiento' },
          };

          await Schedule.reSchedule({ PK: props.order?.reservation?.PK, SK: props.order?.reservation?.SK }, params);
          props.handleSuccess();
          toast.success('Servicio reprogramado con éxito');
        } catch (e: any) {
          console.log('LOGGER', e.message);
          toast.error('No fue posible reagendar tu entrega. Favor intentar con otra hora.');
          dispatch(LOAD_OFF());
        }
      } else {
        toast.warning('Elige una hora antes de continuar');
      }
    }
  };

  return (
    <div className="container-fluid">
      {state.schedule.step === 1 && <Calendar compact bordered locale={calendarSpanishlocale} value={state.schedule.day} onChange={_handleOnChangeDate} />}

      {state.schedule.step === 2 && (
        <>
          {state.availableHours &&
            state.availableHours.map((hour: any) => (
              <HourItem
                checked={hour.checked}
                day={state.schedule.day}
                hour={hour.time}
                discount={hour.discount}
                disabled={hour.disabled}
                onChange={() => _handleOnChangeHour(hour.PK)}
                key={hour.PK}
              />
            ))}
        </>
      )}

      <div className="row mt-4">
        <div className="col-12 text-end color-white">
          <Button className="me-5" onClick={() => setState({ ...state, schedule: { ...state.schedule, step: 1 } })} appearance="subtle" disabled={state.schedule.step === 1}>
            Volver
          </Button>
          <Button onClick={_handleSchedule} appearance="primary">
            {state.schedule.step === 1 ? 'Continuar' : 'Guardar'}
          </Button>
        </div>
      </div>
    </div>
  );
};
export default Reschedule;
