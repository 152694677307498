/* eslint-disable react-hooks/exhaustive-deps */
import Image from '../Image';
import '../../styles/components/component.card-lub.scss';
import Images from '../../assets/image';
import './style.scss';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

const CardSubscription = (props: any) => {
  const { image, text, handleAction, handleDetail, price, data, product, imageFull, fullpoints } = props;
  const { shopping_cart } = useSelector((state: any) => state);
  const [productData, setProductData] = useState<any>({});

  useEffect(() => {
    const service: any = shopping_cart.services.find((s: any) => s.PK === data.PK && s.service?.SK === data.SK);
    if(service) {
      setProductData(service);
    } else {
      setProductData({});
    }
  }, [shopping_cart]);

  const _handleOnClick = () => {
    if (handleAction) {
      handleAction();
    } else {
      console.log('[INFO]', 'Everything ok here ;)...');
    }
  };

  return (
    <div className="card-lavamax">
      <div className={`card-lavamax__image ${imageFull ? 'image-full' : ''}`}>
        <Image image={image || Images.NotFound} />
      </div>
      <div className="card-lavamax__content row">
        <div className="size-12 color-white bold-300">{text}</div>

        {fullpoints && data?.info?.points_per_division && parseInt(data?.info?.points_per_division) ? (
          <div className="d-flex color-white align-items-center size-09 my-2">
            <Image image={Images.CopecFull} className="me-2" style={{ width: '50px' }} />
            Acumula puntos Full Copec.
          </div>
        ) : null}

        <div className="size-12 color-white bold-300">
          <button className="btn-default size-09 color-green-base p-0 m-0 underline" onClick={handleDetail}>
            Ver detalle
          </button>
        </div>

        {product?.info?.show_discount ? (
          <div className="d-flex flex-column">
            <div className="d-flex align-items-center">
              <span className="color-white me-1">$</span>
              <span className="color-white  size-12 bold text-decoration-line-through">{product?.info?.without_discount_price?.toLocaleString('es-CL')}</span>
              <span className="color-green-base bold ms-3">{product?.info?.porcentageDiscount}% Dcto</span>
            </div>

            <div className="d-flex size-15 align-items-center">
              <span className="color-green-base size-08 me-1">$</span>
              <span className="color-white size-15 bold">{price?.toLocaleString('es-CL')}</span>
            </div>
          </div>
        ) : (
          <div className="d-flex size-15 align-items-center">
            <span className="color-green-base size-08 me-1">$</span>
            <span className="color-white size-15 bold">{price?.toLocaleString('es-CL')}</span>
          </div>
        )}

        {!productData?.service?.quantity || productData?.service?.quantity === 0 ? (
          <div className="w-100 mt-2">
            <button className="btn-green-light py-2 w-100 bold" onClick={_handleOnClick}>
              Agregar
            </button>
          </div>
        ) : (
          <div className="w-100 mt-2">
            <button className="btn-green-light py-2 w-100 bold" onClick={_handleOnClick}>
              Eliminar
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default CardSubscription;
