import '../../styles/components/component.confirm.scss';
const Confirm = (props: any) => {
  const { open, type, onClose, children, className, sticky, ...rest } = props;
  const _handleClose = (e: any) => {
    if (e.target.classList.contains('component-confirm')) {
      onClose ? onClose() : console.log('[LOG]', 'Everything ok here ;)...');
    }
  };
  return (
    <div className={`component-confirm ${open ? 'open' : ''} ${className}`} onClick={_handleClose} {...rest}>
      <div className={`component-confirm__content w-100 ${type} ${sticky ? 'position-sticky' : 'position-fixed'}`}>{children}</div>
    </div>
  );
};

export default Confirm;
