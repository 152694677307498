import { createAction } from 'redux-actions';

export const SET_SPLASH: any = createAction('SET_SPLASH');
export const SAVE_STATE: any = createAction('SAVE_STATE');

export const set_splash = (payload: any) => {
  const html = document.querySelector('html')
  if(payload.direction || payload.direction_desktop) {
    if(html) {
      html.style.overflowY = 'hidden';
    }
  } else {
    html?.removeAttribute('style');
  }
  return (dispatch: any) => {
    dispatch(SET_SPLASH(payload));
    dispatch(SAVE_STATE());
  };
};
