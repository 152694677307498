import { toast } from 'react-toastify';
import geozoneService from '../services/geozone.service';
import store from '../redux/store';
import { set_splash } from '../redux/actions/splash_screen';
import { set_cache } from '../redux/actions/cache';
import moment from 'moment';

export const _containsLatLan = async (latitude: any, longitude: any) => {
  const dispatch: any = store.dispatch;
  const { matchMedia, cache } = store.getState();

  try {
    const response = await geozoneService.search(latitude, longitude);
    dispatch(set_cache({ ...cache, directions: { created_at: moment().toString(), last_access: moment().toString(), start_reset: null } }));
    return response?.data.data;
  } catch (e: any) {
    dispatch(set_cache({ ...cache, directions: { created_at: moment(), start_reset: null } }));
    if (matchMedia.media === 'xs') {
      dispatch(set_splash({ direction: false }));
    } else {
      dispatch(set_splash({ direction_desktop: false }));
    }
    toast.error('No se pudo encontrar ningún servicio en esta dirección...');
    return [];
  }
};

export const _containsLatLanFront = async (google: any, latitude: any, longitude: any) => {
  const response = await geozoneService.getAll();
  const geozones = response.data.map((zone: any) => {
    const { coordinate } = zone;
    const { features } = coordinate;
    const item = features.map((feature: any) => {
      const { geometry } = feature;
      return {
        eds: zone.eds_id[0].id,
        coordinates: geometry.coordinates.map((c: any) =>
          c.map((latLan: any) => {
            return { lng: latLan[0], lat: latLan[1] };
          })
        ),
      };
    });

    return item;
  });

  const validEds: any = [];

  geozones.forEach((geo: any) => {
    geo.forEach((eds_data: any) => {
      const { eds, coordinates } = eds_data;
      coordinates.forEach((coordinate: any) => {
        const polygon = new google.maps.Polygon({ paths: coordinate });
        const check = google.maps.geometry.poly.containsLocation({ lat: latitude, lng: longitude }, polygon);

        if (check) {
          validEds.push({ eds });
        }
      });
    });
  });

  return validEds;
};
