import './style.scss';

import IconLub from '../../assets/image/svg/icon-aceite.svg';
import IconKer from '../../assets/image/svg/icon-kerosene.svg';
import IconLav from '../../assets/image/svg/icon-lavado.svg';
import CloseIcon from '@rsuite/icons/Close';
import { useHistory } from 'react-router-dom';

const ErrorsServicePopup = (props: any) => {
  const { toggleModal, open, title, items = [] } = props;
  const history = useHistory();

  const handleClick = (e: any) => {
    const className = e.target?.className;
    if (typeof className === 'string' && className?.trim() === 'welcome-popup') {
      toggleModal();
    }
}

  return (
    <div className="welcome-popup" style={{ display: open ? 'block' : 'none' }} onClick={handleClick}>
      <div className="welcome-popup__popup">
        <div className="welcome-popup__popup__wrapper">
          <div className="welcome-popup__popup__wrapper__header">
            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={toggleModal}>
              <CloseIcon />
            </button>
            <h2>Copec a domicilio</h2>
            <hr />
          </div>

          <div className="welcome-popup__popup__wrapper__body">

            <div className='d-flex justify-content-around mb-4'>
              <img src={IconLub} alt="Icono Aceite" />
              <img src={IconKer} alt="Icono Kerosene" />
              <img src={IconLav} alt="Icono Lavado" />
            </div>
            <hr />
            <h4>
              {title}
            </h4>

            <div className='size-11 bold py-3'>
              Servicios que ya no estan disponibles:
            </div>

            {items.map((item: any, index: number) => (
              <div className="w-100 mb-4 color-grey" key={`error-${index}`}>
                <div className="bold size-12">{item?.name}</div>
                <div className="">{item.service?.name}</div>
                <hr />
              </div>
            ))}

            <button className="btn-promo bold mt-5" type="button" onClick={() => history.push('/')}>
              Volver a los servicios
            </button>

          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorsServicePopup;
