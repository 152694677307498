/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import Images from '../../assets/image';
import Image from '../../components/Image';

const DirectionCard = (props: any) => {
  const { text, onChange, onUpdate, className, name, onDelete, data } = props;

  const [state, setState] = useState({
    title: '',
    subtitle: '',
  });

  useEffect(() => {
    if (text) {
      const _array = text.split(',');
      const title = _array[0];
      _array.splice(0, 1);
      const subtitle = _array.join(' ');
      setState({ ...state, title, subtitle });
    }
  }, [text]);

  return (
    <div className={`w-100 p-2 ${className || ''}`} style={{ borderBottom: '4px solid #F8FAFF' }}>
      <div className="row">
        <div className="col-12 col-sm-10">
          <div className="row">
            <div className="col-12 size-11 bold color-black d-flex justify-content-between align-items-center" style={{ marginBottom: '2px' }}>
              {state.title}
              {data?.info?.history ? <span className="d-md-none badge color-dark-blue border size-07 py-1">Recomendado</span> : null}
            </div>
            <div className="col-12 size-09 mb-2" style={{ marginBottom: '2px' }}>
              {state.subtitle}
            </div>
            <div className="col-7 col-sm-12 d-flex mt-3 align-items-center" style={{ marginBottom: '2px' }}>
              <input type="radio" name={name} checked={data.main} className="me-2" onChange={() => (data.main ? console.log('favorite direction selected...') : onChange(data.SK))} /> Dirección
              favorita
            </div>

            <div className="col-5 d-flex d-sm-none justify-content-end color-blue-base mt-3 align-items-center">
              <Image image={Images.EditIcon} style={{ width: '20px', cursor: 'pointer' }} onClick={() => onUpdate(data.SK)} className="me-4" />
              <Image image={Images.DeleteIcon} style={{ width: '20px', cursor: 'pointer' }} onClick={() => onDelete && onDelete(data.SK)} className="me-2" />
            </div>
          </div>
        </div>
        <div className="col-2 d-none d-md-flex flex-column justify-content-around color-blue-base">
          {data?.info?.history ? (
            <div className="text-end">
              <span className="badge color-dark-blue border size-07 py-1">Recomendado</span>
            </div>
          ) : null}
          <div className="d-flex justify-content-around w-100">
            <Image image={Images.EditIcon} style={{ width: '20px', cursor: 'pointer' }} onClick={() => onUpdate(data.SK)} />
            <Image image={Images.DeleteIcon} style={{ width: '20px', cursor: 'pointer' }} onClick={() => onDelete && onDelete(data.SK)} />
          </div>
        </div>
      </div>
    </div>
  );
};
export default DirectionCard;
