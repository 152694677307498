/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import '../../styles/components/component.profile-image.scss';

const ProfileImage = (props: any) => {
  const [state, setState] = useState<any>({ text: '' });
  const { _string, name, surname } = props;

  useEffect(() => {
    const array = _string.split(' ');
    let text = '';
    if (array.length > 1) {
      if(name && surname) {
        text = `${name[0]} ${surname[0]}`;
      }
    } else {
      text = `${array[0][0]}`;
    }

    setState({ ...state, text });
  }, [_string]);

  return <div className="component-profile-image">{state.text?.toUpperCase()}</div>;
};
export default ProfileImage;
