/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import Images from '../../assets/image'
import ErrorValidation from '../../components/ErrorValidation'
import Image from '../../components/Image'
import Title from '../../components/Title'
import { regexCognito } from '../../helpers/Utils'
import { LOAD_OFF, LOAD_ON } from '../../redux/actions/loader'
import sessionService from '../../services/session.service'
import DesktopTemplate from '../Profile/DesktopTemplate'

const ChangePassword = (props: any) => {

    const [state, setState] = useState<any>({
        update: false,
        inputs: {
            password: '',
            password_c: '',
            code: ''
        },
        block_send: false,
        seconds: '',
        validations: {}
    })
    const dispatch = useDispatch();
    const { user } = useSelector((state: any) => state)

    useEffect(() => {
        const timer = localStorage.getItem('timer_send_code')
        let interval: any = null;
        if (timer) {
            const time_send = moment(timer);
            const now = moment();
            const diff = moment.duration(now.diff(time_send));

            if (diff.minutes() > 0) {
                localStorage.removeItem('timer_send_code')
            }

            if (diff.minutes() === 0 && diff.seconds() <= 59) {
                setState({ ...state, block_send: true })
                interval = setInterval(() => {
                    const now = moment();
                    const diff = moment.duration(now.diff(time_send));
                    const rest_seconds = 60 - diff.seconds();
                    if (rest_seconds === 60 || rest_seconds === 1) {
                        clearInterval(interval);
                        setState({ ...state, block_send: false, seconds: rest_seconds, update: true })
                        localStorage.removeItem('timer_send_code')
                    } else {
                        setState({ ...state, block_send: true, seconds: rest_seconds, update: true })
                    }
                }, 1000)
            }
        }

        return () => {
            if (interval) {
                clearInterval(interval)
                console.log('CLEAR')
            }
        }
    }, [])

    const setUpdate = () => {
        setState({ ...state, update: !state.update, validations: {} })
    }

    const _handleUpdatePassword = async () => {

        if (!regexCognito(state.inputs.password)) {
            setState({ ...state, validations: { password: ['La contraseña debe tener al menos 10 caracteres, 1 letra mayúscula, 1 letra minúscula, 1 carácter especial'] }, loading: false })
            return
        }

        if (state.inputs.password !== state.inputs.password_c) {
            setState({ ...state, validations: { password: ['Las contraseñas deben ser las mismas'], password_c: ['Las contraseñas deben ser las mismas'] }, loading: false })
            return
        }

        dispatch(LOAD_ON());

        try {
            const params = {
                email: user.profile.email,
                code: state.inputs.code,
                password: state.inputs.password
            }
            await sessionService.confirmNewPassword(params);
            localStorage.removeItem('timer_send_code')
            setState({
                ...state,
                validations: {},
                update: false,
                inputs: {
                    password: '',
                    password_c: '',
                    code: ''
                },
            })
            toast.success('Contraseña actualizada con éxito')
        } catch (e: any) {
            console.log("LOGGER", e?.message);
            toast.error("El código de confirmación no es válido")
            setState({ ...state, update: false, validations: {} })
        }
        dispatch(LOAD_OFF());
    }

    const _sendCode = async () => {
        dispatch(LOAD_ON());
        try {
            await sessionService.forgotPassword({ email: user.profile.email });
            toast.success('Se ha enviado un email con el código para cambio de contraseña...')
            const init = moment()
            localStorage.setItem('timer_send_code', init.toString());
            let interval: any = null
            setState({ ...state, block_send: true })
            interval = setInterval(() => {
                const now = moment();
                const diff = moment.duration(now.diff(init));
                const rest_seconds = 60 - diff.seconds();
                if (rest_seconds === 60 || rest_seconds === 1) {
                    clearInterval(interval);
                    setState({ ...state, block_send: false, seconds: rest_seconds, update: true })
                    localStorage.removeItem('timer_send_code')
                } else {
                    setState({ ...state, block_send: true, seconds: rest_seconds, update: true })
                }
            }, 1000)
        } catch (e: any) {
            console.log("LOGGER", e?.message)
            toast.error(e.message)
        }
        dispatch(LOAD_OFF());
    }

    const _handleOnChange = ({ target }: any) => {
        if(target.name === 'code') {
            target.value = target.value.replace(/\D/g, '');
        }
        const { value, name } = target;
        state.inputs[name] = value;
        setState({ ...state })
    }

    return (
        <>
            {/* Mobile Version  */}
            <div className="container-fluid d-md-none mt-5">
                <Title text="Cambiar contraseña" size="md" className="bold color-dark-blue text-center mb-5" />
                <div className="row px-3 mb-4">
                    <div className="p-0 mb-1 color-black bold">
                        Código
                    </div>
                    <div className="col-12 d-flex bg-color-blue-light border p-3 rounded-10">
                        <Image image={state.update ? Images.PasswordOn : Images.PasswordInput} className="me-3" />
                        <input
                            type="text"
                            name="code"
                            value={state.inputs.code}
                            onChange={_handleOnChange}
                            className="input-transparent"
                            placeholder="XXXXXX"
                            maxLength={6}
                            style={{ flex: '1' }}
                            disabled={!state.update}
                            id="password-input"
                        />
                    </div>
                    <div className="w-100 color-blue-base text-end">
                        {!state.block_send && (
                            <button className="btn size-09 bold" onClick={_sendCode}>Enviar código</button>
                        )}
                        {state.block_send && (
                            <span className="size-09 bold color-grey">Reenviar código en {state.seconds}</span>
                        )}
                    </div>
                </div>
                <div className="row px-3 mb-4">
                    <div className="p-0 mb-1 color-black bold">
                        Nueva contraseña
                    </div>
                    <div className="col-12 d-flex bg-color-blue-light border p-3 rounded-10">
                        <Image image={state.update ? Images.PasswordOn : Images.PasswordInput} className="me-3" />
                        <input
                            type="password"
                            name="password"
                            value={state.inputs.password}
                            onChange={_handleOnChange}
                            className="input-transparent placeholder-lg"
                            placeholder="··········"
                            style={{ flex: '1' }}
                            disabled={!state.update}
                        />
                    </div>
                    <ErrorValidation name="password" validations={state.validations} className="size-09 bold-300" />
                </div>
                <div className="row px-3 mb-4">
                    <div className="p-0 mb-1 color-black bold">
                        Repetir la nueva contraseña
                    </div>
                    <div className="col-12 d-flex bg-color-blue-light border p-3 rounded-10">
                        <Image image={state.update ? Images.PasswordOn : Images.PasswordInput} className="me-3" />
                        <input
                            type="password"
                            name="password_c"
                            value={state.inputs.password_c}
                            onChange={_handleOnChange}
                            className="input-transparent placeholder-lg"
                            placeholder="··········"
                            style={{ flex: '1' }}
                            disabled={!state.update}
                        />
                    </div>
                    <ErrorValidation name="password_c" validations={state.validations} className="size-09 bold-300" />
                </div>

                <div className="row mt-5">
                    {state && state.update
                        ? (
                            <div className="col-12 color-white">
                                <button className="btn btn-default w-100 p-3 color-white" onClick={_handleUpdatePassword}>
                                    Guardar
                                </button>
                            </div>
                        ) : (
                            <div className="col-12">
                                <button className="btn bold color-grey d-flex align-items-center justify-content-center w-100" onClick={() => setUpdate()}>
                                    Editar
                                    <Image image={Images.EditIcon} className="ms-3" />
                                </button>
                            </div>
                        )}

                </div>
            </div>

            {/* Desktop Version */}

            <div className="container-fluid d-none d-md-block">
                <DesktopTemplate>
                    <div className="row border-bottom mx-4 px-0 py-4">
                        <div className="col-6 p-0">
                            <span className="bold size-12 color-black">Cambiar contraseña</span>
                        </div>
                        <div className="col-6">
                            <button className="btn bold color-grey d-flex align-items-center justify-content-end w-100" onClick={() => setUpdate()}>
                                {state.update ? 'Cancelar' : 'Editar'}
                                <Image image={Images.EditIcon} className="ms-3" />
                            </button>
                        </div>
                    </div>

                    <div className="row px-3 border-bottom mx-4 py-5">
                        <div className="p-0 mb-1 color-black bold">
                            Código
                        </div>
                        <div className="col-6 d-flex bg-color-blue-light border p-3 rounded-30">
                            <Image image={state.update ? Images.PasswordOn : Images.PasswordInput} className="mx-3" />
                            <input
                                type="text"
                                name="code"
                                onChange={_handleOnChange}
                                className="input-transparent placeholder-lg"
                                placeholder="······"
                                style={{ flex: '1' }}
                                disabled={!state.update}
                                maxLength={6}
                            />
                        </div>
                        <div className="w-100 color-blue-base">
                            {!state.block_send && (
                                <button className="btn size-09 bold" onClick={_sendCode}>Enviar código</button>
                            )}
                            {state.block_send && (
                                <span className="size-09 bold color-grey">Reenviar código en {state.seconds}</span>
                            )}
                        </div>
                    </div>

                    <div className="row px-3 mx-4 py-5">
                        <div className="col-6">
                            <div className="row pe-3 justify-content-start">
                                <div className="p-0 mb-1 color-black bold">
                                    Nueva contraseña
                                </div>
                                <div className="col-12 d-flex bg-color-blue-light border p-3 rounded-30">
                                    <Image image={state.update ? Images.PasswordOn : Images.PasswordInput} className="mx-3" />
                                    <input
                                        type="password"
                                        name="password"
                                        onChange={_handleOnChange}
                                        className="input-transparent placeholder-lg"
                                        placeholder="··········"
                                        style={{ flex: '1' }}
                                        disabled={!state.update}
                                    />
                                </div>
                                <ErrorValidation name="password" validations={state.validations} className="size-09 bold-300" />
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="row pe-3 justify-content-start">
                                <div className="p-0 mb-1 color-black bold">
                                    Repetir contraseña
                                </div>
                                <div className="col-12 d-flex bg-color-blue-light border p-3 rounded-30">
                                    <Image image={state.update ? Images.PasswordOn : Images.PasswordInput} className="mx-3" />
                                    <input
                                        type="password"
                                        name="password_c"
                                        onChange={_handleOnChange}
                                        className="input-transparent placeholder-lg"
                                        placeholder="··········"
                                        style={{ flex: '1' }}
                                        disabled={!state.update}
                                    />
                                </div>
                                <ErrorValidation name="password_c" validations={state.validations} className="size-09 bold-300" />
                            </div>
                        </div>
                    </div>

                    <div className="row px-4 mt-5">
                        <div className="col-12 text-end color-white">
                            <button className="btn-default size-11 px-5 py-2 rounded-30" disabled={!state.update} onClick={_handleUpdatePassword}>Actualizar</button>
                        </div>
                    </div>

                </DesktopTemplate>
            </div>
        </>
    )

}
export default ChangePassword