import img1 from '../../assets/alima/img1.png'
import img2 from '../../assets/alima/img2.png'
import img1Mobile from '../../assets/alima/img1-mobile.jpg'
import img2Mobile from '../../assets/alima/img2-mobile.jpg'
import img3Mobile from '../../assets/alima/img3-mobile.jpg'
import img3 from '../../assets/alima/img3.png'
import arrow_down from '../../assets/alima/arrow_down.svg'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import CardServiceApp from './components/cardService'
import CardServiceMobileApp from './components/cardServiceMobile'
import { _scrollTo } from '../../helpers/Utils'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

function Services() {

    const { directions } = useSelector((state: any) => state)

    const history = useHistory();

    const _handleScrollDown = (e: any) => {
        const container = document.querySelector('#services-container');
        if (container) {
            _scrollTo(e, directions, "#services-container")
        }
    }

    return (
        <Row className='styleContent' id="services-container">
            <Col className='container-white'>
                <button className='btn' onClick={_handleScrollDown}>
                    <img src={arrow_down} width="40" style={{ marginTop: 20 }} alt='Descubre los servicios y productos que tenemos para ti' />
                </button>
                <div className='title'>Descubre los servicios y productos que tenemos para ti</div>
                <Row justify="center" style={{ marginTop: 50, marginBottom: 50 }}>

                    <Col xs={12} md={4}>
                        <div className='hideContent'>
                            <CardServiceApp
                                title="Kerosene"
                                text="Reparto de parafina en bidones certificados de 20 litros."
                                textLink="Saber más"
                                img={img1}
                                click={() => history.push('/services/info/kerosene')}
                            />
                        </div>

                        <div className='showContent'>
                            <CardServiceMobileApp
                                title="Kerosene"
                                text="Reparto de parafina en bidones certificados de 20 litros."
                                textLink="Saber más"
                                img={img1Mobile}
                                click={() => history.push('/services/info/kerosene')}
                            />
                        </div>

                    </Col>
                    <Col xs={12} md={4}>
                        <div className='hideContent'>
                            <CardServiceApp
                                title="Lavado de auto"
                                text="No usamos agua ni dejamos residuos."
                                textLink="Saber más"
                                img={img2}
                                click={() => history.push('/services/info/lavado')}
                            />
                        </div>
                        <div className='showContent'>
                            <CardServiceMobileApp
                                title="Lavado de auto"
                                text="No usamos agua ni dejamos residuos."
                                textLink="Saber más"
                                img={img2Mobile}
                                click={() => history.push('/services/info/lavado')}
                            />
                        </div>
                    </Col>
                    <Col xs={12} md={4}>
                        <div className='hideContent'>
                            <CardServiceApp
                                title="Cambio de Aceite"
                                text="Lubricantes Mobil y servicio experto."
                                textLink="Saber más"
                                img={img3}
                                click={() => history.push('/services/info/cambio-aceite')}
                            />
                        </div>
                        <div className='showContent'>
                            <CardServiceMobileApp
                                title="Cambio de Aceite"
                                text="cambio-aceitericantes Mobil y servicio experto."
                                textLink="Saber más"
                                img={img3Mobile}
                                click={() => history.push('/services/info/cambio-aceite')}
                            />
                        </div>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}

export default Services;
