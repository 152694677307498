/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from 'react-redux';
import AddressSet from '../HomeMobile/AddressSet';
import Top from '../../components/Alima/top';
import Services from '../../components/Alima/services';
import HowDoesWork from '../../components/Alima/howDoesWork';
import OurClients from '../../components/Alima/ourClients';
import FrequentQuestions from '../../components/Alima/frequentQuestions';
import { Loader } from '@googlemaps/js-api-loader';
import { send_message } from '../../redux/actions/broadcast';
import { useEffect, useState } from 'react';

const HomeDesktop = () => {
  const { matchMedia, broadcast, directions } = useSelector((state: any) => state);
  const [state, setState] = useState<any>({
    inputs: {},
    modal: {
      directions: {
        show: false,
      },
    },
    drawer: {
      script: {
        show: false,
        direction: 'right-left',
      },
      manual: {
        show: false,
        direction: 'right-left',
      },
      autocomplete: {
        show: false,
        direction: 'bottom-top',
      },
      history: {
        show: false,
        direction: 'bottom-top',
      },
    },
    google: null,
    addresses: [],
  });
  const dispatch: any = useDispatch();

  useEffect(() => {
    if (!state.google) {
      const loader: any = new Loader({
        apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY ? process.env.REACT_APP_GOOGLE_MAPS_API_KEY : '',
        version: 'weekly',
        libraries: ['places', 'geometry'],
        ...{},
      });

      loader.load().then((google: any) => {
        setState({ ...state, google, api_key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY });
      });
    }
  }, [state]);

  useEffect(() => {
    if (directions && directions.direction) return;
    if (broadcast && broadcast?.open_directions === true) {

      if (matchMedia.media === 'xs') {
        _toogleDrawer('autocomplete');
      } else {
        _handleToogleDirections();
      }
      dispatch(send_message({ open_directions: false }));
    }
  }, [broadcast]);

  const _handleDirections = () => {
    if (matchMedia.media === 'xs') {
      _toogleDrawer('autocomplete');
    } else {
      _handleToogleDirections();
    }
  };

  const _handleToogleDirections = () => {
    setState({ ...state, modal: { ...state.modal, directions: { show: !state.modal.directions.show } } });
  };

  const _toogleDrawer = (target: any) => {
    if (!state.google) {
      const loader: any = new Loader({
        apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY ? process.env.REACT_APP_GOOGLE_MAPS_API_KEY : '',
        version: 'weekly',
        libraries: ['places', 'geometry'],
        ...{},
      });

      loader.load().then((google: any) => {
        const autocompleteService = new google.maps.places.AutocompleteService();
        state.drawer[target].show = !state.drawer[target].show;
        setState({ ...state, google, api_key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY, autocompleteService });
      });
    } else {
      state.drawer[target].show = !state.drawer[target].show;
      setState({ ...state });
    }
  };

  const _closeAndOpen = (target: any) => {

    Object.keys(state.drawer).forEach((key) => {
      state.drawer[key].show = false;
    });
    setState({ ...state });

    /* TIMEOUT EFFECT */
    setTimeout(() => {
      state.drawer[target].show = true;
      setState({ ...state });
    }, 200);
  };

  const _handleFirstDrawer = () => {
    if (state.addresses?.length > 0) {
      _toogleDrawer('history');
    } else {
      _toogleDrawer('autocomplete');
    }
  };

  return (
    <div className={`container-fluid`}>
      {directions && directions.direction ? (
        <AddressSet />
      ) : (
        <>

          <Top
            state={state}
            _handleDirections={_handleDirections}
            _handleToogleDirections={_handleToogleDirections}
            _toogleDrawer={_toogleDrawer}
            _closeAndOpen={_closeAndOpen}
            _handleFirstDrawer={_handleFirstDrawer}
          />

          <Services />

          <HowDoesWork
            _handleToogleDirections={_handleToogleDirections}
            _handleFirstDrawer={_handleFirstDrawer}
          />

          <OurClients />

          <FrequentQuestions />
        </>
      )}
    </div>
  );
};

export default HomeDesktop;
