import './style.scss';

import CloseIcon from '@rsuite/icons/Close';
import { useEffect, useState } from 'react';

const SignPopUp = (props: any) => {
  const { handleModalDirections } = props;
  const [open, setOpen] = useState(true);

  useEffect(() => {
    const html = document.querySelector('html');
    const checkModal: any = _checkModalStatus();
    if (!checkModal && open) {
      setOpen(false);
      return;
    }
    if (html) {
      if (open) {
        setTimeout(() => {
          html.style.overflowY = 'hidden';
        }, 400);
        _updateModalCount();
      } else {
        html.removeAttribute('style');
      }
    }
  }, [open]);

  const _closeModal = (e: any) => {
    if (e?.target?.className === 'welcome-popup') {
      setOpen(false);
    }
  };

  const _openModalDirections = () => {
    setOpen(false);
    handleModalDirections();
  };

  const _checkModalStatus = () => {
    const count: any = localStorage.getItem('show_modal_sign_count');
    if (count && count > 100) return false;

    return true;
  };

  const _updateModalCount = () => {
    let count: any = localStorage.getItem('show_modal_sign_count');
    if (count && count > 0) {
      count++;
    } else {
      count = 1;
    }
    localStorage.setItem('show_modal_sign_count', count);
  };

  return (
    <div className="sign-popup" style={{ display: open ? 'block' : 'none' }} onClick={_closeModal}>
      <div className="sign-popup__popup">
        <div className="sign-popup__popup__wrapper">
          <div className="sign-popup__popup__wrapper__header">
            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => setOpen(false)}>
              <CloseIcon />
            </button>
            <h2>Información Relevante de tu usuario</h2>
            <hr />
          </div>

          <div className="sign-popup__popup__wrapper__body">
            <ul>
              <li>
                <div>
                  <span>Si estabas registrado en nuestra web antigua, debes volver a crear un usuario, en la pestaña <a href="/register">"<u>Regístrate Aquí</u>"</a> </span>
                </div>
              </li>
              <li>
                <div>
                  <span>Si entras con el login de Google, no tendrás contraseña, por lo tanto NO es necesario solicitar una recuperación de contraseña. </span>
                </div>
              </li>
              <li>
                <div>
                  <span>Si necesitas recuperar tu contraseña, revisa tu bandeja de correo no deseado / spam</span>
                </div>
              </li>
              <li>
                <div>
                  <span>Si tienes algún problema, puedes llamar al {process.env.REACT_APP_PHONE_SUPPORT2} o contactarte a través de WhatsApp al
                    <a href={process.env.REACT_APP_WHATSAPP_URL} target='_blank' rel="noreferrer" > <u>{process.env.REACT_APP_PHONE_SUPPORT}</u></a></span>
                </div>
              </li>
            </ul>
            <button className="btn-promo mt-5" type="button" onClick={_openModalDirections}>
              Pedir sin registrarme
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignPopUp;
