import axios from "axios";

const api = axios.create({
  baseURL: `https://${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_STAGE}`,
  headers: {
    "Content-type": "application/json"
  }
});

class ServiceDAO {
  getAllServices = () => {
    return api.get('/services/all')
  }

  getService = (id: any) => {
    return api.get(`/services/${id}`)
  }
}

export default new ServiceDAO();