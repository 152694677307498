import ErrorValidation from '../ErrorValidation';

const House = (props: any) => {
  const { onChange, validations } = props;
  return (
    <>
      {/* DESKTOP */}
      <div className="col-12 mb-3 d-none d-sm-block">
        <div className="color-black bold mb-2">Casa/Departamento</div>
        <div className="col bg-color-blue-light px-3 py-2 border-default rounded-20">
          <input className="w-100 p-2 input-transparent size-09" type="text" placeholder="Casa/Depto/Block" name="apartment" onChange={(e: any) => onChange(e, 'i_will_receive')} />
        </div>
        <ErrorValidation validations={validations} name="apartment" className="size-09 text-end bold-300" />
      </div>
      <div className="col-12 mb-3 d-none d-sm-block">
        <div className="color-black bold mb-2">Observaciones</div>
        <div className="col bg-color-blue-light px-3 py-2 border-default rounded-20">
          <textarea
            className="w-100 p-2 input-transparent size-09 remove-chrome-shadow"
            placeholder="Ej: Dejar en conserjeria"
            rows={4}
            name="observations"
            onChange={(e: any) => onChange(e, 'i_will_receive')}
          />
        </div>
        <ErrorValidation validations={validations} name="observations" className="size-09 text-end bold-300" />
      </div>

      {/* MOBILE */}

      <div className="row flex-column px-4 mb-3 d-sm-none">
        <div className="col ps-1 mb-1 color-black bold size-09">Casa/Departamento</div>
        <div className="col bg-color-blue-light px-3 py-1 border-default rounded-10">
          <input className="w-100 p-2 input-transparent size-09" type="text" placeholder="Casa/Depto/Block" name="apartment" onChange={(e: any) => onChange(e, 'i_will_receive')} />
        </div>
        <ErrorValidation validations={validations} name="apartment" className="size-09 text-end bold-300" />
      </div>

      <div className="row flex-column px-4 mb-3 d-sm-none">
        <div className="col ps-1 mb-1 color-black bold size-09">Observaciones</div>
        <div className="col bg-color-blue-light px-3 py-1 border-default rounded-10">
          <textarea
            className="w-100 p-2 input-transparent size-09 remove-chrome-shadow"
            placeholder="Ej: Dejar en conserjeria"
            rows={4}
            name="observations"
            onChange={(e: any) => onChange(e, 'i_will_receive')}
          />
        </div>
        <ErrorValidation validations={validations} name="observations" className="size-09 text-end bold-300" />
      </div>
    </>
  );
};

export default House;
