import React from "react"
import AliceCarousel from "react-alice-carousel"

const ProductsCarousel = React.forwardRef((props: any, ref: any) => {
  const { items, cRef, ...rest } = props
  return (
    <AliceCarousel ref={ref} items={items} {...rest} />
  )
})

export default ProductsCarousel