/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import Images from "../../assets/image";
import Image from "../../components/Image";
import Title from "../../components/Title";
import { ServicePages } from "../../types/service_types";

const Services = () => {
  const params: any = useParams();
  const { available_services } = useSelector((state: any) => state);
  const [state, setState] = useState<any>({ component: {}, not_found: false });

  useEffect(() => {
    const page_component: any = ServicePages();
    const service = available_services.find(
      (as: any) => as.info.slug === params.slug
    );

    if (service) {
      const component = page_component.find(
        (ct: any) => ct.type === service.type
      );
      if (component) {
        setState({ ...state, component: component, service });
      }
    } else {
      setState({ ...state, not_found: true });
    }
  }, [params]);

  if (state.component && state.component.type) {
    const { component, service } = state;
    return <component.Component PK={service.PK} SK={service.SK} data={service} />;
  }

  if (state.not_found) {
    return (
      <div className="container-fluid my-5 " style={{ height: "60vh" }}>
        <div className="row position-relative justify-content-center align-items-center h-100">
          <Image image={Images.BGStars} className="position-absolute mt-5" />
          <Title
            size="xl"
            text="Servicio no disponible en su area..."
            className="text-center color-dark-blue"
          />
        </div>
      </div>
    );
  }

  return (
    <div className="container-fluid my-5 " style={{ height: "60vh" }}>
      <div className="row position-relative justify-content-center align-items-center h-100">
        <Image image={Images.BGStars} className="position-absolute mt-5" />
        <Title
          size="xl"
          text="Validando servicio..."
          className="text-center color-dark-blue"
        />
      </div>
    </div>
  );
};

export default Services;
