/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import Images from '../../assets/image';
import { _containsLatLan } from '../../helpers/check_polygon';
import { _getGoogleAddressComponents } from '../../helpers/Utils';
import { set_eds } from '../../redux/actions/available_eds';
import { setDirections } from '../../redux/actions/directions';
import { clear_services } from '../../redux/actions/services';
import { clear_cart } from '../../redux/actions/shopping_cart';
import { set_splash } from '../../redux/actions/splash_screen';
import sessionService from '../../services/session.service';
import Image from '../Image';
import Map from './Map';

const GoogleAutoComplete = (props: any) => {
  const { autocompleteService, handleClose, google, callback, data, addresses } = props;

  const [state, setState] = useState({
    inputs: {
      address: '',
      observations: '',
    },
    results: [],
    services: {
      manual: false,
    },
    target: {
      latitude: null,
      longitude: null,
      address: null,
      formatted_address: null,
      streetNumber: {
        long_name: '',
        short_name: '',
      },
    },
  });
  const { user } = useSelector((state: any) => state);
  const dispatch: any = useDispatch();
  const history: any = useHistory();

  useEffect(() => {
    if (data?.info?.address) {
      setState({
        ...state,
        inputs: { ...state.inputs, address: data.info.address, observations: data.info.observations },
        target: {
          ...state.target,
          latitude: data.latitude,
          longitude: data.longitude,
          address: data.info.address,
          formatted_address: data.info.address,
          streetNumber: { long_name: data.info.number, short_name: data.info.number },
        },
      });
    }
  }, []);

  const _handleChangeInputs = async (e: any) => {
    const { target } = e;
    e.preventDefault();

    if (target.name === 'number') {
      target.value = target.value.replace(/\D/g, '');
    }
    const { value, name } = target;
    setState({ ...state, inputs: { ...state.inputs, [name]: value } });
    if (name === 'address' && !state.services.manual) {
      if (value.length > 0) {
        autocompleteService.getPlacePredictions({ input: e.target.value, componentRestrictions: { country: 'cl' } }, _displaySuggestions);
      } else {
        _displaySuggestions([]);
      }
    }
  };

  const _displaySuggestions = (payload: any) => {
    if (payload && payload.length > 0) {
      const results = payload.map((item: any) => {
        let show: any = true;
        let number: any = 0;

        // Number Rule
        item.terms.forEach((term: any) => {
          if (!isNaN(term.value)) {
            show = true;
            number = Number(term.value);
          }
        });

        const data: any = {
          title: item.structured_formatting.main_text,
          subtitle: item.structured_formatting.secondary_text,
          description: item.description,
          place_id: item.place_id,
          number: number,
          show: show,
        };
        return data;
      });

      setState((prevState: any) => {
        return { ...prevState, results: results.filter((item: any) => item.show).slice(0, 3) };
      });
    } else {
      setState((prevState: any) => {
        return { ...prevState, results: [] };
      });
    }
  };

  const _setTarget = (target: any) => {
    setState({ ...state, target: target, results: [], inputs: { ...state.inputs, address: target.address } });
  };

  const _handleGeolocation = (payload: any) => {
    const geocoder = new google.maps.Geocoder();

    geocoder.geocode({ placeId: payload.place_id }, async (response: any, status: any) => {
      if (status === 'OK') {
        if (response.length > 0) {
          const directions = response[0];
          const { geometry, address_components, formatted_address } = directions;

          const { streetNumber, street, country, commune_adm_lvl_3, region } = _getGoogleAddressComponents(address_components, payload.number);

          if (!street) {
            toast.error('No fue posible encontrar la dirección');
            dispatch(set_splash({ direction: false, direction_desktop: false }));
            return;
          }

          const address: any = `${street?.long_name} ${streetNumber?.long_name || ''}, ${commune_adm_lvl_3?.long_name || ''} - ${region?.long_name || ''} ${country?.long_name || ''}`;

          const { location } = geometry;
          const latitude = location.lat();
          const longitude = location.lng();
          _setTarget({ latitude, longitude, address, formatted_address, streetNumber });
        }
      } else {
        toast.error('No se pudo encontrar ningún servicio en esta dirección');
        dispatch(set_splash({ direction: false, direction_desktop: false }));
      }
    });
  };

  const _handleOnClickOnMap = (payload: any) => {
    setState((prevState: any) => {
      return { ...prevState, target: { ...prevState.target, latitude: payload.latitude, longitude: payload.longitude } };
    });
  };

  const _handleSearchServices = async () => {
    const { target } = state;
    const { latitude, longitude, formatted_address, address, streetNumber } = target;
    if (callback) {
      callback(
        {
          _string: formatted_address,
          direction: formatted_address,
          address,
          longitude: longitude,
          latitude: latitude,
          number: streetNumber.long_name,
          observations: state.inputs.observations,
        },
        data && Object.keys(data).length > 0 ? true : false
      );
      return;
    }

    dispatch(set_splash({ direction_desktop: true, direction: false }));
    handleClose();

    /**
     *  If user is logged, save direction to history
     *  @directions
     */

    if (user.isLogged) {
      const payload: any = {
        longitude: longitude,
        latitude: latitude,
        name: 'Principal',
        main: false,
        info: {
          address: address,
          number: streetNumber.long_name,
          observations: state.inputs.observations,
          apartment: state.inputs.observations,
          history: true,
          history_date: moment().toString(),
        },
      };

      try {
        if (addresses && addresses.length > 0) {
          const isAddressListed: any = addresses.find((item: any) => item.info?.address === address);
          if (!isAddressListed) {
            await sessionService.createAddress(payload);
          } else {
            isAddressListed.info.history_date = moment().toString();
            await sessionService.updateAddress(isAddressListed?.SK, isAddressListed);
          }
        } else {
          await sessionService.createAddress(payload);
        }
      } catch (e: any) {
        toast.warning('No fue posible ingresar esta direccion al histórico');
      }
    }

    const available_eds = await _containsLatLan(latitude, longitude);

    if (!available_eds) {
      dispatch(set_splash({ direction: false, direction_desktop: false }));
      return;
    }

    dispatch(
      setDirections({
        _string: formatted_address,
        direction: formatted_address,
        address,
        longitude: longitude,
        latitude: latitude,
      })
    );
    dispatch(clear_cart());
    dispatch(clear_services());
    dispatch(set_splash({ direction: false, direction_desktop: false }));
    dispatch(set_eds(available_eds));
    history.push('/');
  };

  return (
    <>
      <div className="col-12 bold color-black mb-1">Dirección</div>
      <div className="col-12 pb-2 px-3 position-relative" style={{ zIndex: 1 }}>
        <div className="input-default rounded-25 d-flex mb-3">
          <Image image={Images.DirectionColoredIcon} className="mx-3" />
          <input
            id="autocompletesearchdesktop"
            autoComplete="chrome-off"
            type="text"
            className="input-transparent p-1 placeholder-11 w-100"
            placeholder=" "
            name="address"
            value={state.inputs.address}
            onChange={_handleChangeInputs}
          />
        </div>

        {state.results && state.results.length > 0 ? (
          <div className="p-1 shadow-sm" style={{ position: 'absolute', top: '55px', background: '#fff', width: '100%', left: 0 }}>
            {state.results.map((item: any, index: any) => (
              <div className="col-12 bg-color-blue-light border p-3 rounded-10 mb-1 d-flex" style={{ cursor: 'pointer' }} key={item.title + '-' + index}>
                <Image image={Images.DirectionInput} />
                <button className="w-100 btn d-flex flex-column" onClick={() => _handleGeolocation(item)}>
                  <div className="bold color-grey text-start">{item.title}</div>
                  <div className="size-09 color-grey text-start">{item.subtitle}</div>
                </button>
              </div>
            ))}
          </div>
        ) : null}
      </div>
      <div className="col-12">
        <Map google={google} target={state.target} onClickMap={_handleOnClickOnMap} />
      </div>
      <div className="col-12 py-3 text-center mt-3">
        <button className="btn-default py-3 w-100 size-12" disabled={!state.target?.latitude && !state.target.longitude} onClick={_handleSearchServices}>
          Confirmar dirección
        </button>
      </div>
    </>
  );
};

export default GoogleAutoComplete;
