/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router"
import { PAGES_DONT_RENDER } from "../../helpers/pages_payment_dont";
import { set_actionbar } from "../../redux/actions/action_bar";
import { close_cart } from "../../redux/actions/shopping_cart";

const ActionBottomButton = (props: any) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [show, setShow] = useState(false)
    const { available_services, action_bar, matchMedia } = useSelector((state: any) => state);

    useEffect(() => {
        if (PAGES_DONT_RENDER.find((pdr: any) => pdr === history.location.pathname) || history.location.pathname.includes('order')) {
            setShow(false)
        } else {
            setShow(true)
        }
        history.listen((location: any) => {
            if (PAGES_DONT_RENDER.find((pdr: any) => pdr === location.pathname) || location.pathname.includes('order')) {
                setShow(false)
            } else {
                setShow(true)
            }
        })
    }, [])

    const _handleServices = () => {
        dispatch(close_cart());
        available_services.sort((a: any, b: any) => a.name > b.name ? 1 : -1)
        if (matchMedia.media === 'xs') {
            dispatch(set_actionbar({ ...action_bar, unselected_services: available_services, show: true }))
        } else {
            dispatch(set_actionbar({ ...action_bar, unselected_services: available_services, modal_open: true }))
        }
    }

    return (<button className={`btn me-3 bold size-09 ${show ? 'd-block' : 'd-none'}`} style={{ background: '#000', color: '#fff' }} onClick={_handleServices}>Ir a pagar</button>)

}
export default ActionBottomButton