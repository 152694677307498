/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { PAGES_DONT_RENDER } from '../../helpers/pages_payment_dont';
import Logo from '../../components/Logo';

const Footer = () => {
  const { shopping_cart, user, matchMedia } = useSelector((state: any) => state);
  const [show, setShow] = useState(true);
  const [adjustment, setAdjustment] = useState<any>('0px');
  const history = useHistory();

  useEffect(() => {
    if (PAGES_DONT_RENDER.find((pdr: any) => pdr === history.location.pathname)) {
      setShow(false);
      if (user.isLogged) {
        setAdjustment('70px');
      }
    } else {
      let adjustment = 0;
      if (user.isLogged) {
        adjustment += 70;
      }

      if (shopping_cart.services.length > 0) {
        adjustment += 60;
      }

      setAdjustment(`${adjustment}px`);
    }
  }, [user, shopping_cart]);

  return (
    <>
      <div className={`container-footer d-lg-none pt-5`} style={{ marginBottom: adjustment }}>
        <div className="row justify-content-center mt-3">
          <div className="col-12 text-center">
            <Logo style={{ width: '150px' }} />
          </div>
        </div>

        <div className="row justify-content-center mt-3">
          <div className="col-12 text-center color-white">
            <a
              className="btn rounded-30 color-white"
              target="_blank"
              rel="noreferrer"
              href={process.env.REACT_APP_WHATSAPP_URL}
            >
              <i className="bi bi-whatsapp me-2"></i>
              {process.env.REACT_APP_PHONE_SUPPORT}
            </a>
          </div>
          <div className="col-12 text-center size-09">
            <a href={process.env.REACT_APP_ARCO_RIGHTS} target='_blank' rel="noreferrer">Derechos ARCO</a>
          </div>
        </div>

        <div className="row justify-content-center my-4 size-09">
          <div className="col-12 text-center mb-1">Isidora Goyenechea 2915, Las Condes, Santiago</div>
          <div className="col-12 text-center">Copec en Línea 800 200 354</div>
        </div>

        <div className="row py-3 text-center container-footer__bottom-info">
          <div className="col mx-5">@Copyright {moment().format('yyyy')} Copec S. A. Todos los derechos reservados.</div>
        </div>
      </div>

      {/* FOOTER DESKTOP */}

      <div
        className={`container-footer rounded-top-30 d-lg-block d-none ${shopping_cart.services.length > 0 && show && matchMedia.media === 'xs' ? 'margin-bottom-payment' : ''}`}
        style={{ borderWidth: '16px' }}
      >
        <div className="row mx-5">
          <div className="col-9 mt-5">
            <div className="row">
              <div className="col-12">
                <Logo style={{ width: '150px' }} />
              </div>

              <div className="col-12 mt-5 size-09">Isidora Goyenechea 2915, Las Condes, Santiago</div>
              <div className="col-12 size-09">Copec en Línea 800 200 354</div>
            </div>
          </div>

          <div className="col-3 mt-5">
            <div className="row">
              <div className="col-12 bold-300 text-start">Contáctanos</div>

              <div className="col-12 text-start mt-3 color-white">
                <a
                  className="btn p-0 rounded-30 color-white"
                  target="_blank"
                  rel="noreferrer"
                  href={process.env.REACT_APP_WHATSAPP_URL}
                >
                  <i className="bi bi-whatsapp me-2"></i>
                  <span className="bold">{process.env.REACT_APP_PHONE_SUPPORT}</span>
                </a>
              </div>
              <div className="col-12 text-start mt-2 size-09">
                <a href={process.env.REACT_APP_ARCO_RIGHTS} target='_blank' rel="noreferrer">Derechos ARCO</a>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-5 container-footer__bottom-info">
          <div className="col mx-5 size-08">@ Copyright {moment().format('yyyy')} Copec S.A. Todos los derechos reservados.</div>
          <div className="col-12 mx-5 size-08">
            <Link to="/terminos-y-condiciones" className="me-3">T&eacute;rminos y Condiciones</Link>
            <Link to="/politicas" className="me-3">Políticas de Privacidad </Link>
            <Link to="/cookies" className="me-3">Políticas Cookies</Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
