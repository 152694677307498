const Cookies  = () => {
    return(
        <div className="container text-dark">
            <p><strong>POL&Iacute;TICA DE COOKIES</strong></p>
            <p dir="ltr">&nbsp;</p>
            <p dir="ltr">1. &iquest;Que son las&nbsp;cookies?<br/></p>
            <p dir="ltr">Las&nbsp;cookies y/o dispositivos de almacenamiento y recuperaci&oacute;n de datos en equipos terminales (en adelante&nbsp;cookies) son archivos de texto que un servidor web descarga y almacena en el computador/smartphone/tablet (en adelante el equipo) de un usuario al acceder a determinadas p&aacute;ginas web con el objeto de almacenar y recuperar informaci&oacute;n sobre la navegaci&oacute;n que &eacute;ste realiza, permitiendo conocer la actividad del usuario en un determinado sitio web o en otros con los que se relaciona &eacute;ste.</p>
            <p dir="ltr">Por lo tanto, las&nbsp;cookies&nbsp;son instaladas durante la navegaci&oacute;n ya sea por el sitio web que el usuario est&aacute; visitando como por otros con los que dicho sitio se relaciona y pueden almacenar informaci&oacute;n sobre el equipo del usuario o sobre sus sesiones de navegaci&oacute;n en los sitios web que ha visitado.&nbsp;</p>
            <p dir="ltr">2. &iquest;Para que utilizamos las&nbsp;cookies?<br/></p>
            <p dir="ltr">Utilizamos&nbsp;cookies estrictamente necesarias para el funcionamiento del sitio web, las que son esenciales para su uso.</p>
            <p dir="ltr">Tambi&eacute;n utilizamos cookies adicionales u opcionales para conocer la interacci&oacute;n de nuestros usuarios en nuestro sitio, recordar sus elecciones, presentar publicidad personalizada y brindar una mejorar experiencia.</p>
            <p dir="ltr">3. &iquest;Qu&eacute;&nbsp;cookies&nbsp;utilizamos?<br/></p>
            <p dir="ltr">I. Estrictamente necesarias: &nbsp;Estas&nbsp;cookies&nbsp;son necesarias para que el sitio web funcione y no se pueden desactivar en nuestros sistemas. Son esenciales e imprescindibles para la navegaci&oacute;n y el funcionamiento de nuestro sitio web.</p>
            <p dir="ltr"><br/>II. Opcionales u adicionales: &nbsp;Estas&nbsp;cookies&nbsp;tienen por objeto mejorar la experiencia de navegaci&oacute;n. Sin ellas el usuario puede navegar en el sitio, pero algunas funcionalidades podr&iacute;an no estar disponibles. Dentro de estas&nbsp;cookies&nbsp;encontramos:&nbsp;</p>
            <p dir="ltr">Cookies&nbsp;propias:&nbsp;Son&nbsp;cookies&nbsp;creadas por este sitio web, y que s&oacute;lo puede leer el propio sitio. En este sitio, se instalan&nbsp;cookies&nbsp;propias con las siguientes finalidades:</p>
            <ul>
                <li dir="ltr" >
                    <p dir="ltr">T&eacute;cnicas: Estas&nbsp;cookies&nbsp;sirven para controlar el contenido que se carga en el sitio web como im&aacute;genes, videos, anuncios,&nbsp;pop ups&nbsp;Permiten adem&aacute;s acceder a determinadas partes del sitio.</p>
                </li>
                <li dir="ltr" >
                    <p dir="ltr" >Personalizaci&oacute;n:&nbsp;Estas&nbsp;cookies&nbsp;almacenan las preferencias y configuraciones del usuario, seg&uacute;n las preferencias manifestadas durante la navegaci&oacute;n en la web y en funci&oacute;n de una serie de criterios en el terminal del usuario (por ejemplo, el idioma, el tipo de navegador a trav&eacute;s del cual accede al servicio, la configuraci&oacute;n regional desde donde accede al servicio, etc.).</p>
                </li>
                <li dir="ltr" >
                    <p dir="ltr">An&aacute;lisis: Estas cookies&nbsp;permiten a Copec analizar el tr&aacute;fico entrante al sitio web, sus modalidades de uso y hacer an&aacute;lisis del comportamiento de los usuarios en el mismo. Asimismo, registra cu&aacute;ndo fue la primera y &uacute;ltima vez que el usuario visit&oacute; el sitio y cu&aacute;ndo se ha terminado una sesi&oacute;n y su navegaci&oacute;n.&nbsp;</p>
                </li>
            </ul>
            <p dir="ltr">Cookies&nbsp;de terceros:&nbsp;Son&nbsp;cookies&nbsp;creadas por terceros y consisten en:&nbsp;</p>
            <ul>
                <li dir="ltr" >
                    <p dir="ltr">Cookiesde redes sociales: Copec, en el sitio, utiliza&nbsp;cookies&nbsp;de Instagram, Facebook, Twitter, LinkedIn, entre otras redes sociales, para que el usuario pueda compartir contenidos del sitio web en dichas redes sociales; o, bien para facilitar el registro en el E-commerce, de esta forma, con los datos que los usuarios han facilitado en dichas redes sociales, se pueden completar directamente los campos del formulario de registro en el sitio.</p>
                </li>
                <li dir="ltr" >
                    <p dir="ltr">Cookies publicitarias: Utilizamos&nbsp;cookies&nbsp;almacenadas por terceras empresas que gestionan los espacios que muestran publicidad de Copec y sus empresas relacionadas, y a las que los usuarios acceden. Estas&nbsp;cookies&nbsp;nos permiten ofrecer al usuario informaci&oacute;n de su inter&eacute;s, publicidad personalizada, recordarle los art&iacute;culos de su carro de compras y medir la efectividad de nuestras campa&ntilde;as online.</p>
                </li>
            </ul>
            <p dir="ltr">4. &iquest;C&oacute;mo configurar o rechazar las cookies opcionales?<br/><br/></p>
            <p dir="ltr">Las cookies opcionales u adicionales pueden ser configuradas por el usuario a trav&eacute;s de su deshabilitaci&oacute;n. El usuario puede realizar dicha configuraci&oacute;n en su primera visita al sitio y modificarla en cualquier momento.</p>
            <p dir="ltr">Sin embargo, es importante se&ntilde;alar que la falta de algunas cookies adicionales u opcionales puede significar que ciertas funcionalidades del sitio web no est&eacute;n disponibles afectando en todo o parte su funcionalidad y/o el resultado del mismo. Si el usuario acepta el uso de dichas&nbsp;cookies, nos permite mejorar el sitio web, ofrecer un acceso &oacute;ptimo y un servicio m&aacute;s eficaz y personalizado.</p>
            <p dir="ltr">Si el usuario decide deshabilitar todas las&nbsp;cookies, s&oacute;lo usaremos las cookies estrictamente necesarias, que son las esenciales e imprescindibles para la navegaci&oacute;n y el funcionamiento de nuestro sitio web.</p>
            <p dir="ltr">Puedes modificar la configuraci&oacute;n de tus&nbsp;cookies&nbsp;en cualquier momento por medio de las opciones de configuraci&oacute;n de tu navegador.</p>
            <p><br/></p>
        </div>
    )
}

export default Cookies