import Image from '../Image';
import '../../styles/components/component.card-wash.scss';
import ICardWash from '../../interfaces/ICardWash';
import Images from '../../assets/image';
import { ReactComponent as Recommended } from '../../assets/image/svg/recommended_full.svg';
import { toast } from 'react-toastify';

const CardWash: React.FC<ICardWash> = (props) => {
  const { image, text, value, handleAction, handleDetail, price, hasSmallImg, fullpoints, recommended, recommendedFn, data } = props;

  const _handleOnClick = () => {
    if (handleAction) {
      handleAction();
    } else {
      toast.error('No fue posible agregar este producto')
    }
  };
  return (

    <div className={`container-card-wash shadow ${recommendedFn ? 'gx-recommended-card' : ''} ${recommended && 'recommended-border'}`}>

      <div className={`position-relative container-card-wash__image${hasSmallImg ? '__small' : ''}`}>

        {recommended && recommendedFn ? (
          <div className='position-absolute d-flex align-items-center justify-content-end w-100 ' style={{ top: '3px', left: '0px' }}>
            <Recommended className='me-1' style={{ top: '25px', width: '25px', height: '25px', color: '#203391' }} />
          </div>
        ) : null}

        {recommended && recommendedFn ? (
          <div className='position-absolute d-flex align-items-center justify-content-center w-100 bg-color-dark-blue ' style={{ bottom: '0px', left: '0px' }}>
            <span className='color-white size-07 py-1'>Recomendado</span>
          </div>
        ) : null}

        <Image image={image} />
      </div>
      <div className="container-card-wash__text">


        <div className="container-card-wash__text__title bold-300" dangerouslySetInnerHTML={{ __html: text }} />
        {fullpoints && data?.info?.points_per_division && parseInt(data?.info?.points_per_division) ? (
          <div className="w-100 d-flex align-items-center size-08 bold-300" style={{ lineHeight: '15px' }} >
            <Image image={Images.CopecFull} className="me-2" style={{ width: '40px' }} />
            Acumula puntos Full Copec.
          </div>
        ) : null}
        <div className="w-100 d-flex flex-column justify-content-center align-items-start">
          <div className="d-flex justify-content-between align-items-center w-100 pe-4 bold mb-2">
            {data?.info?.show_discount ? (
              <div className="d-flex align-items-center justify-content-between w-100 mt-2">

                <span className="container-card-wash__text__price size-15">${price?.toLocaleString('es-CL')}</span>

                <div className="d-flex align-items-center justify-content-center flex-column size-09">
                  <span className="color-white bold text-decoration-line-through">${data?.info?.without_discount_price?.toLocaleString('es-CL')}</span>
                  <span className="color-green-base bold">{data?.info?.porcentageDiscount}% Dcto</span>
                </div>

              </div>
            ) : (
              <span className="container-card-wash__text__price size-15">${price?.toLocaleString('es-CL')}</span>
            )}


          </div>

          <div className="w-100 d-flex align-items-center justify-content-between">
            <button className="container-card-wash__text__subtitle direction me-2" onClick={_handleOnClick}>
              Agendar
            </button>
            <span className="me-3 size-08 text-center" style={{ cursor: 'pointer' }} onClick={handleDetail}>
              <u>{value}</u>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardWash;
