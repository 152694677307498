import './style.scss';

import IconLub from '../../assets/image/svg/icon-aceite.svg';
import IconKer from '../../assets/image/svg/icon-kerosene.svg';
import IconLav from '../../assets/image/svg/icon-lavado.svg';
import CloseIcon from '@rsuite/icons/Close';
import { useEffect, useState } from 'react';

const WelcomePopUp = (props: any) => {
  const { handleModalDirections } = props;
  const [open, setOpen] = useState(true);

  useEffect(() => {
    const html = document.querySelector('html');
    const checkModal: any = _checkModalStatus();
    if (!checkModal && open) {
      setOpen(false);
      return;
    }
    if (html) {
      if (open) {
        setTimeout(() => {
          html.style.overflowY = 'hidden';
        }, 400);
        _updateModalCount();
      } else {
        html.removeAttribute('style');
      }
    }
  }, [open]);

  const _closeModal = (e: any) => {
    if (e?.target?.className === 'welcome-popup') {
      setOpen(false);
    }
  };

  const _openModalDirections = () => {
    setOpen(false);
    handleModalDirections();
  };

  const _checkModalStatus = () => {
    const count: any = localStorage.getItem('show_modal_count');
    if (count && count > 1) return false;

    return true;
  };

  const _updateModalCount = () => {
    let count: any = localStorage.getItem('show_modal_count');
    if (count && count > 0) {
      count++;
    } else {
      count = 1;
    }
    localStorage.setItem('show_modal_count', count);
  };

  return (
    <div className="welcome-popup" style={{ display: open ? 'block' : 'none' }} onClick={_closeModal}>
      <div className="welcome-popup__popup">
        <div className="welcome-popup__popup__wrapper">
          <div className="welcome-popup__popup__wrapper__header">
            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => setOpen(false)}>
              <CloseIcon />
            </button>
            <h2>Bienvenidos al nuevo sitio de Copec a domicilio</h2>
            <hr />
          </div>

          <div className="welcome-popup__popup__wrapper__body">
            <h4>
              Ahora podrás reservar todos nuestros <br />
              servicios a domicilio en una sola web*
            </h4>
            <p>
              *Servicios disponibles según área de cobertura. <br />
              Ingresa tu dirección para saber qué servicios tenemos <br />
              disponibles en tu zona.
            </p>
            <ul>
              <li>
                <img src={IconKer} alt="Icono Kerosene" />
                <div>
                  <h5>Reparto de Kerosene</h5>
                  <span>Un bidón Copec de 20 litros con certificado SEC.</span>
                </div>
              </li>
              <li>
                <img src={IconLav} alt="Icono Lavado" />
                <div>
                  <h5>Lavado en Seco</h5>
                  <span>100% ecológico, no utilizamos agua.</span>
                </div>
              </li>
              <li>
                <img src={IconLub} alt="Icono Aceite" />
                <div>
                  <h5>Cambio de Aceite</h5>
                  <span>Aceite Mobil, cambio de filtro y servicio experto.</span>
                </div>
              </li>
            </ul>
            <button className="btn-promo" type="button" onClick={_openModalDirections}>
              Ver servicios en mi dirección
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WelcomePopUp;
