/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clear_cache, set_cache } from '../../redux/actions/cache';
import { clearDirections } from '../../redux/actions/directions';
import { LOAD_OFF, LOAD_ON } from '../../redux/actions/loader';
import { clear_services } from '../../redux/actions/services';
import { clear_cart } from '../../redux/actions/shopping_cart';
import { set_splash } from '../../redux/actions/splash_screen';
import sessionService from '../../services/session.service';
import { Loader } from '@googlemaps/js-api-loader';
import { _searchByAddress } from '../../helpers/search_services';

const DirectionCache = () => {
  const { cache, user, directions: directionsData, matchMedia } = useSelector((state: any) => state);
  const [state, setState] = useState({ modal: false, google: null });
  const dispatch = useDispatch();

  const styles: any = {
    backdrop: {
      position: 'absolute',
      zIndex: 2000,
      width: '100%',
      height: '100vh',
      backgroundColor: 'rgb(0 0 0 / .6)',
    },
    main: {
      backgroundColor: '#fff',
      maxWidth: '90%',
      top: '50px',
      right: '5%',
    },
    image: {
      fontSize: '3em',
      textAlign: 'center',
    },
  };

  useEffect(() => {
    const loader: any = new Loader({
      apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY ? process.env.REACT_APP_GOOGLE_MAPS_API_KEY : '',
      version: 'weekly',
      libraries: ['places', 'geometry'],
      ...{},
    });

    loader.load().then((google: any) => {
      _handleDirections(google)
    });
  }, []);

  const _handleSearchSingnedDirections = async (google: any) => {
    const { isLogged } = user;
    if (isLogged && !directionsData?._string) {
      if (matchMedia.media === 'xs') {
        dispatch(set_splash({ direction: true, direction_desktop: false }));
      } else {
        dispatch(set_splash({ direction_desktop: true, direction: false }));
      }

      try {
        const addresses = await sessionService.getAddressesWithId(user?.profile?.PK, { user: user?.profile?.PK, usersk: user?.profile?.SK, Authorization: user?.session?.AuthenticationResult?.AccessToken });
        const favorite: any = addresses.data.find((el: any) => el.main);

        let address: any = null;

        if (addresses.data?.length > 0) address = addresses.data[0];

        if (favorite) address = favorite;

        if (address) {
          await _searchByAddress({
            google: google,
            data: {
              _string: address.info.address,
              number: address.info.number,
              observations: address.info?.observations,
              apartment: address.info?.apartment,
            },
          });
        } else {
          throw new Error("No hay direccion registrada")
        }
      } catch (e: any) {
        dispatch(set_splash({ direction: false, direction_desktop: false }));
      }
    }
  }

  const _updateSettedAddress = async (google: any) => {
    if (directionsData._string) {
      await _searchByAddress({
        google: google,
        data: {
          _string: directionsData._string,
          number: "",
          observations: directionsData.observations,
          apartment: directionsData.apartment,
        },
      });
    }
  }

  const _handleDirections = async (google: any) => {
    dispatch(set_splash({ direction: false, direction_desktop: false }));
    const { directions } = cache;
    const { isLogged } = user;

    _handleSearchSingnedDirections(google)

    if (directions) {
      const { created_at, last_access } = directions;
      const now = moment();
      const directions_created_at = moment(created_at).add(24, 'hours');
      const directions_last_access = moment(last_access);
      const diffHours = now.diff(directions_created_at, 'hours');
      const diffLastAccess = now.diff(directions_last_access, 'minutes');
      if (diffHours > 0 && diffLastAccess > 60) {
        if (!isLogged) {
          _updateSettedAddress(google);
        }
        setState({ ...state, modal: false });
      } else {
        dispatch(set_cache({ ...cache, directions: { ...cache.directions, last_access: moment().toString() } }));
        setState({ ...state, modal: false });
      }
    }
  }

  const _handleConfirm = () => {
    dispatch(LOAD_ON());
    dispatch(clear_cart());
    dispatch(clearDirections());
    dispatch(clear_services());
    dispatch(clear_cache());
    dispatch(LOAD_OFF());
  };

  return (
    <div className={`${state.modal ? 'd-flex' : 'd-none'} justify-content-center align-items-center`} style={styles.backdrop}>
      <div className="p-3 rounded-10" style={styles.main}>
        <div style={styles.image}>
          <i className="bi bi-person-x"></i>
        </div>
        <div className="size-12 bold-300">Su sesión ha caducado y sera finalizada</div>
        <div className="text-center mt-3">
          <button className="btn btn-default py-2 px-3" onClick={_handleConfirm}>
            Confirmar
          </button>
        </div>
      </div>
    </div>
  );
};

export default DirectionCache;
