import moment from 'moment';
import { toast } from 'react-toastify';
import week_days from './week_days';
import { _isCrossSellingApplicable, _isCrossSellingAvailable } from './search_services';

interface ICupons {
  services: any;
  total: any;
  delivery: any;
  total_without_discount: any;
  discount: any;
  is_first_check?: any;
  discountRules?: any;
  deliveryRules?: any;
  cross_selling?: any;
}

export const apply_cupons: any = (data: ICupons, cupon: any) => {
  try {
    /**
     * Check cupon status
     * @status
     */

    if (!cupon?.status) {
      throw new Error('Este cupón no es válido.');
    }

    /**
     * Check campaign status
     * @status
     */

    if (!cupon?.campaign?.status) {
      throw new Error('Este cupón no es válido.');
    }

    /**
     * Check campaign Start and End date
     * @start
     * @end
     */
    const today = moment();
    if (cupon?.campaign?.lock?.status) {
      const startCampaign = moment(cupon?.campaign?.lock?.start);
      const endCampaign = moment(cupon?.campaign?.lock?.end);

      if (today.isBefore(startCampaign) || today.isAfter(endCampaign)) {
        throw new Error('Este cupón no es válido.');
      }
    }

    const setup: any = cupon?.campaign?.setup?.find((item: any) => parseInt(item.PK) === parseInt(cupon?.setup));

    /**
     * Check if exists a setup
     * @setup
     */

    if (!setup) {
      throw new Error('Este cupón no es válido.');
    }

    /**
     * Check setup status
     * @status
     */

    if (!setup.status) {
      throw new Error('Este cupón no es válido.');
    }

    /**
     * Check setup Start and End date
     * @start
     * @end
     */

    if (setup.start !== '' && setup.end !== '' && setup.start !== setup.end) {
      const startSetup = moment(setup.start);
      const endSetup = moment(setup.end);

      if (today.isBefore(startSetup) || today.isAfter(endSetup)) {
        throw new Error('Este cupón no es válido.');
      }
    }

    /**
     * Check if the setup applies to a specific day
     * @apply_days
     */

    if (setup.apply_days?.length > 0) {
      const todayNumber: any = moment().day();
      if (setup.apply_days.filter((item: any) => item === todayNumber).length === 0) {
        let labelAvailableDays: any = [];
        setup.apply_days.forEach((availableDay: any) => {
          const weekDay: any = week_days.find((wd: any) => wd.value === availableDay);
          labelAvailableDays.push(weekDay.name);
        });
        throw new Error(`Este cupón solo es valido en los días: ${labelAvailableDays.join(', ')} `);
      }
    }

    /**
     * Check if the setup requires a minimum amount
     * @minimum_amount
     */

    if (setup.minimum_amount) {
      const minAmount: any = data.total + data.delivery;
      const minimumAmountInCart: any = parseInt(setup.minimum_amount_in_cart);
      if (minAmount < minimumAmountInCart) {
        throw new Error(`Este cupón solo se aplica a compras superiores a $${minimumAmountInCart.toLocaleString('pt-BR')}`);
      }
    }

    const services: any = [...data.services];

    /**
     * Check if the setup applies to a specific EDS or all EDS
     * @pk_eds
     */

    const allEds: any = setup.pk_eds.find((item: any) => item === 'all');

    if (!allEds) {
      const edsServiceAvailable: any = services.filter((service: any) => setup.pk_eds.find((item: any) => item === service.eds?.eds_id));
      if (edsServiceAvailable.length !== services.length) {
        throw new Error('Este cupón no es válido en esta dirección.');
      }
    }

    /**
     * Check if the setup applies to a specific service or all services
     * @pk_services
     */

    const eligibleServices: any = services.filter((item: any) => setup.pk_services.find((pk: any) => pk === item.PK));

    if (eligibleServices.length === 0) {
      throw new Error('Este cupón no es válido.');
    }

    let totalDiscountApplied: any = 0;
    let totalDiscountByHour: any = 0;
    let totalDiscountCrossSelling: any = 0;

    // If existe discount by multiple kerosene services, apply discount

    if(data.deliveryRules?.discount > 0) totalDiscountApplied += data.deliveryRules?.discount;

    /**
     *  Checks if the cupon accumulates with a discount per day
     *  If not, clear discounts
     *  @cumulative_day_discount
     */

    if (data.discountRules?.type === 'by_day' && !setup.cumulative_day_discount) {
      let isDiscountByCrossSellingAvailable: boolean = _isCrossSellingAvailable(data);
      let isDiscountByCrossSellingApplicable: boolean = _isCrossSellingApplicable(data);
      if (isDiscountByCrossSellingApplicable && isDiscountByCrossSellingAvailable && data.cross_selling?.is_available) {
        data.discountRules = {
          discount: parseInt(data?.cross_selling.percentage),
          accumulate: true,
          type: 'cross_selling',
          description: data?.cross_selling?.description,
        };
      }
    }

    let discountRules: any = data.discountRules;

    services.map((item: any) => {
      if (!item.related_products) {
        item.related_products = [];
      }
      return item;
    });

    /**
     * Applies discount by services
     * @Services
     */

    if (setup.apply === 'servicios') {
      data.discount = 0;

      services.forEach((service: any) => {
        /**
         *  Clear discounts
         *  @discounts
         */
        service.service.price_discount = 0;
        service.service.amount_discount = 0;
        service.related_products.map((item: any) => {
          item.amount_discount = 0;
          return item;
        });

        const allowed: any = setup.pk_services.find((item: any) => item === service.PK);

        if (allowed) {
          const { isDiscounted, price, amount_discount, discountService, discountCrossSelling, discountByHour } = _handleDiscountByServices(setup, service, totalDiscountApplied, discountRules);

          if (isDiscounted) {
            service.service.price_discount = price;
            service.service.amount_discount = discountService + discountCrossSelling + discountByHour;
            data.discount = data.discount + amount_discount;
            totalDiscountApplied = totalDiscountApplied + discountService;
          }
          totalDiscountByHour += discountByHour;
          totalDiscountCrossSelling += discountCrossSelling;
        } else {
          if (discountRules?.type === 'by_day' && setup.cumulative_day_discount) {
            const responseDiscountByHour: any = _handleDiscountByHour(service);
            service.service.price_discount = responseDiscountByHour.price;
            data.discount = data.discount + responseDiscountByHour.discountByHour;
            totalDiscountByHour += responseDiscountByHour.discountByHour;
          }

          if (discountRules?.type === 'cross_selling') {
            let discountCrossSelling: number = 0;
            let price: any = parseInt(service.service?.price_total);
            discountCrossSelling += (price * discountRules.discount) / 100;
            price = price - discountCrossSelling;

            service.related_products.map((rp: any) => {
              rp.price = rp.price || rp.pricing || rp.value || rp.precio;

              if (rp.quantity) {
                rp.price_total = rp.price * rp.quantity;
              } else {
                rp.price_total = rp.price;
              }

              rp.amount_discount = (rp.price_total * discountRules.discount) / 100;

              rp.price_discount = rp.price_total - rp.amount_discount;

              discountCrossSelling += rp.amount_discount;

              return rp;
            });

            totalDiscountCrossSelling += discountCrossSelling;
          }
        }

        if (setup.type_discount === 'litro') {
          service.service.amount_discount = service.service.amount_discount / service.service.quantity;
        }
      });
    }

    /**
     * Whole cart discount
     * @Cart
     */

    if (setup.apply === 'carrito') {
      let totalProducts: any = 0;
      let totalDiscounted: 0;
      data.discount = 0;

      services.forEach((service: any) => {
        /**
         *  Clear discounts
         *  @discount
         */

        service.service.price_discount = 0;
        service.service.amount_discount = 0;
        service.related_products.map((item: any) => {
          item.amount_discount = 0;
          return item;
        });

        /**
         *  Count items on cart
         *  @totalProducts
         */

        totalProducts += service.service.quantity || 1;

        service.related_products.forEach((item: any) => {
          totalProducts += item.quantity;
        });

        /**
         *  Get discount by hour or cross selling
         *  @_handleDiscounts
         */

        const { isDiscounted, price, discountService, discountCrossSelling, discountByHour } = _handleDiscounts(setup, service, totalDiscountApplied, discountRules);
        if (isDiscounted) {
          service.service.amount_discount = discountService;
          service.service.price_discount = price;
          totalDiscountApplied = totalDiscountApplied + discountCrossSelling + discountByHour;
        }
      });

      switch (setup.type_discount) {
        case 'porcentaje':
          [totalDiscounted] = _handleDiscountPercentage(services, setup, totalProducts);
          totalDiscountApplied += totalDiscounted;
          break;

        case 'monto':
          [totalDiscounted] = _handleDiscountByAmount(services, setup, totalProducts);
          totalDiscountApplied += totalDiscounted;
          break;
        default:
          totalDiscounted = 0;
      }
    }

    /**
     * Delivery discount
     * @Delivery
     */

    if (setup.apply === 'sobre_envio') {

      // Remove discount applied by multiples kerosene services
      services.map((service: any) => {
        if (service.is_shipping_price_discounted) {
          service.is_shipping_price_discounted = false;
          totalDiscountApplied -= service.shipping_price;
        }
        return service;
      });
      data.deliveryRules = null;

      services.forEach((service: any) => {
        service.service.amount_discount = 0;
        service.related_products.map((item: any) => {
          item.amount_discount = 0;
          return item;
        });

        if (discountRules && discountRules.type !== 'by_day') {
          const { discountCrossSelling } = _handleDiscounts(setup, service, totalDiscountApplied, discountRules);
          totalDiscountApplied += discountCrossSelling;
        }

        if (discountRules && discountRules.type === 'by_day') {
          const isAvailable: any = _isCrossSellingAvailable(data);
          const { cross_selling } = data;

          if (isAvailable && cross_selling.is_available) {
            const ExceptionDiscountRules: any = {
              discount: parseInt(cross_selling?.percentage),
              accumulate: true,
              type: 'cross_selling',
              description: cross_selling?.description,
            };
            const { discountCrossSelling } = _handleDiscounts(setup, service, totalDiscountApplied, ExceptionDiscountRules);
            totalDiscountApplied += discountCrossSelling;
            data.discountRules = ExceptionDiscountRules;
          }
        }

        const applicableService: any = setup.pk_services?.find((item: any) => item === service.PK);
        if (applicableService) {
          totalDiscountApplied = totalDiscountApplied + service?.shipping_price;
          service.free_delivery = true;
        }
      });
    }

    const payload: any = { ...data };
    const discount: any = Math.round(totalDiscountApplied + totalDiscountCrossSelling + totalDiscountByHour);

    if (discountRules?.type === 'by_day' && !setup.cumulative_day_discount) {
      discountRules = null;
    }

    const response: any = {
      cupon: cupon,
      services: services,
      total: payload.total_without_discount - discount,
      delivery: payload.delivery,
      total_without_discount: payload.total_without_discount,
      discount: discount,
      setup: setup,
      discountRules: discountRules,
      deliveryRules: data.deliveryRules,
      totalDiscountCrossSelling,
      totalDiscountByHour,
    };

    response.total = parseInt(response.total);
    response.total_without_discount = parseInt(response.total_without_discount);
    response.delivery = parseInt(response.delivery);

    if (cupon.is_first_check) {
      delete cupon.is_first_check;
      toast.success('Cupón aplicado con éxito.');
    }
    return response;
  } catch (e: any) {
    toast.error(e.message);
  }
};

const _handleDiscountByServices = (setup: any, service: any, totalDiscountApplied: any, discountRules: any) => {
  let discount: number = 0;
  let discountByHour: number = 0;
  let amount_discount: any = 0;
  let discountCrossSelling: number = 0;
  let price: any = parseInt(service.service?.price_total);

  setup.max_amount_discount = parseInt(setup.max_amount_discount);
  setup.use_limit = parseInt(setup.use_limit);

  /**
   *  Apply discounts before cupon
   *  @discounts
   */

  if (discountRules) {
    if (discountRules.type === 'by_day' && setup.cumulative_day_discount) {
      const responseDiscountByHour: any = _handleDiscountByHour(service);
      price = responseDiscountByHour?.price;
      discountByHour = responseDiscountByHour?.discountByHour;
      amount_discount += discountByHour;
    }

    if (discountRules.type === 'cross_selling') {
      discountCrossSelling += (price * discountRules.discount) / 100;
      price = price - discountCrossSelling;
      amount_discount += discountCrossSelling;

      service.related_products.map((rp: any) => {
        rp.price = rp.price || rp.pricing || rp.value || rp.precio;

        if (rp.quantity) {
          rp.price_total = rp.price * rp.quantity;
        } else {
          rp.price_total = rp.price;
        }

        rp.amount_discount = (rp.price_total * discountRules.discount) / 100;

        rp.price_discount = rp.price_total - rp.amount_discount;

        amount_discount += rp.amount_discount;

        discountCrossSelling += rp.amount_discount;

        return rp;
      });
    }
  }

  switch (setup.type_discount) {
    case 'porcentaje':
      discount = Math.round((service.service?.price_total * setup.max_amount_discount) / 100);
      amount_discount += discount / (service.service.quantity || 1);
      break;
    case 'litro':
      if (service.service?.info?.liter) {
        discount = (service.service?.quantity || 1) * parseInt(service.service?.info?.liter) * setup.max_amount_discount;
        amount_discount += discount;
      }
      break;
    case 'monto':
      discount = parseInt(setup.max_amount_discount) > parseInt(price) ? price : setup.max_amount_discount;
      amount_discount += discount;
      break;
    default:
      discount = 0;
  }

  const response: any = {
    price: price,
    totalDiscount: 0,
    isDiscounted: true,
    discountByHour,
    discountCrossSelling,
    amount_discount,
  };

  const discountLimit: number = parseInt(setup.use_limit);
  const discountAvailable: any = discountLimit - totalDiscountApplied;

  if (setup.use_limit === 0) {
    response.discountService = discount;
    return { ...response };
  }

  if (discountAvailable > 0) {
    if (discount <= discountAvailable) {
      response.price = price - discount;
      response.totalDiscountApplied = discount + response.amount_discount;
      response.discountService = discount;
    }

    if (discount > discountAvailable) {
      response.price = discountAvailable;
      response.totalDiscountApplied = discountAvailable + response.amount_discount;
      response.discountService = discountAvailable;
    }
  } else {
    response.totalDiscountApplied = discountAvailable + response.amount_discount;
    response.discountService = discountAvailable;
  }

  return { ...response };
};

const _handleDiscounts = (setup: any, service: any, totalDiscountApplied: any, discountRules: any) => {
  let discountByHour: number = 0;
  let amount_discount: any = 0;
  let discountCrossSelling: number = 0;
  let price: any = parseInt(service.service?.price_total);
  let discountService: any = 0;

  setup.max_amount_discount = parseInt(setup.max_amount_discount);
  setup.use_limit = parseInt(setup.use_limit);

  /**
   *  Apply discounts before cupon
   *  @discounts
   */

  if (discountRules) {
    if (discountRules.type === 'by_day' && setup.cumulative_day_discount) {
      const responseDiscountByHour: any = _handleDiscountByHour(service);
      price = responseDiscountByHour?.price;
      discountByHour = responseDiscountByHour?.discountByHour;
      amount_discount += discountByHour;
      discountService = discountByHour;
    }

    if (discountRules.type === 'cross_selling') {
      discountCrossSelling += (price * discountRules.discount) / 100;
      discountService = (price * discountRules.discount) / 100;
      discountService = parseInt(discountService);
      price = price - discountCrossSelling;
      amount_discount += discountCrossSelling;

      service.related_products.map((rp: any) => {
        rp.price = rp.price || rp.pricing || rp.value || rp.precio;

        if (rp.quantity) {
          rp.price_total = rp.price * rp.quantity;
        } else {
          rp.price_total = rp.price;
        }
        rp.amount_discount = (rp.price_total * discountRules.discount) / 100;

        rp.price_discount = rp.price_total - rp.amount_discount;

        amount_discount += rp.amount_discount;

        discountCrossSelling += rp.amount_discount;

        return rp;
      });
    }
  }

  const response: any = {
    price: price,
    totalDiscount: 0,
    isDiscounted: true,
    discountByHour,
    discountCrossSelling,
    amount_discount,
    discountService,
  };

  return { ...response };
};

const _handleDiscountByHour = (service: any) => {
  let discountByHour: any = 0;
  let price: any = 0;

  if (service.schedule) {
    const discountHour: any = parseInt(service.schedule.hour.discount) > 0 ? parseInt(service.schedule.hour.discount) : false;
    /**
     * Apply discount by hour
     */
    if (discountHour !== false) {
      discountByHour = (service.service.price_total * discountHour) / 100;
      price = service.service.price_total - discountByHour;
    } else {
      price = service.service?.price_total;
    }
  } else {
    price = service.service?.price_total;
  }

  return { price, discountByHour };
};

const _handleSumServices = (services: any) => {
  const total: any = services.reduce((acc: any, current: any) => {
    acc += current.service?.price - current.service?.amount_discount;

    current.related_products?.forEach((item: any) => {
      if (item.amount_discount) {
        acc += item.price_discount;
      } else {
        acc += item.price_total;
      }
    });
    return acc;
  }, 0);
  return total;
};

const _handleDiscountPercentage = (services: any, setup: any, totalProducts: any) => {
  /**
   *  @use_limit maximum discount limit to apply
   *  @max_amount_discount discount amount (percentage or ammount)
   */

  let totalDiscounted: any = 0;
  const discount: any = parseInt(setup.max_amount_discount);
  const availableDiscount: any = parseInt(setup.use_limit);
  const totalCart = _handleSumServices(services);
  let cartDiscount: any = (totalCart * discount) / 100;
  cartDiscount = parseInt(cartDiscount);

  if (availableDiscount > 0) {
    if (cartDiscount > availableDiscount) {
      let percentage: any = _getNewPercentageWhenLimitIsGreater(availableDiscount, discount, cartDiscount);
      totalDiscounted = _getCuponDiscountByCart(services, percentage);
      /**
       *  Check if totalDiscouted is greater then availableDiscount
       *  @totalDiscounted
       *  @availableDiscount
       */
      totalDiscounted = parseInt(totalDiscounted);
      if (totalDiscounted > availableDiscount) totalDiscounted = availableDiscount;
    }
  } else {
    totalDiscounted = _getCuponDiscountByCart(services, discount);
  }

  return [totalDiscounted];
};

const _getNewPercentageWhenLimitIsGreater = (availableDiscount: any, discount: any, cartDiscount: any) => {
  let percentage: any = (availableDiscount * discount) / cartDiscount;
  return percentage;
};

const _handleDiscountByAmount = (services: any, setup: any, totalProducts: any) => {
  const discount: any = parseInt(setup.max_amount_discount);
  let totalDiscounted: any = discount;
  const availableDiscount: any = parseInt(setup.use_limit);
  const totalCart = _handleSumServices(services);
  let cartDiscount: any = (totalCart * discount) / 100;
  cartDiscount = parseInt(cartDiscount);
  /**
   *  @use_limit maximum discount limit to apply
   *  @max_amount_discount discount amount (percentage or ammount)
   */

  if (availableDiscount > 0 && cartDiscount > availableDiscount) totalDiscounted = availableDiscount;

  let discountPerItem: any = totalDiscounted / totalProducts;

  for (const service of services) {
    /**
     *  If exists any discount, apply to price_discount. If not, apply to price
     *  @price_discount
     *  @price
     */
    if (service.service.amount_discount) {
      service.service.price_discount -= discountPerItem;
    } else {
      service.service.price_discount = service.service.price_total - discountPerItem;
    }

    if (service.service.price_discount < 0) service.service.price_discount = 0;

    /**
     *  Sum discount applied
     *  @totalDiscounted
     */

    service.service.amount_discount = service.service.amount_discount + discountPerItem;
    service.service.amount_discount = parseInt(service.service.amount_discount);
    service.service.price_discount = parseInt(service.service.price_discount);

    for (const related of service.related_products) {
      /**
       *  If exists any discount, apply to price_discount. If not, apply to price
       *  @price_discount
       *  @price
       */
      if (related.amount_discount) {
        related.price_discount -= discountPerItem;
      } else {
        related.price_discount = related.price_total - discountPerItem;
      }

      /**
       *  Sum discount applied
       *  @totalDiscounted
       */

      related.amount_discount = related.amount_discount + discountPerItem;
      related.amount_discount = parseInt(related.amount_discount);
      related.price_discount = parseInt(related.price_discount);

      if (related.price_discount < 0) related.price_discount = 0;
    }
  }

  return [totalDiscounted];
};

const _getCuponDiscountByCart = (services: any, percentage: any) => {
  let totalDiscounted: any = 0;
  for (const service of services) {
    let discountToAplly: any = 0;
    /**
     *  If exists any discount, apply to price_discount. If not, apply to price
     *  @price_discount
     *  @price
     */
    if (service.service.amount_discount) {
      discountToAplly = (service.service.price_discount * percentage) / 100;
      service.service.price_discount -= discountToAplly;
    } else {
      discountToAplly = (service.service.price_total * percentage) / 100;
      service.service.price_discount = service.service.price_total - discountToAplly;
    }

    /**
     *  Sum discount applied
     *  @totalDiscounted
     */

    totalDiscounted += discountToAplly;
    service.service.amount_discount = service.service.amount_discount + discountToAplly;
    service.service.amount_discount = parseInt(service.service.amount_discount);
    service.service.price_discount = parseInt(service.service.price_discount);

    for (const related of service.related_products) {
      /**
       *  If exists any discount, apply to price_discount. If not, apply to price
       *  @price_discount
       *  @price
       */
      if (related.amount_discount) {
        discountToAplly = (related.price_discount * percentage) / 100;
        related.price_discount -= discountToAplly;
      } else {
        discountToAplly = (related.price_total * percentage) / 100;
        related.price_discount = related.price_total - discountToAplly;
      }

      /**
       *  Sum discount applied
       *  @totalDiscounted
       */

      totalDiscounted += discountToAplly;
      related.amount_discount = related.amount_discount + discountToAplly;
      related.amount_discount = parseInt(related.amount_discount);
      related.price_discount = parseInt(related.price_discount);
    }
  }

  return totalDiscounted;
};

export const clear_cupons: any = (state: any) => {
  state.services.map((item: any) => {
    if (item.service) {
      item.service.amount_discount = 0;
    }

    if (item.related_products) {
      item.related_products.map((rp: any) => {
        rp.amount_discount = 0;
        return rp;
      });
    }
    return item;
  });

  return state;
};
