/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import TableLavamax from './TableLavamax';

const ServicePriceTableLavamax = (props: any) => {
  const [services, setServices] = useState([]);
  const [headers, setHeaders] = useState([]);
  const servicelist: any = ['Lavado Exterior', 'Lavado Full', 'Lavado de tapiz'];

  useEffect(() => {
    const { data } = props;
    const { sub_services } = data;

    const servicesFiltered: any = sub_services.filter((ss: any) => ss.name?.toLowerCase() !== 'envío' && ss.name?.toLowerCase() !== 'envio');

    servicesFiltered.sort((a: any, b: any) => {
      if (a?.info?.size > b?.info?.size) return 1;
      if (a?.info?.size < b?.info?.size) return -1;
      return 0;
    });

    const servicesGrouped: any = servicesFiltered.reduce((acc: any, current: any) => {
      const size: any = acc.find((item: any) => item.size === current.info?.size);

      if (!servicelist.find((s: any) => s.toLowerCase() === current.name?.toLowerCase())) {
        return acc;
      }

      if (size) {
        size[current.name] = current.price;
        return acc;
      }

      acc.push({
        size: current.info?.size,
        [current.name]: current.price,
      });

      return acc;
    }, []);

    if (servicesGrouped.length > 0) {
      const headers: any = Object.keys(servicesGrouped[0]).map((key: any) => key);
      setHeaders(headers);
    }

    setServices(servicesGrouped);
  }, []);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12 p-0">
          <TableLavamax data={services} headers={headers} />
        </div>
      </div>
    </div>
  );
};

export default ServicePriceTableLavamax;
