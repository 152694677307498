import { handleActions } from 'redux-actions';
import { SET_SPLASH } from '../actions/splash_screen';

export const INITIAL_STATE = {
  direction: false
};

export default handleActions({
  [SET_SPLASH]: (state: any, action: any) => {
    return { ...state, ...action.payload }
  }
}, INITIAL_STATE);