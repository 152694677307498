import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Images from '../../assets/image';
import Image from '../../components/Image';
import { useHistory } from 'react-router';
import Header from '../../template/DefaultTemplate/Header';
import RateService from '../../services/rate.service';



const PurchaseRating = () => {
    const history:any = useHistory()
    const { matchMedia } = useSelector((state: any) => state)

    const params:any = new URLSearchParams(history.location.search)
    const order_pk:any = params.get("order")
    const rate:any = params.get("v")

    useEffect(() => {
        _handleRun(order_pk,rate)
    },[order_pk,rate])

    const _handleRun = async (o:any,r:any) =>{
        let res:any = await RateService.create({
            order_id:o,
            rate:r,
            description:"",
            redirect:true
        })
        return res
    }

    const _handleFinish = () => {
        history.push('/');
    };

    return(
        <>
        <Header />
        <div className="container-fluid" style={{ maxWidth: '700px' }}>
            <div
                className="row mx-4 px-5"
                style={{
                    marginTop: matchMedia.media === 'xs' ? '100px' : '50px',
                }}
                >
                <Image className="w-100" image={Images.SuccessIcon} />
                <div className="col-12 text-center size-15 bold my-3 color-blue-base">¡Gracias por evaluar nuestro servicio de Copec a domicilio!</div>
            </div>
            <div className="row px-4 py-5 bg-color-blue-light mb-5 color-white">
              <button className="btn-default p-3 size-11" onClick={_handleFinish}>
                Volver al Inicio
              </button>
            </div>
        </div>
        </>
    )
}
export default PurchaseRating