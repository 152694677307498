/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import Images from '../../assets/image';
import { _containsLatLan } from '../../helpers/check_polygon';
import { _getGoogleAddressComponents } from '../../helpers/Utils';
import validate from '../../helpers/validate';
import { set_eds } from '../../redux/actions/available_eds';
import { setDirections } from '../../redux/actions/directions';
import { clear_services } from '../../redux/actions/services';
import { clear_cart } from '../../redux/actions/shopping_cart';
import { set_splash } from '../../redux/actions/splash_screen';
import sessionService from '../../services/session.service';
import { DirectionsManualSchema } from '../../validations/directions.manual.validations';
import ErrorValidation from '../ErrorValidation';
import Image from '../Image';
import Title from '../Title';
import Map from './Map';

const ManualDirectionsMobile = (props: any) => {
  const { google, handleClose, callback, data, addresses } = props;

  const [state, setState] = useState({
    inputs: {
      address: '',
      number: '',
      observations: '',
    },
    target: {
      latitude: null,
      longitude: null,
      address: null,
      formatted_address: null,
      streetNumber: {
        long_name: '',
        short_name: '',
      },
    },
    validations: {},
    step_one: true,
    step_two: false,
  });

  const { user } = useSelector((state: any) => state);
  const dispatch: any = useDispatch();
  const history: any = useHistory();

  useEffect(() => {
    if (data?.info?.address) {
      setState({
        ...state,
        inputs: { ...state.inputs, address: data.info.address, observations: data.info.observations, number: data.info.number },
        target: {
          ...state.target,
          latitude: data.latitude,
          longitude: data.longitude,
          address: data.info.address,
          formatted_address: data.info.address,
          streetNumber: { long_name: data.info.number, short_name: data.info.number },
        },
      });
    }
  }, []);

  const _handleChangeInputs = (event: any) => {
    const { target } = event;
    const { name, value } = target;

    setState({ ...state, inputs: { ...state.inputs, [name]: value } });
  };

  const _setTarget = (target: any) => {
    setState({ ...state, validations: {}, step_two: true, step_one: false, target: target, inputs: { ...state.inputs, address: target.address } });
  };

  const _handleSearchServices = async () => {
    const { target } = state;
    const { latitude, longitude, address, streetNumber } = target;

    if (callback) {
      callback(
        {
          _string: address,
          direction: address,
          address,
          longitude: longitude,
          latitude: latitude,
          number: streetNumber.long_name,
          observations: state.inputs.observations,
        },
        data && Object.keys(data).length > 0 ? true : false
      );
      return;
    }

    dispatch(set_splash({ direction_desktop: false, direction: true }));
    handleClose();

    /**
     *  If user is logged, save direction to history
     *  @directions
     */

    if (user.isLogged) {
      const payload: any = {
        longitude: longitude,
        latitude: latitude,
        name: 'Principal',
        main: false,
        info: {
          address: address,
          number: streetNumber.long_name,
          observations: state.inputs.observations,
          apartment: state.inputs.observations,
          history: true,
          history_date: moment().toString(),
        },
      };

      try {
        if (addresses && addresses.length > 0) {
          const isAddressListed: any = addresses.find((item: any) => item.info?.address === address);
          if (!isAddressListed) {
            await sessionService.createAddress(payload);
          } else {
            isAddressListed.info.history_date = moment().toString();
            await sessionService.updateAddress(isAddressListed?.SK, isAddressListed);
          }
        } else {
          await sessionService.createAddress(payload);
        }
      } catch (e: any) {
        toast.warning('No fue posible ingresar esta direccion al histórico');
      }
    }
    const available_eds = await _containsLatLan(latitude, longitude);

    if (!available_eds) {
      dispatch(set_splash({ direction: false, direction_desktop: false }));
      return;
    }

    dispatch(
      setDirections({
        _string: address,
        direction: address,
        longitude: longitude,
        latitude: latitude,
        observations: state.inputs.observations,
      })
    );
    dispatch(clear_cart());
    dispatch(clear_services());
    dispatch(set_splash({ direction: false, direction_desktop: false }));
    dispatch(set_eds(available_eds));
    history.push('/');
  };

  const _handleSearch = async () => {
    const { inputs } = state;
    const { address, number } = inputs;

    const validations: any = await validate(DirectionsManualSchema, inputs);
    setState({ ...state, validations });
    if (validations) {
      return;
    }

    try {
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({ address: `${address} ${number}` }, async (response: any, status: any) => {
        if (status === 'OK') {
          const directions = response[0];
          const { geometry, address_components } = directions;

          const { streetNumber, street, country, commune_adm_lvl_3, region } = _getGoogleAddressComponents(address_components, number);

          if (!street) {
            toast.error('No fue posible encontrar la dirección');
            dispatch(set_splash({ direction: false, direction_desktop: false }));
            return;
          }

          const address: any = `${street?.long_name} ${streetNumber?.long_name}, ${commune_adm_lvl_3?.long_name || ''} - ${region?.long_name || ''} ${country?.long_name || ''}`;

          const { location } = geometry;
          const latitude = location.lat();
          const longitude = location.lng();
          _setTarget({ latitude, longitude, address, streetNumber });
        } else {
          toast.error('No fue posible encontrar la dirección');
          dispatch(set_splash({ direction: false, direction_desktop: false }));
        }
      });
    } catch (e) {
      toast.error('No fue posible encontrar la dirección');
      dispatch(set_splash({ direction: false, direction_desktop: false }));
    }
  };

  const _handleOnClickOnMap = (payload: any) => {
    setState((prevState: any) => {
      return { ...prevState, target: { ...prevState.target, latitude: payload.latitude, longitude: payload.longitude } };
    });
  };

  return (
    <>
      {state.step_one ? (
        <div className="container-address__script">
          <div className="container-address__script__header mb-4">
            <Title size="md" text="Ingresa una dirección" className="color-dark-blue px-3 pt-3 text-center" />
            <div className="container-address__script__header__close" onClick={handleClose}>
              <button className="btn">
                <Image image={Images.CloseBlue} />
              </button>
            </div>
          </div>

          <div className="row position-relative ">
            <div className="col-12">
              <input type="text" className="input-default py-4 pr-4 size-09" value={state.inputs.address} name="address" onChange={_handleChangeInputs} style={{ paddingLeft: '110px' }} />
              <span className="bold size-09">Dirección:</span>
            </div>
          </div>
          <ErrorValidation name="address" validations={state.validations} className="text-end size-08 px-4 py-2 m-0 bold-300" />

          <div className="row mt-4 ">
            <div className="col-12 position-relative">
              <input type="text" className="input-default py-4 size-09" name="number" value={state.inputs.number} onChange={_handleChangeInputs} style={{ paddingLeft: '130px' }} />
              <span className="bold size-09">Número:</span>
            </div>
          </div>
          <ErrorValidation name="number" validations={state.validations} className="text-end size-08 px-4 py-2 m-0 bold-300" />

          <div className="row px-3">
            <div className="col-12 px-4 mt-4">
              <span className="bold size-09 color-black">Observación:</span>
              <textarea className="textarea-default size-09 mt-2 px-4 py-3" name="observations" value={state.inputs.observations} onChange={_handleChangeInputs} />
            </div>
          </div>
          <ErrorValidation name="observations" validations={state.validations} className="text-end size-08 px-4 py-2 m-0 bold-300" />

          <div className="row justify-content-center mt-5">
            <div className="col-8">
              <button type="button" className="btn-default w-100 size-11 py-3" onClick={_handleSearch}>
                {' '}
                Continuar
              </button>
            </div>
          </div>
        </div>
      ) : null}

      {state.step_two ? (
        <div className="container-address__script">
          <div className="container-address__script__header mb-4">
            <Title size="md" text="Ingresa una dirección" className="color-dark-blue px-3 pt-3 text-center" />
            <div className="container-address__script__header__close" onClick={handleClose}>
              <button className="btn">
                <Image image={Images.CloseBlue} />
              </button>
            </div>
          </div>
          <div className="row position-relative ">
            <div className="col-12">
              <input type="text" className="input-default py-4 pr-4 size-09" value={state.inputs.address} name="address" onChange={_handleChangeInputs} style={{ paddingLeft: '110px' }} disabled />
              <span className="bold size-09">Dirección:</span>
            </div>
          </div>
          <div className="col-12 px-3 mt-4">
            <Map google={google} target={state.target} setPin enableClick onClickMap={_handleOnClickOnMap} height={'60vh'} />
          </div>
          <div className="col-12 p-3 text-center mt-3">
            <button className="btn-default py-3 w-100 size-12" disabled={!state.target?.latitude && !state.target.longitude} onClick={_handleSearchServices}>
              Confirmar dirección
            </button>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default ManualDirectionsMobile;
