/* eslint-disable react-hooks/exhaustive-deps */
import Header from './Header';
import IDefaultTemplate from '../../interfaces/DefaultTemplate';
import Footer from './Footer';
import Adjustment from '../../components/Adjustment';
import AdjustmentDesktop from '../../components/AdjustmentDesktop';

import '../../styles/template.default_template.scss';
import ButtonBack from '../../components/ButtonBack';
import { useHistory } from 'react-router-dom';

const EmptyTemplate: React.FC<IDefaultTemplate> = (props) => {
  const { children, noHeader, hideBackButton } = props;
  const history: any = useHistory();
  return (
    <div className="container-template-default">
      {noHeader || <Header />}
      <Adjustment />
      <AdjustmentDesktop />
      {!hideBackButton && (
        <div className="row mt-5 px-0 px-sm-5 color-dark-blue">
          <ButtonBack action={() => history.push('/')} text="Volver" />
        </div>
      )}
      {children}
      <Footer />
    </div>
  );
};

export default EmptyTemplate;
