import instanceOvert from './api_overt';
import instanceAccount from './api_account';
import store from '../redux/store';

class ScheduleDataAccess {
  _setHeaders = () => {
    const state: any = store.getState();
    const { user } = state;
    if (user.isLogged) {
      instanceAccount.defaults.headers.common['user'] = user.profile?.PK;
      instanceAccount.defaults.headers.common['usersk'] = user.profile?.SK;
      instanceAccount.defaults.headers.common['Authorization'] = user?.session?.AuthenticationResult?.AccessToken;
    } else {
      delete instanceAccount.defaults.headers.common['user'];
      delete instanceAccount.defaults.headers.common['usersk'];
      delete instanceAccount.defaults.headers.common['Authorization'];
    }
  };

  getAvailableTime = async (payload: any) => {
    try {
      const response = await instanceOvert.post('/overt/check-schedule', payload);
      return response;
    } catch (e: any) {
      console.log('LOGGER', e.message);
      return Promise.reject(e);
    }
  };

  reSchedule = async (payload: any, data: any) => {
    this._setHeaders();
    try {
      const response = await instanceAccount.put(`/account/client/reservation/reschedule/${payload.PK}?PK=${payload.PK}&SK=${payload.SK}`, data);
      return response;
    } catch (e: any) {
      console.log('LOGGER', e.message);
      return Promise.reject(e);
    }
  };
}

const Schedule = new ScheduleDataAccess();
export default Schedule;
