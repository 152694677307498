import store from '../redux/store';
import instance from './api_account';
class OrderService {
  _setHeaders = () => {
    const state: any = store.getState();
    const { user } = state;
    if (user.isLogged) {
      instance.defaults.headers.common['user'] = user.profile?.PK;
      instance.defaults.headers.common['usersk'] = user.profile?.SK;
      instance.defaults.headers.common['Authorization'] = user?.session?.AuthenticationResult?.AccessToken;
    } else {
      delete instance.defaults.headers.common['user'];
      delete instance.defaults.headers.common['usersk'];
      delete instance.defaults.headers.common['Authorization'];
    }
  };

  list = async () => {
    this._setHeaders();
    try {
      const { user } = store.getState();
      const response = await instance.get('/account/order/list', { headers: { userid: user.profile?.PK } });
      return response.data;
    } catch (e: any) {
      console.log('LOGGER', e);
      return Promise.reject(e);
    }
  };
  detail = async (PK: any) => {
    this._setHeaders();
    try {
      const response = await instance.get(`/account/client/orden/${PK}`);
      return response.data;
    } catch (e: any) {
      console.log('LOGGER', e);
      return Promise.reject(e);
    }
  };

  changeStatus = async (params: any) => {
    this._setHeaders();
    try {
      params.status = params.status?.toLowerCase();
      const response = await instance.post('/account/client/order/update/state', params);
      return response.data;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };
}

const Order = new OrderService();
export default Order;
