import moment from 'moment';
import { handleActions } from 'redux-actions';
import { SET_RECOMMENDED } from '../actions/recommended_subservices';

export const INITIAL_STATE = {
  services: [],
  last_update: moment().toString(),
};

export default handleActions(
  {
    [SET_RECOMMENDED]: (state: any, action: any) => {
      state.services = { ...action.payload };
      state.last_update = moment().toString();
      return { ...state };
    },
  },
  INITIAL_STATE
);
