export const menu = [
  {
    label: 'Mis datos',
    icon: 'UserInput',
    selected: 'UserInputWhite',
    url: '/profile/personal-data',
    home: '/profile',
    roles: ['client', 'client_company'],
  },
  {
    label: 'Mi historial',
    icon: 'History',
    selected: 'HistoryWhite',
    url: '/profile/my-history',
    roles: ['client', 'client_company'],
  },
  {
    label: 'Mis Suscripciones',
    icon: 'CuponMenu',
    selected: 'CuponMenuWhite',
    url: '/profile/my-subscriptions',
    roles: ['client', 'client_company'],
  },
  {
    label: 'Mis direcciones',
    icon: 'DirectionInput',
    selected: 'DirectionInputWhite',
    url: '/profile/my-directions',
    roles: ['client', 'client_company'],
  },
  {
    label: 'Mis vehículos',
    icon: 'MyCars',
    selected: 'MyCarsWhite',
    url: '/profile/my-cars',
    roles: ['client', 'client_company'],
  },
  {
    label: 'Mi ahorro de agua',
    icon: 'Water',
    selected: 'WaterWhite',
    url: '/profile/my-water-saving',
    roles: ['client', 'client_company'],
  },
  {
    label: 'Cambiar contraseña',
    icon: 'PasswordInput',
    selected: 'PasswordInputWhite',
    url: '/profile/my-password',
    roles: ['client', 'client_company'],
  },
  {
    label: 'Usuarios',
    icon: 'UserInput',
    selected: 'UserInputWhite',
    url: '/empresa/usuarios',
    roles: ['user_company'],
  },
];
