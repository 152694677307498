import Images from '../../assets/image';
import Image from '../Image';
import QuantitySelect from '../QuantitySelect';

const CheckoutProductItem = (props: any) => {
  const { product, service, _handlePlusProduct, _handleMinusProduct, _handleDeleteProduct } = props;
  return (
    <div className="row bg-color-white rounded-15 border-default py-4 align-items-center my-3" key={product.PK}>
      <div className="col-2 text-center">
        <Image className="w-75" image={product.info?.gallery?.url ? { default: { src: product.info?.gallery?.url } } : Images.NotFound} />
      </div>
      <div
        className="col-5 size-11 color-black bold-300"
        dangerouslySetInnerHTML={{
          __html: product.title || product._html || product.name,
        }}
      />
      <div className="col-3 color-black d-flex flex-column justify-content-center align-items-center">
        <div className="mb-3 size-14 bold-300">${product.price_total?.toLocaleString('es-CL')}</div>

        {product.quantity && !product.oneItem && !product.info?.air_filter && (
          <QuantitySelect
            quantity={product.quantity}
            onAdd={() =>
              _handlePlusProduct({
                service: service,
                product: product,
              })
            }
            onRemove={() =>
              _handleMinusProduct({
                service: service,
                product: product,
              })
            }
          />
        )}
      </div>
      <div className="col-2 d-flex justify-content-between mt-3">
        <button
          className="btn color-blue-base size-11 bold"
          onClick={() =>
            _handleDeleteProduct({
              service: service,
              product: product,
            })
          }
        >
          <u>Eliminar</u>
        </button>
      </div>
    </div>
  );
};

export default CheckoutProductItem;
