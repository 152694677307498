/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Router, Switch } from 'react-router';
import ShoppingCart from '../components/ShoppingCart';
import SplashDirection from '../components/SplashScreens/SplashDirection';
import SplashDirectionDesktop from '../components/SplashScreens/SplashDirectionDesktop';
import ActionBottom from '../components/ActionBottom';
import Checkout from '../pages/Checkout';
import Checkout2 from '../pages/Checkout2';
import HomeDesktop from '../pages/HomeDesktop';
import KeresoneInfo from '../pages/KeroseneInfo';
import Lubinfo from '../pages/LubInfo';
import OrderSuccess from '../pages/OrderSuccess';
import PurchaseRating from '../pages/PurchaseRating';

import Recovery from '../pages/Recovery';
import Register from '../pages/Register';
import SignIn from '../pages/SignIn';
import WashInfo from '../pages/WashInfo';
import { SET_MEDIA } from '../redux/actions/matchMedia';
import history from '../services/history';
import DefaultTemplate from '../template/DefaultTemplate';
import Services from '../pages/Services';
import SetTemplate from './SetTemplate';
import Loader from '../components/Loader';
import Profile from '../pages/Profile';
import ProfileTemplate from '../template/ProfileTemplate';
import PersonalData from '../pages/PersonalData';
import UpdateProfile from '../pages/PersonalData/update';
import Directions from '../pages/Directions';
import DirectionsCreate from '../pages/Directions/DirectionsCreate';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import Cars from '../pages/Cars';
import CarCreate from '../pages/Cars/CarCreate';
import ChangePassword from '../pages/ChangePassword';
import OrderHistory from '../pages/OrderHistory';
import OrderDetails from '../pages/OrderHistory/OrderDetails';
import ConfirmationCode from '../pages/ConfirmationCode';
import Faq from '../pages/Faq';
import DirectionsUpdate from '../pages/Directions/DirectionsUpdate';
import CarUpdate from '../pages/Cars/CarUpdate';
import DirectionCache from '../components/DirectionCache';
import InfoTemplate from '../template/InfoTemplate';
import EmptyTemplate from '../template/InfoTemplate/EmptyTemplate';
import SignInCognito from '../pages/SignInCognito';
import { clear_user } from '../redux/actions/user';

import Cookies from '../pages/Info/Cookies';
import Policies from '../pages/Info/Policies';
import Terms from '../pages/Info/Terms';
import WhatsApp from '../components/WhatsApp';
import getPageInfo from '../mockups/pages';
import OrderStatus from '../pages/Info/OrderStatus';
import { sendDataLayerArguments, sendDataLayerEvent } from '../helpers/data_layer';
import CookieConsent from '../components/CookieConsent';
import WaterSaving from '../pages/WaterSaving';
import FrequentQuestions from '../components/Alima/frequentQuestions';
import SignInAdmin from '../pages/SignInAdmin';
import CompanyTemplate from '../template/CompanyTemplate';
import CompanyUsers from '../pages/CompanyUsers';
import ConfirmationCodeCompany from '../pages/ConfirmationCodeCompany';
import Subscriptions from '../pages/Subscriptions';

const TemplatedCookies = () => SetTemplate(InfoTemplate, Cookies);
const TemplatedPolicies = () => SetTemplate(InfoTemplate, Policies);
const TemplatedTerms = () => SetTemplate(InfoTemplate, Terms);

const TemplatedHome = () => SetTemplate(DefaultTemplate, HomeDesktop);
const TemplatedKeresoneInfo = () => SetTemplate(InfoTemplate, KeresoneInfo);
const TemplatedLubinfo = () => SetTemplate(InfoTemplate, Lubinfo);
const TemplatedWashInfo = () => SetTemplate(InfoTemplate, WashInfo);
const TemplatedCheckout = () => SetTemplate(DefaultTemplate, Checkout);
const TemplatedCheckout2 = () => SetTemplate(DefaultTemplate, Checkout2);
const TemplatedServices = () => SetTemplate(DefaultTemplate, Services);
const TemplatedServiceFaq = () => SetTemplate(DefaultTemplate, Faq);
const TemplatedFrequentAsk = (props: any) => SetTemplate((e: any) => InfoTemplate({ ...props, ...e, hideBackButton: false }), FrequentQuestions);
const ProfilePersonalData = () => SetTemplate(ProfileTemplate, PersonalData);
const ProfileSubscriptions = () => SetTemplate(ProfileTemplate, Subscriptions);
const TemplatedCompanyUsers = () => SetTemplate(CompanyTemplate, CompanyUsers);
const ProfilePersonalDataUpdate = () => SetTemplate(ProfileTemplate, UpdateProfile);
const ProfileDirections = () => SetTemplate(ProfileTemplate, Directions, null, '/profile');
const ProfileDirectionsCreate = () => SetTemplate(ProfileTemplate, DirectionsCreate, null, '/profile/my-directions');
const ProfileDirectionsUpdate = () => SetTemplate(ProfileTemplate, DirectionsUpdate, null, '/profile/my-directions');
const ProfileCars = () => SetTemplate(ProfileTemplate, Cars, null, '/profile');
const ProfileCarsCreate = () => SetTemplate(ProfileTemplate, CarCreate, null, '/profile/my-cars');
const ProfileCarsUpdate = () => SetTemplate(ProfileTemplate, CarUpdate, null, '/profile/my-cars');
const ProfilePassword = () => SetTemplate(ProfileTemplate, ChangePassword);
const ProfileUpdatePassword = () => SetTemplate(ProfileTemplate, ChangePassword);
const ProfileOrderhistory = () => SetTemplate(ProfileTemplate, OrderHistory);
const ProfileWaterSaving = () => SetTemplate(ProfileTemplate, WaterSaving);
const ProfileOrderDetails = () => SetTemplate(ProfileTemplate, OrderDetails);
const TemplatedOrderStatus = () => SetTemplate(EmptyTemplate, OrderStatus);

const AppRouter = () => {
  const [eventListener, setEventListener] = useState(false);
  const [reactGTMLoaded, setReactGTMLoaded] = useState(false);
  const dispatch = useDispatch();
  const state = useSelector((data: any) => data);
  const { user, gtm_consent } = state;
  const { matchMedia } = state;
  const { media } = matchMedia;

  if (user.isLogged && user.profile && !user.profile.name) {
    dispatch(clear_user());
  }

  const [matchMedias, setMatchMedias] = useState<any>({
    xs: {
      media: '(max-width: 575px)',
      matchMedia: null,
      _toString: 'xs',
    },
    sm: {
      media: '(min-width: 576px)',
      matchMedia: null,
      _toString: 'sm',
    },
    md: {
      media: '(min-width: 768px)',
      matchMedia: null,
      _toString: 'md',
    },
    lg: {
      media: '(min-width: 992px)',
      matchMedia: null,
      _toString: 'lg',
    },
    xl: {
      media: '(min-width: 1200px)',
      matchMedia: null,
      _toString: 'xl',
    },
    xxl: {
      media: '(min-width: 1400px)',
      matchMedia: null,
      _toString: 'xxl',
    },
  });

  useEffect(() => {
    Object.keys(matchMedias).forEach((key) => {
      matchMedias[key].matchMedia = window.matchMedia(matchMedias[key].media);
    });

    setMatchMedias(matchMedias);
    if (eventListener) {
      window.removeEventListener('resize', handleMediaQuerys, true);
      window.addEventListener('resize', handleMediaQuerys, true);
    } else {
      window.addEventListener('resize', handleMediaQuerys, true);
      setEventListener(true);
    }
    handleMediaQuerys();
    let pathname = '';
    history.listen((his: any) => {
      if (pathname !== his.pathname) {
        sendDataLayerData();
        setTimeout(() => {
          document.querySelector('html')?.scrollTo({
            top: 1,
            behavior: 'smooth',
          });
        }, 100);
        pathname = his.pathname;
      }
    });
  }, [media]);

  const sendDataLayerData = () => {
    const Window: any = window;
    Window.dataLayer = Window.dataLayer || [];
    const { location } = history;
    let dataLayerItem: any = {
      event: 'page_view',
      pageUrl: location.pathname,
      baseUrl: window.location.origin,
    };

    const pageInfo: any = getPageInfo(location?.pathname);
    if (pageInfo) {
      dataLayerItem.title = pageInfo.title;
      document.title = `Copec a domicilio - ${pageInfo.title}`;
    } else {
      document.title = `Copec a domicilio`;
    }

    sendDataLayerEvent(dataLayerItem);
  };

  const getInitialDataLayerData = () => {
    const { location } = history;
    let dataLayerItem: any = {
      event: 'page_view',
      pageUrl: location.pathname,
      baseUrl: window.location.origin,
      fullUrl: `${window.location.href}`,
    };

    const pageInfo: any = getPageInfo(location?.pathname);
    if (pageInfo) {
      dataLayerItem.title = pageInfo.title;
      document.title = `Copec a domicilio - ${pageInfo.title}`;
    } else {
      document.title = `Copec a domicilio`;
    }

    return dataLayerItem;
  };

  const handleMediaQuerys = () => {
    let size = '';
    Object.keys(matchMedias).forEach((key) => {
      if (matchMedias[key].matchMedia.matches) {
        size = matchMedias[key]._toString;
      }
    });
    if (media !== size) {
      dispatch(SET_MEDIA(size));
    }
  };

  useEffect(() => {
    try {
      const dataLayer: any = getInitialDataLayerData();
      if (process.env.REACT_APP_ENVIROMENT === 'DEV' && reactGTMLoaded === false) {
        if (!gtm_consent.show) {
          const consent: any = { ...gtm_consent };
          delete consent.show;
          sendDataLayerArguments('consent', 'update', consent);
        }
        sendDataLayerEvent(dataLayer)
        setReactGTMLoaded(true);
      }
      if (process.env.REACT_APP_ENVIROMENT === 'PRO' && reactGTMLoaded === false) {
        if (!gtm_consent.show) {
          const consent: any = { ...gtm_consent };
          delete consent.show;
          sendDataLayerArguments('consent', 'update', consent);
        }
        sendDataLayerEvent(dataLayer)
        setReactGTMLoaded(true);
      }
    } catch (e: any) {
      console.error('No se pudo iniciar Google Tag Manager');
    }
  }, []);

  return (
    <Router history={history}>
      <CookieConsent />
      <ToastContainer />
      <Loader />
      <ShoppingCart />
      <SplashDirection />
      <SplashDirectionDesktop />
      <ActionBottom />
      <DirectionCache />
      <WhatsApp />
      <Switch>
        <Route exact path="/rating/purchase" component={PurchaseRating} />
        <Route exact path="/rating/service" component={PurchaseRating} />

        <Route exact path="/" component={TemplatedHome} />
        <Route exact path="/services/:slug" component={TemplatedServices} />
        <Route exact path="/services/:slug/update/:id" component={TemplatedServices} />
        <Route exact path="/services/info/kerosene" component={TemplatedKeresoneInfo} />
        <Route exact path="/services/info/cambio-aceite" component={TemplatedLubinfo} />
        <Route exact path="/services/info/lavado" component={TemplatedWashInfo} />
        <Route exact path="/check-out/step-1" component={TemplatedCheckout} />
        <Route exact path="/check-out/step-2" component={TemplatedCheckout2} />
        <Route exact path="/faq/:service" component={TemplatedServiceFaq} />
        <Route exact path="/preguntas-frecuentes" component={() => TemplatedFrequentAsk({ hideBackButton: true })} />

        <Route exact path="/order/:id" component={OrderSuccess} />
        <Route exact path="/order/:id/:status" component={OrderSuccess} />
        <Route exact path="/sign-in" component={SignIn} />
        <Route exact path="/admin/sign-in" component={SignInAdmin} />
        <Route exact path="/sign-in/cognito" component={SignInCognito} />
        <Route exact path="/recovery" component={Recovery} />
        <Route exact path="/register" component={Register} />
        <Route exact path="/confirmation-code" component={ConfirmationCode} />
        <Route exact path="/empresa-confirmation-code" component={ConfirmationCodeCompany} />

        <Route exact path="/profile" component={Profile} />
        <Route exact path="/profile/personal-data" component={ProfilePersonalData} />
        <Route exact path="/profile/personal-data/update" component={ProfilePersonalDataUpdate} />
        <Route exact path="/profile/my-directions" component={ProfileDirections} />
        <Route exact path="/profile/my-directions/create" component={ProfileDirectionsCreate} />
        <Route exact path="/profile/my-directions/update/:id" component={ProfileDirectionsUpdate} />
        <Route exact path="/profile/my-cars" component={ProfileCars} />
        <Route exact path="/profile/my-water-saving" component={ProfileWaterSaving} />
        <Route exact path="/profile/my-cars/create" component={ProfileCarsCreate} />
        <Route exact path="/profile/my-cars/update/:id" component={ProfileCarsUpdate} />
        <Route exact path="/profile/my-password" component={ProfilePassword} />
        <Route exact path="/profile/my-password/update" component={ProfileUpdatePassword} />
        <Route exact path="/profile/my-history" component={ProfileOrderhistory} />
        <Route exact path="/profile/my-history/:id" component={ProfileOrderDetails} />
        <Route exact path="/profile/my-subscriptions" component={ProfileSubscriptions} />
        <Route exact path="/profile/my-subscriptions/:id" component={ProfileOrderhistory} />

        <Route exact path="/empresa/usuarios" component={TemplatedCompanyUsers} />

        <Route exact path="/terminos-y-condiciones" component={TemplatedTerms} />
        <Route exact path="/politicas" component={TemplatedPolicies} />
        <Route exact path="/cookies" component={TemplatedCookies} />

        <Route exact path="/order-processing" component={TemplatedOrderStatus} />

        <Redirect to="/" />
      </Switch>
    </Router>
  );
};

export default AppRouter;
