import Title from "../Title"
import Image from "../Image"
import Images from "../../assets/image"
import { useDispatch, useSelector } from "react-redux"
import { set_actionbar } from "../../redux/actions/action_bar"
import Modal from "../Modal"
import { useHistory } from "react-router-dom"

const ActionBottomModalDesktop = (props: any) => {
    const { _handleGotoService, _handleGotoCheckout } = props
    const { shopping_cart, action_bar } = useSelector((state: any) => state)
    const dispatch: any = useDispatch()
    const history: any = useHistory()

    const _closeWindowAndRedirectToHome = () => {
        dispatch(set_actionbar({ ...action_bar, modal_open: false }))
        history.push('/')
    }

    return (
        <Modal open={action_bar?.modal_open}>
            <div className="bg-color-white rounded-25 position-relative m-0 px-4 py-5" style={{ minWidth: '450px', maxWidth: '520px', maxHeight: '80vh' }}>
                <Title size="md" text="También puedes agregar" className="color-dark-blue text-center bold mb-4" />

                <button className="btn" onClick={_closeWindowAndRedirectToHome} style={{ position: 'absolute', top: '15px', right: '15px' }}>
                    {' '}
                    <Image image={Images.CloseBlue} />
                </button>

                {shopping_cart?.cross_selling?.is_available && shopping_cart?.services?.length === 1 ? (
                    <div className="row btn-dark-blue m-0 mb-3 p-3 rounded bold-300">
                        <div className="col-12">
                            Pide otro servicio ahora y obtén un {shopping_cart.cross_selling?.percentage}% de descuento en tu carrito.
                        </div>
                    </div>
                ) : null}

                <div className=" px-2" style={{ maxHeight: '50vh', overflow: 'auto' }}>
                    {action_bar?.unselected_services.map((service: any) => (
                        <div className="row m-0 py-0 component-available-services__services__card mb-3" key={service.PK}>
                            <div className="col-4 m-0 p-0 ">
                                <Image
                                    image={service.info?.gallery?.url ? { default: { src: service.info?.gallery?.url } } : Images.NotFound}
                                    style={{ width: '130px', height: '130px', objectFit: 'cover', borderRadius: '15px 0 0 15px' }}
                                />
                            </div>
                            <div className="col-8 d-flex flex-column justify-content-center align-items-start ps-4">
                                <div>{service.name === 'lub' ? 'Cambio de Aceite' : service.name}</div>
                                <button className="btn-green-light py-1 mt-2" onClick={() => _handleGotoService(`/services/${service.url}`)}>
                                    Pide aquí
                                </button>
                            </div>
                        </div>
                    ))}
                </div>

                <div className="row mt-4 px-2 mb-4 color-white">
                    <button className="btn-dark-blue bold p-3 size-11 rounded-10 color-white" onClick={_handleGotoCheckout}>
                        No, gracias
                    </button>
                </div>
            </div>
        </Modal>
    )
}

export default ActionBottomModalDesktop;
