/* eslint-disable react-hooks/exhaustive-deps */
import './style.scss';

import IconLub from '../../assets/image/svg/icon-aceite.svg';
import IconKer from '../../assets/image/svg/icon-kerosene.svg';
import IconLav from '../../assets/image/svg/icon-lavado.svg';
import CloseIcon from '@rsuite/icons/Close';
import { useEffect, useState } from 'react';

const CompanyClientPopUp = (props: any) => {
  const { accept, cancel, open, title, message } = props;

  const [state, setState] = useState({
    message: [],
  });

  useEffect(() => {
    if (open) {
      const messageArray = message.split('<br>');
      setState({ ...state, message: messageArray });
    }
  }, [open])

  const handleClick = (e: any) => {
    const className = e.target?.className;
    if (typeof className === 'string' && className?.trim() === 'welcome-popup') {
      cancel();
    }
  }

  return (
    <div className="welcome-popup" style={{ display: open ? 'block' : 'none' }} onClick={handleClick}>
      <div className="welcome-popup__popup">
        <div className="welcome-popup__popup__wrapper">
          <div className="welcome-popup__popup__wrapper__header">
            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={cancel}>
              <CloseIcon />
            </button>
            <h2>Copec a domicilio</h2>
            <hr />
          </div>

          <div className="welcome-popup__popup__wrapper__body">

            <div className='d-flex justify-content-around mb-4'>
              <img src={IconLub} alt="Icono Aceite" />
              <img src={IconKer} alt="Icono Kerosene" />
              <img src={IconLav} alt="Icono Lavado" />
            </div>
            <hr />
            <h4>
              {title}
            </h4>

            <div className='size-11 bold py-3'>
              {state.message?.map((item: any, index: number) => (
                <div key={index}>{item}</div>
              ))}
            </div>

            <button className="btn-promo bold mt-5" type="button" onClick={accept}>
              SI
            </button>

            <button className="btn-promo bold mt-5" type="button" onClick={cancel}>
              NO
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyClientPopUp;
