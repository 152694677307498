import Drawer from "../../components/Drawer"
import SelectCar from "./SelectCar"

const DrawerSelectCar = (props: any) => {

  const { show, _handleState } = props

  return (

    <Drawer show={show} direction="right-left" className="drawer-with-header" >
      <SelectCar _handleState={_handleState} id={props.id} PK={props.PK} SK={props.SK} show={show} />
    </Drawer >
  )
}

export default DrawerSelectCar