import instance from './api_overt';

class NewCarService {
  _searchPlate = async (payload: any) => {
    try {
      const response: any = await instance.get(`/overt/api/vehiculo/patentes?patente=${payload.plate}&estacion=${payload.eds_code}`);
      return response.data;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  _getDimensions = async (payload: any) => {
    try {
      const response: any = await instance.get(`/overt/api/vehiculo/dimension?marca=${payload.brand}&modelo=${payload.model}`);
      return response.data;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };
}

const CarApiService = new NewCarService();
export default CarApiService;
