import store from '../redux/store';
import instanceAccount from './api_account';
import instanceOvert from './api_overt';

class Session {
  _setHeaders = () => {
    const state: any = store.getState();
    const { user } = state;
    if (user.isLogged) {
      instanceAccount.defaults.headers.common['user'] = user.profile?.PK;
      instanceAccount.defaults.headers.common['usersk'] = user.profile?.SK;
      instanceAccount.defaults.headers.common['Authorization'] = user?.session?.AuthenticationResult?.AccessToken;
      instanceOvert.defaults.headers.common['user'] = user.profile?.PK;
      instanceOvert.defaults.headers.common['usersk'] = user.profile?.SK;
      instanceOvert.defaults.headers.common['Authorization'] = user?.session?.AuthenticationResult?.AccessToken;
    } else {
      delete instanceAccount.defaults.headers.common['user'];
      delete instanceAccount.defaults.headers.common['usersk'];
      delete instanceAccount.defaults.headers.common['Authorization'];
      delete instanceOvert.defaults.headers.common['user'];
      delete instanceOvert.defaults.headers.common['usersk'];
      delete instanceOvert.defaults.headers.common['Authorization'];
    }
  };

  setStorage = (params: any) => {
    let storage: any = localStorage.getItem('session');
    if (storage) {
      storage = JSON.parse(storage);
      storage = { ...storage, ...params };
      localStorage.setItem('session', JSON.stringify(storage));
      return;
    }

    localStorage.setItem('session', JSON.stringify(params));
  };

  getStorage = () => {
    let storage = localStorage.getItem('session');

    if (storage) {
      return JSON.parse(storage);
    }

    return false;
  };

  signin = async (params: any) => {
    this._setHeaders();
    try {
      const response = await instanceOvert.post('/overt/sign-in', params);
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  confirmCode = async (params: any) => {
    this._setHeaders();
    try {
      const response = await instanceOvert.post('/overt/confirm/code', params);
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  register = async (params: any) => {
    this._setHeaders();
    try {
      const response = await instanceOvert.post('/overt/sign-up', params);
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  updateProfile = async (params: any, headers: any = null) => {
    this._setHeaders();
    try {
      const response = await instanceAccount.put('/account/update', params, { headers: headers || {} });
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  forgotPassword = async (params: any) => {
    this._setHeaders();
    try {
      const response = await instanceOvert.post('/overt/forgot/password', params);
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  forgotPasswordPublic = async (params: any) => {
    this._setHeaders();
    try {
      const response = await instanceOvert.post('/overt/client/forgot/password', params);
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  confirmNewPassword = async (params: any) => {
    this._setHeaders();
    try {
      const response = await instanceOvert.post('/overt/confirm/forgot/password', params);
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  confirmNewPasswordPublic = async (params: any) => {
    this._setHeaders();
    try {
      const response = await instanceOvert.post('/overt/client/confirm/forgot/password', params);
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  getProfile = async (token: string) => {
    this._setHeaders();
    try {
      const response = await instanceOvert.get(`/overt/account`, {
        headers: {
          Authorization: token
        }
      });
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  createVehicle = async (params: any) => {
    this._setHeaders();
    try {
      const { user } = store.getState();
      const response = await instanceAccount.post(`/account/vehicle/create`, params, { headers: { user: user.profile.PK } });
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  deleteVehicle = async (payload: any) => {
    this._setHeaders();
    try {
      const { user } = store.getState();
      const response = await instanceAccount.delete(`/account/vehicle/delete/${payload.PK}`, {
        headers: { user: user.profile.PK },
        data: { hard: true, PK: payload.PK, SK: payload.SK },
      });
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  getVehicles = async () => {
    this._setHeaders();
    try {
      const { user } = store.getState();
      const response = await instanceAccount.get(`/account/vehicle/list`, { headers: { user: user.profile.PK } });
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  updateVehicles = async (PK: any, payload: any) => {
    this._setHeaders();
    try {
      const { user } = store.getState();
      const response = await instanceAccount.put(`/account/vehicle/update/${PK}`, payload, { headers: { user: user.profile.PK } });
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  createAddress = async (params: any) => {
    this._setHeaders();
    try {
      const { user } = store.getState();
      const response = await instanceAccount.post(`/account/address/create`, params, { headers: { user: user.profile.PK } });
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  deleteAddress = async (SK: any) => {
    this._setHeaders();
    try {
      const { user } = store.getState();
      const response = await instanceAccount.delete(`/account/address/delete/${SK}`, {
        headers: { user: user.profile.PK },
        data: { hard: true },
      });
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  getAddresses = async () => {
    this._setHeaders();
    try {
      const { user } = store.getState();
      const response = await instanceAccount.get(`/account/address/list`, { headers: { user: user.profile.PK } });
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  getAddressesWithId = async (PK: any, headers: any = null) => {
    this._setHeaders();
    try {
      const response = await instanceAccount.get(`/account/address/list`, { headers: headers || {} });
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  updateAddress = async (PK: any, payload: any) => {
    this._setHeaders();
    try {
      const { user } = store.getState();
      const response = await instanceAccount.put(`/account/address/update/${PK}`, payload, { headers: { user: user.profile.PK } });
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  };
}

export default new Session();
