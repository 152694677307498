/* eslint-disable jsx-a11y/anchor-is-valid */
import { SelectPicker } from 'rsuite';
import ErrorValidation from '../../components/ErrorValidation';

const SelectCarData = (props: any) => {
  const { state, _handleBrand, _handleChangeInput, _handleAddCar, _handleManualSection, update } = props;
  return (
    <>
      <div className="row px-3 mb-3">
        <div className="col-12 color-black">
          <div className="mb-2">
            <b className="size-09">Selecciona la marca</b>
          </div>
          <SelectPicker
            data={state.brands}
            disabled={state.inputState.brand}
            classPrefix="copec-select"
            cleanable={false}
            className="rs-copec-select-cleanable"
            placeholder="Selecciona la marca"
            placement="auto"
            onChange={_handleBrand}
            locale={state.locale}
            value={state.inputs.brand}
          />
          <ErrorValidation className="ps-4 size-08" validations={state.validations} name="brand" />
        </div>
      </div>

      <div className="row px-3 mb-3">
        <div className="col-12 color-black">
          <div className="mb-2">
            <b className="size-09">Selecciona el modelo</b>
          </div>
          <SelectPicker
            data={state.models}
            disabled={state.inputState.model}
            classPrefix="copec-select"
            cleanable={false}
            className="rs-copec-select-cleanable"
            placeholder="Selecciona el modelo"
            placement="auto"
            locale={state.locale}
            value={state.inputs.model}
            onChange={(e: any) => _handleChangeInput({ target: { name: 'model', value: e } })}
          />
          <ErrorValidation className="ps-4 size-08" validations={state.validations} name="model" />
        </div>
      </div>

      {!state?.inputs?.success ? (
        <div className="row px-3 mb-3">
          <div className="col-12 color-black">
            <div className="bold ps-2 mb-2">Selecciona el tipo</div>
            <SelectPicker
              data={state.types}
              disabled={state.inputState.model && state.inputState.brand}
              classPrefix="copec-select"
              cleanable={false}
              className="rs-copec-select-cleanable"
              placeholder="Selecciona el tipo"
              placement="auto"
              locale={state.locale}
              value={state.inputs.type}
              onChange={(e: any) => _handleChangeInput({ target: { name: 'type', value: e } })}
            />
            <ErrorValidation className="ps-4 size-08" validations={state.validations} name="type" />
          </div>
        </div>
      ) : null}

      <div className="row px-3 mb-3">
        <div className="col-12 color-black">
          <div className="bold ps-2 mb-2">Ingresa el año</div>
          <input
            className="input-default rounded-30 py-3 px-4"
            onChange={_handleChangeInput}
            value={state.inputs.year}
            name="year"
            style={{ textTransform: 'uppercase' }}
            disabled={state.inputState.year}
            maxLength={4}
          />
          <ErrorValidation className="ps-4 size-08" validations={state.validations} name="year" />
        </div>
      </div>

      {!state.inputState.model && (
        <div className="col-12 text-center size-09 color-dark-blue bold">
          ¿No encuentras la marca o el modelo? <br />{' '}
          <a className="btn size-09 color-dark-blue bold" href="#" onClick={_handleManualSection}>
            Haz clic aquí!
          </a>
        </div>
      )}

      <div className="d-flex justify-content-center align-items-center m-4">
        <button className="btn-default w-100 p-3 size-11 rounded-30" onClick={_handleAddCar}>
          {update ? 'Actualizar vehículo' : 'Agregar vehículo'}
        </button>
      </div>
    </>
  );
};

export default SelectCarData;
