const faq_kerosene: any = [
  {
    question: '¿En qué consiste el Lavado en Seco?',
    response:
      '​El lavado de vehículos en seco es un servicio 100% ecológico, ya que no ocupamos agua durante el lavado y todos los productos utilizados son biodegradables. Además, no dejamos residuos.',
    image: null,
  },
  {
    question: '¿Qué incluye cada tipo de lavado?',
    response: [
      {
        text: 'Contamos con 7 tipos de lavados:',
      },
      {
        text: '1 - Lavado exterior: incluye lavado exterior en seco, limpieza de vidrios, limpieza de llantas y renovador de neumáticos.',
      },
      {
        text: '2 - Lavado Full:  incluye lavado exterior en seco, limpieza de vidrios, limpieza de llantas, renovador de neumáticos, aspirado completo, limpieza interior con protección y pisos de goma con renovador.',
      },
      {
        text: '3 - Lavado Full + Sanitizado: incluye lavado exterior en seco, limpieza de vidrios, limpieza de llantas, renovador de neumáticos, aspirado completo, limpieza interior con protección y pisos de goma con renovador. ',
      },
      {
        text: '4 - Lavado Full + Limpieza de motor: incluye lavado exterior en seco, limpieza de vidrios, limpieza de llantas, renovador de neumáticos, aspirado completo, limpieza interior con protección y pisos de goma con renovador. También incluye lavado en seco superficial del motor.',
      },
      {
        text: '5 - Lavado de tapiz: incluye lavado en seco de asientos, alfombras y butacas.',
      },
      {
        text: '6 - Lavado de tapiz + Sanitizado: incluye lavado en seco de asientos, alfombras y butacas. También incluye sanitizado completo del vehículo.',
      },
      {
        text: '7- Lavado Premium: incluye lavado exterior en seco, limpieza de vidrios, limpieza de llantas, renovador de neumáticos, aspirado completo, limpieza interior con protección y pisos de goma con renovador. También incluye lavado de tapiz (asientos, alfombras y butacas), lavado en seco superficial del motor y sanitizado completo del vehículo.',
      },
    ],
    image: null,
  },
  {
    question: '¿Cómo agendo un lavado?',
    response: [
      {
        text: '1 - Iniciar sesión.',
      },
      {
        text: '2 - Ingresar tu dirección.',
      },
      {
        text: '3 - Seleccionar el servicio Lavado en Seco.',
      },
      {
        text: '4 - Ingresar vehículo.',
      },
      {
        text: '5 - Seleccionar el tipo de lavado y luego día y horario de tu reserva.',
      },
      {
        text: '6 - Confirmar agendamiento en el botón "Agendar".',
      },
      {
        text: '7 - Ir a pagar. Puedes revisar tu reserva en el módulo "Mi Historial".',
      },
    ],
  },
  {
    question: '¿Puedo agendar un lavado sin tener usuario?',
    response: 'Sí, puedes hacerlo completando el flujo anterior (¿Cómo agendo un lavado?) comenzando con el paso 2.',
  },
  {
    question: '¿En qué comunas está habilitado el servicio?',
    response:
      'Por el momento, el servicio se encuentra disponible solo en las comunas de Las Condes, Vitacura, Rancagua, Peñalolén, Machalí, La Reina, Macul, La Florida, San Miguel, Olivar, La Cisterna, La Granja, Requinoa, San Joaquín, San Ramón, Graneros y Doñihue.',
  },
  {
    question: '¿Cuáles son las formas de pago?',
    response: 'Por el momento, las formas de pagos disponibles son las siguientes: efectivo, tarjeta de crédito y tarjeta de débito.',
  },
  {
    question: '¿Cuánto se demora cada lavado?',
    response: 'El tiempo estimado es de 45 a 60 minutos por lavado. Puede tardar un poco más dependiendo del tipo de lavado contratado y del tamaño del vehículo.',
  },
  {
    question: '¿Puedo modificar mi reserva?',
    response: [
      {
        text: 'Si necesitas cambiar la fecha de tu reserva, debes hacer lo siguiente:',
      },
      {
        text: '1 - Iniciar sesión.',
      },
      {
        text: '2 - Entrar al módulo "Mi Historial".',
      },
      {
        text: '3 - Seleccionar la orden.',
      },
      {
        text: '4 - Hacer click en reagendar e informar día y hora.',
      },
    ],
  },
  {
    question: '¿Cuál es el valor de cada servicio?',
    response: [
      {
        text: ' Los valores de cada servicio varían según el tamaño del vehículo. Para más información, haz click en  "Ver detalles" del servicio a consultar y te mostraremos las tarifas según los diferentes tamaños de vehículos.',
      },
      {
        text: '1 - Entra al servicio.',
      },
      {
        text: '2 - Informa la patente de tu vehículo.',
      },
      {
        text: '3 - Presiona "ver detalles" en el tipo de servicio que desees conocer más información.',
      },
    ],
  },
  {
    question: '¿Cuál es el horario y días que se presta el servicio?',
    response:
      'Los horarios de atención dependen de la ubicación en que se quiere realizar el lavado. Para ver los horarios disponibles, ingresa a nuestra página, como invitado o usuario, solicita un lavado y luego elige la ubicación en donde se encontrará el automóvil al momento del lavado.',
  },
  {
    question: 'Condiciones de pago Webpay (Débito y Crédito)',
    response: [
      {
        text: 'Para pagos con WebPay, el dinero saldrá de tu cuenta bancaria en el momento que confirmes el pedido. Si tu pedido se entrega conforme a lo previsto, el dinero permanecerá en las cuentas de Copec. En caso de que desees añadir productos en el momento de la venta, estos podrán ser agregados a tu pedido original sin problemas, pero el pago de esos productos se debe realizar de forma presencial.',
      },
      {
        text: 'Por otra parte, si necesitas anular tu pedido, podrás realizarlo siempre y cuando éste no haya cambiado de estado a "En Ruta". Para solicitar la devolución de tu dinero, puedes contactarte con nuestros ejecutivos llamando al 800 200 354, quienes te ayudarán a gestionar la devolución mediante transferencia bancaria, la cual se realizará dentro de 7 hábiles.',
      },
    ],
    image: null,
  },
  {
    question: '¿Cuándo se cargarán mis puntos Full?',
    response: 'Los puntos Full serán cargados en el momento en que el servicio sea entregado por el atendedor. Los puntos Full solo podrán ser cargados si eres parte del programa Full.',
    image: null,
  },
];

export default faq_kerosene;
