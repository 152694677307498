import { ReactComponent as WhatsAppIcon } from '../../assets/image/svg/wpp.svg';

import './styles.scss';

const WhatsApp = () => {
  return (
    <div className="whatsapp-float">
      <a href={process.env.REACT_APP_WHATSAPP_URL} target="_blank" rel="noreferrer">
        <button className="bounce">
          <WhatsAppIcon />
        </button>
      </a>
    </div>
  );
};

export default WhatsApp;
