import { createAction } from 'redux-actions';

export const SET_USER: any = createAction('SET_USER');
export const CLEAR_USER: any = createAction('CLEAR_USER');
export const SAVE_STATE: any = createAction('SAVE_STATE');

export const set_user = (payload: any) => {
  return async (dispatch: any) => {
    await dispatch(SET_USER(payload));
    await dispatch(SAVE_STATE());
    return Promise.resolve()
  }
}

export const clear_user = () => {
  return (dispatch: any) => {
    dispatch(CLEAR_USER());
    dispatch(SAVE_STATE());
  }
}
