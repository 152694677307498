/* eslint-disable no-useless-escape */
import * as yup from 'yup';

export const MassiveLoadUserSchema = yup.object().shape({
  email: yup
    .string()
    .matches(/^[\w+.]+@\w{2,}\.\w{2,}(?:\.\w{2,})?$/, 'Formato de email no es válido')
    .required('El campo email es requerido'),
  name: yup
    .string()
    .matches(/^[\w\. ?¿¡!^\+-\:()\{\}\[\],;\*#@áàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ]*$/gim, 'Este campo contiene caracteres inválidos')
    .max(255, 'Este campo acepta un máximo de 255 caracteres')
    .required('El campo name es requerido'),
  family_name: yup
    .string()
    .matches(/^[\w\. ?¿¡!^\+-\:()\{\}\[\],;\*#@áàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ]*$/gim, 'Este campo contiene caracteres inválidos')
    .max(255, 'Este campo acepta un máximo de 255 caracteres')
    .required('El campo apellido es requerido'),
  phone_number: yup
    .string()
    .matches(/^[\w\. ?¿¡!^\+-\:()\{\}\[\],;\*#@áàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ]*$/gim, 'Este campo contiene caracteres inválidos')
    .min(9, 'Este campo acepta un minimo de 9 caracteres')
    .max(9, 'Este campo acepta un máximo de 9 caracteres')
    .required('El campo teléfono es requerido'),
  rut: yup
    .string()
    .matches(/^[\w\. ?¿¡!^\+-\:()\{\}\[\],;\*#@áàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ]*$/gim, 'Este campo contiene caracteres inválidos')
    .required('El campo rut es requerido'),
});

export type MassiveLoadUserType = yup.InferType<typeof MassiveLoadUserSchema>;
