import axios from 'axios';
import store from '../redux/store';
import { clear_user } from '../redux/actions/user';
import { toast } from 'react-toastify';
import { remove_service_from_cart } from '../redux/actions/shopping_cart';
import { remove_service } from '../redux/actions/services';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_ACCOUNT,
  headers: {
    'Content-type': 'application/json',
    'gux-api-key-account': process.env.REACT_APP_API_ACCOUNT || '',
  },
});
instance.interceptors.response.use(
  function (response: any) {
    return response;
  },
  function (error: any) {
    const { user, shopping_cart } = store.getState();
    if (!user.isLogged) return Promise.reject(error);
    if (error.response && error.response.status === 401) {
      const dispatch: any = store.dispatch;
      toast.warning('Su sesión ha expirado, por favor inicie sesión nuevamente.');

      const company_services = shopping_cart?.services?.filter((service: any) => service?.info?.is_company || service.type === "subscription");
      company_services.forEach((service: any) => {
        dispatch(remove_service_from_cart({ ...service }));
        dispatch(remove_service({ ...service }));
      });

      dispatch(clear_user());
    }
    return Promise.reject(error);
  }
);

export default instance;
