import store from '../redux/store';

export const sendDataLayerEvent = (data: any) => {
  try {
    const { gtm_consent } = store.getState();
    const dataLayerIsNotAllowed: any = gtm_consent.analytics_storage !== 'granted';

    if (dataLayerIsNotAllowed) return;

    const Window: any = window;
    const dataLayer: any = Window.dataLayer || [];
    dataLayer.push(data);
    return true;
  } catch (err: any) {
    return false;
  }
};

export const clearDataLayerEvent = () => {
  try {
    const { gtm_consent } = store.getState();
    const dataLayerIsNotAllowed: any = gtm_consent.analytics_storage !== 'granted';

    if (dataLayerIsNotAllowed) return;

    const Window: any = window;
    const dataLayer: any = Window.dataLayer || [];
    dataLayer.push({ ecommerce: null });
    return true;
  } catch (err: any) {
    return false;
  }
};

export const createDataLayerObject = (eventName: any, items: any) => {
  const dataEventObject: any = {
    event: eventName,
    ecommerce: {
      items,
    },
  };

  return dataEventObject;
};

export const createDataLayerObjectItem = (payload: any, brand: any, sub_category: any, item_list_name: any, index: any = '', variant: any = '') => {
  const item: any = {
    item_name: payload?.name,
    item_id: payload.sku || payload.info?.sku || '',
    price: payload.price,
    item_brand: brand,
    item_category: 'service',
    item_category2: sub_category,
    item_category3: '',
    item_category4: '',
    item_variant: variant,
    item_list_name: item_list_name,
    item_list_id: '',
    index: index,
    quantity: payload.quantity || 1,
  };

  return item;
};

export const addToCartDataLayerEvent = (payload: any, brand: any, sub_category: any, item_list_name: any, index: any = '', variant: any = '') => {
  const item: any = {
    item_name: payload?.name,
    item_id: payload.sku || payload.info?.sku || '',
    price: payload.price,
    item_brand: brand,
    item_category: 'service',
    item_category2: sub_category,
    item_category3: '',
    item_category4: '',
    item_variant: variant,
    item_list_name: item_list_name,
    item_list_id: '',
    index: index,
    quantity: 1,
  };
  const dataLayerObject: any = createDataLayerObject('add_to_cart', [item]);
  clearDataLayerEvent();
  sendDataLayerEvent(dataLayerObject);
};

export const removeFromCartDataLayerEvent = (payload: any, brand: any, sub_category: any, item_list_name: any, index: any = '', variant: any = '') => {
  const item: any = {
    item_name: payload?.name,
    item_id: payload.sku || payload.info?.sku || '',
    price: payload.price,
    item_brand: brand,
    item_category: 'service',
    item_category2: sub_category,
    item_category3: '',
    item_category4: '',
    item_variant: variant,
    item_list_name: item_list_name,
    item_list_id: '',
    index: index,
    quantity: payload.quantity || 1,
  };
  const dataLayerObject: any = createDataLayerObject('remove_from_cart', [item]);
  clearDataLayerEvent();
  sendDataLayerEvent(dataLayerObject);
};

export function sendDataLayerArguments(...data: any[]) {
  const Window: any = window;
  const gtag: any = Window.gtag;
  gtag(...data);
  return true;
}
