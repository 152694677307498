import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Tooltip, Whisper } from 'rsuite';
import ErrorValidation from '../../components/ErrorValidation';
import Building from './Building';
import House from './House';
import Other from './Other';

const ComplementDirections = (props: any) => {
  const { onChange, validations } = props;
  const { directions } = useSelector((state: any) => state);
  const [state, setState] = useState<any>({
    option: 1,
  });

  const _handleOnChangeOption = (payload: any) => {
    setState({ ...state, option: payload });
    onChange({target: { value: payload, name: 'house_type'}}, 'i_will_receive');
  };

  const OptionsDesktop = () => {
    return (
      <div className="col-12 mb-3">
        <div className="color-black bold mb-2">Etiqueta</div>
        <div className="col px-3 py-2 color-white">
          <button onClick={() => _handleOnChangeOption(1)} className={`m-2 py-2 px-4 ${state.option === 1 ? 'btn-default' : 'btn-option'}`}>
            Casa
          </button>
          <button onClick={() => _handleOnChangeOption(2)} className={`m-2 py-2 px-4 ${state.option === 2 ? 'btn-default' : 'btn-option'}`}>
            Edificio
          </button>
          <button onClick={() => _handleOnChangeOption(3)} className={`m-2 py-2 px-4 ${state.option === 3 ? 'btn-default' : 'btn-option'}`}>
            Otro
          </button>
        </div>
      </div>
    );
  };

  const OptionsMobile = () => {
    return (
      <div className="row flex-column px-4 mb-3">
        <div className="col ps-1 mb-1 color-black bold size-09">Etiqueta</div>
        <div className="col px-3 py-1 text-center">
          <button onClick={() => _handleOnChangeOption(1)} className={`m-2 py-2 px-4 ${state.option === 1 ? 'btn-default' : 'btn-option'}`}>
            Casa
          </button>
          <button onClick={() => _handleOnChangeOption(2)} className={`m-2 py-2 px-4 ${state.option === 2 ? 'btn-default' : 'btn-option'}`}>
            Edificio
          </button>
          <button onClick={() => _handleOnChangeOption(3)} className={`m-2 py-2 px-4 ${state.option === 3 ? 'btn-default' : 'btn-option'}`}>
            Otro
          </button>
        </div>
        <ErrorValidation validations={validations} name="apartment" className="size-09 text-end bold-300" />
      </div>
    );
  };

  return (
    <>
      <div className="d-sm-none color-black">
        <div className="row px-2 mb-4">
          <div className="col-12 size-09 bold px-2 mt-4 pb-2 color-dark-blue line-border">Mi dirección</div>
        </div>

        <div className="row flex-column px-4 mb-3">
          <div className="col ps-1 mb-1 color-black bold size-09">Dirección</div>
          <Whisper trigger={'click'} placement="auto" speaker={<Tooltip>{directions._string}</Tooltip>}>
            <div className="col bg-color-blue-light px-3 py-3 size-09 border-default rounded-10 ellipsis" style={{ color: '#cacaca' }}>
              {directions._string}
            </div>
          </Whisper>
        </div>

        <OptionsMobile />
        {state.option === 1 ? <House onChange={onChange} validations={validations} /> : null}
        {state.option === 2 ? <Building onChange={onChange} validations={validations} /> : null}
        {state.option === 3 ? <Other onChange={onChange} validations={validations} /> : null}
        
      </div>

      {/* Desktop Version  */}
      <div className="px-5 mb-3 d-none d-sm-block">
        <div className="row mb-4 line-border">
          <div className="col-12 color-black  size-12 bold mt-3 pb-2">Mi Dirección</div>
        </div>
        <div className="col-12 mb-3">
          <div className="color-black bold mb-2">Dirección</div>
          <div className="col py-3 px-4 bg-color-blue-light px-3 py-2 border-default rounded-20">{directions._string}</div>
        </div>
        
        <OptionsDesktop />

        {state.option === 1 ? <House onChange={onChange} validations={validations} /> : null}
        {state.option === 2 ? <Building onChange={onChange} validations={validations} /> : null}
        {state.option === 3 ? <Other onChange={onChange} validations={validations} /> : null}

      </div>
    </>
  );
};

export default ComplementDirections;
