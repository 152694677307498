import { update_service } from '../redux/actions/services';
import { remove_to_cart } from '../redux/actions/shopping_cart';
import store from '../redux/store';
import Services from '../services/services.service';

export const checkIfServicesAreAvailable = async () => {
  const reduxStore: any = store.getState();
  const dispatch: any = store.dispatch;
  const { shopping_cart } = reduxStore;
  const { services } = shopping_cart;
  const actions: any = [];
  const servicesRemoved: any = [];
  try {
    /**
     * get services list and recommended subservices
     */
    const serviceResponse = await Services.list();
    const serviceList: any = serviceResponse.data.filter((item: any) => item.type !== 'recommended_subservice');
    const recommended_subservices: any = serviceResponse.data.find((item: any) => item.type === 'recommended_subservice');

    let recommended_subservices_details: any = [];

    if (recommended_subservices) {
      const recommended_subservice_response = await Services.details(recommended_subservices);
      recommended_subservices_details = recommended_subservice_response.data.sub_services;
    }

    /**
     * validate if subservices are available
     */

    serviceList.forEach((service: any) => {
      const serviceInCart = services.find((item: any) => item.PK === service.PK);
      if (serviceInCart) {
        const { related_products } = serviceInCart;
        const { sub_services } = service;

        const unvailableSubServices = sub_services.filter((item: any) => item.info?.is_unvailable);
        const unvailableRecommendedSubServices = recommended_subservices_details.filter((item: any) => item.info?.is_unvailable);

        const unvailableItems = [...unvailableSubServices, ...unvailableRecommendedSubServices];

        let productListToCheck: any = [serviceInCart.service];

        if (related_products?.length > 0) {
          productListToCheck = [...productListToCheck, ...related_products];
        }

        const unvailableItemsInCart = productListToCheck?.filter((item: any) => unvailableItems.find((subService: any) => subService.SK === item.SK));

        if (unvailableItemsInCart?.length > 0) {
          serviceInCart.related_products = [];
          dispatch(update_service({ ...serviceInCart }));
          dispatch(remove_to_cart({ ...serviceInCart }));

          unvailableItemsInCart.forEach((item: any) => {
            servicesRemoved.push({ ...item });
          });
        }
      }
    });

    if (servicesRemoved.length > 0) {
      actions.push('remove_service');
    }

    return { actions, servicesRemoved };
  } catch (e: any) {
    return { actions: ['internal_error'], servicesRemoved: [] };
  }
};
