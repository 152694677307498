import { createAction } from 'redux-actions';

export const GET_AUTOS: any = createAction('GET_AUTOS');
export const SET_AUTO: any = createAction('SET_AUTO');
export const DELETE_AUTO: any = createAction('DELETE_AUTO');
export const SAVE_STATE: any = createAction('SAVE_STATE');

export const set_auto = (payload: any) => {
  return (dispatch: any) => {
    dispatch(SET_AUTO(payload));
    dispatch(SAVE_STATE());
  }
}

export const delete_auto = (payload: any) => {
  return (dispatch: any) => {
    dispatch(DELETE_AUTO(payload));
    dispatch(SAVE_STATE());
  }
}
