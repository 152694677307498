import { Notification } from "rsuite";

const Alert = (props: any) => {
  const { type, children, ...rest } = props
  return (
    <Notification classPrefix={`copec-${type}`} {...rest}>
      {children}
    </Notification>
  )
}

export default Alert;