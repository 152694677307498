import Images from "../../assets/image"
import Image from "../Image"
import '../../styles/components/component.splash.scss'
import Title from "../Title"
import { useSelector } from "react-redux"

const SplashDirection = () => {
  const { splash_screen } = useSelector((state: any) => state)
  return (
    <div className={`component-splash d-flex justify-content-around flex-column ${splash_screen.direction ? 'show' : ''}`}>
      <Image image={Images.SplashService} className="mt-5" />
      <Title size="md" text="¡Estamos buscando los servicios disponibles en tu zona!" className="text-center color-dark-blue" />
    </div>
  )
}

export default SplashDirection