/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import Images from '../../assets/image';
import Drawer from '../../components/Drawer';
import ServiceTemplate from '../../template/ServiceTemplate';
import DrawerSelectCar from './DrawerSelectCar';
import { v4 } from 'uuid';
import DateTimePicker from '../../components/DateTimePicker';
import Resume from '../../components/Resume';
import DrawerDirections from '../../components/DrawerDirections';
import RenderInfo from './RenderInfo';
import { useDispatch, useSelector } from 'react-redux';
import { edit_service, update_service } from '../../redux/actions/services';
import { add_service_to_cart, close_cart, remove_to_cart, update_service_to_cart } from '../../redux/actions/shopping_cart';
import { useHistory, useParams } from 'react-router';
import Modal from '../../components/Modal';
import SelectCar from './SelectCar';
import { LOAD_OFF, LOAD_ON } from '../../redux/actions/loader';
import Services from '../../services/services.service';
import { set_available_services } from '../../redux/actions/available_services';
import { set_actionbar } from '../../redux/actions/action_bar';
import CardLub from '../../components/CardLub';
import DrawerRecommendedService from '../../components/DrawerRecommendedService';
import CardWash from '../../components/CardWash';
import { addToCartDataLayerEvent, clearDataLayerEvent, createDataLayerObject, removeFromCartDataLayerEvent, sendDataLayerEvent } from '../../helpers/data_layer';
import { toast } from 'react-toastify';
import Image from '../../components/Image';
import DynamicModal from '../../components/DynamicModal';
import Title from '../../components/Title';
import MultipleServicesModal from '../../components/MultipleServicesModal';
import { send_message } from '../../redux/actions/broadcast';
import { checkIfCopecFullPointsIsAvailable } from '../../helpers/copec_full_points';

const ServiceLub = (props: any) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { services, matchMedia, available_services, shopping_cart, action_bar, broadcast } = useSelector((state: any) => state);
  const [recommended, setRecommended] = useState(false);
  const [showRecommendedButton, setShowRecommendedButton] = useState(false);
  const params: any = useParams();
  const urlParams = new URLSearchParams(window.location.search);

  const [state, setState] = useState<any>({
    service: null,
    drawers: {
      selectCar: {
        show: false,
      },
      info: {
        show: false,
      },
      datepicker: {
        show: false,
      },
      resume: {
        show: false,
      },
    },
    modals: {
      selectCar: {
        show: false,
      },
    },
    drawer: {
      script: {
        show: false,
        direction: 'bottom-top',
      },
      manual: {
        show: false,
        direction: 'bottom-top',
      },
      autocomplete: {
        show: false,
        direction: 'right-left',
      },
      history: {
        show: false,
        direction: 'bottom-top',
      },
    },
    services: [],
    main_service: {},
    dynamicPopUp: {
      open: false,
      first_load: true,
    },
  });
  const [servicesInCartModal, setServicesInCartModal] = useState<any>({
    open: false,
    title: `¿Tienes otro auto que necesite ${props.data?.name?.toLowerCase()}?`,
  });

  useEffect(() => {
    let service: any = services.find((service: any) => !service.is_service_in_cart && service.slug === params.slug);
    let isServiceInCart = shopping_cart.services.find((service: any) => service.PK === props.PK && service.SK === props.SK && service.slug === params.slug);

    if (urlParams.has('id')) {
      const existsServiceWithThisId = services.find((service: any) => service.id === urlParams.get('id'));
      isServiceInCart = shopping_cart.services.find((service: any) => service.id === urlParams.get('id'));
      if (existsServiceWithThisId) service = existsServiceWithThisId;
    }

    if (isServiceInCart && !service && state.drawers.resume.show === false) {
      dispatch(LOAD_OFF());
      setServicesInCartModal({ ...servicesInCartModal, open: true });
    }

    if (!isServiceInCart && !service) {
      dispatch(LOAD_OFF());
      _handleState(true, 'selectCar');
    }

    if (service) {
      getSubServices();
    }
  }, [services]);

  useEffect(() => {
    const unlisten = history.listen(() => {
      dispatch(LOAD_ON());
      window.location.reload();
    });
    return () => {
      unlisten();
    };
  }, [history]);

  useEffect(() => {
    if (broadcast?.update_car) {
      _handleState(true, 'selectCar');
      dispatch(send_message({ update_car: false }));
    }
  }, [broadcast]);

  const getSubServices = async () => {
    dispatch(LOAD_ON());
    try {
      if (state.drawers.resume.show === false && recommended === false) {
        const availableMainService = available_services.find((availableService: any) => availableService.info?.slug === params.slug);
        let mainService = services.find((item: any) => item.PK === props.PK && !item.is_service_in_cart);

        if (urlParams.has('id')) {
          const service = services.find((service: any) => service.id === urlParams.get('id'));
          if (service) mainService = service;
        }

        if (availableMainService && mainService) {
          const { car } = mainService;
          let detailsService: any = null;

          if (availableMainService?.eds?.eds_service_id) {
            detailsService = await Services.detailsWithRelatedProducts({ PK: availableMainService.eds.eds_service_id, service_PK: availableMainService.eds.service_id });
          } else {
            detailsService = await Services.details({ PK: availableMainService.PK, SK: availableMainService.SK });
          }

          const { sub_services } = detailsService.data;

          sub_services.map((item: any) => {
            item.shipping_price = availableMainService.shipping_price;
            if (item.info.without_discount_price) {
              item.info.without_discount_price = Number(item.info.without_discount_price);
              const porcentageDiscount = ((item.info.without_discount_price - item.price) / item.info.without_discount_price) * 100;
              item.info.porcentageDiscount = Math.round(porcentageDiscount);
              item.info.show_discount = item.info.porcentageDiscount > 0;
            }
            return item;
          });

          const availables = sub_services.filter((ss: any) => ss.name !== 'Envio' && ss.name !== 'Envío' && !ss.info?.air_filter && !ss.info?.is_unvailable);

          availables.sort((a: any, b: any) => {
            if (a.name > b.name) return 1;
            if (a.name < b.name) return -1;
            return 0;
          });

          /**
           * SET DEFAULT SERVICE
           */

          if (car.IdItemDefault) {
            const defaultServiceAvailable = availables.find((item: any) => item.info?.sku?.toString() === car.IdItemDefault?.toString());
            if (defaultServiceAvailable) {
              availables.map((item: any) => {
                item.info.recommended = item.SK === defaultServiceAvailable.SK;
                return item;
              });
            }
          }

          availables.forEach((service: any, index: any) => {
            const item: any = {
              item_name: service.name,
              item_id: service.info?.sku || '',
              price: service.price,
              item_brand: params.slug,
              item_category: 'service',
              item_category2: 'sub_service',
              item_category3: '',
              item_category4: '',
              item_variant: '',
              item_list_name: 'show_available_items',
              item_list_id: index,
              index: index,
              quantity: 1,
            };
            clearDataLayerEvent();
            const dataLayerEvent: any = createDataLayerObject('view_item', [item]);
            sendDataLayerEvent(dataLayerEvent);
          });

          const dynamicPopUp: any = {
            open: false,
            first_load: false,
          };
          if (detailsService?.data?.info?.popup_info?.isEnabled && state.dynamicPopUp.first_load) {
            dynamicPopUp.open = true;
            availableMainService.info.popup_info = detailsService?.data?.info?.popup_info;
          }

          const available_services_list: any = available_services.filter((s: any) => s.PK !== availableMainService.PK);

          detailsService.data.isFullPointsAvailable = checkIfCopecFullPointsIsAvailable(detailsService.data);

          const newServiceData = { ...detailsService.data, code: availableMainService.code, url: availableMainService.url, eds: availableMainService.eds, id: availableMainService.id };

          if (availableMainService.company) {
            newServiceData.company = availableMainService.company;
          }

          available_services_list.push({ ...newServiceData });

          setState({
            ...state,
            dynamicPopUp,
            services: availables,
            service: { ...newServiceData },
            main_service: { ...detailsService.data, code: availableMainService.code, url: availableMainService.url, eds: availableMainService.eds, id: availableMainService.id },
          });

          dispatch(set_available_services(available_services_list));
        }
      }
    } catch (e) {
      toast.error('No fue posible cargar los productos.');
    }
    dispatch(LOAD_OFF());
  };

  const _handleState = (value: any, target: any) => {
    Object.keys(state.drawers).forEach((key) => {
      state.drawers[key].show = false;
    });

    Object.keys(state.modals).forEach((key) => {
      state.modals[key].show = false;
    });

    if (matchMedia.media === 'xs') {
      state.drawers[target].show = value;
    } else {
      if (target !== 'selectCar') {
        state.drawers[target].show = value;
      } else {
        state.modals[target].show = value;
      }
    }
    setServicesInCartModal({ ...servicesInCartModal, open: false });
    setRecommended(false);
    setState({ ...state });
  };

  const _handleBack = (target: any) => {
    _handleState(false, target);
  };

  const _handleNext = (current: any, next: any) => {
    setState({ ...state, drawers: { ...state.drawers, [current]: { show: false }, [next]: { show: true } } });
  };

  const _handleService = (id: any) => {
    const service = state.services.find((item: any) => item.SK === id);
    state.service = service;
    state.drawers.info.show = true;
    setState({ ...state });
  };

  const _handleSchedule = (SK: any) => {
    const service = state.services.find((item: any) => item.SK === SK);
    state.service = service;
    state.drawers.datepicker.show = true;
    setState({ ...state });

    const dataLayerEvent: any = {
      event: 'aceite_producto',
      page: '/services/cambio-de-aceite/paso3/seleccion-productos',
      pagename: document.title,
    };

    sendDataLayerEvent(dataLayerEvent);
  };

  const _toogleDrawer = (target: any) => {
    const data = JSON.parse(JSON.stringify(state));
    data.drawer[target].show = !data.drawer[target].show;
    data.drawers.resume.show = false;
    setState(data);
  };

  const _closeAndOpen = (target: any) => {
    const data = JSON.parse(JSON.stringify(state));
    Object.keys(data.drawer).forEach((key) => {
      data.drawer[key].show = false;
    });
    setState(data);

    setTimeout(() => {
      const openState = JSON.parse(JSON.stringify(data));
      openState.drawer[target].show = true;
      setState(openState);
    }, 200);
  };

  const _saveScheduleRedux = (payload: any) => {
    let service = services.find((service: any) => !service.is_service_in_cart && service.slug === params.slug);
    let existsServiceWithThisId = null;
    if (urlParams.has('id')) {
      existsServiceWithThisId = services.find((service: any) => service.id === urlParams.get('id'));
      if (existsServiceWithThisId) service = existsServiceWithThisId;
    }

    if (!service) {
      _handleState(true, 'selectCar');
      return;
    }

    service.schedule = { id: payload.id, day: payload.day, hour: payload.hour, month: payload.month };
    service.service = payload.service;

    const availableServiceData = available_services.find((as: any) => as.PK === service.PK);

    service.eds = availableServiceData.eds;
    service.delivery = availableServiceData.shipping_price;
    service.shipping_price = availableServiceData.shipping_price;
    service.parent_service = service.PK;
    service.PK = props.PK;
    service.SK = props.SK;
    service.name = props.data?.name;
    service.is_service_in_cart = true;

    const url = new URL(window.location.href);

    if (urlParams.has('id') && existsServiceWithThisId) {
      const shoppingCartService = shopping_cart.services.find((service: any) => service.id === urlParams.get('id'));
      service.related_products = shoppingCartService?.related_products || [];
      dispatch(update_service_to_cart({ ...service }));
      removeFromCartDataLayerEvent(shoppingCartService.service, params.slug, 'sub_service', 'show_available_items');
      addToCartDataLayerEvent(service.service, params.slug, 'sub_service', 'show_available_items');
    }

    if (urlParams.has('id') && !existsServiceWithThisId) {
      service.id = v4();
      url.searchParams.set('id', service.id);
      dispatch(add_service_to_cart({ ...service }));
      addToCartDataLayerEvent(service.service, params.slug, 'sub_service', 'show_available_items');
    }

    if (!urlParams.has('id')) {
      service.id = v4();
      url.searchParams.append('id', service.id);
      dispatch(add_service_to_cart({ ...service }));
      addToCartDataLayerEvent(service.service, params.slug, 'sub_service', 'show_available_items');
    }

    dispatch(edit_service(service));
    window.history.replaceState({}, '', url.href);

    let urlPage: any = '/services/cambio-de-aceite/paso4/seleccion-fecha-horario';
    let eventName: any = 'aceite_fecha';

    const dataLayerEvent: any = {
      event: eventName,
      page: urlPage,
      pagename: document.title,
    };

    sendDataLayerEvent(dataLayerEvent);

    _handleNext('datepicker', 'resume');
  };

  const _deleteService = (payload: any) => {
    state.drawers.resume.show = false;
    setState({ ...state });
    if (payload.related_products) {
      delete payload.related_products;
    }
    dispatch(update_service({ ...payload }));
    dispatch(remove_to_cart(payload));
    dispatch(close_cart());
  };

  const _handleModalServices = () => {
    setServicesInCartModal({ ...servicesInCartModal, open: false });
    dispatch(close_cart());
    available_services.sort((a: any, b: any) => (a.name > b.name ? 1 : -1));
    if (matchMedia.media === 'xs') {
      dispatch(set_actionbar({ ...action_bar, unselected_services: available_services, show: true }));
    } else {
      dispatch(set_actionbar({ ...action_bar, unselected_services: available_services, modal_open: true }));
    }
  };

  const _addToCart = () => {
    _handleModalServices();
    const dataLayerEvent: any = {
      event: 'aceite_resumen',
      page: '/services/cambio-de-aceite/paso5/seleccion-resumen',
      pagename: document.title,
    };

    sendDataLayerEvent(dataLayerEvent);
  };

  const _handleToggleRecommended = () => {
    if (recommended) {
      setState({ ...state, drawers: { ...state.drawers, resume: { show: !state.drawers.resume.show } } });
      setRecommended(!recommended);
    } else {
      setState({ ...state, drawers: { ...state.drawers, resume: { show: !state.drawers.resume.show } } });
      setTimeout(() => {
        setRecommended(!recommended);
      }, 1);
    }
  };

  const _handleNewService = () => {
    const url = new URL(window.location.href);
    url.searchParams.delete('id');
    window.history.replaceState({}, '', url.href);
    _handleState(true, 'selectCar');
  };

  return (
    <ServiceTemplate banner={Images.HeaderLub} title={'Lavado'} infoUrl="/services/info/cambio-aceite" data={props.data}>
      <MultipleServicesModal
        open={servicesInCartModal.open}
        title={servicesInCartModal.title}
        disableCancelBtn
        okButtonText="Nuevo Pedido"
        toggleModal={() => setServicesInCartModal({ ...servicesInCartModal, open: false })}
        onClose={() => history.push('/')}
        onOk={() => _handleState(true, 'selectCar')}
        goToPayment={_handleModalServices}
      />

      <DrawerDirections state={state} _toogleDrawer={_toogleDrawer} _closeAndOpen={_closeAndOpen} />

      <DrawerSelectCar show={state.drawers.selectCar.show} _handleState={_handleState} id={props.PK} PK={props.PK} SK={props.SK} data={props.data} />

      <Modal open={state.modals.selectCar.show}>
        <SelectCar _handleState={_handleState} rounded id={props.PK} PK={props.PK} SK={props.SK} show={state.modals.selectCar.show} />
      </Modal>

      <DrawerRecommendedService show={recommended} setShowRecommendedButton={setShowRecommendedButton} handleClose={_handleToggleRecommended} service={state.service} id={props.id} />

      <Drawer show={state.drawers.info.show} direction="bottom-top">
        <RenderInfo state={state} handleBack={_handleBack} onContinue={_handleNext} />
      </Drawer>

      <Drawer show={state.drawers.datepicker.show} direction="right-left" className="drawer-with-header">
        <DateTimePicker
          show={state.drawers.datepicker.show}
          onContinue={_saveScheduleRedux}
          onClose={() => _handleBack('datepicker')}
          module={state.service?.info?.slug}
          service={state.service}
          mainService={state.main_service}
        />
      </Drawer>

      <Drawer show={state.drawers.resume.show} direction="bottom-top" className="drawer-with-header">
        <Resume
          PK={props.PK}
          open={state.drawers.resume.show}
          state={state}
          onFinish={_addToCart}
          openAddress={_toogleDrawer}
          module={state.service?.info?.slug}
          onDelete={_deleteService}
          handleRecommended={_handleToggleRecommended}
          onClose={() => _handleNext('resume', 'datepicker')}
          showRecommendedButton={showRecommendedButton}
          onNewService={_handleNewService}
          filterOption
        />
      </Drawer>

      <DynamicModal open={state.dynamicPopUp?.open} data={state.service?.info?.popup_info} onContinue={() => setState({ ...state, dynamicPopUp: { ...state.dynamicPopUp, open: false } })} />

      <div className="row d-none d-sm-flex size-15 px-5 my-5 align-items-center">
        <div className="col-12 col-sm-12 bold-300 size-13 color-dark-blue">{props.data?.name}</div>
      </div>

      <div className="row d-none d-sm-flex">
        <div className="col-12 text-center size-15 color-dark-blue">
          <Image className="me-3" image={Images.CopecFullRed} style={{ width: '80px' }} />
          Al pedir {props.data?.name} a domicilio acumulas puntos Full Copec
        </div>
      </div>

      {state.services?.length === 0 ? (
        <Title
          text="No pudimos encontrar ningún producto disponible para este servicio en su dirección."
          className={`text-center color-dark-blue mt-5 ${matchMedia.media === 'xs' ? 'size-07' : 'size-09'}`}
        />
      ) : null}

      <div className="w-100 d-flex justify-content-center d-none d-sm-flex mt-5" style={{ flexWrap: 'wrap' }}>
        {state.services &&
          state.services.map((service: any) => (
            <div className="mx-3 color-green-base mb-4" key={v4()}>
              <CardLub
                price={service.price}
                image={service.info?.gallery?.url ? { default: { alt: '', src: service.info?.gallery?.url } } : Images.NotFound}
                text={service.name}
                value="Ver detalle"
                handleAction={() => _handleSchedule(service.SK)}
                handleDetail={() => _handleService(service.SK)}
                recommended={service.info?.recommended || false}
                fullpoints={state.service.isFullPointsAvailable}
                data={service}
              />
            </div>
          ))}
      </div>

      <div className="row d-sm-none px-2 mt-5">
        {state.services &&
          state.services.map((service: any) => (
            <div className="col-12 col-lg-4 col-md-6 px-4 mb-5 bold d-flex justify-content-center " key={v4()}>
              <CardWash
                image={service.info?.gallery?.url ? { default: { alt: '', src: service.info?.gallery?.url } } : Images.NotFound}
                text={service.name}
                price={service.price}
                value="Ver detalle"
                handleAction={() => _handleSchedule(service.SK)}
                handleDetail={() => _handleService(service.SK)}
                hasSmallImg
                fullpoints={state.service.isFullPointsAvailable}
                recommended={state.service.info?.recommended || false}
                recommendedFn
                data={service}
              />
            </div>
          ))}
      </div>
    </ServiceTemplate>
  );
};

export default ServiceLub;
