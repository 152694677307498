/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import Images from '../../assets/image';
import ButtonBack from '../../components/ButtonBack';
import Image from '../../components/Image';
import Title from '../../components/Title';
import { validateRut, unformatRut, formatRut } from '../../helpers/Utils';
import validate from '../../helpers/validate';
import { set_cache } from '../../redux/actions/cache';
import { set_user } from '../../redux/actions/user';
import paymentService from '../../services/payment.service';
import { DeliveryThirdSchema } from '../../validations/delivery.form.third.validations';
import { DeliverySchema } from '../../validations/delivery.form.validations';
import ComplementDirections from '../../components/ComplementDirections';
import IWillReceive from './IWillReceive';
import PaymentMethods from './PaymentMethods';
import ThirdWillReceive from './ThirdWillReceive';
import WhoWillRecieve from './WhoWillReceive';
import { LOAD_OFF, LOAD_ON } from '../../redux/actions/loader';
import DiscountService from '../../services/discounts.service';
import { set_cross_selling } from '../../redux/actions/cross_selling';
import ErrorValidation from '../../components/ErrorValidation';
import termsService from '../../services/terms.service';
import { validateAvailablesGeozone } from '../../helpers/geozone_validations';
import ErrorsServicePopup from '../../components/ErrorsServicesPopup';
import { checkIfServicesAreAvailable } from '../../helpers/services_validations';
import { clear_cupon } from '../../redux/actions/shopping_cart';

/**
 *
 * @ItemTypes
 *
 *  0 - Service
 *  1 - Related Product
 *  2 - Delivery
 */

const Checkout2 = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { shopping_cart, directions, user, cache, available_services } = useSelector((state: any) => state);
  const [showTerms, setShowTerms] = useState(true);
  const [state, setState] = useState<any>({
    inputs: {
      radio_delivery: 1,
      payment_method: -1,
      terms: false,
      i_will_receive: {
        name: '',
        rut: '',
        email: '',
        phone_number: '',
        house_type: 1,
      },
      third_will_receive: {
        name_third: '',
        phone_number_third: '',
        rut: null,
        email: null,
      },
    },
    validate: true,
    loading: false,
    validations: {},
  });
  const [errorModal, setErrorModal] = useState<any>({
    open: false,
    title: 'No fue posible realizar tu pedido. Uno o mas servicios que solicitaste ya no estan disponibles.',
    items: [],
  });

  useEffect(() => {
    _handleLoggedUser();
  }, [shopping_cart, state.inputs.radio_delivery]);

  useEffect(() => {
    _handleRequests();
  }, []);

  const _handleLoggedUser = async () => {
    if (user.isLogged) {
      const rut = formatRut(user.profile.rut);
      const phone_number: any = user.profile.phone_number.length > 9 ? user.profile.phone_number.slice(user.profile.phone_number.length - 9) : user.profile.phone_number;
      const i_will_receive = {
        ...state.inputs.i_will_receive,
        name: `${user.profile.name} ${user.profile.family_name}`,
        rut: rut,
        email: user.profile.email,
        phone_number: phone_number,
        apartment: directions.apartment || '',
        observations: directions.observations || '',
      };
      _setFormData(i_will_receive);
      setState({ ...state, inputs: { ...state.inputs, i_will_receive } });
    }
  };

  const _handleRequests = async () => {
    try {
      dispatch(LOAD_ON());
      const crossSellingDiscount: any = await DiscountService.getCrossSellingDiscountData();
      dispatch(set_cross_selling(crossSellingDiscount));

      if (user.isLogged) {
        const terms: any = await termsService.read();
        const userTerms: any = await termsService.readUser(user.profile.email);
        let showTerms: any = false;
        if (terms?.data?.SK !== userTerms?.data?.SK) {
          showTerms = true;
        }
        setShowTerms(showTerms);
      }

      dispatch(LOAD_OFF());
    } catch (e: any) {
      dispatch(LOAD_OFF());
    }
  };

  const _handleOnChange = async (e: any, prop: any = null) => {
    let field: any = null;
    const { name, value, checked } = e.target;
    const valueOrChecked: any = name === 'keys' || name === 'terms' ? checked : value;
    field = { [name]: valueOrChecked };

    const resultProfile: any = await validate(DeliverySchema, { ...field, house_type: state.inputs?.i_will_receive?.house_type });
    const resultThird: any = await validate(DeliveryThirdSchema, field);
    const preValidations = { ...resultThird, ...resultProfile };

    if (e.target.name === 'rut') {
      if (!validateRut(field.rut)) {
        if (preValidations.rut) {
          preValidations.rut.push('El rut no es válido');
        } else {
          preValidations.rut = ['El rut no es válido'];
        }
      }
    }

    const fieldValidations = Object.keys(preValidations).find((key: any) => key === e.target.name);
    let validations: any = { ...state.validations };

    if (fieldValidations) {
      validations = { ...validations, [e.target.name]: preValidations[e.target.name] };
    } else {
      delete validations[e.target.name];
    }

    if (prop) {
      state.inputs[prop] = { ...state.inputs[prop], ...field };
    } else {
      state.inputs[e.target.name] = valueOrChecked;
    }
    setState({ ...state, validations });
  };

  const _handlePayments = async () => {
    if (user?.profile?.info?.roles?.includes('user_company')) return;
    if (shopping_cart?.services?.length === 0) {
      toast.error('No hay servicios en el carro de compras');
      history.push('/');
      return;
    }

    setState({ ...state, validations: {}, loading: true });
    const showCarOptions: any = shopping_cart?.services?.find((service: any) => service.module !== 'kerosene' && service.module !== 'kerosene-schedule') ? true : false;
    state.inputs.i_will_receive.show_car_options = showCarOptions;
    const resultProfile: any = await validate(DeliverySchema, state.inputs.i_will_receive);
    const resultThird: any = await validate(DeliveryThirdSchema, state.inputs.third_will_receive);
    let result: any = false;

    if (parseInt(state.inputs.radio_delivery) !== 1) {
      result = { ...resultProfile, ...resultThird };
    } else {
      result = { ...resultProfile };
    }

    if (showTerms) {
      if (!state.inputs.terms) {
        if (typeof result === 'boolean') {
          result = { terms: ['Acepta los términos antes de continuar'] };
        } else {
          result.terms = ['Acepta los términos antes de continuar'];
        }
      }
    }

    if (Object.keys(result).length) {
      setState({ ...state, validations: result, loading: false });
      const target: any = Object.keys(result)[0];
      const targets: any = document.querySelectorAll(`[name=${target}]`);
      [].forEach.call(targets, (el: any) => el.scrollIntoView({ block: 'center' }));
      return;
    }

    if (!validateRut(state.inputs.i_will_receive.rut)) {
      setState({ ...state, validations: { rut: ['El rut no es válido'] }, loading: false });
      const targets: any = document.querySelectorAll(`[name=rut]`);
      [].forEach.call(targets, (el: any) => el.scrollIntoView({ block: 'center' }));
      return;
    }

    if (state.inputs.payment_method === -1) {
      toast.error('Elija un medio de pago para continuar');
      setState({ ...state, validations: {}, loading: false });
      const targets: any = document.querySelectorAll(`[name=payment_method]`);
      [].forEach.call(targets, (el: any) => el.scrollIntoView({ block: 'center' }));
      return;
    }

    /**
     * validate availables services
     */
    const servicesAvailableValidations: any = await checkIfServicesAreAvailable();

    if (servicesAvailableValidations?.actions?.includes('remove_service')) {
      setState({ ...state, validations: {}, loading: false });
      setErrorModal({
        ...errorModal,
        items: [...servicesAvailableValidations?.servicesRemoved],
        open: true,
      });

      return;
    }

    /**
     * validate availables geozone
     */
    const geozoneValidation: any = await validateAvailablesGeozone();

    if (geozoneValidation?.actions?.includes('remove_service')) {
      setState({ ...state, validations: {}, loading: false });
      setErrorModal({
        ...errorModal,
        items: [...geozoneValidation?.servicesRemoved],
        open: true,
      });

      return;
    }

    setState({ ...state, validations: {} });
    let userData: any = {};

    userData = {
      ...state.inputs.i_will_receive,
      id: user.profile?.PK ? user.profile.PK : null,
      phone: state.inputs.i_will_receive.phone_number,
      phone_number: state.inputs.i_will_receive.phone_number,
      rut: unformatRut(state.inputs.i_will_receive.rut),
      by_a_third: parseInt(state.inputs.radio_delivery) !== 1 ? { ...state.inputs.third_will_receive } : null,
    };

    userData.phone = '56' + userData.phone;
    userData.phone_number = userData.phone;
    if (userData.by_a_third) {
      userData.by_a_third.phone_number_third = '56' + userData.by_a_third.phone_number_third;
    }

    const services = shopping_cart.services.map((service: any) => {
      const schedule: any = {};
      let scheduleData: any = null;

      if (service.schedule) {
        schedule.day = service.schedule.day.day;
        schedule.month = moment(service.schedule.month._moment).month() + 1;
        schedule.year = moment(service.schedule.month._moment).year();
        schedule.hour = moment(service.schedule.hour._moment).hour();
        schedule.minutes = moment(service.schedule.hour._moment).minutes();
        schedule.date = moment(`${schedule.year}/${schedule.month}/${schedule.day} ${schedule.hour}:${schedule.minutes}`).toString();
        schedule.discount = service.schedule.hour.discount;
        scheduleData = {};
        scheduleData.schedule_at = schedule.date;
        scheduleData.discount = parseInt(schedule.discount);
      }

      const sub_items = [];

      const quantity_to_buy: any = service.service.quantity || 1;
      if (service.service.amount_discount) service.service.amount_discount = parseInt(service.service.amount_discount);
      sub_items.push({
        id: `${service.service.PK}-${service.service.SK}`,
        PK: service.service.id || service.service.PK,
        SK: service.service.SK,
        quantity_to_buy: quantity_to_buy,
        amount: Number(service.service.price),
        amount_discount: service.service.amount_discount || 0,
        name: service.service.name || service.service.nombre,
        url: service.service?.info?.gallery?.url || null,
        liter: service.service?.info?.liter || null,
        type_item: 0,
        sku: service.service?.info?.sku || '',
      });

      if (service.related_products) {
        service.related_products.forEach((rp: any) => {
          if (rp.amount_discount) rp.amount_discount = parseInt(rp.amount_discount);
          sub_items.push({
            id: `${rp.PK}-${rp.SK}`,
            PK: rp.PK,
            SK: rp.SK,
            quantity_to_buy: rp.quantity,
            amount: Number(rp.price),
            amount_discount: rp.amount_discount || 0,
            name: rp.title || rp.name || rp.nombre,
            url: rp.info?.gallery?.url || null,
            type_item: 1,
            sku: rp.sku || rp.info?.sku || '',
          });
        });
      }
      let shippingComponent: any = null;

      const serviceShipping: any = available_services.find((item: any) => item.PK === service.PK);
      if (serviceShipping) {
        const shipping: any = serviceShipping.sub_services.find((ss: any) => ss.name?.toLowerCase() === 'envio' || ss.name?.toLowerCase() === 'envío');
        if (shipping) {
          shippingComponent = {
            id: `${shipping.PK}-${shipping.SK}`,
            PK: shipping.PK,
            SK: shipping.SK,
            quantity_to_buy: 1,
            amount: Number(shipping.price),
            amount_discount: 0,
            name: 'Envío',
            delivery: true,
            type_item: 2,
          };
        }
      }

      if (shippingComponent) {
        if (shopping_cart?.cupon) {
          if (service.free_delivery) {
            shippingComponent.amount_discount = shippingComponent.amount;
          }
          sub_items.push(shippingComponent);
        } else {
          sub_items.push(shippingComponent);
        }
      }

      let vehicle: any = null;

      if (service.car) {
        const auto = {
          brand: {
            id: service.car.brand.value,
            label: service.car.brand.label,
          },
          model: {
            id: service.car.model.value,
            label: service.car.model.label,
          },
          plate: service.car.plate,
          year: service.car.year,
          type: service.car.type,
          size: service.car.size,
        };
        vehicle = auto;
      }

      return {
        id: service.id,
        PK: service.PK,
        SK: service.SK,
        parent_service: service.parent_service,
        eds_id: service.eds.eds_id,
        schedule: scheduleData,
        sub_items,
        vehicle,
        full_points: service.pointsFullCopec,
      };
    });

    const d = {
      _string: directions._string,
      latitude: directions.latitude,
      longitude: directions.longitude,
      apartment: state.inputs.i_will_receive?.apartment,
      observations: state.inputs.i_will_receive?.observations,
      id: null,
    };

    const params: any = {
      user: userData,
      payment_type: Number(state.inputs.payment_method),
      services,
      direction: d,
      redirect: process.env.REACT_APP_PAYMENT_REDIRECT,
      cupon: shopping_cart.cupon,
    };

    if (showTerms) {
      params.terms = true;
    }

    setState({ ...state, loading: true });
    dispatch(set_user(state.inputs.i_will_receive));
    dispatch(set_cache({ ...cache, directions: { ...cache.directions, last_access: moment().toString() } }));

    try {
      const response: any = await paymentService.payment(params);

      if (params.payment_type === 1) {
        const payment_data = await paymentService.getPayment(response.data.data.payment_id);
        const url_status: any = `status=${payment_data?.data?.payment?.status?.replace(/ /g, '_')?.toLowerCase()}`;
        history.push(`/order/${response.data.data.payment_id}/${url_status}`);
      } else {
        //console.log("response",response)
        window.location.href = response.data.data.link;
      }
    } catch (e: any) {
      setState({ ...state, loading: false });
      const errors: any = e.response?.data?.data;

      /** SHOW PAYMENT ERRORS */
      if (errors) {
        errors.forEach((item: any) => {
          toast.error(item);

          /** CHECK IF EXISTS CUPON ERRORS */
          if (item?.toLowerCase()?.includes('cupon expirado')) {
            /** REMOVE CUPON  */
            dispatch(clear_cupon());
            toast.success('El cupón fue eliminado con exito.');
          }
        });
      } else {
        toast.error('No fue posible completar su pedido.');
      }
    }
  };

  const _setFormData = (data: any) => {
    const name = document.querySelectorAll('input[name="name"]');
    const rut = document.querySelectorAll('input[name="rut"]');
    const email = document.querySelectorAll('input[name="email"]');
    const phone_number = document.querySelectorAll('input[name="phone_number"]');
    const apartment = document.querySelectorAll('input[name="apartment"]');
    const observations = document.querySelectorAll('textarea[name="observations"]');

    const nameArray = Array.prototype.slice.call(name);
    const rutArray = Array.prototype.slice.call(rut);
    const emailArray = Array.prototype.slice.call(email);
    const phoneNumberArray = Array.prototype.slice.call(phone_number);
    const apartmentArray = Array.prototype.slice.call(apartment);
    const observationsArray = Array.prototype.slice.call(observations);

    nameArray.forEach((field: any) => (field.value = data.name));
    rutArray.forEach((field: any) => (field.value = data.rut));
    emailArray.forEach((field: any) => (field.value = data.email));
    phoneNumberArray.forEach((field: any) => (field.value = data.phone_number));
    apartmentArray.forEach((field: any) => (field.value = data.apartment));
    observationsArray.forEach((field: any) => (field.value = data.observations));
  };

  const toggleModal = () => {
    setErrorModal({ ...errorModal, open: !errorModal.open });
  };

  return (
    <>
      <ErrorsServicePopup open={errorModal.open} title={errorModal.title} items={errorModal.items} toggleModal={toggleModal} />

      <div className="container-fluid d-sm-none" style={{ marginTop: '30px' }}>
        <div className="row justify-content-between px-3 py-2 bg-color-blue-light">
          <div className="col">
            <ButtonBack text="Volver" action={() => history.goBack()} />
          </div>
        </div>

        <WhoWillRecieve onChange={_handleOnChange} inputs={state.inputs} />

        {parseInt(state.inputs.radio_delivery) === 1 ? (
          <IWillReceive onChange={_handleOnChange} validations={state.validations} disabled={user.isLogged} />
        ) : (
          <>
            <IWillReceive onChange={_handleOnChange} inputs={state.inputs} validations={state.validations} disabled={user.isLogged} />
            <ThirdWillReceive onChange={_handleOnChange} inputs={state.inputs} validations={state.validations} />
          </>
        )}

        <ComplementDirections onChange={_handleOnChange} validations={state.validations} />

        <PaymentMethods onChange={_handleOnChange} inputs={state.inputs} title />

        <div className="row bg-color-white rounded-15 px-3 pb-5 pt-3">
          <div className="col-12">
            <div className="row px-2 py-3 color-black border-bottom">
              <div className="col bold-300">Subtotal</div>
              <div className="col text-end bold">${shopping_cart.total_without_discount?.toLocaleString('es-CL')}</div>
            </div>

            <div className="row px-2 py-3 color-black border-bottom">
              <div className="col bold-300">Descuento</div>
              <div className="col text-end bold">-${shopping_cart.discount?.toLocaleString('es-CL')}</div>
              {shopping_cart.discountRules && shopping_cart.discountRules?.discount > 0 ? (
                <>
                  {shopping_cart.discountRules.type === 'by_day' && shopping_cart.cupon?.campaign?.setup[0]?.apply === 'sobre_envio' ? null : (
                    <div className="col-12 size-08 ms-1 bold-300 color-grey mt-2">
                      <Image image={Images.Discount} className="me-2" style={{ width: '20px' }} />
                      {shopping_cart.discountRules?.description}
                    </div>
                  )}
                </>
              ) : null}
              {shopping_cart.cupon && (
                <div className="col-12 size-08 ms-1 bold-300 color-grey mt-2">
                  <Image image={Images.Discount} className="me-2" style={{ width: '20px' }} />
                  {shopping_cart.setup?.name}
                </div>
              )}
              {shopping_cart.deliveryRules && shopping_cart.deliveryRules?.discount > 0 ? (
                <div className="col-12 size-08 ms-1 bold-300 color-grey mt-2">
                  <Image image={Images.Discount} className="me-2" style={{ width: '20px' }} />
                  {shopping_cart.deliveryRules?.description}
                </div>
              ) : null}
            </div>
            <div className="row px-2 py-3 color-black border-bottom">
              <div className="col bold-300">Despacho</div>
              <div className="col text-end bold">${shopping_cart.delivery?.toLocaleString('es-CL')}</div>
            </div>

            <div className="row px-2 py-3 mt-4 color-blue-base">
              <div className="col-8 bold">TOTAL A PAGAR</div>
              <div className="col-4 text-end bold size-11">${(shopping_cart.total + shopping_cart.delivery)?.toLocaleString('es-CL')}</div>
            </div>

            {showTerms ? (
              <div className="row mb-4">
                <div className="col-12 size-09">
                  <input type="checkbox" name="terms" id="terms_mb" className="me-2" checked={state.inputs.terms} onChange={_handleOnChange} />
                  <label htmlFor="terms_mb">
                    {' '}
                    Acepto los{' '}
                    <a href="/terminos-y-condiciones" target="_blank">
                      <b>Términos y Condiciones</b>
                    </a>
                  </label>
                </div>
                <ErrorValidation validations={state.validations} name="terms" className="size-09 bold-300 text-end mt-1" />
              </div>
            ) : null}
          </div>
        </div>

        <div className="row px-4 py-5 bg-color-blue-light mb-5 color-white">
          <button className="btn-default p-3 size-11" disabled={state.loading} type="submit" onClick={_handlePayments}>
            Finalizar Pedido
            {state.loading && <div className="spinner-border ms-3" role="status" style={{ width: '20px', height: '20px' }} />}
          </button>
        </div>
      </div>

      {/* Version Desktop */}

      <div className="container-fluid bg-color-blue-light d-none d-sm-block pb-5">
        <div className="row justify-content-between px-3 py-2 mt-5">
          <div className="col-5">
            <ButtonBack text="Volver" action={() => history.goBack()} />
          </div>
          <div className="col">
            <Title size="xxl" text="Checkout" className="color-dark-blue" />
          </div>
        </div>

        <div className="row px-5 mx-5">
          <div className="col-8">
            <div className="col-12">
              <Title size="md" text="Completa los datos" className="color-dark-blue mt-4" />
            </div>

            <div className="row bg-white rounded-15 border-default">
              <WhoWillRecieve onChange={_handleOnChange} inputs={state.inputs} />

              {parseInt(state.inputs.radio_delivery) === 1 ? (
                <IWillReceive onChange={_handleOnChange} inputs={state.inputs} validations={state.validations} disabled={user.isLogged} />
              ) : (
                <>
                  <IWillReceive onChange={_handleOnChange} inputs={state.inputs} validations={state.validations} disabled={user.isLogged} />
                  <ThirdWillReceive onChange={_handleOnChange} inputs={state.inputs} validations={state.validations} />
                </>
              )}

              <ComplementDirections onChange={_handleOnChange} validations={state.validations} />
            </div>
          </div>
          <div className="col-4 px-4">
            <div className="col-12">
              <Title size="md" text="Medios de pago" className="color-dark-blue mt-4" />
            </div>

            <div className="row py-4 bg-white rounded-15 border-default">
              <div className="col-12">
                <PaymentMethods onChange={_handleOnChange} inputs={state.inputs} />
              </div>
            </div>

            <div className="row">
              <div className="col-12 p-3 mt-2 bold color-dark-blue">Resumen de compra</div>
            </div>

            <div className="row bg-color-white border-default rounded-15 px-3 pb-5 pt-3">
              <div className="col-12">
                <div className="row px-2 py-3 color-black">
                  <div className="col bold-300">Subtotal</div>
                  <div className="col text-end bold">${shopping_cart.total_without_discount?.toLocaleString('es-CL')}</div>
                </div>

                <div className="row px-2 py-3 color-black">
                  <div className="col bold-300">Descuento</div>
                  <div className="col text-end bold">-${shopping_cart.discount?.toLocaleString('es-CL')}</div>
                  {shopping_cart.discountRules && shopping_cart.discountRules?.discount > 0 ? (
                    <>
                      {shopping_cart.discountRules.type === 'by_day' && shopping_cart.cupon?.campaign?.setup[0]?.apply === 'sobre_envio' ? null : (
                        <div className="col-12 size-08 ms-1 bold-300 color-grey mt-2">
                          <Image image={Images.Discount} className="me-2" style={{ width: '20px' }} />
                          {shopping_cart.discountRules?.description}
                        </div>
                      )}
                    </>
                  ) : null}
                  {shopping_cart.cupon && (
                    <div className="col-12 size-08 ms-1 bold-300 color-grey mt-2">
                      <Image image={Images.Discount} className="me-2" style={{ width: '20px' }} />
                      {shopping_cart.setup?.name}
                    </div>
                  )}
                  {shopping_cart.deliveryRules && shopping_cart.deliveryRules?.discount > 0 ? (
                    <div className="col-12 size-08 ms-1 bold-300 color-grey mt-2">
                      <Image image={Images.Discount} className="me-2" style={{ width: '20px' }} />
                      {shopping_cart.deliveryRules?.description}
                    </div>
                  ) : null}
                </div>
                <div className="row px-2 py-3 color-black">
                  <div className="col bold-300">Despacho</div>
                  <div className="col text-end bold">${shopping_cart.delivery?.toLocaleString('es-CL')}</div>
                </div>

                <div className="row px-2 py-3 mt-4 color-blue-base">
                  <div className="col-8 bold">TOTAL A PAGAR</div>
                  <div className="col-4 text-end bold size-11">${(shopping_cart.total + shopping_cart.delivery)?.toLocaleString('es-CL')}</div>
                </div>

                {showTerms ? (
                  <div className="row mb-4">
                    <div className="col-12 size-08">
                      <input type="checkbox" name="terms" id="terms" className="me-2" checked={state.inputs.terms} onChange={_handleOnChange} />
                      <label htmlFor="terms">
                        {' '}
                        Acepto los{' '}
                        <a href="/terminos-y-condiciones" target="_blank">
                          <b>Términos y Condiciones</b>
                        </a>
                      </label>
                    </div>
                    <ErrorValidation validations={state.validations} name="terms" className="size-08 bold-300 text-end" />
                  </div>
                ) : null}

                <div className="row mt-4 px-3 color-white">
                  <button className="btn-default p-3 size-11" disabled={state.loading || user?.profile?.info?.roles?.includes('user_company')} onClick={_handlePayments}>
                    Finalizar Pedido
                    {state.loading && <div className="spinner-border ms-3" role="status" style={{ width: '20px', height: '20px' }} />}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Checkout2;
