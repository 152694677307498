/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ButtonBack from '../ButtonBack';
import Confirm from '../Confirm';
import Image from '../Image';
import ProductsCarousel from '../ProductsCarousel';
import Title from '../Title';
import { splitDirection } from '../../helpers/Utils';
import Images from '../../assets/image';
import { edit_service, remove_service } from '../../redux/actions/services';
import { useParams } from 'react-router';
import { remove_service_from_cart, update_service_to_cart } from '../../redux/actions/shopping_cart';
import CardService from './CardService';
import CardRelatedProducts from '../CardRelatedProducts';
import ProductCardSchedule from '../ProductsCarousel/ProductCardSchedule';
import { addToCartDataLayerEvent, removeFromCartDataLayerEvent } from '../../helpers/data_layer';

const Resume = (props: any) => {
  const CAROUSEL_CONFIGS: any = {
    disableButtonsControls: true,
    infinite: false,
    autoPlay: false,
    paddingRight: 0,
    paddingLeft: 0,
    responsive: {
      0: {
        items: 1,
      },
      755: {
        items: 2,
      },
      1023: {
        items: 3,
      },
      1365: {
        items: 4,
      },
    },
  };

  const CAROUSEL_CONFIGS_MOBILE: any = {
    disableButtonsControls: true,
    infinite: false,
    autoPlay: false,
    paddingRight: 5,
    paddingLeft: 5,
    responsive: {
      0: {
        items: 1,
      },
      300: {
        items: 2,
      },
      755: {
        items: 3,
      },
      1023: {
        items: 4,
      },
      1365: {
        items: 4,
      },
    },
  };

  const dispatch = useDispatch();
  const { state, onClose, onFinish, onNewService, open, filterOption, handleRecommended, showRecommendedButton } = props;
  const { directions, available_services, shopping_cart } = useSelector((state: any) => state);
  const [activeIndexProducts, setActiveIndexProducts] = useState<any>(0);
  const params: any = useParams();
  const urlParams: any = new URLSearchParams(window.location.search);
  const [data, setData] = useState<any>({
    related_products: [],
    service: null,
    car: {},
    pricing: {
      sub_total: 0,
      discount_percentage: 0,
      delivery: 0,
      discount: 0,
      total: 0,
    },
    date: {
      day: '',
      hour: '',
    },
    confirmWindow: {
      open: false,
    },
    directions: {
      title: 'a',
      subtitle: 'a',
    },
    showSlideNextPrevButtons: true,
    air_filter: true,
    cart_related_products: [],
    checkbox_filter: false,
  });
  const carouselRef = useRef<any>(null);
  const carouselRefMobile = useRef<any>(null);
  const [selectedRelatedProducts, setSelectedRelatedProducts] = useState<any>([]);

  /** CONFIG SERVICE DATA */
  useEffect(() => {
    const serviceInCartData = shopping_cart.services.find((service: any) => service.PK === props.PK && service.id === urlParams.get("id"));

    const availableService = available_services.find((serv: any) => serv.PK === serviceInCartData?.PK);

    if (!serviceInCartData) return

    const { schedule: scheduleData, car, service } = serviceInCartData;
    const { day, hour } = scheduleData;

    /** CHECK IF EXISTS AIR FILTER OBJECT */
    data.air_filter = state?.main_service?.sub_services?.find((ss: any) => ss.info?.air_filter && !ss.info.is_unvailable);

    data.cart_related_products = serviceInCartData.related_products || [];

    if (data.air_filter) {
      const isAirFilterSelected: any = serviceInCartData.related_products?.find((item: any) => item.info?.air_filter);
      data.checkbox_filter = isAirFilterSelected ? true : false;
    }

    const pricing: any = {
      sub_total: service.price,
      discount_percentage: isNaN(parseInt(hour.discount)) ? 0 : parseInt(hour.discount),
      delivery: availableService.shipping_price,
    };

    pricing.sub_total = data.cart_related_products.reduce((acc: any, item: any) => {
      return acc + (item.price * item.quantity);
    }, pricing.sub_total);

    let discount: any = (service.price * pricing.discount_percentage) / 100;
    pricing.discount = Math.round(discount);
    pricing.total = pricing.sub_total - pricing.discount + pricing.delivery;
    data.pricing = pricing;
    data.car = car;
    data.date = { day, hour };
    data.service = service;
    data.schedule = scheduleData;
    data.directions = splitDirection(directions.direction);

    setData({ ...data });

    setSelectedRelatedProducts(serviceInCartData.related_products ? [...serviceInCartData.related_products] : []);

  }, [state, shopping_cart]);

  /** CREATE RELATED PRODUCTS COMPONENTS */
  useEffect(() => {
    try {
      const page_service: any = available_services.find((as: any) => as.info?.slug === params.slug);
      let relatedProducts: any = page_service?.related_products?.filter((item: any) => item.active) || [];
      const products = relatedProducts.map((item: any) => {
        return (
          <CardRelatedProducts
            price={item.price}
            image={item.info?.gallery?.url ? { default: { alt: '', src: item.info?.gallery?.url } } : Images.NotFound}
            text={item.name}
            value="Ver detalle"
            product={item}
            data={page_service}
            handleAction={() => _addRelatedProduct(item)}
            onPlusProduct={_handlePlusClick}
            onMinusProduct={_handleMinusClick}
            recommended={item.info?.recommended || false}
          />
        );
      });

      const related_products_mobile: any = relatedProducts.map((rp: any) => {
        return (
          <ProductCardSchedule
            title={rp.name}
            value={rp.price}
            image={rp.info?.gallery?.url}
            id={rp.PK}
            onClick={() => _addRelatedProduct(rp)}
            onPlusClick={_handlePlusClick}
            onMinusClick={_handleMinusClick}
            product={rp}
            data={page_service}
            recommended={rp.info?.recommended || false}
          />
        );
      });

      data.related_products = products;
      data.related_products_mobile = related_products_mobile;
      setData({ ...data });
    } catch (e: any) {
      console.log('Loading data...', e);
    }
  }, [selectedRelatedProducts]);

  /** SET OVERFLOW */
  useEffect(() => {
    setTimeout(() => {
      const root: any = document.querySelector('html');
      if (!shopping_cart.open) {
        if (open) {
          root.style.overflowY = 'hidden';
        } else {
          root?.removeAttribute('style');
        }
      }
    }, 1);
  }, [shopping_cart.open, open]);

  /** SET SHOW NEXT PREV CARROUSEL BUTTONS */
  useEffect(() => {
    let changed: any = false;

    if (carouselRef.current && carouselRef.current.state && carouselRef.current.state.itemsCount <= carouselRef.current.state.itemsInSlide) {
      changed = true;
      setData((prevState: any) => {
        return { ...prevState, showSlideNextPrevButtons: false };
      });
    }
    if (carouselRefMobile.current && carouselRefMobile.current.state && carouselRefMobile.current.state.itemsCount <= carouselRefMobile.current.state.itemsInSlide) {
      changed = true;
      setData((prevState: any) => {
        return { ...prevState, showSlideNextPrevButtons: false };
      });
    }

    if (!changed) {
      setData((prevState: any) => {
        return { ...prevState, showSlideNextPrevButtons: true };
      });
    }
  }, [carouselRef.current, carouselRefMobile.current, data.related_products]);

  /** ADD RELATED PRODUCT */
  const _addRelatedProduct = (item: any) => {
    const service = shopping_cart.services.find((service: any) => service.id === urlParams.get("id"));

    if (!service) return

    item.quantity = 1;
    if (service.related_products) service.related_products.push(item);
    if (!service.related_products) service.related_products = [item];

    addToCartDataLayerEvent(item, params.slug, 'related_product', 'show_available_items');

    dispatch(update_service_to_cart({ ...service }));
    dispatch(edit_service({ ...service }));

    setSelectedRelatedProducts((prevState: any) => {
      return [...prevState, ...service.related_products];
    });

    setActiveIndexProducts(carouselRef.current.state.activeIndex);
  };

  const _handleAddFilter = ({ target }: any) => {
    const { air_filter } = data;
    data.checkbox_filter = target.checked;
    setData(data);
    if (target.checked) {
      _addRelatedProduct(air_filter);
    } else {
      _handleMinusClick(air_filter);
    }
  };

  const _deleteService = () => {
    const service = shopping_cart.services.find((service: any) => service.id === urlParams.get("id"));

    if (!service) return

    service.related_products?.forEach((item: any) => {
      removeFromCartDataLayerEvent(item, params.slug, 'related_product', 'show_available_items');
    });

    dispatch(remove_service_from_cart({ ...service }));
    dispatch(remove_service({ ...service }));
    removeFromCartDataLayerEvent(service.service, params.slug, 'service', 'show_available_items');

    setData({ ...data, confirmWindow: { open: false } });
  };

  const _handleDeleteProduct = (payload: any) => {
    const service = shopping_cart.services.find((service: any) => service.id === urlParams.get("id"));

    if (!service) return

    const relatedProduct: any = service.related_products.find((relatedProduct: any) => relatedProduct.SK === payload.SK);

    if (relatedProduct) {
      relatedProduct.quantity = 0;
      removeFromCartDataLayerEvent(relatedProduct, params.slug, 'related_product', 'show_available_items');
      const index = service.related_products.findIndex((rp: any) => rp.SK === payload.SK);
      service.related_products.splice(index, 1);
    }

    dispatch(update_service_to_cart({ ...service }));
    dispatch(edit_service({ ...service }));
  }

  const _handlePlusClick = (payload: any) => {
    const service = shopping_cart.services.find((service: any) => service.id === urlParams.get("id"));

    if (!service) return

    const relatedProduct: any = service.related_products.find((relatedProduct: any) => relatedProduct.SK === payload.SK);

    if (relatedProduct) {
      relatedProduct.quantity = relatedProduct.quantity + 1;
      addToCartDataLayerEvent(relatedProduct, params.slug, 'related_product', 'show_available_items');
    }
    const relatedProductsList = [...service.related_products];
    setSelectedRelatedProducts(relatedProductsList);

    dispatch(update_service_to_cart({ ...service }));
    dispatch(edit_service({ ...service }));
  };

  const _handleMinusClick = (payload: any) => {
    const service = shopping_cart.services.find((service: any) => service.id === urlParams.get("id"));

    if (!service) return

    const relatedProduct: any = service.related_products.find((relatedProduct: any) => relatedProduct.SK === payload.SK);

    if (relatedProduct) {
      relatedProduct.quantity = relatedProduct.quantity - 1;
      removeFromCartDataLayerEvent(relatedProduct, params.slug, 'related_product', 'show_available_items');
      if (relatedProduct.quantity === 0) {
        const index = service.related_products.findIndex((rp: any) => rp.SK === payload.SK);
        service.related_products.splice(index, 1);
      }
    }

    const relatedProductsList = [...service.related_products];
    setSelectedRelatedProducts(relatedProductsList);

    dispatch(update_service_to_cart({ ...service }));
    dispatch(edit_service({ ...service }));
  };

  const _nextSlide = () => {
    if (carouselRef.current.state.itemsCount - carouselRef.current.state.itemsInSlide > activeIndexProducts) {
      carouselRef.current.slideNext();
    }

    if (carouselRefMobile.current.state.itemsCount - carouselRefMobile.current.state.itemsInSlide > activeIndexProducts) {
      carouselRefMobile.current.slideNext();
    }
  };

  const _prevSlide = () => {
    if (activeIndexProducts > 0) {
      carouselRef.current.slidePrev();
      carouselRefMobile.current.slidePrev();
    }
  };

  const _onSlideChanged = (e: any) => {
    setActiveIndexProducts(e.item);
  };

  const _handleDeleteService = () => {
    setData({ ...data, confirmWindow: { open: true } });
  };

  return (
    <div className="component-resume" style={{ position: 'relative' }}>
      <Confirm type="danger" open={data.confirmWindow.open} onClose={() => setData({ ...data, confirmWindow: { open: false } })} sticky>
        <div className="row px-3 py-3">
          <div className="col-10 bold">
            <div>¿Te arrepentiste?</div>
            <div>Si, deseo eliminar este producto.</div>
          </div>
          <div className="col-2  d-flex justify-content-center align-items-center">
            <button onClick={_deleteService} className="btn color-white size-15">
              <i className="bi bi-trash"></i>
            </button>
          </div>
        </div>
      </Confirm>

      {/* Mobile Version */}

      <div className="d-sm-none">
        <div className="row mt-3 size-09">
          <ButtonBack text="Volver" action={onClose} />
        </div>
        <Title size="md" text="Resumen del servicio" className="d-flex w-100 justify-content-center mb-4 mt-2 color-dark-blue bold size-09" />

        <div className="row">
          <div className="col-12 size-09 bold px-4">Dirección</div>
          <div className="col-12 mt-2 line-border pb-3">
            <div className="row px-3">
              <div className="col-12">
                <Image image={Images.DirectionInput} />
                <span className="color-black bold-300 ms-2">{data.directions.title}</span>
              </div>
              <div className="col-12">
                <span className=" bold-300 ms-4 size-09">{data.directions.subtitle}</span>
              </div>
            </div>
          </div>
        </div>

        {/* Card Service */}

        <CardService data={data} handleDelete={_handleDeleteService} isMobile />

        {data.cart_related_products && data.cart_related_products.map((rp: any) => <CardService key={rp.PK} data={rp} isMobile handleDelete={() => _handleDeleteProduct(rp)} />)}

        {filterOption && data.air_filter && (
          <div className="mt-3 px-3 d-flex align-items-center size-09 line-border pb-3">
            <input className="me-3" style={{ width: '20px', height: '20px' }} type="checkbox" id="air-filter" onChange={_handleAddFilter} checked={data.checkbox_filter} />
            <label htmlFor="air-filter">Agregar filtro de aire</label>
          </div>
        )}

        <div className="line-border py-3">
          <Title size="sm" text="Datos de agendamiento" className="d-flex w-100 mb-3 mt-2 px-3 bold size-09" />
          <div className="row size-09 px-3">
            <div className="col-5 color-black">Fecha</div>
            <div className="col-7">: {`${moment(data.date?.day._moment).format('LL')}`}</div>
          </div>
          <div className="row size-09 px-3">
            <div className="col-5 color-black">Hora</div>
            <div className="col-7">: {data.date?.hour._string}</div>
          </div>
          <div className="row size-09 px-3">
            <div className="col-5 color-black">Datos del vehículo</div>
            <div className="col-7 capitalize">: {`${data.car?.brand?.label} ${data.car?.model?.label} ${data.car?.year}`.toLowerCase()}</div>
          </div>
        </div>

        <div className="line-border pb-3 pt-4 mt-2 color-black">
          <div className="row size-09 px-3 mb-3">
            <div className="col-5 color-black">Subtotal</div>
            <div className="col-7 text-end">${data.pricing.sub_total?.toLocaleString('es-CL')}</div>
          </div>
          {data?.pricing?.discount > 0 && (
            <div className="row size-09 px-3 mb-3">
              <div className="col-5 color-black">Descuento</div>
              <div className="col-7 text-end">{`-$${data.pricing.discount?.toLocaleString('es-CL')}`}</div>
            </div>
          )}
          <div className="row size-09 px-3 mb-3">
            <div className="col-5 color-black">Despacho</div>
            <div className="col-7 text-end">${data.pricing.delivery?.toLocaleString('es-CL')}</div>
          </div>
        </div>

        <div className="line-border pb-3 pt-4 mt-2 px-3 color-blue-base bold d-flex justify-content-between">
          <span>TOTAL A PAGAR</span>
          <span>${data.pricing.total?.toLocaleString('es-CL')}</span>
        </div>

        {data && data.related_products_mobile?.length > 0 && (
          <div className="row mt-4 bg-color-blue-light py-4 d-sm-none">
            <div className="col-12 d-flex flex-column justify-content-center align-items-center">
              <div>
                <Title size="md" text="También te puede interesar" className={`color-blue-base mt-2 me-3`} />
              </div>
              {data.showSlideNextPrevButtons && (
                <div>
                  <button onClick={_prevSlide} className="btn color-blue-base bold">
                    <Image image={Images.ArrowDown} style={{ transform: 'rotate(90deg)' }} />
                  </button>
                  <button onClick={_nextSlide} className="btn color-blue-base bold">
                    <Image image={Images.ArrowDown} style={{ transform: 'rotate(270deg)' }} />
                  </button>
                </div>
              )}
            </div>
            <ProductsCarousel items={data.related_products_mobile} {...CAROUSEL_CONFIGS_MOBILE} activeIndex={activeIndexProducts} ref={carouselRefMobile} onSlideChanged={_onSlideChanged} />
            {showRecommendedButton && (
              <div className="col-12 text-end">
                <button className="btn bold color-blue-base" style={{ textDecoration: 'underline' }} onClick={handleRecommended}>
                  Ver todos
                  <Image image={Images.ArrowDown} style={{ transform: 'rotate(270deg)', marginLeft: '10px', width: '13px' }} />
                </button>
              </div>
            )}
          </div>
        )}

        <div className="row px-4 mb-5 mt-3">
          <button className="btn-default w-100 size-11 p-3 rounded-30 mb-3" onClick={onNewService}>
            Agregar otro servicio
          </button>
          <button className="btn-default w-100 size-11 p-3 rounded-30" onClick={onFinish}>
            Ir a pagar
          </button>
        </div>
      </div>

      {/* Desktop Version */}

      <div className="container-fluid bg-color-blue-light d-none d-sm-block" style={{ minHeight: '100vh' }}>
        <div className="row position-relative pt-5 justify-content-center">
          <div className="col-12 d-flex align-items-center position-relative">
            <div className="position-absolute">
              <ButtonBack text="Volver" action={onClose} style={{ fontSize: '1.2em', left: '40%' }} />
            </div>
            <Title size="xl" text="Resumen del servicio" className="d-flex w-100 justify-content-center mt-2 color-dark-blue bold" />
          </div>
        </div>

        <div className="row position-relative pt-5 justify-content-center">
          <div className="col-12 py-3 px-5">
            <div className="row justify-content-center">
              {/* Products */}
              <div className="col-8 me-3 bg-color-white rounded-15 border-default p-4">
                <div className="row px-4 border-bottom pb-3">
                  <div className="col-12 bold-300">Dirección</div>
                  <div className="col-12 mt-2">
                    <div className="row">
                      <div className="col-12">
                        <Image image={Images.DirectionInput} />
                        <span className="color-black bold-300 ms-2">{data.directions.title}</span>
                      </div>
                      <div className="col-12">
                        <span className=" bold-300 ms-4 size-09">{data.directions.subtitle}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row px-2 pt-3 m-0">
                  <div className="col-12 bold-300 mb-3">Datos del servicio</div>
                </div>

                {/* Card Service */}

                <CardService data={data} handleDelete={_handleDeleteService} />
                {data.cart_related_products && data.cart_related_products.map((rp: any) => <CardService key={rp.PK} data={rp} handleDelete={() => _handleDeleteProduct(rp)} />)}
                {filterOption && data.air_filter && (
                  <div className=" px-3 d-flex align-items-center size-09 line-border py-4">
                    <input className="me-3" style={{ width: '20px', height: '20px' }} type="checkbox" id="air-filter" onChange={_handleAddFilter} checked={data.checkbox_filter} />
                    <label htmlFor="air-filter">Agregar filtro de aire</label>
                  </div>
                )}

                <div className="py-3">
                  <Title size="sm" text="Datos de agendamiento" className="d-flex w-100 mb-3 mt-2 px-3 bold" />
                  <div className="row px-3">
                    <div className="col-4 color-black bold-300">Fecha</div>
                    <div className="col-7">: {`${moment(data.date?.day._moment).format('LL')}`}</div>
                  </div>
                  <div className="row px-3">
                    <div className="col-4 color-black bold-300">Hora</div>
                    <div className="col-7">: {data.date?.hour._string}</div>
                  </div>
                  <div className="row px-3">
                    <div className="col-4 color-black bold-300">Datos del vehículo</div>
                    <div className="col-7 capitalize">: {`${data.car?.brand?.label} ${data.car?.model?.label} ${data.car?.year}`.toLowerCase()}</div>
                  </div>
                </div>
              </div>

              {/* Values */}
              <div className="col-3 bg-color-white rounded-15 border-default px-4" style={{ height: "fit-content" }}>
                <div className="d-flex flex-column justify-content-between pb-4">
                  <div className="pb-3 pt-4 mt-2 color-black bold-300">
                    <div className="row px-3 py-2 mb-3 line-border">
                      <div className="col-5 color-black">Subtotal</div>
                      <div className="col-7 text-end">${data.pricing?.sub_total?.toLocaleString('es-CL')}</div>
                    </div>

                    {data?.pricing?.discount > 0 && (
                      <div className="row px-3 py-2 mb-3 line-border">
                        <div className="col-5 color-black">Descuento</div>
                        <div className="col-7 text-end">{`-$${data.pricing?.discount?.toLocaleString('es-CL')}`}</div>
                      </div>
                    )}

                    <div className="row px-3 py-2 mb-3 line-border">
                      <div className="col-5 color-black">Despacho</div>
                      <div className="col-7 text-end">${data.pricing?.delivery?.toLocaleString('es-CL')}</div>
                    </div>
                  </div>

                  <div>
                    <div className="w-100" />
                    <div className=" pb-3 pt-4 mt-2 px-3 color-blue-base bold d-flex justify-content-between">
                      <span>TOTAL A PAGAR</span>
                      <span>${data.pricing?.total?.toLocaleString('es-CL')}</span>
                    </div>
                    <div className="m-3">
                      <button className="btn-default size-11 p-2 rounded-30 px-4 w-100 mb-3" onClick={onNewService}>
                        Agregar Otro Servicio
                      </button>
                      <button className="btn-default size-11 p-2 rounded-30 px-4 w-100" onClick={onFinish}>
                        Ir a pagar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={`row mt-4 bg-color-blue-light py-4 ${data?.related_products?.length > 0 ? 'd-flex' : 'd-none'}`}>
          <div className="col-12 d-flex justify-content-center align-items-center pb-4">
            <Title size="md" text="También te puede interesar" className={`color-dark-blue mt-2 me-3`} />
            {data.showSlideNextPrevButtons && (
              <>
                <button onClick={_prevSlide} className="btn color-blue-base bold">
                  <Image image={Images.ArrowDown} style={{ transform: 'rotate(90deg)' }} />
                </button>
                <button onClick={_nextSlide} className="btn color-blue-base bold">
                  <Image image={Images.ArrowDown} style={{ transform: 'rotate(270deg)' }} />
                </button>
              </>
            )}
          </div>
          <ProductsCarousel items={data.related_products} {...CAROUSEL_CONFIGS} activeIndex={activeIndexProducts} ref={carouselRef} onSlideChanged={_onSlideChanged} />
          {showRecommendedButton && (
            <div className="col-12 text-end">
              <button className="btn bold color-blue-base" style={{ textDecoration: 'underline' }} onClick={handleRecommended}>
                Ver todos
                <Image image={Images.ArrowDown} style={{ transform: 'rotate(270deg)', marginLeft: '10px', width: '13px' }} />
              </button>
            </div>
          )}
        </div>
      </div>

      <div className="adjustment-iphone" style={{ height: '15vh' }} />
    </div>
  );
};

export default Resume;
