/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import IDrawer from '../../interfaces/IDrawer';
import '../../styles/components/component.drawer.scss';

const Drawer: React.FC<IDrawer> = (props) => {
  const { show, direction, children, handleClose, className, ...rest } = props;
  const { shopping_cart } = useSelector((state: any) => state);
  useEffect(() => {
    const root: any = document.querySelector('html');
    root?.scrollTo(0, 1);

    const containerRL = document.querySelector('.container-drawer-rl');
    const containerBT = document.querySelector('.container-drawer-bt');

    if (containerRL) {
      containerRL.scrollTo(0, 1);
    }

    if (containerBT) {
      containerBT.scrollTo(0, 1);
    }

    if (shopping_cart.open || show) {
      root.style.overflowY = 'hidden';
    } else {
      root?.removeAttribute('style');
    }
  }, [shopping_cart.open, show]);

  const _handleClose = (e: any) => {
    if (handleClose) {
      handleClose(e);
    }
  };
  return (
    <div
      className={`${direction === 'right-left' ? `container-drawer-rl ${className}` : `container-drawer-bt ${className}`} ${!show ? direction : ''}`}
      onClick={_handleClose}
      {...rest}
    >
      {children}
    </div>
  );
};

export default Drawer;
