import Images from '../assets/image';

const faq_kerosene: any = [
  {
    question: '¿Cuánto se demora el despacho?',
    response: 'La promesa es como máximo al otro día del pedido, pero intentaremos entregar lo antes posible.',
    image: null,
  },
  {
    question: 'Me llegó bidón sin sello, ¿Qué debo hacer?',
    response: 'Es una promesa Copec entregar bidón con su sello, si tu pedido llega sin sello lo puedes rechazar.',
    image: null,
  },
  {
    question: '¿Qué otras opciones de litros existen?',
    response:
      'Actualmente, todas las comunas habilitadas cuentan con compras en bidones de 20 litros, existiendo algunas con la opción de bidones de 10 litros. Al ingresar con tu dirección a la página, podrás conocer las opciones en tu comuna. ',
    image: null,
  },
  {
    question: '¿Cuál es el mínimo de compra?',
    response:
      'Si tu comuna cuenta con bidones de 10 litros, la compra mínima son 10 litros y puedes comprar en múltiplos de 10. En caso de no existir esa opción, la compra mínima son 20 litros y solo puedes comprar en múltiplos de 20.',
    image: null,
  },
  {
    question: '¿Qué puedo hacer si no tengo el bidón?',
    response:
      'Si no tienes un bidón que cumpla las condiciones para el recambio, debes comprar una sola vez a modo de inversión, para luego poder realizar el recambio del bidón. El precio está indicado en nuestra web.',
    image: null,
  },
  
  {
    question: '¿Qué bidón necesito para comprar Kerosene?',
    response: [
      {
        text: '- Bidón Copec de color azul de 20 litros con sello certificado por SEC (año 2015).',
        image: null,
      },
      {
        text: '- Bidón Copec limpio y en buen estado, es decir, sin fugas, orificios, líquido ni material particulado.',
        image: null,
      },
      {
        text: '- Utiliza el bidón de la foto como referencia para chequear si el tuyo cumple.',
        image: Images.Bidon3,
      },
      {
        text: '- Recuerda que otros clientes deben poder utilizar este bidón después.',
        image: null,
      },
    ],
    image: null,
  },
  {
    question: '¿Cuáles son las formas de pago?',
    response: [
      {
        text: 'Puedes pagar utilizando WebPay, tanto con débito como crédito.',
        image: null,
      },
      {
        text: 'También puedes realizar el pago en el momento de la entrega. Opciones:',
        image: null,
      },
      {
        text: '- Efectivo',
        image: null,
      },
      {
        text: '- Tarjeta de débito',
        image: null,
      },
      {
        text: '- Tarjeta de crédito',
        image: null,
      },
    ],
    image: null,
  },
  {
    question: '¿Puedo comprar sin Usuario?',
    response: 'Sí, puedes hacer el flujo de compra sin iniciar sesión.',
    image: null,
  },
  {
    question: '¿Cómo comprar?',
    response: [
      {
        text: '1. Puedes iniciar sesión o continuar como invitado. En otro caso puedes llamar a nuestro Call Center 6002000202.',
      },
      {
        text: '2. Selecciona tu pedido: cantidad de litros Kerosene o cantidad de bidones.',
      },
      {
        text: '3. Recibirás un comprobante en tu correo con el detalle de tu pedido y fecha de despacho.',
      },
      {
        text: '4. Elige el medio de pago que más te acomode.',
      },
    ],
    image: null,
  },
  {
    question: 'Quiero devolver o cambiar mi producto',
    response:
      'Una vez recibido el bidón, no se aceptan cambios o devoluciones. En el caso que hayas recibido el producto adulterado o con alguna falla contáctanos a la brevedad para ayudarte. El plazo para esta solicitud es de 10 días.',
    image: null,
  },
  {
    question: 'Condiciones de pago Webpay (Débito y Crédito)',
    response: [
      {
        text: 'Para pagos con WebPay, el dinero saldrá de tu cuenta bancaria en el momento que confirmes el pedido. Si tu pedido se entrega conforme a lo previsto, el dinero permanecerá en las cuentas de Copec. En caso de que desees añadir productos en el momento de la venta, estos podrán ser agregados a tu pedido original sin problemas, pero el pago de esos productos se debe realizar de forma presencial.',
      },
      {
        text: 'Por otra parte, si necesitas anular tu pedido, podrás realizarlo siempre y cuando éste no haya cambiado de estado a "En Ruta". Para solicitar la devolución de tu dinero, puedes contactarte con nuestros ejecutivos llamando al 800 200 354, quienes te ayudarán a gestionar la devolución mediante transferencia bancaria, la cual se realizará dentro de 7 hábiles.',
      },
    ],
    image: null,
  },
  {
    question: '¿Cuándo se cargarán mis puntos Full?',
    response: 'Los puntos Full serán cargados en el momento en que el servicio sea entregado por el atendedor. Los puntos Full solo podrán ser cargados si eres parte del programa Full.',
    image: null,
  },
];

export default faq_kerosene;
