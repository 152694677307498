/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Images from '../../assets/image';
import Image from '../../components/Image';
import Modal from '../../components/Modal';
import Title from '../../components/Title';

const ModalKerosene = (props: any) => {
  const { open, onContinue } = props;
  const { matchMedia } = useSelector((state: any) => state);

  const [style, setStyle] = useState<any>({
    background: '#fff',
    width: '100%',
    height: '85vh',
    maxHeight: '85vh',
    overflow: 'hidden',
    maxWidth: '500px',
  });

  useEffect(() => {
    if (matchMedia.media === 'xs') {
      setStyle({ ...style, height: '85vh' });
    } else {
      const s = {
        background: '#fff',
        width: '100%',
        maxHeight: '85vh',
        overflow: 'hidden',
        maxWidth: '500px',
      };
      setStyle({ ...s });
    }
  }, [matchMedia.media]);
  return (
    <Modal className="px-3 " open={open}>
      <div className="row px-3 rounded-20 position-relative pt-4" style={style}>
        <div className="col-12 position-relative" style={{ maxHeight: '55vh', overflow: 'auto' }}>
          <div className="col-12 text-center">
            <Image image={Images.Bidon2} />
          </div>
          <Title size="md" text="Importante" className="d-flex w-100 justify-content-center mt-3 mb-3 color-dark-blue" />
          <div className="col-12 d-flex mb-3 size-09 bold">
            <div className="circle-blue me-3 mt-1" />
            Exige tu bidón sellado (Sello Copec).
          </div>
          <div className="col-12 d-flex mb-3 size-09 bold">
            <div className="circle-blue me-3 mt-1" />
            Recuerda que si sólo compraste Kerosene, deberás entregarnos tu bidón Copec con certificado SEC y en buen estado.
          </div>
          <div className="col-12 d-flex mb-3 size-09 bold">
            <div className="circle-blue me-3 mt-1" />
            Este debe ser del año 2015 en adelante, completamente azul (uso exclusivo Kerosene), hermético y completamente vacío.
          </div>
          {/* <div className="col-12 d-flex mb-3 size-09 bold">
            <div className="circle-blue me-3 mt-1" />
            En caso de que no lo tengas o que no esté en buen estado, puedes pagarlo al momento de la entrega con un 20% de descuento.
          </div> */}

          <div className="col-12 mb-5 size-09 bold-300">
            <i>Copec se reserva el derecho de rechazar bidones que no cumplan con las condiciones de seguridad e imagen.</i>
          </div>
        </div>

        <div className={`col-12 text-center mt-3 ${matchMedia.media === 'xs' ? 'mb-2' : 'mb-5'}`}>
          <button className="btn-default w-100 py-3 rounded-10 size-11" onClick={onContinue}>
            Continuar
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalKerosene;
