import { createAction } from 'redux-actions';

export const SET_RECOMMENDED: any = createAction('SET_RECOMMENDED');
export const SAVE_STATE: any = createAction('SAVE_STATE');

export const set_recommended = (payload: any) => {
  return (dispatch: any) => {
    dispatch(SET_RECOMMENDED(payload));
    dispatch(SAVE_STATE());
  };
};
