import { createAction } from 'redux-actions';

export const SET_EVALUATIONS: any = createAction('SET_EVALUATIONS');
export const SAVE_STATE: any = createAction('SAVE_STATE');

export const set_evaluations = (payload: any) => {
  return (dispatch: any) => {
    dispatch(SET_EVALUATIONS(payload));
    dispatch(SAVE_STATE());
  }
}
