/* eslint-disable react-hooks/exhaustive-deps */
import { useHistory } from "react-router-dom";
import Images from "../../assets/image";
import Image from "../../components/Image";

const CarCard = (props: any) => {
  const { data, onChange, onUpdate, className, id, onDelete, desktop, name } =
    props;
  const history = useHistory();
  console.log(id)
  return (
    <div
      className={`w-100 p-2 ${className || ""}`}
      style={{ borderBottom: "4px solid #F8FAFF" }}
    >
      <div className="row">
        <div className="col-12 col-sm-10">
          <div className="row">
            {!desktop && (
              <div className="col-2" style={{ marginBottom: "2px" }}>
                <Image image={Images.MyCars} />
              </div>
            )}
            <div className="col-10" style={{ marginBottom: "2px" }}>
              <div className="size-11 bold-300 color-black">{`${data.info.brand.label} ${data.info.model.label}`}</div>
              <div className="size-09">{`Patente ${data.plate.toUpperCase()} - Año ${
                data.year
              }`}</div>
            </div>

            <div
              className="col-7 col-sm-12 d-flex mt-3 align-items-center"
              style={{ marginBottom: "2px" }}
            >
              <input
                className="me-3"
                type="radio"
                name={name}
                checked={data.main ? true : false}
                onChange={() => onChange(id)}
              />{" "}
              Vehículo favorito
            </div>

            <div className="col-5 d-flex d-sm-none justify-content-end color-blue-base mt-3 align-items-center">
              <Image
                image={Images.EditIcon}
                style={{ width: "20px", cursor: "pointer" }}
                onClick={() => history.push(`/profile/my-cars/update/${id}`)}
                className="me-4"
              />
              <Image
                image={Images.DeleteIcon}
                style={{ width: "20px", cursor: "pointer" }}
                onClick={() => onDelete && onDelete(id)}
                className="me-2"
              />
            </div>
          </div>
        </div>
        <div className="col-2 d-none d-sm-flex justify-content-around color-blue-base">
          <Image
            image={Images.EditIcon}
            style={{ width: "20px", cursor: "pointer" }}
            onClick={() => onUpdate(id)}
            className="me-4"
          />
          <Image
            image={Images.DeleteIcon}
            style={{ width: "20px", cursor: "pointer" }}
            onClick={() => onDelete && onDelete(id)}
            className="me-2"
          />
        </div>
      </div>
    </div>
  );
};
export default CarCard;
