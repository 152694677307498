import Images from '../../assets/image';
import Image from '../Image';

const ServiceCard = (props: any) => {
  const { service, _handleGotoService } = props;
  return (
    <div className="w-100 d-flex p-0 shadow mb-3" style={{ borderRadius: '17px' }}>
      <div style={{ width: '200px' }}>
        <Image image={service.info?.gallery?.url ? { default: { src: service.info?.gallery?.url } } : Images.NotFound} style={{ width: '100%', height: '130px', borderRadius: '17px 0 0 17px' }} />
      </div>
      <div className="d-flex flex-column justify-content-center align-items-start ps-3 w-100 color-white bold" style={{ background: '#203391', width: '100%', borderRadius: '0 17px 17px 0' }}>
        <div className="size-12">{service.name}</div>
        <button className="btn-green-light py-2 mt-2" onClick={() => _handleGotoService(`/services/${service.url}`)}>
          Pide aquí
        </button>
      </div>
    </div>
  );
};

export default ServiceCard;
