/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { set_actionbar } from '../../redux/actions/action_bar';
import { close_cart } from '../../redux/actions/shopping_cart';

const GoToCheckoutButton = (props: any) => {
  const { className, style, onClick } = props;

  const { action_bar, available_services, shopping_cart, matchMedia } = useSelector((state: any) => state);
  const [disabled, setDisabled] = useState(false);
  const dispatch: any = useDispatch();
  const params: any = useParams();

  useEffect(() => {
    if (params && params.slug) {
      let availables_to_select: any = [];
      available_services.forEach((as: any) => {
        const find = shopping_cart.services.find((service: any) => service.PK === as.PK);
        if (!find) {
          availables_to_select.push(as);
        }
      });

      const isThisServiceUnselected: any = availables_to_select.find((item: any) => item.info?.slug === params.slug);

      if (isThisServiceUnselected) {
        setDisabled(true);
      } else {
        setDisabled(false);
      }
    }
  }, [action_bar]);

  const _handleOnClick = () => {
    dispatch(close_cart());
    available_services.sort((a: any, b: any) => a.name > b.name ? 1 : -1)
    if (matchMedia.media === 'xs') {
      dispatch(set_actionbar({ ...action_bar, unselected_services: available_services, show: true }))
    } else {
      dispatch(set_actionbar({ ...action_bar, unselected_services: available_services, modal_open: true }))
    }
  };

  return (
    <button className={`${className || 'btn-default py-3 px-5 size-12'}`} style={style || { width: '300px' }} onClick={onClick || _handleOnClick} disabled={disabled}>
      Continuar
    </button>
  );
};

export default GoToCheckoutButton;
