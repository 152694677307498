import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Images from '../../assets/image';
import AddressSeted from '../../components/Address/AddressSeted';
import ButtonBack from '../../components/ButtonBack';
import Cupon from '../../components/Cupon';
import DrawerDirections from '../../components/DrawerDirections';
import Image from '../../components/Image';
import Title from '../../components/Title';
import CheckoutServiceItemMobile from '../../components/CheckoutServiceItem/CheckoutServiceItemMobile';
import CheckoutProductItemMobile from '../../components/CheckoutProductItem/CheckoutProductItemMobile';

const CheckoutMobile = (props: any) => {
  const { state, _toogleDrawer, _handleDeleteService, _handlePlusProduct, _handleMinusProduct, _handlePlusService, _handleMinusService, _handleDeleteProduct, _handleNext } = props;
  const history = useHistory();
  const { shopping_cart, user } = useSelector((state: any) => state);

  const _handleFirstDrawer = () => {
    if (user.isLogged && state?.addresses?.length > 0) {
      _toogleDrawer('history');
    } else {
      _toogleDrawer('autocomplete');
    }
  };
  return (
    <div className="container-fluid d-sm-none">
      <DrawerDirections state={state} _toogleDrawer={_toogleDrawer} _closeAndOpen={_toogleDrawer} addresses={state.addresses} />
      <div className="row justify-content-between px-3 py-2 bg-color-blue-light">
        <div className="col">
          <ButtonBack text="Volver" action={() => history.goBack()} />
        </div>
      </div>

      <div className="row px-2">
        <div className="col-12 size-09 bold px-4 mt-4 pb-2 color-dark-blue line-border">Dirección del servicio</div>
        <AddressSeted onClick={_handleFirstDrawer} bgWhite addresses={state.addresses} />
      </div>

      <div className="row px-2">
        <div className="col-12 size-09 bold px-4 mt-4 pb-2 color-dark-blue line-border">Cupón de descuento</div>
        <div className="col-12 p-4">
          <Cupon />
        </div>
      </div>

      <div className="row">
        <Title size="md" text="Resumen de compra" className="d-flex w-100 justify-content-center p-3 color-dark-blue bold bg-color-blue-light" />
      </div>

      {shopping_cart.services && shopping_cart.services.length === 0 && (
        <div className="row mb-5 mt-5 text-center">
          <div>
            <Image image={Images.EmptyCart} className="w-50" />
          </div>
          <Title text="Carro vacío!" className="text-center color-blue-base " />
        </div>
      )}

      {/* Loop products */}
      {shopping_cart.services &&
        shopping_cart.services.map((service: any, index: number) => (
          <Fragment key={`service-${index}`}>
            <div className="row">
              <div className="col-12 p-3 mt-2 bold color-dark-blue line-border">Servicio {service.name}</div>
            </div>
            <div className="row line-border p-3 mt-3 align-items-center">
              {service.service ? (
                <CheckoutServiceItemMobile service={service} _handleDeleteService={_handleDeleteService} _handlePlusService={_handlePlusService} _handleMinusService={_handleMinusService} />
              ) : null}
            </div>

            {/* Loop related products */}

            {service.related_products &&
              service.related_products.map((rp: any) => (
                <CheckoutProductItemMobile
                  service={service}
                  product={rp}
                  _handlePlusProduct={_handlePlusProduct}
                  _handleMinusProduct={_handleMinusProduct}
                  _handleDeleteProduct={_handleDeleteProduct}
                />
              ))}
          </Fragment>
        ))}

      {shopping_cart.services.length > 0 && (
        <>
          <div className="row line-border px-2 py-3 color-black">
            <div className="col">Subtotal</div>
            <div className="col text-end bold">${shopping_cart.total_without_discount?.toLocaleString('es-CL')}</div>
          </div>

          <div className="row line-border px-2 py-3 color-black">
            <div className="col">Descuento</div>
            <div className="col text-end bold">-${shopping_cart.discount?.toLocaleString('es-CL')}</div>
            {shopping_cart.discountRules && shopping_cart.discountRules?.discount > 0 ? (
              <>
                {shopping_cart.discountRules.type === 'by_day' && shopping_cart.cupon?.campaign?.setup[0]?.apply === 'sobre_envio' ? null : (
                  <div className="col-12 size-08 ms-1 bold-300 color-grey mt-2">
                    <Image image={Images.Discount} className="me-2" style={{ width: '20px' }} />
                    {shopping_cart.discountRules?.description}
                  </div>
                )}
              </>
            ) : null}
            {shopping_cart.cupon && (
              <div className="col-12 size-08 ms-1 bold-300 color-grey mt-2">
                <Image image={Images.Discount} className="me-2" style={{ width: '20px' }} />
                {shopping_cart.setup?.name}
              </div>
            )}
            {shopping_cart.deliveryRules && shopping_cart.deliveryRules?.discount > 0 ? (
              <div className="col-12 size-08 ms-1 bold-300 color-grey mt-2">
                <Image image={Images.Discount} className="me-2" style={{ width: '20px' }} />
                {shopping_cart.deliveryRules?.description}
              </div>
            ) : null}
          </div>

          <div className="row line-border px-2 py-3 color-black">
            <div className="col">Despacho</div>
            <div className="col text-end bold">${shopping_cart.delivery?.toLocaleString('es-CL')}</div>
          </div>

          {shopping_cart.fullPointsCopec && shopping_cart.fullPointsCopec > 0 ? (
            <div className="row line-border px-2 py-3 color-black">
              <div className="col">Puntos Full Acumulados</div>
              <div className="col text-end bold">{shopping_cart.fullPointsCopec}</div>
            </div>
          ) : null}

          <div className="row px-2 py-3 mt-4 color-blue-base">
            <div className="col bold size-11">TOTAL A PAGAR</div>
            <div className="col text-end bold size-11">${(shopping_cart.total + shopping_cart.delivery)?.toLocaleString('es-CL')}</div>
          </div>

          <div className="row mt-4 py-5 px-3 bg-color-blue-light">
            <button className="btn-default p-3 size-11 rounded-10" onClick={_handleNext}>
              Continuar
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default CheckoutMobile;
