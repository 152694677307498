import React from 'react'

const containerBody: React.CSSProperties = {
    backgroundColor: '#213291',
    color: '#ffffff',
    borderWidth: 15,
    borderTop: `12px solid #00FF7D`,
    borderRadius: 41,
    position: 'relative', top: ' -35px',
    height: 200,
    textAlign: 'left', padding: 30,
    marginBottom:10
}

const title: React.CSSProperties = {
    fontSize: 18,
    marginBottom: 10,
    fontFamily: 'var(--font-normsBold)'
}

const text: React.CSSProperties = {
    marginBottom: 10,
    fontFamily: 'var(--font-normsLight)'
}

const btn: React.CSSProperties = {
    color: '#00FF7D',
    fontFamily: 'var(--font-normsBold)',
    cursor: 'pointer'
}

function CardServiceApp(props: any) {
    return (
        <div>
            <div>
                <img alt={props.title} src={props.img} height={350} style={{ width: '100%' }} />
            </div>
            <div style={containerBody}>
                <div style={title}>{props.title}</div>
                <div style={text}>{props.text}</div>
                <div style={btn} onClick={props.click}>{props.textLink}</div>
            </div>
        </div>
    );
}

export default CardServiceApp;