/* eslint-disable react-hooks/exhaustive-deps */
import Image from '../Image';
import '../../styles/components/component.card-product.scss';
import { useEffect, useState } from 'react';
import QuantitySelect from '../QuantitySelect';
import Images from '../../assets/image';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

const CardProduct = (props: any) => {
  const { imagen, _html, name, price, action, product, data, onPlusProduct, onMinusProduct, fullpoints } = props;

  const { shopping_cart, services } = useSelector((state: any) => state);
  const [productData, setProductData] = useState<any>({});

  useEffect(() => {
    const service: any = shopping_cart.services.find((s: any) => s.PK === data.PK && s.SK === data.SK);
    const target_service: any = services.find((s: any) => s.PK === data.PK && s.SK === data.SK);

    if (service || target_service) {
      let products: any = [];
      let related_products: any = [];
      if (service) {
        related_products = service.related_products || [];
        products = [...[service.service], ...related_products];
      }

      if (target_service) {
        related_products = target_service.related_products || [];
        products = [...[target_service.service], ...related_products];
      }
      const productData: any = products.find((p: any) => p.SK === product.SK);
      setProductData(productData);
    } else {
      setProductData({});
    }
  }, [shopping_cart]);

  useEffect(() => {
    try {
      const isServiceInShoppingCart: any = shopping_cart.services.find((s: any) => s.PK === data.PK);
      if (!isServiceInShoppingCart) {
        const service: any = services.find((service: any) => service.PK === data.PK);

        if (service) {
          service.related_products = service.related_products || [];
          const products: any = [...[service.service], ...service.related_products];
          const productData: any = products.find((p: any) => p.SK === product.SK);
          setProductData(productData);
        } else {
          setProductData({});
        }
      }
    } catch (e: any) {
      toast.error('No fue posible actualizar este producto.');
    }
  }, [services]);

  const _handleOnClick = () => {
    if (action) {
      action();
    } else {
      console.log('[INFO]', 'Everything ok here ;)...');
    }
  };
  return (
    <div className="container-card-product" style={{ maxWidth: '550px' }}>
      <div className={'container-card-product__image'}>
        <Image image={imagen ? { default: { src: imagen } } : Images.NotFound} />
      </div>
      <div className="container-card-product__text color-blue-base">
        <div className="container-card-product__text__title mb-1 size-09 color-white" dangerouslySetInnerHTML={{ __html: _html || name || '' }} />

        {fullpoints && product?.info?.points_per_division && parseInt(product?.info?.points_per_division) ? (
          <div className="d-flex color-white align-items-center size-09">
            <Image image={Images.CopecFull} className="me-2" style={{ width: '100px' }} />
            Acumula puntos Full Copec.
          </div>
        ) : null}

        {product?.info?.show_discount ? (
          <div className="d-flex justify-content-between w-100 pe-4">

            <div className="size-15 bold color-white">
              <span className="color-green-base color-white">$</span>
              {price?.toLocaleString('es-CL')}
            </div>

            <div className="d-flex flex-column align-items-end">
              <span className="color-white bold text-decoration-line-through">$ {product?.info?.without_discount_price?.toLocaleString('es-CL')}</span>
              <span className="color-green-base bold size-09">{product?.info?.porcentageDiscount}% Dcto</span>
            </div>

          </div>
        ) : (
          <div className="container-card-product__text__title mb-2 size-15 bold color-white">
            <span className="color-green-base color-white">$</span>
            {price?.toLocaleString('es-CL')}
          </div>
        )}

        {!productData?.quantity || productData?.quantity === 0 ? (
          <button className={'btn container-card-product__text__subtitle direction bold size-15'} onClick={_handleOnClick}>
            Agregar
          </button>
        ) : (
          <QuantitySelect quantity={productData.quantity} onAdd={() => onPlusProduct({ PK: product.PK, SK: product.SK })} onRemove={() => onMinusProduct({ PK: product.PK, SK: product.SK })} />
        )}
      </div>
    </div>
  );
};

export default CardProduct;
