import { createAction } from 'redux-actions';

export const SET_GTM_CONSENT: any = createAction('SET_GTM_CONSENT');
export const SAVE_STATE: any = createAction('SAVE_STATE');

export const set_gtm_consent = (payload: any) => {
  return (dispatch: any) => {
    dispatch(SET_GTM_CONSENT(payload));
    dispatch(SAVE_STATE());
  };
};
