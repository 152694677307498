/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import Title from '../../components/Title';
import DesktopTemplate from '../Profile/DesktopTemplate';
import DirectionCard from './DirectionCard';
import { useDispatch, useSelector } from 'react-redux';
import { LOAD_OFF, LOAD_ON } from '../../redux/actions/loader';
import sessionService from '../../services/session.service';
import { set_user } from '../../redux/actions/user';
import ConfirmationModal from '../../components/ConfirmationModal';
import MapsDirections from '../../components/MapsDirections';
import DrawerDirections from '../../components/DrawerDirections';
import { Loader } from '@googlemaps/js-api-loader';
import moment from 'moment';

const Directions = (props: any) => {
  const [state, setState] = useState<any>({
    modal: {
      directions: {
        show: false,
      },
      directions_update: {
        show: false,
      },
    },
    drawer: {
      script: {
        show: false,
        direction: 'right-left',
      },
      manual: {
        show: false,
        direction: 'right-left',
      },
      autocomplete: {
        show: false,
        direction: 'bottom-top',
      },
      history: {
        show: false,
        direction: 'bottom-top',
      },
    },
    google: null,
    autocompleteService: null,
    address: {},
  });
  const [addresses, setAddresses] = useState<any>();
  const [selectDirection, setSelectedDirection] = useState<any>(null);

  const [confirmationModal, setConfirmationModal] = useState({
    open: false,
    type: 'warning',
    size: 'md',
    title: 'Eliminar',
  });
  const dispatch = useDispatch();
  const history = useHistory();
  const { user } = useSelector((state: any) => state);

  useEffect(() => {
    const params: any = new URLSearchParams(history.location.search);
    _getAddressesList();
    if (params.has('success')) {
      toast.success('Dirección agregada con éxito');
      window.history.pushState({}, '', '/profile/my-directions');
    }

    if (params.has('success_update')) {
      toast.success('Dirección actualizada con éxito');
      window.history.pushState({}, '', '/profile/my-directions');
    }

    if (params.has('error')) {
      toast.error('No fue posible agregar la dirección...');
      window.history.pushState({}, '', '/profile/my-directions');
    }
  }, []);

  const _handleZindexMenu = (target: any) => {
    const el: any = document.querySelector('#header-desktop');
    if (el) {
      if (!state.modal[target].show) {
        el.style.zIndex = 0;
      } else {
        el.style.zIndex = 1000;
      }
    }
  };

  const _handleChangeFavorite = async (id: any) => {
    dispatch(LOAD_ON());
    const target = addresses.find((car: any) => car.SK === id);
    const favorite = addresses.find((car: any) => car.main);

    try {
      if (favorite) {
        const favoriteParams = {
          ...favorite,
          main: false,
        };
        await sessionService.updateAddress(favorite.SK, favoriteParams);
      }

      if (target) {
        const targetParams = {
          ...target,
          main: true,
          info: {
            ...target.info,
            history: false,
          },
        };
        await sessionService.updateAddress(target.SK, targetParams);
      }

      await _getAddressesList();
    } catch (e) {
      toast.error('No fue posible actualizar la dirección');
      dispatch(LOAD_OFF());
    }
  };

  const _toggleModal = () => {
    _handleZindexMenu('directions');
    const footer = document.querySelector('#footer-tools');

    if (footer) {
      footer.classList.remove('d-none');
      footer.classList.add('d-flex');
    }
    setState({
      ...state,
      address: {},
      modal: {
        ...state.modal,
        directions: { show: !state.modal.directions.show },
      },
      drawer: {
        script: {
          show: false,
          direction: 'right-left',
        },
        manual: {
          show: false,
          direction: 'right-left',
        },
        autocomplete: {
          show: false,
          direction: 'bottom-top',
        },
        history: {
          show: false,
          direction: 'bottom-top',
        },
      },
      action: 'create',
    });
  };

  const _toggleModalUpdate = () => {
    _handleZindexMenu('directions_update');
    setState({
      ...state,
      modal: {
        ...state.modal,
        directions_update: { show: !state.modal.directions_update.show },
      },
    });
  };

  const _handleClose = async (update: any = false) => {
    if (update) {
      _toggleModalUpdate();
    } else {
      _toggleModal();
    }
  };

  const _getAddressesList = async () => {
    dispatch(LOAD_ON());
    try {
      const response = await sessionService.getAddresses();

      if (!state.google) {
        const loader: any = new Loader({
          apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY ? process.env.REACT_APP_GOOGLE_MAPS_API_KEY : '',
          version: 'weekly',
          libraries: ['places', 'geometry'],
          ...{},
        });

        loader.load().then((google: any) => {
          const autocompleteService = new google.maps.places.AutocompleteService();
          setState({ ...state, google, services: { ...state.services, autocomplete: true }, api_key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY, autocompleteService });
        });
      }

      response.data?.sort((a:any, b:any) => {
        if(moment(a.created_at).isBefore(moment(b.created_at))) return 1;
        if(moment(a.created_at).isAfter(moment(b.created_at))) return -1;
        return 0;
      })

      response.data?.sort((a: any, b: any) => {
        if (a?.info?.history) return 1;
        return -1;
      });

      dispatch(set_user({ ...user, directions: response.data }));
      setAddresses(response.data);
    } catch (error) {
      toast.error('No fue posible listar las direcciones');
    }
    dispatch(LOAD_OFF());
  };

  const handleDelete = async (selectedDirection: any) => {
    const { id } = selectedDirection;
    dispatch(LOAD_ON());
    try {
      setConfirmationModal({ ...confirmationModal, open: false });
      await sessionService.deleteAddress(id);
      const data = addresses.filter((i: any) => i.SK !== id);
      setAddresses(data);
      toast.success('Dirección eliminada con éxito');
    } catch (error: any) {
      toast.error('No fue posible eliminar la dirección');
    }
    dispatch(LOAD_OFF());
  };

  const _handleUpdateModal = (id: any) => {
    _handleZindexMenu('directions_update');
    const address: any = addresses.find((add: any) => add.SK === id);
    setState({
      ...state,
      modal: { ...state.modal, directions: { show: true } },
      address: address,
      action: 'update',
    });
  };

  const _handleUpdateModalMobile = (id: any) => {
    const footer = document.querySelector('#footer-tools');
    if (footer) {
      footer.classList.remove('d-flex');
      footer.classList.add('d-none');
    }
    const address: any = addresses.find((add: any) => add.SK === id);
    setState({
      ...state,
      drawer: { ...state.drawer, autocomplete: { ...state.drawer.autocomplete, show: true } },
      address: address,
      action: 'update',
    });
  };

  const _handleConfirmationModalDeleteDirection = (id: any) => {
    const direction: any = addresses.find((item: any) => item.SK === id);
    setSelectedDirection({ ...direction, id: id });
    setConfirmationModal({ ...confirmationModal, open: true });
  };

  const _createOrUpdateDirection = async (payload: any, update: any = false) => {
    const { latitude, longitude, address, observations, number } = payload;
    dispatch(LOAD_ON());
    try {
      const data: any = {
        longitude: longitude,
        latitude: latitude,
        name: 'Principal',
        main: state.address.main || false,
        info: {
          ...state.address.info,
          address: address,
          number: number,
          observations: observations,
          apartment: observations,
        },
      };
      if (update) {
        await sessionService.updateAddress(state.address.SK, data);
        toast.success('Dirección actualizada con éxito');
      } else {
        await sessionService.createAddress(data);
        toast.success('Dirección agregada con éxito');
      }
      _handleClose();
      _getAddressesList();
    } catch (e: any) {
      toast.error('No fue posible agregar la dirección');
      _handleClose();
      dispatch(LOAD_OFF());
    }
  };

  const _toogleDrawer = (target: any) => {
    if (!state.google) {
      const loader: any = new Loader({
        apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY ? process.env.REACT_APP_GOOGLE_MAPS_API_KEY : '',
        version: 'weekly',
        libraries: ['places', 'geometry'],
        ...{},
      });

      loader.load().then((google: any) => {
        const autocompleteService = new google.maps.places.AutocompleteService();
        state.drawer[target].show = !state.drawer[target].show;
        setState({ ...state, google, services: { ...state.services, autocomplete: true }, api_key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY, autocompleteService });
      });
    } else {
      const footer = document.querySelector('#footer-tools');
      if (footer) {
        if (state.drawer[target].show) {
          footer.classList.remove('d-none');
          footer.classList.add('d-flex');
        } else {
          footer.classList.remove('d-flex');
          footer.classList.add('d-none');
        }
      }
      state.drawer[target].show = !state.drawer[target].show;
      setState({ ...state, address: {} });
    }
  };

  const _closeAndOpen = (target: any) => {
    Object.keys(state.drawer).forEach((key) => {
      state.drawer[key].show = false;
    });
    setState({ ...state });

    /* TIMEOUT EFFECT */
    setTimeout(() => {
      state.drawer[target].show = true;
      setState({ ...state });
    }, 200);
  };

  return (
    <>
      <div className="container-fluid mt-5 d-md-none">
        <ConfirmationModal {...confirmationModal} handleClose={() => setConfirmationModal({ ...confirmationModal, open: false })} handleConfirm={() => handleDelete(selectDirection)}>
          ¿Seguro que quiere eliminar esta dirección ?
        </ConfirmationModal>

        <DrawerDirections
          state={state}
          _toogleDrawer={_toogleDrawer}
          _closeAndOpen={_closeAndOpen}
          google={state.google}
          autocompleteService={state.autocompleteService}
          callback={_createOrUpdateDirection}
          data={state.address}
          isHistoryDisabled
        />

        <div className="w-100 d-flex justify-content-center">
          <div className="row px-2" style={{ maxWidth: '500px' }}>
            <Title text="Mis direcciones" className="text-center color-dark-blue mb-3" size="md" />

            {addresses && addresses.length === 0 && <Title text="No tienes dirección guardada. ¡Agrega una ahora!" className="size-07 text-center mt-5 color-dark-blue" />}

            {addresses &&
              addresses.map((item: any) => (
                <DirectionCard
                  id={item.PK}
                  data={item}
                  text={item.info.address}
                  selected={item.is_selected}
                  name="direction_mobile"
                  className="mb-3"
                  onChange={_handleChangeFavorite}
                  onDelete={_handleConfirmationModalDeleteDirection}
                  onUpdate={_handleUpdateModalMobile}
                />
              ))}

            <div className="col-12 p-0 mt-4">
              <button className="btn-default w-100 p-3" onClick={() => _toogleDrawer('autocomplete')}>
                Agregar una dirección
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid d-none d-md-block">
        <DesktopTemplate>
          <div className="row border-bottom mx-4 px-0 py-4 mb-2">
            <div className="col-6 p-0 d-flex align-items-center">
              <span className="bold size-12 color-black">Mis direcciones</span>
            </div>
            <div className="col-6 p-0 text-end color-white">
              <button className="btn btn-default rounded-25 px-4 py-3" onClick={_toggleModal}>
                Agregar una dirección
              </button>
            </div>
          </div>

          <MapsDirections
            open={state.modal.directions.show}
            handleClose={_handleClose}
            address={state.address}
            action={state.action}
            callback={_createOrUpdateDirection}
            data={state.address}
            isHistoryDisabled
          />

          {addresses && addresses.length === 0 && <Title text="No tienes dirección guardada. ¡Agrega una ahora!" className="size-07 text-center mt-5 color-dark-blue" />}

          {addresses &&
            addresses.map((item: any) => (
              <div className="row px-5">
                <DirectionCard
                  id={item.PK}
                  data={item}
                  text={item.info.address}
                  selected={item.is_selected}
                  name="direction_desktop"
                  className="mb-3"
                  onChange={_handleChangeFavorite}
                  onDelete={_handleConfirmationModalDeleteDirection}
                  onUpdate={_handleUpdateModal}
                />
              </div>
            ))}
        </DesktopTemplate>
      </div>
    </>
  );
};
export default Directions;
