import React from 'react'

const container: React.CSSProperties = {
    backgroundColor: '#F3F5FA',
    textAlign: 'left',
    minHeight: 250,
    boxShadow:'none', 
    border:0.8, 
    borderColor:'#d9d9d9', 
    borderStyle:'solid',
    padding: 24,
    borderRadius:8,
    margin:10
};

const titleCard: React.CSSProperties = {
    color: '#213291',
    fontSize: 18,
    fontFamily: 'normsMedium',
    marginBottom: 10
};

const titleText: React.CSSProperties = {
    color: '#6E7478',
    fontFamily: 'var(--font-normsLight)',
    marginTop: 40,
};

function CardCliendApp(props: any) {
    return (
        <div style={container}>
            <div style={titleCard}> {props.name} </div>
            <div> </div>
            <div style={titleText}> {props.text} </div>
        </div>
    );
}

export default CardCliendApp;