/* eslint-disable no-useless-escape */
import * as yup from 'yup';

export const SignUpCognitoSchema = yup.object().shape({
  email: yup
    .string()
    .matches(/^[\w+.]+@\w{2,}\.\w{2,}(?:\.\w{2,})?$/, 'Formato de email no es válido')
    .required('Este campo es requerido'),
  name: yup
    .string()
    .matches(/^[\w\. ?¿¡!^\+-\:()\{\}\[\],;\*#@áàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ]*$/gim, 'Este campo contiene caracteres inválidos')
    .max(255, "Este campo acepta un máximo de 255 caracteres")
    .required('Este campo es requerido'),
  family_name: yup
    .string()
    .matches(/^[\w\. ?¿¡!^\+-\:()\{\}\[\],;\*#@áàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ]*$/gim, 'Este campo contiene caracteres inválidos')
    .max(255, "Este campo acepta un máximo de 255 caracteres")
    .required('Este campo es requerido'),
  phone_number: yup.string().min(9, 'El Nº de teléfono debe ser de 9 dígitos').max(9, 'El Nº de teléfono debe ser de 9 dígitos').required('Este campo es requerido'),
  rut: yup.string().required('Este campo es requerido'),
});

export type SignUpCognitoType = yup.InferType<typeof SignUpCognitoSchema>;
