/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Images from '../../assets/image';
import ErrorValidation from '../../components/ErrorValidation';
import Image from '../../components/Image';
import Title from '../../components/Title';
import validate from '../../helpers/validate';
import { LOAD_OFF, LOAD_ON } from '../../redux/actions/loader';
import CarService from '../../services/cars.service';
import { CarSchema, CarWithTypeSchema, PlateSchema } from '../../validations/car.validations';
import SelectCarData from './SelectCarData';
import SelectManualCarData from './SelectManualCarData';
const INITIAL_STATE = {
  inputs: {
    plate: '',
    model: '',
    brand: '',
    year: '',
    type: '',
  },
  models: [],
  brands: [],
  validations: {},
  formState: {
    plate: false,
    brand: false,
    model: false,
    searchPlate: true,
    manualData: false,
  },
  inputState: {
    plate: false,
    model: false,
    brand: false,
    year: false,
  },
  locale: {
    noResultsText: 'No se encontraron resultados',
    searchPlaceholder: 'Buscar',
  },
};

const ModalSelectCarUpdate = (props: any) => {
  const { handleConfirm, handleClose, rounded, open, data } = props;
  const dispatch = useDispatch();
  const [state, setState] = useState<any>(INITIAL_STATE);
  const Car = new CarService();

  useEffect(() => {
    if (open) {
      setState({
        ...state,
        inputs: { ...state.inputs, plate: data.plate },
      });
    } else {
      setState({ ...INITIAL_STATE });
    }
  }, [open]);

  const _clearInputs = () => {
    setState(INITIAL_STATE);
  };

  const _handleChangeInput = async (e: any) => {
    const { target } = e;
    const { name, value } = target;
    state.inputs[name] = value ? value : '';
    delete state.validations[name];

    setState({ ...state });
  };

  const _handleAddCar = async () => {
    const validations = state.formState.manualData ? await validate(CarWithTypeSchema, state.inputs) : await validate(CarSchema, state.inputs);
    if (validations) {
      setState({ ...state, validations });
    } else {
      let payload: any = {};
      if (state.formState.manualData) {
        payload = {
          plate: state.inputs.plate,
          model: {
            value: 1,
            label: state.inputs.model,
          },
          brand: {
            value: 1,
            label: state.inputs.brand,
          },
          year: state.inputs.year,
          size: state.inputs.type,
          IdItemDefault: null,
          service: 'lavamax',
        };
      } else {
        payload = {
          plate: state.inputs.plate,
          model: state.models.find((item: any) => parseInt(item.value) === parseInt(state.inputs.model)),
          brand: state.brands.find((item: any) => parseInt(item.value) === parseInt(state.inputs.brand)),
          year: state.inputs.year,
          size: state.IdMedidaVehiculo ? state.IdMedidaVehiculo : 2,
          IdItemDefault: state.inputs.IdItemDefault,
          service: 'lavamax',
        };
      }
      handleConfirm(payload);
    }
  };

  const _getBrands = async () => {
    try {
      const response = await Car.listBrands();
      const { data } = response;
      const brands = data.map((brand: any) => {
        return {
          value: brand.IdMarca,
          label: brand.NombreMarca,
        };
      });
      return brands;
    } catch (err) {
      console.log(err);
    }
  };

  const _handlePlate = async () => {
    const validations = await validate(PlateSchema, state.inputs);
    if (validations) {
      setState({ ...state, validations });
      return;
    }
    dispatch(LOAD_ON());
    try {
      const response = await Car.getDataByPlate({ patente: state.inputs.plate });
      const { data } = response;
      if (!data) {
        const brands = await _getBrands();
        setState({
          ...state,
          brands,
          formState: { ...state.formState, plate: true, searchPlate: false },
          inputState: { ...state.inputState, plate: true },
          inputs: { ...state.inputs, model: '', brand: '', year: '', IdItemDefault: null },
        });
      } else {
        const brands = [
          {
            value: data.IdMarca || 1,
            label: data.Marca || 'Marca no informada',
          },
        ];
        const models = [
          {
            value: data.IdModelo || 1,
            label: data.Modelo || 'Modelo no informado',
          },
        ];

        setState({
          ...state,
          brands,
          models,
          inputs: {
            ...state.inputs,
            model: models[0].value,
            brand: brands[0].value,
            year: data.Anho,
            IdItemDefault: data.IdItemDefault
          },
          formState: { ...state.formState, plate: true, searchPlate: false },
          inputState: {
            plate: true,
            model: true,
            brand: true,
            year: true,
          },
          IdMedidaVehiculo: data.IdMedidaVehiculo,
        });
      }
    } catch (err) {
      console.log(err);
    }
    dispatch(LOAD_OFF());
  };

  const _handleBrand = async (e: any) => {
    dispatch(LOAD_ON());
    try {
      if (e === null) {
        setState({
          ...state,
          inputs: { ...state.inputs, brand: null, year: '' },
          models: [],
        });
        dispatch(LOAD_OFF());
        return;
      }
      const response = await Car.getModels(e);
      const { data } = response;
      const models = data.map((brand: any) => {
        return {
          value: brand.IdMarca,
          label: brand.NombreModelo,
        };
      });
      delete state.validations.brand;
      setState({ ...state, models, inputs: { ...state.inputs, brand: e } });
    } catch (err) {
      console.log(err);
      dispatch(LOAD_OFF());
    }
    dispatch(LOAD_OFF());
  };

  const _handleManualSection = (e: any) => {
    e.preventDefault();
    setState({
      ...state,
      formState: { ...state.formState, manualData: true },
      inputs: { ...state.inputs, brand: '', model: '', year: '' },
    });
  };

  return (
    <div
      className={`drawer-services-form-fields d-flex flex-column justify-content-between bg-color-white ${rounded ? 'rounded-20' : ''}`}
      style={{ overflowY: 'auto', overflowX: 'hidden', minWidth: '30%' }}
    >
      <div className="drawer-services-form-fields position-relative">
        <button className="position-absolute top-0 end-0 mt-2 me-4 btn" onClick={handleClose}>
          <Image image={Images.CloseBlue} />
        </button>
        <Title size="md" text="Actualizar un vehículo" className="d-flex w-100 justify-content-center my-4 color-dark-blue bold" />

        {/* SEARCH PLATE */}
 <div className="row px-3 mb-3">
          <div  className="col-12 color-black position-relative">
            <div   className="bold ps-2 mb-2">Ingresa la patente</div>
            <input
              className="input-default rounded-30 py-3 px-4"
              onChange={_handleChangeInput}
              value={state.inputs.plate}
              name="plate"
              style={{ textTransform: 'uppercase' }}
              disabled={state.inputState.plate}
              maxLength={6}
            />
            {!state.formState.searchPlate && <Image image={Images.CloseBlue} className="position-absolute" style={{ right: '30px', top: '50px' }} onClick={_clearInputs} />}
            <ErrorValidation className="ps-4 size-08" validations={state.validations} name="plate" />
          </div>
        </div> 

        {state.formState.searchPlate && (
          <div className="d-flex justify-content-center align-items-center m-4">
            <button className="btn-default p-2 w-50 size-11 rounded-30" onClick={_handlePlate}>
              Confirmar
            </button>
          </div>
        )}

        {state.formState.plate && !state.formState.manualData && (
          <SelectCarData state={state} _handleBrand={_handleBrand} _handleChangeInput={_handleChangeInput} _handleAddCar={_handleAddCar} _handleManualSection={_handleManualSection} />
        )}

        {state.formState.plate && state.formState.manualData && (
          <SelectManualCarData state={state} _handleBrand={_handleBrand} _handleChangeInput={_handleChangeInput} _handleAddCar={_handleAddCar} _handleManualSection={_handleManualSection} />
        )}
      </div>

      <div className="adjustment-iphone" style={{ height: '15vh' }} />
    </div>
  );
};

export default ModalSelectCarUpdate;
